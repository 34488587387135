import { useContext, useEffect, useState } from "react";
import GenericTabs from "components/GenericTabs/generic-tabs.component";
import { ActiveTabName } from "components/GenericTabs/props";
import { TaskInfoTab } from "shared/enums/task-management-tabs.enum";
import "./task-info-tabs.styles.scss";
import { useSelector } from "react-redux";
import PatientAndEpisodeDetails from "./patient-and-episode-details/patient-and-episode-details.component";
import Icon from "components/Icon/icon.component";
import CallParticipantsDropdown from "components/call-participants-dropdown/call-participants-dropdown.component";
import {
  getBrowserState,
  getCurrentPageTaskState,
  getTaskManagementState,
} from "state/feature/task-management/task-management.slice";
import { getCallState } from "state/feature/call/call.slice";
import { getAssignPhoneNumberListAsync } from "state/feature/navigator/navigator.action";
import { toast } from "react-toastify";
import ClickAwayListener from "react-click-away-listener";
import { getAuth } from "state/feature/auth/auth.slice";
import {
  getCommonState,
  setIsOpenCallModal,
  setIsOpenCareOfPlanDeleteConfirmationModal,
  setIsOpenChatModal,
  setIsOpenPOCServiceModal,
} from "state/feature/common/common.slice";
import PatientTaskDetailsContaianer from "./patient-task-details/patient-task-details.container";
import {
  addPatientToPatientArray,
  saveConversationParticipants,
  setUserIdforMessage,
} from "state/feature/messaging/messaging.slice";
import { getNavigatorState } from "state/feature/navigator/navigator.slice";
import { CALL_MESSAGE_ERROR_MESSAGE } from "../constant";
import { OpenFrom } from "shared/enums/chat.enum";
import { CallSidePopup } from "components/SidePopup/CallSidePopup/call-side-popup.component";
import { ContactDetail } from "state/types/task-management-slice.type";
import { Participant } from "shared/types/participant.type";
import { ContactType } from "shared/enums/contact-type.enum";
import ActivityLogs from "./activity-history-tab/activity-logs.container";
import { getTaskInfoTabs } from "components/GenericTabs/constants";
import { getConfig } from "config/config";
import { CustomToolTip } from "components/CustomTooltip/custom-tooltip.component";
import PlanOfCareTab from "./plan-of-care-tab/plan-of-care-tab.container";
import POCServiceModal from "components/Modal/poc-service-modal/poc-service-modal.container";
import POCDeleteConfirmationModal from "components/Modal/POCDeleteConfirmationModal/poc-delete-confirmation-modal.component";
import { createCarePlanUtilizationPACLogs, deleteCarePlanAsync } from "state/feature/plan-of-care/plan-of-care.action";
import { useAppDispatch } from "state/store";
import { getPlanOfCareState, resetPlanOfCareState } from "state/feature/plan-of-care/plan-of-care.slice";
import { getPlanOfCareListAsync } from "state/feature/episodes/episodes.action";
import { DeleteLogText } from "components/Modal/poc-service-modal/poc-service-modal.constant";
import { getPatientFullName, isEmptyString } from "shared/methods/utilityFunctions";
import moment from "moment";
import { DaysVisit } from "shared/enums/plan-of-care.enum";
import { CreateCarePlanPACLogsRequestPayload } from "components/Modal/poc-service-modal/poc-service-modal.props";
import { CallingStatus } from "shared/enums/calling-status.enum";
import { CallContext } from "App";
interface TaskInfoProps {
  activeTabName: ActiveTabName;
  onClickTabName: (tabName: ActiveTabName) => void;
}

const TaskInfoTabs = (props: TaskInfoProps) => {
  const { activeTabName, onClickTabName } = props;
  const [showCallParticipants, setShowCallParticipants] = useState(false);
  const { assignedPhoneNumbers } = useSelector(getNavigatorState);
  const callState = useSelector(getCallState);
  const { twilioErrorCode, modal } = useSelector(getCommonState);
  const { user } = useSelector(getAuth);
  const { currentTab } = useSelector(getBrowserState);
  const { episode, patientDetails, isRightPanelLoading, intakeId } = useSelector(
    getCurrentPageTaskState(currentTab!.taskId)
  );
  const dispatch = useAppDispatch();
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [selectedParticipantsForCall, setSelectedParticipantsForCall] = useState<Array<Participant>>();
  const { id, deleteCarePlanData } = useSelector(getPlanOfCareState);
  const { browser } = useSelector(getTaskManagementState);
  const { ongoingCall: isOngoingCall } = useContext(CallContext);

  useEffect(() => {
    if (assignedPhoneNumbers.length === 0) {
      dispatch(getAssignPhoneNumberListAsync());
    }
  }, [assignedPhoneNumbers.length, dispatch]);

  const handleCall = () => {
    if (
      !isOngoingCall ||
      !(
        localStorage.getItem("callStatus") === CallingStatus.CONNECTED ||
        localStorage.getItem("callStatus") === CallingStatus.RINGING
      )
    ) {
      if (!twilioErrorCode) {
        if (
          !episode?.patientAndEpisodeDetails?.contactDetails ||
          !episode?.patientAndEpisodeDetails?.contactDetails.length
        ) {
          toast.error("No Phone Number Exists.", {
            toastId: "phone-number-error",
            containerId: "main",
          });
        } else {
          setShowCallParticipants(true);
        }
      }
    }
  };

  const onParticipantSelectionForCall = (contact: ContactDetail) => {
    dispatch(setIsOpenCallModal(true));
    let userId = "";
    let contactType;

    if (contact.type === ContactType.PHYSICIAN) {
      userId = episode?.patientAndEpisodeDetails.physicianUserId
        ? episode?.patientAndEpisodeDetails.physicianUserId.toString()
        : "";
      contactType = ContactType.PHYSICIAN;
    } else if (contact.type === ContactType.CAREGIVER) {
      contactType = ContactType.CAREGIVER;
    } else {
      userId = episode?.patientAndEpisodeDetails.patientUserId
        ? episode?.patientAndEpisodeDetails.patientUserId.toString()
        : "";
      contactType = ContactType.PATIENT;
    }
    setSelectedParticipantsForCall([
      {
        firstName: contact.firstName,
        lastName: contact.lastName,
        phoneNumber: contact.phoneNumber,
        patientContactDetails: [],
        userId,
        clientId: contact.clientId,
        contactType,
      },
    ]);
  };

  const handleMessage = () => {
    if (episode?.patientAndEpisodeDetails.patientUserId) {
      if (episode?.id && episode?.id !== "" && patientDetails?.patientName) {
        dispatch(setUserIdforMessage(episode.id));
        dispatch(
          addPatientToPatientArray({
            patientID: episode.patientAndEpisodeDetails.patientId,
            patientUserID: episode.patientAndEpisodeDetails.patientUserId,
            patientFirstName: episode.patientAndEpisodeDetails.patientFirstName,
            patientLastName: episode.patientAndEpisodeDetails.patientLastName,
          })
        );
        dispatch(saveConversationParticipants(patientDetails.patientName));
        dispatch(setIsOpenChatModal({ isOpen: true, openFrom: OpenFrom.TASK_MANAGEMENT }));
      }
    }
  };
  const getCarePlanUtilizationPACLogsPayload = (): CreateCarePlanPACLogsRequestPayload | null => {
    if (deleteCarePlanData) {
      const {
        reasonForOtherReadmission,
        notes,
        actualAdmitDate,
        actualDischargeDate,
        provider,
        reasonForService,
        pacType,
        aptNo,
        readmitReason,
        daysType,
      } = deleteCarePlanData;

      const los = aptNo && daysType === DaysVisit.LOS ? aptNo : null;
      const visits = aptNo && daysType === DaysVisit.VISIT ? aptNo : null;
      const payload: CreateCarePlanPACLogsRequestPayload = {
        intakeId: intakeId,
        user: getPatientFullName({
          firstName: user?.firstName,
          lastName: user?.lastName,
        }),
        userId: user.navigatorId,
        createdDate: moment().utc().format(),
        notes: notes && !isEmptyString(notes) ? notes : null,
        los,
        visits,
        service: pacType ?? "",
        serviceReason: reasonForService && !isEmptyString(reasonForService) ? reasonForService : null,
        provider: provider && !isEmptyString(provider) ? provider : null,
        providerId: provider && !isEmptyString(provider) ? -1 : null,
        admissionDate: actualAdmitDate && actualAdmitDate !== "-" ? moment(actualAdmitDate).format("MM/DD/YYYY") : "",
        dischargeDate:
          actualDischargeDate && actualDischargeDate !== "-" ? moment(actualDischargeDate).format("MM/DD/YYYY") : null,
        otherReason:
          reasonForOtherReadmission && !isEmptyString(reasonForOtherReadmission) ? reasonForOtherReadmission : null,
        readmissionReason: readmitReason && !isEmptyString(readmitReason) ? readmitReason : null,
        taskId: id,
        planOfCareTaskStatus: DeleteLogText,
      };
      return payload;
    }
    return null;
  };

  const handleSubmitForDeleteConfirmation = () => {
    dispatch(deleteCarePlanAsync(id)).then((response) => {
      if (response.payload.statusCode === 200) {
        const careUtilizationPacLogsPayload = getCarePlanUtilizationPACLogsPayload();
        if (careUtilizationPacLogsPayload) {
          dispatch(createCarePlanUtilizationPACLogs(careUtilizationPacLogsPayload)).finally(() => {
            dispatch(resetPlanOfCareState());
            dispatch(setIsOpenPOCServiceModal(false));
            dispatch(setIsOpenCareOfPlanDeleteConfirmationModal(false));
          });
        } else {
          dispatch(resetPlanOfCareState());
          dispatch(setIsOpenPOCServiceModal(false));
          dispatch(setIsOpenCareOfPlanDeleteConfirmationModal(false));
        }
        if (browser.currentPage) {
          dispatch(getPlanOfCareListAsync({ intakeId: browser.currentPage?.intakeId, sortBy: "", sortOrder: "" }));
        }
      }
    });
  };

  return (
    <div className="task-details">
      <div className="task-details-header">
        <div className="task-details-header-text">
          Contact Details
          <div
            id="Open Patient Chart"
            data-tip
            data-for={"Open Patient Chart"}
            className="task-details-header-text-icon"
          >
            <a href={`${getConfig().efusionBase}/TIntakes/PatientProfile/${patientDetails?.intakeId}`} target="_blank">
              <Icon icon="patientChart" size={32} />
              <CustomToolTip text={"Open Patient Chart"} id="Open Patient Chart" place="bottom" />
            </a>
          </div>
        </div>
        {user.navigatorId ? (
          <div className="task-details-header-icons">
            <div
              className={`call-icon task-info-icon ${showCallParticipants ? "call-icon-active" : ""}`}
              onClick={() => {
                if (
                  !callState.currentCall &&
                  !isOngoingCall &&
                  !(
                    localStorage.getItem("callStatus") === CallingStatus.CONNECTED ||
                    localStorage.getItem("callStatus") === CallingStatus.RINGING
                  )
                ) {
                  handleCall();
                }
              }}
            >
              <Icon icon="call-icon" size={20} />
              {showCallParticipants ? (
                <ClickAwayListener
                  onClickAway={() => setShowCallParticipants(false)}
                  children={
                    <div>
                      <CallParticipantsDropdown
                        patient={patientDetails!.patientName}
                        id={patientDetails!.patientId.toString()}
                        contactDetails={episode?.patientAndEpisodeDetails?.contactDetails ?? []}
                        onClick={(contact: ContactDetail) => {
                          setShowCallParticipants(false);
                          if (
                            !callState.currentCall &&
                            !isOngoingCall &&
                            !(
                              localStorage.getItem("callStatus") === CallingStatus.CONNECTED ||
                              localStorage.getItem("callStatus") === CallingStatus.RINGING
                            )
                          ) {
                            onParticipantSelectionForCall(contact);
                          } else {
                            toast.error("Another call is ongoing", {
                              toastId: "call-ongoing",
                              containerId: "main",
                            });
                          }
                        }}
                        key={patientDetails?.patientId}
                        className="down"
                      />
                    </div>
                  }
                ></ClickAwayListener>
              ) : null}
            </div>
            <div className={`task-info-icon`} onClick={handleMessage}>
              <Icon icon="navigator-message" size={17} />
            </div>
          </div>
        ) : null}
      </div>
      {!isRightPanelLoading && !episode?.patientAndEpisodeDetails.patientUserId && (
        <div className="call-message-error-state">
          <Icon icon="error-message-icon" size={20} />
          <div>{CALL_MESSAGE_ERROR_MESSAGE}</div>
        </div>
      )}
      <CallSidePopup
        isOpen={modal.isOpenCallModal}
        heading="Make a Call"
        isDropdownVisible={isDropdownVisible}
        setIsDropdownVisible={setIsDropdownVisible}
        participants={selectedParticipantsForCall}
        setParticipants={(participants) => {
          setSelectedParticipantsForCall(participants);
        }}
        intakeId={intakeId}
      />
      <POCServiceModal />
      <POCDeleteConfirmationModal handleSubmitForDeleteConfirmation={handleSubmitForDeleteConfirmation} />
      <GenericTabs tabsList={getTaskInfoTabs()} activeTabName={activeTabName} setActiveTabName={onClickTabName} />
      {activeTabName === TaskInfoTab.EPISODE && <PatientAndEpisodeDetails />}
      {activeTabName === TaskInfoTab.TASKS && <PatientTaskDetailsContaianer />}
      {activeTabName === TaskInfoTab.ACTIVITY_HISTORY && <ActivityLogs />}
      {activeTabName === TaskInfoTab.PLAN_OF_CARE && <PlanOfCareTab />}
    </div>
  );
};

export default TaskInfoTabs;
