import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  getEpisodesSlice,
  resetEpisodeDetailsActvityList,
  setIsActivityFilterApplied,
} from "state/feature/episodes/episodes.slice";
import ActivityHistoryPresentation from "./activity-history.component";
import { getActvityLogAsync } from "state/feature/episodes/episodes.action";
import { setIsFilterModal } from "state/feature/common/common.slice";
import { IGetActivityLogRequestPayload } from "state/types/episodes-slice.type";
import { PAGE_LIMIT } from "./type";

const ActivityHistory = () => {
  const { episodeDetails, isLoadingEpisodeDetailTabs, episodeDetailTabs } = useSelector(getEpisodesSlice);
  const { intakeId } = useParams<{ intakeId: string }>();
  const dispatch = useDispatch();
  const initialRequestPayload = {
    intakeId: intakeId,
    offset: 0,
    limit: PAGE_LIMIT,
    categories: [],
  };
  const [requestPayload, setRequestPayload] = useState<IGetActivityLogRequestPayload>(initialRequestPayload);

  const handleApplyFilter = (filter: Array<string>) => {
    dispatch(setIsActivityFilterApplied(filter.length > 0));
    dispatch(resetEpisodeDetailsActvityList());
    setRequestPayload((prev) => {
      return { ...prev, offset: 0, categories: [...filter] };
    });
    dispatch(setIsFilterModal(false));
  };

  const handleClearFilter = () => {
    dispatch(setIsActivityFilterApplied(false));
    dispatch(resetEpisodeDetailsActvityList());
    setRequestPayload((prev) => {
      return { ...prev, offset: 0, categories: [] };
    });
    dispatch(setIsFilterModal(false));
  };
  useEffect(() => {
    dispatch(getActvityLogAsync(requestPayload));
  }, [episodeDetails, dispatch, intakeId, requestPayload]);

  useEffect(() => {
    return () => {
      dispatch(setIsActivityFilterApplied(false));
      dispatch(resetEpisodeDetailsActvityList());
    };
  }, [dispatch]);
  const onSort = (sortKey: string, sortOrder: string) => {};

  const onIncrementPage = () => {
    setRequestPayload((prev) => {
      return { ...prev, offset: prev.offset + PAGE_LIMIT };
    });
  };

  return (
    <ActivityHistoryPresentation
      onSort={onSort}
      currentTableData={episodeDetailTabs.activityHistory}
      isLoading={isLoadingEpisodeDetailTabs.activityHistory}
      selectedFilterValues={requestPayload.categories ?? []}
      handleApplyFilter={handleApplyFilter}
      handleClearFilter={handleClearFilter}
      onIncrementPage={onIncrementPage}
    />
  );
};

export default ActivityHistory;
