import Icon from "components/Icon/icon.component";
import ReactCollapsible from "react-collapsible";
import "./collapsible.styles.scss";
import { CollapsibleProps } from "./props";

const Collapsible = (props: CollapsibleProps) => {
  const { isOpen, handleClick, heading, isTriggerDisabled, content, containerClass } = props;
  return (
    <ReactCollapsible
      open={isOpen}
      handleTriggerClick={() => {
        handleClick(isOpen);
      }}
      classParentString={`collapsible-container ${isOpen ? "is-open" : "not-open"} ${containerClass ?? ""}`}
      trigger={
        <div className={`collapsible-container-heading ${!isTriggerDisabled ? "is-present" : "is-not-present"}`}>
          {heading}
          <Icon
            icon={isOpen ? "up-arrow" : "down-arrow"}
            size={10}
            className={!isTriggerDisabled ? "is-present" : "is-not-present"}
          />
        </div>
      }
      triggerDisabled={isTriggerDisabled}
    >
      {content}
    </ReactCollapsible>
  );
};

export default Collapsible;
