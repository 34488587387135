import Checkbox from "components/Checkbox/checkbox.component";
import { CheckboxProps } from "components/Checkbox/props";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { TaskPageName } from "shared/enums/page-name.enum";
import { getAssessmentState } from "state/feature/assessment/assessment.slice";
import { getRiskAssessmentState } from "state/feature/risk-assessment/risk-assessment.slice";
import { getBrowserState } from "state/feature/task-management/task-management.slice";

const CheckoxContainer = ({ value, name, onChange, id, sectionId, isDisabled, className }: CheckboxProps) => {
  const { currentTab } = useSelector(getBrowserState);
  const { taskType } = currentTab!;
  const { selectedValues, answers }: any = useSelector<any>(
    taskType === TaskPageName.RISK_ASSESSMENT ? getRiskAssessmentState : getAssessmentState
  );

  const getCheckedValue = useCallback(
    (optionid: string, sId: string | null | undefined) => {
      switch (taskType) {
        case TaskPageName.RISK_ASSESSMENT:
          if (sId) {
            const values = selectedValues.filter((item: any) => item.sectionId === sId);
            if (values && values.length > 0) {
              const wantedState = Object.values(values[0].answers).flat();
              const isPresent = wantedState.filter((item: any) => {
                return item.id === optionid;
              });
              isPresent.forEach((item: any) => {
                const element = document.getElementById(item.id) as HTMLInputElement;
                element.checked = true;
              });
            }
          }
          break;
        case TaskPageName.ASSESSMENT:
          if (sId) {
            const values = answers.filter((item: any) => item.questionId === sId);
            if (values && values.length > 0) {
              const wantedState = Object.values(values[0].selectedValues).flat();
              const isPresent = wantedState.filter((item: any) => {
                return item.id === optionid;
              });
              isPresent.forEach((item: any) => {
                const element = document.getElementById(item.id) as HTMLInputElement;
                element.checked = true;
              });
            }
          }
          break;
      }
    },
    [selectedValues, answers, taskType]
  );

  useEffect(() => {
    getCheckedValue(id, sectionId);
  }, [selectedValues, id, sectionId, getCheckedValue, answers]);
  return (
    <Checkbox isDisabled={isDisabled} value={value} name={name} onChange={onChange} id={id} className={className} />
  );
};

export default CheckoxContainer;
