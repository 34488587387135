import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBrowserState, getCurrentPageTaskState } from "state/feature/task-management/task-management.slice";
import { findDistance, getAllTocFacilitiesAsync } from "state/feature/toc-management/toc-management.action";
import { getTOCManagementState } from "state/feature/toc-management/toc-management.slice";

const useFacilities = (intakeId: string): (() => void) => {
  const dispatch = useDispatch();
  const { currentTab } = useSelector(getBrowserState);
  const { patientDetails } = useSelector(getCurrentPageTaskState(currentTab!.taskId));
  const { facilities } = useSelector(getTOCManagementState);
  useEffect(() => {
    if (intakeId && !facilities.length) {
      dispatch(getAllTocFacilitiesAsync(intakeId));
    }
  }, [dispatch, intakeId, facilities.length]);

  const getPatientLocation = useCallback(() => {
    const location = [];

    if (patientDetails?.patientAddress.address1) {
      location.push(patientDetails?.patientAddress.address1);
    }

    if (patientDetails?.patientAddress.city) {
      location.push(patientDetails?.patientAddress.city);
    }

    if (patientDetails?.patientAddress.state) {
      location.push(patientDetails?.patientAddress.state);
    }

    if (location.length) {
      return [location.join(", ")];
    }
    return [];
  }, [patientDetails]);

  const getFacilitiesWithDistance = useCallback(async () => {
    if (facilities.length) {
      const selectedPlaces: Array<{ id: number; address: string }> = [];
      const origins = getPatientLocation();
      const destinations = facilities
        .filter((el) => {
          let location = "";

          if (el.providerAddress1 && el.providerAddress1.length) {
            location += el.providerAddress1;
          }

          if (el.providerCity && el.providerCity.length) {
            location += el.providerCity;
          }

          if (el.providerState && el.providerState.length) {
            location += el.providerState;
          }

          return location.length;
        })
        .map((el) => {
          const location = [];

          if (el.providerAddress1 && el.providerAddress1.length) {
            location.push(el.providerAddress1);
          }

          if (el.providerCity && el.providerCity.length) {
            location.push(el.providerCity);
          }

          if (el.providerState && el.providerState.length) {
            location.push(el.providerState);
          }

          selectedPlaces.push({
            id: el.id,
            address: location.join(", "),
          });

          return location.join(", ");
        });

      if (origins.length && destinations.length && selectedPlaces.length) {
        dispatch(findDistance({ origins, destinations, selectedPlaces }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilities.length, dispatch, getPatientLocation]);

  return getFacilitiesWithDistance;
};

export default useFacilities;
