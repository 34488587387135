import { useEffect, useState } from "react";
import Sort from "components/Sort/sort.component";
import { batch, useSelector } from "react-redux";
import { isEmptyString, isReplyButtonDisabled } from "shared/methods/utilityFunctions";
import { USER_ROLE } from "shared/constant/role-constant";
import { getAuth } from "state/feature/auth/auth.slice";
import { getNavigatorState, resetCalls } from "state/feature/navigator/navigator.slice";
import { isMobile } from "react-device-detect";
import { SortingOrderType } from "shared/enums/sorting-types.enums";
import Icon from "components/Icon/icon.component";
import InfiniteScroll from "react-infinite-scroll-component";
import TableLoader from "components/TableLoader/table-loader.component";
import CommonState from "components/common-state/common-state.component";
import { CommonStateType } from "components/common-state/common-state.enum";
import InboundRow from "./row/inbound-row.component";
import "./call-logs-table.styles.scss";
import "./inbound-patient-table.styles.scss";
import { ColumnType, Participant, TableNavigatorDashboardProps } from "shared/types/table.type";
import { useTwilioClientService } from "shared/services/twilio/twilio-client.service";
import { getCallState } from "state/feature/call/call.slice";
import { getCommonState, setIsOpenForceCallQuitModal } from "state/feature/common/common.slice";
import { useAppDispatch } from "state/store";
import { toast } from "react-toastify";
import { CallingStatus } from "shared/enums/calling-status.enum";
import { synchronizeCallsAsync } from "state/feature/call/call.action";
import ForceQuitCallModal from "components/Modal/ForceQuitCallModal/force-quit-call-modal.component";
import { useIdleTimerContext } from "react-idle-timer";

const CallLogsTable = ({
  tableClassName,
  columns,
  currentTableData,
  searchText,
  handleColumnSort,
  incrementPage,
  getNavigatorCalls,
}: {
  tableClassName: string;
  currentTableData: TableNavigatorDashboardProps[];
  columns: ColumnType[];
  searchText: string;
  handleColumnSort?: (column: ColumnType) => void;
  incrementPage: () => void;
  getNavigatorCalls: () => void;
}) => {
  const [indexOfAudioPlayer, setIndexOfAudioPlayer] = useState(-1);
  const { user } = useSelector(getAuth);
  const { isLoading } = useSelector(getNavigatorState);
  const { currentCall } = useSelector(getCallState);
  const [hasScrollBar, setHasScrollBar] = useState(false);
  const [selectedCallerParticipantId, setSelectedCallerParticipantId] = useState("");
  const [callSid, setCallSid] = useState("");
  const [otherParticipantDetails, setOtherParticipantDetails] = useState<Participant | null>(null);
  const { modal } = useSelector(getCommonState);
  // const { resumeTimer } = useIdleTimerContainer();
  const { resume: resumeTimer } = useIdleTimerContext();
  const twilioClient = useTwilioClientService();
  const appDispatch = useAppDispatch();

  const displaySortIcons = (column: ColumnType) => {
    return column.sortOrder === SortingOrderType.DEFAULT ? (
      <Sort />
    ) : (
      <Icon icon={column.sortOrder === SortingOrderType.ASC ? "sort-top" : "sort-bottom"} size={8} />
    );
  };

  const columnName = (column: ColumnType) => {
    const shouldDisplaySortIcons = !isLoading;
    if (column.name !== "") {
      return (
        <div
          className={`navigator-dashboard-table-header-content ${
            isLoading && "navigator-dashboard-table-header-content-disable-cursor"
          }`}
          onClick={() => {
            if (shouldDisplaySortIcons) {
              handleColumnSort?.(column);
            }
          }}
        >
          <div className="name">{column.name}</div>
          <span>{shouldDisplaySortIcons && displaySortIcons(column)}</span>
        </div>
      );
    }
    return column.name;
  };

  const updateState = () => {
    const InfiniteScrollOuterDivElement = document.querySelector(".infinite-scroll-component__outerdiv");
    const InfiniteScrollInnerDivElement = InfiniteScrollOuterDivElement?.children[0]
      ? InfiniteScrollOuterDivElement.children[0]
      : null;

    if (InfiniteScrollOuterDivElement && InfiniteScrollInnerDivElement) {
      setHasScrollBar(InfiniteScrollInnerDivElement.clientWidth < InfiniteScrollOuterDivElement.clientWidth);
    }
  };
  const disconnectCall = () => {
    twilioClient.disconnectConference(currentCall).then(() => {
      getNavigatorCalls();
    });
    toast.dismiss("call");
    resumeTimer();
    localStorage.setItem("callStatus", CallingStatus.DISCONNECTED);
  };

  const openForceQuitCallModal = (callParticipantId: string, id: string, otherParticipant?: Participant) => {
    appDispatch(setIsOpenForceCallQuitModal(true));
    setSelectedCallerParticipantId(callParticipantId);
    setCallSid(id);

    setOtherParticipantDetails(otherParticipant ?? null);
  };

  const handleApplyForceQuitCall = () => {
    if (
      currentCall &&
      ((currentCall._direction === "OUTGOING" && currentCall.parameters.CallSid === callSid) ||
        (otherParticipantDetails &&
          currentCall._direction === "INCOMING" &&
          currentCall.parameters.From === otherParticipantDetails.phoneNumber))
    ) {
      disconnectCall();
    }
    if (!isEmptyString(selectedCallerParticipantId)) {
      appDispatch(synchronizeCallsAsync({ ids: [selectedCallerParticipantId], callSid }))
        .then(() => {
          batch(() => {
            appDispatch(resetCalls());
            getNavigatorCalls();
            appDispatch(setIsOpenForceCallQuitModal(false));
          });
        })
        .catch(() => {
          getNavigatorCalls();
          appDispatch(setIsOpenForceCallQuitModal(false));
        });
    }
    setSelectedCallerParticipantId("");
    setOtherParticipantDetails(null);
  };

  const handleCloseForceQuitCall = () => {
    appDispatch(setIsOpenForceCallQuitModal(false));
    setSelectedCallerParticipantId("");
    setOtherParticipantDetails(null);
  };

  useEffect(() => {
    updateState();
    window.addEventListener("resize", updateState);
    return () => window.removeEventListener("resize", updateState);
  }, [currentTableData]);

  return (
    <>
      <table className={tableClassName}>
        <ForceQuitCallModal
          handleApply={handleApplyForceQuitCall}
          handleCloseClick={handleCloseForceQuitCall}
          isOpen={modal.isOpenForceCallQuitModal}
        />
        <thead>
          <tr
            className={`inbound-navigator-dashboard-table-header ${isMobile ? "hidden" : ""} ${
              hasScrollBar ? "scrollbar-is-visible" : ""
            }`}
          >
            <td>
              <table>
                <tbody>
                  <tr>
                    {columns.map((column) => {
                      if (column.key === "originalrecipient" && user?.roles?.includes(USER_ROLE.MANAGER)) {
                        return (
                          <th className={column.key} key={column.key}>
                            {columnName({
                              ...column,
                              name: "Primary Navigator",
                            })}
                          </th>
                        );
                      }
                      return (
                        <th
                          className={
                            column.key === "reasonForPrimaryUnavailability" ? "missed-call-reason" : column.key
                          }
                          key={column.key}
                        >
                          {columnName(column)}
                        </th>
                      );
                    })}
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </thead>
        <tbody className={isMobile ? "navigator-dashboard-mobile-data" : ""}>
          <tr className="dashboard-no-border-in-mobile">
            <td>
              <InfiniteScroll
                next={incrementPage}
                hasMore={currentTableData.length > 0}
                loader={isLoading ? <TableLoader /> : <></>}
                height="100%"
                dataLength={currentTableData.length}
              >
                <table>
                  <tbody className="inbound-navigator-dashboard-table-body">
                    {isLoading && currentTableData.length === 0 && (
                      <tr>
                        <td>
                          <div className="loading-container">
                            <CommonState type={CommonStateType.LOADING} />
                          </div>
                        </td>
                      </tr>
                    )}
                    {!isLoading && currentTableData.length === 0 && (
                      <tr className="no-message-container">
                        <td className="no-message-td" colSpan={isMobile ? 3 : 5}>
                          <div className="no-messages">No Call Logs Found</div>
                        </td>
                      </tr>
                    )}
                    {currentTableData.length > 0 &&
                      currentTableData.map((item, index) => {
                        return (
                          <InboundRow
                            key={`call_${index}`}
                            {...item}
                            searchText={searchText}
                            index={index}
                            indexOfAudioPlayer={indexOfAudioPlayer}
                            setIndexOfAudioPlayer={setIndexOfAudioPlayer}
                            currentTableData={currentTableData}
                            clientID={item.clientId}
                            openForceQuitCallModal={openForceQuitCallModal}
                            isReplyButtonDisabled={isReplyButtonDisabled() || user.roles[0] != USER_ROLE.MANAGER}
                            to={item.to}
                          />
                        );
                      })}
                  </tbody>
                </table>
              </InfiniteScroll>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default CallLogsTable;
