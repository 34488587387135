import { createSlice } from "@reduxjs/toolkit";
import { INewManualTaskState } from "state/types/new-manual-task.type";
import {
  addManualTaskAsync,
  getManualFieldTaskAsync,
  getManualTaskTypeAsync,
  getTaskOwnerMenuAsync,
} from "./new-manual-task.action";

const initialState: INewManualTaskState = {
  manualTaskType: [],
  currentManualTaskType: null,
  isError: false,
  isLoading: false,
  isDirty: false,
  taskOwnerDropdown: [],
  manualTaskFields: [],
  isLoadingAddTask: false,
  isValueChanged: false,
  manualTaskFieldsValues: { title: "", taskOwner: null, notes: "", startDate: null, dueDate: null, requests: "" },
};

const newManualTaskSlice = createSlice({
  name: "new-manual-task",
  initialState,
  reducers: {
    setCurrentManualTaskType(state, action) {
      state.currentManualTaskType = action.payload;
      state.isValueChanged = true;
    },
    setNewManualTaskStateFromMemoize(state, action) {
      return action.payload;
    },
    setIsDirty(state, action) {
      state.isDirty = action.payload;
    },
    setManualTaskFieldsValues(state, action) {
      state.manualTaskFieldsValues = action.payload;
      state.isValueChanged = true;
    },
    resetManualFieldsValues(state) {
      state.manualTaskFieldsValues = {
        title: "",
        taskOwner: null,
        notes: "",
        startDate: null,
        dueDate: null,
        requests: "",
      };
      state.isValueChanged = false;
    },
    setIsValueChanged(state, action) {
      state.isValueChanged = action.payload;
    },
    resetNewManualTask() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    return (
      builder.addCase(getManualTaskTypeAsync.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(getManualTaskTypeAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.manualTaskType = action.payload;
      }),
      builder.addCase(getManualTaskTypeAsync.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = false;
      }),
      builder.addCase(getManualFieldTaskAsync.pending, (state, action) => {
        state.isLoading = true;
      }),
      builder.addCase(getManualFieldTaskAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.manualTaskFields = action.payload;
      }),
      builder.addCase(getManualFieldTaskAsync.rejected, (state, action) => {
        state.isLoading = false;
      }),
      builder.addCase(getTaskOwnerMenuAsync.pending, (state, action) => {
        // state.isLoading = true;
      }),
      builder.addCase(getTaskOwnerMenuAsync.fulfilled, (state, action) => {
        state.taskOwnerDropdown = action.payload;
      }),
      builder.addCase(getTaskOwnerMenuAsync.rejected, (state, action) => {}),
      builder.addCase(addManualTaskAsync.pending, (state, action) => {
        // state.isLoading = true;
        state.isLoadingAddTask = true;
      }),
      builder.addCase(addManualTaskAsync.fulfilled, (state, action) => {
        state.isLoadingAddTask = false;
      }),
      builder.addCase(addManualTaskAsync.rejected, (state, action) => {
        state.isLoadingAddTask = false;
      })
    );
  },
});

export const {
  setCurrentManualTaskType,
  setNewManualTaskStateFromMemoize,
  setManualTaskFieldsValues,
  setIsDirty,
  resetManualFieldsValues,
  resetNewManualTask,
  setIsValueChanged,
} = newManualTaskSlice.actions;
export const getNewManualTaskState = (state: any): INewManualTaskState => state.newManualTask;
export default newManualTaskSlice;
