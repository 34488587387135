export const LOSVisitsLabel = {
  LOS: "LOS (Days)",
  Visits: "Visits",
};

export const planOfCareDetailsLabels = {
  reasonService: "Service Reason",
  readmitReason: "Readmission Reason",
  otherDetails: "Other Details",
  notes: "Notes",
};
