import Sort from "components/Sort/sort.component";
import Row from "./row/row.component";
import "./score-table.styles.scss";

type ScoreType = {
  id: string;
  section: string;
  sectionScore: number;
  riskScore: number;
};

const ScoreTable = ({
  tableClassName,
  scores,
  columns,
}: {
  tableClassName: string;
  scores: ScoreType[];
  columns: any[];
}) => {
  return (
    <>
      <table className={tableClassName}>
        <thead>
          <tr>
            {columns.map(({ name, sorting }) => {
              return (
                <th key={name}>
                  <div className="summary-table-header-content">
                    <div className="name">{name}</div>
                    <div>{sorting && <Sort />}</div>
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        {scores && (
          <tbody className="summary-table-body">
            {scores.length === 0 && (
              <tr className="no-message-container">
                <td className="no-message-td" colSpan={6}>
                  <div className="no-messages">Summary does not exist</div>
                </td>
              </tr>
            )}
            {scores.map((item: ScoreType) => {
              return <Row key={item.id} {...item} />;
            })}
          </tbody>
        )}
      </table>
    </>
  );
};

export default ScoreTable;
