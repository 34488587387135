import { createSlice } from "@reduxjs/toolkit";
import { TOAST_MESSAGES } from "pages/login/common/constants";
import { Pages } from "pages/task-management/risk-assessment/common/risk-assessment.types";
import { toast } from "react-toastify";
import { IPageConfig, IRiskAssessmentState, SelectedValue } from "state/types/risk-assessment-slice.type";
import {
  getExtraQuestionsBySectionsAsync,
  getQuestionsBySectionsAsync,
  getRiskAssessmentByIntakeIdAsync,
  getRiskAssessmentForResultByIntakeIdAsync,
  getRiskAssessmentSummaryByIntakeIdAsync,
  getSectionsByPageAsync,
  saveRiskAssessmentAnswersAsync,
} from "./risk-assessment.action";
import { RiskAssessmentView } from "shared/enums/page-name.enum";
import { isEmptyString } from "shared/methods/utilityFunctions";

const pageConfig: IPageConfig = {
  id: "",
  tab: -1,
  name: "",
  isDirty: false,
  isLoading: false,
  isCurrent: false,
  sections: [],
  sectionDetails: [],
  extraSection: [],
  unAnsweredSections: [],
  isValidationEnabled: false,
  isSavingDraft: false,
};

const initialState: IRiskAssessmentState = {
  isLoading: false,
  isSavingDraft: false,
  isError: false,
  id: null,
  status: null,
  summary: {},
  selectedValues: [],
  collapsibleSections: [],
  staticModalVisible: false,
  isValueChanged: false,
  showPostAcuteTaskCompletionPopUp: false,
  currentView: RiskAssessmentView.FORM,
  pages: {
    general: {
      ...pageConfig,
      id: "generalAssessment",
      tab: Pages.General,
      name: "General Assessment",
      isCurrent: true,
    },
    lace: {
      ...pageConfig,
      id: "laceAssessment",
      tab: Pages.Lace,
      name: "LACE Assessment",
    },
    additional: {
      ...pageConfig,
      id: "additionalQuestions",
      tab: Pages.Additional,
      name: "Additional Questions",
    },
    closing: {
      ...pageConfig,
      id: "closingQuestions",
      tab: Pages.Closing,
      name: "Closing Questions",
    },
  },
  result: [],
  resultMeta: null,
  deplayStatuses: [],
  intakeDetails: null,
  delayAttempts: [],
  taskStatusId: 1,
};

const getExtraInfo = (question: any, questions: any[], isSecondLevel: boolean): any => {
  const values = [];
  for (const selectionValue of question.selectionValues) {
    const nextQuestion = questions.find((el) => el.questionId === selectionValue.nextQuestionId);
    if (nextQuestion) {
      const nestedValues = getExtraInfo(nextQuestion, questions, true);
      if (nestedValues && nestedValues.length > 0) {
        selectionValue.value = `${selectionValue.value} (${nestedValues.toString()})`;
        values.push(selectionValue.value);
      } else {
        values.push(selectionValue.value);
      }
    } else {
      values.push(selectionValue.value);
    }
  }
  return values;
};

const getTotalScoreForNestedQuestions = (question: any, questions: any[]): any => {
  let score = 0;
  for (const selectionValue of question.selectionValues) {
    const nextQuestion = questions.find((el) => el.questionId === selectionValue.nextQuestionId);
    if (nextQuestion) {
      score = getTotalScoreForNestedQuestions(nextQuestion, questions);
    } else {
      score = selectionValue.score;
    }
  }
  return score;
};

const mapSelectedAnswers = (state: any, action: any) => {
  state.selectedValues = [];
  state.collapsibleSections = [];
  action.payload.sectionAnswers.forEach((section: any) => {
    const mappedAnswers = section.answers.map((x: any) => {
      return {
        [x.questionId]: x.selectionValues.map((y: any) => {
          return {
            id: y.id,
            value: y.value,
            nextQuestionId: y.nextQuestionId,
            order: x.order,
            collapsibleSectionId: x.collapsibleSectionId,
            isConclusion: x.isConclusion,
          };
        }),
      };
    });
    const finalMappedAnswers: any = {};
    for (const ans of mappedAnswers) {
      for (const [k, v] of Object.entries(ans)) {
        finalMappedAnswers[k] = v;
      }
    }
    state.selectedValues.push({
      sectionId: section.sectionId,
      pageId: section.pageId,
      sectionOrder: section.order,
      answers: finalMappedAnswers,
    });
  });
  state.selectedValues.forEach((selectedValue: SelectedValue) => {
    for (const [k, v] of Object.entries(selectedValue.answers)) {
      if (v[0].collapsibleSectionId) {
        state.collapsibleSections.push({
          sectionId: v[0].collapsibleSectionId,
          pageId: selectedValue.pageId,
          questionId: k,
          isDirty: true,
        });
      }
    }
  });
};

const removeFromUnansweredSectionsPrivate = (state: any, action: any) => {
  switch (action.payload.pageId) {
    case Pages.General:
      const unansweredSectionGeneral = state.pages.general.unAnsweredSections.find(
        (x: any) => x.sectionId === action.payload.sectionId
      );
      if (unansweredSectionGeneral) {
        const sectionQuestion = unansweredSectionGeneral.questions.find((x: any) => x === action.payload.questionId);
        if (sectionQuestion) {
          unansweredSectionGeneral.questions = unansweredSectionGeneral.questions.filter(
            (x: any) => x !== action.payload.questionId
          );
          if (unansweredSectionGeneral.questions.length < 1) {
            state.pages.general.unAnsweredSections = state.pages.general.unAnsweredSections.filter(
              (x: any) => x.sectionId !== action.payload.sectionId
            );
          } else {
            state.pages.general.unAnsweredSections = state.pages.general.unAnsweredSections.map((x: any) =>
              x.sectionId === action.payload.sectionId ? unansweredSectionGeneral : x
            );
          }
        }
      }

      if (
        action.payload.clearChildQuestions &&
        unansweredSectionGeneral &&
        unansweredSectionGeneral.questions.length > 0
      ) {
        unansweredSectionGeneral.questions = unansweredSectionGeneral.questions.filter(
          (x: any) => !x.split(".").includes(action.payload.questionId)
        );
        if (unansweredSectionGeneral.questions.length < 1) {
          state.pages.general.unAnsweredSections = state.pages.general.unAnsweredSections.filter(
            (x: any) => x.sectionId !== action.payload.sectionId
          );
        } else {
          state.pages.general.unAnsweredSections = state.pages.general.unAnsweredSections.map((x: any) =>
            x.sectionId === action.payload.sectionId ? unansweredSectionGeneral : x
          );
        }
      }

      if (state.pages.general.unAnsweredSections.length == 0) {
        state.pages.general.isValidationEnabled = false;
      }
      break;
    case Pages.Lace:
      const unansweredSectionLace = state.pages.lace.unAnsweredSections.find(
        (x: any) => x.sectionId === action.payload.sectionId
      );
      if (unansweredSectionLace) {
        const sectionQuestion = unansweredSectionLace.questions.find((x: any) => x === action.payload.questionId);
        if (sectionQuestion) {
          unansweredSectionLace.questions = unansweredSectionLace.questions.filter(
            (x: any) => x !== action.payload.questionId
          );
          if (unansweredSectionLace.questions.length < 1) {
            state.pages.lace.unAnsweredSections = state.pages.lace.unAnsweredSections.filter(
              (x: any) => x.sectionId !== action.payload.sectionId
            );
          } else {
            state.pages.lace.unAnsweredSections = state.pages.lace.unAnsweredSections.map((x: any) =>
              x.sectionId === action.payload.sectionId ? unansweredSectionLace : x
            );
          }
        }
      }

      if (action.payload.clearChildQuestions && unansweredSectionLace && unansweredSectionLace.questions.length > 0) {
        unansweredSectionLace.questions = unansweredSectionLace.questions.filter(
          (x: any) => !x.split(".").includes(action.payload.questionId)
        );
        if (unansweredSectionLace.questions.length < 1) {
          state.pages.lace.unAnsweredSections = state.pages.lace.unAnsweredSections.filter(
            (x: any) => x.sectionId !== action.payload.sectionId
          );
        } else {
          state.pages.lace.unAnsweredSections = state.pages.lace.unAnsweredSections.map((x: any) =>
            x.sectionId === action.payload.sectionId ? unansweredSectionLace : x
          );
        }
      }

      if (state.pages.lace.unAnsweredSections.length == 0) {
        state.pages.lace.isValidationEnabled = false;
      }
      break;
    case Pages.Additional:
      const unansweredSectionAdditional = state.pages.additional.unAnsweredSections.find(
        (x: any) => x.sectionId === action.payload.sectionId
      );
      if (unansweredSectionAdditional) {
        const sectionQuestion = unansweredSectionAdditional.questions.find((x: any) => x === action.payload.questionId);
        if (sectionQuestion) {
          unansweredSectionAdditional.questions = unansweredSectionAdditional.questions.filter(
            (x: any) => x !== action.payload.questionId
          );
          if (unansweredSectionAdditional.questions.length < 1) {
            state.pages.additional.unAnsweredSections = state.pages.additional.unAnsweredSections.filter(
              (x: any) => x.sectionId !== action.payload.sectionId
            );
          } else {
            state.pages.additional.unAnsweredSections = state.pages.additional.unAnsweredSections.map((x: any) =>
              x.sectionId === action.payload.sectionId ? unansweredSectionAdditional : x
            );
          }
        }
      }

      if (
        action.payload.clearChildQuestions &&
        unansweredSectionAdditional &&
        unansweredSectionAdditional.questions.length > 0
      ) {
        unansweredSectionAdditional.questions = unansweredSectionAdditional.questions.filter(
          (x: any) => !x.split(".").includes(action.payload.questionId)
        );
        if (unansweredSectionAdditional.questions.length < 1) {
          state.pages.additional.unAnsweredSections = state.pages.additional.unAnsweredSections.filter(
            (x: any) => x.sectionId !== action.payload.sectionId
          );
        } else {
          state.pages.additional.unAnsweredSections = state.pages.additional.unAnsweredSections.map((x: any) =>
            x.sectionId === action.payload.sectionId ? unansweredSectionAdditional : x
          );
        }
      }

      if (state.pages.additional.unAnsweredSections.length == 0) {
        state.pages.additional.isValidationEnabled = false;
      }
      break;
    case Pages.Closing:
      const unansweredSectionClosing = state.pages.closing.unAnsweredSections.find(
        (x: any) => x.sectionId === action.payload.sectionId
      );
      if (unansweredSectionClosing) {
        const sectionQuestion = unansweredSectionClosing.questions.find((x: any) => x === action.payload.questionId);
        if (sectionQuestion) {
          unansweredSectionClosing.questions = unansweredSectionClosing.questions.filter(
            (x: any) => x !== action.payload.questionId
          );
          if (unansweredSectionClosing.questions.length < 1) {
            state.pages.closing.unAnsweredSections = state.pages.closing.unAnsweredSections.filter(
              (x: any) => x.sectionId !== action.payload.sectionId
            );
          } else {
            state.pages.closing.unAnsweredSections = state.pages.closing.unAnsweredSections.map((x: any) =>
              x.sectionId === action.payload.sectionId ? unansweredSectionClosing : x
            );
          }
        }
      }

      if (
        action.payload.clearChildQuestions &&
        unansweredSectionClosing &&
        unansweredSectionClosing.questions.length > 0
      ) {
        unansweredSectionClosing.questions = unansweredSectionClosing.questions.filter(
          (x: any) => !x.split(".").includes(action.payload.questionId)
        );
        if (unansweredSectionClosing.questions.length < 1) {
          state.pages.closing.unAnsweredSections = state.pages.closing.unAnsweredSections.filter(
            (x: any) => x.sectionId !== action.payload.sectionId
          );
        } else {
          state.pages.closing.unAnsweredSections = state.pages.closing.unAnsweredSections.map((x: any) =>
            x.sectionId === action.payload.sectionId ? unansweredSectionClosing : x
          );
        }
      }

      if (state.pages.closing.unAnsweredSections.length == 0) {
        state.pages.closing.isValidationEnabled = false;
      }
      break;
  }
};

const riskAssessmentSlice = createSlice({
  name: "risk-assessment",
  initialState,
  reducers: {
    setShowPostAcuteTaskCompletionPopUp(state, action) {
      state.showPostAcuteTaskCompletionPopUp = action.payload;
    },
    setUnansweredSections(state, action) {
      switch (action.payload.pageId) {
        case Pages.General:
          const unansweredSectionGeneral = state.pages.general.unAnsweredSections.find(
            (x: any) => x.sectionId === action.payload.sectionId
          );
          if (unansweredSectionGeneral) {
            const sectionQuestion = unansweredSectionGeneral.questions.find(
              (x: any) => x === action.payload.questionId
            );
            if (!sectionQuestion) {
              unansweredSectionGeneral.questions.push(action.payload.questionId);
              state.pages.general.unAnsweredSections = state.pages.general.unAnsweredSections.map((x: any) =>
                x.sectionId === action.payload.sectionId ? unansweredSectionGeneral : x
              );
            }
          } else {
            state.pages.general.unAnsweredSections.push({
              sectionId: action.payload.sectionId,
              questions: [action.payload.questionId],
            });
          }
          break;
        case Pages.Lace:
          const unansweredSectionLace = state.pages.lace.unAnsweredSections.find(
            (x: any) => x.sectionId === action.payload.sectionId
          );
          if (unansweredSectionLace) {
            const sectionQuestion = unansweredSectionLace.questions.find((x: any) => x === action.payload.questionId);
            if (!sectionQuestion) {
              unansweredSectionLace.questions.push(action.payload.questionId);
              state.pages.lace.unAnsweredSections = state.pages.lace.unAnsweredSections.map((x: any) =>
                x.sectionId === action.payload.sectionId ? unansweredSectionLace : x
              );
            }
          } else {
            state.pages.lace.unAnsweredSections.push({
              sectionId: action.payload.sectionId,
              questions: [action.payload.questionId],
            });
          }
          break;
        case Pages.Additional:
          const unansweredSectionAdditional = state.pages.additional.unAnsweredSections.find(
            (x: any) => x.sectionId === action.payload.sectionId
          );
          if (unansweredSectionAdditional) {
            const sectionQuestion = unansweredSectionAdditional.questions.find(
              (x: any) => x === action.payload.questionId
            );
            if (!sectionQuestion) {
              unansweredSectionAdditional.questions.push(action.payload.questionId);
              state.pages.additional.unAnsweredSections = state.pages.additional.unAnsweredSections.map((x: any) =>
                x.sectionId === action.payload.sectionId ? unansweredSectionAdditional : x
              );
            }
          } else {
            state.pages.additional.unAnsweredSections.push({
              sectionId: action.payload.sectionId,
              questions: [action.payload.questionId],
            });
          }
          break;
        case Pages.Closing:
          const unansweredSectionClosing = state.pages.closing.unAnsweredSections.find(
            (x: any) => x.sectionId === action.payload.sectionId
          );
          if (unansweredSectionClosing) {
            const sectionQuestion = unansweredSectionClosing.questions.find(
              (x: any) => x === action.payload.questionId
            );
            if (!sectionQuestion) {
              unansweredSectionClosing.questions.push(action.payload.questionId);
              state.pages.closing.unAnsweredSections = state.pages.closing.unAnsweredSections.map((x: any) =>
                x.sectionId === action.payload.sectionId ? unansweredSectionClosing : x
              );
            }
          } else {
            state.pages.closing.unAnsweredSections.push({
              sectionId: action.payload.sectionId,
              questions: [action.payload.questionId],
            });
          }
          break;
      }
    },
    removeFromUnansweredSections(state, action) {
      removeFromUnansweredSectionsPrivate(state, action);
    },
    setIsDirty(state, action) {
      switch (action.payload.pageId) {
        case Pages.General:
          state.pages.general.isDirty = action.payload.isDirty;
          break;
        case Pages.Lace:
          state.pages.lace.isDirty = action.payload.isDirty;
          break;
        case Pages.Additional:
          state.pages.additional.isDirty = action.payload.isDirty;
          break;
        case Pages.Closing:
          state.pages.closing.isDirty = action.payload.isDirty;
          break;
      }
    },
    setIsValueChanged(state, action) {
      state.isValueChanged = action.payload;
    },
    setActivePage(state, action) {
      state.pages.general.isCurrent = false;
      state.pages.lace.isCurrent = false;
      state.pages.additional.isCurrent = false;
      state.pages.closing.isCurrent = false;
      switch (action.payload) {
        case Pages.General:
          state.pages.general.isCurrent = true;
          break;
        case Pages.Lace:
          state.pages.lace.isCurrent = true;
          break;
        case Pages.Additional:
          state.pages.additional.isCurrent = true;
          break;
        case Pages.Closing:
          state.pages.closing.isCurrent = true;
          break;
      }
    },
    enablePageValidation(state, action) {
      switch (action.payload) {
        case Pages.General:
          if (state.pages.general.unAnsweredSections.length > 0) {
            state.pages.general.isValidationEnabled = true;
          }

          break;
        case Pages.Lace:
          if (state.pages.lace.unAnsweredSections.length > 0) {
            state.pages.lace.isValidationEnabled = true;
          }
          break;
        case Pages.Additional:
          if (state.pages.additional.unAnsweredSections.length > 0) {
            state.pages.additional.isValidationEnabled = true;
          }
          break;
        case Pages.Closing:
          if (state.pages.closing.unAnsweredSections.length > 0) {
            state.pages.closing.isValidationEnabled = true;
          }
          break;
      }
    },
    setCollapsibleSectionIsDirty(state, action) {
      state.collapsibleSections.forEach((section) => {
        if (section.pageId === action.payload.pageId) {
          section.isDirty = action.payload.isDirty;
        }
      });
    },
    setSelectedValues(state, action) {
      state.selectedValues = action.payload;
    },
    setResult(state, action) {
      state.result = action.payload;
    },
    setSelectedValue(state, action) {
      const payload = action.payload;
      const sectionId = payload.sectionId;

      // if section already exists in the state, then get that section and append the answer
      const selectedValuesForSection = state.selectedValues.filter((value) => value.sectionId === sectionId);
      if (selectedValuesForSection.length > 0) {
        const index = state.selectedValues.indexOf(selectedValuesForSection[0]);
        for (const i in state.selectedValues[index].answers) {
          if (i.includes(`${payload.questionId}.`)) {
            delete state.selectedValues[index].answers[i];
          }
        }
        const copyOfSelectedValuesForSection = { ...selectedValuesForSection[0] };
        copyOfSelectedValuesForSection.answers = {
          ...copyOfSelectedValuesForSection.answers,
          ...action.payload.answer,
        };
        copyOfSelectedValuesForSection.sectionOrder = action.payload.sectionOrder;
        state.selectedValues[index] = copyOfSelectedValuesForSection;
      } else {
        state.selectedValues.push({
          sectionId,
          sectionOrder: payload.sectionOrder,
          pageId: payload.pageId.toString(),
          answers: payload.answer,
        });
      }
    },
    appendSelectedValue(state, action) {
      const payload = action.payload;
      const sectionId = payload.sectionId;
      // if section already exists in the state, then get that section and append the answer
      const selectedValuesForSection = state.selectedValues.filter((value) => value.sectionId === sectionId);
      if (selectedValuesForSection.length > 0) {
        const index = state.selectedValues.indexOf(selectedValuesForSection[0]);
        const copyOfSelectedValuesForSection = { ...selectedValuesForSection[0] };
        const existingAnswers = copyOfSelectedValuesForSection.answers[payload.questionId];
        if (existingAnswers && existingAnswers.length > 0) {
          const appendedAnswers = existingAnswers.push(payload.answer[payload.questionId][0]);
          copyOfSelectedValuesForSection.answers = {
            ...copyOfSelectedValuesForSection.answers,
            ...appendedAnswers,
          };
        } else {
          copyOfSelectedValuesForSection.answers = {
            ...copyOfSelectedValuesForSection.answers,
            ...action.payload.answer,
          };
        }
        state.selectedValues[index] = copyOfSelectedValuesForSection;
      } else {
        state.selectedValues.push({
          sectionId,
          sectionOrder: payload.sectionOrder,
          pageId: payload.pageId.toString(),
          answers: payload.answer,
        });
      }
    },
    removeSelectedValue(state, action) {
      const payload = action.payload;
      const sectionId = payload.sectionId;
      // if section already exists in the state, then get that section and append the answer
      const section = state.selectedValues.filter((value) => value.sectionId === sectionId);
      if (section.length > 0) {
        const index = state.selectedValues.indexOf(section[0]);
        const copy = { ...section[0] };
        delete copy.answers[payload.questionId];
        for (const i in state.selectedValues[index].answers) {
          if (i.includes(payload.questionId + ".")) {
            delete state.selectedValues[index].answers[i];
          }
        }
        if (Object.keys(copy.answers).length === 0) {
          state.selectedValues.splice(index, 1);
        } else {
          state.selectedValues[index] = copy;
        }
        state.isValueChanged = true;
      }
    },
    showStaticModal(state, action) {
      state.staticModalVisible = action.payload;
    },
    removeAppendedSelectedValue: (state: any, action) => {
      const payload = action.payload;
      const pageId = payload.pageId;
      const sectionId = payload.sectionId;
      const questionId = payload.questionId;
      const nextQuestionId = payload.nextQuestionId;
      const section = state.selectedValues.filter((value: any) => value.sectionId === sectionId);
      removeFromUnansweredSectionsPrivate(state, {
        payload: {
          pageId: pageId,
          sectionId: sectionId,
          questionId: nextQuestionId,
          clearChildQuestions: true,
        },
      });
      if (section.length > 0) {
        const index = state.selectedValues.findIndex((val: any) => val?.sectionId == action.payload?.sectionId);
        state.selectedValues[index].answers[questionId] = state.selectedValues[index].answers[questionId].filter(
          (obj: any) => obj?.id !== payload?.answerId
        );
        for (const i in state.selectedValues[index].answers) {
          if (i.includes(`${nextQuestionId}.`) || i.includes(nextQuestionId)) {
            delete state.selectedValues[index].answers[i];
          }
        }
        if (state.selectedValues[index].answers[questionId].length == 0) {
          delete state.selectedValues[index].answers[questionId];
        }
        state.isValueChanged = true;
      }
    },
    setCollapsibleSection(state, { payload }) {
      state.collapsibleSections.push(payload);
    },
    removeCollapsibleSection(state, { payload }) {
      let index = -1;
      state.collapsibleSections.map((x, i) => {
        if (x.questionId === payload.questionId && x.sectionId === payload.sectionId) {
          index = i;
        }
      });
      if (index >= 0) {
        state.collapsibleSections.splice(index, 1);
      }
      index = -1;
      state.collapsibleSections.map((x, i) => {
        if (x.sectionId === payload.sectionId) {
          index = i;
        }
      });
      if (index < 0) {
        state.selectedValues = state.selectedValues.filter((r) => r.sectionId !== payload.sectionId);
        switch (payload.pageId) {
          case Pages.General:
            state.pages.general.unAnsweredSections = state.pages.general.unAnsweredSections.filter(
              (x: any) => x.sectionId !== payload.sectionId
            );
            break;
          case Pages.Lace:
            state.pages.lace.unAnsweredSections = state.pages.lace.unAnsweredSections.filter(
              (x: any) => x.sectionId !== payload.sectionId
            );
            break;
          case Pages.Additional:
            state.pages.additional.unAnsweredSections = state.pages.additional.unAnsweredSections.filter(
              (x: any) => x.sectionId !== payload.sectionId
            );
            break;
          case Pages.Closing:
            state.pages.closing.unAnsweredSections = state.pages.closing.unAnsweredSections.filter(
              (x: any) => x.sectionId !== payload.sectionId
            );
            break;
        }
      }
    },
    removeSelectedValuesForSection(state, { payload }) {
      state.isValueChanged = true;
      state.selectedValues = state.selectedValues.filter((obj) => obj.sectionId !== payload);
    },
    clearPage(state) {
      if (state.pages.general.isCurrent) {
        state.selectedValues = state.selectedValues.filter((obj) => obj.pageId !== Pages.General.toString());
        state.pages.general.isValidationEnabled = false;
        state.collapsibleSections = state.collapsibleSections.filter(
          (x) => x.pageId !== Pages.General && x.questionId !== ""
        );
      } else if (state.pages.lace.isCurrent) {
        state.selectedValues = state.selectedValues.filter((obj) => obj.pageId !== Pages.Lace.toString());
        state.pages.lace.isValidationEnabled = false;
        state.collapsibleSections = state.collapsibleSections.filter(
          (x) => x.pageId !== Pages.Lace && x.questionId !== ""
        );
      } else if (state.pages.closing.isCurrent) {
        state.selectedValues = state.selectedValues.filter((obj) => obj.pageId !== Pages.Closing.toString());
        state.pages.closing.isValidationEnabled = false;
        state.collapsibleSections = state.collapsibleSections.filter(
          (x) => x.pageId !== Pages.Closing && x.questionId !== ""
        );
      } else {
        state.selectedValues = state.selectedValues.filter((obj) => obj.pageId !== Pages.Additional.toString());
        state.pages.additional.isValidationEnabled = false;
        state.collapsibleSections = state.collapsibleSections.filter(
          (x) => x.pageId !== Pages.Additional && x.questionId !== ""
        );
      }
    },
    resetSelections() {
      sessionStorage.clear();
      return initialState;
    },
    saveLosDays(state: any, action) {
      const copyOfSelectedValues = [...state.selectedValues];
      const sectionIdxInSelectedValues = copyOfSelectedValues.findIndex(
        (sectionEl: any) => sectionEl.sectionId === "lace"
      );
      copyOfSelectedValues[sectionIdxInSelectedValues].answers.los[0].value = action.payload;

      state.selectedValues = copyOfSelectedValues;
    },
    setRiskAssessmentCurrentView(state, action) {
      state.currentView = action.payload;
    },
    setRiskAssessmentStateFromMemoize(state, action) {
      return action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    resetRiskAssessmentState(state) {
      state = structuredClone(initialState);
    },
  },
  extraReducers: (builder) => {
    return (
      builder.addCase(getSectionsByPageAsync.pending, (state: any) => {
        state.isLoading = true;
      }),
      builder.addCase(getSectionsByPageAsync.fulfilled, (state: any, action) => {
        state.isLoading = false;
        if (action.payload) {
          switch (action.meta.arg.pageId) {
            case Pages.General:
              state.pages.general.sections = action.payload.sections;
              break;
            case Pages.Lace:
              state.pages.lace.sections = action.payload.sections;
              break;
            case Pages.Additional:
              state.pages.additional.sections = action.payload.sections;
              break;
            case Pages.Closing:
              state.pages.closing.sections = action.payload.sections;
              break;
          }
        }
      }),
      builder.addCase(getSectionsByPageAsync.rejected, (state: any, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(TOAST_MESSAGES.ERROR, {
          containerId: "main",
          toastId: "error",
        });
      }),
      builder.addCase(getQuestionsBySectionsAsync.pending, (state: any, action) => {
        state.pages.general.isLoading = true;
        state.pages.lace.isLoading = true;
        state.pages.additional.isLoading = false;
        state.pages.closing.isLoading = true;
      }),
      builder.addCase(getQuestionsBySectionsAsync.fulfilled, (state: any, action) => {
        state.pages.general.isLoading = false;
        state.pages.lace.isLoading = false;
        state.pages.additional.isLoading = false;
        state.pages.closing.isLoading = false;
        switch (action.meta.arg.pageId) {
          case Pages.General:
            state.pages.general.sectionDetails = action.payload;
            break;
          case Pages.Lace:
            state.pages.lace.sectionDetails = action.payload;
            break;
          case Pages.Additional:
            state.pages.additional.sectionDetails = action.payload;
            break;
          case Pages.Closing:
            state.pages.closing.sectionDetails = action.payload;
            break;
        }
      }),
      builder.addCase(getQuestionsBySectionsAsync.rejected, (state: any, action) => {
        state.pages.general.isLoading = false;
        state.pages.lace.isLoading = false;
        state.pages.additional.isLoading = false;
        state.pages.closing.isLoading = false;
        state.isError = true;
      }),
      builder.addCase(getExtraQuestionsBySectionsAsync.fulfilled, (state: any, action) => {
        state.pages.general.isLoading = false;
        state.pages.lace.isLoading = false;
        state.pages.additional.isLoading = false;
        state.pages.closing.isLoading = false;
        switch (action.meta.arg.pageId) {
          case Pages.General:
            state.pages.general.extraSection = action.payload.data;
            break;
          case Pages.Lace:
            state.pages.lace.extraSection = action.payload.data;
            break;
          case Pages.Additional:
            state.pages.additional.extraSection = action.payload.data;
            break;
          case Pages.Closing:
            state.pages.closing.extraSection = action.payload.data;
            break;
        }
      }),
      builder.addCase(getRiskAssessmentByIntakeIdAsync.pending, (state: any, action) => {
        state.isLoadingIntake = true;
        state.isError = false;
      }),
      builder.addCase(getRiskAssessmentByIntakeIdAsync.fulfilled, (state: any, action) => {
        state.isLoadingIntake = false;
        state.isError = false;
        if (action.payload) {
          state.id = action.payload.id;
          state.status = action.payload.status;
          state.taskStatusId = action.payload.taskStatusId;
          if (action.payload.sectionAnswers && action.payload.sectionAnswers.length > 0) {
            mapSelectedAnswers(state, action);
            state.isValueChanged = false;
          }
          if (state.status === "published") {
            state.currentView = RiskAssessmentView.SUMMARY;
          }
        }
      }),
      builder.addCase(getRiskAssessmentByIntakeIdAsync.rejected, (state: any, action: any) => {
        state.isLoadingIntake = false;
        state.isError = true;
      }),
      builder.addCase(getRiskAssessmentSummaryByIntakeIdAsync.pending, (state: any, action) => {
        state.isLoading = true;
        state.isError = false;
      }),
      builder.addCase(getRiskAssessmentSummaryByIntakeIdAsync.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.isError = false;
        state.summary = action.payload;
      }),
      builder.addCase(getRiskAssessmentSummaryByIntakeIdAsync.rejected, (state: any, action: any) => {
        state.isLoading = false;
        state.isError = true;
      }),
      builder.addCase(saveRiskAssessmentAnswersAsync.pending, (state: any, action) => {
        if (action.meta?.arg?.isSaveDraft) {
          state.isSavingAsDraft = true;
        } else {
          state.isLoading = true;
        }
        state.isError = false;
      }),
      builder.addCase(saveRiskAssessmentAnswersAsync.fulfilled, (state: any, action) => {
        if (action.meta?.arg?.isSaveDraft) {
          state.isSavingAsDraft = false;
        } else {
          state.isLoading = false;
        }
        state.isValueChanged = false;
        state.isError = false;
        state.id = action.payload.id;
      }),
      builder.addCase(saveRiskAssessmentAnswersAsync.rejected, (state: any, action: any) => {
        if (action.meta?.arg?.isSaveDraft) {
          state.isSavingAsDraft = false;
        } else {
          state.isLoading = false;
        }
        state.isError = true;
        if (action.payload.error.data && !isEmptyString(action.payload.error.data.submittedBy)) {
          toast.error(`${action.payload.error.data.submittedBy} has already completed/closed this task.`, {
            containerId: "main",
            toastId: "risk-assessment-error",
          });
        } else if (action.payload.error && action.payload.error.message === "Request failed with status code 400") {
          toast.error("Please complete the form before final submission.", {
            containerId: "main",
            toastId: "risk-assessment-error",
          });
        } else {
          toast.error(TOAST_MESSAGES.ERROR, {
            containerId: "main",
            toastId: "risk-assessment-error",
          });
        }
      }),
      builder.addCase(getRiskAssessmentForResultByIntakeIdAsync.pending, (state: any, action) => {
        state.isLoading = false;
        state.isError = true;
      }),
      builder.addCase(getRiskAssessmentForResultByIntakeIdAsync.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.isError = true;
        if (!action.payload) {
          return;
        }
        if (action.payload.sectionAnswers && action.payload.sectionAnswers.length > 0) {
          mapSelectedAnswers(state, action);
        }
        const iteratedQuestions: Array<string> = [];
        const result = action.payload.sectionAnswers.map((section: any) => {
          const questionAnswers: Array<any> = [];
          section.answers.map((question: any) => {
            const values = [];
            let title = "";
            let score = 0;
            for (const selectionValue of question.selectionValues) {
              const nextQuestion = section.answers.find((el: any) => el.questionId === selectionValue.nextQuestionId);
              if (nextQuestion) {
                const value = getExtraInfo(nextQuestion, section.answers, false);
                score = getTotalScoreForNestedQuestions(nextQuestion, section.answers);
                values.push(...value);
                title = nextQuestion.questionTitle;
              }
            }
            if (!iteratedQuestions.includes(question.questionId.split(".")[0])) {
              iteratedQuestions.push(question.questionId);
              questionAnswers.push({
                id: question.questionId,
                question: question.questionTitle,
                answer: question.selectionValues[0].value,
                score: question.selectionValues[0].score + score,
                nextQuestionId: question.selectionValues[0].nextQuestionId,
                extraInfo: values.length > 0 ? { title: title, value: values } : null,
                ignoreScore: !!section.ignoreSectionScore,
                isConclusion: question.isConclusion ?? null,
              });
            }
          });
          return {
            id: section.sectionId,
            sectionTitle: section.sectionTitle,
            totalScore: section.sectionScore,
            questionAnswers: questionAnswers,
            ignoreScore: !!section.ignoreSectionScore,
          };
        });
        state.result = result;
        delete action.payload.sectionAnswers;
        state.resultMeta = action.payload;
      }),
      builder.addCase(getRiskAssessmentForResultByIntakeIdAsync.rejected, (state: any, action: any) => {
        state.isLoading = false;
        state.isError = true;
      })
    );
  },
});

export const {
  setIsDirty,
  setActivePage,
  showStaticModal,
  setSelectedValue,
  setSelectedValues,
  appendSelectedValue,
  removeSelectedValue,
  setCollapsibleSection,
  removeCollapsibleSection,
  removeAppendedSelectedValue,
  removeSelectedValuesForSection,
  resetSelections,
  clearPage,
  setUnansweredSections,
  removeFromUnansweredSections,
  enablePageValidation,
  saveLosDays,
  setCollapsibleSectionIsDirty,
  setIsValueChanged,
  setShowPostAcuteTaskCompletionPopUp,
  setRiskAssessmentCurrentView,
  setRiskAssessmentStateFromMemoize,
  setIsLoading,
  resetRiskAssessmentState,
} = riskAssessmentSlice.actions;
export default riskAssessmentSlice;
export const getRiskAssessmentState = (state: any): IRiskAssessmentState => state.riskAssessment;
