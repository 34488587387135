import React, { useEffect, useState } from "react";
import Button from "components/Button/button.component";
import GenericTabs from "components/GenericTabs/generic-tabs.component";
import Header from "components/Header/header.component";
import NavigatorTable from "./components/navigator-table/navigator-table.component";
import "./navigator-management.styles.scss";
import { navigatorManagementTableColumns } from "shared/constant/table";
import { useHistory, useLocation } from "react-router";
import EmptyScreen from "components/EmptyScreen/empty-screen.component";
import { useDispatch, useSelector } from "react-redux";
import Sort from "components/Sort/sort.component";
import { NavigatorsTab } from "shared/enums";
import { ActiveTabName } from "components/GenericTabs/props";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import { getAuth } from "state/feature/auth/auth.slice";
import { getNavigatorsForManagerAsync } from "state/feature/navigator/navigator.action";
import { getNavigatorState } from "state/feature/navigator/navigator.slice";
import CommonState from "components/common-state/common-state.component";
import { CommonStateType } from "components/common-state/common-state.enum";

const NavigatorManagement = () => {
  useAxiosAuthenticated();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const authState = useSelector(getAuth);
  const navigatorState = useSelector(getNavigatorState);
  const [activeTabName, setActiveTabName] = useState<ActiveTabName>(NavigatorsTab.ALL);
  const onClickTabName = (tabName: ActiveTabName) => {
    setActiveTabName(tabName);
  };

  const isEmpty = false;

  useEffect(() => {
    if (authState.user.navigatorId != "") {
      dispatch(getNavigatorsForManagerAsync({ navigatorId: authState.user.navigatorId }));
    }
  }, [dispatch, authState.user.navigatorId]);

  const getCurrentTableDataBasedOnActiveTabName = () => {
    const currentData = navigatorState.navigatorsForManager.filter((nav) => nav.email != authState.user.email);
    const loggedInNavigator = navigatorState.navigatorsForManager.find((nav) => nav.email == authState.user?.email);
    if (loggedInNavigator) {
      currentData.unshift(loggedInNavigator);
    }
    if (activeTabName === "Patient Navigators") {
      return currentData.filter((item: any) => item.navigatorType === 0);
    } else if (activeTabName === "Transition Navigators") {
      return currentData.filter((item: any) => item.navigatorType === 1);
    } else {
      return currentData;
    }
  };

  return (
    <div id="navigator-management-container">
      <Header className="navigator-management">
        <div className="header-title">Navigator Management</div>
        <div className="navigator-management-buttons">
          {!isEmpty && (
            <>
              <Button
                text="Set On-call Navigator"
                className="white-button set-on-call-navigator"
                onClick={() =>
                  history.push(`${location.pathname}/schedule`, {
                    pageName: "Set Navigator",
                  })
                }
              />
            </>
          )}
        </div>
      </Header>
      {isEmpty && (
        <EmptyScreen
          onClick={() =>
            history.push(`${location.pathname}/profile/new-navigator`, {
              pageName: "New Navigator",
            })
          }
        />
      )}
      {!isEmpty && (
        <>
          <div className="all-filters-section">
            <GenericTabs
              tabsList={[
                {
                  name: NavigatorsTab.ALL,
                },
                {
                  name: NavigatorsTab.PATIENT,
                },
                {
                  name: NavigatorsTab.TRANSITION,
                },
              ]}
              activeTabName={activeTabName}
              setActiveTabName={onClickTabName}
            />
          </div>
          {navigatorState.isLoading && (
            <div className="loading-container">
              <CommonState type={CommonStateType.LOADING} />
            </div>
          )}
          {!navigatorState.isLoading && (
            <table className="navigator-management-container-table">
              <tbody>
                <tr>
                  <td>
                    <table>
                      <thead>
                        <tr>
                          {navigatorManagementTableColumns.map(({ name, sorting }, index) => {
                            return (
                              <th key={`${name}-${index}`}>
                                <div className="navigator-management-table-header-content">
                                  <div className="name">{name}</div>
                                  <div>{sorting && <Sort />}</div>
                                </div>
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="navigator-management-table-container">
                      {navigatorState && (
                        <NavigatorTable
                          tableClassName="navigator-management-table"
                          columns={navigatorManagementTableColumns}
                          currentTableData={getCurrentTableDataBasedOnActiveTabName()}
                        />
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </>
      )}
    </div>
  );
};

export default NavigatorManagement;
