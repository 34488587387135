import React, { useEffect, useState, useRef } from "react";
import { Range, DateRangePicker } from "react-date-range";
import Button from "components/Button/button.component";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DatePickerProps } from "./props";
import Icon from "components/Icon/icon.component";
import moment from "moment";
import "./date-picker.styles.scss";
import ClickAwayListener from "react-click-away-listener";
import { isMobile } from "react-device-detect";

const DatePicker = (props: DatePickerProps) => {
  const {
    onTap,
    isOpen,
    isDisabled,
    onDateRangeSelect,
    handleClearButtonClick,
    handleApplyClick,
    initialSelectedDates,
    maxDate,
    minDate,
    dateFormat,
    staticRangeEmpty,
    onClickEvent,
    showEmptyPlaceholder,
  } = props;
  const isAbortCancelled = useRef(true);

  const [state, setState] = useState<Range[]>([
    {
      startDate: initialSelectedDates ? initialSelectedDates[0]?.startDate : moment().subtract(7, "day").toDate(),
      endDate: initialSelectedDates ? initialSelectedDates[0]?.endDate : new Date(),
      key: "selection",
    },
  ]);
  const [previousState, setPreviousState] = useState<Range[]>([
    {
      startDate: initialSelectedDates ? initialSelectedDates[0]?.startDate : moment().subtract(7, "day").toDate(),
      endDate: initialSelectedDates ? initialSelectedDates[0]?.endDate : new Date(),
      key: "selection",
    },
  ]);

  useEffect(() => {
    if (isAbortCancelled) {
      setState([
        {
          startDate: initialSelectedDates ? initialSelectedDates[0]?.startDate : moment().subtract(7, "day").toDate(),
          endDate: initialSelectedDates ? initialSelectedDates[0]?.endDate : new Date(),
          key: "selection",
        },
      ]);
      setPreviousState([
        {
          startDate: initialSelectedDates ? initialSelectedDates[0]?.startDate : moment().subtract(7, "day").toDate(),
          endDate: initialSelectedDates ? initialSelectedDates[0]?.endDate : new Date(),
          key: "selection",
        },
      ]);
    }
    return () => {
      isAbortCancelled.current = false;
    };
  }, [initialSelectedDates]);

  const handleDateRangeSelect = (item: any) => {
    if (item?.selection) {
      setState([item.selection]);
      onDateRangeSelect?.([item?.selection]);
    } else {
      setState([item?.updated]);
      onDateRangeSelect?.([item?.updated]);
    }
  };

  const handleApplyButton = (item: any) => {
    setPreviousState(item);
    handleApplyClick(item);
  };

  const handleClearButton = () => {
    const defaultDate = {
      startDate: moment().subtract(7, "day").toDate(),
      endDate: new Date(),
      key: "selection",
    };
    setState([defaultDate]);
    setPreviousState([defaultDate]);
    handleClearButtonClick?.(false);
    onTap(false);
  };

  const getDate = (date: any) => {
    return moment(date).format("LL").slice(0, -6);
  };

  const getDateInputValue = () => {
    if (showEmptyPlaceholder) {
      return "";
    } else if (dateFormat === "mm/dd/yyyy") {
      return `${moment(state[0]?.startDate).format("MM/DD/YYYY")} - ${moment(state[0]?.endDate).format("MM/DD/YYYY")}`;
    }
    return `${getDate(state[0]?.startDate)} - ${getDate(state[0]?.endDate)}`;
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        setState(previousState);
        if (isOpen) {
          onTap(false);
        }
      }}
    >
      <div id="date-picker-selector" className={`${isDisabled ? "no-pointer-events" : ""}`}>
        <input
          className="date-picker-input"
          type="text"
          readOnly
          placeholder={showEmptyPlaceholder ? "MM/DD/YYYY - MM/DD/YYYY" : "Last 7 Days (Feb 7 - Feb 12)"}
          value={getDateInputValue()}
          onClick={(e: React.MouseEvent<HTMLInputElement>) => {
            onClickEvent?.(e);
            onTap(true);
          }}
        />
        <div onClick={() => onTap(true)}>
          <Icon className="calender-icon" icon="Calender" size={15} />
        </div>
        {isOpen && (
          <div id="date-picker-container">
            <div className="date-picker-inner-container">
              <DateRangePicker
                minDate={minDate}
                maxDate={maxDate}
                onChange={(item) => handleDateRangeSelect(item)}
                moveRangeOnFirstSelection={false}
                months={isMobile ? 1 : 2}
                ranges={state}
                direction="horizontal"
                className={`date-picker ${staticRangeEmpty ? "no-right-section" : ""}`}
                dateDisplayFormat="MMM, yyyy"
                rangeColors={["#1AA99C"]}
                showMonthArrow
                inputRanges={[]}
                staticRanges={
                  staticRangeEmpty
                    ? []
                    : [
                        {
                          range: () => {
                            return {
                              startDate: moment().subtract(1, "day").toDate(),
                              endDate: new Date(),
                              color: "",
                            };
                          },
                          isSelected: (range) => {
                            return false;
                          },
                          label: "Last 24 hours",
                        },
                        {
                          range: (dateRange) => {
                            return {
                              startDate: moment().subtract(7, "day").toDate(),
                              endDate: new Date(),
                              color: "",
                            };
                          },
                          isSelected: (range) => false,
                          label: "Last 7 days",
                        },
                        {
                          range: (dateRange) => {
                            return {
                              startDate: moment().subtract(1, "month").toDate(),
                              endDate: new Date(),
                              color: "",
                            };
                          },
                          isSelected: (range) => false,
                          label: "Last 30 days",
                        },
                        {
                          range: (dateRange) => {
                            return {
                              startDate: moment().subtract(3, "month").toDate(),
                              endDate: new Date(),
                              color: "",
                            };
                          },
                          isSelected: (range) => false,
                          label: "Last 3 months",
                        },
                        {
                          range: (dateRange) => {
                            return {
                              startDate: new Date(),
                              endDate: new Date(),
                              color: "",
                            };
                          },
                          isSelected: (range) => false,
                          label: "Custom Range",
                        },
                      ]
                }
              >
                <Button text="Custom Range" className="green-button" onClick={() => {}} />
              </DateRangePicker>
              <div
                className={`date-picker-footer ${
                  staticRangeEmpty ? "buttons-with-no-right-section" : "buttons-with-right-section"
                }`}
              >
                <Button text="Clear" className="white-button cancel-button" onClick={handleClearButton} />
                <Button text="Apply" className="green-button apply-button" onClick={() => handleApplyButton(state)} />
              </div>
            </div>
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default DatePicker;
