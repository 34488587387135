import { CustomToolTip } from "components/CustomTooltip/custom-tooltip.component";
import "./style.scss";
import getHighlightedText from "components/HighLightedText/high-lighted-text.component";

type TocTasksServiceProvidersDetailsProps = {
  className?: string;
  details: string;
  isObselete?: boolean;
  notShowTooltip?: boolean;
  searchText?: string;
};

const TocTasksServiceProvidersDetails = (props: TocTasksServiceProvidersDetailsProps) => {
  const { className, details, isObselete, searchText, notShowTooltip = false } = props;
  return (
    <div className={`task-info-toc-tasks-details ${className ?? ""} ${isObselete ? "obsolete-task" : ""}`}>
      <div data-tip data-for={details} className="details-left">
        {getHighlightedText(details, searchText ?? "")}{" "}
        {notShowTooltip === false && <CustomToolTip text={details} id={details} place={"top"} />}
      </div>
      {isObselete && <div className="details-right">Outdated</div>}
    </div>
  );
};

export default TocTasksServiceProvidersDetails;
