import { IdleTimerProvider, useIdleTimer } from "react-idle-timer";
import { ReactNode, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteAllCookies } from "shared/methods/utilityFunctions";
import { TwilioClient } from "shared/services/twilio/twilioClient";
import { clearTwilioToken, getCallState, saveCurrentCall, saveTwilioCallDevice } from "state/feature/call/call.slice";
import { getCommonState, setIsSessionTimeoutModalVisible } from "state/feature/common/common.slice";
import { useSignalRService } from "shared/services/signalr/signalr.service";
import * as workerTimers from "worker-timers";
import { getAuth } from "state/feature/auth/auth.slice";
import { TIMEOUT } from "./idle-timer.constant";
import { logNavigatorAction } from "state/feature/navigator/navigator.action";
import { NavigatorActionsEnum } from "shared/enums/navigator-actions-enum";
import { getNavigatorState } from "state/feature/navigator/navigator.slice";

const IdleTimerContainer = ({ children }: { children: ReactNode }) => {
  const signalRClient = useSignalRService();
  const dispatch = useDispatch();
  const authState = useSelector(getAuth);
  const navState = useSelector(getNavigatorState);
  const { modal } = useSelector(getCommonState);
  const { currentCall, twilioCallDevice } = useSelector(getCallState);
  const handleOnIdle = () => {
    if (!modal.isSessionTimeoutModalVisible && !currentCall) {
      dispatch(setIsSessionTimeoutModalVisible(true));
      deleteAllCookies();
      if (twilioCallDevice) {
        twilioCallDevice?.disconnectAll();
        if (twilioCallDevice._state === "registered") {
          twilioCallDevice?.unregister();
        }
      }
      dispatch(
        logNavigatorAction({
          createdBy: navState.currentUserProfile?.id ?? "",
          createdByUser: `${navState.currentUserProfile?.firstName} ${navState.currentUserProfile?.lastName}`,
          type: NavigatorActionsEnum.TIME_OUT,
        })
      );
      TwilioClient.removeInstance();
      signalRClient?.disconnectSignalrOnLogout(authState.user.email);
      dispatch(clearTwilioToken());
      dispatch(saveCurrentCall(null));
      dispatch(saveTwilioCallDevice(null));
      localStorage.clear();
      sessionStorage.clear();
    }
  };

  return (
    <IdleTimerProvider
      timeout={TIMEOUT}
      onIdle={handleOnIdle}
      timers={workerTimers}
      crossTab={true}
      name={"es-userInteractionTimer"}
      syncTimers={300}
      startOnMount={false}
    >
      {children}
    </IdleTimerProvider>
  );
};

export default IdleTimerContainer;
