import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearProvidersSearch,
  getAdminState,
  setProvidersProviderGetRequest,
  setProvidersIsSearching,
} from "state/feature/admin/admin.slice";
import { debounce } from "shared/methods/utilityFunctions";
import Button from "components/Button/button.component";
import SearchBox from "components/SearchBox/search-box.component";
import "./providers-sub-header.styles.scss";
import { ProvidersTab } from "pages/admin/common/constant";

const ProvidersSubHeader = () => {
  const dispatch = useDispatch();
  const {
    providers: { activeTab, isSearching },
  } = useSelector(getAdminState);
  const [searchTextVal, setSearchTextVal] = useState("");
  const handleSearch = useCallback(
    (search: string) => {
      if (activeTab === ProvidersTab.PROVIDER) {
        dispatch(setProvidersProviderGetRequest({ searchToken: search, pageCount: 0 }));
      }
    },
    [dispatch, activeTab]
  );

  const debouncedSearch = useMemo(() => {
    return debounce(handleSearch, 500);
  }, [handleSearch]);

  useEffect(() => {
    if (!isSearching) {
      setSearchTextVal("");
    }
  }, [isSearching]);

  useEffect(() => {
    dispatch(setProvidersIsSearching(false));
    dispatch(clearProvidersSearch());
  }, [activeTab, dispatch]);

  return (
    <div className="sub-header-container">
      <div className="right-section">
        {isSearching ? (
          <SearchBox
            icon="cross"
            className="search-box"
            iconClassName="search-icon"
            SearchBoxSize={22}
            placeholder="Search"
            text={searchTextVal}
            autoFocus={false}
            showIcon={!!searchTextVal && searchTextVal.length > 0}
            onClick={() => {
              dispatch(setProvidersIsSearching(false));
              dispatch(clearProvidersSearch());
            }}
            onTextChange={(text) => {
              setSearchTextVal(text);
              debouncedSearch(text);
            }}
          />
        ) : (
          <Button
            icon="search"
            buttonSize={14}
            className="search-icon-container"
            iconClassName="search-icon"
            onClick={() => {
              dispatch(setProvidersIsSearching(true));
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ProvidersSubHeader;
