import React from "react";
import { CallingStatus } from "shared/enums/calling-status.enum";
import "./ongoing-tag.styles.scss";
import Icon from "components/Icon/icon.component";
import { CustomToolTip } from "components/CustomTooltip/custom-tooltip.component";

function OngoingTag({
  status,
  openForceQuitCallModal,
  hideCallQuitIcon,
  isOutgoingCall,
}: {
  status?: string;
  openForceQuitCallModal?: () => void;
  hideCallQuitIcon?: boolean;
  isOutgoingCall?: boolean;
}) {
  const getCrossIconUI = () => {
    return (
      <div
        data-tip
        data-for="End Call"
        className="cross-icon-container"
        onClick={() => {
          openForceQuitCallModal?.();
        }}
      >
        <CustomToolTip text="End Call" id="End Call" place="bottom" />
        <Icon icon="cross-in-circle-filled" size={20} />
      </div>
    );
  };
  return (
    <div className="ongoing-tag">
      <div className="dot" />
      {status === CallingStatus.IN_PROGRESS && <div className="ongoing-text">Ongoing</div>}
      {status === CallingStatus.RINGING &&
        (isOutgoingCall ? <div className="ongoing-text">Ongoing</div> : <div className="ongoing-text">Ringing</div>)}
      {status === CallingStatus.RECORDING_VOICEMAIL && <div className="ongoing-text">Recording Voicemail</div>}
      {!hideCallQuitIcon && getCrossIconUI()}
    </div>
  );
}

export default OngoingTag;
