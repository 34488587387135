import "./style.scss";
import { IPlanOfCareListType } from "pages/episodes/episode-details/tabs/plan-of-care/type";
import moment from "moment";
const ReadmissionCollapsible = (props: { readmissionList: Array<IPlanOfCareListType> }) => {
  const { readmissionList } = props;
  return (
    <div className="readmission-collapsible-container">
      {readmissionList.map((readmission) => {
        return (
          <div className="readmission-collapsible-card">
            <p className="readmission">{readmission.pacType}</p>
            <div className="provider">
              <span>Provider:</span>
              {readmission.provider}
            </div>
            <div className="date-container">
              <div className="admission-date">
                <span className="">Admission:</span>
                {readmission.actualAdmitDate ? moment(readmission.actualAdmitDate).format("MM/DD/YYYY") : ""}
              </div>
              {readmission.actualDischargeDate && readmission.actualDischargeDate !== "-" && (
                <div className="discharge-date">
                  <span className="">Discharge:</span>
                  {moment(readmission.actualDischargeDate).format("MM/DD/YYYY")}
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ReadmissionCollapsible;
