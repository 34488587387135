import "./note-for-stricked-task.scss";
import { NoteForStrickedTaskProps } from "./note-forstricked-task.props";
const NoteForStrickedTask = (props: NoteForStrickedTaskProps) => {
  const { doneBy, dateAndTime, reason } = props;
  return (
    <div className="note-for-stricked-task">
      <div className="column-for-stricked-task">
        <div className="heading">Done By:</div>
        <div className="value">{doneBy}</div>
      </div>
      <div className="column-for-stricked-task">
        <div className="heading">Date & Time:</div>
        <div className="value">{dateAndTime}</div>
      </div>
      <div className="column-for-stricked-task">
        <div className="heading">Reason:</div>
        <div className="value">{reason}</div>
      </div>
    </div>
  );
};

export default NoteForStrickedTask;
