import Modal from "components/Modal/modal.component";
import Button from "components/Button/button.component";
import "./online-offline-modal.styles.scss";
import ButtonLoader from "components/ButtonLoader/button-loader.component";
import { useState } from "react";
import CustomDropDown from "components/CustomDropdown/custom-dropdown.component";
import { ONLINE_STATUS_DROPDOWN_ITEMS } from "shared/constant/dropDown";
import moment from "moment";
import { OnlineOfflineStatus } from "shared/enums/online-offline.enum";
import { getNavigatorWorkTimeInUtcByTimezone } from "shared/methods/utilityFunctions";

type OnlineOfflineModalProps = {
  heading?: string;
  submitButtonTitle: string;
  isOpen: boolean;
  handleCancelClick: () => void;
  handleSubmitClick: (value: number) => void;
  isButtonLoading?: boolean;
  className?: string;
  isOnline: boolean;
  currentProfile?: any;
};

const OnlineOfflineModal = ({
  heading,
  submitButtonTitle,
  isOpen,
  handleCancelClick,
  handleSubmitClick,
  isButtonLoading = false,
  className,
  isOnline,
  currentProfile,
}: OnlineOfflineModalProps) => {
  const [minutes, setMinutes] = useState<{ key: string; name: string }>({ key: "", name: "" });
  const [error, setError] = useState(false);

  const convertDateTimeInMinutes = (time1: string, time2: string) => {
    return Math.ceil(moment(time1).diff(time2, "seconds") / 60);
  };

  const getInitialTimeForDropdown = (time: string) => {
    let timeFromDropdown = moment(time);
    if (timeFromDropdown.minutes() >= 45) {
      timeFromDropdown = timeFromDropdown.add(1, "hours").set({ minutes: 30, seconds: 0, millisecond: 0 });
    } else if (timeFromDropdown.minutes() >= 15 && timeFromDropdown.minutes() < 45) {
      timeFromDropdown = timeFromDropdown.add(1, "hours").set({ minutes: 0, seconds: 0, millisecond: 0 });
    } else {
      timeFromDropdown = timeFromDropdown.set({ minutes: 30, seconds: 0, millisecond: 0 });
    }
    return timeFromDropdown.format();
  };

  const getValueForDrodpwon = (localtime: string) => {
    const time = moment(localtime);
    return {
      name: time.format("hh:mm A"),
      key: time.format(),
    };
  };

  const getOfflineStatusMenu = () => {
    if (isOnline) {
      const { localTime: localtime, endTimeConverted } = getNavigatorWorkTimeInUtcByTimezone(
        currentProfile.timeZone,
        currentProfile.workStartHour,
        currentProfile.workStartMinute,
        currentProfile.workEndHour,
        currentProfile.workEndMinute
      );
      const endtime = moment(endTimeConverted).local().format();
      const localTime = moment(localtime).local().format();
      let convertedLocalTime = getInitialTimeForDropdown(localTime);
      const onlineOfflineDropdown: { name: string; key: string }[] = [];
      const remainingMinutesBetweenLocalAndCurrentTime = convertDateTimeInMinutes(endtime, localTime);

      if (remainingMinutesBetweenLocalAndCurrentTime <= 15) {
        onlineOfflineDropdown.push(getValueForDrodpwon(endtime));
        return onlineOfflineDropdown;
      }
      while (convertedLocalTime <= endtime) {
        onlineOfflineDropdown.push(getValueForDrodpwon(convertedLocalTime));
        convertedLocalTime = getInitialTimeForDropdown(convertedLocalTime);
      }
      return onlineOfflineDropdown;
    }

    return ONLINE_STATUS_DROPDOWN_ITEMS;
  };

  const handleSubmit = () => {
    if (isOnline) {
      const { localTime } = getNavigatorWorkTimeInUtcByTimezone(
        currentProfile.timezone,
        currentProfile.workStartHour,
        currentProfile.workStartMinute,
        currentProfile.workEndHour,
        currentProfile.workEndMinute
      );
      let min = 0;
      if (minutes.key !== "") {
        min = convertDateTimeInMinutes(minutes.key, localTime);
      }
      handleSubmitClick(min);
    } else {
      const min = minutes.key === "" ? 0 : Number(minutes.key);
      handleSubmitClick(min);
    }
  };

  return (
    <Modal isOpen={isOpen} className={`center online-offline-modal-container ${className ? className : ""}`}>
      <div className="online-offline-container">
        <div className="header">{heading}</div>
        <div className="content">
          <span className="content-field">
            Set me{" "}
            {isOnline
              ? `${OnlineOfflineStatus.OFFLINE.toLowerCase()} until`
              : `${OnlineOfflineStatus.ONLINE.toLowerCase()} for`}
          </span>

          <CustomDropDown
            placeholder=""
            dropDownMenuItems={getOfflineStatusMenu()}
            handleValueChanges={(option: any) => setMinutes(option)}
            value={minutes}
            idFieldName="key"
            dropDownBoxClass="online-offline-dropdown"
            selectionClass="online-offline-selection"
            dropDownContainerClass={`online-offline-dropdown-container ${isOnline ? "is-online-dropdown" : ""}`}
          />
          {isOnline ? "" : "mins"}
        </div>

        <div className="button-section">
          <Button
            text="Cancel"
            className="white-button cancel-button"
            onClick={() => {
              setError(false);
              setMinutes({ key: "", name: "" });
              handleCancelClick();
            }}
          />
          {isButtonLoading ? (
            <div className="green-button reload-button">
              <ButtonLoader />
            </div>
          ) : (
            <Button
              text={submitButtonTitle}
              className="green-button reload-button"
              onClick={handleSubmit}
              disabled={error || minutes.key === ""}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default OnlineOfflineModal;
