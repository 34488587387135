import { AudioPlayerProps } from "./props";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import "./player.styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { getCommonState, setPlayerVolume } from "state/feature/common/common.slice";

const Player = ({ audioUrl }: AudioPlayerProps) => {
  const dispatch = useDispatch();
  const { audioPlayerVolume } = useSelector(getCommonState);
  return (
    <div className="audio-player-section">
      <AudioPlayer
        preload="metadata"
        autoPlay
        src={audioUrl}
        hasDefaultKeyBindings={false}
        layout={"horizontal"}
        showFilledVolume
        volume={audioPlayerVolume}
        onVolumeChange={(e: any) => dispatch(setPlayerVolume(e.target.volume))}
      />
    </div>
  );
};

export default Player;
