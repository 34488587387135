import Status from "components/status/status.component";
import { getInitialsIfPictureNotPresent } from "shared/methods/taskSpecificMethods";
import "./avatar.styles.scss";
import { useSelector } from "react-redux";
import { getNavigatorState } from "state/feature/navigator/navigator.slice";
import { getNavigatorAvailabilityStatus } from "shared/methods/utilityFunctions";
import { getCommonState } from "state/feature/common/common.slice";
import { AvatarProps } from "./props";

const Avatar = (props: AvatarProps) => {
  const { personName, isPointerDisabled = true, firstName, lastName, callIconButton } = props;
  const { currentUserProfile, onCallLNavigators, holidayList } = useSelector(getNavigatorState);
  const { featureFlags } = useSelector(getCommonState);
  const initials = getInitialsIfPictureNotPresent(
    personName ? { name: personName } : { firstName: firstName, lastName: lastName }
  );
  return (
    <div className={`avatar-container ${isPointerDisabled ? "" : "pointer"}`}>
      <div>{initials}</div>
      {!callIconButton && (
        <div className="avatar-status">
          {featureFlags.inboundCalls && (
            <Status
              status={getNavigatorAvailabilityStatus(currentUserProfile, holidayList, onCallLNavigators).toLowerCase()}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Avatar;
