import { createAsyncThunk } from "@reduxjs/toolkit";
import { getConfig } from "config/config";
import { axiosInstance } from "shared/axios-instance";
import {
  ICheckOnPatientGetResponse,
  IProviderType,
  ISaveCheckOnPatientRequestPayload,
} from "state/types/check-on-patient.type";

export const getCheckOnPatientDetailsAsync = createAsyncThunk(
  "check-on-patient/getCheckOnPatientDetailsAsync",
  async (requestPayload: { intakeId: string; taskId: string }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `${getConfig().taskManagementBase}/api/v1/TOCTask/check-on-patient/${requestPayload.intakeId}/${
          requestPayload.taskId
        }`
      );
      if (response.status === 200 && response.data && response.data?.data) {
        return response.data.data as ICheckOnPatientGetResponse;
      }
      return null as null;
    } catch (error: any) {
      return rejectWithValue(error.response);
    }
  }
);

export const getProviderOptionsAsync = createAsyncThunk(
  "check-on-patient/getProviderOptionsAync",
  async (intakeId: string, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `${getConfig().efusionBase}/api/v2/ExtProvider/getallByIntake/${intakeId}/false/true/false/false/true`
      );
      if (response.status === 200 && response.data) {
        return response.data as Array<IProviderType>;
      }
      return [];
    } catch (error) {
      return rejectWithValue([]);
    }
  }
);

export const saveCheckOnPatientAsync = createAsyncThunk(
  "check-on-patient/saveCheckOnPatientAsync",
  async (requestPayload: ISaveCheckOnPatientRequestPayload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `${getConfig().taskManagementBase}/api/v1/TOCTask/check-on-patient`,
        requestPayload
      );
      return response;
    } catch (error: any) {
      const message = error?.response?.data?.errors?.[0]?.message ?? null;
      return rejectWithValue(message);
    }
  }
);

export const getTOCOffReasonsAsync = createAsyncThunk("check-on-patient/getTOCOffReasonsAsync", async () => {
  try {
    const response = await axiosInstance.get(
      `${getConfig().efusionBase}/api/v2/ExtGenericListItem?ItemGroup=TOCOffTrackReason`
    );

    if (response.status === 200 && response.data) {
      return response.data.map((res: { ID: number; ItemGroup: string | null; ItemLongName: string | null }) => {
        return { key: res?.ID?.toString() ?? "", name: res.ItemLongName ?? "" };
      });
    }
    return [];
  } catch (error: any) {
    return [];
  }
});

export const getAllFacilitiesAsync = createAsyncThunk(
  "check-on-patient/getAllFacilitiesAsync",
  async (clientId: number) => {
    try {
      const response = await axiosInstance.get(`${getConfig().efusionBase}/api/v2/ExtProvider/acuteCareFacility`, {
        params: { clientId },
      });
      return response.data;
    } catch (error) {
      return [];
    }
  }
);
