import { DaysVisit, IntakeStatus } from "shared/enums/plan-of-care.enum";
import { PlanOfCareTabRowPrentationProps } from "../plan-of-care-tab.props";
import { LOSVisitsLabel } from "shared/constant/plan-of-care.constant";
import PlanOfCareDetailsColumn from "components/plan-of-care-details-column/plan-of-care-details-column.component";
import { useDispatch, useSelector } from "react-redux";
import { getTaskManagementState } from "state/feature/task-management/task-management.slice";
import Icon from "components/Icon/icon.component";
import { CustomToolTip } from "components/CustomTooltip/custom-tooltip.component";
import {
  setIsOpenCareOfPlanDeleteConfirmationModal,
  setIsOpenPOCServiceModal,
} from "state/feature/common/common.slice";
import { setCarePlanEditId, setDeleteCarePlan } from "state/feature/plan-of-care/plan-of-care.slice";

const PlanOfCareTabRowPresentation = (props: PlanOfCareTabRowPrentationProps) => {
  const { pocLog, index, onChangeMouseHovered, isHovered } = props;
  const { browser } = useSelector(getTaskManagementState);
  const dispatch = useDispatch();
  const page = browser.pages.find(
    (el) => el.intakeId === browser.currentTab!.intakeId && el.taskId === browser.currentTab!.taskId
  )!;
  const isNewIntakeCreation = page.intakeStatusIdIfExistInCosmos ? true : false;

  return (
    <div
      className="plan-of-care-tab-log"
      onMouseDown={() => onChangeMouseHovered(true)}
      onMouseEnter={() => onChangeMouseHovered(true)}
      onMouseLeave={() => onChangeMouseHovered(false)}
    >
      <div className="left-section">
        <div className="service-name">{pocLog?.pacType ?? "-"}</div>
        <div className="service-provider">
          <span className="service-provider-name"> Provider:</span>
          {pocLog?.provider ?? "-"}
        </div>
        <div className="service-los">
          <span className="service-los-name">
            {pocLog?.daysType === DaysVisit.LOS ? LOSVisitsLabel.LOS : LOSVisitsLabel.Visits}:
          </span>
          {pocLog?.aptNo ?? "-"}
        </div>
        <PlanOfCareDetailsColumn
          pocDetails={pocLog}
          key={`${index}-${pocLog?.id}`}
          className="plan-of-care-tab-right-panel"
          openFromRightPanel={true}
        />
        <div className="date-time">
          {pocLog?.actualAdmitDate && pocLog?.actualDischargeDate
            ? `${pocLog.actualAdmitDate} | ${pocLog.actualDischargeDate}`
            : "-"}
        </div>
      </div>
      <div className="right-section">
        {isHovered &&
          isNewIntakeCreation &&
          browser &&
          browser.currentPage &&
          !(
            browser.currentPage.episode?.patientAndEpisodeDetails.intakeStatusId === IntakeStatus.CLOSED ||
            browser.currentPage.episode?.patientAndEpisodeDetails.intakeStatusId === IntakeStatus.CANCELED ||
            browser.currentPage.episode?.patientAndEpisodeDetails.intakeStatusId === IntakeStatus.DECEASED ||
            browser.currentPage.episode?.patientAndEpisodeDetails.intakeStatusId === IntakeStatus.DISCHARGED
          ) && (
            <div className="buttons">
              <div
                data-tip
                data-for={"Edit"}
                className="icon"
                onClick={() => {
                  dispatch(setCarePlanEditId(pocLog.id));
                  dispatch(setDeleteCarePlan(pocLog));
                  dispatch(setIsOpenPOCServiceModal({ isOpen: true, isEdit: true }));
                }}
              >
                <Icon icon="edit" size={15} />
                <CustomToolTip text={"Edit"} id="Edit" place={"left"} />
              </div>

              <div
                data-tip
                data-for={"Delete"}
                className="icon"
                onClick={() => {
                  dispatch(setCarePlanEditId(pocLog.id));
                  dispatch(setDeleteCarePlan(pocLog));
                  dispatch(setIsOpenCareOfPlanDeleteConfirmationModal(true));
                }}
              >
                <Icon icon="poc-delete-icon" size={15} />
                <CustomToolTip text={"Delete"} id="Delete" place={"left"} />
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default PlanOfCareTabRowPresentation;
