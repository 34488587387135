import Status from "components/status/status.component";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getDurationOfCall, getNavigatorAvailabilityStatus } from "shared/methods/utilityFunctions";
import { getCommonState } from "state/feature/common/common.slice";
import { getNavigatorState } from "state/feature/navigator/navigator.slice";
import { NavigatorDataType } from "../navigator-table/props";
import { useState } from "react";

const NavigatorRow = ({ navigator }: { navigator: NavigatorDataType }) => {
  const { featureFlags } = useSelector(getCommonState);
  const { onCallLNavigators, holidayList } = useSelector(getNavigatorState);
  const [showInboundOutboundCount, setShowInboundOutboundCount] = useState(false);
  const handleRouteToNavigator = (item: any) => {
    sessionStorage.setItem("navigatorIdForManager", item?.id);
    sessionStorage.setItem("externalId", item?.externalId);
  };

  return (
    <tr key={navigator.id}>
      <td>
        {featureFlags.inboundCalls && (
          <div className="work-hour-status">
            <Status status={getNavigatorAvailabilityStatus(navigator, holidayList, onCallLNavigators).toLowerCase()} />
          </div>
        )}
      </td>
      <td onClick={() => handleRouteToNavigator(navigator)}>
        <div className="navigator-name-container">
          <Link
            to={{
              pathname: `/dashboard/${navigator.name}`,
            }}
          >
            {navigator.name}
          </Link>
        </div>
      </td>
      <td
        onMouseEnter={() => setShowInboundOutboundCount(true)}
        onMouseLeave={() => setShowInboundOutboundCount(false)}
        className="total-calls-count"
      >
        {navigator.totalCallsCount}
      </td>
      <td>{navigator.inboundCallsCount}</td>
      <td>{navigator.outboundCallsCount}</td>
      <td>{getDurationOfCall(Number(Math.round(navigator.totalCallDuration)))}</td>
      <td>{navigator.missedCallsCount}</td>
      <td>{navigator.pendingVoicemailsCount}</td>
      <td>{navigator.unreadMessagesCount}</td>
    </tr>
  );
};

export default NavigatorRow;
