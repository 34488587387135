import Icon from "components/Icon/icon.component";
import { useSelector } from "react-redux";
import { USER_ROLE } from "shared/constant/role-constant";
import { Participant } from "shared/types/table.type";
import { getAuth } from "state/feature/auth/auth.slice";
export interface InboundCallIconsProps {
  participantDetail?: Participant;
}

const InboundCallIcons = (props: InboundCallIconsProps) => {
  const { participantDetail } = props;
  const { user } = useSelector(getAuth);

  const showIcon = () => {
    if (
      participantDetail?.status === "missed" ||
      participantDetail?.status === "busy" ||
      participantDetail?.status === "no-answer"
    ) {
      return (
        <div>
          <Icon icon="missed-call-icon" size={20} className="missed-call-icon" />
        </div>
      );
    } else {
      if (participantDetail?.isCaller) {
        return (
          <div className={user.roles[0].toLowerCase() === USER_ROLE.NAVIGATOR.toLowerCase() ? "outgoing-icon" : ""}>
            <Icon icon="outgoing-call-icon" size={20} className="outgoing-call-icon" />
          </div>
        );
      } else {
        return (
          <div>
            <Icon icon="incoming-call-icon" size={20} className="outgoing-call-icon" />
          </div>
        );
      }
    }
  };

  return showIcon();
};
export default InboundCallIcons;
