import { useState } from "react";
import { RowProps } from "../../type";
import RowPresentation from "./row.component";

const Row = (props: RowProps) => {
  const [isRowHovered, setIsRowHovered] = useState(false);

  const onHover = (value: boolean) => {
    setIsRowHovered(value);
  };

  return <RowPresentation {...props} onHover={onHover} isRowHovered={isRowHovered} />;
};
export default Row;
