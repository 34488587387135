import { useCallback, useEffect, useState } from "react";
import DelayModalPresentation from "./delay-modal.component";
import { useSelector } from "react-redux";
import { DelayStatusAction, DelayStatusDeceased, DelayStatusResult } from "state/types/risk-assessment-slice.type";
import { getAuth } from "state/feature/auth/auth.slice";
import { useHistory } from "react-router";
import { getCommonState, setIsOpenDelayModal } from "state/feature/common/common.slice";
import { useAppDispatch } from "state/store";
import {
  expandTaskBoard,
  getBrowserState,
  getCurrentPageTaskState,
  setLoadTaskList,
  setShouldPerformPostDelayAction,
  setShouldPerformPostTaskWaitingApprovalAction,
} from "state/feature/task-management/task-management.slice";
import { isEmptyString } from "shared/methods/utilityFunctions";
import { DelayOptionName } from "shared/enums/delay-options.enum";
import { getDelayAttempts, postDelayTask } from "state/feature/task-management/task-management.action";
import { DelayActionUnableToCompleteType, DelayModalProps } from "./delay-modal.types";
import { TaskPageName } from "shared/enums/page-name.enum";
import { getAssessmentState, saveAnswerId } from "state/feature/assessment/assessment.slice";
import {
  DelayActionAnswerCallBackLaterKey,
  DelayActionAnswerOtherCloseTaskKey,
  DelayActionAnswerOtherDelayTaskKey,
  DelayActionAnswerPartiallyCompletedWithNoFurtherActivityKey,
  DelayActionAnswerPatientDeclinedNavigationServicesKey,
  DelayActionAnswerPatientNotWantToDoTaskKey,
  DelayActionNoAnswerMessageLeftKey,
  DelayActionNoAnswerUnableToLeaveMessageKey,
  DelayActionUnableToCompletedDuplicateTaskKey,
  DelayActionUnableToCompletedOtherKey,
  DelayActionUnableToCompletedPatientDeceasedKey,
  DelayActionUnableToCompletedPracticeRequestKey,
  DelayActionUnableToCompletedUnableToReachKey,
  DelayResultAnswersKey,
  DelayResultNoAnswersKey,
  DelayStatusOptionKey,
  DelayResultUnableToCompleteKey,
  LastDelayAttemptStatus,
  PostDelaySubmitResponseData,
  DelayActionUnableToCompletedPatientDeclinedNavigationKey,
} from "./delay-modal.enum";
import { TaskDelayAttempt } from "state/types/task-management-slice.type";

const DelayModal = (props: DelayModalProps) => {
  const dispatch = useAppDispatch();
  const { handleSubmit } = props;
  const { user } = useSelector(getAuth);
  const { redirectURL } = useSelector(getCommonState);
  const { currentTab } = useSelector(getBrowserState);
  const {
    episode,
    delayTask: { delayAttempts, delayStatuses },
  } = useSelector(getCurrentPageTaskState(currentTab!.taskId));
  const { modal } = useSelector(getCommonState);
  const { isRedirectEnabled } = useSelector(getAssessmentState);
  const [delayStatus, setDelayStatus] = useState({ name: "", key: "" });
  const [result, setResult] = useState({ name: "", key: "" });
  const [results, setResults] = useState<DelayStatusResult[]>([]);
  const [action, setAction] = useState({ name: "", key: "" });
  const [actions, setActions] = useState<DelayStatusAction[]>([]);
  const [deceasedOptions, setDeceasedOptions] = useState<DelayStatusDeceased[]>([]);
  const [deceased, setDeceased] = useState({ name: "", key: "" });
  const [note, setNote] = useState("");
  const [reasonForDecline, setReasonForDecline] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [lastDelayAttempt, setLastDelayAttempt] = useState<TaskDelayAttempt | null>(null);
  const [isDirty, setIsDirty] = useState(false);
  const history = useHistory();
  const handleOnCancel = () => {
    setDelayStatus({ name: "", key: "" });
    setAction({ name: "", key: "" });
    setResult({ name: "", key: "" });
    setNote("");
    setIsDirty(false);
    dispatch(setIsOpenDelayModal(false));
  };

  const checkIfMarkAsClosedSelected = useCallback(() => {
    if (delayStatus.key === DelayStatusOptionKey.NO_ANSWER) {
      return (
        (result.key === DelayResultNoAnswersKey.MESSAGE_LEFT &&
          action.key === DelayActionNoAnswerMessageLeftKey.MARK_CLOSED) ||
        (result.key === DelayResultNoAnswersKey.UNABLE_TO_LEAVE_MESSAGE &&
          action.key === DelayActionNoAnswerUnableToLeaveMessageKey.MARK_CLOSED)
      );
    } else if (delayStatus.key === DelayStatusOptionKey.ANSWER) {
      if (
        result.key === DelayResultAnswersKey.PATIENT_NOT_WANT_TO_DO_TASK &&
        action.key === DelayActionAnswerPatientNotWantToDoTaskKey.MARK_TASK_CLOSED
      ) {
        return true;
      }
      if (
        result.key === DelayResultAnswersKey.PATIENT_DECLINE_NAVIGATION_SERVICE &&
        action.key === DelayActionAnswerPatientDeclinedNavigationServicesKey.MARK_TASK_CLOSED
      ) {
        return true;
      }
      if (
        result.key === DelayResultAnswersKey.PARTIALLY_COMPLETED_WITH_NO_FURTHER_ACTIVITY &&
        action.key === DelayActionAnswerPartiallyCompletedWithNoFurtherActivityKey.MARK_TASK_CLOSED
      ) {
        return true;
      }
      if (
        result.key === DelayResultAnswersKey.OTHER_DELAY_TASK &&
        action.key === DelayActionAnswerOtherDelayTaskKey.MARK_TASK_CLOSED
      ) {
        return true;
      }
      if (
        result.key === DelayResultAnswersKey.OTHER_CLOSE_TASK &&
        action.key === DelayActionAnswerOtherCloseTaskKey.MARK_TASK_CLOSED
      ) {
        return true;
      }
      return false;
    } else {
      return [
        DelayActionUnableToCompletedDuplicateTaskKey.MARK_TASK_CLOSED,
        DelayActionUnableToCompletedOtherKey.MARK_TASK_CLOSED,
        DelayActionUnableToCompletedPatientDeceasedKey.MARK_TASK_CLOSED_WITH_EPISODE_DECEASED,
        DelayActionUnableToCompletedPracticeRequestKey.MARK_TASK_CLOSED,
        DelayActionUnableToCompletedUnableToReachKey.MARK_TASK_CLOSED,
        DelayActionUnableToCompletedPatientDeclinedNavigationKey.MARK_TASK_CLOSED,
      ].includes(action.key as DelayActionUnableToCompleteType);
    }
  }, [result, action, delayStatus]);

  const checkIfNotesValid = useCallback(() => {
    if (
      lastDelayAttempt &&
      lastDelayAttempt?.status === LastDelayAttemptStatus.REJECTED &&
      checkIfMarkAsClosedSelected()
    ) {
      return lastDelayAttempt?.notes !== note && note.length > 0;
    }
    return note.length > 0;
  }, [note, lastDelayAttempt, checkIfMarkAsClosedSelected]);

  const delayTask = (taskId: string | undefined | null) => {
    const { intakeId, taskType } = currentTab!;
    if (taskId) {
      dispatch(
        postDelayTask({
          intakeId: parseInt(intakeId),
          taskId: taskId,
          taskDelaystatusId: parseInt(delayStatus.key),
          taskDelayResultId: parseInt(result.key),
          taskDelayActionId: parseInt(action.key),
          notes: note,
          createUser: `${user.firstName} ${user.lastName}`,
          createUserId: user.id,
          intakeStatusReasonId: deceased.key ? parseInt(deceased.key) : null,
          reasonForDecline: !isEmptyString(reasonForDecline) ? reasonForDecline : null,
        })
      ).then((response) => {
        dispatch(saveAnswerId(response.payload.id));
        dispatch(setLoadTaskList(true));
        dispatch(
          getDelayAttempts({
            taskId,
            intakeId: parseInt(intakeId),
          })
        )
          .then(() => {
            if (response.meta.requestStatus === "fulfilled") {
              setIsLoading(false);
              setIsDirty(false);
              handleOnCancel();
              if (taskType !== TaskPageName.ASSESSMENT || (taskType === TaskPageName.ASSESSMENT && isRedirectEnabled)) {
                history.replace(redirectURL);
              }
              setTimeout(() => dispatch(expandTaskBoard(true)), 1000);
            }
          })
          .catch(() => {
            setIsDirty(false);
            setIsLoading(false);
          });
        if (
          response.payload?.data &&
          typeof response.payload?.data === "string" &&
          !isEmptyString(response.payload.data) &&
          response.payload.data === PostDelaySubmitResponseData.SUBMITTED_FOR_CLOSE_APPROVAL
        ) {
          dispatch(setShouldPerformPostTaskWaitingApprovalAction({ taskId, performAction: true }));
        } else {
          dispatch(setShouldPerformPostDelayAction({ taskId, performAction: true }));
        }
      });
    } else {
      setIsLoading(false);
      setIsDirty(false);
    }
  };

  const handleOnSubmit = () => {
    setIsDirty(true);
    const ifTaskMarkAsClosed = checkIfMarkAsClosedSelected();
    const isNotesValid = checkIfNotesValid();
    if (!ifTaskMarkAsClosed || (ifTaskMarkAsClosed && isNotesValid)) {
      setIsLoading(true);
      handleSubmit(delayTask);
    }
  };

  const handleChangeNote = (value: string) => {
    if (isDirty) {
      setIsDirty(false);
    }
    if (!/^\s/.test(value)) {
      setNote(value);
    }
  };
  const handleChangeReasonForDecline = (value: string) => {
    if (isDirty) {
      setIsDirty(false);
    }
    if (!/^\s/.test(value)) {
      setReasonForDecline(value);
    }
  };

  const handleChange = (optionName: string, optionValue: { name: string; key: string | number }) => {
    switch (optionName) {
      case DelayOptionName.DELAY_STATUS:
        const statusInfo = delayStatuses.filter((item) => {
          return item?.status === optionValue?.name;
        })[0];
        setDelayStatus({ key: statusInfo.taskDelayStatusId.toString(), name: optionValue.name });
        setResult({ name: "", key: "" });
        setAction({ name: "", key: "" });
        setDeceased({ name: "", key: "" });
        setReasonForDecline("");
        setResults(statusInfo.result);
        break;
      case DelayOptionName.RESULT:
        const statusResultInfo = results.filter((item) => {
          return item.text === optionValue.name;
        })[0];
        setAction({ name: "", key: "" });
        if (
          episode?.patientAndEpisodeDetails?.patientNavigationDeclined &&
          episode?.patientAndEpisodeDetails?.patientNavigationDeclinedNotes &&
          !isEmptyString(episode?.patientAndEpisodeDetails?.patientNavigationDeclinedNotes) &&
          ((delayStatus.key === DelayStatusOptionKey.UNABLE_TO_COMPLETE &&
            optionValue.key.toString() === DelayResultUnableToCompleteKey.PATIENT_DECLINE_NAVIGATION_SERVICE) ||
            (delayStatus.key === DelayStatusOptionKey.ANSWER &&
              optionValue.key.toString() === DelayResultAnswersKey.PATIENT_DECLINE_NAVIGATION_SERVICE))
        ) {
          setReasonForDecline(episode?.patientAndEpisodeDetails?.patientNavigationDeclinedNotes);
        }
        if (
          delayStatus.key === DelayStatusOptionKey.UNABLE_TO_COMPLETE &&
          optionValue.key.toString() === DelayResultUnableToCompleteKey.PATIENT_DECEASED
        ) {
          if (deceasedOptions?.length === 0) {
            const deceasedChek = delayStatuses.find((status) => status.taskDelayStatusId === 3);
            const deceasedResult = deceasedChek ? deceasedChek.result?.find((res) => res.id === 1) : null;
            const checkDeceasedOptionExist = deceasedResult?.action.find(
              (val) => val.finalIntakeStatusChangeReason !== null
            );
            const newDeceasedOptions = checkDeceasedOptionExist?.finalIntakeStatusChangeReason
              ? checkDeceasedOptionExist?.finalIntakeStatusChangeReason
              : [];
            setDeceasedOptions(newDeceasedOptions);
          }
        } else {
          setDeceased({ key: "", name: "" });
        }
        setActions(statusResultInfo.action);
        setResult({ key: statusResultInfo.id.toString(), name: statusResultInfo.text });
        break;
      case DelayOptionName.ACTION:
        const statusActionInfo = actions.filter((item) => {
          return item.text === optionValue.name;
        })[0];
        setIsDirty(false);
        setAction({ key: statusActionInfo.id.toString(), name: statusActionInfo.text });
        break;

      case DelayOptionName.DECEASED:
        setDeceased({ key: optionValue.key.toString(), name: optionValue.name });
        break;

      default:
        break;
    }
  };

  const getIsDisabledSubmitButton = () => {
    return (
      isEmptyString(delayStatus.name) ||
      isEmptyString(result.name) ||
      isEmptyString(action.name) ||
      (delayStatus.key === DelayStatusOptionKey.UNABLE_TO_COMPLETE &&
        result.key === DelayResultUnableToCompleteKey.PATIENT_DECEASED &&
        isEmptyString(deceased.key))
    );
  };

  useEffect(() => {
    if (
      delayAttempts &&
      delayAttempts?.length > 0 &&
      delayAttempts[0]?.status === LastDelayAttemptStatus.REJECTED &&
      modal.isOpenDelayModal
    ) {
      const newLastDelayAttempt = delayAttempts[0];
      setLastDelayAttempt(newLastDelayAttempt ?? null);
      setNote(newLastDelayAttempt?.notes ?? "");
      const newStatus = delayStatuses?.find((obj) => {
        return obj?.taskDelayStatusId === delayAttempts[0]?.taskDelayStatusId;
      });
      if (newStatus) {
        handleChange(DelayOptionName.DELAY_STATUS, {
          key: newStatus?.taskDelayStatusId ?? "",
          name: newStatus?.status ?? "",
        });
        const newResult = newStatus?.result.find((res) => {
          return res?.id === delayAttempts[0]?.taskDelayResultId;
        });
        if (newResult) {
          setResult({ key: newResult?.id.toString() ?? "", name: newResult?.text ?? "" });
          if (
            delayStatus.key === DelayStatusOptionKey.UNABLE_TO_COMPLETE &&
            newResult.id.toString() === DelayResultUnableToCompleteKey.PATIENT_DECEASED
          ) {
            if (deceasedOptions?.length === 0) {
              const deceasedCheck = delayStatuses.find((val) => val.taskDelayStatusId === 3);
              const deceasedResult = deceasedCheck ? deceasedCheck.result?.find((res) => res.id === 1) : null;
              const checkDeceasedOptionExist = deceasedResult?.action.find(
                (val) => val.finalIntakeStatusChangeReason !== null
              );
              const newDeceasedOptions = checkDeceasedOptionExist?.finalIntakeStatusChangeReason
                ? checkDeceasedOptionExist?.finalIntakeStatusChangeReason
                : [];
              setDeceasedOptions(newDeceasedOptions);
            }
          } else {
            setDeceased({ key: "", name: "" });
          }
          setActions(newResult.action);
        }

        const newAction = newResult
          ? newResult?.action?.find((val) => val?.id === newLastDelayAttempt?.taskDelayActionId)
          : null;
        if (newAction) {
          setAction({ key: newAction?.id.toString() ?? "", name: newAction?.text ?? "" });
        }
        if (newLastDelayAttempt?.intakeStatusReasonId && newAction?.finalIntakeStatusChangeReason) {
          const newReasonForDeceased = newAction?.finalIntakeStatusChangeReason
            ? newAction.finalIntakeStatusChangeReason?.find(
                (reason) => reason?.id === newLastDelayAttempt?.intakeStatusReasonId
              )
            : null;

          setDeceased({
            key: newReasonForDeceased?.id ? newReasonForDeceased?.id?.toString() : "",
            name: newReasonForDeceased?.reasonLongName ?? "-",
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delayAttempts, delayStatuses, modal.isOpenDelayModal]);

  return (
    <DelayModalPresentation
      handleOnSubmit={handleOnSubmit}
      handleChange={handleChange}
      delayStatus={delayStatus}
      result={result}
      action={action}
      actions={actions}
      results={results}
      note={note}
      handleChangeNote={handleChangeNote}
      handleOnCancel={handleOnCancel}
      getIsDisabledSubmitButton={getIsDisabledSubmitButton}
      isLoading={isLoading}
      deceasedOptions={deceasedOptions}
      deceased={deceased}
      isMarkAsClosedSelected={checkIfMarkAsClosedSelected()}
      showNotesErrorMessage={!checkIfNotesValid() && isDirty && checkIfMarkAsClosedSelected()}
      reasonForDecline={reasonForDecline}
      handleChangeReasonForDecline={handleChangeReasonForDecline}
    />
  );
};

export default DelayModal;
