import Sort from "components/Sort/sort.component";
import { SortingOrderType } from "shared/enums/sorting-types.enums";
import Icon from "components/Icon/icon.component";
import { EpisodesTableProps, HeaderColumnInfo } from "pages/episodes/types";
import CommonState from "components/common-state/common-state.component";
import TableLoader from "components/TableLoader/table-loader.component";
import InfiniteScroll from "react-infinite-scroll-component";
import { CommonStateType } from "components/common-state/common-state.enum";
import Loading from "shared/assets/images/loader.gif";
import "./episodes-table.style.scss";
import EpisodesRow from "../episode-row/episodes-row.component";
const EpisodesTable = (props: EpisodesTableProps) => {
  const {
    isLoading,
    currentTableData,
    columnHeader,
    hasScrollbarVisible,
    isFilterSearchApplied,
    handleColumnSorting,
    onIncrementPage,
    isFilterApplied,
    onClickEpisode,
  } = props;
  const displaySortIcons = (el: HeaderColumnInfo) => {
    return el.sortOrder === SortingOrderType.DEFAULT ? (
      <Sort />
    ) : (
      <Icon icon={el.sortOrder === SortingOrderType.ASC ? "sort-top" : "sort-bottom"} size={8} />
    );
  };
  return (
    <div className={`episodes-listing-table`}>
      <div
        className={`episodes-listing-header ${hasScrollbarVisible ? "episodes-listing-header-scrollbar-visible" : ""}`}
      >
        {columnHeader.map((el, index) => (
          <div key={`${index}-${el.key}`} className="column">
            <div
              className={`column-content ${isLoading ? "column-content-disable-cursor" : ""}`}
              onClick={() => {
                if (!isLoading) {
                  handleColumnSorting(el);
                }
              }}
            >
              <div className="content">{el.name}</div>
              <span className="sort-icon">{!isLoading && displaySortIcons(el)}</span>
            </div>
          </div>
        ))}
      </div>
      <div
        className={`episodes-listing-content  ${isFilterApplied() ? "episodes-listing-content-filter-applied" : ""}`}
      >
        {isLoading && currentTableData.length === 0 && (
          <div className="empty-state-container loader">
            <img src={Loading} alt="loading" />
          </div>
        )}
        {!isLoading && isFilterSearchApplied() && currentTableData.length === 0 && (
          <CommonState type={CommonStateType.EPISODE_NOT_FOUND_ON_SEARCH} />
        )}
        {currentTableData?.length > 0 ? (
          <InfiniteScroll
            next={() => {
              onIncrementPage();
            }}
            hasMore={currentTableData.length > 0}
            loader={isLoading ? <TableLoader /> : <></>}
            height="100%"
            dataLength={currentTableData.length}
          >
            {currentTableData.map((row, index) => (
              <EpisodesRow index={index} row={row} onClickEpisode={onClickEpisode} key={`${row.id}-${index}`} />
            ))}
          </InfiniteScroll>
        ) : null}
      </div>
    </div>
  );
};

export default EpisodesTable;
