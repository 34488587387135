export enum TaskPageName {
  RISK_ASSESSMENT = "risk-assessment",
  TRANSITION_OF_CARE = "toc",
  ASSESSMENT = "assessment",
  NEW_MANUAL_TASK = "new-task",
  REMINDER_TASK = "reminder",
  NOTES_TASK = "notes",
  SEND_ORDER = "send-order",
  ADMIT_TASK = "admit-task",
  CHECK_ON_PATIENT = "check-on-patient",
}

export enum RiskAssessmentView {
  RESULT = "result",
  SUMMARY = "summary",
  FORM = "form",
}

export enum TaskListTab {
  ACTIVE = 1,
  DELAYED,
}
