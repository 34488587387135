import Button from "components/Button/button.component";
import Header from "components/Header/header.component";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import {
  clearCurrentUserDetails,
  getUserManagementState,
  setUserValueChanged,
} from "state/feature/user-management/user-management.slice";
import { AccountType } from "../common/user-management.enum";
import "./user-management-details.styles.scss";
import { useAppDispatch } from "state/store";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import { PatientFormDetails } from "./patient-form-details/patient-form-details.component";
import { NavigatorFormDetails } from "./navigator-form-details/navigator-form-details.component";
import { PhysicianFormDetails } from "./physician-form-details/physician-form-details.component";
import { getUsersTypeAsync } from "state/feature/user-management/user-management.action";
import { checkIfFeatureEnabledAsync } from "state/feature/common/common.action";
import ScreenLoader from "components/ScreenLoader/screen-loader.component";
import useUrlBlocking from "shared/hooks/use-blocking.hook";

export const UserManagementDetails = () => {
  useAxiosAuthenticated();
  const appDispatch = useAppDispatch();
  const dispatch = useDispatch();
  const history = useHistory();
  const { userId } = useParams<{ userId: string }>();
  const childRef = useRef<any>(null);
  const {
    isSavingData,
    currentUserDetails,
    isLoading,
    isLoadingPatientData,
    isPatientSelected,
    isLoadingNavigatorDataById,
    isLoadingNavigatorManagers,
    isUserValueChanged,
  } = useSelector(getUserManagementState);
  useUrlBlocking();
  const [userAccountType, setUserAccountType] = useState<AccountType>();

  useEffect(() => {
    appDispatch(getUsersTypeAsync());
  }, [appDispatch]);

  useEffect(() => {
    appDispatch(checkIfFeatureEnabledAsync({ featureFlagName: "userManagement" })).then((res: any) => {
      if (!res.payload.data) {
        history.push("/not-found");
      }
    });
  }, [appDispatch, history]);

  useEffect(() => {
    if (!localStorage.getItem("userAccountType") && !userAccountType) {
      history.push(`/user-management`);
    }
  }, [history, userAccountType]);

  useEffect(() => {
    setUserAccountType(localStorage.getItem("userAccountType") as AccountType);
  }, [history, userAccountType, setUserAccountType]);

  useEffect(() => {
    return () => {
      localStorage.removeItem("userAccountType");
    };
  }, []);

  const breadcrumb = userId
    ? [
        { label: "User Management", link: "/user-management" },
        { label: "User Account Details", link: null },
      ]
    : [
        { label: "User Management", link: "/user-management" },
        { label: "Add New User", link: null },
      ];

  const getFormViewByUserAccountType = () => {
    if (userAccountType === AccountType.PATIENT) {
      return <PatientFormDetails userID={userId} ref={childRef} />;
    }
    if (userAccountType === AccountType.NAVIGATOR) {
      return <NavigatorFormDetails userId={userId} ref={childRef} />;
    }
    if (userAccountType === AccountType.PHYSICIAN) {
      return <PhysicianFormDetails userId={userId} ref={childRef} />;
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearCurrentUserDetails());
      dispatch(setUserValueChanged(false));
    };
  }, [dispatch]);

  const isDisabledSaveButton = () => {
    if (userAccountType === AccountType.PATIENT) {
      return isLoadingPatientData || !isPatientSelected || isSavingData || !isUserValueChanged;
    } else if (userAccountType === AccountType.NAVIGATOR) {
      return isLoadingNavigatorDataById || isLoadingNavigatorManagers || isSavingData || !isUserValueChanged;
    } else {
      return isLoading || isSavingData || !isUserValueChanged;
    }
  };

  return (
    <>
      <div id="user-management-details-container">
        <Header className="user-management-details">
          <div className="breadcrumb-container">
            {breadcrumb.map((el, index) => {
              return (
                <span
                  key={el.label}
                  className={`breadcrumb-item ${!el.link ? "inactive" : "active"} ${index !== 0 ? "name" : ""}`}
                  aria-current="page"
                >
                  {el.link ? <Link to={`${el.link}`}>{el.label}</Link> : <span>{el.label}</span>}
                </span>
              );
            })}
          </div>
          <div className="header-title">
            {userId
              ? [currentUserDetails?.firstName, currentUserDetails?.middleName, currentUserDetails?.lastName].join(" ")
              : "Add New User"}
          </div>
        </Header>
        {isSavingData && <ScreenLoader isOpen={true} />}
        {getFormViewByUserAccountType()}
        <div className="footer-button-container">
          <div className="footer-button-section">
            <Button
              text="Cancel"
              className="cancel-button"
              onClick={() => {
                appDispatch(setUserValueChanged(false));
                history.goBack();
              }}
            />
            <Button
              text={userId ? "Save Changes" : "Add User"}
              className="save-button"
              onClick={() => {
                childRef.current?.saveData();
              }}
              disabled={isDisabledSaveButton()}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default UserManagementDetails;
