import { TaskListProps } from "./task-list.type";
import TaskListPresentation from "./task-list.component";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTasksAsync } from "state/feature/task-management/task-management.action";
import { TASKS_LIMIT } from "../constant";
import { getTaskBoardState, setLoadTaskList } from "state/feature/task-management/task-management.slice";
import moment from "moment";

const TaskList = (props: TaskListProps) => {
  const { tasks, activeTabName, searchText } = props;
  const dispatch = useDispatch();
  const { selectedCareManagerIds, currentTaskListTab: currentTaskTab, loadTaskList } = useSelector(getTaskBoardState);

  useEffect(() => {
    if (loadTaskList) {
      dispatch(
        getTasksAsync({
          careManagerIds: selectedCareManagerIds,
          offset: 0,
          limit: TASKS_LIMIT,
          taskType: currentTaskTab,
          currentDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
        })
      );
      dispatch(setLoadTaskList(false));
    }
  }, [loadTaskList, selectedCareManagerIds, currentTaskTab, dispatch]);

  return <TaskListPresentation searchText={searchText} tasks={tasks} activeTabName={activeTabName} />;
};

export default TaskList;
