import "./button-loader.styles.scss";
import loader from "shared/assets/images/WhiteLoader.gif";

const ButtonLoader = (props: { loaderImage?: string }) => {
  const { loaderImage } = props;
  return (
    <div className="buttonloader">
      <img className="button-loader-img" src={loaderImage ? loaderImage : loader} alt="loader" />
    </div>
  );
};

export default ButtonLoader;
