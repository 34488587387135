import CommonState from "components/common-state/common-state.component";
import { CommonStateType } from "components/common-state/common-state.enum";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { TaskPageName } from "shared/enums/page-name.enum";
import { getEpisodesSlice } from "state/feature/episodes/episodes.slice";
import { getBrowserState, getCurrentPageTaskState } from "state/feature/task-management/task-management.slice";
import "./style.scss";
import { SERVICES } from "pages/task-management/constant";
import { setIsOpenPOCServiceModal } from "state/feature/common/common.slice";
import Icon from "components/Icon/icon.component";
import PlanOfCareTabRow from "./component/plan-of-care-tab.row.container";
import { IntakeStatus } from "shared/enums/plan-of-care.enum";
import { PlanOfCareTabProps } from "./plan-of-care-tab.props";

const PlanOfCareTabPresentation = (props: PlanOfCareTabProps) => {
  const { handleOnClick } = props;
  const { currentTab, currentPage, pages } = useSelector(getBrowserState);
  const { isValidIntake, isValidatingIntake, taskType } = useSelector(getCurrentPageTaskState(currentTab!.taskId));
  const {
    episodeDetailTabs: { planOfCare },
    isLoadingEpisodeDetailTabs,
    isLoadingNewIntakeCreation,
  } = useSelector(getEpisodesSlice);
  const { intakeId, taskId } = currentTab!;

  const page = pages.find((el) => el.intakeId === intakeId && el.taskId === taskId)!;
  const isNewIntakeCreation = page?.intakeStatusIdIfExistInCosmos ? true : false;
  const dispatch = useDispatch();

  const renderPlanOfCareUI = () => {
    if (isLoadingEpisodeDetailTabs.planOfCare && planOfCare.length === 0) {
      return <CommonState type={CommonStateType.LOADING} />;
    } else if (!isLoadingEpisodeDetailTabs.planOfCare && planOfCare.length === 0) {
      return (
        <CommonState
          type={CommonStateType.NO_PLAN_OF_CARE_LIST_FOUND}
          className="plan-of-care-not-found-right-panel"
          handleAddService={() => dispatch(setIsOpenPOCServiceModal({ isOpen: true, isEdit: false }))}
          showAddTaskButton
          isNewIntakeCreation={isNewIntakeCreation}
        />
      );
    }
    return (
      <div className="plan-of-care-tab-logs">
        <div className="header">
          <div className="upcoming-task-title">{SERVICES}</div>
          {planOfCare.length > 0 &&
            !isLoadingNewIntakeCreation &&
            isNewIntakeCreation &&
            currentPage &&
            !(
              currentPage.episode?.patientAndEpisodeDetails.intakeStatusId === IntakeStatus.CLOSED ||
              currentPage.episode?.patientAndEpisodeDetails.intakeStatusId === IntakeStatus.CANCELED ||
              currentPage.episode?.patientAndEpisodeDetails.intakeStatusId === IntakeStatus.DECEASED ||
              currentPage.episode?.patientAndEpisodeDetails.intakeStatusId === IntakeStatus.DISCHARGED
            ) && (
              <div className="add-task-cta" onClick={handleOnClick}>
                <Icon icon="plus-add" size={12} className="plus-add-icon" />
                Add Service
              </div>
            )}
        </div>
        <InfiniteScroll next={() => {}} hasMore={false} loader={<></>} height="100%" dataLength={planOfCare.length}>
          {planOfCare.map((pocLog, index) => (
            <PlanOfCareTabRow pocLog={pocLog} index={index} />
          ))}
        </InfiniteScroll>
      </div>
    );
  };

  return (
    <div
      className={`plan-of-care-tab-container  ${
        isValidIntake && !isValidatingIntake && taskType !== TaskPageName.TRANSITION_OF_CARE
          ? "footer-present"
          : "footer-not-present"
      }`}
    >
      {renderPlanOfCareUI()}
    </div>
  );
};

export default PlanOfCareTabPresentation;
