import React from "react";
import "./styles.scss";
import { ActiveTabWithBottomlineProps, TabsListProps } from "./props";

const ActiveTabWithBottomLine = (props: ActiveTabWithBottomlineProps) => {
  const { tabsList, activeTabName, setActiveTabName } = props;
  const handleClick = (singleTab: TabsListProps) => {
    if (!singleTab.isDisabled) setActiveTabName(singleTab.name);
  };
  return (
    <div className="active-tab-with-bottomline-container">
      <div className="active-tab-with-bottomline-tabs">
        {tabsList?.map((singleTab) => (
          <div
            key={singleTab.name}
            className={`tabs ${singleTab.isDisabled ? "" : "allowed"} ${
              activeTabName === singleTab.name ? "active" : ""
            } ${singleTab.className ? singleTab.className : ""}`}
            onClick={() => handleClick(singleTab)}
          >
            {singleTab?.name} {singleTab?.count ? `(${singleTab?.count})` : null}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ActiveTabWithBottomLine;
