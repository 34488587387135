import { useEffect, useState } from "react";
import "./episode-details.style.scss";
import { useParams } from "react-router";
import {
  getEpisodeDetailsById,
  getEpisodePatientLastLoginAsync,
  getEpisodeTasksExistAsync,
  getIntakeStatuses,
} from "state/feature/episodes/episodes.action";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import EpisodeDetailsPresentation from "./episode-details.component";
import { EpisodeDetailsTab } from "shared/enums/episodes-details.enum";
import { ActiveTabName } from "components/GenericTabs/props";
import { useAppDispatch } from "state/store";
import { useSelector } from "react-redux";
import { getEpisodesSlice, resetEpisodeAndPatientEpisodeDetails } from "state/feature/episodes/episodes.slice";

const EpisodeDetails = () => {
  useAxiosAuthenticated();
  const { intakeId } = useParams<{ intakeId: string }>();
  const [activeTabName, setActiveTabName] = useState<ActiveTabName>(EpisodeDetailsTab.TASKS);
  const appDispatch = useAppDispatch();

  const { episodeDetails } = useSelector(getEpisodesSlice);

  useEffect(() => {
    appDispatch(getEpisodeTasksExistAsync({ intakeId }));
    appDispatch(getEpisodeDetailsById({ intakeId: intakeId })).then((res) => {
      if (res.payload) {
        appDispatch(getIntakeStatuses());
      }
    });
  }, [appDispatch, intakeId]);

  useEffect(() => {
    if (episodeDetails && episodeDetails.patientUserId && episodeDetails.patientLastLoginAt === null) {
      appDispatch(getEpisodePatientLastLoginAsync({ userId: episodeDetails.patientUserId }));
    }
  }, [episodeDetails, appDispatch]);

  useEffect(() => {
    return () => {
      appDispatch(resetEpisodeAndPatientEpisodeDetails());
    };
  }, [appDispatch]);

  const onClickTabName = (tabName: ActiveTabName) => {
    setActiveTabName(tabName);
  };

  return (
    <div
      id="episode-details-container"
      className={`${
        episodeDetails?.patientNavigationDeclined ? "episode-details-container-with-patient-declined-alert" : ""
      }`}
    >
      <EpisodeDetailsPresentation intakeId={intakeId} activeTabName={activeTabName} onClickTabName={onClickTabName} />
    </div>
  );
};
export default EpisodeDetails;
