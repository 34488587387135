import Icon from "components/Icon/icon.component";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { isEmptyString } from "shared/methods/utilityFunctions";
import { setCurrentProvidersProvider } from "state/feature/admin/admin.slice";
import { setIsOpenEditProviderModal } from "state/feature/common/common.slice";
import { IProvidersProviderType } from "state/types/admin-slice.type";

const ProviderRow = (props: IProvidersProviderType) => {
  const { id, name, priviledgeFacilities, type, providerName } = props;
  const priveledgesAt = priviledgeFacilities?.length
    ? priviledgeFacilities.map((priveledge) => priveledge?.name).join(", ")
    : "-";

  const [isRowHovered, setIsRowHovered] = useState(false);
  const dispatch = useDispatch();
  return (
    <div
      className="table-with-pagination-row"
      id={id.toString()}
      onMouseEnter={() => {
        setIsRowHovered(true);
      }}
      onMouseLeave={() => {
        setIsRowHovered(false);
      }}
    >
      <div className="column">
        <div className="content">{!isEmptyString(name.trim()) ? name : "-"}</div>
      </div>
      <div className="column">
        <div className="content">{providerName && !isEmptyString(providerName.trim()) ? providerName : "-"}</div>
      </div>
      <div className="column">
        <div className="content">{!isEmptyString(type.trim()) ? type : "-"}</div>
      </div>
      <div className="column">
        <div className="content">{priveledgesAt}</div>
      </div>
      <div className="column">
        <div className="content">
          {isRowHovered && (
            <div
              className="icon-background edit-button"
              onClick={() => {
                dispatch(setIsOpenEditProviderModal(true));
                dispatch(setCurrentProvidersProvider(props));
              }}
            >
              <Icon icon="edit" size={12} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default ProviderRow;
