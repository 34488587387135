import { render } from "react-dom";
import { ErrorBoundary } from "react-error-boundary";
import App from "./App";
import { Provider } from "react-redux";
import store from "./state/store";
import "./index.scss";
import { enableMapSet } from "immer";
import CommonState from "components/common-state/common-state.component";
import { CommonStateType } from "components/common-state/common-state.enum";

enableMapSet();

render(
  <Provider store={store}>
    <ErrorBoundary fallback={<CommonState type={CommonStateType.ACCESS_DENIED} />}>
      <App />
    </ErrorBoundary>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))                    sdsdsdsdsds                                                sdsdsds
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
