import { createAsyncThunk } from "@reduxjs/toolkit";
import { getConfig } from "config/config";
import { TocStatusEnum } from "pages/task-management/transition-of-care/constants/index.constant";
import { axiosInstance, getGoogleMapsApiInstance } from "shared/axios-instance";
import {
  ICreateNewTocPayload,
  IEditTocPayload,
  IFacility,
  IIntake,
  ITransitionOfCare,
  ITocRevisionReason,
  ITocStatus,
} from "state/types/toc-management-slice.type";

export const getTocAsync = createAsyncThunk(
  "toc-management/getTocAsync",
  async (intakeId: string, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`${getConfig().taskManagementBase}/api/v1/TransitionOfCare/${intakeId}`);

      if (response.status === 200) {
        return response.data.data.map(
          (item: any) =>
            ({
              id: item.id,
              intakeId: item.intakeId,
              clientId: item.clientId,
              noteCareManager: item.noteCareManager,
              notePhysician: item.notePhysician,
              version: item.version,
              anticipatedAcuteLOS: item.anticipatedAcuteLOS,
              createdAt: `${item.createdAt}Z`,
              approvedAt: item.reviewedAt ? `${item.reviewedAt}Z` : "",
              transitionOfCareItems: item.transitionOfCareItems,
              isExpanded: item.status === TocStatusEnum.PENDING,
              isModified: item.isModified,
              additionalNote: item.additionalNote,
              approvedBy: item.approvedBy,
              reasonId: item.reasonId,
              reason: item.reason,
              statusId: item.statusId,
              status: item.status,
              createdBy: item.createdBy,
              updatedAt: `${item.updatedAt}Z`,
              updatedBy: item.updatedBy,
            } as ITransitionOfCare)
        );
      }
      return [];
    } catch (error: any) {
      return rejectWithValue(error?.response);
    }
  }
);

export const getAllTocFacilitiesAsync = createAsyncThunk(
  "toc-management/getAllTocFacilitiesAsync",
  async (intakeId: string) => {
    const response = await axiosInstance.get(
      `${getConfig().efusionBase}/api/v2/ExtProvider/getallByIntake/${intakeId}/false/true/false/false/true`,
      { params: {} }
    );

    return response.data.map(
      (el: any) =>
        ({
          id: el.ID,
          firstName: el.FirstName,
          preferredProvider: el.PreferredProvider,
          lastName: el.LastName,
          providerName: el.ProviderName,
          providerPhone: el.ProviderPhone,
          providerAddress1: el.ProviderAddress1,
          providerCity: el.ProviderCity,
          providerState: el.ProviderState,
          providerZip: el.ProviderZip,
          distance: "",
        } as IFacility)
    );
  }
);

export const createTocAsync = createAsyncThunk(
  "toc-management/createTocAsync",
  async (payload: ICreateNewTocPayload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`${getConfig().taskManagementBase}/api/v1/TransitionOfCare`, payload);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response);
    }
  }
);

export const editTocAsync = createAsyncThunk(
  "toc-management/editTocAsync",
  async (payload: IEditTocPayload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await axiosInstance.put(`${getConfig().taskManagementBase}/api/v1/TransitionOfCare`, payload);
      return fulfillWithValue(res.data);
    } catch (error: any) {
      return rejectWithValue(error?.response);
    }
  }
);

export const getIntakeAsync = createAsyncThunk("toc-management/getIntakeAsync", async (intakeId: string) => {
  const result = await axiosInstance.get(`${getConfig().efusionBase}/api/v2/ExtIntake/${intakeId}`);
  if (result.status === 200) {
    return {
      id: result.data.ID,
      facilityId: result.data.FacilityID,
      episodeName: result.data.EpisodeLongName,
      clientId: parseInt(result.data.ClientID, 10),
    } as IIntake;
  }
  return null;
});

export const getTocHistory = createAsyncThunk("toc-management/getTocHistory", async (tocId: string) => {
  const response = await axiosInstance.get(
    `${getConfig().taskManagementBase}/api/v1/TransitionOfCare/${tocId}/history`
  );
  const item = response.data.data;
  return {
    id: item.id,
    intakeId: item.intakeId,
    clientId: item.clientId,
    noteCareManager: item.noteCareManager,
    notePhysician: item.notePhysician,
    version: item.version,
    anticipatedAcuteLOS: item.anticipatedAcuteLOS,
    createdAt: item.createdAt,
    approvedAt: item.reviewedAt,
    transitionOfCareItems: item.transitionOfCareItems,
    isExpanded: !item.reviewedAt,
    isModified: item.isModified,
    approvedBy: item.approvedBy,
    updatedAt: item.updatedAt,
    updatedBy: item.updatedBy,
  } as ITransitionOfCare;
});

export const findDistance = createAsyncThunk(
  "toc-management/findDistance",
  async (payload: {
    origins: Array<string>;
    destinations: Array<string>;
    selectedPlaces: Array<{ id: string | number; address: string }>;
  }) => {
    const { origins, destinations } = payload;
    const mapisApi = getGoogleMapsApiInstance(`${getConfig().googleMapsApiKey}`);
    const { UnitSystem } = await mapisApi.importLibrary("core");
    const { DistanceMatrixService, TravelMode } = await mapisApi.importLibrary("routes");
    const distanceMatrixService = new DistanceMatrixService();

    const response = await distanceMatrixService.getDistanceMatrix({
      origins,
      destinations,
      unitSystem: UnitSystem.IMPERIAL,
      travelMode: TravelMode.DRIVING,
    });

    return response;
  }
);

export const getTocRevisionReasons = createAsyncThunk("toc-management/getTocRevisionReasons", async () => {
  const result = await axiosInstance.get(`${getConfig().taskManagementBase}/api/v1/TransitionOfCare/revision-reasons`);

  return result.data.map(
    (el: { id: number; reason: string }) => ({ id: el.id, reason: el.reason } as ITocRevisionReason)
  );
});

export const getTocStatuses = createAsyncThunk("toc-management/getTocStatuses", async () => {
  const result = await axiosInstance.get(`${getConfig().taskManagementBase}/api/v1/TransitionOfCare/statuses`);

  return result.data.map((el: { id: number; status: string }) => ({ id: el.id, status: el.status } as ITocStatus));
});
