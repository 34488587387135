import { useSelector } from "react-redux";
import { isEmptyString } from "shared/methods/utilityFunctions";
import { getBrowserState, getCurrentPageTaskState } from "state/feature/task-management/task-management.slice";
import "./style.scss";
const AlertCollapsible = () => {
  const { currentTab } = useSelector(getBrowserState);
  const { episode } = useSelector(getCurrentPageTaskState(currentTab!.taskId));
  return (
    <div className="alert-collapsible-container-content">
      {episode &&
        episode?.statusAndAlerts &&
        episode?.statusAndAlerts.alert &&
        episode?.statusAndAlerts.alert.map((item: any) => {
          return (
            <div className="alert-collapsible-container-content-alert-item">
              <div className="alert-title">{item.alert}</div>
              <div className="alert-detail">{isEmptyString(item.detail) ? "-" : item.detail}</div>
            </div>
          );
        })}
    </div>
  );
};
export default AlertCollapsible;
