import CustomDropDown from "components/CustomDropdown/custom-dropdown.component";
import TextField from "components/TextField/text-field.component";
import SingleDatePicker from "components/single-date-picker/single-date-picker.component";
import { useSelector } from "react-redux";
import { getEpisodesSlice } from "state/feature/episodes/episodes.slice";
import { ProcedureDetailPresentationProps } from "./procedure-details.props";
import TextArea from "../components/text-area/text-area.component";
import RadioInputGroup from "../components/radio-input-group/radio-input-group.component";
import { CCN_LENGTH, CPT_LENGTH } from "shared/constant/commonConstants";
import { TextLabels } from "shared/enums/episodes.enum";
import ProcedureDetailsFooter from "./footer/footer.component";
import { getPatientFullName, isEmptyString } from "shared/methods/utilityFunctions";
import { isStringEmpty } from "shared/methods/taskSpecificMethods";
import { getNavigatorState } from "state/feature/navigator/navigator.slice";
import CommonState from "components/common-state/common-state.component";
import { CommonStateType } from "components/common-state/common-state.enum";
import { useHistory } from "react-router";
import { useRef } from "react";
import SingleSelectDropdownWithClearOption from "components/single-select-dropdown-with-clear-option/single-select-dropdown-with-clear-option.component";
import { NavigatorType } from "pages/user-management/common/user-management.enum";

const ProcedureDetailsPresentation = (props: ProcedureDetailPresentationProps) => {
  const history = useHistory();
  const patientEpisodeStatusRef = useRef<HTMLDivElement | null>(null);
  const patientCareManagerRef = useRef<HTMLDivElement | null>(null);
  const acuteCareFacilityRef = useRef<HTMLDivElement | null>(null);
  const physicianPracticeRef = useRef<HTMLDivElement | null>(null);
  const acuteDischargePhysicianRef = useRef<HTMLDivElement | null>(null);
  const admittingPhysicianRef = useRef<HTMLDivElement | null>(null);
  const {
    episodeDetailTabs: {
      procedureDetails: { metadata, details: procedureDetails },
    },
    isLoadingProcedureDetailsSubmit,
  } = useSelector(getEpisodesSlice);
  const { backupNavigators } = useSelector(getNavigatorState);
  const {
    handleSurgeonNameChange,
    handleSurgeonPracticeChange,
    handleProgramChange,
    handleEpisodeChange,
    handleCptChange,
    handleProcedureDescriptionChange,
    handleEpisodeSettingUpdate,
    handleEpisodeTypeUpdate,
    handleEpisodeCareUpdate,
    handleSurgerySiteSideChange,
    handleAcuteCareFacilityChange,
    handleCnnChange,
    handleAdmitDateChange,
    handleSurgeryDateChange,
    handleDischargeDateChange,
    handleAcuteDischargePhysicianChange,
    handlePatientCareManagerChange,
    handleIntakeStatusChange,
    handleReferralIdChange,
    handleReferrerChange,
    handleTransitionNavigatorChange,
    isChecked,
    episodeSettingInputs,
    acuteCareFacilityValueIfDisabled,
    episodeCareInputs,
    episodeTypeInputs,
    isEpisodeCareInputDisabled,
    handleSubmit,
    isLoading,
    isDisabledSubmitButton,
  } = props;

  const getMinDate = () => {
    if (
      episodeCareInputs.find((item) => item.checked && item.text === "Surgical") &&
      procedureDetails?.surgeryDate.value
    ) {
      return new Date(procedureDetails?.surgeryDate.value).setDate(
        new Date(procedureDetails?.surgeryDate.value).getDate() - 1
      );
    } else {
      if (procedureDetails?.admitDate.value) {
        return new Date(procedureDetails?.admitDate.value).setDate(
          new Date(procedureDetails?.admitDate.value).getDate() - 1
        );
      } else {
        return null;
      }
    }
  };

  return (
    <div className="procedure-details-parent-container">
      {isLoading ? (
        <CommonState type={CommonStateType.LOADING} />
      ) : (
        <>
          <div className="input-form-container">
            <div className="input-grid-container">
              <div className="input-container">
                <div className="input-wrapper">
                  <div className="dropdown-label" id={procedureDetails?.program.id}>
                    Program
                    <span className="red-color-asterisk">*</span>
                  </div>
                  <CustomDropDown
                    placeholder="Select"
                    dropDownMenuItems={metadata.programs}
                    handleValueChanges={handleProgramChange}
                    defaultValue={{
                      name: procedureDetails?.program.value.name,
                      key: procedureDetails?.program.value.id,
                    }}
                    idFieldName="key"
                    dropDownBoxClass="program-dropdown"
                    selectionClass="program-selection"
                    message="Please select one from dropdown."
                    isError={!procedureDetails?.program.isDisabled && !procedureDetails?.program.isValid}
                    isDisabled={procedureDetails?.program.isDisabled}
                  />
                  {(procedureDetails?.program.isDisabled || procedureDetails?.program.isValid) && (
                    <div className="input-with-no-error"></div>
                  )}
                </div>
              </div>
              <div className="input-container">
                <div className="input-wrapper">
                  <div className="dropdown-label" id={procedureDetails?.episode.id}>
                    Episode
                    <span className="red-color-asterisk">*</span>
                  </div>
                  <CustomDropDown
                    placeholder="Select"
                    dropDownMenuItems={metadata.episodes}
                    handleValueChanges={handleEpisodeChange}
                    value={{ name: procedureDetails?.episode.value.name, key: procedureDetails?.episode.value.id }}
                    idFieldName="key"
                    dropDownBoxClass="episode-dropdown"
                    selectionClass="episode-selection"
                    message="Please select one from dropdown."
                    isError={!procedureDetails?.episode.isDisabled && !procedureDetails?.episode.isValid}
                    isDisabled={
                      procedureDetails?.episode.isDisabled || isEmptyString(procedureDetails?.program.value.name)
                    }
                  />
                  {(procedureDetails?.episode.isDisabled || procedureDetails?.episode.isValid) && (
                    <div className="input-with-no-error"></div>
                  )}
                </div>
              </div>
              <div className="input-container">
                <div className="episode-setting-radio-input" id={procedureDetails?.episodeSettings.id}>
                  <RadioInputGroup
                    labelText="Episode Settings"
                    errorMessage={TextLabels.EPISODE_SETTING_GERROR}
                    showError={!isChecked(episodeSettingInputs) && !procedureDetails?.episodeSettings.isValid}
                    radioList={episodeSettingInputs}
                    handleInputChange={handleEpisodeSettingUpdate}
                    required
                    disabled={procedureDetails?.episodeSettings.isDisabled}
                  />
                  {procedureDetails?.episodeSettings.isValid && <div className="input-with-no-error"></div>}
                </div>
              </div>

              <div className="input-container">
                <div className="episode-type-radio-input" id={procedureDetails?.episodeType.id}>
                  <RadioInputGroup
                    labelText="Episode Type"
                    errorMessage={TextLabels.EPISODE_TYPE_ERROR}
                    showError={!isChecked(episodeTypeInputs) && !procedureDetails?.episodeType.isValid}
                    radioList={episodeTypeInputs}
                    handleInputChange={handleEpisodeTypeUpdate}
                    required
                    disabled={procedureDetails?.episodeType.isDisabled}
                  />
                  {procedureDetails?.episodeType.isValid && <div className="input-with-no-error"></div>}
                </div>
              </div>
              <div className="input-container">
                <div className="episode-type-radio-input">
                  <RadioInputGroup
                    labelText="Episode Care"
                    errorMessage={TextLabels.EPISODE_CARE_ERROR}
                    showError={!isChecked(episodeCareInputs) && !procedureDetails?.episodeCare.isValid}
                    shouldDisplayErroronDisabled
                    radioList={episodeCareInputs}
                    handleInputChange={handleEpisodeCareUpdate}
                    className="episode-care"
                    required
                    disabled={
                      !isChecked(episodeTypeInputs) ||
                      isEpisodeCareInputDisabled ||
                      procedureDetails?.episodeCare.isDisabled
                    }
                  />
                  {procedureDetails?.episodeCare.isValid && <div className="input-with-no-error"></div>}
                </div>
              </div>

              <div className="input-container">
                <div className="input-wrapper">
                  <div className="dropdown-label" id={procedureDetails?.surgerySiteSide.id}>
                    Surgery Site Side
                    <span className="red-color-asterisk">*</span>
                  </div>
                  <CustomDropDown
                    placeholder="Select"
                    dropDownMenuItems={procedureDetails?.episode.value.id ? metadata.surgerySiteSides : []}
                    handleValueChanges={handleSurgerySiteSideChange}
                    value={{
                      name: procedureDetails?.surgerySiteSide.value.name,
                      key: procedureDetails?.surgerySiteSide.value.id,
                    }}
                    idFieldName="key"
                    dropDownBoxClass="surgery-site-side-dropdown"
                    selectionClass="surgery-site-side-selection"
                    message="Please select one from dropdown."
                    isError={
                      !procedureDetails?.surgerySiteSide.isDisabled && !procedureDetails?.surgerySiteSide.isValid
                    }
                    isDisabled={procedureDetails?.surgerySiteSide.isDisabled}
                  />
                  {(procedureDetails?.surgerySiteSide.isDisabled || procedureDetails?.surgerySiteSide.isValid) && (
                    <div className="input-with-no-error"></div>
                  )}
                </div>
              </div>

              <div className="text-area-container">
                <TextArea
                  leftLabel="Procedure Description"
                  inputText={procedureDetails?.procedureDescription.value}
                  handleChange={handleProcedureDescriptionChange}
                  showError={
                    !procedureDetails?.procedureDescription.isDisabled &&
                    !procedureDetails?.procedureDescription.isValid
                  }
                  errorMessage={"Length should not be more than 500 characters."}
                  rows={7}
                  required={false}
                  isDisabled={procedureDetails?.procedureDescription.isDisabled}
                  id={procedureDetails?.procedureDescription.id}
                />
                <div className="input-with-no-error"></div>
              </div>

              <div className="input-container surgeon-episode-date-container">
                <div className="input-wrapper">
                  <div className="label" id={procedureDetails?.admitDate.id}>
                    Admission Date
                    <span className="red-color-asterisk">*</span>
                  </div>
                  <SingleDatePicker
                    required
                    defaultDate={procedureDetails?.admitDate.value}
                    minDate={null}
                    maxDate={null}
                    isDisabled={procedureDetails?.admitDate.isDisabled}
                    isError={!procedureDetails?.admitDate.isDisabled && !procedureDetails?.admitDate.isValid}
                    handleDateChange={handleAdmitDateChange}
                    popperPlacement="bottom"
                    errorMessage={"Admission date is required"}
                  />
                  {(procedureDetails?.admitDate.isDisabled || procedureDetails?.admitDate.isValid) && (
                    <div className="input-with-no-error"></div>
                  )}
                </div>
              </div>
              {episodeCareInputs.find((item) => item.checked && item.text === "Surgical") && (
                <div className="input-container">
                  <div className="input-wrapper">
                    <div className="label" id={procedureDetails?.surgeryDate.id}>
                      Surgery Date
                      <span className="red-color-asterisk">*</span>
                    </div>
                    <SingleDatePicker
                      required
                      defaultDate={procedureDetails?.surgeryDate.value}
                      minDate={
                        procedureDetails?.admitDate.value
                          ? new Date(procedureDetails?.admitDate.value).setDate(
                              new Date(procedureDetails?.admitDate.value).getDate() - 1
                            )
                          : null
                      }
                      maxDate={null}
                      isDisabled={!procedureDetails?.admitDate.value || procedureDetails?.surgeryDate.isDisabled}
                      isError={
                        !isStringEmpty(procedureDetails?.admitDate.value) && !procedureDetails?.surgeryDate.isValid
                      }
                      handleDateChange={handleSurgeryDateChange}
                      popperPlacement="bottom"
                      errorMessage={"Surgery date is required"}
                    />
                    {(!procedureDetails?.admitDate.value ||
                      procedureDetails?.surgeryDate.isDisabled ||
                      isStringEmpty(procedureDetails?.admitDate.value) ||
                      procedureDetails?.surgeryDate.isValid) && <div className="input-with-no-error"></div>}
                  </div>
                </div>
              )}
              <div className="input-container">
                <div className="input-wrapper">
                  <div className="dropdown-label" id={procedureDetails?.surgeonName.id}>
                    {episodeCareInputs.find((item) => item.checked && item.text === "Surgical")
                      ? "Surgeon"
                      : "Admitting Physician"}
                    <span className="red-color-asterisk">*</span>
                  </div>
                  <CustomDropDown
                    placeholder="Select"
                    dropDownMenuItems={metadata.surgeons}
                    handleValueChanges={handleSurgeonNameChange}
                    value={{
                      name: procedureDetails?.surgeonName.value?.name ?? "",
                      key: procedureDetails?.surgeonName.value?.id ?? "",
                    }}
                    idFieldName="key"
                    dropDownBoxClass="surgeon-admitting-physician-dropdown"
                    selectionClass="surgeon-admitting-physician-selection"
                    message="Please select one from dropdown."
                    isError={!procedureDetails?.surgeonName.isDisabled && !procedureDetails?.surgeonName.isValid}
                    isDisabled={procedureDetails?.surgeonName.isDisabled}
                    dropdownRef={admittingPhysicianRef}
                  />
                  {(procedureDetails?.surgeonName.isDisabled || procedureDetails?.surgeonName.isValid) && (
                    <div className="input-with-no-error"></div>
                  )}
                </div>
              </div>
              <div className="input-container">
                <div className="input-wrapper">
                  <div className="label" id={procedureDetails?.dischargeDate.id}>
                    Acute Discharge Date
                  </div>
                  <SingleDatePicker
                    required={false}
                    defaultDate={procedureDetails?.dischargeDate.value}
                    minDate={getMinDate()}
                    maxDate={null}
                    isDisabled={false}
                    isError={false}
                    handleDateChange={handleDischargeDateChange}
                    popperPlacement="bottom"
                  />
                  <div className="input-with-no-error"></div>
                </div>
              </div>

              <div className="input-container">
                <div className="input-wrapper">
                  <div className="dropdown-label">Acute Discharge Physician</div>
                  <CustomDropDown
                    placeholder="Select"
                    dropDownMenuItems={metadata.surgeons}
                    handleValueChanges={handleAcuteDischargePhysicianChange}
                    value={{
                      name: procedureDetails?.acuteDischargePhysician.value.name,
                      key: procedureDetails?.acuteDischargePhysician.value.id,
                    }}
                    idFieldName="key"
                    dropDownBoxClass="surgeon-admitting-physician-dropdown"
                    selectionClass="surgeon-admitting-physician-selection"
                    isError={false}
                    isDisabled={false}
                    dropdownRef={acuteDischargePhysicianRef}
                  />
                  <div className="input-with-no-error"></div>
                </div>
              </div>
              <div className="input-container">
                <div className="input-wrapper">
                  <div className="dropdown-label" id={procedureDetails?.surgeonPractice.id}>
                    Physician Practice
                  </div>
                  <CustomDropDown
                    placeholder="Select"
                    dropDownMenuItems={metadata.practices}
                    handleValueChanges={handleSurgeonPracticeChange}
                    value={{
                      name: procedureDetails?.surgeonPractice.value?.name,
                      key: procedureDetails?.surgeonPractice.value?.id,
                    }}
                    idFieldName="key"
                    dropDownBoxClass="surgeon-practice-dropdown"
                    selectionClass="surgeon-practice-selection"
                    isError={false}
                    isDisabled={procedureDetails?.surgeonPractice.isDisabled}
                    dropdownRef={physicianPracticeRef}
                  />
                </div>
              </div>
              <div className="input-container">
                <div className="input-wrapper">
                  <TextField
                    label="CPT"
                    type="text"
                    value={procedureDetails?.cpt.value}
                    setValue={(label, value) => handleCptChange(value)}
                    inputClassName="cpt-name-input"
                    placeholder="XXXXX"
                    errorMessage="Length should be equal to 5 characters."
                    showError={!procedureDetails?.cpt.isDisabled && !procedureDetails?.cpt.isValid}
                    maxLength={CPT_LENGTH}
                    disabled={procedureDetails?.cpt.isDisabled}
                    id={procedureDetails?.cpt.id}
                  />
                  {(procedureDetails?.cpt.isDisabled || procedureDetails?.cpt.isValid) && (
                    <div className="input-with-no-error"></div>
                  )}
                </div>
              </div>

              <div className="input-container">
                <div className="input-wrapper">
                  <div className="dropdown-label" id={procedureDetails?.acuteCareFacility.id}>
                    Acute Care Facility
                    <span className="red-color-asterisk">*</span>
                  </div>
                  <CustomDropDown
                    placeholder="Select"
                    dropDownMenuItems={metadata.facilities}
                    handleValueChanges={handleAcuteCareFacilityChange}
                    defaultValue={{
                      name: procedureDetails?.acuteCareFacility.isDisabled
                        ? acuteCareFacilityValueIfDisabled
                        : procedureDetails?.acuteCareFacility.value.name,
                    }}
                    idFieldName="key"
                    dropDownBoxClass="acute-care-facility-dropdown"
                    selectionClass="acute-care-facility-selection"
                    message="Please select one from dropdown."
                    isError={
                      !procedureDetails?.acuteCareFacility.isDisabled && !procedureDetails?.acuteCareFacility.isValid
                    }
                    isDisabled={procedureDetails?.acuteCareFacility.isDisabled}
                    dropdownRef={acuteCareFacilityRef}
                  />
                  {(procedureDetails?.acuteCareFacility.isDisabled || procedureDetails?.acuteCareFacility.isValid) && (
                    <div className="input-with-no-error"></div>
                  )}
                </div>
              </div>
              <div className="input-container">
                <div className="input-wrapper">
                  <TextField
                    label="CCN"
                    type="text"
                    value={procedureDetails?.ccn.value}
                    setValue={(label, val) => handleCnnChange(val)}
                    inputClassName="npi-name-input"
                    placeholder="XXXXXX"
                    errorMessage="Length should be equal to 6 characters."
                    showError={!procedureDetails?.ccn.isDisabled && !procedureDetails?.ccn.isValid}
                    disabled={procedureDetails?.ccn.isDisabled}
                    maxLength={CCN_LENGTH}
                  />
                  {(procedureDetails?.ccn.isDisabled || procedureDetails?.ccn.isValid) && (
                    <div className="input-with-no-error"></div>
                  )}
                </div>
              </div>
              <div className="input-container">
                <div className="input-wrapper">
                  <div className="dropdown-label">Patient Care Manager</div>
                  <CustomDropDown
                    placeholder="Select"
                    dropDownMenuItems={backupNavigators.map((item) => {
                      return {
                        name: `${item.firstName} ${item.lastName}`,
                        key: item.careManagerId,
                      };
                    })}
                    handleValueChanges={handlePatientCareManagerChange}
                    value={{
                      name: procedureDetails?.patientCareManager.value?.name,
                      key: procedureDetails?.patientCareManager.value.id,
                    }}
                    idFieldName="key"
                    dropDownBoxClass="surgeon-admitting-physician-dropdown"
                    selectionClass="surgeon-admitting-physician-selection"
                    isError={false}
                    isDisabled={false}
                    dropdownRef={patientCareManagerRef}
                  />
                  <div className="input-with-no-error"></div>
                </div>
              </div>
              <div className="input-container">
                <div className="input-wrapper">
                  <div className="dropdown-label">{NavigatorType.TRANSITION_NAVIGATOR}</div>
                  <CustomDropDown
                    placeholder="Select Transition Navigator"
                    dropDownMenuItems={backupNavigators
                      .filter((item) => item.navigatorType && item?.navigatorType == "1")
                      .map((item) => {
                        return {
                          name: `${item.firstName} ${item.lastName}`,
                          key: item.careManagerId,
                        };
                      })}
                    handleValueChanges={handleTransitionNavigatorChange}
                    value={{
                      name: procedureDetails?.transitionNavigator.value.name,
                      key: procedureDetails?.transitionNavigator.value.key,
                    }}
                    idFieldName="key"
                    dropDownBoxClass="surgeon-admitting-physician-dropdown"
                    selectionClass="surgeon-admitting-physician-selection"
                  />
                  <div className="input-with-no-error"></div>
                </div>
              </div>
              <div className="input-container">
                <div className="input-wrapper">
                  <div className="dropdown-label">Referral ID</div>
                  <TextField
                    label=""
                    type="text"
                    value={procedureDetails?.referralId.value}
                    placeholder="Enter Referral ID"
                    maxLength={50}
                    setValue={(label, value) => {
                      handleReferralIdChange(value);
                    }}
                  />
                  <div className="input-with-no-error"></div>
                </div>
              </div>
              <div className="input-container">
                <div className="input-wrapper">
                  <div className="dropdown-label">Referring Provider</div>
                  <SingleSelectDropdownWithClearOption
                    placeholder="Select Referring Provider"
                    searchPlaceholder=""
                    onItemSelected={handleReferrerChange}
                    dropDownMenuItems={metadata.referrals.map((refererrs) => {
                      return {
                        key: refererrs.id,
                        name: getPatientFullName({
                          firstName: refererrs?.firstName ?? undefined,
                          lastName: refererrs?.lastName ?? undefined,
                        }),
                      };
                    })}
                    value={procedureDetails?.referringProvider.value}
                    clearSelection={(item) => {
                      handleReferrerChange({ key: "", name: "" });
                    }}
                    noSearchBar={true}
                    dropDownBoxClass="navigator-dropdown-container"
                  />
                  <div className="input-with-no-error"></div>
                </div>
              </div>
            </div>

            <ProcedureDetailsFooter
              isDisabled={isLoadingProcedureDetailsSubmit || isDisabledSubmitButton}
              handleSubmit={handleSubmit}
              isLoadingButton={isLoadingProcedureDetailsSubmit}
              isLoading={isLoadingProcedureDetailsSubmit}
              handleCancel={() => {
                if (history.location.state && history.location.state === true) {
                  history.goBack();
                } else {
                  history.push("/episodes");
                }
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ProcedureDetailsPresentation;
