import React, { useCallback } from "react";
import Message from "components/Message/message.component";
import moment from "moment";
import { MessageProps } from "./types";
import InfiniteScroll from "react-infinite-scroll-component";
import "./chat-list.styles.scss";
import ConversationLoading from "components/ConversationLoading/conversation-loading.component";
import Seprator from "components/Seperator/seperator.component";
import { formatBytes } from "shared/methods/utilityFunctions";
import { useSelector } from "react-redux";
import { getAuth } from "state/feature/auth/auth.slice";
import { USER_ROLE } from "shared/constant/role-constant";
import { getNavigatorState } from "state/feature/navigator/navigator.slice";
const ChatList = ({
  messageDetails,
  endMessage,
  totalLength,
  fetchMoreData,
  isPreviewThumbnailVisible,
  cancelUpload,
  sendMessage,
  selectedParticipants,
  componentName,
  readCount,
  unreadCount,
  loading = true,
  openFrom = "",
  receiptDetailsForParticipants,
}: MessageProps) => {
  const authState = useSelector(getAuth);
  const navigatorState = useSelector(getNavigatorState);
  const allMessages = messageDetails.map((message, index, messages) => {
    let sepratorToBeShown;
    if (index === messages.length - 1) {
      sepratorToBeShown = true;
    } else {
      sepratorToBeShown =
        index <= messages.length - 1 &&
        moment(message.createdAt).format("DD/MM/YYYY") !== moment(messages[index + 1]?.createdAt).format("DD/MM/YYYY");
    }
    return {
      ...message,
      sepratorToBeShown,
    };
  });

  const getClassName = () => {
    if (openFrom !== "compose") {
      if (isPreviewThumbnailVisible) {
        return "reduce-height";
      } else {
        return "height";
      }
    } else {
      if (isPreviewThumbnailVisible) {
        return "reduce-height-compose";
      } else {
        return "compose-modal";
      }
    }
  };
  const showLoader = useCallback(() => {
    if (loading && (openFrom === "contact" || openFrom === "notification_panel" || selectedParticipants.length > 0)) {
      return <ConversationLoading />;
    }
    return;
  }, [loading, openFrom, selectedParticipants]);

  const getName = (user: any) => {
    const participantCount = selectedParticipants?.split(",").length;
    switch (openFrom) {
      case "notification_panel":
        if (participantCount > 1) {
          return user?.name;
        }
        return "";
      default:
        if (
          participantCount > 1 ||
          (authState.user.roles.includes(USER_ROLE.MANAGER) &&
            navigatorState.managerStats.selectedNavigator?.externalId !== undefined &&
            navigatorState.managerStats.selectedNavigator?.externalId != authState.user.id)
        ) {
          return user?.name;
        }
        return "";
    }
  };

  return (
    <div className={`chat-messages ${componentName ? componentName : ""} ${getClassName()}`} id="chat-messages">
      <InfiniteScroll
        dataLength={messageDetails.length}
        next={() => {
          fetchMoreData();
        }}
        style={{ display: "flex", flexDirection: "column-reverse" }}
        inverse={true}
        hasMore={messageDetails.length < totalLength}
        loader={<ConversationLoading />}
        height="100%"
        endMessage={<div className="end-message">{endMessage}</div>}
        className="messages-container-scroll"
      >
        {showLoader()}
        {allMessages.map(({ message, createdAt, user, _id, sepratorToBeShown }, index) => {
          let date;
          if (sepratorToBeShown) {
            date = moment(createdAt).calendar({
              sameDay: "[Today]",
              nextDay: "[Tomorrow]",
              nextWeek: "MM/DD/YYYY dddd",
              lastDay: "[Yesterday]",
              lastWeek: "[Last] dddd MM/DD/YYYY",
              sameElse: "MM/DD/YYYY dddd",
            });
          }

          return (
            <React.Fragment key={`${createdAt}-${index}`}>
              <Message
                id={_id}
                type={message.type}
                text={message.content}
                fileName={message.fileName}
                fileSize={formatBytes(message.fileSize)}
                time={moment(createdAt).format("hh:mm A")}
                isColored={user._id === authState.user.id}
                name={getName(user)}
                isProgressVisible={message.isProgressVisible}
                progress={message.progress}
                cancelUpload={cancelUpload}
                messageFile={message.file}
                sendMessage={sendMessage}
                isCancelled={message.isCancelled}
                readCount={readCount}
                unreadCount={unreadCount}
                index={index}
                receiptDetailsForParticipants={receiptDetailsForParticipants}
              />
              {sepratorToBeShown && <Seprator time={`${date}`} />}
            </React.Fragment>
          );
        })}
      </InfiniteScroll>
    </div>
  );
};

export default ChatList;
