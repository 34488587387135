import { createSlice } from "@reduxjs/toolkit";
import { TOAST_MESSAGES } from "pages/login/common/constants";
import { toast } from "react-toastify";
import { INotification, INotificationState } from "state/types/notification-slice-type";
import {
  acknowledgeAllUnreadNotifications,
  acknowledgeNotification,
  getNotificationCountAsync,
  getNotificationsAsync,
} from "./notification.action";

const initialState: INotificationState = {
  isError: false,
  isLoadingNotifications: false,
  isLoadingNotificationsCount: false,
  isNotificationDrawerOpen: false,
  isNotificationScreenVisible: false,
  unreadNotificationCount: 0,
  notifications: [],
  prevNotificationsCount: 0,
  internalId: "",
  shouldRefreshNotifications: false,
  runTasksPostAcknowledge: false,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    addNewNotification: (state: any, action) => {
      if (state.isNotificationDrawerOpen || state.isNotificationScreenVisible) {
        state.notifications = [action.payload, ...state.notifications];
      }
    },
    incrementUnreadNotificationCount: (state: any) => {
      state.unreadNotificationCount += 1;
    },
    resetNotifications: (state: any) => {
      state.notifications = [];
    },
    setIsNotificationDrawerOpen: (state: any, action) => {
      state.isNotificationDrawerOpen = action.payload;
    },
    setIsNotificationScreenVisible: (state: any, action) => {
      state.isNotificationScreenVisible = action.payload;
    },
    setShouldRefreshNotifications: (state: any, action) => {
      state.shouldRefreshNotifications = action.payload;
    },
    setRunTasksPostAcknowledge: (state: any, action) => {
      state.runTasksPostAcknowledge = action.payload;
    },
  },
  extraReducers: (builder) => {
    return (
      builder.addCase(getNotificationCountAsync.pending, (state: any) => {
        state.isLoadingNotificationsCount = true;
      }),
      builder.addCase(getNotificationCountAsync.fulfilled, (state: any, action) => {
        state.isLoadingNotificationsCount = false;
        state.unreadNotificationCount = action.payload.data;
      }),
      builder.addCase(getNotificationCountAsync.rejected, (state: any) => {
        state.isLoadingNotificationsCount = false;
        state.isError = true;
        toast.error(TOAST_MESSAGES.ERROR, {
          containerId: "main",
          toastId: "error",
        });
      }),
      builder.addCase(getNotificationsAsync.pending, (state: any) => {
        state.isLoadingNotifications = true;
      }),
      builder.addCase(getNotificationsAsync.fulfilled, (state: any, action) => {
        state.isLoadingNotifications = false;
        if (state.notifications && state.notifications.length > 0 && action.meta.arg.offset > 0) {
          state.prevNotificationsCount = state.notifications.length;
          state.notifications = [...state.notifications, ...action.payload.data] as INotification[];
        } else {
          state.notifications = action.payload.data as INotification[];
        }
      }),
      builder.addCase(getNotificationsAsync.rejected, (state: any) => {
        state.isLoadingNotifications = false;
        state.isError = true;
        toast.error(TOAST_MESSAGES.ERROR, {
          containerId: "main",
          toastId: "error",
        });
      }),
      builder.addCase(acknowledgeAllUnreadNotifications.pending, (state: any) => {}),
      builder.addCase(acknowledgeAllUnreadNotifications.fulfilled, (state: any) => {
        let count = 0;
        state.notifications = state.notifications.map((notification: INotification) => {
          if (!notification.isRead) {
            count++;
            return { ...notification, isRead: true };
          }
          return notification;
        });
        state.unreadNotificationCount -= count;
      }),
      builder.addCase(acknowledgeAllUnreadNotifications.rejected, (state: any) => {
        state.isError = true;
        toast.error(TOAST_MESSAGES.ERROR, {
          containerId: "main",
          toastId: "error",
        });
      }),
      builder.addCase(acknowledgeNotification.pending, (state: any) => {}),
      builder.addCase(acknowledgeNotification.fulfilled, (state: any, action) => {
        // state.unreadNotificationCount = state.unreadNotificationCount > 0 ? state.unreadNotificationCount - 1 : 0;
        state.notifications = state.notifications.map((notification: INotification) =>
          notification.internalId === action.meta.arg.internalId ? { ...notification, isRead: true } : notification
        );
        state.runTasksPostAcknowledge = action.meta.arg.runTasksPostAcknowledge;
      }),
      builder.addCase(acknowledgeNotification.rejected, (state: any) => {
        state.isError = true;
        toast.error(TOAST_MESSAGES.ERROR, {
          containerId: "main",
          toastId: "error",
        });
      })
    );
  },
});

export const {
  addNewNotification,
  incrementUnreadNotificationCount,
  setShouldRefreshNotifications,
  resetNotifications,
  setIsNotificationDrawerOpen,
  setIsNotificationScreenVisible,
  setRunTasksPostAcknowledge,
} = notificationSlice.actions;
export const getNotificationState = (state: any): INotificationState => state.notification;
export default notificationSlice;
