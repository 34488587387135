import Modal from "components/Modal/modal.component";
import Button from "components/Button/button.component";
import "./user-management-modal.styles.scss";

type UserManagementModalProps = {
  heading?: string;
  submitButtonTitle: string;
  cancelButtonTitle?: string;
  content: string;
  isOpen: boolean;
  handleCancelClick: () => void;
  handleSubmitClick: () => void;
};

const UserManagementModal = ({
  heading,
  submitButtonTitle,
  cancelButtonTitle,
  content,
  isOpen,
  handleCancelClick,
  handleSubmitClick,
}: UserManagementModalProps) => {
  return (
    <Modal isOpen={isOpen} className="center user-management-modal-container">
      <div className="save-risk-assessment-container">
        <div className="header">{heading}</div>
        <div className="content">{content}</div>
        <div className="button-section">
          <Button
            text={cancelButtonTitle ?? "Cancel"}
            className="white-button cancel-button"
            onClick={handleCancelClick}
          />
          <Button text={submitButtonTitle} className="green-button reload-button" onClick={handleSubmitClick} />
        </div>
      </div>
    </Modal>
  );
};

export default UserManagementModal;
