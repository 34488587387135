import { IActivityHistoryLog, IActivityHistoryRow } from "state/types/episodes-slice.type";

export type ActivityHistoryPresentationProps = {
  onSort: (sortKey: string, sortOrder: string) => void;
  currentTableData: Array<IActivityHistoryLog>;
  isLoading: boolean;
  selectedFilterValues: Array<string>;
  handleApplyFilter: (filter: Array<string>) => void;
  handleClearFilter: () => void;
  onIncrementPage: () => void;
};

export const PAGE_LIMIT = 10;

export type ActivityHistoryRowProps = { logData: IActivityHistoryRow; index: number; className?: string };
