import Button from "components/Button/button.component";
import { useSelector } from "react-redux";
import {
  clearPage,
  enablePageValidation,
  getRiskAssessmentState,
  setActivePage,
  setCollapsibleSectionIsDirty,
  setIsValueChanged,
  setShowPostAcuteTaskCompletionPopUp,
} from "state/feature/risk-assessment/risk-assessment.slice";
import { Pages, SaveRiskAssessmentPayload } from "pages/task-management/risk-assessment/common/risk-assessment.types";
import "./risk-footer.styles.scss";
import { RiskAssessmentView } from "shared/enums/page-name.enum";
import SummaryFooter from "../summary-view/summary-footer/summary-footer.component";
import { useCallback, useState } from "react";
import { SelectedValue } from "state/types/risk-assessment-slice.type";
import {
  getRiskAssessmentByIntakeIdAsync,
  saveRiskAssessmentAnswersAsync,
} from "state/feature/risk-assessment/risk-assessment.action";
import { getAuth } from "state/feature/auth/auth.slice";
import store, { useAppDispatch } from "state/store";
import { toast } from "react-toastify";
import GenericPopupModal from "components/Modal/GenericPopupModal/generic-popup-modal.component";
import {
  expandTaskBoard,
  getBrowserState,
  getCurrentPageTaskState,
  setIsCurrentTaskDirty,
} from "state/feature/task-management/task-management.slice";
import {
  getCommonState,
  setIsOpenDelayModal,
  setIsOpenManagerTaskApprovalModal,
} from "state/feature/common/common.slice";
import { TaskStatus } from "shared/enums/tasks-status.enum";
import { USER_ROLE } from "shared/constant/role-constant";
import { TaskFooterButtonsText } from "shared/enums/task-management-footer.enum";

const RiskAssessmentFooter = () => {
  const dispatch = useAppDispatch();
  const { isLoading } = useSelector(getCommonState);
  const [isSaveDraftModalOpen, setIsSaveDraftModalOpen] = useState(false);
  const [isClearPageModalOpen, setIsClearPageModalOpen] = useState(false);
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);

  const { user } = useSelector(getAuth);
  const riskAssessmentState = useSelector(getRiskAssessmentState);
  const { currentView, taskStatusId } = riskAssessmentState;

  const { currentTab } = useSelector(getBrowserState);
  const { patientDetails, isValidatingIntake, isValidIntake, intakeId, delayTask, taskId } = useSelector(
    getCurrentPageTaskState(currentTab!.taskId)
  );
  const { intakeDetails } = delayTask;

  const isDelayedTaskRequiresApproval =
    delayTask?.delayAttempts?.length > 0 && delayTask?.delayAttempts[0]?.requiresApproval ? true : false;

  const handleOpenManagerTaskApprovalButton = () => {
    dispatch(setIsOpenManagerTaskApprovalModal(true));
  };

  const saveAnswers = useCallback(
    async (isPublish: boolean, saveAndClose?: boolean) => {
      const { selectedValues, id, collapsibleSections, status } = store.getState().riskAssessment;
      const sectionAnswers = selectedValues.map((selectedValue) => {
        return {
          sectionId: selectedValue.sectionId,
          pageId: selectedValue.pageId,
          order: selectedValue.sectionOrder,
          answers: Object.entries(selectedValue.answers).map(([questionId, selectionValues]) => ({
            questionId,
            order: selectionValues[0].order,
            selectionValues: selectionValues,
            collapsibleSectionId: selectionValues[0].collapsibleSectionId,
            isConclusion: selectionValues[0].isConclusion,
          })),
        };
      });

      if (sectionAnswers && sectionAnswers.length > 0) {
        const payload: SaveRiskAssessmentPayload = {
          id,
          taskId,
          isLosUpdated: false,
          patientID: patientDetails?.patientId ? patientDetails?.patientId : 0,
          intakeID: parseInt(intakeId, 10),
          createUser: user.username,
          isSaveDraft: isPublish ? false : !status || status === "draft",
          sectionAnswers,
          collapsibleSections: collapsibleSections.map((x: any) => x.sectionId),
        };

        const response = await dispatch(saveRiskAssessmentAnswersAsync(payload));
        if (response && response.meta.requestStatus === "fulfilled") {
          dispatch(setIsCurrentTaskDirty({ taskId, isDirty: false }));
          if (saveAndClose) {
            toast.success("Saved successfully as a Draft.", {
              containerId: "main",
            });
          } else if (isPublish) {
            toast.success("Risk Assessment submitted successfully", {
              containerId: "main",
            });
            dispatch(getRiskAssessmentByIntakeIdAsync({ intakeId }));
            dispatch(setShowPostAcuteTaskCompletionPopUp(true));
          }
        }
      }
    },
    [dispatch, intakeId, user.username, patientDetails?.patientId, taskId]
  );

  const checkIfAtLeastOneQuestionIsAnswered = useCallback(() => {
    const { selectedValues, pages } = riskAssessmentState;
    const currentPage = Object.values(pages).find((pageEl: any) => pageEl.isCurrent);
    const atLeastOneQuestionAnswered =
      selectedValues.find((section: SelectedValue) => section.pageId === (currentPage as any).tab.toString()) !==
      undefined;

    return atLeastOneQuestionAnswered;
  }, [riskAssessmentState]);

  const handleSaveDraftConfirmationModal = useCallback(
    (isOpen: boolean) => {
      if (!isOpen) {
        setIsSaveDraftModalOpen(isOpen);
        return;
      }

      if (checkIfAtLeastOneQuestionIsAnswered() && isOpen) {
        setIsSaveDraftModalOpen(isOpen);
      }
    },
    [checkIfAtLeastOneQuestionIsAnswered]
  );

  const handleClearPageConfirmationModal = useCallback(
    (isOpen: boolean) => {
      if (!isOpen) {
        setIsClearPageModalOpen(isOpen);
        return;
      }
      if (checkIfAtLeastOneQuestionIsAnswered() && isOpen) {
        setIsClearPageModalOpen(isOpen);
      }
    },
    [checkIfAtLeastOneQuestionIsAnswered]
  );

  const onNext = useCallback(
    (nextPage: Pages) => {
      if (taskStatusId !== TaskStatus.CLOSED) {
        saveAnswers(false);
      }
      dispatch(setActivePage(nextPage));
    },
    [saveAnswers, dispatch, taskStatusId]
  );

  const onPrevious = useCallback(
    (previousPage: Pages) => {
      if (taskStatusId !== TaskStatus.CLOSED) {
        saveAnswers(false);
      }
      dispatch(setActivePage(previousPage));
    },
    [saveAnswers, dispatch, taskStatusId]
  );

  const handleSubmitConfirmationModal = useCallback((isOpen: boolean) => {
    setIsSubmitModalOpen(isOpen);
  }, []);

  const isSaveDraftDisabled = () => {
    return !(checkIfAtLeastOneQuestionIsAnswered() && riskAssessmentState.isValueChanged);
  };

  if (isValidIntake && !isValidatingIntake && patientDetails) {
    if (!patientDetails.isRiskAssessmentTaskAdded) {
      return <></>;
    }

    if (currentView === RiskAssessmentView.SUMMARY) {
      return <SummaryFooter />;
    }
    if (currentView === RiskAssessmentView.FORM) {
      return (
        <>
          {isSubmitModalOpen && (
            <GenericPopupModal
              heading="Submit"
              submitButtonTitle="Submit"
              content="Are you sure you want to submit the form?"
              isOpen={isSubmitModalOpen}
              handleCancelClick={() => handleSubmitConfirmationModal(false)}
              handleSubmitClick={() => {
                handleSubmitConfirmationModal(false);
                saveAnswers(true);
                dispatch(setIsValueChanged(false));
              }}
              cancelButtonVisible
            />
          )}
          {isSaveDraftModalOpen && (
            <GenericPopupModal
              heading="Save as Draft"
              submitButtonTitle="Confirm"
              content="Do you want save this as a Draft?"
              isOpen={isSaveDraftModalOpen}
              handleCancelClick={() => handleSaveDraftConfirmationModal(false)}
              handleSubmitClick={() => {
                handleSaveDraftConfirmationModal(false);
                dispatch(setIsCurrentTaskDirty({ taskId, isDirty: false }));
                saveAnswers(false, true);
              }}
              cancelButtonVisible
            />
          )}
          {isClearPageModalOpen && (
            <GenericPopupModal
              heading="Clear selections"
              submitButtonTitle="Clear"
              content="Are you sure you want to clear the selections for this page?"
              isOpen={isClearPageModalOpen}
              handleCancelClick={() => setIsClearPageModalOpen(false)}
              handleSubmitClick={() => {
                setIsClearPageModalOpen(false);
                dispatch(clearPage());
                dispatch(setIsValueChanged(true));
              }}
              cancelButtonVisible
            />
          )}
          <div className="footer-button-container">
            <div className="questions-footer-button-section">
              <div className="footer-buttons-div-container">
                {intakeDetails && taskStatusId !== TaskStatus.CLOSED && !isDelayedTaskRequiresApproval && (
                  <Button
                    text={TaskFooterButtonsText.DELAY}
                    className="delay-button"
                    disabled={isLoading}
                    onClick={() => {
                      dispatch(expandTaskBoard(false));
                      dispatch(setIsOpenDelayModal(true));
                    }}
                  />
                )}
                {intakeDetails && taskStatusId !== TaskStatus.CLOSED && isDelayedTaskRequiresApproval && (
                  <Button
                    text={TaskFooterButtonsText.MANAGER_APPROVAL}
                    className="manager-approval-button"
                    onClick={handleOpenManagerTaskApprovalButton}
                    disabled={!user.roles.includes(USER_ROLE.MANAGER)}
                  />
                )}
                {riskAssessmentState.pages.general.isCurrent && (
                  <>
                    {taskStatusId !== TaskStatus.CLOSED && (
                      <Button
                        text={TaskFooterButtonsText.SAVE_DRAFT}
                        className="save-close-button"
                        disabled={isSaveDraftDisabled()}
                        onClick={() => {
                          handleSaveDraftConfirmationModal(true);
                        }}
                      />
                    )}
                    <Button
                      text={TaskFooterButtonsText.NEXT}
                      className="next-button"
                      disabled={
                        riskAssessmentState.isLoading ||
                        riskAssessmentState.pages.general.sectionDetails.length === 0 ||
                        riskAssessmentState.pages.general.sectionDetails?.[0].questions.length === 0
                      }
                      onClick={() => {
                        onNext(Pages.Lace);
                        if (taskStatusId !== TaskStatus.CLOSED) {
                          dispatch(enablePageValidation(Pages.General));
                          dispatch(setCollapsibleSectionIsDirty({ pageId: Pages.General, isDirty: true }));
                        }
                      }}
                    />
                    {riskAssessmentState.pages.general.isValidationEnabled &&
                      riskAssessmentState.pages.general.unAnsweredSections.length > 0 && (
                        <div className="unattempted-questions">There are Unattempted question(s)</div>
                      )}
                  </>
                )}
                {riskAssessmentState.pages.lace.isCurrent && (
                  <>
                    {taskStatusId !== TaskStatus.CLOSED && (
                      <Button
                        text={TaskFooterButtonsText.SAVE_DRAFT}
                        className="save-close-button"
                        disabled={isSaveDraftDisabled()}
                        onClick={() => {
                          handleSaveDraftConfirmationModal(true);
                        }}
                      />
                    )}
                    <Button
                      text={TaskFooterButtonsText.PREVIOUS}
                      className="previous-button"
                      onClick={() => {
                        onPrevious(Pages.General);
                      }}
                    />
                    <Button
                      text={TaskFooterButtonsText.NEXT}
                      className="next-button"
                      disabled={
                        riskAssessmentState.isLoading ||
                        riskAssessmentState.pages.lace.sectionDetails.length === 0 ||
                        riskAssessmentState.pages.lace.sectionDetails?.[0].questions.length === 0
                      }
                      onClick={() => {
                        onNext(Pages.Additional);
                        if (taskStatusId !== TaskStatus.CLOSED) {
                          dispatch(enablePageValidation(Pages.Lace));
                          dispatch(setCollapsibleSectionIsDirty({ pageId: Pages.Lace, isDirty: true }));
                        }
                      }}
                    />
                    {riskAssessmentState.pages.lace.isValidationEnabled &&
                      riskAssessmentState.pages.lace.unAnsweredSections.length > 0 && (
                        <div className="unattempted-questions">There are Unattempted question(s)</div>
                      )}
                  </>
                )}
                {riskAssessmentState.pages.additional.isCurrent && (
                  <>
                    {taskStatusId !== TaskStatus.CLOSED && (
                      <Button
                        text={TaskFooterButtonsText.SAVE_DRAFT}
                        className="save-close-button"
                        disabled={isSaveDraftDisabled()}
                        onClick={() => {
                          handleSaveDraftConfirmationModal(true);
                        }}
                      />
                    )}
                    <Button
                      text={TaskFooterButtonsText.PREVIOUS}
                      className="previous-button"
                      onClick={() => {
                        onPrevious(Pages.Lace);
                      }}
                    />
                    <Button
                      text={TaskFooterButtonsText.NEXT}
                      className="next-button"
                      disabled={
                        riskAssessmentState.isLoading ||
                        riskAssessmentState.pages.additional.sectionDetails.length === 0 ||
                        riskAssessmentState.pages.additional.sectionDetails?.[0].questions.length === 0
                      }
                      onClick={() => {
                        onNext(Pages.Closing);
                        if (taskStatusId !== TaskStatus.CLOSED) {
                          dispatch(enablePageValidation(Pages.Additional));
                          dispatch(setCollapsibleSectionIsDirty({ pageId: Pages.Additional, isDirty: true }));
                        }
                      }}
                    />
                    {riskAssessmentState.pages.additional.isValidationEnabled &&
                      riskAssessmentState.pages.additional.unAnsweredSections.length > 0 && (
                        <div className="unattempted-questions">There are unattempted question(s)</div>
                      )}
                  </>
                )}
                {riskAssessmentState.pages.closing.isCurrent && (
                  <>
                    {taskStatusId !== TaskStatus.CLOSED && (
                      <Button
                        text={TaskFooterButtonsText.SAVE_DRAFT}
                        className="save-close-button"
                        disabled={isSaveDraftDisabled()}
                        onClick={() => {
                          handleSaveDraftConfirmationModal(true);
                        }}
                      />
                    )}
                    <Button
                      text={TaskFooterButtonsText.PREVIOUS}
                      className="previous-button"
                      onClick={() => {
                        onPrevious(Pages.Additional);
                      }}
                    />
                    {taskStatusId !== TaskStatus.CLOSED && (
                      <Button
                        text={TaskFooterButtonsText.SUBMIT}
                        className="next-button"
                        onClick={() => {
                          dispatch(enablePageValidation(Pages.Closing));
                          dispatch(setCollapsibleSectionIsDirty({ pageId: Pages.Closing, isDirty: true }));
                          handleSubmitConfirmationModal(true);
                        }}
                      />
                    )}
                    {riskAssessmentState.pages.closing.isValidationEnabled &&
                      riskAssessmentState.pages.closing.unAnsweredSections.length > 0 && (
                        <div className="unattempted-questions">There are unattempted question(s)</div>
                      )}
                  </>
                )}
                {taskStatusId !== TaskStatus.CLOSED && (
                  <Button
                    text={TaskFooterButtonsText.CLEAR}
                    className="no-border-button clear-button"
                    disabled={!checkIfAtLeastOneQuestionIsAnswered()}
                    onClick={() => {
                      handleClearPageConfirmationModal(true);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      );
    }
    if (currentView === RiskAssessmentView.RESULT) {
      return <></>;
    }
  }

  return <></>;
};

export default RiskAssessmentFooter;
