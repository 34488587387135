import { NavigatorMessageType } from "./props";
import Row from "./row.component";
import Sort from "../../../../components/Sort/sort.component";
import { useSelector } from "react-redux";
import "./message-table.styles.scss";
import { USER_ROLE } from "shared/constant/role-constant";
import { getNavigatorState } from "state/feature/navigator/navigator.slice";
import { getAuth } from "state/feature/auth/auth.slice";
import { isMobile } from "react-device-detect";
import { SortingOrderType } from "shared/enums/sorting-types.enums";
import Icon from "components/Icon/icon.component";
import InfiniteScroll from "react-infinite-scroll-component";
import TableLoader from "components/TableLoader/table-loader.component";
import CommonState from "components/common-state/common-state.component";
import { CommonStateType } from "components/common-state/common-state.enum";
import { useEffect } from "react";
import { getCommonState } from "state/feature/common/common.slice";
import { ColumnType } from "shared/types/table.type";

const MessageTable = ({
  tableClassName,
  columns,
  conversations,
  activeToggleTitle,
  searchText,
  isReplyButtonDisabled,
  getMoreMessages,
  handleColumnSort,
  incrementPage,
  isLoading,
}: {
  tableClassName: string;
  columns: ColumnType[];
  conversations: NavigatorMessageType[];
  activeToggleTitle: string;
  searchText?: string;
  isReplyButtonDisabled?: boolean;
  getMoreMessages?: () => void;
  handleColumnSort?: (column: ColumnType) => void;
  incrementPage: () => void;
  isLoading?: boolean;
}) => {
  const { user } = useSelector(getAuth);
  const { isLoading: isNavigatorsLoading } = useSelector(getNavigatorState);
  const userName = `${user.firstName} ${user.lastName}`;
  const commonState = useSelector(getCommonState);
  const { twilioClientRef } = commonState.globalValues;

  useEffect(() => {
    twilioClientRef.addListener("messageAdded", getMoreMessages);
    return () => {
      if (twilioClientRef) twilioClientRef.removeListener("messageAdded", getMoreMessages);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const displaySortIcons = (column: ColumnType) => {
    return column.sortOrder === SortingOrderType.DEFAULT ? (
      <Sort />
    ) : (
      <Icon icon={column.sortOrder === SortingOrderType.ASC ? "sort-top" : "sort-bottom"} size={8} />
    );
  };

  const columnName = (column: ColumnType) => {
    const shouldDisplaySortIcons = !isLoading;
    if (column.name !== "" && column.name !== "Message") {
      return (
        <div
          className={`navigator-message-table-header-content ${
            isLoading && "navigator-message-table-header-content-disable-cursor"
          }`}
          onClick={() => {
            if (shouldDisplaySortIcons) {
              handleColumnSort?.(column);
            }
          }}
        >
          <div className="table-header-content-name">{column.name}</div>

          {shouldDisplaySortIcons && displaySortIcons(column)}
        </div>
      );
    }
    return column.name;
  };

  return (
    <>
      <table className={tableClassName}>
        <thead>
          <tr className={`navigator-message-table-header ${isMobile ? "hidden" : ""}`}>
            <td>
              <table>
                <tbody>
                  <tr>
                    {columns.map((column) => {
                      if (!(activeToggleTitle === "Practice" && column.name === "Patient")) {
                        return <th key={column.key}>{columnName(column)}</th>;
                      }
                    })}
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </thead>
        <tbody className={isMobile ? "navigator-messages-mobile-data" : ""}>
          <tr className="message-no-border-in-mobile">
            <td>
              <InfiniteScroll
                next={incrementPage}
                hasMore={conversations.length > 0}
                loader={isLoading ? <TableLoader /> : <></>}
                height="100%"
                dataLength={conversations.length}
              >
                <table>
                  <tbody className="navigator-message-table-body">
                    {(isLoading || isNavigatorsLoading) && conversations.length === 0 && (
                      <tr>
                        <td>
                          <div className="loading-container">
                            <CommonState type={CommonStateType.LOADING} />
                          </div>
                        </td>
                      </tr>
                    )}
                    {!isLoading && !isNavigatorsLoading && conversations.length === 0 && (
                      <tr className="no-message-container">
                        <td className="no-message-td" colSpan={isMobile ? 3 : 5}>
                          <div className="no-messages">No Messages Found</div>
                        </td>
                      </tr>
                    )}
                    {conversations &&
                      conversations.length > 0 &&
                      conversations.map((conversation) => {
                        return (
                          <Row
                            key={conversation.conversationId}
                            conversation={conversation}
                            activeToggleTitle={activeToggleTitle}
                            searchText={searchText}
                            isReplyButtonDisabled={
                              isReplyButtonDisabled ||
                              user.roles[0] != USER_ROLE.MANAGER ||
                              conversation?.participants.includes(userName)
                            }
                          />
                        );
                      })}
                  </tbody>
                </table>
              </InfiniteScroll>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default MessageTable;
