import Button from "components/Button/button.component";
import { useSelector } from "react-redux";
import { getCommonState } from "state/feature/common/common.slice";
import Modal from "../modal.component";
import CustomDropDown from "components/CustomDropdown/custom-dropdown.component";
import "./delay-modal.styles.scss";
import { isEmptyString } from "shared/methods/utilityFunctions";
import moment from "moment";
import { getBrowserState, getCurrentPageTaskState } from "state/feature/task-management/task-management.slice";
import { DelayModalComponentProps } from "./delay-modal.types";
import { DelayStatusOptionKey, DelayResultUnableToCompleteKey, DelayResultAnswersKey } from "./delay-modal.enum";
import Error from "components/error-message/error-message.component";
import { delayNotesMandatoryWhenClosingTaskErrorMessage } from "./delay-modal-constants";

function DelayModalPresentation(props: DelayModalComponentProps) {
  const { modal } = useSelector(getCommonState);
  const { currentTab } = useSelector(getBrowserState);
  const {
    delayTask: { delayAttempts, delayStatuses },
  } = useSelector(getCurrentPageTaskState(currentTab!.taskId));
  const {
    handleOnSubmit,
    delayStatus,
    handleChange,
    result,
    action,
    actions,
    results,
    deceasedOptions,
    handleChangeNote,
    handleOnCancel,
    note,
    getIsDisabledSubmitButton,
    deceased,
    isLoading,
    isMarkAsClosedSelected,
    showNotesErrorMessage,
    handleChangeReasonForDecline,
    reasonForDecline,
  } = props;

  const isPatientDeceasedResult =
    delayStatus.key === DelayStatusOptionKey.UNABLE_TO_COMPLETE &&
    result.key === DelayResultUnableToCompleteKey.PATIENT_DECEASED;

  const isPatientDeclinedNavigation =
    (delayStatus.key === DelayStatusOptionKey.ANSWER &&
      result.key === DelayResultAnswersKey.PATIENT_DECLINE_NAVIGATION_SERVICE) ||
    (delayStatus.key === DelayStatusOptionKey.UNABLE_TO_COMPLETE &&
      result.key === DelayResultUnableToCompleteKey.PATIENT_DECLINE_NAVIGATION_SERVICE);
  return (
    <Modal isOpen={modal.isOpenDelayModal} className="center delay-popup">
      <div
        className={`delay-container ${
          delayAttempts && delayAttempts.length > 0 ? "with-delay-attempts" : "no-delay-attempts"
        } ${isPatientDeceasedResult ? "result-patient-deceased" : ""}`}
      >
        <div>
          <div className="header">Cannot Complete Task</div>
          <div className="content">
            <div className="delay-dropdown-content-title">Delay Status</div>
            <CustomDropDown
              placeholder="Select"
              dropDownMenuItems={delayStatuses.map((item) => {
                return {
                  key: item.id,
                  name: item.status,
                };
              })}
              handleValueChanges={(option) => {
                handleChange("delay-status", { key: option.key ?? "", name: option.name });
              }}
              value={delayStatus}
              idFieldName="key"
              dropDownBoxClass="status-dropdown"
              selectionClass="status-selection"
              dropDownContainerClass={`status-dropdown-container`}
              isDisabled={false}
            />
            <div className="delay-dropdown-content-title">Result</div>
            <CustomDropDown
              placeholder="Select"
              dropDownMenuItems={results.map((item) => {
                return {
                  name: item.text,
                  key: item.id,
                };
              })}
              handleValueChanges={(option: any) => {
                handleChange("result", option);
              }}
              value={result}
              idFieldName="key"
              dropDownBoxClass="result-dropdown"
              selectionClass="result-selection"
              dropDownContainerClass={`result-dropdown-container`}
              isDisabled={isEmptyString(delayStatus.key)}
            />
            {isPatientDeceasedResult && (
              <>
                <div className="delay-dropdown-content-title">Deceased in What Phase</div>
                <CustomDropDown
                  placeholder="Select"
                  dropDownMenuItems={deceasedOptions.map((deceasedoption) => {
                    return { key: deceasedoption.id.toString(), name: deceasedoption.reasonLongName };
                  })}
                  handleValueChanges={(option: any) => {
                    handleChange("deceased", option);
                  }}
                  value={deceased}
                  idFieldName="key"
                  dropDownBoxClass="deceased-dropdown"
                  selectionClass="deceased-selection"
                  dropDownContainerClass={`deceased-dropdown-container`}
                  isDisabled={isEmptyString(result.key)}
                />
              </>
            )}
            {isPatientDeclinedNavigation && (
              <>
                <div className="delay-dropdown-content-title">Reason for Decline (Optional)</div>
                <textarea
                  className="text-area"
                  id={`textarea`}
                  name={`textarea`}
                  maxLength={2000}
                  cols={44}
                  rows={3}
                  placeholder="Enter any additional information for your answer"
                  value={reasonForDecline}
                  onChange={(event: any) => {
                    handleChangeReasonForDecline(event.target.value);
                  }}
                />
                <div className="notes-length-and-error-message">
                  <div className="error-message"></div>
                  <div className="note-length">
                    {reasonForDecline.length > 0 ? `${reasonForDecline.length} / 2000` : ""}
                  </div>
                </div>
              </>
            )}

            <div className="delay-dropdown-content-title">Action</div>
            <CustomDropDown
              placeholder="Select"
              dropDownMenuItems={actions.map((item) => {
                return {
                  name: item.text,
                  key: item.id,
                };
              })}
              handleValueChanges={(option: any) => {
                handleChange("action", option);
              }}
              value={action}
              idFieldName="key"
              dropDownBoxClass="action-dropdown"
              selectionClass="action-selection"
              dropDownContainerClass={`action-dropdown-container`}
              isDisabled={isEmptyString(result.key)}
            />
            <div className="delay-dropdown-content-title">
              Notes{isMarkAsClosedSelected && <span className="red-color">*</span>}
            </div>
            <textarea
              className="text-area"
              id={`textarea`}
              name={`textarea`}
              maxLength={2000}
              cols={44}
              rows={3}
              placeholder="Enter any additional information for your answer"
              value={note}
              onChange={(event: any) => {
                handleChangeNote(event.target.value);
              }}
            />
            <div className="notes-length-and-error-message">
              <div className="error-message">
                {showNotesErrorMessage && <Error message={delayNotesMandatoryWhenClosingTaskErrorMessage} />}
              </div>
              <div className="note-length">{note.length > 0 ? `${note.length} / 2000` : ""}</div>
            </div>
          </div>
        </div>
        <div className="button-section">
          <Button text="Cancel" className="cancel-button" onClick={handleOnCancel} />
          <Button
            text="Submit"
            disabled={getIsDisabledSubmitButton()}
            className="green-button submit-button"
            onClick={handleOnSubmit}
            showLoader={isLoading}
          />
        </div>
        {delayAttempts && delayAttempts?.length > 0 && (
          <div className="previous-attempts-section">
            <div className="line"></div>
            <div className="previous-attempts-title">Previous Attempts</div>
            <div className="delay-previous-attempts">
              {delayAttempts.map((item) => {
                const filteredDelayStatus = delayStatuses.find(
                  (delay) => item.taskDelayStatusId === delay.taskDelayStatusId
                );
                return (
                  <div key={item.timeOfDelay} className="delay-attempts-line">
                    {moment(item.timeOfDelay).format("MM/DD/YYYY")} | {moment(item.timeOfDelay).format("hh:mm A")}
                    {", "}
                    {filteredDelayStatus?.status}
                    {", "}
                    {filteredDelayStatus?.result.find((i) => i.id === item.taskDelayResultId)?.text} by {item.delayBy}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default DelayModalPresentation;
