export enum SortingTypes {
  original = 0,
  asc = 1,
  desc = 2,
}

export enum SortingOrderType {
  DEFAULT = "Default",
  ASC = "Asc",
  DESC = "Desc",
}
