import { ChangeEvent, useEffect } from "react";
import AssessmentPresentation from "./assessment.component";
import { useAppDispatch } from "state/store";
import {
  getAssessmentDataAsync,
  getPhysicianDataAsync,
  postAsessmentDataAsync,
} from "state/feature/assessment/assessment.action";
import { AssessmentContext } from "./context/assessment-context";
import {
  appendSelectedValue,
  getAssessmentState,
  removeAppendedSelectedValue,
  removeSelectedValue,
  resetAssessmentState,
  setIsRedirectEnabled,
  setIsValueChanged,
  setScaleValue,
  setSelectedValue,
} from "state/feature/assessment/assessment.slice";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import { isEmptyString } from "shared/methods/utilityFunctions";
import { useSelector } from "react-redux";
import { HandleSubmitDelayTask } from "components/Modal/delay-modal/delay-modal.types";
import { getAuth } from "state/feature/auth/auth.slice";
import {
  getBrowserState,
  getCurrentPageTaskState,
  setIsCurrentTaskDirty,
  setIsCurrentTaskTouched,
  setLoadTaskList,
} from "state/feature/task-management/task-management.slice";
import {
  getDelayAttempts,
  getIntakeDetails,
  getTaskDelayStatuses,
} from "state/feature/task-management/task-management.action";
import useUrlBlocking from "shared/hooks/use-blocking.hook";
import { getCommonState } from "state/feature/common/common.slice";
import { useHistory } from "react-router";

const Assessment = () => {
  useAxiosAuthenticated();
  const history = useHistory();
  const { currentTab } = useSelector(getBrowserState);
  const { intakeId, taskId } = currentTab!;
  const { taskPayload, delayTask } = useSelector(getCurrentPageTaskState(taskId));
  const { featureFlags } = useSelector(getCommonState);
  const dispatch = useAppDispatch();
  const { user } = useSelector(getAuth);
  const {
    answers,
    formId,
    version,
    patientId,
    id: answerId,
    isValueChanged,
    physicans,
  } = useSelector(getAssessmentState);
  useUrlBlocking();

  useEffect(() => {
    if (!taskPayload) {
      dispatch(getIntakeDetails({ intakeId, taskId })).then((response) => {
        if (response.payload) {
          dispatch(setIsRedirectEnabled(true));
        } else {
          dispatch(setIsRedirectEnabled(false));
        }
      });
      dispatch(getAssessmentDataAsync({ workflowDetailId: taskId, intakeId }));
      dispatch(
        getDelayAttempts({
          taskId,
          intakeId: parseInt(intakeId),
        })
      );
    }
  }, [dispatch, taskPayload, intakeId, taskId]);

  useEffect(() => {
    dispatch(setIsCurrentTaskTouched({ taskId: currentTab?.taskId, isTouched: isValueChanged }));
    dispatch(setIsCurrentTaskDirty({ taskId: currentTab?.taskId, isDirty: isValueChanged }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValueChanged]);

  useEffect(() => {
    if (!physicans) {
      dispatch(getPhysicianDataAsync());
    }
  }, [physicans, dispatch]);

  useEffect(() => {
    if (!delayTask.delayStatuses.length) {
      dispatch(getTaskDelayStatuses(currentTab!.taskId));
    }
  }, [dispatch, currentTab, delayTask.delayStatuses]);

  const onChangePill = (
    e: ChangeEvent<HTMLInputElement>,
    id: string,
    sectionId?: string | null,
    questionId?: string | null,
    nextQuestionId?: string | null,
    conditionalSectionId?: string | null,
    showSection?: boolean | null,
    pageId?: number | null,
    order?: number | null,
    sectionOrder?: number | null
  ) => {
    const element = document.getElementById(e.target.id) as HTMLInputElement;
    const allOtherRelatedCheckboxes = document.querySelectorAll(
      `.${e.target.classList[1]}`
    ) as NodeListOf<HTMLInputElement>;
    const parentElement = element.parentElement;
    if (parentElement) {
      if (element && element.checked) {
        allOtherRelatedCheckboxes.forEach((item: HTMLInputElement) => {
          if (item === element) {
            parentElement.classList.add("checked");
            dispatch(
              setSelectedValue({
                questionId,
                answer: {
                  [e.target.name]: [
                    {
                      id,
                      value: e.target.value,
                      nextQuestionId,
                      order,
                      collapsibleSectionId: showSection ? conditionalSectionId : null,
                    },
                  ],
                },
              })
            );
          } else {
            item.checked = false;
            item?.parentElement?.classList.remove("checked");
          }
        });
      } else {
        dispatch(
          removeSelectedValue({
            sectionId,
            questionId,
            [e.target.name]: [{ id, value: e.target.value, nextQuestionId }],
          })
        );
        parentElement.classList.remove("checked");
      }
    }
  };

  const onSelectPill = (
    e: ChangeEvent<HTMLInputElement>,
    id: string,
    sectionId?: string | null,
    questionId?: string | null,
    nextQuestionId?: string | null,
    conditionalSectionId?: string | null,
    showSection?: boolean | null,
    pageId?: number | null,
    order?: number | null,
    sectionOrder?: number | null
  ) => {
    const element = document.getElementById(e.target.id) as HTMLInputElement;
    const allOtherRelatedCheckboxes = document.querySelectorAll(
      `.${e.target.classList[1]}`
    ) as NodeListOf<HTMLInputElement>;
    const parentElement = element.parentElement;
    if (parentElement) {
      if (element && element.checked) {
        allOtherRelatedCheckboxes.forEach((item: HTMLInputElement) => {
          if (item === element) {
            parentElement.classList.add("checked");
            dispatch(
              appendSelectedValue({
                questionId,
                answer: { [e.target.name]: [{ id, value: e.target.value, nextQuestionId }] },
                pageId,
              })
            );
          }
        });
      } else {
        // TODO: remove appended value
        parentElement.classList.remove("checked");
      }
    }
  };

  const onChangeText = (
    text: string,
    sectionId: string,
    questionId: string,
    pageId?: number | null,
    order?: number | null,
    sectionOrder?: number | null
  ) => {
    if (!text || isEmptyString(text)) {
      dispatch(
        removeSelectedValue({
          questionId,
          answer: {
            [questionId]: [{ id: "", value: text, nextQuestionId: null, order }],
          },
        })
      );
    } else {
      dispatch(
        setSelectedValue({
          questionId,
          answer: {
            [questionId]: [{ id: "", value: text, nextQuestionId: null, order }],
          },
        })
      );
    }
  };

  const onChangeCheckbox = (
    e: ChangeEvent<HTMLInputElement>,
    id: string,
    title: string,
    sectionId: string,
    questionId: string,
    nextQuestionId?: string | null,
    pageId?: number | null,
    order?: number | null,
    sectionOrder?: number | null
  ) => {
    if (e.target.checked) {
      dispatch(
        appendSelectedValue({
          questionId,
          answer: { [questionId]: [{ id, value: title, nextQuestionId: nextQuestionId, order }] },
          pageId,
          showSection: false,
        })
      );
    } else {
      dispatch(
        removeAppendedSelectedValue({
          questionId,
          answerId: id,
          nextQuestionId,
          pageId,
        })
      );
    }
  };

  const onChangeDropdown = (
    selectedValue: any,
    sectionId: string,
    questionId: string,
    pageId?: number | null,
    order?: number | null,
    sectionOrder?: number | null
  ) => {
    dispatch(
      setSelectedValue({
        questionId,
        answer: { [questionId]: [{ ...selectedValue, order }] },
        pageId,
      })
    );
  };

  const onChangeDays = (
    e: ChangeEvent<HTMLInputElement>,
    id?: string | null,
    sectionId?: string | null,
    questionId?: string | null,
    pageId?: number | null,
    order?: number | null,
    sectionOrder?: number | null
  ) => {
    dispatch(
      setSelectedValue({
        questionId,
        answer: { [e.target.name]: [{ id, value: e.target.value, nextQuestionId: null, order }] },
        pageId,
      })
    );
  };

  const onChangeAge = (
    id?: string | null,
    sectionId?: string | null,
    questionId?: string | null,
    pageId?: number | null,
    order?: number | null,
    sectionOrder?: number | null,
    value?: string | null
  ) => {
    dispatch(
      setSelectedValue({
        questionId,
        answer: { [questionId ?? ""]: [{ id, value: value, nextQuestionId: null, order }] },
        pageId,
      })
    );
  };

  const onChangeDate = (
    selectedDate: any,
    sectionId: string,
    questionId: string,
    pageId?: number | null,
    order?: number | null,
    sectionOrder?: number | null
  ) => {
    if (!isEmptyString(selectedDate)) {
      dispatch(
        setSelectedValue({
          questionId,
          answer: { [questionId]: [{ id: "", value: selectedDate, nextQuestionId: null, order }] },
          pageId,
        })
      );
    } else {
      dispatch(
        removeSelectedValue({
          questionId,
          answer: { [questionId]: [{ id: "", value: selectedDate, nextQuestionId: null, order }] },
        })
      );
    }
  };

  const onScaleChange = (questionId: string, minValue?: string, maxValue?: string, pageId?: string) => {
    dispatch(
      setScaleValue({
        questionId,
        answer: {
          [questionId]: [
            { id: "", value: minValue, nextQuestionId: null },
            { id: "", value: maxValue, nextQuestionId: null },
          ],
        },
        pageId,
      })
    );
  };

  const saveAnswers = async (isSaveDraft: boolean) => {
    return dispatch(
      postAsessmentDataAsync({
        patientId: patientId,
        loggedInUserEmail: user.email,
        formId,
        version,
        workflowId: taskId,
        intakeId: parseInt(intakeId),
        isSaveDraft,
        id: answerId,
        answers: answers.map(({ selectedValues, questionId }) => {
          return {
            questionId,
            selectionValues: Object.entries(selectedValues).flatMap((item) => {
              if (item[1].length > 1) {
                const selectedValue: any = [];
                item[1].forEach((value: any, index: number) => {
                  selectedValue[index] = {
                    id: value.id,
                    value: value.value,
                    nextQuestionId: value.nextQuestionId,
                  };
                });
                return selectedValue;
              }
              return { id: item[1][0].id, value: item[1][0].value, nextQuestionId: item[1][0].nextQuestionId };
            }),
          };
        }),
      })
    ).unwrap();
  };

  const handleSubmitDelayTask = (delaytask: HandleSubmitDelayTask) => {
    saveAnswers(true)
      .then(() => {
        dispatch(setIsValueChanged(false));
        dispatch(setLoadTaskList(true));
        delaytask(taskId);
        if (!(featureFlags.taskManagement && featureFlags.taskManagementListView)) {
          history.goBack();
        }
      })
      .catch(() => {
        delaytask();
      });
  };

  useEffect(() => {
    return () => {
      dispatch(resetAssessmentState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <AssessmentContext.Provider
      value={{
        onChangeAge,
        onChangeCheckbox,
        onChangeDate,
        onChangeDropdown,
        onChangeDays,
        onChangePill,
        onChangeText,
        onSelectPill,
        onScaleChange,
      }}
    >
      <AssessmentPresentation handleSubmitDelayTask={handleSubmitDelayTask} />
    </AssessmentContext.Provider>
  );
};

export default Assessment;
