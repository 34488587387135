import { useDispatch, useSelector } from "react-redux";
import "./browser-view.styles.scss";
import PageViewContainer from "./page-view/page-view.container";
import TabsViewContainer from "./tabs-view/tabs-view.container";
import {
  addNewPage,
  addNewTab,
  expandTaskBoard,
  getBrowserState,
  getTaskBoardState,
  getTaskManagementState,
  setCurrentPage,
  setCurrentTab,
  setIsCurrentTaskTouched,
} from "state/feature/task-management/task-management.slice";
import { useCallback, useEffect } from "react";
import CommonState from "components/common-state/common-state.component";
import { CommonStateType } from "components/common-state/common-state.enum";
import { IPage } from "state/types/task-management-slice.type";

const BrowserViewContainer = function () {
  const dispatch = useDispatch();
  const { isExpanded } = useSelector(getTaskBoardState);
  const { tabs, pages, currentTab, currentPage } = useSelector(getBrowserState);
  const { redirectParams } = useSelector(getTaskManagementState);

  const pageForCurrentTabExists = useCallback(() => {
    if (currentTab) {
      const { intakeId, taskId } = currentTab!;
      const page = pages.find((p) => p.intakeId === intakeId && p.taskId === taskId);
      return !!page;
    }
  }, [currentTab, pages]);

  useEffect(() => {
    if (currentTab && currentTab.taskId !== currentPage?.taskId) {
      const { taskId, taskType, intakeId } = currentTab;
      if (!pageForCurrentTabExists()) {
        const newPage: IPage = {
          attachments: [],
          activity: [],
          upcomingTask: [],
          isValidIntake: true,
          isLoading: false,
          allowIntakeAcess: true,
          isValidatingIntake: false,
          patientDetails: null,
          episode: null,
          isRightPanelLoading: false,
          taskId,
          taskType,
          intakeId,
          taskPayload: null,
          delayTask: {
            delayStatuses: [],
            intakeDetails: null,
            delayAttempts: [],
          },
          isDelayTaskLoading: false,
          isDelayTaskError: false,
          isTouched: false,
          isDirty: false,
          shouldPerformPostSubmitAction: false,
          shouldPerformPostDelayAction: false,
          shouldPerformPostTaskWaitingApprovalAction: false,
          shouldPerformPostTaskApprovedAction: false,
          shouldPerformPostTaskRejectedAction: false,
        };
        dispatch(setCurrentPage(newPage));
        dispatch(addNewPage(newPage));
      } else {
        const page = pages.find((p) => p.intakeId === intakeId && p.taskId === taskId)!;
        dispatch(setIsCurrentTaskTouched({ taskId, isTouched: false }));
        dispatch(setCurrentPage(structuredClone(page)));
      }
    }
  }, [dispatch, pageForCurrentTabExists, currentTab, currentPage, pages]);

  useEffect(() => {
    if (redirectParams) {
      const { taskId, taskType, intakeId } = redirectParams;
      const newPage: IPage = {
        attachments: [],
        activity: [],
        upcomingTask: [],
        isValidIntake: true,
        isLoading: false,
        allowIntakeAcess: true,
        isValidatingIntake: false,
        patientDetails: null,
        episode: null,
        isRightPanelLoading: false,
        taskId,
        taskType,
        intakeId,
        taskPayload: null,
        delayTask: {
          delayStatuses: [],
          intakeDetails: null,
          delayAttempts: [],
        },
        isDelayTaskLoading: false,
        isDelayTaskError: false,
        isTouched: false,
        isDirty: false,
        shouldPerformPostSubmitAction: false,
        shouldPerformPostDelayAction: false,
        shouldPerformPostTaskWaitingApprovalAction: false,
        shouldPerformPostTaskApprovedAction: false,
        shouldPerformPostTaskRejectedAction: false,
      };
      dispatch(addNewPage(newPage));
      dispatch(addNewTab({ taskId, intakeId, taskType, title: "" }));
      dispatch(setCurrentTab({ taskId, intakeId, taskType, title: "" }));
      dispatch(setCurrentPage(newPage));
    }
  }, [dispatch, redirectParams]);

  useEffect(() => {
    const isAnyPageDirty = pages.findIndex((p) => p.isDirty) > -1;
    const handler = function (e: BeforeUnloadEvent) {
      if (isAnyPageDirty) {
        e.returnValue = true;
      }
      return;
    };
    window.addEventListener("beforeunload", handler);
    return () => window.removeEventListener("beforeunload", handler);
  }, [pages]);

  const renderPage = () => {
    if (currentPage && currentTab) {
      return currentPage?.taskId === currentTab?.taskId ? <PageViewContainer /> : null;
    }
    return null;
  };

  useEffect(() => {
    if (tabs.length === 0) {
      dispatch(expandTaskBoard(true));
    }
  }, [tabs, dispatch]);

  return (
    <div id="browser-view-container">
      <div id="browser-view-context">
        {tabs.length && !redirectParams ? (
          <header id="header-container">
            <TabsViewContainer tabs={tabs} />
          </header>
        ) : null}

        <div id="view-container">
          <div id="view-context">
            {!currentTab?.intakeId && !redirectParams?.intakeId ? (
              <div className={`task-not-selected-container ${isExpanded ? "expand" : "collapse"}`}>
                <CommonState type={CommonStateType.NO_TASK_SELECTED} />
              </div>
            ) : (
              <>{renderPage()}</>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrowserViewContainer;
