import Button from "components/Button/button.component";
import Checkbox from "components/Checkbox/checkbox.component";
import DatePicker from "components/DatePicker/date-picker.component";
import SidePopup from "components/SidePopup/side-popup.component";
import TagsSearchDropdown from "components/TagsSearchDropdown/tags-search-dropdown.component";
import { TagsSearchDropdownUseCase } from "components/TagsSearchDropdown/tags-search-dropdown.enum";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ITagsSearchDropdownType } from "shared/types/dropdown.type";
import { getCommonState, setIsFilterModal } from "state/feature/common/common.slice";
import { IEpisodesFilterModalProps } from "./episodes-filter-modal.types";
import "./episodes-filter-modal.style.scss";
import { getBackUpNavigatorListAsync } from "state/feature/navigator/navigator.action";
import { useAppDispatch } from "state/store";
import { INavigator } from "state/types/navigator.type";
import { getAssociatedPhysiciansAsync } from "state/feature/user-management/user-management.action";
import { EpisodesFilterTypes } from "shared/enums/episodes.enum";
import { getEpisodesFilterStatusMenuAsync } from "state/feature/episodes/episodes.action";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import { DATE_PICKER_CALENDER_HEIGHT } from "shared/constant/date-picker-constants";

const EpisodesFilterModal = (props: IEpisodesFilterModalProps) => {
  useAxiosAuthenticated();
  const { handleFilterApplyClick, handleFilterClearClick, selectedValues } = props;
  const { modal: modalState } = useSelector(getCommonState);
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const initialDateState = [
    {
      startDate: moment().subtract(7, "day").startOf("day").toDate(),
      endDate: moment().toDate(),
      key: "selection",
    },
  ];

  const [admitDateState, setAdmitDateState] = useState<any>(initialDateState);
  const [surgeryDateState, setSurgeryDateState] = useState<any>(initialDateState);

  const [isAdmitDatePickerOpen, setAdmitDatePickerOpen] = useState<boolean>(false);
  const [isSurgeryDatePickerOpen, setSurgeryDatePickerOpen] = useState<boolean>(false);
  const [isLoadingEpisodeFilterDropdownOptions, setIsLoadingEpisodeFilterDropdownOptions] = useState<{
    status: boolean;
    pnav: boolean;
    tnav: boolean;
    physicians: boolean;
  }>({ status: true, pnav: true, tnav: true, physicians: true });
  const [episodeFilterDropdownOptions, setEpisodeFilterDropdownOptions] = useState<{
    status: Array<ITagsSearchDropdownType>;
    pnav: Array<ITagsSearchDropdownType>;
    tnav: Array<ITagsSearchDropdownType>;
    physicians: Array<ITagsSearchDropdownType>;
  }>({
    status: [],
    pnav: [],
    tnav: [],
    physicians: [],
  });
  const initialEpisodeFilterValue = {
    status: [],
    pnav: [],
    tnav: [],
    physicians: [],
    admitDate: [],
    surgeryDate: [],
  };
  const [episodeFilterValues, setEpisodeFilterValues] = useState<{
    status: Array<ITagsSearchDropdownType>;
    pnav: Array<ITagsSearchDropdownType>;
    tnav: Array<ITagsSearchDropdownType>;
    physicians: Array<ITagsSearchDropdownType>;
    admitDate: Array<any>;
    surgeryDate: Array<any>;
  }>(initialEpisodeFilterValue);

  const [filterSearchValue, setFilterSearchValues] = useState({
    pnav: "",
    tnav: "",
    physician: "",
  });

  const handleAdmitDateApplyClick = (item: any) => {
    setAdmitDatePickerOpen(false);
    setEpisodeFilterValues((prev) => {
      return { ...prev, admitDate: item };
    });
  };
  const handleSurgeryDateApplyClick = (item: any) => {
    setSurgeryDatePickerOpen(false);
    setEpisodeFilterValues((prev) => {
      return { ...prev, surgeryDate: item };
    });
  };

  const checkSearchText = (value1: string, value2: string): boolean => {
    return value1.toLowerCase().includes(value2.toLowerCase());
  };

  const getDropdownMenu = (dropDownName: string) => {
    if (dropDownName === EpisodesFilterTypes.PNAV) {
      return episodeFilterDropdownOptions.pnav.filter((pnavElement: ITagsSearchDropdownType) => {
        return episodeFilterDropdownOptions.pnav.length > 0
          ? !episodeFilterValues.pnav.some((obj: ITagsSearchDropdownType) => obj.key === pnavElement.key) &&
              checkSearchText(pnavElement.name, filterSearchValue.pnav)
          : checkSearchText(pnavElement.name, filterSearchValue.pnav);
      });
    } else if (dropDownName === EpisodesFilterTypes.TNAV) {
      return episodeFilterDropdownOptions.tnav.filter((tnavElement: ITagsSearchDropdownType) => {
        return episodeFilterDropdownOptions.tnav.length > 0
          ? !episodeFilterValues.tnav.some((obj: ITagsSearchDropdownType) => obj.key === tnavElement.key) &&
              checkSearchText(tnavElement.name, filterSearchValue.tnav)
          : checkSearchText(tnavElement.name, filterSearchValue.tnav);
      });
    } else if (dropDownName === EpisodesFilterTypes.PHYSICIAN) {
      return episodeFilterDropdownOptions.physicians.filter((physicianElement: ITagsSearchDropdownType) => {
        return episodeFilterDropdownOptions.physicians.length > 0
          ? !episodeFilterValues.physicians.some((obj: ITagsSearchDropdownType) => obj.key === physicianElement.key) &&
              checkSearchText(physicianElement.name, filterSearchValue.physician)
          : checkSearchText(physicianElement.name, filterSearchValue.physician);
      });
    }
    return [];
  };

  const handleStatusFilter = (status: ITagsSearchDropdownType) => {
    const newStatusFilterValues: Array<ITagsSearchDropdownType> = [...episodeFilterValues.status];

    const ifFilterApplied = episodeFilterValues.status.some((val) => val.key === status.key);
    if (ifFilterApplied) {
      const currentIdx = episodeFilterValues.status.findIndex((val: ITagsSearchDropdownType) => val.key === status.key);
      newStatusFilterValues.splice(currentIdx, 1);
    } else {
      newStatusFilterValues.push(status);
    }
    setEpisodeFilterValues((prev) => {
      return { ...prev, status: newStatusFilterValues };
    });
  };

  const getDefaultDateState = () => {
    const startDate = moment().subtract(7, "day");
    const endDate = moment();
    const startDateState = startDate.startOf("day").toISOString();
    const endDateState = endDate.toISOString();
    return { startDateState, endDateState };
  };

  const handleModalClose = () => {
    dispatch(setIsFilterModal(false));
    setAdmitDatePickerOpen(false);
    setSurgeryDatePickerOpen(false);
    setAdmitDateState(initialDateState);
    setSurgeryDateState(initialDateState);
    setEpisodeFilterValues((prev) => {
      return { physicians: [], pnav: [], tnav: [], status: [], admitDate: [], surgeryDate: [] };
    });
  };

  const handleAdmitDateClearClick = (val: boolean) => {
    setAdmitDatePickerOpen(val);
    const { startDateState, endDateState } = getDefaultDateState();
    setAdmitDateState([
      {
        startDate: startDateState ? moment(startDateState).toDate() : null,
        endDate: endDateState ? moment(endDateState).toDate() : null,
        key: "selection",
      },
    ]);
    setEpisodeFilterValues((prev) => {
      return { ...prev, admitDate: [] };
    });
  };

  const handleSurgeryDateClearClick = (val: boolean) => {
    setSurgeryDatePickerOpen(val);
    const { startDateState, endDateState } = getDefaultDateState();

    setSurgeryDateState([
      {
        startDate: startDateState ? moment(startDateState).toDate() : null,
        endDate: endDateState ? moment(endDateState).toDate() : null,
        key: "selection",
      },
    ]);
    setEpisodeFilterValues((prev) => {
      return { ...prev, surgeryDate: [] };
    });
  };

  const compareDateFunction = (date1: Array<any>, date2: Array<any>) => {
    if (date1.length > 0 && date2.length > 0) {
      const checkStartDate = moment(date1[0].startDate).isSame(moment(date2[0].startDate));
      const checkEndDate = moment(date1[0].endDate).isSame(moment(date2[0].endDate));
      return !checkStartDate || !checkEndDate;
    }
    return date1.length !== date2.length;
  };
  const compareFunction = (arr1: Array<any>, arr2: Array<any>, type?: string) => {
    return arr1.some((obj1) => {
      const element = arr2.find((obj2) => {
        return obj1.key == obj2.key;
      });
      return element == null;
    });
  };
  const isFilterApplied = useCallback(() => {
    if (modalState.isOpenFilterModal) {
      const newSelectFilter: any = selectedValues;
      const newEpisodeSelectedValue: any = episodeFilterValues;
      const newSelectedFilterKeys = Object.keys(newSelectFilter);
      const isChanged = newSelectedFilterKeys.some((obj: any) => {
        if (newSelectFilter[obj].length !== newEpisodeSelectedValue[obj].length) {
          return true;
        }
        const isTrue = obj.toLowerCase().includes("date")
          ? compareDateFunction(newSelectFilter[obj], newEpisodeSelectedValue[obj])
          : compareFunction(newSelectFilter[obj], newEpisodeSelectedValue[obj]);
        return isTrue;
      });
      return !isChanged;
    }
  }, [selectedValues, episodeFilterValues, modalState.isOpenFilterModal]);

  useEffect(() => {
    setEpisodeFilterValues((prev) => {
      return {
        ...prev,
        pnav: [...selectedValues.pnav],
        tnav: [...selectedValues.tnav],
        physicians: [...selectedValues.physicians],
        status: [...selectedValues.status],
        admitDate: selectedValues.admitDate.length > 0 ? selectedValues.admitDate : [],
        surgeryDate: selectedValues.surgeryDate.length > 0 ? selectedValues.surgeryDate : [],
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValues, modalState.isOpenFilterModal]);

  useEffect(() => {
    if (modalState.isOpenFilterModal) {
      appDispatch(getBackUpNavigatorListAsync({ types: ["TNAV"] }))
        .unwrap()
        .then((result) => {
          const tnav =
            result.length > 0
              ? result.map((res: INavigator) => {
                  return { key: res.careManagerId, name: `${res.firstName}${res.lastName ? ` ${res.lastName}` : ""}` };
                })
              : [];
          setEpisodeFilterDropdownOptions((prev) => {
            return { ...prev, tnav: tnav };
          });
          setIsLoadingEpisodeFilterDropdownOptions((prev) => {
            return { ...prev, tnav: false };
          });
        })
        .catch((err) => {
          setIsLoadingEpisodeFilterDropdownOptions((prev) => {
            return { ...prev, tnav: false };
          });
        });
      appDispatch(getBackUpNavigatorListAsync({ types: ["PNAV"] }))
        .unwrap()
        .then((result) => {
          const pnav =
            result.length > 0
              ? result.map((res: INavigator) => {
                  return { key: res.careManagerId, name: `${res.firstName}${res.lastName ? ` ${res.lastName}` : ""}` };
                })
              : [];
          setEpisodeFilterDropdownOptions((prev) => {
            return { ...prev, pnav: pnav };
          });
          setIsLoadingEpisodeFilterDropdownOptions((prev) => {
            return { ...prev, pnav: false };
          });
        })
        .catch((err) => {
          setIsLoadingEpisodeFilterDropdownOptions((prev) => {
            return { ...prev, pnav: false };
          });
        });
      appDispatch(getAssociatedPhysiciansAsync())
        .unwrap()
        .then((result) => {
          const physician =
            result.length > 0
              ? result.map((res: any) => {
                  return {
                    key: res.id,
                    name: `${res.firstName}${res.middleName ? ` ${res.middleName}` : ""}${
                      res.lastName ? ` ${res.lastName}` : ""
                    }`,
                  };
                })
              : [];
          setEpisodeFilterDropdownOptions((prev) => {
            return { ...prev, physicians: physician };
          });
          setIsLoadingEpisodeFilterDropdownOptions((prev) => {
            return { ...prev, physicians: false };
          });
        })
        .catch((err) => {
          setIsLoadingEpisodeFilterDropdownOptions((prev) => {
            return { ...prev, physicians: false };
          });
        });
    }
    if (episodeFilterDropdownOptions.status.length === 0) {
      appDispatch(getEpisodesFilterStatusMenuAsync())
        .unwrap()
        .then((result) => {
          setIsLoadingEpisodeFilterDropdownOptions((prev) => {
            return { ...prev, status: false };
          });
          const statusMenu =
            result.length > 0
              ? result.map((res: any) => {
                  return {
                    key: res.statusShortName,
                    name: res.statusLongName,
                  };
                })
              : [];
          setEpisodeFilterDropdownOptions((prev) => {
            return { ...prev, status: statusMenu };
          });
        })
        .catch((err) => {
          setIsLoadingEpisodeFilterDropdownOptions((prev) => {
            return { ...prev, status: false };
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appDispatch, modalState.isOpenFilterModal]);

  return (
    <SidePopup
      isOpen={modalState.isOpenFilterModal}
      onClose={() => {
        handleModalClose();
      }}
      contentClass="filter-content-container"
      className="filter-side-popup episodes-filter-side-popup"
      heading="Filter"
    >
      <div className="filter-content">
        <div className="filter-options">
          <div>
            <div className="status">Status</div>
            <div className="status-checkbox-container">
              {episodeFilterDropdownOptions.status.length > 0 &&
                episodeFilterDropdownOptions.status.map((statusOption) => {
                  return (
                    <div className={`${statusOption.value}`}>
                      <Checkbox
                        name={statusOption.name}
                        value={statusOption.name}
                        id={`${statusOption.key}-checkbox`}
                        onChange={() => {
                          handleStatusFilter(statusOption);
                        }}
                        isChecked={episodeFilterValues.status.some((status) => status.key === statusOption.key)}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
          <div>
            <div className="pnav-label">PNav</div>
            <TagsSearchDropdown
              dropDownMenuItems={getDropdownMenu(EpisodesFilterTypes.PNAV)}
              key={"pnav-input"}
              value={episodeFilterValues.pnav}
              handleValueChanges={(val: any) => {
                setEpisodeFilterValues((prev) => {
                  return { ...prev, pnav: val };
                });
              }}
              dropDownBoxClass={""}
              selectionClass={""}
              onSearchTextChange={(searchText) => {
                setFilterSearchValues((prev) => {
                  return { ...prev, pnav: searchText };
                });
              }}
              placeholder="Search by name"
              isDisabled={false}
              isLoading={isLoadingEpisodeFilterDropdownOptions.pnav}
              useCaseType={TagsSearchDropdownUseCase.ASSOCIATED_PHYSICIAN}
              renderFrom="episodes-filter"
            />
          </div>
          <div>
            <div className="tnav-label">TNav</div>
            <TagsSearchDropdown
              dropDownMenuItems={getDropdownMenu(EpisodesFilterTypes.TNAV)}
              value={[...episodeFilterValues.tnav]}
              handleValueChanges={(val: any) => {
                setEpisodeFilterValues((prev) => {
                  return { ...prev, tnav: val };
                });
              }}
              dropDownBoxClass={""}
              selectionClass={""}
              onSearchTextChange={(searchText) => {
                setFilterSearchValues((prev) => {
                  return { ...prev, tnav: searchText };
                });
              }}
              placeholder="Search by name"
              isDisabled={false}
              isLoading={isLoadingEpisodeFilterDropdownOptions.tnav}
              useCaseType={TagsSearchDropdownUseCase.ASSOCIATED_PHYSICIAN}
              renderFrom="episodes-filter"
            />
          </div>
          <div>
            <div className="physician-label">Physician</div>
            <TagsSearchDropdown
              dropDownMenuItems={getDropdownMenu(EpisodesFilterTypes.PHYSICIAN)}
              value={episodeFilterValues.physicians}
              handleValueChanges={(val: any) => {
                setEpisodeFilterValues((prev) => {
                  return { ...prev, physicians: val };
                });
              }}
              dropDownBoxClass={"episodes-filter-physician-dropdown"}
              selectionClass={""}
              onSearchTextChange={(searchText) => {
                setFilterSearchValues((prev) => {
                  return { ...prev, physician: searchText };
                });
              }}
              placeholder="Search by name"
              isDisabled={false}
              isLoading={isLoadingEpisodeFilterDropdownOptions.physicians}
              useCaseType={TagsSearchDropdownUseCase.ASSOCIATED_PHYSICIAN}
              renderFrom="episodes-filter"
            />
          </div>
          <div>
            <div className="admit-date-label">Admit Date Range</div>
            <div className="admit-date" id="episodes-filter-admit-date">
              <DatePicker
                onTap={(val) => {
                  setAdmitDatePickerOpen(val);
                }}
                isOpen={isAdmitDatePickerOpen}
                handleClearButtonClick={handleAdmitDateClearClick}
                handleApplyClick={handleAdmitDateApplyClick}
                dateFormat="mm/dd/yyyy"
                initialSelectedDates={selectedValues.admitDate.length > 0 ? selectedValues.admitDate : admitDateState}
                onClickEvent={(e: React.MouseEvent<HTMLInputElement>) => {
                  const position = e.currentTarget.getBoundingClientRect();
                  setTimeout(() => {
                    const element = document.querySelector("#episodes-filter-admit-date #date-picker-container");
                    element?.setAttribute(
                      "style",
                      `top:${Math.ceil(
                        position.top - DATE_PICKER_CALENDER_HEIGHT + 30
                      )}px;display:block;transition:none;`
                    );
                  }, 10);
                }}
                showEmptyPlaceholder={episodeFilterValues.admitDate.length === 0}
              />
            </div>
          </div>
          <div>
            <div className="sugery-date-label">Surgery Date Range</div>
            <div className="surgery-date" id="episodes-filter-surgery-date">
              <DatePicker
                onTap={(val) => {
                  setSurgeryDatePickerOpen(val);
                }}
                isOpen={isSurgeryDatePickerOpen}
                handleClearButtonClick={handleSurgeryDateClearClick}
                handleApplyClick={handleSurgeryDateApplyClick}
                dateFormat="mm/dd/yyyy"
                initialSelectedDates={
                  selectedValues.surgeryDate.length > 0 ? selectedValues.surgeryDate : surgeryDateState
                }
                onClickEvent={(e: React.MouseEvent<HTMLInputElement>) => {
                  const position = e.currentTarget.getBoundingClientRect();
                  setTimeout(() => {
                    const element = document.querySelector("#episodes-filter-surgery-date #date-picker-container");
                    element?.setAttribute(
                      "style",
                      `top:${Math.ceil(
                        position.top - DATE_PICKER_CALENDER_HEIGHT + 30
                      )}px;display:block;transition:none;`
                    );
                  }, 10);
                }}
                showEmptyPlaceholder={episodeFilterValues.surgeryDate.length === 0}
              />
            </div>
          </div>
        </div>
        <div className="filter-buttons">
          <Button
            onClick={() => {
              if (!isFilterApplied()) {
                handleFilterApplyClick(episodeFilterValues);
                handleModalClose();
              }
            }}
            text="Apply"
            disabled={isFilterApplied()}
            className={`green-button apply-button ${isFilterApplied() ? "disabled" : ""}`}
          />
          <Button
            onClick={() => {
              handleFilterClearClick();
              handleModalClose();
            }}
            text="Clear"
            className={`white-button clear-button `}
          />
        </div>
      </div>
    </SidePopup>
  );
};
export default EpisodesFilterModal;
