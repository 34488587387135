import Icon from "components/Icon/icon.component";
import "./chat-footer.styles.scss";
import { ThumbnailProp } from "components/Thumbnail/thumbnail.component";
import React from "react";
import { isMobile } from "react-device-detect";

const MSG_LENGTH = 3200;

type ChatInputProps = {
  sendMessage: () => void;
  inputValue: string;
  onChangeInputValue: (value: string) => void;
  disabled?: boolean;
  previewThumbnail: Array<ThumbnailProp>;
  onClickFileAttachment?: (e: any) => void;
  isSendDisabled?: boolean;
  placeholder?: string;
};

function ChatFooter({
  sendMessage,
  inputValue,
  onChangeInputValue,
  disabled,
  previewThumbnail,
  onClickFileAttachment,
  isSendDisabled,
  placeholder,
}: ChatInputProps) {
  const chatTextAreaRef = React.useRef<HTMLTextAreaElement>(null);
  const expandTextBox = (e: any) => {
    e.target.setAttribute("style", `height:auto`);
    e.target.setAttribute("style", `height:${e.target.scrollHeight}px`);
  };

  const resetHeight = () => {
    const element = document.querySelector(".chat-text-box");
    if (element) {
      element?.setAttribute("style", "height:42px !important");
    }
    if (chatTextAreaRef?.current) {
      chatTextAreaRef.current?.focus();
    }
  };
  return (
    <div className="chat-footer">
      <div className="chat-text-container">
        <textarea
          ref={chatTextAreaRef}
          value={inputValue}
          rows={isMobile ? 1 : 2}
          onChange={(e) => {
            expandTextBox(e);
            onChangeInputValue(e.target.value);
          }}
          className="chat-text-box"
          disabled={disabled}
          placeholder={placeholder}
          maxLength={MSG_LENGTH}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey && !isSendDisabled) {
              sendMessage();
              resetHeight();
            }
          }}
        />
        {previewThumbnail.length === 0 && (
          <label htmlFor="attachment">
            <input id="attachment" disabled={disabled} onChange={onClickFileAttachment} type="file" />
            <Icon icon="Path" size={20} className={`attachment ${disabled ? "disabled" : ""}`} />
          </label>
        )}
      </div>
      <div
        className={`send ${disabled || isSendDisabled ? "disabled" : ""}`}
        onClick={() => {
          sendMessage();
          resetHeight();
        }}
      >
        <Icon icon="Shape" size={30} />
      </div>
    </div>
  );
}

export default ChatFooter;
