import "./task.styles.scss";
import { Task as TaskSliceProps } from "state/types/task-management-slice.type";
import moment from "moment";
import Days from "components/days/days.component";
import { useSelector } from "react-redux";
import { getRiskAssessmentState, resetSelections } from "state/feature/risk-assessment/risk-assessment.slice";
import {
  addNewTab,
  expandTaskBoard,
  getBrowserState,
  memoizeTaskPayloadForCurrentPage,
  setCurrentTab,
  setRequestedTaskPayload,
  setShowTabLimitModal,
} from "state/feature/task-management/task-management.slice";
import Icon from "components/Icon/icon.component";
import { getAuth } from "state/feature/auth/auth.slice";
import { getNavigatorState } from "state/feature/navigator/navigator.slice";
import { INavigator } from "state/types/navigator.type";
import { CustomToolTip } from "components/CustomTooltip/custom-tooltip.component";
import {
  checkIsTaskIsTOCTaskById,
  getPatientFullName,
  getTaskNameBasedOnTaskTypeId,
  isEmptyString,
  removeLineBreaksFromText,
} from "shared/methods/utilityFunctions";
import getHighlightedText from "components/HighLightedText/high-lighted-text.component";
import { setIsOpenMultipleTabPopup } from "state/feature/common/common.slice";
import { TaskManagementTab } from "shared/enums/task-management-tabs.enum";
import { getAssessmentState } from "state/feature/assessment/assessment.slice";
import { getTOCManagementState } from "state/feature/toc-management/toc-management.slice";
import { TaskPageName } from "shared/enums/page-name.enum";
import { getTaskAccess } from "state/feature/task-management/task-management.action";
import { useAppDispatch } from "state/store";
import { getReminderTaskState } from "state/feature/reminder-task/reminder-task.slice";
import { getNewManualTaskState } from "state/feature/new-manual-task/new-manual-task.slice";
import { TASKS_LIMIT } from "pages/task-management/constant";
import { useState } from "react";
import { getSendOrderSlice } from "state/feature/send-order/send-order.slice";
import { getAdmitTaskState } from "state/feature/admit-task/admit-task.slice";
import { getCheckOnPatientSlice } from "state/feature/check-on-patient/check-on-patient.slice";
import { ADMIT_TASK_TYPE_NAME } from "shared/constant/commonConstants";
import { TaskStatus } from "shared/enums/tasks-status.enum";
import { FormType } from "shared/enums/form-types.enum";
import TocTasksServiceProvidersDetails from "pages/task-management/components/toc-tasks-service-provider-details/toc-tasks-service-provider-details.component";

const Task = (props: TaskSliceProps) => {
  const { user } = useSelector(getAuth);
  const { backupNavigators } = useSelector(getNavigatorState);
  const { currentTab, tabs } = useSelector(getBrowserState);
  const assessmentState = useSelector(getAssessmentState);
  const riskAssessmentState = useSelector(getRiskAssessmentState);
  const tocManagementState = useSelector(getTOCManagementState);
  const reminderTaskState = useSelector(getReminderTaskState);
  const newManualTaskState = useSelector(getNewManualTaskState);
  const sendOrderState = useSelector(getSendOrderSlice);
  const admitTaskState = useSelector(getAdmitTaskState);
  const checkOnPatientState = useSelector(getCheckOnPatientSlice);
  const [isAddingToView, setIsAddingToView] = useState(false);
  const {
    dueDate,
    id,
    intakeId,
    attemptCount,
    patientName,
    physicianName,
    taskName,
    careManagerId,
    delayedAt,
    searchText,
    activeTabName,
    taskStatus,
    provider,
    providerType,
    taskTypeId,
    isOutdated,
    taskStatusId,
    taskTitle,
  } = props;
  const dispatch = useAppDispatch();

  const checkTaskIsTOCTask = checkIsTaskIsTOCTaskById(taskTypeId);

  const getDueDateClass = () => {
    const dayDiff = moment().diff(moment(dueDate.split("T")[0]), "days");
    if (dayDiff >= 1) {
      return "red";
    } else {
      return "grey";
    }
  };

  const showDelayUntilIcon = () => {
    if (
      attemptCount &&
      attemptCount > 0 &&
      delayedAt !== null &&
      moment().startOf("day").diff(moment(delayedAt).startOf("day")) === 0
    ) {
      return <Icon icon="calender-icon-task-list" className="delay-icon-image" size={18} />;
    } else {
      return null;
    }
  };

  const showDifferentNavigatorIcon = () => {
    if (backupNavigators && backupNavigators.length > 0) {
      const currentUser = backupNavigators.find((nav: INavigator) => nav.email === user.email);
      const userFromCareManagerId = backupNavigators.find((nav: INavigator) => nav.careManagerId === careManagerId);
      const name = userFromCareManagerId ? getPatientFullName(userFromCareManagerId) : "";
      if (careManagerId !== currentUser?.careManagerId) {
        return (
          <div className="handshake-icon" data-tip data-for={`Task owner: ${name}`}>
            <Icon icon="handshake-icon" size={18} />
            <CustomToolTip text={`Task owner: ${name}`} id={`Task owner: ${name}`} place={"bottom"} />
          </div>
        );
      } else {
        return null;
      }
    }
  };

  const checkSelectedTask = () => tabs.findIndex((t) => t.intakeId === intakeId && t.taskId === id) > -1;

  const memoizeTask = () => {
    if (!currentTab) {
      return;
    }
    const { taskType } = currentTab;

    let taskPayload = null;
    if (taskType === TaskPageName.RISK_ASSESSMENT) {
      taskPayload = riskAssessmentState;
    } else if (taskType === TaskPageName.TRANSITION_OF_CARE) {
      taskPayload = tocManagementState;
    } else if (taskType === TaskPageName.ASSESSMENT) {
      taskPayload = assessmentState;
    } else if (taskType === TaskPageName.REMINDER_TASK) {
      taskPayload = reminderTaskState;
    } else if (taskType === TaskPageName.NEW_MANUAL_TASK) {
      taskPayload = newManualTaskState;
    } else if (taskType === TaskPageName.SEND_ORDER) {
      taskPayload = sendOrderState;
    } else if (taskType === TaskPageName.ADMIT_TASK) {
      taskPayload = admitTaskState;
    } else if (taskType === TaskPageName.CHECK_ON_PATIENT) {
      taskPayload = checkOnPatientState;
    }

    dispatch(memoizeTaskPayloadForCurrentPage({ taskPayload, currentTab }));
  };

  const handleTabHandling = () => {
    const selectedTab = tabs.find((t) => t.taskId === id);
    const taskType: string = getTaskNameBasedOnTaskTypeId(taskTypeId);
    memoizeTask();

    if (!selectedTab) {
      dispatch(addNewTab({ taskId: id, intakeId, taskType, title: `${patientName} | ${taskName}` }));
      dispatch(resetSelections());
    } else {
      dispatch(setCurrentTab(selectedTab));
    }
    dispatch(expandTaskBoard(false));
  };

  const handleOnClick = () => {
    if (isAddingToView) {
      return;
    }
    setIsAddingToView(true);
    const selectedTab = tabs.find((t) => t.taskId === id);
    const taskType: string = getTaskNameBasedOnTaskTypeId(taskTypeId);

    if (taskType === TaskPageName.TRANSITION_OF_CARE) {
      if (!selectedTab && tabs.length === TASKS_LIMIT) {
        dispatch(setShowTabLimitModal(true));
        setIsAddingToView(false);
        return;
      }
      handleTabHandling();
      setIsAddingToView(false);
      return;
    }
    if (!selectedTab) {
      if (tabs.length === TASKS_LIMIT) {
        dispatch(setShowTabLimitModal(true));
        setIsAddingToView(false);
        return;
      }
      dispatch(getTaskAccess({ taskId: id, intakeId: parseInt(intakeId) })).then((response) => {
        if (!response?.payload?.data) {
          handleTabHandling();
        } else {
          dispatch(
            setRequestedTaskPayload({
              taskId: id,
              intakeId,
              title: `${patientName} | ${taskName}`,
              taskType: taskType,
            })
          );
          dispatch(
            setIsOpenMultipleTabPopup({
              isOpen: true,
              ...response.payload.data,
            })
          );
        }

        setIsAddingToView(false);
      });
    } else {
      setIsAddingToView(false);
      dispatch(setCurrentTab(selectedTab));
      dispatch(expandTaskBoard(false));
    }
  };

  return (
    <div id={id} className={`task ${checkSelectedTask() ? "selected-task" : ""}`} role="button" onClick={handleOnClick}>
      <div className={`task-info-container`}>
        <div className="task-info">
          <div className="task-info-sections">
            <div className="left-section">
              <div className="name-and-attempts">
                <div className="name" data-tip data-for={taskName}>
                  {getHighlightedText(taskName, searchText ?? "")}{" "}
                  <CustomToolTip text={taskName} id={taskName} place="top" />
                </div>
                {attemptCount && <Days className="attempt-count" days={attemptCount} />}
                {activeTabName === TaskManagementTab.ACTIVE ? showDelayUntilIcon() : null}
                {showDifferentNavigatorIcon()}
              </div>
              <div className="patient-details">Pat: {getHighlightedText(patientName, searchText ?? "")}</div>
            </div>
            <div className="right-section">
              <div className="date">
                <div className="due">Due:</div>
                <div className={`due-date ${getDueDateClass()}`}>
                  {moment(dueDate.split("Z")[0]).format("MM/DD/YYYY")}
                </div>
              </div>
              <div className="physician">
                <div className="phy">Phy:</div>
                <div className="physician-name">
                  {physicianName ? getHighlightedText(physicianName, searchText ?? "") : "-"}
                </div>
              </div>
            </div>
          </div>
          {(checkTaskIsTOCTask || taskTypeId === FormType.REMINDER) && (
            <TocTasksServiceProvidersDetails
              searchText={searchText}
              details={
                taskTypeId === FormType.REMINDER
                  ? `Title: ${taskTitle ?? "-"}`
                  : `${providerType && !isEmptyString(providerType) ? providerType : "-"} | ${
                      provider && !isEmptyString(provider) ? removeLineBreaksFromText(provider) : "-"
                    }`
              }
              isObselete={
                taskTypeId !== FormType.REMINDER &&
                isOutdated &&
                taskStatus !== null &&
                (taskStatusId === TaskStatus.IN_PROGRESS || taskStatusId === TaskStatus.PROCESSING)
                  ? true
                  : false
              }
              notShowTooltip={
                checkTaskIsTOCTask &&
                (provider === null ||
                  isEmptyString(provider ?? "") ||
                  providerType === null ||
                  isEmptyString(providerType ?? ""))
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Task;
