import TextField from "components/TextField/text-field.component";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ITagsSearchDropdownType } from "shared/types/dropdown.type";
import { getAdminState } from "state/feature/admin/admin.slice";
import TagsSearchDropdown from "components/TagsSearchDropdown/tags-search-dropdown.component";
import Button from "components/Button/button.component";
import { TagsSearchDropdownUseCase } from "components/TagsSearchDropdown/tags-search-dropdown.enum";
import "./provider-edit.styles.scss";
import { setIsOpenEditProviderModal } from "state/feature/common/common.slice";
import { IProviderEditRequestPayload } from "state/types/admin-slice.type";
import { useAppDispatch } from "state/store";
import { getProvidersTabProviderList, updateProviderFacilitesAsync } from "state/feature/admin/admin.action";
import { toast } from "react-toastify";
import { PROVIDER_EDITED_SUCCESSFULLY, SOMETHING_WENT_WRONG } from "shared/constant/commonConstants";
import { isEmptyString } from "shared/methods/utilityFunctions";
const ProviderEdit = () => {
  const dispatch = useDispatch();
  const {
    selectedPractice,
    providers: {
      provider: { currentProvider, privelegesAtList, providerGetRequest },
    },
  } = useSelector(getAdminState);

  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isPristine, setIsPristine] = useState(true);
  const [selectedPrivelegesAt, setSelectedPrivelegesAt] = useState<Array<{ key: string; name: string }>>([]);
  const appDispatch = useAppDispatch();

  useEffect(() => {
    if (currentProvider && currentProvider?.priviledgeFacilities?.length) {
      setSelectedPrivelegesAt(
        currentProvider.priviledgeFacilities.map((priveledge) => ({
          key: priveledge?.id?.toString() ?? "",
          name: priveledge?.name ?? "",
        }))
      );
    }
  }, [currentProvider]);
  const compareSearchText = (value1: string, value2: string): boolean => {
    return value1.toLowerCase().includes(value2.toLowerCase());
  };

  const filterPrivelgesAtDropdown = (obj: ITagsSearchDropdownType): boolean => {
    if (selectedPrivelegesAt.length > 0) {
      const isPresent: boolean =
        !selectedPrivelegesAt.some((item: ITagsSearchDropdownType) => item.key === obj.key) &&
        compareSearchText(obj.name, searchText);
      return isPresent;
    } else {
      return compareSearchText(obj.name, searchText);
    }
  };

  const getHavePrivelegesAtDropdownMenu = (): ITagsSearchDropdownType[] => {
    return privelegesAtList
      .map((privelege) => ({ key: privelege.ID.toString(), name: privelege.ProviderName } as ITagsSearchDropdownType))
      .filter((obj: ITagsSearchDropdownType) => filterPrivelgesAtDropdown(obj));
  };

  const handleSave = async () => {
    if (currentProvider && currentProvider.id && selectedPractice?.key) {
      setIsLoading(true);
      const request: IProviderEditRequestPayload = {
        providerId: currentProvider!.id,
        facilityIds: selectedPrivelegesAt.map((priveledge) => parseInt(priveledge.key)),
        clientId: Number(selectedPractice.key),
      };
      try {
        await appDispatch(updateProviderFacilitesAsync(request)).unwrap();
        toast.success(PROVIDER_EDITED_SUCCESSFULLY, {
          toastId: "edit-provider-sucess",
          containerId: "main",
        });
        dispatch(setIsOpenEditProviderModal(false));
        dispatch(getProvidersTabProviderList(providerGetRequest));
      } catch (error) {
        toast.error(SOMETHING_WENT_WRONG, {
          toastId: "edit-provider-error",
          containerId: "main",
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (currentProvider?.priviledgeFacilities?.length) {
      setSelectedPrivelegesAt(
        currentProvider.priviledgeFacilities.map(({ id, name }) => ({ key: id.toString(), name }))
      );
    }
  }, [currentProvider?.priviledgeFacilities]);

  return (
    <div className="provider-edit-container">
      <div className="title">Provider Privileges</div>
      <div className="form-body">
        <div className="row">
          <div className="col">
            <TextField
              label="Name"
              type="text"
              value={currentProvider?.name && !isEmptyString(currentProvider.name) ? currentProvider.name : "-"}
              inputClassName="disabled"
              disabled={true}
              setValue={() => {}}
            />
          </div>
          <div className="col">
            <TextField
              label="Provider Type"
              type="text"
              value={currentProvider?.type && !isEmptyString(currentProvider.type) ? currentProvider.type : "-"}
              inputClassName="disabled"
              disabled={true}
              setValue={() => {}}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="dropdown-label">
              Have Privileges At
              <span className="optional-text">{`(${selectedPrivelegesAt.length} of 5 selected)`}</span>
            </div>

            <TagsSearchDropdown
              idFieldName=""
              value={selectedPrivelegesAt}
              dropDownContainerClass="assigned-privileges-dropdown-container"
              dropDownBoxClass="assigned-privileges-dropdown"
              selectionClass="assigned-privileges-selection "
              onSearchTextChange={(val: string) => {
                setSearchText(val);
              }}
              handleValueChanges={(val: any) => {
                if (selectedPrivelegesAt.length <= 5) {
                  setSelectedPrivelegesAt(val);
                  setIsPristine(false);
                }
              }}
              dropDownMenuItems={getHavePrivelegesAtDropdownMenu()}
              placeholder="Search by acute care hospital"
              useCaseType={TagsSearchDropdownUseCase.PRIVELEGES_AT}
              maximumRange={5}
              renderFrom="admin-provider"
            />
          </div>
        </div>
      </div>
      <div className="form-footer">
        <Button
          text="Cancel"
          className={`cancel-button`}
          disabled={isLoading}
          onClick={() => {
            dispatch(setIsOpenEditProviderModal(false));
          }}
        />
        <Button text="Save" className={`submit-button`} disabled={isLoading || isPristine} onClick={handleSave} />
      </div>
    </div>
  );
};

export default ProviderEdit;
