import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from "@microsoft/signalr";
import Icon from "components/Icon/icon.component";
import { getConfig } from "config/config";
import moment from "moment";
import { Dispatch, SetStateAction, createContext, useCallback, useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { SignalREvents } from "shared/enums/signalR-events.enum";
import {
  getEndDate,
  getNotificationDescription,
  getStartDate,
  isNotificationStale,
} from "shared/methods/utilityFunctions";
import { acknowledgeNotification } from "state/feature/notification/notification.action";
import { setShouldRefreshNotifications } from "state/feature/notification/notification.slice";
import store, { useAppDispatch } from "state/store";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setLastMessageNotificationStatus } from "state/feature/messaging/messaging.slice";
import { getAllConversationsAsync } from "state/feature/messaging/messaging.action";
import { getCommonState } from "state/feature/common/common.slice";

interface SignalRContextType {
  isReconnecting: boolean;
  setIsReconnecting: Dispatch<SetStateAction<boolean>>;
  connection: HubConnection | null;
  startSignalRConnection: (userEmail: string) => void;
  reconnectSignalR: (userEmail: string) => void;
  disconnectSignalR: () => void;
  disconnectSignalrOnLogout: (userEmail: string) => void;
}

const SignalRServiceContext = createContext<SignalRContextType | null>(null);

export const SignalRServiceProvider = (props: any) => {
  const appDispatch = useAppDispatch();
  const { featureFlags } = useSelector(getCommonState);
  const [value, setValue] = useState<SignalRContextType | null>(null);
  const [connection, setConnection] = useState<HubConnection | null>(null);
  const [isReconnecting, setIsReconnecting] = useState(false);
  const startSignalRConnection = useCallback(
    (userEmail) => {
      const { auth } = store.getState();
      if (auth.auth.isAuthenticated && connection?.state === HubConnectionState.Disconnected) {
        connection
          ?.start()
          .then(() => {
            console.log("SignalR connected.");
            const addUserToGroup = () => {
              if (userEmail != "") {
                console.log("Added to group: ", userEmail);
                connection.invoke("JoinGroup", userEmail);
              } else {
                console.log("Did not add user: ", userEmail);
                setTimeout(addUserToGroup, 5000);
              }
            };
            addUserToGroup();
          })
          .catch((err) => {
            console.error("SignalR connection failed: ", err);
          });
      }
    },
    [connection]
  );

  const stopSignalRConnection = useCallback(() => {
    connection
      ?.stop()
      .then(() => {
        console.log("SignalR disconnected.");
      })
      .catch((err) => {
        console.error("SignalR disconnection failed: ", err);
      });
  }, [connection]);

  const reconnectSignalR = useCallback(
    (userEmail) => {
      if (!isReconnecting && connection?.state === HubConnectionState.Disconnected) {
        startSignalRConnection(userEmail);
      }
    },
    [connection?.state, isReconnecting, startSignalRConnection]
  );
  const messageReceivedCallback = useCallback(
    async (receivedNotification: any) => {
      const { auth, common, messaging, notification } = store.getState();
      const eventMsg = getNotificationDescription(receivedNotification);
      if (
        isNotificationStale(
          receivedNotification.payload.conversationId,
          common.modal,
          messaging.selectedConversation.conversationId
        )
      ) {
        const result = await appDispatch(
          acknowledgeNotification({
            internalId: receivedNotification.internalId,
            ownerUserId: receivedNotification.ownerUserId,
            createdBefore: moment().toISOString(),
          })
        );
        appDispatch(setLastMessageNotificationStatus(receivedNotification.payload.conversationId));
        if (result.meta.requestStatus === "fulfilled") {
          appDispatch(setShouldRefreshNotifications(!notification.shouldRefreshNotifications));
        }
      } else {
        if (!isMobile) {
          toast.dismiss("notify");
          toast.info(eventMsg, {
            containerId: "notification",
            toastId: "notify",
            icon: <Icon icon="notification-toast" size={24} />,
            progressClassName: "notification-toast-progress-bar-color",
          });
          appDispatch(setShouldRefreshNotifications(!notification.shouldRefreshNotifications));
        }
      }
      if (messaging.conversations.length > 0) {
        appDispatch(
          getAllConversationsAsync({
            ...messaging.convRequestPayload,
            userID: auth.user.id,
            convStartDate: getStartDate(new Date(messaging.convRequestPayload.convStartDate)),
            convEndDate: getEndDate(new Date(messaging.convRequestPayload.convEndDate)),
          })
        );
      }
    },
    [appDispatch]
  );

  const disconnectSignalR = useCallback(() => {
    if (connection?.state === HubConnectionState.Connected) {
      stopSignalRConnection();
    }
  }, [connection?.state, stopSignalRConnection]);

  const disconnectSignalrOnLogout = useCallback(
    (userEmail) => {
      if (connection?.state == HubConnectionState.Connected) {
        connection.invoke("LeaveGroup", userEmail);
        disconnectSignalR();
      } else {
        console.log("No active signalR connection to disconnect");
      }
    },
    [connection, disconnectSignalR]
  );

  useEffect(() => {
    const { common } = store.getState();
    if (common.featureFlags.inAppNotifications && !connection) {
      setConnection(
        new HubConnectionBuilder()
          .withUrl(`${getConfig().notificationBase}/notifications`)
          .withAutomaticReconnect()
          .configureLogging(LogLevel.Error)
          .build()
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureFlags.inAppNotifications]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    connection?.on(SignalREvents.MESSAGE_RECEIVED, messageReceivedCallback);
    connection?.onreconnecting((error) => {
      setIsReconnecting(true);
      console.log(`SignalR connection lost. Attempting to reconnect: ${error}.`);
    });
    connection?.onreconnected((connectionId: string | undefined) => {
      setIsReconnecting(false);
      console.log(`SignalR reconnected successfully. New connection ID: ${connectionId}.`);
    });
    connection?.onclose((error) => {
      console.log(`SignalR connection closed. Error: ${error}`);
      // try to reconnect after 5 seconds
      timeout = setTimeout(reconnectSignalR, 50000);
    });

    return () => {
      connection?.off(SignalREvents.MESSAGE_RECEIVED);
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection, reconnectSignalR]);

  useEffect(() => {
    setValue({
      connection: connection,
      isReconnecting: isReconnecting,
      setIsReconnecting: setIsReconnecting,
      startSignalRConnection: startSignalRConnection,
      reconnectSignalR: reconnectSignalR,
      disconnectSignalR: disconnectSignalR,
      disconnectSignalrOnLogout: disconnectSignalrOnLogout,
    });
  }, [
    connection,
    isReconnecting,
    setIsReconnecting,
    startSignalRConnection,
    reconnectSignalR,
    disconnectSignalR,
    disconnectSignalrOnLogout,
  ]);

  return <SignalRServiceContext.Provider value={value}>{props.children}</SignalRServiceContext.Provider>;
};

export const useSignalRService = () => {
  return useContext(SignalRServiceContext);
};
