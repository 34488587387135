import POCServiceModalPresentation from "./poc-service-modal.component";
import { getCommonState, setIsOpenPOCServiceModal } from "state/feature/common/common.slice";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch } from "state/store";
import { useSelector } from "react-redux";
import { getEpisodesSlice } from "state/feature/episodes/episodes.slice";
import { FacilityValue } from "pages/task-management/admit-task/admit-task.props";
import {
  createCarePlanUtilizationPACLogs,
  deleteCarePlanAsync,
  getCarePlanDataAsync,
  getFacilitiesForPlanOfCare,
  getReadmissionReasons,
  getReasonForService,
  getServiceTypes,
  postPlanOfCareDataAsync,
  putPlanOfCareDataAsync,
} from "state/feature/plan-of-care/plan-of-care.action";
import {
  getPlanOfCareState,
  resetPlanOfCareState,
  setCarePlanLoading,
  setProviderForPlanOfCare,
} from "state/feature/plan-of-care/plan-of-care.slice";
import { useParams } from "react-router";
import { getPatientFullName, isEmptyString } from "shared/methods/utilityFunctions";
import moment from "moment";
import { AddedPOCLogText, DeleteLogText, Other, UpdatedPOCLogText } from "./poc-service-modal.constant";
import { getPlanOfCareListAsync } from "state/feature/episodes/episodes.action";
import { DaysVisit, IntakeStatus, ServiceType } from "shared/enums/plan-of-care.enum";
import { getAuth } from "state/feature/auth/auth.slice";
import {
  CreateCarePlanPACLogsRequestPayload,
  GetCarePlanUtilizationPACLogsPayload,
  POCPayloadType,
} from "./poc-service-modal.props";
import { getTaskManagementState } from "state/feature/task-management/task-management.slice";

const POCServiceModal = () => {
  const dispatch = useAppDispatch();
  const { intakeId } = useParams<{ intakeId: string }>();
  const { episodeDetails } = useSelector(getEpisodesSlice);
  const { browser } = useSelector(getTaskManagementState);
  const [isSubmitButtonPressed, setIsSubmitButtonPressed] = useState(false);
  const [enableSaveButton, setEnableSaveButton] = useState(true);
  const { selectedPlanOfCareValues, id } = useSelector(getPlanOfCareState);
  const [isScrollVisible, setIsScrollVisible] = useState(false);
  const { modal } = useSelector(getCommonState);
  const { user } = useSelector(getAuth);
  const initialRequestPayload = { intakeId: intakeId ?? browser.currentPage?.intakeId, sortBy: "", sortOrder: "" };

  const isRequiredDayVisit = useCallback(
    () =>
      selectedPlanOfCareValues.serviceType.daysType &&
      !isEmptyString(selectedPlanOfCareValues.serviceType.daysType) &&
      selectedPlanOfCareValues.serviceType.daysType === DaysVisit.VISIT &&
      selectedPlanOfCareValues.dischargeDate
        ? true
        : false,
    [selectedPlanOfCareValues.serviceType, selectedPlanOfCareValues.dischargeDate]
  );

  const isErrorLosEmptyIfDischargeDateAdded = useCallback(
    () =>
      isRequiredDayVisit() &&
      (selectedPlanOfCareValues.los === null || isEmptyString(selectedPlanOfCareValues.los ?? ""))
        ? true
        : false,
    [isRequiredDayVisit, selectedPlanOfCareValues.los]
  );

  const handleModalClose = () => {
    setIsSubmitButtonPressed(false);
    setEnableSaveButton(true);
    dispatch(resetPlanOfCareState());
    dispatch(setIsOpenPOCServiceModal({ isOpen: false, isEdit: false }));
  };

  const onFacilityChange = (value: FacilityValue) => {
    dispatch(setProviderForPlanOfCare({ key: value.providerId, value: value.providerName }));
  };

  const checkConditionForReadmission = () => {
    if (
      selectedPlanOfCareValues.serviceType.name === ServiceType.ER_URGENT_CARE ||
      selectedPlanOfCareValues.serviceType.name === ServiceType.HOSPITAL_OBSERVATION ||
      selectedPlanOfCareValues.serviceType.name === ServiceType.HOSPITAL_READMIT ||
      selectedPlanOfCareValues.serviceType.name === ServiceType.OP_PROCEDURE_SERGERY
    ) {
      if (!isEmptyString(selectedPlanOfCareValues.readmissionReason.name)) {
        if (selectedPlanOfCareValues.readmissionReason.name === Other) {
          return selectedPlanOfCareValues.otherReason.length > 0;
        }
        return true;
      }
      return false;
    }
    return true;
  };

  const checkDataBeforeSubmit = () => {
    if (
      !isEmptyString(selectedPlanOfCareValues.serviceType.name) &&
      selectedPlanOfCareValues.serviceType.name === ServiceType.HOME_WITHOUT_SERVICES
    ) {
      return selectedPlanOfCareValues.admissionDate !== null;
    }
    return (
      !isEmptyString(selectedPlanOfCareValues.serviceType.name) &&
      !isEmptyString(selectedPlanOfCareValues.provider.value) &&
      (selectedPlanOfCareValues.los && !isEmptyString(selectedPlanOfCareValues.los)
        ? parseInt(selectedPlanOfCareValues.los) <= 200 && parseInt(selectedPlanOfCareValues.los) > 0
        : !isErrorLosEmptyIfDischargeDateAdded()) &&
      selectedPlanOfCareValues.admissionDate !== null &&
      checkConditionForReadmission() &&
      !isEmptyString(selectedPlanOfCareValues.reason.name)
    );
  };

  const getIsServiceTypeReadmission = () => {
    if (
      selectedPlanOfCareValues.serviceType.name === ServiceType.ER_URGENT_CARE ||
      selectedPlanOfCareValues.serviceType.name === ServiceType.HOSPITAL_OBSERVATION ||
      selectedPlanOfCareValues.serviceType.name === ServiceType.HOSPITAL_READMIT ||
      selectedPlanOfCareValues.serviceType.name === ServiceType.OP_PROCEDURE_SERGERY
    ) {
      return true;
    }
    return false;
  };

  const handleIsSaveButtonEnabled = () => {
    setEnableSaveButton(false);
  };

  const getIfProviderPresent = (isChecked: boolean) => {
    return dispatch(
      getFacilitiesForPlanOfCare({
        intakeId: intakeId ?? browser.currentPage?.intakeId,
        activeInactive: isChecked,
        id: selectedPlanOfCareValues.provider.key,
      })
    );
  };

  const getPayload = () => {
    const clientId = episodeDetails?.clientId
      ? episodeDetails?.clientId
      : browser?.currentPage?.patientDetails?.clientId;
    const payload: POCPayloadType = {
      pacTypeID: selectedPlanOfCareValues.serviceType.key,
      isServiceTypeReadmission: getIsServiceTypeReadmission(),
      providerId: selectedPlanOfCareValues.provider.key,
      appointmentNo: selectedPlanOfCareValues.los,
      admissionDate: selectedPlanOfCareValues.admissionDate
        ? moment(selectedPlanOfCareValues.admissionDate).format("MM/DD/YYYY")
        : "",
      dischargeDate:
        selectedPlanOfCareValues.dischargeDate !== null
          ? moment(selectedPlanOfCareValues.dischargeDate).format("MM/DD/YYYY")
          : "",
      readmissionReasonId: selectedPlanOfCareValues.readmissionReason.key,
      isReadmissionReasonTypeOther: selectedPlanOfCareValues.readmissionReason.name === Other,
      reasonForOtherReadmission: selectedPlanOfCareValues.otherReason,
      notes: selectedPlanOfCareValues.notes,
      clientId,
      intakeId: intakeId ?? browser.currentPage?.intakeId,
      reasonForServiceId: selectedPlanOfCareValues.reason.key,
      updatedBy: user.navigatorId,
    };
    if (!modal.isOpenPOCServiceModal.isEdit) {
      payload.createdBy = user.navigatorId;
    }
    return payload;
  };

  const getEditableStatus = () => {
    if (
      browser &&
      browser.currentPage &&
      !(
        browser.currentPage.episode?.patientAndEpisodeDetails.intakeStatusId === IntakeStatus.CLOSED ||
        browser.currentPage.episode?.patientAndEpisodeDetails.intakeStatusId === IntakeStatus.CANCELED ||
        browser.currentPage.episode?.patientAndEpisodeDetails.intakeStatusId === IntakeStatus.DECEASED ||
        browser.currentPage.episode?.patientAndEpisodeDetails.intakeStatusId === IntakeStatus.DISCHARGED
      )
    ) {
      return true;
    }
    if (
      episodeDetails &&
      !(
        episodeDetails.intakeStatusId === IntakeStatus.CLOSED ||
        episodeDetails.intakeStatusId === IntakeStatus.CANCELED ||
        episodeDetails.intakeStatusId === IntakeStatus.DECEASED ||
        episodeDetails.intakeStatusId === IntakeStatus.DISCHARGED
      )
    ) {
      return true;
    }
    return false;
  };

  const getCarePlanUtilizationPACLogsPayload = (
    props: GetCarePlanUtilizationPACLogsPayload
  ): CreateCarePlanPACLogsRequestPayload => {
    const { planOfCareTaskStatus, taskId } = props;

    const losVisitValue =
      selectedPlanOfCareValues.los && !isEmptyString(selectedPlanOfCareValues.los)
        ? parseInt(selectedPlanOfCareValues.los)
        : null;
    const los = losVisitValue && selectedPlanOfCareValues.serviceType.daysType === DaysVisit.LOS ? losVisitValue : null;
    const visits =
      losVisitValue && selectedPlanOfCareValues.serviceType.daysType === DaysVisit.VISIT ? losVisitValue : null;
    const payload: CreateCarePlanPACLogsRequestPayload = {
      intakeId: intakeId ?? browser.currentPage?.intakeId,
      user: getPatientFullName({
        firstName: user?.firstName,
        lastName: user?.lastName,
      }),
      userId: user.navigatorId,
      createdDate: moment().utc().format(),
      notes: selectedPlanOfCareValues.notes ?? null,
      los,
      visits,
      service: selectedPlanOfCareValues.serviceType.name,
      serviceReason: selectedPlanOfCareValues.reason.name,
      provider:
        selectedPlanOfCareValues.provider.value && !isEmptyString(selectedPlanOfCareValues.provider.value)
          ? selectedPlanOfCareValues.provider.value
          : "",
      providerId:
        selectedPlanOfCareValues.provider.key && !isEmptyString(selectedPlanOfCareValues.provider.key)
          ? parseInt(selectedPlanOfCareValues.provider.key)
          : null,
      admissionDate: selectedPlanOfCareValues.admissionDate
        ? moment(selectedPlanOfCareValues.admissionDate).format("MM/DD/YYYY")
        : "",
      dischargeDate:
        selectedPlanOfCareValues.dischargeDate !== null
          ? moment(selectedPlanOfCareValues.dischargeDate).format("MM/DD/YYYY")
          : null,
      otherReason: !isEmptyString(selectedPlanOfCareValues.otherReason) ? selectedPlanOfCareValues.otherReason : null,
      readmissionReason: !isEmptyString(selectedPlanOfCareValues.readmissionReason.name)
        ? selectedPlanOfCareValues.readmissionReason.name
        : null,
      taskId,
      planOfCareTaskStatus,
    };
    return payload;
  };

  const handleSubmitForDeleteConfirmation = () => {
    dispatch(deleteCarePlanAsync(id)).then((response) => {
      if (response.payload.statusCode === 200) {
        const careUtilizationPacLogsPayload = getCarePlanUtilizationPACLogsPayload({
          taskId: id,
          planOfCareTaskStatus: DeleteLogText,
        });
        dispatch(createCarePlanUtilizationPACLogs(careUtilizationPacLogsPayload)).finally(() => {
          dispatch(getPlanOfCareListAsync(initialRequestPayload));
          setEnableSaveButton(true);
          setIsSubmitButtonPressed(false);
          handleModalClose();
        });
      }
    });
  };

  const onClickSaveButton = () => {
    setIsSubmitButtonPressed(true);
    setEnableSaveButton(true);
    if (checkDataBeforeSubmit()) {
      const payload = getPayload();
      if (!modal.isOpenPOCServiceModal.isEdit) {
        dispatch(postPlanOfCareDataAsync(payload)).then((response) => {
          if (response.payload.statusCode === 201 && response.payload.data) {
            const careUtilizationPacLogsPayload = getCarePlanUtilizationPACLogsPayload({
              taskId: response.payload.data,
              planOfCareTaskStatus: AddedPOCLogText,
            });
            dispatch(createCarePlanUtilizationPACLogs(careUtilizationPacLogsPayload)).finally(() => {
              dispatch(getPlanOfCareListAsync(initialRequestPayload));
              setEnableSaveButton(true);
              setIsSubmitButtonPressed(false);
              handleModalClose();
            });
          }
        });
      } else {
        dispatch(putPlanOfCareDataAsync({ ...payload, id })).then((response) => {
          if (response.payload.statusCode === 200) {
            const careUtilizationPacLogsPayload = getCarePlanUtilizationPACLogsPayload({
              taskId: id,
              planOfCareTaskStatus: UpdatedPOCLogText,
            });
            dispatch(createCarePlanUtilizationPACLogs(careUtilizationPacLogsPayload)).finally(() => {
              dispatch(getPlanOfCareListAsync(initialRequestPayload));
              setEnableSaveButton(true);
              setIsSubmitButtonPressed(false);
              handleModalClose();
            });
          }
        });
      }
    } else {
      setEnableSaveButton(false);
    }
  };

  const ifScrollVisible = () => {
    const form = document.querySelector(".poc-service-modal-form");
    if (form) {
      setIsScrollVisible(form.scrollHeight > form.clientHeight);
    }
  };

  useEffect(() => {
    ifScrollVisible();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlanOfCareValues]);

  useEffect(() => {
    dispatch(getServiceTypes());
    dispatch(getReadmissionReasons());
    dispatch(getReasonForService());
  }, [dispatch]);

  useEffect(() => {
    if (intakeId || browser.currentPage?.intakeId) {
      dispatch(setCarePlanLoading(true));
      dispatch(
        getFacilitiesForPlanOfCare({
          intakeId: intakeId ?? browser.currentPage?.intakeId,
          activeInactive: modal.isOpenPOCServiceModal.isEdit,
        })
      ).then(() => {
        if (modal.isOpenPOCServiceModal.isEdit && !isEmptyString(id)) {
          dispatch(getCarePlanDataAsync({ id, isEdit: modal.isOpenPOCServiceModal.isEdit })).then(() => {
            const includeInactiveCheckbox = document.getElementById("inactive") as HTMLInputElement;
            if (includeInactiveCheckbox) {
              includeInactiveCheckbox.checked = true;
            }
          });
        }
      });
    }
  }, [modal.isOpenPOCServiceModal.isEdit, id, dispatch, intakeId, browser.currentPage?.intakeId]);

  return (
    <POCServiceModalPresentation
      handleModalClose={handleModalClose}
      onFacilityChange={onFacilityChange}
      isSubmitButtonPressed={isSubmitButtonPressed}
      onClickSaveButton={onClickSaveButton}
      enableSaveButton={enableSaveButton}
      handleIsSaveButtonEnabled={handleIsSaveButtonEnabled}
      isEditable={getEditableStatus()}
      handleSubmitForDeleteConfirmation={handleSubmitForDeleteConfirmation}
      getIfProviderPresent={getIfProviderPresent}
      isScrollVisible={isScrollVisible}
      isErrorLosEmptyIfDischargeDateAdded={isErrorLosEmptyIfDischargeDateAdded()}
      isRequiredDayVisit={isRequiredDayVisit()}
    />
  );
};
export default POCServiceModal;
