export const ReminderTaskFeilds = {
  title: {
    label: "Title",
    order: 1,
    placeHolder: "Enter the title of the task here",
    maxLength: 100,
  },
  navigatorNotes: {
    label: "Navigator Notes",
    order: 1,
    placeHolder: "Write your notes here for the physician",
    maxLength: 5000,
  },
};
