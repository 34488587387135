import { useEffect, useRef } from "react";
import Icon from "../Icon/icon.component";
import { SearchBoxProps } from "./props";
import "./search-box.styles.scss";

const SearchBox = ({
  text,
  className,
  onClick,
  icon,
  onTextChange,
  placeholder = "Search By Name",
  showIcon = false,
  onBlur,
  autoFocus = true,
}: SearchBoxProps) => {
  const searchInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (!showIcon && searchInputRef?.current) {
      searchInputRef.current.value = "";
    }
  }, [showIcon]);

  if (icon) {
    return (
      <div className={`${className ? className : ""} icon-button`}>
        <div className="input-plus-lens">
          <Icon icon="search-lens" size={16} className="search-lens-icon" />
          <input
            tabIndex={-1}
            className="input-field"
            type="text"
            placeholder={placeholder}
            onChange={(event) => {
              onTextChange(event.target.value);
            }}
            value={text}
            autoFocus={autoFocus}
            ref={searchInputRef}
          />
        </div>
        {showIcon && (
          <div className="icon-section" onClick={onClick}>
            <Icon icon="search-cross" size={16} className="search-cross-icon" />
          </div>
        )}
      </div>
    );
  }

  return (
    <input
      type="SearchBox"
      className={`${className}`}
      onClick={onClick}
      value={text}
      onBlur={onBlur}
      onChange={(event) => {
        onTextChange(event.target.value);
      }}
    />
  );
};

export default SearchBox;
