import { useEffect, useState } from "react";
import { HeaderColumnInfo } from "components/table-with-pagination/table-with-pagination.component";
import { useDispatch, useSelector } from "react-redux";
import { getAdminState, resetProvidersProvider, setProvidersProviderGetRequest } from "state/feature/admin/admin.slice";
import { getAllPrivelegesListAsync, getProvidersTabProviderList } from "state/feature/admin/admin.action";
import "./provider.style.scss";
import { getInitialAdminProvidersProviderHeader } from "shared/constant/table";
import { SortingOrderType } from "shared/enums/sorting-types.enums";
import ProviderPresentation from "./provider.component";

const Provider = () => {
  const dispatch = useDispatch();
  const {
    selectedPractice,
    providers: { provider },
  } = useSelector(getAdminState);
  const [columnsHeaderInfo, setHeaderColumnsInfo] = useState<Array<HeaderColumnInfo>>(
    getInitialAdminProvidersProviderHeader()
  );

  const onPageChange = (pageCount: number) => {
    dispatch(setProvidersProviderGetRequest({ pageCount: pageCount - 1 }));
  };

  const handleSort = (col: HeaderColumnInfo) => {
    const index = columnsHeaderInfo.findIndex((x) => x.key === col.key);
    let sort: SortingOrderType = SortingOrderType.DEFAULT;
    switch (columnsHeaderInfo[index].sortOrder) {
      case SortingOrderType.DESC:
      case SortingOrderType.DEFAULT:
        sort = SortingOrderType.ASC;
        break;
      case SortingOrderType.ASC:
        sort = SortingOrderType.DESC;
        break;
    }
    const tempHeaders = [...columnsHeaderInfo];
    tempHeaders.forEach((header) => {
      if (header.sortOrder) {
        header.sortOrder = SortingOrderType.DEFAULT;
      }
    });
    tempHeaders[index].sortOrder = sort;
    setHeaderColumnsInfo(tempHeaders);
    dispatch(setProvidersProviderGetRequest({ pageCount: 0, sortOrder: sort }));
  };

  useEffect(() => {
    if (provider.providerGetRequest.clientId !== 0) {
      dispatch(getProvidersTabProviderList(provider.providerGetRequest));
    }
  }, [dispatch, provider.providerGetRequest]);

  useEffect(() => {
    if (selectedPractice?.key && selectedPractice.key !== 0) {
      dispatch(getAllPrivelegesListAsync(Number(selectedPractice.key)));
      dispatch(setProvidersProviderGetRequest({ pageCount: 0, clientId: Number(selectedPractice.key) }));
    }
  }, [dispatch, selectedPractice?.key]);

  useEffect(() => {
    return () => {
      dispatch(resetProvidersProvider());
    };
  }, [dispatch]);

  return (
    <ProviderPresentation columnsHeaderInfo={columnsHeaderInfo} handleSort={handleSort} onPageChange={onPageChange} />
  );
};

export default Provider;
