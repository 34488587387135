import { createSlice } from "@reduxjs/toolkit";
import { lowerCase } from "lodash";
import { toast } from "react-toastify";
import { IContactState, PatientContactType, PhysicianContactType } from "state/types/contact-slice.type";
import {
  getNavigatorContactsAsync,
  getNavigatorPatientContactAsync,
  getNavigatorPhysicianContactAsync,
} from "./contact.action";
import { ContactType } from "shared/enums/contact-type.enum";

const initialState: IContactState = {
  isLoading: false,
  isError: false,
  userId: "",
  allContacts: {
    all: [],
    count: null,
  },
  paginationOffset: {
    patientOffset: 0,
    practiceOffset: 0,
  },
  navigatorContacts: {
    isPatientContactsLoading: false,
    isPhysicianContactsLoading: false,
    patientContact: [],
    physicianContact: [],
  },
};

const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    setIsLoading: (state: any, action) => {
      state.isLoading = action.payload;
    },
    setIsPhysicianContactsLoading: (state: any, action) => {
      state.navigatorContacts.isPhysicianContactsLoading = action.payload;
    },
    setIsPatientContactsLoading: (state: any, action) => {
      state.navigatorContacts.isPatientContactsLoading = action.payload;
    },
    resetAllContacts: (state: any) => {
      state.allContacts = {
        all: [],
        count: null,
      };
    },
    resetContacts: (state: any) => initialState,
  },
  extraReducers: (builder) => {
    return (
      builder.addCase(getNavigatorContactsAsync.pending, (state: any, action) => {
        state.isLoading = true;
      }),
      builder.addCase(getNavigatorContactsAsync.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.navigatorContacts = action.payload;
        const patientContacts = action.payload?.patientContact.map((obj: PatientContactType) => {
          const newPatientObj = {
            clientId: obj.clientID,
            contactType: ContactType.PATIENT,
            description: obj.procedure,
            firstName: obj.patientFirstName,
            lastName: obj.patientLastName,
            lastContactedOn: null,
            patientContactDetails: obj.patientContactDetails,
            phoneNumber: obj.patientPhoneNumber,
            practicePhone: null,
            providerId: 0,
            thumbnailPath: null,
            userId: obj.patientUserID,
            email: obj.patientEmail,
          };
          return newPatientObj;
        });
        const physicianContacts = action.payload?.physicianContact.map((obj: PhysicianContactType) => {
          const newPhysicianObj = {
            clientId: obj.clientID,
            contactType: "Physician",
            description: "Practice",
            firstName: obj.physicianFirstName,
            lastName: obj.physicianLastName,
            lastContactedOn: null,
            patientContactDetails: null,
            phoneNumber: obj.physicianPhone,
            practicePhone: null,
            providerId: 0,
            thumbnailPath: null,
            userId: obj.physicianUserID,
            email: obj.physicianEmail,
          };
          return newPhysicianObj;
        });
        state.allContacts.all = patientContacts.concat(physicianContacts);
        if (action.payload.physicianContact.length > 0 || action.payload.patientContact.length > 0) {
          state.allContacts.count = {
            Patient: action.payload.physicianContact.length,
            Physician: action.payload.patientContact.length,
          };
        }
      }),
      builder.addCase(getNavigatorContactsAsync.rejected, (state: any, action) => {
        state.isLoading = false;
        state.isError = true;
        // If no results are found, clear the state
        state.navigatorContacts = {
          patientContact: [],
          physicianContact: [],
          response: null,
        };
      }),
      builder.addCase(getNavigatorPatientContactAsync.pending, (state: any, action) => {
        state.isLoading = true;
        state.navigatorContacts.isPatientContactsLoading = true;
      }),
      builder.addCase(getNavigatorPatientContactAsync.rejected, (state: any, action) => {
        state.isLoading = false;
        if (!action.meta.aborted) {
          state.navigatorContacts.isPatientContactsLoading = false;
          toast.error("Something went wrong", { containerId: "contactLoadingError" });
        }
      }),
      builder.addCase(getNavigatorPatientContactAsync.fulfilled, (state: IContactState, action) => {
        state.isLoading = false;
        state.navigatorContacts.isPatientContactsLoading = false;
        state.paginationOffset.patientOffset = action.payload.offset;
        const patientContacts = action.payload.data.patientContact?.map((obj: PatientContactType) => {
          const newPatientObj = {
            clientId: obj.clientID,
            contactType: "Patient",
            description: obj.procedure,
            firstName: obj.patientFirstName,
            lastName: obj.patientLastName,
            lastContactedOn: null,
            patientContactDetails: obj.patientContactDetails,
            phoneNumber: obj.patientPhoneNumber,
            practicePhone: null,
            providerId: 0,
            thumbnailPath: null,
            userId: obj.patientUserID,
            email: obj.patientEmail,
          };
          return newPatientObj;
        });
        state.allContacts.all = [...state.allContacts.all, ...patientContacts].sort((objA, objB) =>
          lowerCase(`${objA.firstName} ${objA.lastName}`).localeCompare(lowerCase(`${objB.firstName} ${objB.lastName}`))
        );
        if (action.payload.userId !== state.userId || action.payload.offset === 1) {
          state.navigatorContacts.patientContact = action.payload.data.patientContact;
        } else {
          state.navigatorContacts.patientContact = [
            ...state.navigatorContacts.patientContact,
            ...action.payload.data.patientContact,
          ];
        }
        state.userId = action.payload.userId;
      }),
      builder.addCase(getNavigatorPhysicianContactAsync.pending, (state: any, action) => {
        state.isLoading = true;
        state.navigatorContacts.isPhysicianContactsLoading = true;
      }),
      builder.addCase(getNavigatorPhysicianContactAsync.rejected, (state: any, action) => {
        state.isLoading = false;
        if (!action.meta.aborted) {
          state.navigatorContacts.isPhysicianContactsLoading = false;
          toast.error("Something went wrong", { containerId: "contactLoadingError" });
        }
      }),
      builder.addCase(getNavigatorPhysicianContactAsync.fulfilled, (state: IContactState, action) => {
        state.isLoading = false;
        state.navigatorContacts.isPhysicianContactsLoading = false;
        state.paginationOffset.practiceOffset = action.payload.offset;
        const physicianContacts = action.payload.data.physicianContact?.map((obj: PhysicianContactType) => {
          const newPhysicianObj = {
            clientId: obj.clientID,
            contactType: "Physician",
            description: "Practice",
            firstName: obj.physicianFirstName,
            lastName: obj.physicianLastName,
            lastContactedOn: null,
            patientContactDetails: null,
            phoneNumber: obj.physicianPhone,
            practicePhone: null,
            providerId: 0,
            thumbnailPath: null,
            userId: obj.physicianUserID,
            email: obj.physicianEmail,
          };
          return newPhysicianObj;
        });
        state.allContacts.all = [...state.allContacts.all, ...physicianContacts].sort((objA, objB) =>
          lowerCase(`${objA.firstName} ${objA.lastName}`).localeCompare(lowerCase(`${objB.firstName} ${objB.lastName}`))
        );
        if (action.payload.userId !== state.userId || action.payload.offset === 1) {
          state.navigatorContacts.physicianContact = action.payload.data.physicianContact;
        } else {
          state.navigatorContacts.physicianContact = [
            ...state.navigatorContacts.physicianContact,
            ...action.payload.data.physicianContact,
          ];
        }
        state.userId = action.payload.userId;
      })
    );
  },
});

export const {
  resetContacts,
  resetAllContacts,
  setIsLoading,
  setIsPatientContactsLoading,
  setIsPhysicianContactsLoading,
} = contactSlice.actions;
export default contactSlice;
export const getContactState = (state: any): IContactState => state.contact;
