import { createAsyncThunk } from "@reduxjs/toolkit";
import { getConfig } from "config/config";
import { axiosInstance } from "shared/axios-instance";
import { IGetContactsAsyncRequestPayload } from "state/types/contact-slice.type";

export const getNavigatorContactsAsync = createAsyncThunk(
  "contact/getNavigatorContactsAsync",
  async (requestPayload: { userId: string; isCareManagerId: boolean }) => {
    const response = await axiosInstance.get(`${getConfig().messagingBase}/api/v1/Messaging/NavigatorContactList`, {
      params: { id: requestPayload.userId, isCareManagerId: requestPayload.isCareManagerId },
    });
    return response.data;
  }
);

export const getNavigatorPhysicianContactAsync = createAsyncThunk(
  "contact/getNavigatorPhysicianContactAsync",
  async (requestPayload: IGetContactsAsyncRequestPayload, thunkApi) => {
    const response = await axiosInstance.post(
      `${getConfig().messagingBase}/api/v1/Messaging/NavigatorPhysicianContactList`,
      requestPayload,
      { signal: thunkApi.signal }
    );
    return { data: response.data, offset: requestPayload.pageNumber, userId: requestPayload.id };
  }
);

export const getNavigatorPatientContactAsync = createAsyncThunk(
  "contact/getNavigatorPatientContactAsync",
  async (requestPayload: IGetContactsAsyncRequestPayload, thunkApi) => {
    const response = await axiosInstance.post(
      `${getConfig().messagingBase}/api/v1/Messaging/NavigatorPatientContactList`,
      requestPayload,
      { signal: thunkApi.signal }
    );
    return { data: response.data, offset: requestPayload.pageNumber, userId: requestPayload.id };
  }
);
