import { useCallback, useEffect, useState } from "react";
import { getTaskTypeCategoriesAsync } from "state/feature/episodes/episodes.action";
import { useAppDispatch } from "state/store";
import ActivityFilterPresentation from "./activity-filter.component";
import { getCommonState, setIsFilterModal } from "state/feature/common/common.slice";
import "./style.scss";
import { useSelector } from "react-redux";
const ActivityFilter = (props: {
  handleApplyFilter: (selectedVals: Array<string>) => void;
  selectedValues: Array<string>;
  handleClearFilter: () => void;
}) => {
  const { selectedValues, handleClearFilter, handleApplyFilter } = props;
  const appDispatch = useAppDispatch();
  const [filterOptions, setFilterOptions] = useState<Array<string>>([]);
  const [isLoadingFilter, setIsLoadingFilter] = useState(true);
  const [selectedFilterValues, setSelectedFilterValues] = useState<Array<string>>([]);
  const { modal } = useSelector(getCommonState);

  const handleStatusFilter = (status: string) => {
    const filterIndex = selectedFilterValues.findIndex((fill: string) => fill === status);
    const selectedVal = selectedFilterValues;
    if (filterIndex > -1) {
      selectedVal.splice(filterIndex, 1);
    } else selectedVal.push(status);
    setSelectedFilterValues([...selectedVal]);
  };

  const handleApplyClick = () => {
    handleApplyFilter(selectedFilterValues);
  };

  const handleModalClose = () => {
    appDispatch(setIsFilterModal(false));
    setSelectedFilterValues([]);
  };

  const isFilterValueChanged = useCallback(() => {
    return (
      selectedValues.length !== selectedFilterValues.length ||
      selectedValues.some((filter) => {
        return !selectedFilterValues.includes(filter);
      })
    );
  }, [selectedFilterValues, selectedValues]);

  const handleFilterApplyClick = () => {
    if (!isLoadingFilter && isFilterValueChanged()) {
      handleApplyClick();
    }
  };

  const handleFilterClearClick = () => {
    if (!isLoadingFilter) {
      if (selectedValues.length === 0 && selectedFilterValues.length > 0) {
        setSelectedFilterValues([]);
      } else {
        handleClearFilter();
      }
    }
  };

  useEffect(() => {
    setSelectedFilterValues([...selectedValues]);
  }, [selectedValues]);

  useEffect(() => {
    if (modal.isOpenFilterModal) {
      setIsLoadingFilter(true);
      appDispatch(getTaskTypeCategoriesAsync())
        .unwrap()
        .then((res) => {
          if (res.status === 200 && res.data?.data) {
            setFilterOptions(res.data.data);
          }
        })
        .finally(() => {
          setIsLoadingFilter(false);
        });
    }
  }, [appDispatch, modal.isOpenFilterModal]);

  return (
    <ActivityFilterPresentation
      filterOptions={filterOptions}
      selectedFilterValues={selectedFilterValues}
      handleStatusFilter={handleStatusFilter}
      handleApplyClick={handleFilterApplyClick}
      handleClearFilter={handleFilterClearClick}
      handleModalClose={handleModalClose}
      isLoadingFilter={isLoadingFilter}
      isFilterValueChanged={isFilterValueChanged()}
      selectedValues={selectedValues}
    />
  );
};
export default ActivityFilter;
