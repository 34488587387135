import moment from "moment";
import Header from "../header/header.component";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import { getNotesTaskState } from "state/feature/notes-task/notes-task.slice";
import {
  getBrowserState,
  getCurrentPageTaskState,
  removeTab,
} from "state/feature/task-management/task-management.slice";
import { useAppDispatch } from "state/store";
import { ReminderTaskFeilds } from "../reminder-task/common/reminder-task.constants";
import Button from "components/Button/button.component";
import { getCommonState } from "state/feature/common/common.slice";
import CommonState from "components/common-state/common-state.component";
import { CommonStateType } from "components/common-state/common-state.enum";
import { getNotesTaskAsync } from "state/feature/notes-task/notes-task.action";
import "./notes-task.style.scss";
import { getTaskHeaderText } from "shared/methods/utilityFunctions";
import { setLockState } from "state/feature/task-management/task-management.action";
import { PatientDetailsHeader } from "components/patient-details-header/patient-details-header.component";
const NotesTask = () => {
  useAxiosAuthenticated();
  const { title, navigatorNotes } = ReminderTaskFeilds;
  const appDispatch = useAppDispatch();
  const { currentTab } = useSelector(getBrowserState);
  const { intakeId, taskType, taskId } = currentTab!;
  const { existingNotes, isLoading } = useSelector(getNotesTaskState);
  const { patientDetails } = useSelector(getCurrentPageTaskState(taskId));
  const { featureFlags } = useSelector(getCommonState);

  const getPatientDetails: () => JSX.Element | undefined = useCallback(() => {
    if (patientDetails) {
      return <PatientDetailsHeader intakeId={intakeId} patientDetails={patientDetails} />;
    }
  }, [patientDetails, intakeId]);

  const handleCancelButton = () => {
    appDispatch(setLockState({ intakeId: currentTab!.intakeId, taskId: currentTab!.taskId, isLocked: false }));
    if (featureFlags.taskManagement && featureFlags.taskManagementListView) {
      appDispatch(removeTab(currentTab!.taskId));
      return;
    }
  };

  useEffect(() => {
    if (intakeId && taskId) {
      appDispatch(getNotesTaskAsync({ intakeId, taskId }));
    }
  }, [appDispatch, intakeId, taskId]);

  return (
    <div id="notes-task-container">
      <Header
        className="notes-task-header"
        patientDetails={getPatientDetails()}
        title={getTaskHeaderText(taskType ?? "")}
      />
      <div id="form-container">
        {isLoading ? (
          <CommonState type={CommonStateType.LOADING} />
        ) : (
          <div className="notes-fields-container">
            <div className="title-input-container notes-input-field">
              <div className="title-label label">{title.label}</div>
              <input
                type="text"
                className="title-input disabled"
                maxLength={title.maxLength}
                placeholder={title.placeHolder}
                value={existingNotes.title}
                disabled={true}
              />
            </div>

            <div className="navigator-notes-container  notes-input-field">
              <div className="navigator-notes-label label">{navigatorNotes.label}</div>
              <textarea
                className="navigator-notes-input"
                maxLength={navigatorNotes.maxLength}
                cols={70}
                rows={5}
                placeholder={navigatorNotes.placeHolder}
                disabled={true}
                value={existingNotes.navigatorNotes ?? ""}
              />
            </div>
          </div>
        )}
      </div>
      <footer className="notes-task-footer-container">
        <div className="footer-buttons-div-container">
          <Button text={"Back"} className="cancel-button" onClick={handleCancelButton} />
        </div>
      </footer>
    </div>
  );
};

export default NotesTask;
