import "./practice-table.styles.scss";
import { useSelector } from "react-redux";
import { getContactState } from "state/feature/contact/contact.slice";
import Icon from "components/Icon/icon.component";
import { SortingOrderType } from "shared/enums/sorting-types.enums";
import { isMobile } from "react-device-detect";
import InfiniteScroll from "react-infinite-scroll-component";
import { PhysicianContactType } from "state/types/contact-slice.type";
import TableLoader from "components/TableLoader/table-loader.component";
import CommonState from "components/common-state/common-state.component";
import { getNavigatorState } from "state/feature/navigator/navigator.slice";
import Sort from "components/Sort/sort.component";
import { CommonStateType } from "components/common-state/common-state.enum";
import Row from "./row/row.component";
import { ColumnType } from "shared/types/table.type";

const PracticeTable = ({
  tableClassName,
  columns,
  handleMessageSidePopup,
  columnsData,
  handleColumnSort,
  incrementPage,
}: {
  tableClassName: string;
  columns: ColumnType[];
  handleMessageSidePopup: () => void;
  columnsData: any;
  handleColumnSort: (column: ColumnType) => void;
  incrementPage: () => void;
}) => {
  const { isLoading } = useSelector(getContactState);
  const { isLoading: isNavigatorsLoading } = useSelector(getNavigatorState);

  const displaySortIcons = (column: ColumnType) => {
    return column.sortOrder === SortingOrderType.DEFAULT ? (
      <Sort />
    ) : (
      <Icon icon={column.sortOrder === SortingOrderType.ASC ? "sort-top" : "sort-bottom"} size={8} />
    );
  };

  const columnName = (column: ColumnType) => {
    const shouldDisplaySortIcons = !isLoading;
    if (column.name !== "") {
      return (
        <div
          className={`contact-practice-table-header-content ${
            isLoading && "contact-practice-table-header-content-disable-cursor"
          }`}
          onClick={() => {
            if (shouldDisplaySortIcons) {
              handleColumnSort?.(column);
            }
          }}
        >
          <div className="contact-practice-table-header-content-name">{column.name}</div>
          {shouldDisplaySortIcons && displaySortIcons(column)}
        </div>
      );
    }
    return column.name;
  };

  return (
    <>
      <table className={tableClassName}>
        <thead>
          <tr className={`contact-practice-table-header ${isMobile ? "hidden" : ""}`}>
            <td>
              <table>
                <tbody>
                  <tr>
                    {columns.map((column) => {
                      return <th key={column.name}>{columnName(column)}</th>;
                    })}
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr className="contact-no-border-in-mobile">
            <td>
              <InfiniteScroll
                scrollableTarget="scrollableDiv"
                next={incrementPage}
                hasMore={true}
                loader={false}
                dataLength={columnsData.physicianContact.length}
              >
                <table>
                  <tbody id="scrollableDiv" className="contact-practice-table-body">
                    {(isLoading || isNavigatorsLoading) && columnsData.physicianContact.length === 0 && (
                      <tr className="contact-no-border-row">
                        <td>
                          <div className="loading-container">
                            <CommonState type={CommonStateType.LOADING} />
                          </div>
                        </td>
                      </tr>
                    )}

                    {!isLoading && !isNavigatorsLoading && columnsData.physicianContact.length === 0 && (
                      <tr className="no-message-container">
                        <td className="no-message-td" colSpan={isMobile ? 2 : 4}>
                          <div className="no-messages">No Contacts Found</div>
                        </td>
                      </tr>
                    )}

                    {columnsData &&
                      columnsData?.physicianContact &&
                      columnsData?.physicianContact?.length > 0 &&
                      columnsData?.physicianContact.map((item: PhysicianContactType, index: number) => {
                        return (
                          <Row
                            key={index}
                            practice={`${item.physicianFirstName} ${item.physicianLastName}`}
                            practicePhoneNumber={item.physicianPhone ? item.physicianPhone : ""}
                            noOfPatients={item.patientNumber ? item.patientNumber : 0}
                            physicianUserID={item.physicianUserID ? item.physicianUserID : ""}
                            handleMessageSidePopup={handleMessageSidePopup}
                            clientID={item.clientID}
                            practiceName={item.practiceName}
                            physicianDetails={item}
                          />
                        );
                      })}
                    {columnsData.physicianContact.length > 0 && isLoading ? (
                      <tr className="contact-no-border-row">
                        <td>
                          <TableLoader />
                        </td>
                      </tr>
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </InfiniteScroll>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default PracticeTable;
