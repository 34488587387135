import { useState, useCallback, useEffect } from "react";
import Header from "components/Header/header.component";
import "./navigator-schedule.styles.scss";
import { useHistory, useLocation } from "react-router";
import Breadcrumb from "components/Breadcrumb/breadcrumb.component";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { deleteOnCallNavigator, getNavigatorState, resetToInitialState } from "state/feature/navigator/navigator.slice";
import {
  getBackUpNavigatorListAsync,
  getHolidayListAsync,
  getOnCallNavigatorsListAsync,
  postOnCallNavigatorListAsync,
} from "state/feature/navigator/navigator.action";
import { IGetOnCallNavigatorProps, IPostOnCallNavigatorProps } from "shared/dtos/navigator";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import { useAppDispatch } from "state/store";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import "@fullcalendar/daygrid/main.css";
import NavigatorPicker from "components/navigator-picker/navigator-picker.container";
import Button from "components/Button/button.component";
import CommonState from "components/common-state/common-state.component";
import { getAuth } from "state/feature/auth/auth.slice";
import { CommonStateType } from "components/common-state/common-state.enum";
import useUrlBlocking from "shared/hooks/use-blocking.hook";

export type OnCallDataType = {
  id: string;
  navigatorId: string;
  onCallDate: string;
};

const NavigatorSchedule = () => {
  useAxiosAuthenticated();
  const location = useLocation();
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const history = useHistory();
  const { user } = useSelector(getAuth);
  const {
    extraOnCallNavigators,
    isLoadingHolidayList,
    isLoadingOnCallNavigators,
    isLoadingBackupNavigatorList,
    isSaveOnCallNavigatorList,
    isLoading,
    navigatorScheduleOnCallNavigators,
    currentUserProfile,
  } = useSelector(getNavigatorState);
  useUrlBlocking();
  const [clickedElement, setClickedElement] = useState({ id: "", position: {}, remove: false });

  const [calendarStartEndDate, setCalendarStartEndDate] = useState<{ startDate: string; endDate: string }>({
    startDate: moment().startOf("month").toDate().setHours(0, 0, 0, 0).toString(),
    endDate: moment().endOf("month").toDate().setHours(0, 0, 0, 0).toString(),
  });
  const getHolidayList = useCallback(
    (year?) => {
      appDispatch(getHolidayListAsync({ year: year || moment().year() }));
    },
    [appDispatch]
  );

  const getOnCallNavigatorList = useCallback(
    async (startDateValue, endDateValue) => {
      const data: IGetOnCallNavigatorProps = {
        startDate: new Date(new Date(startDateValue ?? new Date()).getTime() - 60 * 60 * 12 * 1000).toDateString(),
        endDate: new Date(new Date(endDateValue ?? new Date()).getTime() + 60 * 60 * 12 * 1000).toDateString(),
        isInvokedFromSidebar: false,
      };
      appDispatch(getOnCallNavigatorsListAsync(data));
    },
    [appDispatch]
  );

  useEffect(() => {
    if (user && user.roles && user.roles.length > 0 && !user.roles.includes("Navigator Manager")) {
      history.push("/");
    }
  }, [history, user]);

  useEffect(() => {
    dispatch(
      getBackUpNavigatorListAsync({
        types: ["PNAV", "TNAV"],
      })
    );
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetToInitialState());
    };
  }, [dispatch]);

  const postOnCallNavigatorData = useCallback(async () => {
    const result = extraOnCallNavigators
      .filter((navigator) => {
        return navigator.date;
      })
      .map((navigator) => {
        if (navigator.dateId) {
          return {
            id: navigator.dateId,
            previousNavigatorId: navigator.previousNavigatorId,
            navigatorId: navigator.id,
            navigatorManagerId: navigator.managerId,
            onCallDate: navigator.date,
            createdUser: navigator?.createdUser ?? null,
            createdDate: navigator?.createdDate ?? null,
          };
        } else {
          return {
            navigatorId: navigator.id,
            previousNavigatorId: navigator.previousNavigatorId,
            navigatorManagerId: navigator.managerId,
            onCallDate: navigator.date,
            createdUser: navigator?.createdUser ?? null,
            createdDate: navigator?.createdDate ?? null,
          };
        }
      });
    appDispatch(postOnCallNavigatorListAsync(result as IPostOnCallNavigatorProps[])).then(() => {
      getOnCallNavigatorList(calendarStartEndDate.startDate, calendarStartEndDate.endDate);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appDispatch, extraOnCallNavigators, getOnCallNavigatorList]);

  const handleSaveChangesButton = () => {
    postOnCallNavigatorData();
  };

  const renderEventContent = (eventInfo: any) => {
    return <NavigatorPicker id={eventInfo.event.startStr} clickedElement={clickedElement} />;
  };

  return (
    <div id="navigator-setup-container">
      {isLoadingBackupNavigatorList && (
        <div className="loading-container">
          <CommonState type={CommonStateType.LOADING} />
        </div>
      )}
      {!isLoadingBackupNavigatorList && currentUserProfile !== null && (
        <div style={{ height: "100%" }}>
          <div className="breadcrumb-container">
            <Breadcrumb pathname={location.pathname} />
          </div>
          <Header className="navigator-setup">
            <div className="header-title">Set Up On-Call Schedule</div>
            <div>
              <Button
                text="Save Changes"
                className={`green-button save-changes`}
                onClick={handleSaveChangesButton}
                disabled={extraOnCallNavigators.length === 0 || isSaveOnCallNavigatorList || isLoading}
                showLoader={
                  isSaveOnCallNavigatorList ||
                  isLoadingHolidayList ||
                  isLoadingOnCallNavigators ||
                  isLoadingBackupNavigatorList
                }
              />
            </div>
          </Header>
          <div className="navigator-setup-content">
            <FullCalendar
              plugins={[dayGridPlugin]}
              initialView="dayGridMonth"
              dayHeaderFormat={{ weekday: "long" }}
              weekNumberCalculation="ISO"
              customButtons={{
                headerTitle: {
                  text: "Weekends and ES Holiday Timings: 9AM - 9AM Coverage",
                  click: (e: any) => {
                    e.preventDefault();
                  },
                },
              }}
              headerToolbar={{
                start: "title prev,next",
                center: "",
                right: "headerTitle",
              }}
              titleFormat={{
                month: "short",
                year: "numeric",
              }}
              eventClick={(e: any) => {
                const element = e.el.firstChild?.firstChild as HTMLDivElement;
                const id = element.id;
                const elementPresent = navigatorScheduleOnCallNavigators?.find((item) => {
                  return moment(item.onCallDate).format("YYYY-MM-DD") === id || item.date === id;
                });
                const elementPresentExtraOnCall = extraOnCallNavigators?.find((item) => {
                  return moment(item?.onCallDate).format("YYYY-MM-DD") === id || item?.date === id;
                });
                if (elementPresent || elementPresentExtraOnCall) {
                  dispatch(deleteOnCallNavigator(id));
                }
                setClickedElement((prevState) => {
                  if (prevState.id === id) {
                    return { ...prevState, position: e.el.getBoundingClientRect(), remove: true };
                  }
                  return { id: id, position: e.el.getBoundingClientRect(), remove: false };
                });
              }}
              initialEvents={[
                {
                  daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
                },
              ]}
              eventBackgroundColor="#fff"
              eventOverlap={false}
              eventContent={renderEventContent}
              fixedWeekCount={false}
              datesSet={(dateInfo: any) => {
                setCalendarStartEndDate({ startDate: dateInfo.startStr, endDate: dateInfo.endStr });
                document.querySelector(".fc-scroller-liquid-absolute")?.scrollTo({ top: 0 });
                if (moment(dateInfo.end).year() - moment(dateInfo.start).year() === 1) {
                  getHolidayList(moment(dateInfo.endStr).year());
                  getHolidayList(moment(dateInfo.startStr).year());
                } else {
                  getHolidayList(moment(dateInfo.endStr).year());
                }
                getOnCallNavigatorList(dateInfo.startStr, dateInfo.endStr);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default NavigatorSchedule;
