import { NavigatorDataType, ColumnType } from "./props";
import { useSelector } from "react-redux";
import { getAuth } from "state/feature/auth/auth.slice";
import NavigatorRow from "../navigator-row/navigator-row.component";
import "./navigator-table.styles.scss";
import { SortingOrderType } from "shared/enums/sorting-types.enums";
import Sort from "components/Sort/sort.component";
import Icon from "components/Icon/icon.component";
import CommonState from "components/common-state/common-state.component";
import { CommonStateType } from "components/common-state/common-state.enum";

const NavigatorTable = ({
  columns,
  currentTableData,
  handleColumnSort,
  isSortedColumnLoading,
}: {
  columns: ColumnType[];
  currentTableData: NavigatorDataType[];
  handleColumnSort: (column: ColumnType) => void;
  isSortedColumnLoading: boolean;
}) => {
  const { isLoading } = useSelector(getAuth);

  const displaySortIcons = (column: ColumnType) => {
    return column.sortOrder === SortingOrderType.DEFAULT ? (
      <Sort />
    ) : (
      <Icon icon={column.sortOrder === SortingOrderType.ASC ? "sort-top" : "sort-bottom"} size={8} />
    );
  };

  const getColumnName = (column: ColumnType) => {
    const shouldDisplaySortIcons = !isSortedColumnLoading;
    if (column.name !== "") {
      return (
        <div
          className={`manager-dashboard-table-header-content ${
            isSortedColumnLoading && "manager-dashboard-table-header-content-disable-cursor"
          }`}
          onClick={() => {
            if (shouldDisplaySortIcons) {
              handleColumnSort(column);
            }
          }}
        >
          <div className="name">{column.name}</div>
          <span>{shouldDisplaySortIcons && displaySortIcons(column)}</span>
        </div>
      );
    }
  };
  return (
    <>
      <thead className="manager-dashboard-table-header">
        <tr>
          <td>
            <table>
              <tr className="manager-dashboard-table-header-rows">
                {columns.map((column) => {
                  return (
                    <th key={column.name}>
                      <div>{getColumnName(column)}</div>
                    </th>
                  );
                })}
              </tr>
            </table>
          </td>
        </tr>
      </thead>
      <tbody className="manager-dashboard-table-body">
        <tr>
          <td colSpan={columns.length}>
            <div className="manager-stats-table-body">
              <table>
                <tbody>
                  {isSortedColumnLoading && (
                    <tr>
                      <td colSpan={columns.length}>
                        <div className="table-loading-container">
                          <CommonState type={CommonStateType.LOADING} />
                        </div>
                      </td>
                    </tr>
                  )}
                  {!isSortedColumnLoading && currentTableData.length === 0 && (
                    <tr className="no-message-container">
                      <td className="no-message-td" colSpan={columns.length}>
                        {isLoading ? (
                          <div className="no-messages">Fetching records...</div>
                        ) : (
                          <div className="no-messages">No Records Found</div>
                        )}
                      </td>
                    </tr>
                  )}
                  {!isSortedColumnLoading &&
                    currentTableData &&
                    currentTableData.length > 0 &&
                    currentTableData?.map((item) => {
                      return <NavigatorRow navigator={item} key={item.id} />;
                    })}
                </tbody>
              </table>
            </div>
          </td>
        </tr>
      </tbody>
    </>
  );
};

export default NavigatorTable;
