import React from "react";
import IcoMoon from "react-icomoon";
import iconSet from "shared/IconSet.json";

const Icon = ({
  icon,
  size,
  className,
  color,
}: { icon: string } & { size: number | undefined } & {
  className?: string;
} & { color?: string } & React.CSSProperties) => {
  return <IcoMoon iconSet={iconSet} size={size} icon={icon} className={className} color={color} />;
};

export default Icon;
