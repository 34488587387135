import { Row } from "./row";

type RiskTableProps = {
  tableClassName: string;
  risks: {
    highRisk: boolean;
    standardRisk: boolean;
    name: string;
  }[];
};

export const RiskTable = ({ risks, tableClassName }: RiskTableProps) => {
  return (
    <>
      <table className={tableClassName}>
        <thead>
          <th>Items</th>
          <th>Standard Risk</th>
          <th>High Risk</th>
        </thead>
        <tbody>
          {risks.map(({ highRisk, standardRisk, name }) => {
            return <Row key={name} name={name} highRisk={highRisk} standardRisk={standardRisk} />;
          })}
        </tbody>
      </table>
    </>
  );
};
