import { useDispatch, useSelector } from "react-redux";
import "./refresh.styles.scss";
import Button from "components/Button/button.component";
import { clearUser } from "state/feature/auth/auth.slice";
import { getCommonState, resetCommon } from "state/feature/common/common.slice";

export const Refresh = () => {
  const dispatch = useDispatch();
  const {
    modal: { isSessionTimeoutModalVisible },
  } = useSelector(getCommonState);
  const onRealod = () => {
    if (isSessionTimeoutModalVisible) {
      dispatch(resetCommon());
      dispatch(clearUser());
      location.replace("/");
    } else {
      window.location.reload();
    }
  };

  return (
    <div className="refresh-component-container">
      <div>Please refresh the page to enable calling once again</div>
      <Button text="Reload" className="no-border-button reload-link" onClick={onRealod} />
    </div>
  );
};
