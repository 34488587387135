import { createContext } from "react";
import { AssessmentProps } from "../common/assessment.types";

export const AssessmentContext = createContext<AssessmentProps>({
  onChangePill: () => {},
  onChangeDropdown: () => {},
  onSelectPill: () => {},
  onChangeCheckbox: () => {},
  onChangeDays: () => {},
  onChangeDate: () => {},
  onChangeText: () => {},
  onChangeAge: () => {},
  onScaleChange: () => {},
});
