import React from "react";
import "./common-note.styles.scss";
import AlarmIcon from "shared/assets/images/clock-alarm.svg";
import AlertIcon from "shared/assets/images/alert.svg";

type CommonNotePropsType = {
  reminder?: string | null;
  alert?: string | null;
  className?: string;
  image?: any;
};

function CommonNote(props: CommonNotePropsType) {
  const { reminder, alert, className, image } = props;
  return (
    <div className={`common-note-container ${className ? className : ""}`}>
      {reminder && (
        <>
          <div className="reminder-note-icon">{image ? image : <img src={AlarmIcon} />}</div>
          <div className="reminder-note-container">
            <span className="reminder-text">{reminder}</span>
          </div>
        </>
      )}
      {alert && (
        <>
          <div className="alert-note-icon">
            <img src={AlertIcon} />
          </div>
          <div className="alert-note-container">
            <span className="alert-tag">Alert: </span>
            <span className="alert-text">{alert}</span>
          </div>
        </>
      )}
    </div>
  );
}

export default CommonNote;
