import CommonDashBoard from "pages/common-dashboard";
import { ForgotPassword } from "pages/forgot-password/forgot-password.container";
import { Login } from "pages/login/login.container";
import NavigatorSchedule from "pages/navigator-schedule/navigator-schedule.container";
import Profile from "pages/profile/profile.container";
import UserManagementDetails from "pages/user-management/user-management-details/user-management-details.container";
import UserManagement from "pages/user-management/user-management.container";
import { IRoute } from "routes/route.type";
import AccessDenied from "pages/errors/access-denied";
import PermissionDenied from "pages/errors/permission-denied";
import PageNotFound from "pages/errors/page-not-found";
import { USER_ROLE } from "shared/constant/role-constant";
import TaskManagementContainer from "pages/task-management/task-management.container";
import RedirectToTaskManagement from "components/redirect-to-task-management/redirect-to-task-management.component";
import NotificationMobile from "pages/notification/mobile/notification-mobile.container";
import Episodes from "pages/episodes/episodes.container";
import ManagerContact from "pages/manager-contact/manager-contact.container";
import NavigatorDetail from "pages/navigator-detail/navigator-detail.container";
import NavigatorManagement from "pages/navigator-management/navigator-management.container";
import Contact from "pages/contact/contact.container";
import EpisodeDetails from "pages/episodes/episode-details/episode-details.container";
import Admin from "pages/admin/admin.container";

export const publicRoutes: IRoute[] = [
  {
    id: 1,
    path: "/",
    main: () => <Login />,
  },
  {
    id: 2,
    path: "/forgot-password",
    main: () => <ForgotPassword />,
  },
];

export const privateRoutes: IRoute[] = [
  {
    id: 3,
    path: "/contact",
    main: () => <Contact />,
    isAccessibleBy: [USER_ROLE.NAVIGATOR, USER_ROLE.MANAGER],
  },
  {
    id: 4,
    path: "/contact/:navigatorName",
    main: () => <ManagerContact />,
    isAccessibleBy: [USER_ROLE.MANAGER],
  },
  {
    id: 5,
    path: "/navigator-management",
    main: () => <NavigatorManagement />,
    isAccessibleBy: [USER_ROLE.MANAGER],
  },
  {
    id: 6,
    path: "/navigator-management/schedule",
    main: () => <NavigatorSchedule />,
    isAccessibleBy: [USER_ROLE.MANAGER],
  },
  {
    id: 7,
    path: "/navigator-management/profile/:navigatorName",
    main: () => <Profile />,
    isAccessibleBy: [USER_ROLE.MANAGER],
  },
  {
    id: 8,
    path: "/profile",
    main: () => <Profile />,
    isAccessibleBy: [USER_ROLE.NAVIGATOR],
  },
  {
    id: 9,
    path: "/dashboard",
    main: () => <CommonDashBoard />,
    isAccessibleBy: [USER_ROLE.NAVIGATOR, USER_ROLE.MANAGER],
  },
  {
    id: 10,
    path: "/dashboard/:navigatorName",
    main: () => <NavigatorDetail />,
    isAccessibleBy: [USER_ROLE.MANAGER],
  },
  {
    id: 11,
    path: "/user-management",
    main: () => <UserManagement />,
    isAccessibleBy: [USER_ROLE.NAVIGATOR, USER_ROLE.MANAGER],
  },
  {
    id: 12,
    path: "/user-management/new-user",
    main: () => <UserManagementDetails />,
    isAccessibleBy: [USER_ROLE.NAVIGATOR, USER_ROLE.MANAGER],
  },
  {
    id: 13,
    path: "/user-management/edit-user/:userId",
    main: () => <UserManagementDetails />,
    isAccessibleBy: [USER_ROLE.NAVIGATOR, USER_ROLE.MANAGER],
  },
  {
    id: 16,
    path: "/task-management/:intakeId?/:taskType?/:taskId?/:sectionName?",
    main: () => <TaskManagementContainer />,
    isAccessibleBy: [USER_ROLE.NAVIGATOR, USER_ROLE.MANAGER],
  },
  {
    id: 17,
    path: "/risk-assessment/:intakeId/:taskId?/:sectionName?",
    main: () => <RedirectToTaskManagement />,
    isAccessibleBy: [USER_ROLE.NAVIGATOR, USER_ROLE.MANAGER],
  },
  {
    id: 18,
    path: "/not-found",
    main: () => <PageNotFound />,
    isAccessibleBy: [USER_ROLE.NAVIGATOR, USER_ROLE.MANAGER],
  },
  {
    id: 19,
    path: "/access-denied",
    main: () => <AccessDenied />,
    isAccessibleBy: [USER_ROLE.NAVIGATOR, USER_ROLE.MANAGER],
  },
  {
    id: 20,
    path: "/permission-denied",
    main: () => <PermissionDenied />,
    isAccessibleBy: [USER_ROLE.NAVIGATOR, USER_ROLE.MANAGER],
  },
  {
    id: 21,
    path: "/notification",
    main: () => <NotificationMobile />,
    isAccessibleBy: [USER_ROLE.NAVIGATOR, USER_ROLE.MANAGER],
  },
  {
    id: 22,
    path: "/episodes",
    main: () => <Episodes />,
    isAccessibleBy: [USER_ROLE.NAVIGATOR, USER_ROLE.MANAGER],
  },
  {
    id: 22,
    path: "/episodes/:intakeId",
    main: () => <EpisodeDetails />,
    isAccessibleBy: [USER_ROLE.NAVIGATOR, USER_ROLE.MANAGER],
  },
  {
    id: 23,
    path: "/admin",
    main: () => <Admin />,
    isAccessibleBy: [USER_ROLE.NAVIGATOR, USER_ROLE.MANAGER],
  },
];
