import Icon from "components/Icon/icon.component";
import { RowPresentationProps } from "../../type";
import PlanOfCareDetailsColumn from "components/plan-of-care-details-column/plan-of-care-details-column.component";
import { DaysVisit, IntakeStatus } from "shared/enums/plan-of-care.enum";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsOpenCareOfPlanDeleteConfirmationModal,
  setIsOpenPOCServiceModal,
} from "state/feature/common/common.slice";
import { setCarePlanEditId, setDeleteCarePlan } from "state/feature/plan-of-care/plan-of-care.slice";
import { getEpisodesSlice } from "state/feature/episodes/episodes.slice";
import { CustomToolTip } from "components/CustomTooltip/custom-tooltip.component";
import { LOSVisitsLabel } from "shared/constant/plan-of-care.constant";

const RowPresentation = (props: RowPresentationProps) => {
  const { index, row, isRowHovered, onHover } = props;
  const dispatch = useDispatch();
  const { episodeDetails, isLoadingNewIntakeCreation, isNewIntakeCreation } = useSelector(getEpisodesSlice);

  return (
    <div
      className={`plan-of-care-table-row ${isRowHovered ? "hovered" : ""}`}
      key={`${index}-${row.id}`}
      onMouseEnter={(e) => {
        onHover(true);
      }}
      onMouseLeave={(e) => {
        onHover(false);
      }}
    >
      <div className="column">
        <div className="service-name">{row?.pacType ?? "-"}</div>
        <div className="service-provider">
          <span>Provider:</span>
          {row?.provider ?? "-"}
        </div>
        <div className="service-los">
          <span>{row?.daysType === DaysVisit.LOS ? LOSVisitsLabel.LOS : LOSVisitsLabel.Visits}:</span>
          {row?.aptNo ?? "-"}
        </div>
      </div>
      <div className="column">{row?.actualAdmitDate ?? "-"}</div>
      <div className="column">{row?.actualDischargeDate ?? "-"}</div>
      <div className="column poc-details">{<PlanOfCareDetailsColumn pocDetails={row} />}</div>
      <div className="column">
        {isRowHovered &&
          episodeDetails &&
          !isLoadingNewIntakeCreation &&
          isNewIntakeCreation &&
          !(
            episodeDetails.intakeStatusId === IntakeStatus.CLOSED ||
            episodeDetails.intakeStatusId === IntakeStatus.CANCELED ||
            episodeDetails.intakeStatusId === IntakeStatus.DECEASED ||
            episodeDetails.intakeStatusId === IntakeStatus.DISCHARGED ||
            episodeDetails.intakeStatusId === IntakeStatus.ON_HOLD ||
            episodeDetails.intakeStatusId === IntakeStatus.EXCLUDED
          ) && (
            <>
              <div
                data-tip
                data-for={"Edit"}
                className="icon"
                onClick={() => {
                  dispatch(setCarePlanEditId(row.id));
                  dispatch(setDeleteCarePlan(row));
                  dispatch(setIsOpenPOCServiceModal({ isOpen: true, isEdit: true }));
                }}
              >
                <Icon icon="edit" size={15} />
                <CustomToolTip text={"Edit"} id="Edit" place={"top"} />
              </div>

              <div
                data-tip
                data-for={"Delete"}
                className="icon"
                onClick={() => {
                  dispatch(setCarePlanEditId(row.id));
                  dispatch(setDeleteCarePlan(row));
                  dispatch(setIsOpenCareOfPlanDeleteConfirmationModal(true));
                }}
              >
                <Icon icon="poc-delete-icon" size={15} />
                <CustomToolTip text={"Delete"} id="Delete" place={"top"} />
              </div>
            </>
          )}
      </div>
    </div>
  );
};

export default RowPresentation;
