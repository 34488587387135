import moment from "moment";
import "./style.scss";

export const PatientRegisteredTooltip = (props: {
  patientLastLogged: {
    lastLoginDate: string | Date | null;
    isRegistered: boolean;
  } | null;
}) => {
  const { patientLastLogged } = props;
  const dateTime =
    patientLastLogged && patientLastLogged.lastLoginDate
      ? moment.utc(patientLastLogged.lastLoginDate, "YYYY-MM-DDTHH:mm:ss").local()
      : null;

  return (
    <div className="patient-registered-tooltip-container">
      Registered
      {dateTime && (
        <>
          {" "}
          | <span>Last Login:</span> {dateTime.format("MM/DD/YYYY")} {dateTime.format("hh:mm A")}
        </>
      )}
    </div>
  );
};
