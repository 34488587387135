import Button from "components/Button/button.component";
import Modal from "../modal.component";
import "./result-modal.styles.scss";

type ResultModalProps = {
  isOpen: boolean;
  handleOkClick: () => void;
  fromStandardToHigh?: boolean;
  headerDescription?: string;
};

const ResultModal = ({ isOpen, fromStandardToHigh, handleOkClick, headerDescription }: ResultModalProps) => {
  const getHeaderDescription = () => {
    if (headerDescription) {
      return <header>{headerDescription}</header>;
    }
    return fromStandardToHigh ? (
      <header>
        Patient has changed from <span className="standard-text">Standard</span> to{" "}
        <span className="high-text">High</span> risk
      </header>
    ) : (
      <header>
        Patient has changed from <span className="high-text">High</span> to{" "}
        <span className="standard-text">Standard</span> risk
      </header>
    );
  };

  return (
    <Modal isOpen={isOpen} className="center result-modal-container" contentClass="result-modal-main">
      <div id="modal-header">{getHeaderDescription()}</div>
      <div className="result-modal-content">
        <div className="result-modal-text">Manually mark the following tasks in the timeline as complete:</div>
        <div className="result-list-container">
          <ul className="result-list">
            <li>Week 2</li>
            <li>Week 4</li>
            <li>Week 8</li>
            <li>Week 10</li>
          </ul>
        </div>
        <div className="button-section">
          <Button text="Okay" className="green-button reload-button" onClick={handleOkClick} />
        </div>
      </div>
    </Modal>
  );
};

export default ResultModal;
