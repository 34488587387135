import { useEffect, useState } from "react";
import ManagerTaskApprovalModalPresentation from "./manager-approval-task-modal.component";
import { useSelector } from "react-redux";
import { getAuth } from "state/feature/auth/auth.slice";
import { getCommonState, setIsOpenManagerTaskApprovalModal } from "state/feature/common/common.slice";
import { useAppDispatch } from "state/store";
import {
  getBrowserState,
  getCurrentPageTaskState,
  setLoadTaskList,
  setShouldPerformPostTaskApprovedAction,
  setShouldPerformPostTaskRejectedAction,
} from "state/feature/task-management/task-management.slice";
import { isEmptyString } from "shared/methods/utilityFunctions";
import { getDelayAttempts, postTaskActionAsync } from "state/feature/task-management/task-management.action";
import { DelayModalProps } from "./manager-approval-task-modal.types";
import { ApproveRejectTaskActionPayload, TaskDelayAttempt } from "state/types/task-management-slice.type";
import { toast } from "react-toastify";
import { SOMETHING_WENT_WRONG } from "shared/constant/commonConstants";

const ManagerTaskApprovalModal = (props: DelayModalProps) => {
  const dispatch = useAppDispatch();

  const { currentTab } = useSelector(getBrowserState);
  const {
    delayTask: { delayAttempts, delayStatuses },
  } = useSelector(getCurrentPageTaskState(currentTab!.taskId));
  const [delayStatus, setDelayStatus] = useState<{ name: string; key: string | number }>({ name: "", key: "" });
  const [result, setResult] = useState<{ name: string; key: string | number }>({ name: "", key: "" });
  const [action, setAction] = useState<{ name: string; key: string | number }>({ name: "", key: "" });
  const [note, setNote] = useState("");
  const [reasonForDeceased, setReasonForDeceased] = useState<{ name: string; key: string | number }>({
    name: "",
    key: "",
  });
  const {
    modal: { isOpenManagerTaskApprovalModal },
  } = useSelector(getCommonState);
  const [isLoadingApproveButton, setIsLoadingApproveButton] = useState(false);
  const [isLoadingRejectButton, setIsLoadingRejectButton] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const { user } = useSelector(getAuth);
  const [lastDelayAttempt, setLastDelayAttempt] = useState<TaskDelayAttempt | null>(null);

  const checkNotesValidation = () => {
    return lastDelayAttempt && note.length && note.length > 0 && lastDelayAttempt?.notes !== note ? true : false;
  };

  const checkValidation = ({
    intakeId,
    taskId,
    isRejected,
  }: {
    intakeId?: string | null;
    taskId: string;
    isRejected?: boolean;
  }): boolean => {
    const commonValidation = intakeId && !isEmptyString(intakeId) && taskId && lastDelayAttempt ? true : false;
    if (isRejected) {
      return commonValidation && checkNotesValidation();
    }
    return commonValidation;
  };

  const getPayload = ({
    intakeId,
    taskId,
    isApproved,
  }: {
    intakeId: string;
    taskId: string;
    isApproved: boolean;
  }): ApproveRejectTaskActionPayload => {
    return {
      intakeId: parseInt(intakeId),
      taskId,
      isApproved,
      taskDelaystatusId: Number(delayStatus.key),
      taskDelayResultId: Number(result.key),
      taskDelayActionId: Number(action.key),
      notes: note,
      createUser: `${user.firstName} ${user.lastName}`,
      createUserId: user.id,
      intakeStatusReasonId: reasonForDeceased.key !== "" ? Number(reasonForDeceased.key) : null,
    };
  };

  const handleOnCancel = () => {
    setNote("");
    dispatch(setIsOpenManagerTaskApprovalModal(false));
    setIsDirty(false);
  };

  const handleOnApprove = () => {
    const { intakeId, taskId } = currentTab!;
    if (checkValidation({ intakeId, taskId })) {
      setIsLoadingApproveButton(true);
      const payload = getPayload({ intakeId, taskId, isApproved: true });
      dispatch(postTaskActionAsync(payload))
        .then((response) => {
          if (response.meta.requestStatus === "fulfilled") {
            dispatch(setLoadTaskList(true));
            dispatch(setShouldPerformPostTaskApprovedAction({ taskId, performAction: true }));
            dispatch(
              getDelayAttempts({
                taskId,
                intakeId: parseInt(intakeId),
              })
            )
              .then(() => {
                if (response.meta.requestStatus === "fulfilled") {
                  setIsLoadingApproveButton(false);
                  handleOnCancel();
                }
              })
              .catch(() => {
                setIsLoadingApproveButton(false);
              });
          } else {
            toast.error(SOMETHING_WENT_WRONG, {
              containerId: "main",
            });
            setIsLoadingApproveButton(false);
          }
        })
        .catch(() => {
          toast.error(SOMETHING_WENT_WRONG, {
            containerId: "main",
          });
        })
        .finally(() => {
          setIsLoadingApproveButton(false);
        });
    }
    setIsDirty(false);
  };

  const handleOnReject = async () => {
    setIsDirty(true);
    const { intakeId, taskId } = currentTab!;
    if (checkValidation({ intakeId, taskId, isRejected: true })) {
      const payload = getPayload({ intakeId, taskId, isApproved: false });
      setIsLoadingRejectButton(true);
      dispatch(postTaskActionAsync(payload))
        .then((response) => {
          if (response.meta.requestStatus === "fulfilled") {
            dispatch(setLoadTaskList(true));
            dispatch(setShouldPerformPostTaskRejectedAction({ taskId, performAction: true }));
            dispatch(
              getDelayAttempts({
                taskId,
                intakeId: parseInt(intakeId),
              })
            )
              .then(() => {
                if (response.meta.requestStatus === "fulfilled") {
                  setIsLoadingRejectButton(false);
                  handleOnCancel();
                }
              })
              .catch(() => {
                setIsLoadingRejectButton(false);
              });
          } else {
            toast.error(SOMETHING_WENT_WRONG, {
              containerId: "main",
            });
            setIsLoadingRejectButton(false);
          }
        })
        .catch(() => {
          toast.error(SOMETHING_WENT_WRONG, {
            containerId: "main",
          });
        })
        .finally(() => {
          setIsDirty(false);
          setIsLoadingRejectButton(false);
        });
    }
  };

  const handleChangeNote = (value: string) => {
    if (isDirty) {
      setIsDirty(false);
    }
    if (!/^\s/.test(value)) {
      setNote(value);
    }
  };

  useEffect(() => {
    if (delayAttempts && delayAttempts?.length > 0 && isOpenManagerTaskApprovalModal) {
      const newLastDelayAttempt = delayAttempts[0];
      setNote(newLastDelayAttempt?.notes ?? "");
    }
  }, [isOpenManagerTaskApprovalModal, delayAttempts]);

  useEffect(() => {
    if (delayAttempts && delayAttempts?.length > 0) {
      const newLastDelayAttempt = delayAttempts[0];
      setLastDelayAttempt(newLastDelayAttempt ?? null);
      setNote(newLastDelayAttempt?.notes ?? "");
      const status = delayStatuses?.find((obj) => {
        return obj?.taskDelayStatusId === delayAttempts[0]?.taskDelayStatusId;
      });
      if (status) {
        setDelayStatus({ key: status?.taskDelayStatusId ?? "", name: status?.status ?? "" });
        const newResult = status?.result.find((res) => {
          return res?.id === delayAttempts[0]?.taskDelayResultId;
        });
        setResult({ key: newResult?.id ?? "", name: newResult?.text ?? "" });
        const newAction = newResult
          ? newResult?.action?.find((val) => val?.id === newLastDelayAttempt?.taskDelayActionId)
          : null;
        setAction({ key: newAction?.id ?? "", name: newAction?.text ?? "" });
        if (newLastDelayAttempt?.intakeStatusReasonId && newAction?.finalIntakeStatusChangeReason) {
          const newReasonForDeceased = newAction?.finalIntakeStatusChangeReason
            ? newAction.finalIntakeStatusChangeReason?.find(
                (reason) => reason?.id === newLastDelayAttempt?.intakeStatusReasonId
              )
            : null;

          setReasonForDeceased({
            key: newReasonForDeceased?.id ?? "",
            name: newReasonForDeceased?.reasonLongName ?? "-",
          });
        }
      }
    }
  }, [delayAttempts, delayStatuses]);

  return (
    <ManagerTaskApprovalModalPresentation
      handleOnApprove={handleOnApprove}
      handleOnReject={handleOnReject}
      delayStatus={delayStatus}
      result={result}
      action={action}
      note={note}
      reasonForDeceased={reasonForDeceased}
      handleOnCancel={handleOnCancel}
      isLoadingApproveButton={isLoadingApproveButton}
      isLoadingRejectButton={isLoadingRejectButton}
      handleChangeNote={handleChangeNote}
      isFormDirty={isDirty}
      showNotesError={!checkNotesValidation()}
    />
  );
};

export default ManagerTaskApprovalModal;
