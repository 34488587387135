import TextField from "components/TextField/text-field.component";
import "./episode-edit.styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { getAdminState } from "state/feature/admin/admin.slice";
import { useEffect, useState } from "react";
import TagsSearchDropdown from "components/TagsSearchDropdown/tags-search-dropdown.component";
import { TagsSearchDropdownUseCase } from "components/TagsSearchDropdown/tags-search-dropdown.enum";
import Button from "components/Button/button.component";
import { setIsOpenEditEpisodeModal } from "state/feature/common/common.slice";
import { ITagsSearchDropdownType } from "shared/types/dropdown.type";
import { editEpisodeAsync, getEpisodesAsync } from "state/feature/admin/admin.action";
import { IEpisodeEditRequest } from "state/types/admin-slice.type";
import { useAppDispatch } from "state/store";
import { toast } from "react-toastify";
import { EPISODE_EDITED_SUCCESSFULLY, SOMETHING_WENT_WRONG } from "shared/constant/commonConstants";
import { getAuth } from "state/feature/auth/auth.slice";

const EpisodeEdit = function () {
  const appDispatch = useAppDispatch();
  const dispatch = useDispatch();
  const {
    episode: { currentEpisode, standardCareplans, episodeGetRequest },
  } = useSelector(getAdminState);

  const { user } = useSelector(getAuth);

  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isPristine, setIsPristine] = useState(true);
  const [selectedCarePlans, setSelectedCarePlans] = useState<Array<{ key: string; name: string; value: string }>>([]);

  useEffect(() => {
    if (currentEpisode && currentEpisode.standardCarePlans.length) {
      setSelectedCarePlans(
        currentEpisode.standardCarePlans.map(({ id, name }) => ({ key: id.toString(), name, value: id.toString() }))
      );
    }
  }, [currentEpisode]);
  const compareSearchText = (value1: string, value2: string): boolean => {
    return value1.toLowerCase().includes(value2.toLowerCase());
  };

  const filterCarePlanDropdown = (obj: ITagsSearchDropdownType): boolean => {
    if (selectedCarePlans.length > 0) {
      const isPresent: boolean =
        !selectedCarePlans.some((item: ITagsSearchDropdownType) => item.key === obj.key) &&
        compareSearchText(obj.name, searchText);
      return isPresent;
    } else {
      return compareSearchText(obj.name, searchText);
    }
  };

  const getCarePlanDropdownMenu = (): ITagsSearchDropdownType[] => {
    return standardCareplans
      .map((el) => ({ key: el.id.toString(), name: el.label, value: el.id } as ITagsSearchDropdownType))
      .filter((obj: ITagsSearchDropdownType) => filterCarePlanDropdown(obj));
  };

  const handleSave = async function () {
    setIsLoading(true);
    try {
      const request: IEpisodeEditRequest = {
        id: currentEpisode!.id,
        standardCarePlanIds: selectedCarePlans.map((el) => parseInt(el.key)),
        updatedBy: user.navigatorId,
      };
      await appDispatch(editEpisodeAsync(request)).unwrap();
      setIsLoading(false);
      toast.success(EPISODE_EDITED_SUCCESSFULLY, {
        toastId: "edit-episode-sucess",
        containerId: "main",
      });
      dispatch(setIsOpenEditEpisodeModal(false));
      dispatch(getEpisodesAsync(episodeGetRequest));
    } catch (err) {
      setIsLoading(false);
      toast.error(SOMETHING_WENT_WRONG, {
        toastId: "edit-episode-error",
        containerId: "main",
      });
    }
  };

  return (
    <div className="episode-edit-container">
      <div className="title">Edit Episode</div>
      <div className="form-body">
        <div className="row">
          <div className="col">
            <TextField
              label="Episode Name"
              type="text"
              value={currentEpisode!.episodeName}
              inputClassName="disabled"
              disabled={true}
              setValue={() => {}}
            />
          </div>
          <div className="col">
            <TextField
              label="Program Name"
              type="text"
              value={currentEpisode!.programName}
              inputClassName="disabled"
              disabled={true}
              setValue={() => {}}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="dropdown-label">
              Standard Care Plan
              <span className="optional-text">{`(${selectedCarePlans.length} of 5 selected)`}</span>
            </div>

            <TagsSearchDropdown
              idFieldName=""
              value={selectedCarePlans}
              dropDownContainerClass="assigned-careplan-dropdown-container"
              dropDownBoxClass="assigned-careplan-dropdown"
              selectionClass="assigned-careplan-selection "
              onSearchTextChange={(val: string) => {
                setSearchText(val);
              }}
              handleValueChanges={(val: any) => {
                if (selectedCarePlans.length <= 5) {
                  setSelectedCarePlans(val);
                  setIsPristine(false);
                }
              }}
              dropDownMenuItems={getCarePlanDropdownMenu()}
              placeholder="Search by Care Plan"
              useCaseType={TagsSearchDropdownUseCase.STANDARD_CAREPLAN}
              maximumRange={5}
              renderFrom="admin-episode"
            />
          </div>
        </div>
      </div>
      <div className="form-footer">
        <Button
          text="Cancel"
          className={`cancel-button`}
          disabled={isLoading}
          onClick={() => dispatch(setIsOpenEditEpisodeModal(false))}
        />
        <Button text="Save" className={`submit-button`} disabled={isLoading || isPristine} onClick={handleSave} />
      </div>
    </div>
  );
};

export default EpisodeEdit;
