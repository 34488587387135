import Header from "components/Header/header.component";
import { useState, useEffect, useCallback, useMemo } from "react";
import GenericTabs from "components/GenericTabs/generic-tabs.component";
import { TaskManagementTab } from "shared/enums/task-management-tabs.enum";
import { ActiveTabName } from "components/GenericTabs/props";
import CollapseArrow from "shared/assets/images/expandable-section-arrow.svg";
import ExpandArrow from "shared/assets/images/collapse-section-arrow.svg";
import { useSelector } from "react-redux";
import {
  expandTaskBoard,
  saveSelectedCareManagerIds,
  getTaskBoardState,
  getBrowserState,
} from "state/feature/task-management/task-management.slice";
import { useAppDispatch } from "state/store";
import TaskList from "./task-list/task-list.container";
import CommonState from "components/common-state/common-state.component";
import { CommonStateType } from "components/common-state/common-state.enum";
import { CustomToolTip } from "components/CustomTooltip/custom-tooltip.component";
import { getAuth } from "state/feature/auth/auth.slice";
import { getTasksAsync } from "state/feature/task-management/task-management.action";
import { debounce } from "lodash";
import { TASKS_LIMIT } from "./constant";
import { useParams } from "react-router";
import Loading from "shared/assets/images/loader.gif";
import TagsSearchDropdown from "components/TagsSearchDropdown/tags-search-dropdown.component";
import { TagsSearchDropdownUseCase } from "components/TagsSearchDropdown/tags-search-dropdown.enum";
import { ITagsSearchDropdownType } from "shared/types/dropdown.type";
import { getBackUpNavigatorListAsync } from "state/feature/navigator/navigator.action";
import { INavigator } from "state/types/navigator.type";
import { getPatientFullName } from "shared/methods/utilityFunctions";
import SearchBox from "components/SearchBox/search-box.component";
import { Task } from "state/types/task-management-slice.type";
import { TaskPageName } from "shared/enums/page-name.enum";
import moment from "moment";

const TaskManagement = () => {
  const dispatch = useAppDispatch();
  const { isExpanded: isTaskListExpanded, tasks, areTasksLoading } = useSelector(getTaskBoardState);
  const [activeTabName, setActiveTabName] = useState<ActiveTabName>(TaskManagementTab.ACTIVE);
  const authState = useSelector(getAuth);
  const [searchText, setSearchText] = useState("");
  const [searchTaskText, setSearchTaskText] = useState("");
  const [backUpNavigatorList, setBackUpNavigatorList] = useState<{ key: string; name: string }[]>([]);
  const [selectedBackupNavigatorList, setSelectedBackupNavigatorList] = useState<Array<ITagsSearchDropdownType>>([]);
  const { currentTab } = useSelector(getBrowserState);

  const taskInitialState = {
    careManagerIds: [authState.user.navigatorId],
    offset: 0,
    limit: TASKS_LIMIT,
    taskType: 1,
  };
  const [tasksRequestPayload, setTaskRequestPayload] = useState(taskInitialState);

  useEffect(
    () => {
      if (currentTab?.intakeId && currentTab?.taskId) {
        dispatch(expandTaskBoard(false));
      } else {
        if (currentTab?.taskType !== TaskPageName.NEW_MANUAL_TASK) {
          dispatch(expandTaskBoard(true));
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentTab]
  );

  useEffect(() => {
    dispatch(
      getBackUpNavigatorListAsync({
        types: ["PNAV", "TNAV"],
      })
    ).then((response) => {
      const dataForBackUpNavigatorList = response.payload.map((elem: INavigator) => ({
        key: elem?.id,
        name: getPatientFullName(elem),
        careManagerId: elem.careManagerId,
        email: elem.email,
        value: elem.email,
      }));
      setBackUpNavigatorList(dataForBackUpNavigatorList);
      setSelectedBackupNavigatorList([
        dataForBackUpNavigatorList.find((nav: INavigator) => nav.email === authState.user.email),
      ]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getTasksAsync({ ...tasksRequestPayload, currentDate: moment().format("YYYY-MM-DDTHH:mm:ss") }));
    dispatch(
      saveSelectedCareManagerIds({
        ids: [...tasksRequestPayload.careManagerIds],
        taskType: tasksRequestPayload.taskType,
      })
    );
  }, [dispatch, tasksRequestPayload]);

  const handleSearchTask = (taskList: Task[]) => {
    return taskList.filter(
      (task: Task) =>
        task.taskName.toLowerCase().includes(searchTaskText.toLowerCase()) ||
        task.patientName.toLowerCase().includes(searchTaskText.toLowerCase()) ||
        task.provider?.toLowerCase().includes(searchTaskText.toLowerCase()) ||
        task.physicianName.toLowerCase().includes(searchTaskText.toLowerCase())
    );
  };

  const getTasks = () => {
    const taskList = activeTabName === TaskManagementTab.ACTIVE ? tasks.active : tasks.delayed;
    if (searchTaskText.length > 2) {
      return handleSearchTask(taskList);
    }
    return taskList;
  };

  const changeSearchTaskText = useCallback((text: string) => {
    setSearchTaskText(text);
  }, []);

  const debouncedSearch = useMemo(() => {
    return debounce(changeSearchTaskText, 300);
  }, [changeSearchTaskText]);

  const onClickTabName = (tabName: ActiveTabName) => {
    setActiveTabName(tabName);
    setSearchTaskText("");
    setTaskRequestPayload((prevState) => {
      return {
        ...prevState,
        taskType: tabName === TaskManagementTab.DELAYED ? 2 : 1,
      };
    });
  };

  const incrementPage = () => {
    setTaskRequestPayload((prev) => {
      return {
        ...prev,
        offset: prev.offset + TASKS_LIMIT,
      };
    });
  };

  const checkSearchText = (value1: string, value2: string): boolean => {
    return value1.toLowerCase().includes(value2.toLowerCase());
  };

  const filterBackupNavigatorDropdown = (obj: ITagsSearchDropdownType): boolean => {
    return selectedBackupNavigatorList.length > 0
      ? !selectedBackupNavigatorList.some((val: ITagsSearchDropdownType) => val.key === obj.key) &&
          checkSearchText(obj.name, searchText)
      : checkSearchText(obj.name, searchText);
  };
  const getBackupNavigatorDropdownMenu = (): ITagsSearchDropdownType[] => {
    return backUpNavigatorList.filter((obj: ITagsSearchDropdownType) => filterBackupNavigatorDropdown(obj));
  };

  return (
    <div className="task-management">
      {isTaskListExpanded && (
        <>
          <div>
            <img
              data-tip
              data-for="Hide Task list"
              src={CollapseArrow}
              className="collapse-section-arrow"
              alt="collapse-arrow"
              onClick={() => dispatch(expandTaskBoard(false))}
            />

            <CustomToolTip text={"Hide Task list"} id="Hide Task list" place="right" />
          </div>
        </>
      )}
      {!isTaskListExpanded && (
        <>
          <img
            data-tip
            data-for="Show Task list"
            src={ExpandArrow}
            className="expand-section-arrow"
            alt="expand-arrow"
            onClick={() => {
              dispatch(expandTaskBoard(true));
            }}
          />
          <CustomToolTip text={"Show Task list"} id="Show Task list" place="right" />
        </>
      )}
      <div className={`${isTaskListExpanded ? "expanded" : "collapsed"}`}>
        <Header className="task-management">
          <div className="header-title">Today’s Task List</div>
        </Header>
        <div className="middle-section">
          <div className="tags">
            Include:
            <TagsSearchDropdown
              idFieldName=""
              value={selectedBackupNavigatorList}
              dropDownContainerClass="backup-navigator-dropdown-container-profile"
              dropDownBoxClass=""
              selectionClass="backup-navigator-dropdown"
              onSearchTextChange={(val: string) => {
                setSearchText(val);
              }}
              handleValueChanges={(val: Array<ITagsSearchDropdownType>) => {
                if (selectedBackupNavigatorList.length <= 6) {
                  setSelectedBackupNavigatorList(val);
                  getBackupNavigatorDropdownMenu();
                  setTaskRequestPayload((prev: typeof taskInitialState) => {
                    return {
                      ...prev,
                      careManagerIds: val.map((item) => item.careManagerId),
                    };
                  });
                }
              }}
              useCaseType={TagsSearchDropdownUseCase.BACKUP_NAVIGATOR}
              dropDownMenuItems={getBackupNavigatorDropdownMenu()}
              placeholder="Search by navigator name"
              maximumRange={6}
              isDisabled={false}
              isOptionDisabled={authState.user.email}
              renderFrom="task-list"
            />
          </div>
          <GenericTabs
            tabsList={[
              {
                name: TaskManagementTab.ACTIVE,
              },
              {
                name: TaskManagementTab.DELAYED,
              },
            ]}
            activeTabName={activeTabName}
            setActiveTabName={onClickTabName}
          />
          <div className="search-sort-section">
            <SearchBox
              icon="cross"
              className="search-box"
              iconClassName="search-icon"
              showIcon={searchTaskText.length > 0}
              SearchBoxSize={22}
              onClick={() => {
                setSearchTaskText("");
              }}
              onTextChange={debouncedSearch}
              placeholder="Search by patient/task/physician/facility name"
            />
          </div>
        </div>
        {areTasksLoading && getTasks().length === 0 ? (
          <div className="empty-state-container loader">
            <img src={Loading} alt="loading" />
          </div>
        ) : !areTasksLoading && getTasks().length === 0 && searchTaskText.length <= 2 ? (
          <div className="no-task-found-container">
            <CommonState type={CommonStateType.NO_TASKS_AVAILABLE} />
          </div>
        ) : !areTasksLoading && getTasks().length === 0 && searchTaskText.length > 2 ? (
          <CommonState type={CommonStateType.SEARCH_TASK_NOT_FOUND} />
        ) : (
          <TaskList
            tasks={getTasks()}
            isLoading={areTasksLoading}
            incrementPage={incrementPage}
            searchText={searchTaskText.length > 2 ? searchTaskText : ""}
            activeTabName={activeTabName}
          />
        )}
      </div>
    </div>
  );
};

export default TaskManagement;
