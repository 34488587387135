import CustomDropDown from "components/CustomDropdown/custom-dropdown.component";
import SingleDatePicker from "components/single-date-picker/single-date-picker.component";
import moment from "moment";

import { isEmptyString } from "shared/methods/utilityFunctions";
import Error from "components/error-message/error-message.component";
import { ManualTaskInputFieldsProps } from "./props";

const ManualTasksInputFields = (props: ManualTaskInputFieldsProps) => {
  const {
    questionId,
    fieldType,
    required,
    textLength,
    disabled,
    name,
    placeHolder,
    onChangeValue,
    description,
    answer,
    options,
    isDirty,
    minDate,
    isDueDateDisabled,
    dropdownRef,
  } = props;
  switch (fieldType) {
    case "text-area":
      return (
        <div className={`manual-task-input-field text-area-container text-area-main-container`}>
          {description && <div className="label">{description}</div>}
          <textarea
            className="text-area"
            id={`textarea`}
            name={`textarea`}
            maxLength={textLength ?? undefined}
            cols={70}
            rows={5}
            placeholder={placeHolder ?? ""}
            disabled={disabled ?? false}
            value={answer ?? ""}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
              if (!/^\s/.test(event.currentTarget.value)) {
                onChangeValue(event.currentTarget.value, name, "text");
              }
            }}
          />
          <div className={`text-area-container-max-length`}>
            {textLength && answer && typeof answer === "string" && answer.length > 0 && !disabled && (
              <div className="text-area-title">{`${
                answer && answer.length > 0 ? answer.length : "0"
              }/${textLength}`}</div>
            )}
          </div>
          {isDirty && required && (!answer || (answer && isEmptyString(answer))) && !disabled && (
            <Error message="This field is required" />
          )}
        </div>
      );

    case "text-box":
      return (
        <div className="text-selector-container manual-task-input-field">
          {description && <div className="label">{description}</div>}
          <input
            name={`${questionId}`}
            type="text"
            className={`text-selector ${disabled ? "disabled" : ""}`}
            maxLength={textLength ?? undefined}
            placeholder={placeHolder ?? ""}
            value={answer ?? ""}
            disabled={disabled ?? false}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (!/^\s/.test(event.currentTarget.value)) {
                onChangeValue(event.currentTarget.value, name, "text");
              }
            }}
          />
          <div className="error-container">
            <div className={"text-container-max-length"}>
              <div>
                {isDirty && required && (!answer || (answer && isEmptyString(answer))) && !disabled && (
                  <Error message="This field is required" />
                )}
              </div>
              {textLength && answer && typeof answer === "string" && answer.length > 0 && !disabled && (
                <div className="text-length">{`${
                  answer && answer.length > 0 ? answer.length : "0"
                }/${textLength}`}</div>
              )}
            </div>
          </div>
        </div>
      );

    case "date-selector":
      return (
        <div className="days-selector-container manual-task-input-field">
          {description && <div className=" label">{description}</div>}
          <div className="single-date-picker-selector-container">
            <SingleDatePicker
              defaultDate={answer ?? null}
              minDate={minDate ? minDate : new Date().setDate(new Date().getDate() - 1)}
              maxDate={null}
              isDisabled={isDueDateDisabled ?? false}
              isError={false}
              handleDateChange={(val: any) => {
                onChangeValue(val ? moment(val).toString() : null, name, "date");
              }}
              popperPlacement="bottom"
            />
          </div>

          <div className="error-container">
            {isDirty && required && !answer && !disabled && <Error message="This field is required" />}
          </div>
        </div>
      );

    case "single-select":
      return (
        <>
          <div
            className={`single-select-dropdown-container question-section-dropdown-container manual-task-input-field`}
          >
            {description && <p className=" label">{description}</p>}
            <CustomDropDown
              placeholder={placeHolder ?? ""}
              dropDownMenuItems={options && options.length > 0 ? options : []}
              handleValueChanges={(value: any) => {
                onChangeValue(value, name, "dropdown");
              }}
              value={answer}
              idFieldName="key"
              dropDownBoxClass="navigator-dropdown"
              isDisabled={disabled ?? false}
              selectionClass="navigator-selection"
              dropDownContainerClass="navigator-dropdown-container"
              message="Please select the navigator type"
              dropdownRef={dropdownRef}
            />
            <div className="error-container">
              {isDirty && required && !answer && !disabled && <Error message="This field is required" />}
            </div>
          </div>
        </>
      );
    default:
      return <div></div>;
  }
};
export default ManualTasksInputFields;
