import { IUser, CurrentUserDetails } from "./../../types/user-management-slice.type";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "shared/axios-instance";
import { getConfig } from "config/config";
import {
  IGetUsersAsyncRequestPayload,
  ISavePhysicianPayload,
} from "pages/user-management/common/user-management.types";
import { SavePatientDetailsPayload } from "pages/user-management/user-management-details/patient-form-details/patient-form-details.type";
import { Provider } from "pages/user-management/user-management-details/physician-form-details/physician-form-details.types";
import { SaveNavigatorDetailsPayloadType } from "pages/user-management/user-management-details/navigator-form-details/navigator-form-details.types";
import {
  extractCountryCodeAndFormatFromPhone,
  getLocalDateTime,
  getPatientFullName,
  getPatientMetaData,
} from "shared/methods/utilityFunctions";
import { AccountType, AllowPCPStatusType, StatusType } from "pages/user-management/common/user-management.enum";
import { TOAST_MESSAGES } from "pages/login/common/constants";
import { toast } from "react-toastify";
import { NAVIGATOR_TYPE_DROPDOWN_ITEMS } from "pages/user-management/common/user-management.constants";
import moment from "moment";

export const getUsersAsync = createAsyncThunk(
  "user-management/getUsersAsync",
  async (requestPayload: IGetUsersAsyncRequestPayload) => {
    const mappedRequestPayload = {
      pageSize: requestPayload.pageSize,
      pageNumber: requestPayload.pageNumber,
      searchText: requestPayload.searchText,
      sortColumn: requestPayload.sortColumn,
      sortOrder: requestPayload.sortOrder,
      userType: requestPayload.userType.join(","),
      includeInActive: requestPayload.includeInActive,
    };
    const response = await axiosInstance.post(
      `${getConfig().userManagementBase}/api/v1/UserManagement/users`,
      mappedRequestPayload
    );

    if (response.data) {
      return response.data.map((el: any) => {
        const user: IUser = {
          id: el.id,
          name: [el.firstName, el.middleName, el.lastName].join(" "),
          accountTypeId: el.userTypeId,
          email: el.email && el.email.length ? el.email : "-",
          status: el.isActive ? "Active" : "Inactive",
          createdAt:
            el?.recordCreated && el.recordCreated !== null
              ? `${moment(el.recordCreated).format("MMMM Do, YYYY ")} at ${moment(
                  moment.utc(el.recordCreated).toDate()
                ).format("hh:mm A")}`
              : "-",
        };

        return user;
      });
    }
    return [];
  }
);

export const getUsersTypeAsync = createAsyncThunk("user-management/getUsersTypeAsync", async () => {
  const response = await axiosInstance.get(`${getConfig().userManagementBase}/api/v1/UserManagement/user-types`);
  return response.data;
});

export const getPatientDetailsAsync = createAsyncThunk(
  "user-management/getPatientDetailsAsync",
  async (searchQuery: string) => {
    const response = await axiosInstance.get(
      `${getConfig().userManagementBase}/api/v1/UserManagement/patients?name=${searchQuery}`
    );
    return response.data;
  }
);

export const getAssociatedPhysiciansAsync = createAsyncThunk(
  "user-management/getAssociatedPhysiciansAsync",
  async (clientId: string | undefined) => {
    const urlClientId = clientId ? `clientId=${clientId}` : "clientId";
    const response = await axiosInstance.get(
      `${getConfig().userManagementBase}/api/v1/UserManagement/associated-physicians?${urlClientId}`
    );
    return response.data;
  }
);

export const getClientsAsync = createAsyncThunk("user-management/getClientsAsync", async () => {
  const response = await axiosInstance.get(`${getConfig().userManagementBase}/api/v1/UserManagement/clients`);
  return response.data;
});

export const saveNavigatorDetailsAsync = createAsyncThunk(
  "user-management/saveNavigatorDetailsAsync",
  async (requestPayload: SaveNavigatorDetailsPayloadType, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `${getConfig().userManagementBase}/api/v1/UserManagement/navigator`,
        requestPayload
      );
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err?.response);
    }
  }
);

export const savePhysicianAsync = createAsyncThunk(
  "user-management/savePhysicianAsync",
  async (payload: ISavePhysicianPayload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `${getConfig().userManagementBase}/api/v1/UserManagement/physician`,
        payload
      );
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err?.response);
    }
  }
);

export const savePatientDetailsAsync = createAsyncThunk(
  "user-management/savePatientDetailsAsync",
  async (requestPayload: SavePatientDetailsPayload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `${getConfig().userManagementBase}/api/v1/UserManagement/patient`,
        requestPayload
      );
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err?.response);
    }
  }
);

export const getPatientDetailsByIdAsync = createAsyncThunk(
  "user-management/getPatientDetailsByIdAsync",
  async (id: string, { rejectWithValue }) => {
    try {
      const patientDetailsResponseById = await axiosInstance.get(
        `${getConfig().userManagementBase}/api/v1/UserManagement/patient?userId=${id}`
      );

      if (patientDetailsResponseById.status === 200) {
        const { data: PatientDetailsById } = patientDetailsResponseById;
        if (PatientDetailsById && PatientDetailsById.patientId) {
          const patientDetailsResponseByPatientId = await axiosInstance.get(
            `${getConfig().userManagementBase}/api/v1/UserManagement/patient-details?patientId=${
              PatientDetailsById.patientId
            }`
          );
          if (patientDetailsResponseByPatientId.status === 200) {
            const {
              email,
              isActive,
              firstName,
              middleName,
              lastName,
              phoneNumber,
              recordCreated,
              recordUpdated,
              recordCreatedBy,
              recordUpdatedBy,
              patientId,
            } = patientDetailsResponseById.data;
            const {
              dob,
              genderId,
              genderCode,
              genderDescription,
              mbi,
              clientID,
              firstName: firstNameFromFusionDB,
              middleName: middleNameFromFusionDB,
              lastName: lastNameFromFusionDB,
            } = patientDetailsResponseByPatientId.data;
            const recordCreatedAt = getLocalDateTime(recordCreated ? recordCreated : "");
            const recordUpdatedAt = getLocalDateTime(recordUpdated ? recordUpdated : "");
            const created = recordCreatedBy ? `${recordCreatedAt} by ${recordCreatedBy}` : `${recordCreatedAt}`;
            const updated = recordUpdatedBy ? `${recordUpdatedAt} by ${recordUpdatedBy}` : `${recordUpdatedAt}`;
            const mappedResponse: CurrentUserDetails | null = {
              accountType: AccountType.PATIENT,
              email: email ? email : "",
              status: isActive ? StatusType.ACTIVE : StatusType.INACTIVE,
              firstName: firstName ? firstName : "",
              middleName: middleName ? middleName : "",
              lastName: lastName ? lastName : "",
              phone: extractCountryCodeAndFormatFromPhone(phoneNumber ? phoneNumber : ""),
              createdAt: recordCreated ? created : "",
              updatedAt: recordUpdated ? updated : "",
              dob: dob,
              mbi,
              genderDescription,
              id: "",
              genderId: genderId,
              genderCode: genderCode,
              clientID: clientID ? clientID : 0,
              isActive: false,
              patientId: patientId,
              providerIds: [],
              careManagerId: "",
            };
            const fullName = getPatientFullName({
              firstName: firstNameFromFusionDB,
              middleName: middleNameFromFusionDB,
              lastName: lastNameFromFusionDB,
            });
            const patientDetailsFromFusionDB = `${fullName} ${getPatientMetaData(mappedResponse)}`;

            return { currentDetails: mappedResponse, currentPatientDetails: patientDetailsFromFusionDB };
          } else if (patientDetailsResponseByPatientId.status === 204) {
            toast.error(TOAST_MESSAGES.NO_ACCOUNT_EXIST, {
              containerId: "main",
              toastId: "error",
            });
          }
        } else {
          toast.error("Patient information is not linked with this user.", {
            containerId: "main",
            toastId: "error",
          });
        }
      } else if (patientDetailsResponseById.status === 204) {
        toast.error(TOAST_MESSAGES.NO_ACCOUNT_EXIST, {
          containerId: "main",
          toastId: "error",
        });
      }
    } catch (err: any) {
      return rejectWithValue(err?.response);
    }
  }
);

export const getPatientDetailsByPatientIdAsync = createAsyncThunk(
  "user-management/getPatientDetailsByPatientIdAsync",
  async (id: string) => {
    const response = await axiosInstance.get(
      `${getConfig().userManagementBase}/api/v1/UserManagement/patient-details?patientId=${id}`
    );
    return response;
  }
);

export const getPhysicianDetailsByIdAsync = createAsyncThunk(
  "user-management/getPhysicianDetailsByIdAsync",
  async (id: string) => {
    const response = await axiosInstance.get(
      `${getConfig().userManagementBase}/api/v1/UserManagement/physician?userId=${id}`
    );
    if (response.status === 200) {
      const {
        email,
        firstName,
        lastName,
        middleName,
        isActive,
        phoneNumber,
        providerIds,
        clientId,
        recordCreated,
        recordUpdated,
        recordCreatedBy,
        recordUpdatedBy,
        isPhysicianExistInTprovider,
        transitionNavigatorId,
        allowPcp,
      } = response.data;
      const recordCreatedAt = getLocalDateTime(recordCreated ?? "");
      const recordUpdatedAt = getLocalDateTime(recordUpdated ?? "");
      const created = recordCreatedBy ? `${recordCreatedAt} by ${recordCreatedBy}` : `${recordCreatedAt}`;
      const updated = recordUpdatedBy ? `${recordUpdatedAt} by ${recordUpdatedBy}` : `${recordUpdatedAt}`;

      const physicianDetails: CurrentUserDetails = {
        accountType: AccountType.PHYSICIAN,
        email: email ?? "",
        firstName,
        middleName,
        lastName,
        phone: phoneNumber ? phoneNumber.replace("+1", "").replace(/\D+/g, "") : "",
        createdAt: recordCreated ? created : "",
        updatedAt: recordUpdated ? updated : "",
        status: isActive ? StatusType.ACTIVE : StatusType.INACTIVE,
        isActive: false,
        providerIds: providerIds,
        dob: "",
        mbi: "",
        clientId: clientId,
        careManagerId: "",
        isPhysicianExistInTprovider,
        transitionNavigatorId,
        allowPcp: allowPcp ? AllowPCPStatusType.ALLOWED : AllowPCPStatusType.NOT_ALLOWED,
      };
      return physicianDetails;
    }

    return response.data;
  }
);

export const getAssociatedPhysiciansByProviderIdsAsync = createAsyncThunk(
  "user-management/getAssociatedPhysiciansByProviderIdsAsync",
  async (ids: Array<number>): Promise<Array<Provider>> => {
    const response = await axiosInstance.post(`${getConfig().userManagementBase}/api/v1/UserManagement/providers`, ids);
    return response.data;
  }
);

export const getNavigatorByIdAsync = createAsyncThunk(
  "user-management/getNavigatorByIdAsync",
  async (id: string, { rejectWithValue }) => {
    try {
      const navigatorDetailsById = await axiosInstance.get(
        `${getConfig().userManagementBase}/api/v1/UserManagement/navigator?userId=${id}`
      );
      if (navigatorDetailsById.status === 200) {
        const {
          firstName,
          middleName,
          lastName,
          email,
          phoneNumber,
          careManagerId,
          isActive,
          recordCreated,
          recordUpdated,
          recordCreatedBy,
          recordUpdatedBy,
          clientId,
        } = navigatorDetailsById.data;

        if (careManagerId) {
          const navigatorDetailsFromCosmos = await axiosInstance.get(
            `${getConfig().userManagementBase}/api/v1/UserManagement/existing-navigator-details?id=${careManagerId}`
          );
          if (navigatorDetailsFromCosmos.status === 200) {
            const recordCreatedAt = getLocalDateTime(recordCreated);
            const recordUpdatedAt = getLocalDateTime(recordUpdated);
            const created = recordCreatedBy ? `${recordCreatedAt} by ${recordCreatedBy}` : `${recordCreatedAt}`;
            const updated = recordUpdatedBy ? `${recordUpdatedAt} by ${recordUpdatedBy}` : `${recordUpdatedAt}`;
            const { id: navigatorId, navigatorType, managerId } = navigatorDetailsFromCosmos.data;

            const navigatorDetails: CurrentUserDetails = {
              accountType: AccountType.NAVIGATOR,
              id: navigatorId,
              careManagerId: careManagerId ? careManagerId : "",
              email: email ? email : "",
              firstName: firstName ? firstName : "",
              middleName: middleName ? middleName : "",
              lastName: lastName ? lastName : "",
              phone: extractCountryCodeAndFormatFromPhone(phoneNumber),
              navigatorType: NAVIGATOR_TYPE_DROPDOWN_ITEMS[navigatorType],
              createdAt: recordCreated ? created : "",
              updatedAt: recordUpdated ? updated : "",
              clientID: clientId ? clientId : 0,
              managerId: managerId,
              isActive: isActive,
              status: isActive ? StatusType.ACTIVE : StatusType.INACTIVE,
              providerIds: [],
              dob: "",
              mbi: "",
            };
            return navigatorDetails;
          } else if (navigatorDetailsFromCosmos.status === 204) {
            toast.error(TOAST_MESSAGES.NO_ACCOUNT_EXIST, {
              containerId: "main",
              toastId: "error",
            });
            return navigatorDetailsFromCosmos;
          }
        } else {
          toast.error(TOAST_MESSAGES.ERROR, {
            containerId: "main",
            toastId: "error",
          });
        }
      }
    } catch (error: any) {
      rejectWithValue(error?.response);
    }
  }
);

export const getExistingNavigatorByIdFromCosmosAsync = createAsyncThunk(
  "user-management/getExistingNavigatorByIdFromCosmosAsync",
  async (id: string) => {
    const response = await axiosInstance.get(
      `${getConfig().userManagementBase}/api/v1/UserManagement/existing-navigator-details?id=${id}`
    );
    return response;
  }
);

export const getExistingNavigatorByEmailAsync = createAsyncThunk(
  "user-management/getExistingNavigatorByEmailAsync",
  async (email: string, { rejectWithValue }) => {
    try {
      const navigatorDetails = await axiosInstance.get(
        `${getConfig().userManagementBase}/api/v1/UserManagement/existing-navigator?EmailId=${email}`
      );
      if (navigatorDetails.status === 200) {
        const { firstName, middleName, lastName, phoneNumber, id, isActive, clientId } = navigatorDetails.data;
        const currentNavigatorDetails: CurrentUserDetails = {
          accountType: AccountType.NAVIGATOR,
          id: id,
          email: email,
          firstName: firstName ? firstName : "",
          middleName: middleName ? middleName : "",
          lastName: lastName ? lastName : "",
          phone: extractCountryCodeAndFormatFromPhone(phoneNumber),
          status: isActive ? StatusType.ACTIVE : StatusType.INACTIVE,
          navigatorType: null,
          clientID: clientId ? clientId : 0,
          createdAt: "",
          updatedAt: "",
          careManagerId: "",
          isActive: false,
          providerIds: [],
          dob: "",
          mbi: "",
        };
        return currentNavigatorDetails;
      } else {
        return navigatorDetails;
      }
    } catch (error: any) {
      rejectWithValue(error?.response);
    }
  }
);

export const getNavigatorManagersAsync = createAsyncThunk("user-management/getNavigatorManagersAsync", async () => {
  const response = await axiosInstance.get(
    `${getConfig().userManagementBase}/api/v1/UserManagement/navigator-managers`
  );
  return response.data;
});
