import { isMobile } from "react-device-detect";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppRoutes from "routes";
import { TwilioClientServiceProvider } from "shared/services/twilio/twilio-client.service";
import { useAppDispatch } from "./state/store";
import { getConfigurationSettings } from "./state/feature/common/common.action";
import { useSelector } from "react-redux";
import { getCommonState } from "./state/feature/common/common.slice";
import "./App.styles.scss";
import { createContext, useEffect, useRef, useState } from "react";
import CommonState from "./components/common-state/common-state.component";
import { CommonStateType } from "components/common-state/common-state.enum";
import { getCallState } from "state/feature/call/call.slice";
import { SignalRServiceProvider } from "shared/services/signalr/signalr.service";
import { getAuth } from "state/feature/auth/auth.slice";
import { isEmptyString } from "shared/methods/utilityFunctions";
import IdleTimerContainer from "components/IdleTimerContainer/idle-timer.component";
import { CallingStatus } from "shared/enums/calling-status.enum";

export const CallContext = createContext({
  ongoingCall: false,
});

const App = () => {
  const appDispatch = useAppDispatch();
  const { user, auth } = useSelector(getAuth);
  const { isMinimizeIncomingCall } = useSelector(getCallState);
  const { isAppInitialized } = useSelector(getCommonState);
  const callingModalVisible = useRef(true);
  const [callingToastVisible, setCallingToastVisible] = useState(true);
  const [isOngoingCall, setIsOngoingCall] = useState(false);

  useEffect(() => {
    window.addEventListener("incomingCallDisconnect", () => {
      setCallingToastVisible(false);
      callingModalVisible.current = false;
    });
    window.addEventListener("incomingCall", () => {
      setCallingToastVisible(true);
      callingModalVisible.current = true;
    });
  }, []);

  useEffect(() => {
    window.addEventListener("storage", () => {
      if (
        localStorage.getItem("callStatus") === CallingStatus.CONNECTED ||
        localStorage.getItem("callStatus") === CallingStatus.RINGING
      ) {
        setIsOngoingCall(true);
      } else {
        setIsOngoingCall(false);
      }
    });
  }, []);

  useEffect(() => {
    const globalWindow = window as any;
    if (globalWindow && globalWindow.ReactNativeWebView && !isEmptyString(user.id) && auth.isAuthenticated) {
      globalWindow.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "loggedInUser",
          loggedInUser: {
            ...user,
            ...auth,
          },
        })
      );
    }
  }, [user, auth]);

  if (!isAppInitialized) {
    appDispatch(getConfigurationSettings());
  }

  if (!isAppInitialized) {
    return (
      <div className="loading-container">
        <CommonState type={CommonStateType.LOADING} />
      </div>
    );
  }

  return (
    <>
      <IdleTimerContainer>
        <TwilioClientServiceProvider>
          <div className="app">
            <>
              <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                limit={2}
                pauseOnHover
                enableMultiContainer
                containerId={"main"}
              />
              <ToastContainer
                position="top-center"
                autoClose={8000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick={false}
                enableMultiContainer
                containerId={"notification"}
              />
            </>
            {(callingModalVisible.current || callingToastVisible) && (
              <div
                className={`calling-container ${isMobile ? "mobileView" : ""} ${
                  isMinimizeIncomingCall ? "minimize-incoming-call" : ""
                }`}
              >
                <ToastContainer
                  position="bottom-right"
                  hideProgressBar={false}
                  newestOnTop={true}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss={false}
                  draggable
                  pauseOnHover
                  enableMultiContainer
                  containerId={"calling"}
                />
              </div>
            )}
            <SignalRServiceProvider>
              <CallContext.Provider value={{ ongoingCall: isOngoingCall }}>
                <AppRoutes />
              </CallContext.Provider>
            </SignalRServiceProvider>
          </div>
        </TwilioClientServiceProvider>
      </IdleTimerContainer>
    </>
  );
};

export default App;
