export enum AccountType {
  PATIENT = "Patient",
  PHYSICIAN = "Physician",
  NAVIGATOR = "Navigator",
}

export enum StatusType {
  ACTIVE = "Active",
  INACTIVE = "Inactive",
}

export enum AllowPCPStatusType {
  ALLOWED = "Allowed",
  NOT_ALLOWED = "Not Allowed",
}

export enum NavigatorType {
  PATIENT_NAVIGATOR = "Patient Navigator",
  TRANSITION_NAVIGATOR = "Transition Navigator",
}

export enum SortingOrderType {
  DEFAULT = "Default",
  ASC = "Asc",
  DESC = "Desc",
}
