import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AdminTab, ProvidersTab } from "pages/admin/common/constant";
import { IAdminState, IProvidersProviderGetType, IProvidersProviderType } from "state/types/admin-slice.type";
import {
  getAllPrivelegesListAsync,
  getClientsAsync,
  getEpisodesAsync,
  getProvidersTabProviderList,
  getStandardCareplans,
} from "./admin.action";
import { Episode } from "pages/admin/episodes/types";
import { ActiveTabName } from "components/GenericTabs/props";
import { SortingOrderType } from "shared/enums/sorting-types.enums";

const initialState: IAdminState = {
  isLoading: false,
  isError: false,
  activeTab: AdminTab.PROVIDERS,
  practiceDropdownOptions: [],
  episode: {
    records: [],
    totalCount: 0,
    episodeGetRequest: {
      clientId: 0,
      pageCount: 1,
      pageSize: 10,
    },
    standardCareplans: [],
    isSearching: false,
    isLoading: true,
  },
  appliedFilters: [],
  providers: {
    activeTab: ProvidersTab.PROVIDER,
    provider: {
      isLoading: true,
      isError: false,
      records: [],
      totalCount: 0,
      privelegesAtList: [],
      providerGetRequest: { clientId: 0, pageCount: 0, pageSize: 10, sortOrder: SortingOrderType.ASC },
      currentProvider: null,
      isLoadingPrivelegesList: false,
    },
    isSearching: false,
  },
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setPracticeOption: (state, action) => {
      state.selectedPractice = action.payload;
    },
    setEpisodesIsSearching: (state, action) => {
      state.episode.isSearching = action.payload;
    },
    setEpisodesSearch: (state, action) => {
      state.episode.searchText = action.payload.searchText;
      state.episode.searchBy = action.payload.searchBy;
    },
    clearEpisodesSearch: (state) => {
      state.episode.searchText = "";
      state.episode.searchBy = "";
    },
    setEpisodeGetRequest: (state, action) => {
      state.episode.episodeGetRequest = { ...state.episode.episodeGetRequest, ...action.payload };
    },
    resetEpisodeGetRequest: (state) => {
      state.episode.episodeGetRequest = {
        clientId: 0,
        pageCount: 1,
        pageSize: 10,
      };
    },
    setProvidersProviderGetRequest: (state, action) => {
      state.providers.provider.providerGetRequest = {
        ...state.providers.provider.providerGetRequest,
        ...action.payload,
      };
    },

    clearProvidersSearch: (state) => {
      state.providers.provider.providerGetRequest.searchToken = "";
      state.providers.provider.providerGetRequest.searchBy = "";
      state.providers.isSearching = false;
    },
    setCurrentEpisode: (state, action) => {
      state.episode.currentEpisode = { ...state.episode.currentEpisode, ...action.payload };
    },
    resetEpisode: (state) => {
      state.isLoading = true;
      state.episode = {
        records: [],
        totalCount: 0,
        episodeGetRequest: {
          clientId: 0,
          pageCount: 1,
          pageSize: 10,
        },
        isSearching: false,
        searchText: "",
        searchBy: "",
        standardCareplans: [],
        isLoading: false,
      };
    },
    resetProvidersProvider: (state) => {
      state.providers = {
        ...state.providers,
        provider: {
          isLoading: true,
          isError: false,
          records: [],
          totalCount: 0,
          privelegesAtList: [],
          providerGetRequest: { clientId: 0, pageCount: 0, pageSize: 10, sortOrder: SortingOrderType.ASC },
          currentProvider: null,
          isLoadingPrivelegesList: false,
        },
        isSearching: false,
      };
    },
    setProvidersActiveTab: (state, action: PayloadAction<ActiveTabName>) => {
      state.providers.activeTab = action.payload;
    },
    setProvidersIsSearching: (state, action: PayloadAction<boolean>) => {
      state.providers.isSearching = action.payload;
    },
    setCurrentProvidersProvider: (state, action: PayloadAction<IProvidersProviderType | null>) => {
      state.providers.provider.currentProvider = action.payload;
    },
  },
  extraReducers: (builder) => {
    return (
      builder.addCase(getEpisodesAsync.pending, (state) => {
        state.episode.isLoading = true;
      }),
      builder.addCase(getEpisodesAsync.fulfilled, (state, action) => {
        state.episode.isLoading = false;
        const date = new Date();
        state.episode.records = action.payload.records.map(
          (el: any) =>
            ({
              id: el.id,
              episodeName: el.episodeName,
              programName: el.programName,
              episodeTypeId: el.episodeTypeId.toString(),
              workflowDomain: el.workflowDomain,
              standardCarePlans: el.standardCarePlans,
              startDate: el.startDate,
              endDate: el.endDate,
              isActive: date >= new Date(el.startDate) && date <= new Date(el.endDate),
            } as Episode)
        );
        state.episode.totalCount = action.payload.totalCount;
      }),
      builder.addCase(getClientsAsync.pending, (state, action) => {
        state.isLoading = true;
      }),
      builder.addCase(getClientsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.practiceDropdownOptions = action.payload.map((el: any) => ({ key: el.id, name: el.longName }));
        state.selectedPractice = state.practiceDropdownOptions[0];
      }),
      builder.addCase(getClientsAsync.rejected, (state, action) => {
        state.isLoading = false;
      }),
      builder.addCase(getStandardCareplans.fulfilled, (state, action) => {
        state.episode.standardCareplans = action.payload;
      }),
      builder.addCase(getProvidersTabProviderList.pending, (state, action: any) => {
        state.providers.provider.isLoading = true;
      }),
      builder.addCase(
        getProvidersTabProviderList.fulfilled,
        (state, action: PayloadAction<{ records: Array<IProvidersProviderGetType>; totalCount: number }>) => {
          state.providers.provider.isLoading = false;
          state.providers.provider.isError = false;
          state.providers.provider.totalCount = action.payload.totalCount;
          state.providers.provider.records = action.payload.records;
        }
      ),
      builder.addCase(getProvidersTabProviderList.rejected, (state, action: any) => {
        state.providers.provider.isLoading = false;
        state.providers.provider.isError = true;
      }),
      builder.addCase(getAllPrivelegesListAsync.pending, (state, action) => {
        state.providers.provider.isLoadingPrivelegesList = true;
      }),
      builder.addCase(getAllPrivelegesListAsync.fulfilled, (state, action) => {
        state.providers.provider.isLoadingPrivelegesList = false;
        state.providers.provider.privelegesAtList = action.payload;
      }),
      builder.addCase(getAllPrivelegesListAsync.rejected, (state, action) => {
        state.providers.provider.isLoadingPrivelegesList = false;
      })
    );
  },
});

export default adminSlice;
export const {
  setActiveTab,
  setPracticeOption,
  setEpisodesIsSearching,
  setEpisodesSearch,
  clearEpisodesSearch,
  setEpisodeGetRequest,
  resetEpisodeGetRequest,
  setCurrentEpisode,
  resetEpisode,
  resetProvidersProvider,
  setProvidersActiveTab,
  setProvidersIsSearching,
  clearProvidersSearch,
  setProvidersProviderGetRequest,
  setCurrentProvidersProvider,
} = adminSlice.actions;

export const getAdminState = (state: any): IAdminState => state.admin;
