import { ActionMeta, SingleValue } from "react-select";
import AsyncSelect from "react-select/async";
import { searchWithResetStyles } from "./search-with-reset.styles";
import { isEmptyString } from "shared/methods/utilityFunctions";
import { useState } from "react";
import Button from "components/Button/button.component";
import "./search-with-reset.styles.scss";
import { ISelectedDropdownValue } from "state/types/navigator.type";

type SearchWithResetProps = {
  value: ISelectedDropdownValue;
  onClick: (val: SingleValue<ISelectedDropdownValue>, actionMeta: ActionMeta<ISelectedDropdownValue>) => void;
  onTextChange: (val: string) => void;
  placeholder: string;
  className: string;
  defaultOptions: ISelectedDropdownValue[];
  loadOptions: (inputValue: string, callback: (options: ISelectedDropdownValue[]) => void) => void;
  onClickReset: () => void;
  defaultValue: ISelectedDropdownValue;
  buttonText: string;
  isSearchable: boolean;
  notFoundMessage: string;
  inputId?: string;
  isDisabled: boolean;
};

const SearchWithReset = (props: SearchWithResetProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const {
    value,
    className,
    onClick,
    onTextChange,
    placeholder,
    defaultOptions,
    loadOptions,
    onClickReset,
    buttonText,
    isSearchable,
    notFoundMessage,
    inputId,
    isDisabled,
  } = props;
  return (
    <div className="search-with-reset-button-container">
      <AsyncSelect
        value={value}
        formatOptionLabel={(data: any) => (
          <div className={`option-text ${isDisabled ? "disabled" : ""}`}>
            <div className="left">{data.label}</div>
            <div className="right">{data.value}</div>
          </div>
        )}
        className={className}
        inputId={inputId ? inputId : ""}
        onChange={onClick}
        onInputChange={(val) => {
          if (!isEmptyString(val)) {
            setIsMenuOpen(true);
          } else {
            setIsMenuOpen(false);
          }
          if (!isEmptyString(val) && isSearchable && value) {
            onTextChange(val);
          }
        }}
        loadingMessage={() => <div className="loading">Loading...</div>}
        noOptionsMessage={() => <div className="loading">{notFoundMessage}</div>}
        isLoading={false}
        loadOptions={loadOptions}
        defaultOptions={defaultOptions}
        placeholder={placeholder}
        styles={searchWithResetStyles}
        defaultMenuIsOpen={false}
        closeMenuOnSelect
        isSearchable={isSearchable}
        menuIsOpen={isMenuOpen}
        isDisabled={isDisabled}
      />
      {!isDisabled && <Button className="reset-button no-border-button" text={buttonText} onClick={onClickReset} />}
    </div>
  );
};

export default SearchWithReset;
