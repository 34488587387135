export const checkboxData = [
  {
    value: "Incoming",
    name: "incoming",
    id: "Incoming-checkox",
  },
  {
    value: "Outgoing",
    name: "outgoing",
    id: "Outgoing-checkox",
  },
  {
    value: "Missed",
    name: "missed",
    id: "missed-checkox",
  },
];
