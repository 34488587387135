import { createSlice } from "@reduxjs/toolkit";
import { TOAST_MESSAGES } from "pages/login/common/constants";
import { toast } from "react-toastify";
import { deleteAllCookies, getCookie, setCookie } from "shared/methods/utilityFunctions";
import { IAuthProps, IAuthState, IUserProps } from "state/types/auth-slice.type";
import {
  getNavigatorRoleAsync,
  loginUserAsync,
  refreshTokenAsync,
  requestSendOtpAsync,
  requestUpdatePasswordAsync,
  requestVerifyOtpAsync,
} from "./auth.action";
import jwt_decode from "jwt-decode";
import moment from "moment";

function getLoggedInUserFromCookies(): IUserProps {
  const userFromCookie = getCookie("loggedInUser");
  if (userFromCookie) {
    return JSON.parse(userFromCookie); // TODO: try catch
  } else {
    return {
      id: "",
      email: "",
      username: "",
      firstName: "",
      lastName: "",
      roles: [],
      navigatorId: "",
    };
  }
}

export function getTokensFromCookies(): IAuthProps {
  const tokensFromCookie = getCookie("authTokens");
  if (tokensFromCookie) {
    return JSON.parse(tokensFromCookie); // TODO: try catch
  } else {
    return {
      isAuthenticated: false,
      resetPasswordCode: "",
      jwtToken: "",
      refreshToken: "",
      twilioAccessToken: "",
      expiryMinutes: "",
    };
  }
}

const initialState: IAuthState = {
  isLoading: false,
  isError: false,
  isSessionExpired: false,
  auth: getTokensFromCookies(),
  user: getLoggedInUserFromCookies(),
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsLoading: (state: any, action) => {
      state.isLoading = action.payload;
    },
    setError: (state: any, action) => {
      state.isError = action.payload;
    },
    setAuthenticationTokens: (state: any, action) => {
      state.isLoading = false;
      state.auth = action.payload;
    },
    logout: (state: any) => {
      state.isLoading = false;
      state.auth = {
        isAuthenticated: false,
        resetPasswordCode: "",
        jwtToken: "",
        refreshToken: "",
        twilioAccessToken: "",
        expiryMinutes: "",
      };
    },
    setUsername: (state: any, action) => {
      state.isLoading = false;
      state.username = action.payload;
    },
    setEmail: (state: any, action) => {
      state.isLoading = false;
      state.email = action.payload;
    },
    setId: (state: any, action) => {
      state.isLoading = false;
      state.id = action.payload;
    },
    setIsSessionExpired: (state: any, action) => {
      state.isSessionExpired = action.payload;
    },
    clearUser: (state: any) => {
      deleteAllCookies();
      sessionStorage.clear();
      localStorage.clear();
      state.user = {
        id: "",
        email: "",
        username: "",
        firstName: "",
        lastName: "",
        roles: [],
      };
      state.auth = {
        isAuthenticated: false,
        resetPasswordCode: "",
        jwtToken: "",
        refreshToken: "",
        twilioAccessToken: "",
        expiryMinutes: "",
      };
    },
    setExpiryMinutes: (state, action) => {
      state.auth.expiryMinutes = action.payload;
    },
  },
  extraReducers: (builder) => {
    return (
      builder.addCase(loginUserAsync.pending, (state: any, action) => {
        state.isLoading = true;
        state.isError = false;
      }),
      builder.addCase(loginUserAsync.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.isError = false;
        if (action.payload.succeeded) {
          const { twilioAccessToken } = getTokensFromCookies();
          const jwtPayload: any = jwt_decode(action.payload.jwtToken);
          setCookie(
            "loggedInUser",
            JSON.stringify({
              // ...state.user,
              // ...state.auth,
              id: action.payload.id,
              email: action.payload.email,
              username: action.meta.arg.username,
              firstName: action.payload.firstName,
              lastName: action.payload.lastName,
              navigatorId: jwtPayload.navigatorid,
            })
          );
          setCookie(
            "authTokens",
            JSON.stringify({
              isAuthenticated: true,
              jwtToken: action.payload.jwtToken,
              refreshToken: action.payload.refreshToken,
              twilioAccessToken: twilioAccessToken ?? "",
              expiryMinutes: moment(new Date(jwtPayload.exp * 1000))
                .diff(moment(new Date()), "minutes", true)
                .toFixed(0),
            })
          );
          state.user.id = action.payload.id;
          state.user.email = action.payload.email;
          state.user.username = action.meta.arg.username;
          state.user.firstName = action.payload.firstName;
          state.user.lastName = action.payload.lastName;
          state.user.navigatorId = jwtPayload.navigatorid;
          state.auth = {
            isAuthenticated: true,
            jwtToken: action.payload.jwtToken,
            refreshToken: action.payload.refreshToken,
            twilioAccessToken: twilioAccessToken ?? "",
            expiryMinutes: moment(new Date(jwtPayload.exp * 1000))
              .diff(moment(new Date()), "minutes", true)
              .toFixed(0),
          };
          const globalWindow = window as any;
          if (globalWindow && globalWindow.ReactNativeWebView) {
            globalWindow.ReactNativeWebView.postMessage(
              JSON.stringify({
                type: "loggedInUser",
                loggedInUser: {
                  ...state.user,
                  ...state.auth,
                },
              })
            );
          }
        } else if (action.payload.isLockedOut) {
          toast.error(TOAST_MESSAGES.USER_LOCKOUT, {
            toastId: "login-error",
            containerId: "main",
          });
        } else {
          toast.error(TOAST_MESSAGES.USER_NOT_EXIST, {
            toastId: "login-error",
            containerId: "main",
          });
        }
      }),
      builder.addCase(loginUserAsync.rejected, (state: any, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(TOAST_MESSAGES.ERROR, {
          toastId: "login-error",
          containerId: "main",
        });
      }),
      builder.addCase(requestSendOtpAsync.pending, (state: any, action) => {
        state.isLoading = true;
        state.isError = false;
      }),
      builder.addCase(requestSendOtpAsync.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.isError = false;
      }),
      builder.addCase(requestSendOtpAsync.rejected, (state: any, action) => {
        state.isLoading = false;
        state.isError = true;
      }),
      builder.addCase(requestVerifyOtpAsync.pending, (state: any, action) => {
        state.isLoading = true;
        state.isError = false;
      }),
      builder.addCase(requestVerifyOtpAsync.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.isError = false;
        if (!action.payload.result) {
          state.isError = true;
        }
      }),
      builder.addCase(requestVerifyOtpAsync.rejected, (state: any, action) => {
        state.isLoading = false;
        state.isError = true;
      }),
      builder.addCase(requestUpdatePasswordAsync.pending, (state: any, action) => {
        state.isLoading = true;
        state.isError = false;
      }),
      builder.addCase(requestUpdatePasswordAsync.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.isError = false;
      }),
      builder.addCase(requestUpdatePasswordAsync.rejected, (state: any, action) => {
        state.isLoading = false;
        state.isError = true;
      }),
      builder.addCase(getNavigatorRoleAsync.pending, (state: any, action) => {
        state.isLoading = true;
        state.isError = false;
      }),
      builder.addCase(getNavigatorRoleAsync.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.isError = false;
        state.user.roles = [action.payload];
      }),
      builder.addCase(getNavigatorRoleAsync.rejected, (state: any, action) => {
        state.isLoading = false;
        state.isError = true;
      }),
      builder.addCase(refreshTokenAsync.pending, (state: any, action: any) => {
        state.isSessionExpired = false;
      }),
      builder.addCase(refreshTokenAsync.fulfilled, (state: any, action: any) => {
        state.isSessionExpired = false;
        const jwtPayload: any = jwt_decode(action.payload.jwtToken);
        const { twilioAccessToken } = getTokensFromCookies();
        state.user.username = action.meta.arg.username;
        state.user.email = action.payload.email;
        state.user.firstName = action.payload.firstName;
        state.user.lastName = action.payload.lastName;
        state.user.id = action.payload.id;
        state.user.navigatorId = jwtPayload.navigatorid;
        state.auth = {
          isAuthenticated: true,
          jwtToken: action.payload.jwtToken,
          refreshToken: action.payload.refreshToken,
          twilioAccessToken: twilioAccessToken,
          expiryMinutes: moment(new Date(jwtPayload.exp * 1000))
            .diff(moment(new Date()), "minutes", true)
            .toFixed(0),
        };
        setCookie("loggedInUser", JSON.stringify(state.user));
        setCookie("authTokens", JSON.stringify(state.auth));
        const globalWindow = window as any;
        if (globalWindow && globalWindow.ReactNativeWebView) {
          globalWindow.ReactNativeWebView.postMessage(
            JSON.stringify({
              type: "refreshToken",
              loggedInUser: {
                ...state.user,
                ...state.auth,
              },
            })
          );
        }
      }),
      builder.addCase(refreshTokenAsync.rejected, (state: any, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSessionExpired = true;
        toast.dismiss("tokenRefresh");
        toast.error(TOAST_MESSAGES.SESSION_EXPIRED, {
          toastId: "error",
          containerId: "main",
        });
      })
    );
  },
});

export default authSlice;
export const {
  setIsLoading,
  setError,
  setUsername,
  setEmail,
  setId,
  clearUser,
  setIsSessionExpired,
  logout,
  setExpiryMinutes,
  setAuthenticationTokens,
} = authSlice.actions;
export const getAuth = (state: any): IAuthState => state.auth;
export const getUser = (state: any): IUserProps => state.auth.user;
