import Button from "components/Button/button.component";
import { SendOrderFooterProps } from "../../common/send-order.props";
import "./footer.style.scss";
import { TaskFooterButtonsText } from "shared/enums/task-management-footer.enum";
const SendOrderFooter = (props: SendOrderFooterProps) => {
  const {
    isDisabled,
    handleSubmitButton,
    isLoadingButton,
    handleCancelButton,
    handleDelayButton,
    isDelayedTaskRequiresApproval = false,
    isDisabledDelayedTaskRequiresApproval,
    handleOpenManagerTaskApproval,
  } = props;
  return (
    <footer className="send-order-footer-container">
      <Button
        text={TaskFooterButtonsText.CANCEL}
        className="cancel-button"
        disabled={false}
        onClick={handleCancelButton}
      />
      {handleDelayButton && !isDelayedTaskRequiresApproval && (
        <Button
          text={TaskFooterButtonsText.DELAY}
          className="cancel-button"
          onClick={handleDelayButton}
          disabled={isLoadingButton}
        />
      )}
      {handleOpenManagerTaskApproval && isDelayedTaskRequiresApproval && (
        <Button
          text={TaskFooterButtonsText.MANAGER_APPROVAL}
          className="manager-approval-button"
          onClick={handleOpenManagerTaskApproval}
          disabled={isDisabledDelayedTaskRequiresApproval || isLoadingButton}
        />
      )}
      {handleSubmitButton && (
        <Button
          text={TaskFooterButtonsText.SUBMIT}
          className={`submit-button`}
          onClick={() => handleSubmitButton()}
          disabled={isDisabled}
          showLoader={isLoadingButton}
        />
      )}
    </footer>
  );
};

export default SendOrderFooter;
