import { useEffect, useState } from "react";
import { ActiveTabName } from "components/GenericTabs/props";
import { TaskInfoTab } from "shared/enums/task-management-tabs.enum";
import { useSelector } from "react-redux";
import {
  calculateRisk,
  getPatientAndEpisodeDetailsAysnc,
  getPatientLastLoginAsync,
  getTaskManagementTasksExistAsync,
} from "state/feature/task-management/task-management.action";
import TaskInfoTabs from "./task-info-tabs.component";
import { getBrowserState, getCurrentPageTaskState } from "state/feature/task-management/task-management.slice";
import { useAppDispatch } from "state/store";

const TaskInfoContainer = () => {
  const dispatch = useAppDispatch();
  const { currentTab, pages } = useSelector(getBrowserState);
  const { episode } = useSelector(getCurrentPageTaskState(currentTab!.taskId));
  const [activeTabName, setActiveTabName] = useState<ActiveTabName>(TaskInfoTab.EPISODE);
  const page = pages.find((p) => currentTab && currentTab.taskId === p.taskId);

  const onClickTabName = (tabName: ActiveTabName) => {
    setActiveTabName(tabName);
  };

  useEffect(() => {
    if (currentTab && !episode) {
      const { intakeId, taskId } = currentTab;
      dispatch(getTaskManagementTasksExistAsync({ intakeId, taskId }));
      dispatch(getPatientAndEpisodeDetailsAysnc({ intakeId, taskId })).then((res: any) => {
        dispatch(calculateRisk({ intakeId }));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab, , episode, dispatch]);

  useEffect(() => {
    if (currentTab) {
      const { intakeId, taskId } = currentTab;
      if (
        page?.patientDetails?.lastLoginAt === null &&
        page?.episode?.patientAndEpisodeDetails.patientUserId &&
        taskId &&
        intakeId
      ) {
        dispatch(
          getPatientLastLoginAsync({
            userId: page.episode.patientAndEpisodeDetails.patientUserId ?? "",
            intakeId: intakeId,
            taskId: taskId,
          })
        );
      }
    }
  }, [currentTab, dispatch, page?.episode, page?.patientDetails]);

  return <TaskInfoTabs activeTabName={activeTabName} onClickTabName={onClickTabName} />;
};

export default TaskInfoContainer;
