import AlertIcon from "shared/assets/images/alert.svg";
import "./alert-section.styles.scss";

const AlertSection = ({ alerts }: { alerts: Array<string> }) => {
  return (
    <div className="alert-container">
      <div className="alert-icon-section">
        <img src={AlertIcon} />
      </div>
      <div className="alert-details-section">
        <div className="alert-title">Alert</div>
        <div className="alert-list-section">
          <ul className="alert-list">
            {alerts.map((alert: string) => (
              <li>{alert}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AlertSection;
