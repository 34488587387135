import Icon from "components/Icon/icon.component";
import { PatientStatusProps } from "./patient-status.props";
import "./patient-status.styles.scss";
import { useDispatch } from "react-redux";
import { setIsOpenPatientStatusModal } from "state/feature/common/common.slice";

const PatientStatus = (props: PatientStatusProps) => {
  const { status } = props;
  const dispatch = useDispatch();
  return (
    <div id="patient-status-container">
      <div className={`patient-status-container`}>
        <div className="patient-status-container-title">Status</div>
        <div className="patient-status-container-value">
          <div className="status-value">{status}</div>
          <div className="icon-container-edit" onClick={() => dispatch(setIsOpenPatientStatusModal(true))}>
            <Icon icon="edit-icon" size={32} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientStatus;
