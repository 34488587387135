import React from "react";
import { TextAreaProps } from "./text-area.props";
import "./text-area.styles.scss";
import { TEXT_AREA_LIMIT } from "shared/constant/commonConstants";

const TextArea = (props: TextAreaProps) => {
  const {
    leftLabel,
    rightLabel,
    inputText,
    handleChange,
    showError,
    errorMessage,
    required,
    rows,
    isDisabled,
    id,
    characterLimit,
    className,
    maxLength,
  } = props;
  const [isFocused, setIsFocused] = React.useState<boolean>(false);
  const handleOnFocus = () => {
    setIsFocused(true);
  };
  const handleOnBlur = () => {
    setIsFocused(false);
  };
  const limit = characterLimit ? characterLimit : TEXT_AREA_LIMIT;
  return (
    <div className={`textarea-input-group-section ${className ?? ""}`} id={id}>
      <div className="label-container">
        <label className="left-label">
          {leftLabel}
          {required && <span className="red-color">*</span>}
        </label>
        <label className="right-label">{rightLabel}</label>
      </div>
      <textarea
        className={`${showError ? "red-border" : isFocused ? "focused" : ""} $${isDisabled ? " disabled" : ""}`}
        name="story"
        rows={rows !== null ? rows : 8}
        onChange={(event) => {
          if (event.target.value.length <= limit) {
            event.preventDefault();
            handleChange(event.target.value);
          }
        }}
        value={inputText}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        disabled={isDisabled}
        maxLength={maxLength}
      />
      {showError && <div className="error-wrap">{errorMessage}</div>}
    </div>
  );
};

export default TextArea;
