import "./multiple-task-modal.styles.scss";
import Modal from "../modal.component";
import { MultipleTaskModalProps } from "./multiple-task-modal.props";

const MultipleTaskModal = (props: MultipleTaskModalProps) => {
  const { buttons, isOpen, header, subHeader } = props;

  return (
    <Modal isOpen={isOpen} className="center multiple-task-modal">
      <div className="multiple-task-container">
        <div className="header">{header}</div>
        <div className="content">{subHeader}</div>
        <div className="button-section">{buttons}</div>
      </div>
    </Modal>
  );
};

export default MultipleTaskModal;
