import Player from "components/Player/player.component";
import Modal from "../modal.component";
import "./audio-player-modal.styles.scss";
import { getCommonState } from "state/feature/common/common.slice";
import { useSelector } from "react-redux";
import Icon from "components/Icon/icon.component";

interface AudioPlayerModalProps {
  voiceMailUrl: string;
  name: string;
  onClose: () => void;
  date: string;
}

const AudioPlayerModal = (props: AudioPlayerModalProps) => {
  const { voiceMailUrl, name, onClose, date } = props;
  const { modal } = useSelector(getCommonState);
  return (
    <Modal
      id="audio-player-modal"
      className="center"
      modalHeader={
        <div className="heading">
          <div>
            <div className="name">{name}</div>
            <div className="date">{date}</div>
          </div>
          <div className="right-section" onClick={onClose}>
            <Icon icon="close" size={17} />
          </div>
        </div>
      }
      isOpen={modal.isOpenAudioModal}
    >
      <Player audioUrl={voiceMailUrl} />
    </Modal>
  );
};

export default AudioPlayerModal;
