import { HeaderColumnInfo } from "pages/episodes/types";
import { useEffect, useState } from "react";
import { getInitialEpisodePlanOfCareHeader } from "shared/constant/table";
import { resetPlanofCareList } from "state/feature/episodes/episodes.slice";
import PlanOfCarePresentation from "./plan-of-care.component";
import { useAppDispatch } from "state/store";
import { useParams } from "react-router";
import { getPlanOfCareListAsync } from "state/feature/episodes/episodes.action";
import { SortingOrderType } from "shared/enums/sorting-types.enums";
import { createCarePlanUtilizationPACLogs, deleteCarePlanAsync } from "state/feature/plan-of-care/plan-of-care.action";
import { useSelector } from "react-redux";
import { getPlanOfCareState, resetPlanOfCareState } from "state/feature/plan-of-care/plan-of-care.slice";
import {
  getCommonState,
  setIsOpenCareOfPlanDeleteConfirmationModal,
  setIsOpenPOCServiceModal,
} from "state/feature/common/common.slice";
import { getPatientFullName, isEmptyString } from "shared/methods/utilityFunctions";
import { CreateCarePlanPACLogsRequestPayload } from "components/Modal/poc-service-modal/poc-service-modal.props";
import moment from "moment";
import { getAuth } from "state/feature/auth/auth.slice";
import { DaysVisit } from "shared/enums/plan-of-care.enum";
import { DeleteLogText } from "components/Modal/poc-service-modal/poc-service-modal.constant";

const PlanOfCare = () => {
  const [headerColumns, setHeaderColumns] = useState<Array<HeaderColumnInfo>>(getInitialEpisodePlanOfCareHeader());
  const { intakeId } = useParams<{ intakeId: string }>();
  const appDispatch = useAppDispatch();
  const initialRequestPayload = { intakeId: intakeId ?? "", sortBy: "", sortOrder: "" };
  const [requestPayload, setRequestPayload] = useState<{ intakeId: string; sortBy: string; sortOrder: string }>(
    initialRequestPayload
  );
  const { user } = useSelector(getAuth);
  const { id, deleteCarePlanData } = useSelector(getPlanOfCareState);

  const handleColumnSorting = (selectedColumn: HeaderColumnInfo) => {
    let sort: string | SortingOrderType = "";
    const tempHeaders = headerColumns.map((header) => {
      if (header.key === selectedColumn.key) {
        if (
          selectedColumn.sortOrder === SortingOrderType.DESC ||
          selectedColumn.sortOrder === SortingOrderType.DEFAULT
        ) {
          sort = SortingOrderType.ASC;
          header.sortOrder = SortingOrderType.ASC;
        } else if (selectedColumn.sortOrder === SortingOrderType.ASC) {
          sort = SortingOrderType.DESC;
          header.sortOrder = SortingOrderType.DESC;
        }
      } else if (header?.sortOrder) {
        header.sortOrder = SortingOrderType.DEFAULT;
      }
      return header;
    });

    setRequestPayload((prev) => {
      return { ...prev, sortBy: selectedColumn.key, sortOrder: sort.toLowerCase() };
    });
    setHeaderColumns(tempHeaders);
  };

  const getCarePlanUtilizationPACLogsPayload = (): CreateCarePlanPACLogsRequestPayload | null => {
    if (deleteCarePlanData) {
      const {
        reasonForOtherReadmission,
        notes,
        actualAdmitDate,
        actualDischargeDate,
        provider,
        reasonForService,
        pacType,
        aptNo,
        readmitReason,
        daysType,
      } = deleteCarePlanData;

      const los = aptNo && daysType === DaysVisit.LOS ? aptNo : null;
      const visits = aptNo && daysType === DaysVisit.VISIT ? aptNo : null;
      const payload: CreateCarePlanPACLogsRequestPayload = {
        intakeId: intakeId,
        user: getPatientFullName({
          firstName: user?.firstName,
          lastName: user?.lastName,
        }),
        userId: user.navigatorId,
        createdDate: moment().utc().format(),
        notes: notes && !isEmptyString(notes) ? notes : null,
        los,
        visits,
        service: pacType ?? "",
        serviceReason: reasonForService && !isEmptyString(reasonForService) ? reasonForService : null,
        provider: provider && !isEmptyString(provider) ? provider : null,
        providerId: provider && !isEmptyString(provider) ? -1 : null,
        admissionDate: actualAdmitDate && actualAdmitDate !== "-" ? moment(actualAdmitDate).format("MM/DD/YYYY") : "",
        dischargeDate:
          actualDischargeDate && actualDischargeDate !== "-" ? moment(actualDischargeDate).format("MM/DD/YYYY") : null,
        otherReason:
          reasonForOtherReadmission && !isEmptyString(reasonForOtherReadmission) ? reasonForOtherReadmission : null,
        readmissionReason: readmitReason && !isEmptyString(readmitReason) ? readmitReason : null,
        taskId: id,
        planOfCareTaskStatus: DeleteLogText,
      };
      return payload;
    }
    return null;
  };

  const handleSubmitForDeleteConfirmation = () => {
    appDispatch(deleteCarePlanAsync(id)).then((response) => {
      if (response.payload.statusCode === 200) {
        const careUtilizationPacLogsPayload = getCarePlanUtilizationPACLogsPayload();
        if (careUtilizationPacLogsPayload) {
          appDispatch(createCarePlanUtilizationPACLogs(careUtilizationPacLogsPayload)).finally(() => {
            appDispatch(resetPlanOfCareState());
            appDispatch(setIsOpenPOCServiceModal(false));
            appDispatch(setIsOpenCareOfPlanDeleteConfirmationModal(false));
            appDispatch(getPlanOfCareListAsync(requestPayload));
          });
        } else {
          appDispatch(resetPlanOfCareState());
          appDispatch(setIsOpenPOCServiceModal(false));
          appDispatch(setIsOpenCareOfPlanDeleteConfirmationModal(false));
          appDispatch(getPlanOfCareListAsync(requestPayload));
        }
      }
    });
  };

  useEffect(() => {
    appDispatch(getPlanOfCareListAsync(requestPayload));
  }, [appDispatch, requestPayload]);

  useEffect(() => {
    return () => {
      appDispatch(resetPlanofCareList());
    };
  }, [appDispatch]);

  return (
    <PlanOfCarePresentation
      headerColumns={headerColumns}
      handleColumnSorting={handleColumnSorting}
      handleSubmitForDeleteConfirmation={handleSubmitForDeleteConfirmation}
    />
  );
};

export default PlanOfCare;
