import "./notification-badge-web.styles.scss";
import { getNotificationState, setIsNotificationDrawerOpen } from "state/feature/notification/notification.slice";
import { useDispatch, useSelector } from "react-redux";
import { CustomToolTip } from "components/CustomTooltip/custom-tooltip.component";
import Icon from "components/Icon/icon.component";

function NotificationBadgeWeb() {
  const dispatch = useDispatch();
  const { unreadNotificationCount, isNotificationDrawerOpen } = useSelector(getNotificationState);

  return (
    <div
      className="notification-icon-container-web"
      data-tip
      data-for={"Notification"}
      onClick={() => dispatch(setIsNotificationDrawerOpen(!isNotificationDrawerOpen))}
    >
      {unreadNotificationCount > 0 && (
        <div className="notification-count-badge">
          <p>{unreadNotificationCount > 99 ? "99+" : unreadNotificationCount}</p>
        </div>
      )}
      <Icon
        icon={isNotificationDrawerOpen ? "active-notification-bell" : "notification-bell"}
        className="notification-icon"
        size={20}
      />
      {!isNotificationDrawerOpen && <CustomToolTip text={"Notification"} id="Notification" place="left" />}
    </div>
  );
}

export default NotificationBadgeWeb;
