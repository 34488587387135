import React from "react";
import { RadioInputProps, RadioInputType } from "./radio-input-group.props";
import "./radio-input-group.styles.scss";

const RadioInputGroup = (props: RadioInputProps) => {
  const {
    labelText,
    radioList,
    className,
    handleInputChange,
    required,
    disabled,
    showError,
    errorMessage,
    shouldDisplayErroronDisabled,
  } = props;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let text = "";
    radioList.forEach((singleItem) => {
      if (singleItem.id == event.target.value) {
        text = singleItem.text;
      }
    });
    handleInputChange(event.target.value, text);
  };

  const isDisplayErrorMessage = (shouldDisplayErroronDisabled && showError) || (!disabled && showError);
  return (
    <div className="radio-input-group-section">
      <div className="radio-group-label">
        {labelText}
        {required && <span className="red-color-asterisk">*</span>}
      </div>
      <div className="input-section" onChange={handleChange}>
        {radioList.map((item: RadioInputType) => (
          <div className="single-radio" key={item.id}>
            <label className={`container ${disabled ? "disable-radio-button" : ""}`}>
              <input
                type="radio"
                checked={item.checked}
                name={labelText}
                value={item.id}
                onChange={() => {}}
                className={`${className ? className : ""} ${disabled ? "disable-radio-button" : ""}`}
                disabled={disabled}
              />
              {item.text}
            </label>
          </div>
        ))}
      </div>
      {isDisplayErrorMessage && <div className="error-wrap">{errorMessage}</div>}
    </div>
  );
};

export default RadioInputGroup;
