import { SelectionValue } from "pages/task-management/risk-assessment/common/risk-assessment.types";

type PillsProps = {
  options?: SelectionValue[];
  children: (option: SelectionValue, index: number) => React.ReactNode;
};

const Pills = (props: PillsProps) => {
  const { options, children } = props;

  if (options && options.length > 0) {
    return (
      <>
        {options.map((item, index) => {
          return <div key={`${item.sectionId}_${item.order}`}>{children(item, index)}</div>;
        })}
      </>
    );
  }

  return null;
};

export default Pills;
