import { createSlice } from "@reduxjs/toolkit";
import { getAdmitTaskData, getAllFacilities, postAdmitTaskData, providerDetails } from "./admit-task.action";
import { IAdmitTaskStateType } from "state/types/admit-task.type";
import { toast } from "react-toastify";
import { TOAST_MESSAGES } from "pages/login/common/constants";
import { getDateInMMDDYYYYFormat } from "shared/methods/utilityFunctions";
import moment from "moment";

const initialState: IAdmitTaskStateType = {
  isLoading: false,
  isButtonLoading: false,
  data: null,
  facilities: [],
  isDirty: false,
  selectedAdmitTaskOptions: {
    notes: "",
    actualAdmissionDate: null,
    actualDischargeDate: null,
    orderStatus: { key: "", name: "" },
    provider: { key: "", value: "" },
    providerType: "",
  },
  isValueChanged: false,
};

const admitTaskSlice = createSlice({
  name: "admit-task",
  initialState,
  reducers: {
    resetAdmitTaskState: () => initialState,
    resetAdmitTaskWhenChangingTab(state) {
      state.isButtonLoading = false;
      state.isDirty = false;
      state.isLoading = false;
      state.isValueChanged = false;
      state.selectedAdmitTaskOptions = { ...initialState.selectedAdmitTaskOptions };
    },
    setIsDirty(state, action) {
      state.isDirty = action.payload;
    },
    setNotes(state, action) {
      state.selectedAdmitTaskOptions = {
        ...state.selectedAdmitTaskOptions,
        notes: action.payload,
      };
      state.isValueChanged = true;
    },
    setActualAdmissionDate(state, action) {
      state.selectedAdmitTaskOptions = {
        ...state.selectedAdmitTaskOptions,
        actualAdmissionDate: action.payload,
      };
      state.isValueChanged = true;
    },
    setActualDischargeDate(state, action) {
      state.selectedAdmitTaskOptions = {
        ...state.selectedAdmitTaskOptions,
        actualDischargeDate: action.payload,
      };
      state.isValueChanged = true;
    },
    setProvider(state, action) {
      state.selectedAdmitTaskOptions = {
        ...state.selectedAdmitTaskOptions,
        provider: action.payload,
      };
      state.isValueChanged = true;
    },
    setOrderStatus(state, action) {
      state.selectedAdmitTaskOptions = {
        ...state.selectedAdmitTaskOptions,
        orderStatus: action.payload,
      };
      state.isValueChanged = true;
    },
    setAdmitTaskStateFromMemoize(state, action) {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    return (
      builder.addCase(getAdmitTaskData.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(getAdmitTaskData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        const { data } = action.payload;
        state.selectedAdmitTaskOptions = {
          notes: data?.tocTaskDetails?.notes ?? "",
          actualAdmissionDate: data?.tocTaskDetails?.actualAdmissionDate
            ? getDateInMMDDYYYYFormat(moment(data?.tocTaskDetails?.actualAdmissionDate, "YYYY-MM-DDThh:mm:ss"))
            : "",
          actualDischargeDate: data.tocTaskDetails?.actualDischargeDate
            ? getDateInMMDDYYYYFormat(moment(data.tocTaskDetails?.actualDischargeDate, "YYYY-MM-DDThh:mm:ss"))
            : "",
          orderStatus: {
            key: data?.tocTaskDetails?.orderStatus ? data.tocTaskDetails.orderStatus?.toLowerCase() : "",
            name: data?.tocTaskDetails?.orderStatus ?? "",
          },
          provider: {
            key: data?.tocTaskDetails?.providerDetails?.id ?? -1,
            value: data?.tocTaskDetails?.providerDetails?.provider ?? "",
          },
          providerType: "",
        };
      }),
      builder.addCase(postAdmitTaskData.pending, (state) => {
        state.isButtonLoading = true;
      }),
      builder.addCase(postAdmitTaskData.fulfilled, (state) => {
        state.isButtonLoading = false;
      }),
      builder.addCase(postAdmitTaskData.rejected, (state, action) => {
        state.isButtonLoading = false;
        toast.error(action.payload ?? TOAST_MESSAGES.ERROR, {
          toastId: "admit-task-error",
          containerId: "main",
        });
      }),
      builder.addCase(getAllFacilities.fulfilled, (state, action) => {
        state.facilities = action.payload;
      }),
      builder.addCase(providerDetails.fulfilled, (state, action) => {
        if (action.payload === null) {
          toast.error("Provider type does not exist", {
            toastId: "provider-error",
            containerId: "main",
          });
          return;
        }
        state.selectedAdmitTaskOptions.providerType = action.payload.data.providerType;
      })
    );
  },
});

export default admitTaskSlice;
export const getAdmitTaskState = (state: any): IAdmitTaskStateType => state.admitTask;
export const {
  resetAdmitTaskState,
  setIsDirty,
  setNotes,
  setActualAdmissionDate,
  setActualDischargeDate,
  setProvider,
  setOrderStatus,
  setAdmitTaskStateFromMemoize,
  resetAdmitTaskWhenChangingTab,
} = admitTaskSlice.actions;
