import { TASKS_LIMIT } from "pages/task-management/constant";
import { PatientFormTabs } from "shared/enums/episodes.enum";
import { IProcedureDetailType } from "state/types/episodes-slice.type";

export const PAGE_LIMIT = 30;
export const TOAST_MESSAGE_NO_PHONE_NUMBER_ASSIGNED = "No phone number is assigned";
export const UNSAVED_MESSAGE = "You have unsaved changes. Do you want to discard them?";
export const CALL_NOT_READ = "Unable to mark the call as addressed";
export const CALL_READ = "Successfuly marked call as addressed";
export const BACKUP_NAVIGATOR_LIMIT = 10;
export const NO_PHONE_NUMBER_FOR_PRACTICE = "No phone number entered for the practice";
export const METERS_PER_MILE = 1609;
export const FORM_SUBMITTED_SUCCESSFULLY = "Form successfully submitted";
export const EPISODE_EDITED_SUCCESSFULLY = "Episode edited successfully";
export const PROVIDER_EDITED_SUCCESSFULLY = "Provider edited successfully";

export const SOMETHING_WENT_WRONG = "Something went wrong";
export const PLEASE_FILL_ALL_REQUIRED_QUESTIONS = "Please fill all the required questions";
export const ZIP_CODE_LENGTH = 5;

export const prefferedPhoneType = {
  MOBILE: { id: "24", text: "Mobile" },
  HOME: { id: "22", text: "Home" },
};

export const preferedNumberRadioList = [
  {
    text: prefferedPhoneType.MOBILE.text,
    id: prefferedPhoneType.MOBILE.id,
    checked: true,
  },
  {
    text: prefferedPhoneType.HOME.text,
    id: prefferedPhoneType.HOME.id,
    checked: false,
  },
];

export const genderTypeDetails = {
  MALE: { text: "Male", id: 1052 },
  FEMALE: { text: "Female", id: 1051 },
  NOT_KNOWN: { text: "Not known", id: 1050 },
};

export const genderDropdownItems = [
  { key: 1052, name: genderTypeDetails.MALE.text },
  { key: 1051, name: genderTypeDetails.FEMALE.text },
  { key: 1050, name: genderTypeDetails.NOT_KNOWN.text },
];

export const countryList = [
  { key: "AL", name: "Alabama" },
  { key: "AK", name: "Alaska" },
  { key: "AS", name: "American Samoa" },
  { key: "AZ", name: "Arizona" },
  { key: "AR", name: "Arkansas" },
  { key: "CA", name: "California" },
  { key: "CO", name: "Colorado" },
  { key: "CT", name: "Connecticut" },
  { key: "DE", name: "Delaware" },
  { key: "DC", name: "District Of Columbia" },
  { key: "FM", name: "Federated States Of Micronesia" },
  { key: "FL", name: "Florida" },
  { key: "GA", name: "Georgia" },
  { key: "GU", name: "Guam" },
  { key: "HI", name: "Hawaii" },
  { key: "ID", name: "Idaho" },
  { key: "IL", name: "Illinois" },
  { key: "IN", name: "Indiana" },
  { key: "IA", name: "Iowa" },
  { key: "KS", name: "Kansas" },
  { key: "KY", name: "Kentucky" },
  { key: "LA", name: "Louisiana" },
  { key: "ME", name: "Maine" },
  { key: "MH", name: "Marshall Islands" },
  { key: "MD", name: "Maryland" },
  { key: "MA", name: "Massachusetts" },
  { key: "MI", name: "Michigan" },
  { key: "MN", name: "Minnesota" },
  { key: "MS", name: "Mississippi" },
  { key: "MO", name: "Missouri" },
  { key: "MT", name: "Montana" },
  { key: "NE", name: "Nebraska" },
  { key: "NV", name: "Nevada" },
  { key: "NH", name: "New Hampshire" },
  { key: "NJ", name: "New Jersey" },
  { key: "NM", name: "New Mexico" },
  { key: "NY", name: "New York" },
  { key: "NC", name: "North Carolina" },
  { key: "ND", name: "North Dakota" },
  { key: "MP", name: "Northern Mariana Islands" },
  { key: "OH", name: "Ohio" },
  { key: "OK", name: "Oklahoma" },
  { key: "OR", name: "Oregon" },
  { key: "PW", name: "Palau" },
  { key: "PA", name: "Pennsylvania" },
  { key: "PR", name: "Puerto Rico" },
  { key: "RI", name: "Rhode Island" },
  { key: "SC", name: "South Carolina" },
  { key: "SD", name: "South Dakota" },
  { key: "TN", name: "Tennessee" },
  { key: "TX", name: "Texas" },
  { key: "UT", name: "Utah" },
  { key: "VT", name: "Vermont" },
  { key: "VI", name: "Virgin Islands" },
  { key: "VA", name: "Virginia" },
  { key: "WA", name: "Washington" },
  { key: "WV", name: "West Virginia" },
  { key: "WI", name: "Wisconsin" },
  { key: "WY", name: "Wyoming" },
];
export const UNSAVED_CHANGES = "Unsaved Changes";
export const UNSAVED_CHANGES_SUB_HEADING = "You have unsaved changes. Are you sure you want to cancel?";
export const UNSAVED_TASK_SUB_HEADING =
  "You have open tasks. If you continue, you will lose any data entered for those tasks. Do you want to continue?";
export const TAB_LIMIT_EXCEEDED_HEADING = "Tab Limit Exceeded";
export const TAB_LIMIT_EXCEEDED_SUB_HEADING = `You have already opened the maximum of ${TASKS_LIMIT} tabs. To open another tab, you must first close an open tab.`;
export const NPI_LENGTH = 10;
export const inputFieldNames = {
  PROCEDURE_DESCRIPTION: "procedureDescription",
  SURGEON_NAME: "surgeonName",
  SURGEON_PRACTICE: "surgeonPractice",
  PROGRAM: "program",
  EPISODE: "episode",
  CPT: "cpt",
  EPISODE_SETTINGS: "episodeSettings",
  EPISODE_TYPE: "episodeType",
  EPISODE_CARE: "episodeCare",
  SURGERY_SITE_SIDE: "surgerySiteSide",
  ADMIT_DATE: "admitDate",
  SURGERY_DATE: "surgeryDate",
  ACUTE_CARE_FACILITY: "acuteCareFacility",
  CNN: "cnn",
  PATIENT_EPISODE_STATUE: "patientEpisodeStatus",
  NPI: "npi",
};

export const CPT_LENGTH = 5;
export const CCN_LENGTH = 6;

export const episodeSettingType = {
  INPATIENT: { text: "Inpatient", id: "5" },
  OUTPATIENT: { text: "Outpatient", id: "6" },
};

export const episodePlanType = {
  PLANNED: { text: "Planned", id: "6" },
  UNPLANNED: { text: "Unplanned", id: "7" },
};

export const episodeCareType = {
  MEDICAL: { text: "Medical", id: "8" },
  SURGICAL: { text: "Surgical", id: "9" },
};

export const episodeSettingRadioList = [
  {
    text: episodeSettingType.INPATIENT.text,
    id: episodeSettingType.INPATIENT.id,
    checked: false,
  },
  {
    text: episodeSettingType.OUTPATIENT.text,
    id: episodeSettingType.OUTPATIENT.id,
    checked: false,
  },
];

export const episodeTypeRadioList = [
  {
    text: episodePlanType.PLANNED.text,
    id: episodePlanType.PLANNED.id,
    checked: false,
  },
  {
    text: episodePlanType.UNPLANNED.text,
    id: episodePlanType.UNPLANNED.id,
    checked: false,
  },
];

export const episodeCareRadioList = [
  {
    text: episodeCareType.MEDICAL.text,
    id: episodeCareType.MEDICAL.id,
    checked: false,
  },
  {
    text: episodeCareType.SURGICAL.text,
    id: episodeCareType.SURGICAL.id,
    checked: false,
  },
];

export const tablist = [
  {
    name: "Surgeon and Episode Details",
    id: PatientFormTabs.SURGEON_AND_EPISODE_DETAILS,
  },
  {
    name: "Patient Personal Details",
    id: PatientFormTabs.PATIENT_PERSONAL_DETAILS,
  },
  {
    name: "Patient Address",
    id: PatientFormTabs.PATIENT_ADDRESS,
  },
];

export const surgerySiteSideData = [
  { key: "1", name: "Cervical - N/A" },
  { key: "2", name: "Coccys - N/A" },
  { key: "3", name: "Lumbar - N/A" },
  { key: "4", name: "Sacral - N/A" },
  { key: "5", name: "Sacroiliac - Left" },
  { key: "6", name: "Sacroiliac - Right" },
  { key: "7", name: "Thoracic - N/A" },
  { key: "8", name: "N/A - N/A" },
  { key: "9", name: "A/P lumbar - N/A" },
  { key: "10", name: "A/P cervical - N/A" },
];

export const keys = {
  SURGEON_AND_EPISODE_DETAILS: "surgeonAndEpisodeDetails",
  PATIENT_PERSONAL_DETAILS: "patientPersonalDetails",
  PATIENT_ADDRESS: "patientAddress",
};

export const Warnings = [
  {
    Message:
      "The surgeon or admitting physician is not part of the contracted network for Episode (Chronic obstructive pulmonary disease, bronchitis/asthma).",
    RowNumber: -1,
    ColumnName: null,
  },
  {
    Message:
      "The surgeon or admitting physician is not part of the contracted network for Episode (Cardiac defibrillator).",
    RowNumber: -1,
    ColumnName: null,
  },
  {
    Message:
      "The surgeon or admitting physician is not part of the contracted network for Episode (Major joint replacement of the lower extremity).",
    RowNumber: -1,
    ColumnName: null,
  },
];

export const initialProcedureDetailsSubmitRequestPayload = {
  surgeonID: null,
  practiceID: null,
  programID: 313,
  episodeID: "1809",
  procedureDescription: "awfdas",
  cpt: "",
  surgerySiteSide: 6,
  facilityID: 8877,
  episodeTypeID: 5,
  medSurg: 9,
  plannedUnplanned: 7,
  admitDate: "01/08/2024",
  surgeryDate: "",
  dischargeDate: "",
  ccn: "",
  patientCareManager: null,
  patientEpisodeStatus: null,
  acuteDischargePhysician: null,
  fromNavigatorApp: true,
  address1: "1",
  city: "1",
  genderID: "1",
  preferredPhone: "1",
  state: "1",
  zip: "1",
  intakeStatusId: "2",
  patientId: "42792",
  intakeId: "45014",
  admittingPhysicianId: 9129,
  primaryCareManagerId: "fe314686-4c26-4e96-88f0-7a3b6d73b1c8",
};

export const SURGEON_EPISODE_DETAILS_FORM: IProcedureDetailType = {
  surgeonName: {
    value: "",
    isValid: true,
    isRequired: true,
    isDisabled: false,
    id: "surgeon_name",
  },
  surgeonId: {
    value: "",
    isValid: true,
    isRequired: true,
    isDisabled: false,
    id: "",
  },
  npi: {
    value: "",
    isValid: true,
    isRequired: false,
    isDisabled: false,
    id: "npi",
  },
  surgeonPractice: {
    value: { id: "", name: "" },
    isValid: true,
    isRequired: true,
    isDisabled: false,
    id: "surgeon_practice",
  },
  program: {
    value: { id: "", name: "" },
    isValid: true,
    isRequired: true,
    isDisabled: false,
    id: "program",
  },
  episode: {
    value: { id: "", name: "" },
    isValid: true,
    isRequired: true,
    isDisabled: false,
    id: "episode",
  },
  cpt: {
    value: "",
    isValid: true,
    isRequired: false,
    isDisabled: false,
    id: "cpt",
  },
  procedureDescription: {
    value: "",
    isValid: true,
    isRequired: true,
    isDisabled: true,
    id: "procedure_description",
  },
  episodeSettings: {
    value: { name: "", id: "" },
    isValid: true,
    isRequired: true,
    isDisabled: false,
    id: "episode_setting",
  },
  episodeType: {
    value: { name: "", id: "" },
    isValid: true,
    isRequired: true,
    isDisabled: false,
    id: "episode_type",
  },
  episodeCare: {
    value: { name: "", id: "" },
    isValid: true,
    isRequired: true,
    isDisabled: false,
    id: "episode_care",
  },
  surgerySiteSide: {
    value: { name: "", id: "" },
    isValid: true,
    isRequired: true,
    isDisabled: false,
    id: "surgery_site_side",
  },
  acuteCareFacility: {
    value: { name: "", id: "" },
    isValid: true,
    isRequired: true,
    isDisabled: false,
    id: "acute_care_facility",
  },
  ccn: {
    value: "",
    isValid: true,
    isRequired: false,
    isDisabled: false,
    id: "ccn",
  },
  admitDate: {
    value: null,
    isValid: true,
    isRequired: true,
    isDisabled: false,
    id: "admit_date",
  },
  surgeryDate: {
    value: null,
    isValid: true,
    isRequired: true,
    isDisabled: false,
    id: "surgery_date",
  },
  dischargeDate: {
    value: null,
    isValid: true,
    isRequired: true,
    isDisabled: false,
    id: "discharge_date",
  },
  patientEpisodeStatus: {
    value: "New",
    isValid: true,
    isRequired: true,
    isDisabled: false,
    id: "patient_episode_status",
  },
  acuteDischargePhysician: {
    value: { key: "", name: "" },
    isValid: true,
    isRequired: false,
    isDisabled: false,
    id: "acute_discharge_physician",
  },
  patientCareManager: {
    value: "",
    isValid: true,
    isRequired: false,
    isDisabled: false,
    id: "patient_care_manager",
  },
  intakeStatus: {
    value: "",
    isValid: true,
    isRequired: false,
    isDisabled: false,
    id: "intake_status",
  },
  referralId: { value: "", isValid: true, isRequired: false, isDisabled: false, id: "referralId" },
  referringProvider: {
    value: { key: "", name: "" },
    isValid: true,
    isRequired: false,
    isDisabled: false,
    id: "referring_provider",
  },
  transitionNavigator: {
    value: { key: "", name: "" },
    isValid: true,
    isRequired: false,
    isDisabled: false,
    id: "transition_navigator",
  },
};

export type RadioButtonListType = {
  text: string;
  id: string;
  checked: boolean;
};

export const TEXT_AREA_LIMIT = 500;

export const ADMIT_TASK_TYPE_NAME = {
  patient: "Confirm Patient Admission with Patient",
  provider: "Confirm Patient Admission with Provider",
};

export const STRICKED_ROW = "stricked-row";
