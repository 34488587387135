import { createAsyncThunk } from "@reduxjs/toolkit";
import { CreateCarePlanPACLogsRequestPayload } from "components/Modal/poc-service-modal/poc-service-modal.props";
import { getConfig } from "config/config";
import { axiosInstance } from "shared/axios-instance";
import { IFacility } from "state/types/toc-management-slice.type";

export const postPlanOfCareDataAsync = createAsyncThunk(
  "planOfCare/postPlanOfCareDataAsync",
  async (requestPayload: any, { rejectWithValue }) => {
    try {
      const result = await axiosInstance.post(
        `${getConfig().taskManagementBase}/api/v1/PlanOfCare/careplanPAC`,
        requestPayload
      );
      return result.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getServiceTypes = createAsyncThunk("planOfCare/getServiceTypes", async () => {
  const result = await axiosInstance.get(`${getConfig().taskManagementBase}/api/v1/PlanOfCare/serviceTypes`);
  return result.data.data;
});

export const getReadmissionReasons = createAsyncThunk("planOfCare/getReadmissionReasons", async () => {
  const result = await axiosInstance.get(`${getConfig().taskManagementBase}/api/v1/PlanOfCare/readmissionReasons`);
  return result.data.data;
});

export const getFacilitiesForPlanOfCare = createAsyncThunk(
  "toc-management/getFacilitiesForPlanOfCare",
  async (requestPayload: any) => {
    const response = await axiosInstance.get(
      `${getConfig().efusionBase}/api/v2/ExtProvider/getallByIntake/${requestPayload.intakeId}/${
        requestPayload.activeInactive
      }/true/false/false/true`,
      { params: {} }
    );

    return response.data;
  }
);

export const getReasonForService = createAsyncThunk("planOfCare/getReasonForService", async () => {
  const result = await axiosInstance.get(`${getConfig().taskManagementBase}/api/v1/PlanOfCare/reasonForService`);
  return result.data.data;
});

export const putPlanOfCareDataAsync = createAsyncThunk(
  "planOfCare/putPlanOfCareDataAsync",
  async (requestPayload: any, { rejectWithValue }) => {
    try {
      const result = await axiosInstance.put(
        `${getConfig().taskManagementBase}/api/v1/PlanOfCare/careplanPAC`,
        requestPayload
      );
      return result.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getCarePlanDataAsync = createAsyncThunk("planOfCare/getCarePlanDataAsync", async (requestPayload: any) => {
  try {
    const result = await axiosInstance.get(`${getConfig().taskManagementBase}/api/v1/PlanOfCare/${requestPayload.id}`);
    return result.data.data;
  } catch (err) {
    return {};
  }
});

export const deleteCarePlanAsync = createAsyncThunk("planOfCare/deleteCarePlanAsync", async (id: string) => {
  try {
    const result = await axiosInstance.delete(`${getConfig().taskManagementBase}/api/v1/PlanOfCare/${id}`);
    return result.data;
  } catch (err) {
    return {};
  }
});

export const createCarePlanUtilizationPACLogs = createAsyncThunk(
  "planOfCare/createCarePlanUtilizationPACLogs",
  async (requestPayload: CreateCarePlanPACLogsRequestPayload) => {
    try {
      const response = await axiosInstance.post(
        `${getConfig().taskManagementBase}/api/v1/ActivityLog/CreateCarePlanPACLogs`,
        requestPayload
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);
