import { getAuth } from "state/feature/auth/auth.slice";
import SessionTimerPresentation from "./session-timer.component";
import { useEffect, useState } from "react";
import { duration } from "moment";
import "./session-timer.styles.scss";
import { useSelector } from "react-redux";
import { isEmptyString } from "shared/methods/utilityFunctions";
import { useIdleTimerContext, workerTimers } from "react-idle-timer";
import { TIMEOUT } from "components/IdleTimerContainer/idle-timer.constant";

const SessionTimer = () => {
  const { auth, user } = useSelector(getAuth);
  const { getRemainingTime } = useIdleTimerContext();
  const [timeRemaining, setRemaining] = useState(TIMEOUT);
  const [idleTimeRemaining, setIdleTimeRemaining] = useState("");

  useEffect(() => {
    const timerId = workerTimers.setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      workerTimers.clearInterval(timerId);
    };
  }, [getRemainingTime]);

  useEffect(() => {
    const tempTime = duration(timeRemaining, "seconds");
    const seconds = tempTime.seconds().toString();
    const mins = tempTime.minutes().toString();
    const remainingMins = mins.length === 1 ? `0${mins}` : tempTime.minutes();
    const remmainingSeconds = seconds.length === 1 ? `0${seconds}` : tempTime.seconds();
    const timeInMins = `${remainingMins}:${remmainingSeconds}`;
    setIdleTimeRemaining(timeInMins);
  }, [timeRemaining]);

  return auth && auth.isAuthenticated && user && !isEmptyString(user.id) && user.roles && user.roles.length > 0 ? (
    <SessionTimerPresentation timer={idleTimeRemaining} />
  ) : null;
};

export default SessionTimer;
