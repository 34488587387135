import { createAsyncThunk } from "@reduxjs/toolkit";
import { getConfig } from "config/config";
import { axiosInstance } from "shared/axios-instance";

export const generateTwilioTokenAsync = createAsyncThunk(
  "messaging/generateTwilioTokenAsync",
  async (requestPayload: { email: string }) => {
    const response = await axiosInstance.get(`${getConfig().messagingBase}/api/v1/Messaging/GetAccessToken`, {
      params: { emailAddress: requestPayload.email },
    });
    return response.data.accessToken;
  }
);

export const getIncomingCallDetails = createAsyncThunk(
  "call/getIncomingCallDetails",
  async (requestPayload: { fromNumber: string }) => {
    const response = await axiosInstance.post(`${getConfig().navigatorBase}/api/v1/inbound_calls/participant-details`, {
      fromNumber: requestPayload.fromNumber,
    });
    return response.data;
  }
);

export const updateIncomingCallStatusAsync = createAsyncThunk(
  "call/updateIncomingCallStatusAsync",
  async (requestPayload: {
    callSid: string;
    status: string;
    currentUserProfile: any;
    isAutoRejected?: boolean;
    rejectedDueToError?: boolean;
    isCallCancelled?: boolean;
    isRejected?: boolean;
  }) => {
    const response = await axiosInstance.post(`${getConfig().telephonyBase}/api/v1/logs/update-inbound-call`, {
      callSid: requestPayload.callSid,
      callStatus: requestPayload.status,
      effectiveRecipientId: requestPayload.currentUserProfile.id,
      effectiveRecipientCareManagerId: requestPayload.currentUserProfile.careManagerId,
      effectiveRecipientFirstName: requestPayload.currentUserProfile.firstName,
      effectiveRecipientLastName: requestPayload.currentUserProfile.lastName,
      effectiveRecipientPhoneNumber: requestPayload.currentUserProfile.forwardingPhoneNumber,
      isEffectiveRecipientOnCall: false,
      origin: "web",
      isisAutoRejected: requestPayload.isAutoRejected,
      rejectedDueToError: requestPayload.rejectedDueToError,
      isCallCancelled: requestPayload.isCallCancelled,
      isRejected: requestPayload.isRejected,
    });
    return response.data;
  }
);

export const synchronizeCallsAsync = createAsyncThunk(
  "call/synchronizeCallsAsync",
  async (requestPayload: { ids: Array<string>; callSid: string | null }) => {
    const response = await axiosInstance.post(`${getConfig().telephonyBase}/api/v1/logs/synchronize-calls`, {
      navigatorIds: requestPayload.ids,
      callSid: requestPayload.callSid,
    });
    return response.data;
  }
);
