import "./user-management.styles.scss";
import Button from "components/Button/button.component";
import Checkbox from "components/Checkbox/checkbox.component";
import Header from "components/Header/header.component";
import Icon from "components/Icon/icon.component";
import SearchBox from "components/SearchBox/search-box.component";
import SidePopup from "components/SidePopup/side-popup.component";
import { useCallback, useEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { batch, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import { checkIfFeatureEnabledAsync } from "state/feature/common/common.action";
import { getCommonState, setIsFilterModal } from "state/feature/common/common.slice";
import { getUsersAsync, getUsersTypeAsync } from "state/feature/user-management/user-management.action";
import {
  getUserManagementState,
  resetUserManagementState,
  resetUsers,
} from "state/feature/user-management/user-management.slice";
import { useAppDispatch } from "state/store";
import {
  AppliedFilterType,
  FilterParams,
  HeaderColumnInfo,
  IGetUsersAsyncRequestPayload,
} from "./common/user-management.types";
import { AccountType, SortingOrderType } from "./common/user-management.enum";
import TableLoader from "components/TableLoader/table-loader.component";
import Sort from "components/Sort/sort.component";
import Loading from "shared/assets/images/loader.gif";
import { debounce } from "lodash";
import FilterPill from "components/FilterPill/filter-pill.component";
import { ACCOUNT_TYPE, INCLUDE_INACTIVE_STATUS, LS_USERMANAGEMENT_FILTER } from "./common/user-management.constants";
import Status from "components/status/status.component";

const UserManagement = () => {
  const headerColumns = [
    {
      key: "name",
      name: "Name",
      sortOrder: SortingOrderType.DEFAULT,
    },
    {
      key: "account-type",
      name: ACCOUNT_TYPE,
      sortOrder: SortingOrderType.DEFAULT,
    },
    {
      key: "email",
      name: "Email",
      sortOrder: SortingOrderType.DEFAULT,
    },
    {
      key: "status",
      name: "Status",
      sortOrder: SortingOrderType.DEFAULT,
    },
    {
      key: "createddate",
      name: "Creation Date Time",
      sortOrder: SortingOrderType.DESC,
    },
  ];

  const getFilterSearchFromLocalStorage = (): {
    searchText: string | null;
    filter: Array<AppliedFilterType> | null;
  } => {
    let result = { searchText: null, filter: null };
    const selectedFilterSearch = localStorage.getItem(LS_USERMANAGEMENT_FILTER);
    if (selectedFilterSearch) {
      result = JSON.parse(selectedFilterSearch);
    }
    return result;
  };

  const getInitialAppliedFilter = () => {
    const { filter } = getFilterSearchFromLocalStorage();
    return filter ?? [];
  };

  const getInitialRequestPayload = () => {
    const initialRequestPayload = {
      pageSize: 20,
      pageNumber: 1,
      searchText: "",
      sortColumn: "createddate",
      sortOrder: SortingOrderType.DESC,
      includeInActive: false,
      userType: [],
    };
    const { searchText, filter } = getFilterSearchFromLocalStorage();
    let userType = [];
    let includeInActive = false;

    if (filter && filter.length > 0) {
      const accountType = filter.find((f) => f.title === ACCOUNT_TYPE);
      userType = accountType?.id?.length ? accountType.id.map((id) => id.toString()) : [];

      includeInActive = !!filter.find((inactive) => inactive.title === INCLUDE_INACTIVE_STATUS.status);
    }

    return {
      ...initialRequestPayload,
      includeInActive,
      userType,
      searchText: searchText ?? "",
    };
  };

  const initialRequestPayload = getInitialRequestPayload();
  const [requestPayload, setRequestPayload] = useState<IGetUsersAsyncRequestPayload>(initialRequestPayload);
  const history = useHistory();
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const { modal: modalState, featureFlags, isLoading: isFeatureLoading } = useSelector(getCommonState);
  const { users, isLoading, usertypes } = useSelector(getUserManagementState);
  const [isSearching, setIsSearching] = useState<boolean>(requestPayload.searchText.length > 0);
  const [isAddUserDropdownOpen, setIsAddUserDropdownOpen] = useState<boolean>(false);
  const [headerColumnsInfo, setHeaderColumns] = useState<HeaderColumnInfo[]>(headerColumns);
  const [currentUserTypeFilter, setCurrentUserTypeFilter] = useState<any[]>(
    requestPayload.userType.map((userType) => Number(userType))
  );
  const [includeInactive, setIncludeInctive] = useState<boolean>(requestPayload.includeInActive);
  const [searchText, setSearchText] = useState(initialRequestPayload.searchText);
  const [appliedFilters, setAppliedFilters] = useState<Array<AppliedFilterType>>(getInitialAppliedFilter());

  const resetRequestPayload = () => {
    setRequestPayload({
      pageSize: 20,
      pageNumber: 1,
      searchText: "",
      sortColumn: "createddate",
      sortOrder: SortingOrderType.DESC,
      includeInActive: false,
      userType: [],
    });
  };

  const setUsermanagementFilterInLocalStorage = (usermanagementFilterSearch: { searchText: string; filter: any }) => {
    localStorage.setItem(LS_USERMANAGEMENT_FILTER, JSON.stringify(usermanagementFilterSearch));
  };

  const incrementPage = () => {
    setRequestPayload((prev) => {
      return {
        ...prev,
        pageNumber: prev.pageNumber + 1,
      };
    });
  };

  const changeSort = (key: string, order: SortingOrderType) => {
    setRequestPayload((prev) => {
      return {
        ...prev,
        pageNumber: 1,
        sortColumn: key,
        sortOrder: order,
      };
    });
  };

  const changeFilters = (params?: FilterParams) => {
    setRequestPayload({
      searchText: requestPayload.searchText,
      pageSize: requestPayload.pageSize,
      sortColumn: requestPayload.sortColumn,
      sortOrder: requestPayload.sortOrder,
      pageNumber: 1,
      userType: currentUserTypeFilter,
      includeInActive: includeInactive,
      ...params,
    });
  };

  const changePageNumber = (page: number) => {
    setRequestPayload((prev) => {
      return {
        ...prev,
        pageNumber: page,
      };
    });
  };

  const changeSearchText = useCallback(
    (text: string) => {
      appDispatch(resetUsers());
      setRequestPayload((prev) => {
        return {
          ...prev,
          pageNumber: 1,
          searchText: text.trim(),
        };
      });
    },
    [appDispatch]
  );

  const handleColumnSorting = (selectedColumn: HeaderColumnInfo) => {
    const index = headerColumnsInfo.findIndex((x) => x.key === selectedColumn.key);
    let sort: SortingOrderType = SortingOrderType.DEFAULT;
    switch (headerColumnsInfo[index].sortOrder) {
      case SortingOrderType.DESC:
      case SortingOrderType.DEFAULT:
        sort = SortingOrderType.ASC;
        break;
      case SortingOrderType.ASC:
        sort = SortingOrderType.DESC;
        break;
    }
    const tempHeaders = [...headerColumnsInfo];
    tempHeaders.forEach((header) => (header.sortOrder = SortingOrderType.DEFAULT));
    tempHeaders[index].sortOrder = sort;
    batch(() => {
      changeSort(selectedColumn.key, sort);
      appDispatch(resetUsers());
      changePageNumber(1);
    });
    setHeaderColumns(tempHeaders);
  };

  const handleFilterPill = (isClearFilter?: boolean) => {
    const newAppliedFilters = [];
    if (!isClearFilter) {
      if (currentUserTypeFilter.length > 0) {
        const currentUserTypeFilterText = usertypes
          .filter((type) => {
            return currentUserTypeFilter.includes(type?.id);
          })
          .map((currUserType) => currUserType?.description)
          .join(" | ");

        newAppliedFilters.push({ title: ACCOUNT_TYPE, text: currentUserTypeFilterText, id: currentUserTypeFilter });
      }
      if (includeInactive) {
        newAppliedFilters.push({
          title: INCLUDE_INACTIVE_STATUS.status,
          text: INCLUDE_INACTIVE_STATUS.includeInActive,
        });
      }
    }
    setUsermanagementFilterInLocalStorage({
      filter: isClearFilter ? [] : newAppliedFilters,
      searchText: requestPayload.searchText,
    });
    setAppliedFilters(newAppliedFilters);
  };

  const handleApplyFilter = () => {
    appDispatch(resetUsers());
    changeFilters();
    handleFilterPill();
    dispatch(setIsFilterModal(false));
  };

  const handleClearFilter = () => {
    setCurrentUserTypeFilter([]);
    setIncludeInctive(false);
    handleFilterPill(true);

    batch(() => {
      appDispatch(resetUsers());
      changeFilters({ userType: [], includeInActive: false });
      dispatch(setIsFilterModal(false));
    });
  };

  const handleSearch = useCallback(
    (search: string) => {
      changeSearchText(search);
      setUsermanagementFilterInLocalStorage({ filter: appliedFilters, searchText: search });
    },
    [changeSearchText, appliedFilters]
  );

  const debouncedSearch = useMemo(() => {
    return debounce(handleSearch, 1000);
  }, [handleSearch]);

  useAxiosAuthenticated();
  useEffect(() => {
    appDispatch(checkIfFeatureEnabledAsync({ featureFlagName: "userManagement" })).then((res: any) => {
      if (!res.payload.data) {
        history.push("/not-found");
      }
    });
  }, [appDispatch, history]);

  useEffect(() => {
    const controller = new AbortController();

    return () => {
      controller?.abort();
      resetRequestPayload();
      dispatch(resetUserManagementState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (featureFlags.userManagement) {
      dispatch(getUsersAsync(requestPayload));
    }
  }, [dispatch, requestPayload, featureFlags.userManagement]);

  useEffect(() => {
    if (featureFlags.userManagement) {
      appDispatch(getUsersTypeAsync());
    }
  }, [featureFlags.userManagement, appDispatch]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      const addUserContent = document.getElementById("add-user-content");
      const isClickedInside = addUserContent?.contains(event.target);
      if (!isClickedInside) {
        setIsAddUserDropdownOpen(false);
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  });

  const onAccountTypeSelection = (type: AccountType) => {
    setIsAddUserDropdownOpen(false);
    localStorage.setItem("userAccountType", type);
    history.push("/user-management/new-user");
  };

  const handleAccountTypeFilter = (filter: AccountType) => {
    const existingAccountTypeFilters = [...currentUserTypeFilter];
    const isFilterAlreadyApplied = existingAccountTypeFilters.includes(filter);
    if (isFilterAlreadyApplied) {
      const currentIdx = existingAccountTypeFilters.findIndex((el) => el === filter);
      existingAccountTypeFilters.splice(currentIdx, 1);
    } else {
      existingAccountTypeFilters.push(filter);
    }
    setCurrentUserTypeFilter(existingAccountTypeFilters);
  };

  const handleIncludeInactiveStatusFilter = (filter: boolean) => {
    setIncludeInctive(filter);
  };

  const redirectToUserDetails = (userId: string, accountType: string) => {
    localStorage.setItem("userAccountType", accountType);
    history.push(`/user-management/edit-user/${userId}`);
  };

  const displaySortIcons = (el: (typeof headerColumns)[0]) => {
    return el.sortOrder === SortingOrderType.DEFAULT ? (
      <Sort />
    ) : (
      <Icon icon={el.sortOrder === SortingOrderType.ASC ? "sort-top" : "sort-bottom"} size={8} />
    );
  };

  const handleRemoveFilterPill = (title: string) => {
    const arr =
      appliedFilters.length === 1
        ? []
        : appliedFilters.filter((filters) => filters.title.toLowerCase() !== title.toLowerCase());
    if (title.toLowerCase() === "account type") {
      setCurrentUserTypeFilter([]);
      changeFilters({ ...requestPayload, userType: [] });
    } else {
      setIncludeInctive(false);
      changeFilters({ ...requestPayload, includeInActive: false });
    }
    setUsermanagementFilterInLocalStorage({ filter: arr, searchText: requestPayload.searchText });
    setAppliedFilters(arr);
  };

  return (
    <>
      <div id="user-management-container">
        <>
          <SidePopup
            isOpen={modalState.isOpenFilterModal}
            onClose={() => {
              dispatch(setIsFilterModal(false));
            }}
            contentClass="filter-content-container"
            className="filter-side-popup"
            heading="Filter"
          >
            <div className="filter-content">
              <div>
                <div className="status">Account Type</div>
                {usertypes.length > 0 && (
                  <div className="account-type-checkbox-container">
                    <div className="checkboxes" key="physician-type-checkox">
                      <Checkbox
                        name={usertypes[0].description}
                        value={usertypes[0].description}
                        id="physician-checkox"
                        onChange={() => handleAccountTypeFilter(usertypes[0].id)}
                        isChecked={currentUserTypeFilter.includes(usertypes[0].id)}
                      />
                    </div>
                    <div className="checkboxes" key="navigator-type-checkox">
                      <Checkbox
                        name={usertypes[1].description}
                        value={usertypes[1].description}
                        id="navigator-checkox"
                        onChange={() => handleAccountTypeFilter(usertypes[1].id)}
                        isChecked={currentUserTypeFilter.includes(usertypes[1].id)}
                      />
                    </div>
                    <div className="checkboxes" key="patient-type-checkox">
                      <Checkbox
                        name={usertypes[2].description}
                        value={usertypes[2].description}
                        id="patient-checkox"
                        onChange={() => handleAccountTypeFilter(usertypes[2].id)}
                        isChecked={currentUserTypeFilter.includes(usertypes[2].id)}
                      />
                    </div>
                  </div>
                )}
                <div className="status status-filter">Status</div>
                <div className="status-switch">
                  <div className="toggleContainer">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={includeInactive}
                        onChange={() => handleIncludeInactiveStatusFilter(!includeInactive)}
                      />
                      <span className={`slider round ${includeInactive ? "active" : ""}`}></span>
                    </label>
                    <div className={"toggleTitle"}>{"Include Inactive"}</div>
                  </div>
                </div>
              </div>
              <div className="filter-buttons">
                <Button
                  onClick={() => {
                    handleApplyFilter();
                  }}
                  text="Apply"
                  className="green-button apply-button"
                />
                <Button onClick={handleClearFilter} text="Clear" className="white-button clear-button" />
              </div>
            </div>
          </SidePopup>
          <Header className="user-management">
            <div className="header-title">User Management</div>
            <div className="right-section">
              {isSearching ? (
                <SearchBox
                  icon="cross"
                  className="search-box"
                  iconClassName="search-icon"
                  SearchBoxSize={22}
                  placeholder="Search"
                  text={searchText}
                  autoFocus={false}
                  showIcon={searchText.length > 0}
                  onClick={() => {
                    setIsSearching(false);
                    handleSearch("");
                    setSearchText("");
                  }}
                  onTextChange={(text) => {
                    setSearchText(text);
                    debouncedSearch(text);
                  }}
                />
              ) : (
                <Button
                  icon="search"
                  buttonSize={14}
                  className="search-icon-container"
                  iconClassName="search-icon"
                  onClick={() => setIsSearching(true)}
                />
              )}
              <div className="filter-status-container">
                <Status status={appliedFilters.length > 0 ? "filtered" : ""} />
                <Button
                  icon="filter"
                  className="filter-icon-container"
                  iconClassName="filter-icon"
                  buttonSize={14}
                  onClick={() => {
                    dispatch(setIsFilterModal(true));
                  }}
                />
              </div>
              <div id="add-user-content">
                <div
                  onClick={() => {
                    setIsAddUserDropdownOpen(!isAddUserDropdownOpen);
                  }}
                  className="img-button-container green-button-container"
                >
                  <input type="button" className="green-button-input" value="Add User" />
                  <Icon
                    icon={isAddUserDropdownOpen ? "up-arrow" : "down-arrow"}
                    size={14}
                    className={isAddUserDropdownOpen ? "up-arrow-icon" : "down-arrow-icon"}
                  />
                </div>

                {isAddUserDropdownOpen && (
                  <div id="add-user-dropdown" className="add-user-dropdown">
                    <div
                      className="add-user-dropdown-item"
                      onClick={() => onAccountTypeSelection(AccountType.PHYSICIAN)}
                    >
                      {AccountType.PHYSICIAN}
                    </div>
                    <div
                      className="add-user-dropdown-item"
                      onClick={() => onAccountTypeSelection(AccountType.NAVIGATOR)}
                    >
                      {AccountType.NAVIGATOR}
                    </div>
                    <div className="add-user-dropdown-item" onClick={() => onAccountTypeSelection(AccountType.PATIENT)}>
                      {AccountType.PATIENT}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Header>
          <div className="filter-applied-container">
            {appliedFilters.length > 0 && (
              <>
                <div className="filter-text">Applied Filters: </div>
                <div className="filters-applied">
                  {appliedFilters.map(({ title, text }, index) => {
                    return (
                      <FilterPill
                        title={title}
                        text={text}
                        onClear={() => {
                          handleRemoveFilterPill(title);
                        }}
                        key={`${title}-${index}`}
                      />
                    );
                  })}
                </div>
                <div className="clear-filter-button" onClick={handleClearFilter}>
                  Clear All
                </div>
              </>
            )}
          </div>
          <div className="user-management-listing-container">
            <div className="user-management-listing-table">
              <div className="user-management-listing-header">
                {headerColumnsInfo.map((el, index) => (
                  <div key={`${index}-${el.key}`} className="column">
                    <div
                      className={`column-content ${isLoading && "column-content-disable-cursor"}`}
                      onClick={() => {
                        if (!isLoading) {
                          handleColumnSorting(el);
                        }
                      }}
                    >
                      <div className="content">{el.name}</div>
                      <span className="sort-icon">{!isLoading && displaySortIcons(el)}</span>
                    </div>
                  </div>
                ))}
              </div>
              <div
                className={`user-management-listing-content ${
                  appliedFilters.length > 0 ? "filter-applied-content" : ""
                }`}
              >
                {users === null && (isLoading || isFeatureLoading) ? (
                  <div className="empty-state-container loader">
                    <img src={Loading} alt="loading" />
                  </div>
                ) : null}
                {users?.length === 0 &&
                  (isLoading ? (
                    <div className=" user-management-listing-row no-message-container">Fetching records...</div>
                  ) : (
                    <div className=" user-management-listing-row no-message-container">No Users Found</div>
                  ))}
                {users?.length ? (
                  <InfiniteScroll
                    next={() => {
                      incrementPage();
                    }}
                    hasMore={users && users.length ? true : false}
                    loader={isLoading ? <TableLoader /> : <></>}
                    height="100%"
                    dataLength={users.length}
                  >
                    {users.map((el, index) => (
                      <div
                        key={`${el.id}--${index}`}
                        onClick={() =>
                          redirectToUserDetails(
                            el.id,
                            usertypes.find((type) => type.id === el.accountTypeId).description
                          )
                        }
                        className="user-management-listing-row"
                      >
                        {Object.entries(el).map(([key, value]) => {
                          {
                            if (key === "id") {
                              return null;
                            } else if (key === "accountTypeId" && usertypes.length > 0) {
                              return (
                                <div key={`${el.id} ${key}`} className="column">
                                  <div className="content">
                                    {key === "accountTypeId" &&
                                      usertypes.length > 0 &&
                                      usertypes.find((type) => type.id === el.accountTypeId).description}
                                  </div>
                                </div>
                              );
                            } else {
                              return (
                                <div key={`${el.id} ${key}`} className="column">
                                  <div className="content">{value}</div>
                                </div>
                              );
                            }
                          }
                        })}
                      </div>
                    ))}
                  </InfiniteScroll>
                ) : null}
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default UserManagement;
