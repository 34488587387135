export enum DaysVisit {
  LOS = "LOS",
  VISIT = "Visits",
}

export enum IntakeStatus {
  ON_HOLD = 4,
  DISCHARGED,
  CANCELED = 8,
  CLOSED,
  DECEASED,
  EXCLUDED = 12,
}

export enum ServiceType {
  ER_URGENT_CARE = "ER-Urgent Care",
  HOSPITAL_OBSERVATION = "Hospital-Observation",
  HOSPITAL_READMIT = "Hospital-Readmit",
  OP_PROCEDURE_SERGERY = "OP-Procedure-Surgery",
  HOME_WITHOUT_SERVICES = "Home w/no Services",
}
