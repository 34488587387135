import PlanOfCareTabPresentation from "./plan-of-care-tab.component";
import { useAppDispatch } from "state/store";
import { getPlanOfCareListAsync } from "state/feature/episodes/episodes.action";
import { useEffect } from "react";
import { resetPlanofCareList } from "state/feature/episodes/episodes.slice";
import { useSelector } from "react-redux";
import { getBrowserState } from "state/feature/task-management/task-management.slice";
import { setIsOpenPOCServiceModal } from "state/feature/common/common.slice";

const PlanOfCareTab = () => {
  const { currentTab } = useSelector(getBrowserState);
  const { intakeId } = currentTab!;
  const appDispatch = useAppDispatch();

  useEffect(() => {
    if (intakeId) {
      appDispatch(getPlanOfCareListAsync({ intakeId }));
    }
  }, [appDispatch, intakeId]);

  useEffect(() => {
    return () => {
      appDispatch(resetPlanofCareList());
    };
  }, [appDispatch]);

  const handleOnClick = () => {
    appDispatch(setIsOpenPOCServiceModal({ isOpen: true, isEdit: false }));
  };

  return <PlanOfCareTabPresentation handleOnClick={handleOnClick} />;
};

export default PlanOfCareTab;
