import CustomDropDown from "components/CustomDropdown/custom-dropdown.component";
import ActiveTabWithBottomLine from "components/active-tab-wIth-bottomline/active-tab-with-bottomline.component";
import { useDispatch, useSelector } from "react-redux";
import { getAdminState, setActiveTab, setEpisodeGetRequest, setPracticeOption } from "state/feature/admin/admin.slice";
import "./header.styles.scss";
import { TabsListProps } from "components/active-tab-wIth-bottomline/props";
import { AdminTab } from "pages/admin/common/constant";

const Header = () => {
  const dispatch = useDispatch();
  const { activeTab, selectedPractice, practiceDropdownOptions } = useSelector(getAdminState);
  const tabList: TabsListProps[] = [
    { name: AdminTab.PROVIDERS, className: "admin-tab" },
    { name: AdminTab.EPISODES, className: "admin-tab" },
  ];

  const handlePracticeChange = function (option: any) {
    dispatch(setPracticeOption(option));
    if (activeTab === AdminTab.EPISODES) {
      dispatch(setEpisodeGetRequest({ clientId: option.key, pageCount: 1, pageSize: 10 }));
    }
  };
  return (
    <header className="admin-container-header">
      <ActiveTabWithBottomLine
        activeTabName={activeTab}
        setActiveTabName={(tabName) => {
          dispatch(setActiveTab(tabName));
        }}
        tabsList={tabList}
      />

      <div className="practice-dropdown-container">
        <div>
          <label className="practice-dropdown-label">Practice</label>
        </div>
        <CustomDropDown
          placeholder="Select"
          dropDownMenuItems={practiceDropdownOptions.map(({ key, name }) => ({ key: key.toString(), name }))}
          handleValueChanges={handlePracticeChange}
          defaultValue={{ key: selectedPractice?.key.toString(), name: selectedPractice?.name ?? "" }}
          idFieldName="key"
          selectionClass=""
          dropDownContainerClass="practice-dropdown-field-container"
          message="Please select one from dropdown."
          isError={false}
          isDisabled={practiceDropdownOptions.length === 0}
        />
      </div>
    </header>
  );
};

export default Header;
