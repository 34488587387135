import { PatientDetailsHeader } from "components/patient-details-header/patient-details-header.component";
import { useSelector } from "react-redux";
import Header from "../header/header.component";
import { getCheckOnPatientSlice } from "state/feature/check-on-patient/check-on-patient.slice";
import CommonState from "components/common-state/common-state.component";
import { CommonStateType } from "components/common-state/common-state.enum";
import "./check-on-patient.styles.scss";
import CheckOnPatientFooter from "./component/footer/footer.component";
import CheckOnPatientForm from "./component/form/check-on-patient-form.container";
import { FormType } from "shared/enums/form-types.enum";
import { CheckOnPatientPresentationProps } from "./common/check-on-patient.props";
import DelayModal from "components/Modal/delay-modal/delay-modal.container";
import { getNavigatorState } from "state/feature/navigator/navigator.slice";
import { useCallback } from "react";
import { getBrowserState, getCurrentPageTaskState } from "state/feature/task-management/task-management.slice";
import { getAuth } from "state/feature/auth/auth.slice";
import { USER_ROLE } from "shared/constant/role-constant";
import ManagerTaskApprovalModal from "components/Modal/manager-approval-task-modal/manager-approval-task-modal.container";
import { ACH_ID, checkOnPatientTitle } from "./check-on-patient.constant";

const CheckOnPatientPresentation = (props: CheckOnPatientPresentationProps) => {
  const { isLoading, checkOnPatientDetails, isLoadingSaveButton, isValueChange } = useSelector(getCheckOnPatientSlice);
  const {
    handleCancelButton,
    handleOpenDelayButton,
    handleSubmitButton,
    handleSubmitDelayTask,
    isTaskCompletedOrClosed,
    patientDetails,
    showDelayNotes,
    handleOpenManagerTaskApprovalButton,
  } = props;
  const { currentUserProfile } = useSelector(getNavigatorState);
  const { currentTab } = useSelector(getBrowserState);
  const { intakeId, taskId } = currentTab!;
  const { delayTask } = useSelector(getCurrentPageTaskState(taskId));
  const { user } = useSelector(getAuth);
  const isDelayedTaskRequiresApproval =
    delayTask?.delayAttempts?.length > 0 && delayTask?.delayAttempts[0]?.requiresApproval ? true : false;

  const patientDetailsDisplay = useCallback(() => {
    if (patientDetails) {
      return <PatientDetailsHeader intakeId={intakeId} patientDetails={patientDetails} />;
    }
  }, [patientDetails, intakeId]);

  const getTitle = () => {
    if (checkOnPatientDetails) {
      if (
        checkOnPatientDetails?.taskTypeId === FormType.CHECK_ON_PATIENT_WITH_PROVIDER &&
        checkOnPatientDetails?.tocTaskDetails?.serviceTypeId === ACH_ID
      ) {
        return checkOnPatientTitle.ACUTE_DISCHARGE_TNAV;
      }
      return checkOnPatientDetails?.taskTypeId === FormType.CHECK_ON_PATIENT_WITH_PATIENT
        ? checkOnPatientTitle.CHECK_ON_PATIENT_WITH_PATIENT
        : checkOnPatientTitle.CHECK_ON_PATIENT_WITH_PROVIDER;
    }
    return "";
  };

  return (
    <>
      {isDelayedTaskRequiresApproval && <ManagerTaskApprovalModal handleSubmit={() => {}} />}
      <DelayModal handleSubmit={handleSubmitDelayTask} />
      <div id="check-on-patient-container">
        <Header
          className="assessment"
          patientDetails={patientDetailsDisplay()}
          title={getTitle()}
          showDelayNote={showDelayNotes}
        />
        {isLoading && <CommonState type={CommonStateType.LOADING} />}
        {!isLoading && checkOnPatientDetails === null && (
          <CommonState type={CommonStateType.NO_TASK_FOUND} className="check-on-patient-no-task-found" />
        )}
        {!isLoading && checkOnPatientDetails && <CheckOnPatientForm />}
        <CheckOnPatientFooter
          handleCancelButton={handleCancelButton}
          isLoadingButton={isLoadingSaveButton}
          handleOpenDelayButton={!isTaskCompletedOrClosed ? handleOpenDelayButton : undefined}
          handleSubmitButton={!isTaskCompletedOrClosed ? handleSubmitButton : undefined}
          isDisabled={isLoading || checkOnPatientDetails === null || currentUserProfile === null || !isValueChange}
          isDelayedTaskRequiresApproval={isDelayedTaskRequiresApproval}
          isDisabledDelayedTaskRequiresApproval={!user.roles.includes(USER_ROLE.MANAGER)}
          handleOpenManagerTaskApproval={!isTaskCompletedOrClosed ? handleOpenManagerTaskApprovalButton : undefined}
        />
      </div>
    </>
  );
};

export default CheckOnPatientPresentation;
