import Icon from "components/Icon/icon.component";
import { StatisticBoxProps } from "./props";
import "./statistic-box.styles.scss";

const StatisticBox = ({ image, heading, info, className, size }: StatisticBoxProps) => {
  return (
    <div className="statistic-box-container">
      {/* <div className="statistic-box-container-icon-background"> */}
      <Icon icon={image} className={`${className} statistic-box-container-icon-background`} size={size} />
      {/* </div> */}
      <div className="statistic-box-container-stats">
        <div className="statistic-box-container-stats-heading">{heading}</div>
        {<div className="statistic-box-container-stats-info">{info}</div>}
      </div>
    </div>
  );
};

export default StatisticBox;
