import { episodeSettingType } from "shared/constant/commonConstants";
import { EpisodeRowProps } from "./types";
import Icon from "components/Icon/icon.component";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setIsOpenEditEpisodeModal } from "state/feature/common/common.slice";
import { setCurrentEpisode } from "state/feature/admin/admin.slice";

const EpisodeRow = function (props: EpisodeRowProps) {
  const dispatch = useDispatch();
  const [isRowHovered, setIsRowHovered] = useState(false);
  const { episodeName, programName, id, episodeTypeId, workflowDomain, standardCarePlans, isActive } = props.episode;

  const getEpisodeSetting = () =>
    episodeTypeId === episodeSettingType.INPATIENT.id
      ? episodeSettingType.INPATIENT.text
      : episodeSettingType.OUTPATIENT.text;
  return (
    <div
      className={`table-with-pagination-row ${!isActive ? "disabled" : ""}`}
      onMouseEnter={() => {
        if (isActive) {
          setIsRowHovered(true);
        }
      }}
      onMouseLeave={() => {
        if (isActive) {
          setIsRowHovered(false);
        }
      }}
    >
      <div className="column">
        <div className="content">{episodeName}</div>
      </div>
      <div className="column">
        <div className="content">{programName}</div>
      </div>
      <div className="column">
        <div className="content">{id}</div>
      </div>
      <div className="column">
        <div className="content">{getEpisodeSetting()}</div>
      </div>
      <div className="column">
        <div className="content">{workflowDomain}</div>
      </div>
      <div className="column">
        <div className="content">
          {standardCarePlans.length ? standardCarePlans.map((e) => e.name).join(", ") : "-"}
        </div>
      </div>
      <div className="column">
        <div className="content">
          {isRowHovered && isActive && (
            <div
              className="icon-background edit-button"
              onClick={() => {
                dispatch(setIsOpenEditEpisodeModal(true));
                dispatch(setCurrentEpisode(props.episode));
              }}
            >
              <Icon icon="edit" size={12} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EpisodeRow;
