import InfiniteScroll from "react-infinite-scroll-component";
import { EpisodeTasksTableProps } from "./props";
import TableLoader from "components/TableLoader/table-loader.component";
import Loading from "shared/assets/images/loader.gif";
import { HeaderColumnInfo } from "pages/episodes/types";
import { SortingOrderType } from "shared/enums/sorting-types.enums";
import Sort from "components/Sort/sort.component";
import Icon from "components/Icon/icon.component";
import "./style.scss";
import EpisodeTasksRow from "../episode-tasks-row/episode-tasks-table-row.component";
import { IEpisodeDetailTasksType } from "state/types/episodes-slice.type";
import { EpisodeTaskStatus } from "shared/enums/add-edit-task.enum";
import moment from "moment";
import { useSelector } from "react-redux";
import { getCommonState } from "state/feature/common/common.slice";
import { TaskStatus } from "shared/enums/tasks-status.enum";
import { FormType } from "shared/enums/form-types.enum";
const EpisodeTasksTable = (props: EpisodeTasksTableProps) => {
  const {
    columns,
    currentTableData,
    hasScrollbarVisible,
    isLoading,
    handleColumnSorting,
    onClickTask,
    onClickEditIcon,
  } = props;
  const { featureFlags } = useSelector(getCommonState);

  const displaySortIcons = (episodeTask: HeaderColumnInfo) => {
    return episodeTask.sortOrder === SortingOrderType.DEFAULT ? (
      <Sort />
    ) : (
      <Icon icon={episodeTask.sortOrder === SortingOrderType.ASC ? "sort-top" : "sort-bottom"} size={8} />
    );
  };

  const isDisabledRow = (episodeTask: IEpisodeDetailTasksType) => {
    const startDate = moment(episodeTask.startDate);
    const localtime = moment();
    return {
      isStricked: !episodeTask.isActive,
      isDisabled:
        !episodeTask.isActive ||
        (startDate > localtime &&
          episodeTask.taskStatus.toLowerCase() !== EpisodeTaskStatus.COMPLETED &&
          episodeTask.taskStatus.toLowerCase() !== EpisodeTaskStatus.CLOSED),
      isFutureTask:
        startDate > localtime &&
        episodeTask.taskStatus.toLowerCase() !== EpisodeTaskStatus.COMPLETED &&
        episodeTask.taskStatus.toLowerCase() !== EpisodeTaskStatus.CLOSED,
    };
  };

  const getClassName = (episodeTask: IEpisodeDetailTasksType) => {
    const isShowEditIcon =
      featureFlags.AllowTaskOwnerChange &&
      [
        FormType.SEND_ORDER,
        FormType.ADMIT_TASK_WITH_PROVIDER,
        FormType.CHECK_ON_PATIENT_WITH_PROVIDER,
        FormType.REMINDER,
      ].includes(episodeTask.taskTypeId) &&
      [TaskStatus.IN_PROGRESS, TaskStatus.NEW, TaskStatus.PROCESSING].includes(episodeTask.taskStatusId) &&
      episodeTask.ownerName &&
      episodeTask.ownerId
        ? true
        : false;
    if (isDisabledRow(episodeTask).isStricked) {
      return "stricked-row";
    } else if (isDisabledRow(episodeTask).isDisabled && !isShowEditIcon) {
      return "disabled-row";
    }
    return "";
  };

  return (
    <div
      className={`episode-tasks-table ${
        featureFlags.AllowTaskOwnerChange ? "episode-tasks-table-with-owner-column" : ""
      }`}
    >
      <div
        className={`episode-tasks-table-header ${
          hasScrollbarVisible ? "episode-tasks-table-header-scrollbar-visible" : ""
        }`}
      >
        {columns.map((episodeTask, index) => (
          <div key={`${index}-${episodeTask.key}`} className="column">
            <div
              className={`column-content ${episodeTask.sortOrder ? "column-content-cursor-pointer" : ""} ${
                isLoading ? "column-content-disable-cursor" : ""
              }`}
              onClick={() => {
                if (!isLoading && episodeTask?.sortOrder) {
                  handleColumnSorting(episodeTask);
                }
              }}
            >
              <div className="content">{episodeTask.name}</div>
              <span className="sort-icon">{!isLoading && episodeTask.sortOrder && displaySortIcons(episodeTask)}</span>
            </div>
          </div>
        ))}
      </div>
      <div className="episode-tasks-table-body">
        {isLoading && currentTableData.length === 0 && (
          <div className="empty-state-container loader">
            <img src={Loading} alt="loading" />
          </div>
        )}

        {currentTableData?.length > 0 ? (
          <InfiniteScroll
            next={() => {}}
            hasMore={false}
            loader={isLoading ? <TableLoader /> : <></>}
            height="100%"
            dataLength={currentTableData.length}
          >
            {currentTableData.map((episodeTask, index) => (
              <EpisodeTasksRow
                index={index}
                rowData={episodeTask}
                onClickTask={onClickTask}
                isDisabled={isDisabledRow(episodeTask).isDisabled}
                className={getClassName(episodeTask)}
                isFutureTask={isDisabledRow(episodeTask).isFutureTask}
                onClickEditIcon={onClickEditIcon}
              />
            ))}
          </InfiniteScroll>
        ) : null}
      </div>
    </div>
  );
};
export default EpisodeTasksTable;
