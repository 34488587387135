import axios from "axios";
import { getConfig } from "config/config";
import { Loader } from "@googlemaps/js-api-loader";

export const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
    "Ocp-Apim-Subscription-Key": getConfig().subscriptionKey!,
  },
});

export const getGoogleMapsApiInstance = (apiKey: string) =>
  new Loader({
    apiKey,
  });
