const PatientDeclineNotesTooltip = (props: { notes: string }) => {
  const { notes } = props;
  return (
    <div className="patient-decline-notes-tooltip">
      <span className="bold">Note:</span> {notes}
    </div>
  );
};

export default PatientDeclineNotesTooltip;
