import TextField from "components/TextField/text-field.component";
import { AccountType, StatusType } from "pages/user-management/common/user-management.enum";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import {
  getUserManagementState,
  resetUserManagementState,
  setIsPatientSelected,
  setUserValueChanged,
} from "state/feature/user-management/user-management.slice";
import { useAppDispatch } from "state/store";
import "./patient-form-details.styles.scss";
import {
  extractCountryCodeAndFormatFromPhone,
  getPatientFullName,
  getPatientMetaData,
  isEmptyString,
  validateEmail,
  validateNumber,
} from "shared/methods/utilityFunctions";
import { Patient, PatientFormDetailsProps, SavePatientDetailsPayload } from "./patient-form-details.type";
import {
  getPatientDetailsAsync,
  getPatientDetailsByIdAsync,
  savePatientDetailsAsync,
} from "state/feature/user-management/user-management.action";
import Icon from "components/Icon/icon.component";
import Loading from "shared/assets/images/loader.gif";
import { getAuth } from "state/feature/auth/auth.slice";
import UserManagementModal from "components/Modal/UserManagementModal/user-management-modal.component";
import { useHistory } from "react-router";
import {
  EMAIL_ERROR,
  FIRSTNAME_ERROR,
  LASTNAME_ERROR,
  PHONE_ERROR,
} from "pages/user-management/common/user-management.constants";
import PatientSearchBox from "components/patient-search-box/patient-search-box.component";
import { debounce } from "lodash";

export const PatientFormDetails = forwardRef(({ userID = "" }: PatientFormDetailsProps, ref) => {
  useAxiosAuthenticated();
  const appDispatch = useAppDispatch();
  const authState = useSelector(getAuth);
  const {
    isLoadingPatientData,
    usertypes,
    currentUserDetails,
    patients,
    isPatientListLoading,
    isPatientSelected,
    currentPatientDetails,
  } = useSelector(getUserManagementState);
  const [patientFormData, setpatientFormData] = useState<Patient>({
    accountType: AccountType.PATIENT,
    email: "",
    status: StatusType.INACTIVE,
    firstName: "",
    middleName: "",
    lastName: "",
    phone: "",
    createdAt: "",
    updatedAt: "",
  });
  const [searchText, setSearchText] = useState<string>("");
  const [isShowPatientList, setIsShowPatientList] = useState<boolean>(false);
  const [validationValues, setValidationValues] = useState({
    firstName: true,
    lastName: true,
    userName: true,
    email: true,
    phone: true,
    patientDetailsInfo: true,
  });
  const [isDisableStatusModalOpen, setIsStatusDisableModalOpen] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    return () => {
      appDispatch(resetUserManagementState());
    };
  }, [appDispatch]);

  useEffect(() => {
    if (userID) {
      appDispatch(getPatientDetailsByIdAsync(userID));
    }
  }, [appDispatch, userID]);

  useEffect(() => {
    if (currentUserDetails) {
      setpatientFormData({
        ...patientFormData,
        ...currentUserDetails,
      });

      setSearchText(currentPatientDetails);
      appDispatch(setIsPatientSelected(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserDetails]);

  const validatePhone = (phone: string) => {
    return !phone || phone.length === 0 || phone.length < 10 || !validateNumber(phone);
  };

  const validatePhoneInput = (phone: string) => {
    const isValidPhone = validatePhone(phone);
    setValidationValues({ ...validationValues, phone: !isValidPhone });
  };

  const checkIfAllFieldsAreValid = () => {
    if (patientFormData) {
      if (isPatientSelected) {
        setValidationValues({
          ...validationValues,
          firstName: !isEmptyString(patientFormData.firstName),
          lastName: !isEmptyString(patientFormData.lastName),
          email: !isEmptyString(patientFormData.email ?? "") && validateEmail(patientFormData.email ?? ""),
          phone: !validatePhone(patientFormData.phone),
          patientDetailsInfo: isPatientSelected,
        });
      } else {
        setValidationValues({
          ...validationValues,
          patientDetailsInfo: isPatientSelected,
        });
      }
      return (
        !isEmptyString(patientFormData.firstName) &&
        !isEmptyString(patientFormData.lastName) &&
        !isEmptyString(patientFormData.email ?? "") &&
        validateEmail(patientFormData.email ?? "") &&
        !validatePhone(patientFormData.phone) &&
        isPatientSelected
      );
    }
  };

  useImperativeHandle(ref, () => ({
    saveData() {
      const isValid = checkIfAllFieldsAreValid();
      if (isValid) {
        let patientID = "";
        const { id, patientId } = patientFormData;
        if (id) {
          patientID = id;
        } else if (patientId) {
          patientID = patientId;
        }

        const patientData: SavePatientDetailsPayload = {
          userTypeId: usertypes?.find((type) => type.description === AccountType.PATIENT)?.id,
          id: userID ? userID : "",
          patientId: patientID,
          firstName: patientFormData.firstName,
          middleName: patientFormData.middleName,
          lastName: patientFormData.lastName,
          email: patientFormData.email ?? "",
          phoneNumber: "+1" + patientFormData.phone,
          isActive: patientFormData.status === StatusType.ACTIVE ? true : false,
          clientId: patientFormData && patientFormData.clientID ? patientFormData.clientID : 0,
          recordCreatedBy: authState.user.id,
          recordUpdatedBy: userID ? authState.user.id : "",
        };
        appDispatch(savePatientDetailsAsync(patientData))
          .unwrap()
          .then(() => {
            setTimeout(() => {
              history.goBack();
            }, 10);
          });
      }
    },
    isRequestPayloadValid: () => checkIfAllFieldsAreValid(),
  }));

  const onChangeInputField = (type: string, value: string) => {
    switch (type) {
      case "First Name":
        setValidationValues({ ...validationValues, firstName: true });
        setpatientFormData({ ...patientFormData, firstName: value });
        break;
      case "Middle Name (optional)":
        setpatientFormData({ ...patientFormData, middleName: value });
        break;
      case "Last Name":
        setValidationValues({ ...validationValues, lastName: true });
        setpatientFormData({ ...patientFormData, lastName: value });
        break;
      case "Email Address":
        setValidationValues({ ...validationValues, email: true });
        setpatientFormData({ ...patientFormData, email: value });
        break;
      case "Phone":
        setValidationValues({ ...validationValues, phone: true });
        setpatientFormData({ ...patientFormData, phone: value });
        break;
      default:
        break;
    }
    appDispatch(setUserValueChanged(true));
  };

  const validateEmailInput = (email: string) => {
    const isValidEmail = validateEmail(email);
    setValidationValues({ ...validationValues, email: isValidEmail });
  };

  const validateInputFields = (type: string) => {
    const fieldValue = (patientFormData as any)[type];
    setValidationValues({
      ...validationValues,
      [type]: !isEmptyString(fieldValue),
    });
  };

  const handleSearchBoxOnBlur = () => {
    if (patientFormData) {
      appDispatch(setIsPatientSelected(true));
      setSearchText(currentPatientDetails);
    } else {
      setSearchText("");
      setValidationValues({
        ...validationValues,
        patientDetailsInfo: isPatientSelected,
      });
    }
    appDispatch(setUserValueChanged(true));
  };

  useEffect(() => {
    function handleClickOutside(event: any) {
      const patientListContent = document.getElementById("patient-search-list");
      const isClickedInside = patientListContent?.contains(event.target);
      if (isClickedInside === false) {
        setIsShowPatientList(false);
        handleSearchBoxOnBlur();
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  });

  const handllePatientItemClicked = (patientData: Patient) => {
    const fullName = getPatientFullName(patientData);
    const metaData = getPatientMetaData(patientData);
    setpatientFormData({ ...patientData, status: StatusType.ACTIVE });
    setSearchText(`${fullName} ${metaData}`);
    appDispatch(setIsPatientSelected(true));
    appDispatch(setUserValueChanged(true));
    setIsShowPatientList(false);
  };

  const handleStatusToggle = () => {
    if (isPatientSelected) {
      if (patientFormData.status === StatusType.ACTIVE) {
        setIsStatusDisableModalOpen(true);
      } else {
        setpatientFormData({
          ...patientFormData,
          status: StatusType.ACTIVE,
        });
      }
    }
    appDispatch(setUserValueChanged(true));
  };

  useEffect(() => {
    if (isPatientSelected) {
      checkIfAllFieldsAreValid();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPatientSelected]);

  const showPatientList = () => {
    if (isShowPatientList && patients.length === 0) {
      return (
        <div className="patient-list" id="patient-search-list">
          <div className="patient-item">No search results</div>
        </div>
      );
    }
    if (isShowPatientList && patients.length > 0) {
      return (
        <div className="patient-list" id="patient-search-list">
          {patients.map((item: Patient) => (
            <div
              className="patient-item"
              key={`${item.id}${item.email}`}
              onClick={() => {
                handllePatientItemClicked(item);
              }}
            >
              {`${getPatientFullName(item)} ${getPatientMetaData(item)}`}
            </div>
          ))}
        </div>
      );
    }
  };

  const getPatientDetails = async (searchValue: string) => {
    const response = await appDispatch(getPatientDetailsAsync(searchValue)).unwrap();
    if (response || isEmptyString(response)) {
      setIsShowPatientList(true);
    }
  };

  const handleSearch = useCallback((value: string) => {
    if (value.length === 0) {
      setIsShowPatientList(false);
    }
    if (value.length >= 3) {
      getPatientDetails(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debouncedSearch = useMemo(() => {
    return debounce(handleSearch, 500);
  }, [handleSearch]);

  const onSearchTextChange = (value: string) => {
    setValidationValues({ ...validationValues, patientDetailsInfo: true });
    setSearchText(value);
    appDispatch(setIsPatientSelected(false));
    debouncedSearch(value);
  };

  const handleConfirmedInactive = () => {
    setpatientFormData({
      ...patientFormData,
      status: StatusType.INACTIVE,
    });
    setIsStatusDisableModalOpen(false);
  };

  return (
    <>
      {isLoadingPatientData ? (
        <div className="empty-state-container loader">
          <img src={Loading} alt="loading" />
        </div>
      ) : (
        <>
          {isDisableStatusModalOpen && (
            <UserManagementModal
              heading="Change Status"
              content="Are you sure, you want to set this user’s status to ‘Inactive’?"
              submitButtonTitle="Yes"
              cancelButtonTitle="No"
              isOpen={isDisableStatusModalOpen}
              handleCancelClick={() => setIsStatusDisableModalOpen(false)}
              handleSubmitClick={handleConfirmedInactive}
            />
          )}
          <div className="patient-form-container">
            <div className="form-row">
              <div className="form-col account-type-disabled">
                <TextField
                  label="Account Type"
                  type="text"
                  value={AccountType.PATIENT}
                  setValue={() => {}}
                  inputClassName="account-type-disabled"
                  onBlur={() => {}}
                  disabled={true}
                />
              </div>
              <div className="form-col">
                <TextField
                  label="Email Address"
                  type="email"
                  value={patientFormData.email ?? ""}
                  setValue={onChangeInputField}
                  className={!isPatientSelected ? "field-temporary-disabled" : ""}
                  inputClassName={`email-input`}
                  onBlur={() => validateEmailInput(patientFormData.email ?? "")}
                  placeholder="Email Address"
                  showError={!validationValues.email}
                  errorMessage={EMAIL_ERROR}
                  disabled={!isPatientSelected}
                  showErrorIcon={true}
                  maxLength={100}
                />
              </div>
              <div className="form-col">
                <label className={`status-switch-label  ${!isPatientSelected ? "field-temporary-disabled" : ""}`}>
                  Active
                </label>
                <div className={`toggleContainer ${!isPatientSelected ? "field-temporary-disabled" : ""}`}>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={patientFormData.status === StatusType.ACTIVE}
                      onChange={handleStatusToggle}
                    />
                    <span
                      className={`slider round ${patientFormData.status === StatusType.ACTIVE ? "active" : ""}`}
                    ></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="form-row form-row-patient-search">
              <div className="form-col patient-select">
                <label className="status-switch-label">Patient</label>
                <div className="search-box-container" id="search-box-container">
                  <PatientSearchBox
                    icon={isPatientSelected ? "" : "cross"}
                    showIcon={!isPatientSelected && searchText.length > 0}
                    className="patient-search-box"
                    iconClassName="search-icon"
                    SearchBoxSize={70}
                    placeholder="Search patient by name"
                    onBlur={() => handleSearchBoxOnBlur()}
                    onBlurWithIcon={() => {
                      if (currentPatientDetails && !searchText) {
                        appDispatch(setIsPatientSelected(true));
                        setSearchText(currentPatientDetails);
                      }
                    }}
                    onTextChange={onSearchTextChange}
                    text={searchText}
                    onClick={() => {
                      setSearchText("");
                      setIsShowPatientList(false);
                      appDispatch(setUserValueChanged(true));
                    }}
                  />
                </div>

                {!validationValues.patientDetailsInfo && (
                  <div className="error-wrap">
                    {<Icon icon="error-message-icon" size={15} className="error-message-icon" />}
                    {"Select a patient"}
                  </div>
                )}
                {showPatientList()}
              </div>
              <div className="form-col">
                {isPatientListLoading && (
                  <div className="loader">
                    <img src={Loading} alt="loading" />
                  </div>
                )}
              </div>
              <div className="form-col"></div>
            </div>
            <div className="form-row name">
              <div className="form-col">
                <TextField
                  label="First Name"
                  type="text"
                  value={patientFormData.firstName}
                  setValue={onChangeInputField}
                  className={!isPatientSelected ? "field-temporary-disabled" : ""}
                  inputClassName={`first-name`}
                  showError={!validationValues.firstName}
                  onBlur={() => validateInputFields("firstName")}
                  errorMessage={FIRSTNAME_ERROR}
                  placeholder="First Name"
                  disabled={!isPatientSelected}
                  showErrorIcon={true}
                  maxLength={30}
                />
              </div>
              <div className="form-col">
                <TextField
                  label="Middle Name (optional)"
                  type="text"
                  value={patientFormData.middleName}
                  setValue={onChangeInputField}
                  className={!isPatientSelected ? "field-temporary-disabled" : ""}
                  inputClassName={`middle-name`}
                  onBlur={() => {}}
                  placeholder="Middle Name"
                  disabled={!isPatientSelected}
                  maxLength={30}
                />
              </div>
              <div className="form-col">
                <TextField
                  label="Last Name"
                  type="text"
                  value={patientFormData.lastName}
                  setValue={onChangeInputField}
                  className={!isPatientSelected ? "field-temporary-disabled" : ""}
                  inputClassName={`last-name`}
                  onBlur={() => validateInputFields("lastName")}
                  showError={!validationValues.lastName}
                  errorMessage={LASTNAME_ERROR}
                  placeholder="Last Name"
                  disabled={!isPatientSelected}
                  showErrorIcon={true}
                  maxLength={30}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-col phone-col">
                <div className="phone-container">
                  <TextField
                    label="Phone"
                    type="tel"
                    onBlur={() => validatePhoneInput(patientFormData.phone)}
                    value={
                      Number(extractCountryCodeAndFormatFromPhone(patientFormData.phone)) === 0 ||
                      isNaN(Number(extractCountryCodeAndFormatFromPhone(patientFormData.phone)))
                        ? ""
                        : Number(extractCountryCodeAndFormatFromPhone(patientFormData.phone))
                    }
                    setValue={onChangeInputField}
                    inputClassName="phone-input"
                    className={`phone-container-content ${!isPatientSelected ? "field-temporary-disabled" : ""}`}
                    showError={!validationValues.phone}
                    placeholder={"Phone"}
                    isPhoneNumber
                    maxLength={10}
                    disabled={!isPatientSelected}
                  />
                  {!validationValues.phone && (
                    <div className="error-wrap">
                      {<Icon icon="error-message-icon" size={15} className="error-message-icon" />}
                      {PHONE_ERROR}
                    </div>
                  )}
                </div>
              </div>
              {userID && (
                <>
                  {patientFormData.createdAt && (
                    <div className="form-col">
                      <TextField
                        label="Created"
                        type="text"
                        value={patientFormData.createdAt}
                        setValue={() => {}}
                        inputClassName="created-disabled"
                        onBlur={() => {}}
                        disabled={true}
                      />
                    </div>
                  )}
                  {patientFormData.updatedAt ? (
                    <div className="form-col">
                      <TextField
                        label="Modified"
                        type="text"
                        value={patientFormData.updatedAt}
                        setValue={() => {}}
                        inputClassName="updated-disabled"
                        onBlur={() => {}}
                        disabled={true}
                      />
                    </div>
                  ) : (
                    <div className="form-col"></div>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
});
