import { useDispatch, useSelector } from "react-redux";
import {
  getBrowserState,
  removeTab,
  setShouldPerformPostDelayAction,
  setShouldPerformPostSubmitAction,
} from "state/feature/task-management/task-management.slice";
import "./post-task-performed-view.styles.scss";
import { useEffect, useState } from "react";
import { setLockState } from "state/feature/task-management/task-management.action";

const PostTaskPerformedView = () => {
  const dispatch = useDispatch();
  const { currentTab, pages } = useSelector(getBrowserState);
  const { title, taskId, intakeId } = currentTab!;
  const [progressVal, setProgressVal] = useState(0);
  const timeout = 5000;

  const getMessage = () => {
    const page = pages.find((p) => p.taskId === taskId && p.intakeId === intakeId);
    const taskTitle = title.split("|")[1];
    if (page?.shouldPerformPostSubmitAction) {
      if (typeof page.shouldPerformPostSubmitAction !== "boolean" && page.shouldPerformPostSubmitAction?.message) {
        return page.shouldPerformPostSubmitAction?.message;
      }
      return `${taskTitle} has been successfully submitted`;
    }

    if (page?.shouldPerformPostDelayAction) {
      return `${taskTitle} has been successfully delayed`;
    }

    if (page?.shouldPerformPostTaskApprovedAction) {
      return `Approval to close ${taskTitle} task approved`;
    }

    if (page?.shouldPerformPostTaskRejectedAction) {
      return `Approval to close ${taskTitle} task rejected`;
    }

    if (page?.shouldPerformPostTaskWaitingApprovalAction) {
      return "Submitted for Manager Approval";
    }
    return `${title.split("|")[1]} has been successfully performed`;
  };

  useEffect(() => {
    const timeId = setInterval(() => {
      setProgressVal((prev) => prev + 1);
    }, 50);
    setTimeout(() => {
      clearInterval(timeId);
      dispatch(setLockState({ intakeId, taskId, isLocked: false }));
      dispatch(removeTab(currentTab?.taskId));
    }, timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div id="post-task-view-container">
      <div className="message-container">
        <div className="checkmark-container">
          <svg xmlns="http://www.w3.org/2000/svg" width="84" height="83" viewBox="0 0 84 83" fill="none">
            <circle cx="42" cy="41.5" r="41.5" fill="#1AA99C" />
            <path
              d="M61.6716 29.6406L33.6716 57.6406L20.9443 44.9134"
              stroke="white"
              stroke-width="6"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div className="message-text">{getMessage()} </div>
        <div className="loader-container">
          <progress value={progressVal} max={100}>
            {progressVal}
          </progress>
        </div>
      </div>
    </div>
  );
};

export default PostTaskPerformedView;
