export const Other = "Other";

export const serviceSuccessMessage = "Service successfully added";

export const serviceEditSuccessMessage = "Service successfully updated";

export const deleteSuccessMessage = "Service successfully deleted";

export const pocDeleteHeading = "This action is not reversible, Are you sure you want to delete this service?";

export const errorCharacterMessage = "Length should not be more than 200 characters";

export const losWithDays = "LOS (Days)";
export const losAndVisits = "LOS/Visits";

export const DeleteLogText = "delete";
export const AddedPOCLogText = "add";
export const UpdatedPOCLogText = "update";
