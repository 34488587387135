import Icon from "components/Icon/icon.component";
import "./style.scss";
const NoAnswerDelayNotes = () => {
  return (
    <div className="no-answer-delay-notes">
      <div className="no-answer-delay-notes-content">
        <div className="title">
          <div className="icon">
            <Icon icon="important" size={15} />
          </div>
          Important!
        </div>
        <div className="text">
          If you do not get an answer when calling, contact care giver and document results in the Notes field before
          delaying/closing this task
        </div>
      </div>
    </div>
  );
};

export default NoAnswerDelayNotes;
