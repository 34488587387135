import { TaskInfoTab } from "shared/enums/task-management-tabs.enum";

export const TASKS_LIMIT = 10;
export const CALL_MESSAGE_ERROR_MESSAGE =
  "Patient details are not loading. Contact Episode Solutions Support for assistance.";
export const UP_COMING_TASK = "Upcoming Tasks";
export const OOPS_NO_TASK = "Oops! No tasks found";
export const defaultTaskmanagementURL = "/task-management";
export const SERVICES = "Services";
export const VISIT_TYPE = {
  irf: "IRF",
  snf: "SNF",
  hh: "HH",
  mpt: "MPT",
  opt: "OPT",
};
