import Icon from "components/Icon/icon.component";
import Sort from "components/Sort/sort.component";
import CommonState from "components/common-state/common-state.component";
import { CommonStateType } from "components/common-state/common-state.enum";
import { useEffect, useState } from "react";
import "./table-with-pagination.styles.scss";
import { SortingOrderType } from "shared/enums/sorting-types.enums";
import Button from "components/Button/button.component";

export type HeaderColumnInfo = {
  key: string;
  name: string;
  sortOrder?: SortingOrderType;
};

type TableWithPaginationPresentationProps = {
  columns: HeaderColumnInfo[];
  handleSort: (column: HeaderColumnInfo) => void;
  isLoading: boolean;
  rows: Array<React.ReactNode>;
  currentPage: number;
  countPerPage: number;
  totalCount: number;
  onPageChange: (pageCount: number) => void;
  emptyMessageType?: CommonStateType;
  tableContainerClass?: string;
  emptyMessageContainerClass?: string;
};

const TableWithPaginationPresentation = (props: TableWithPaginationPresentationProps) => {
  const {
    columns,
    handleSort,
    isLoading,
    rows,
    totalCount,
    countPerPage,
    currentPage,
    onPageChange,
    emptyMessageType: messageForNoData,
    tableContainerClass = "",
    emptyMessageContainerClass = "",
  } = props;
  const [hasScrollBar, setHasScrollBar] = useState(false);
  const [totalPageCount, setTotalPageCount] = useState(0);

  useEffect(() => {
    if (totalCount && countPerPage) {
      const pageCount = Math.ceil(totalCount / countPerPage);
      setTotalPageCount(pageCount);
    }
  }, [totalCount, countPerPage]);

  const displaySortIcons = (el: HeaderColumnInfo) => {
    return el.sortOrder === SortingOrderType.DEFAULT ? (
      <Sort />
    ) : (
      <Icon icon={el.sortOrder === SortingOrderType.ASC ? "sort-top" : "sort-bottom"} size={8} />
    );
  };
  const updateState = () => {
    const InfiniteScrollOuterDivElement = document.querySelector(".infinite-scroll-component__outerdiv");
    const InfiniteScrollInnerDivElement = InfiniteScrollOuterDivElement?.children[0]
      ? InfiniteScrollOuterDivElement.children[0]
      : null;
    if (InfiniteScrollOuterDivElement && InfiniteScrollInnerDivElement) {
      setHasScrollBar(InfiniteScrollInnerDivElement.clientWidth < InfiniteScrollOuterDivElement.clientWidth);
    }
  };

  useEffect(() => {
    updateState();
    window.addEventListener("resize", updateState);
    return () => window.removeEventListener("resize", updateState);
  }, [rows, isLoading]);

  const renderBody = function () {
    if (isLoading) {
      return (
        <div className="empty-state-container loader">
          <CommonState type={CommonStateType.LOADING} />
        </div>
      );
    }

    if (rows.length === 0) {
      return (
        <div className={`empty-message-container ${emptyMessageContainerClass}`}>
          <CommonState type={messageForNoData} />
        </div>
      );
    }

    return rows;
  };

  const renderPageCountSection = function () {
    if (rows.length && totalPageCount) {
      const pages = [];

      for (let count = 1; count <= totalPageCount; count++) {
        pages.push(
          <button
            className={`page-button ${count === currentPage ? "active" : ""}`}
            key={count}
            disabled={isLoading}
            onClick={() => {
              if (count !== currentPage) {
                onPageChange(count);
              }
            }}
          >
            {count}
          </button>
        );
      }

      const currIdx = currentPage - 1;
      if (pages.length <= 6) {
        return (
          <section className={`page-button-section ${isLoading ? "disabled" : ""}`}>{pages.map((e) => e)}</section>
        );
      } else {
        if (currIdx <= 2) {
          return (
            <section className={`page-button-section ${isLoading ? "disabled" : ""}`}>
              {pages.slice(0, 4).map((el) => el)}
              <span className="filler">.</span>
              <span className="filler">.</span>
              <span className="filler">.</span>
              {pages[pages.length - 1]}
            </section>
          );
        } else if (currIdx + 3 > pages.length - 1) {
          return (
            <section className={`page-button-section ${isLoading ? "disabled" : ""}`}>
              {pages[0]}
              <span className="filler">.</span>
              <span className="filler">.</span>
              <span className="filler">.</span>
              {pages.slice(pages.length - 4, pages.length).map((e) => e)}
            </section>
          );
        } else {
          return (
            <section className={`page-button-section ${isLoading ? "disabled" : ""}`}>
              {pages[0]}
              <span className="filler">.</span>
              <span className="filler">.</span>
              <span className="filler">.</span>
              {pages.slice(currIdx - 1, currIdx + 2).map((e) => e)}
              <span className="filler">.</span>
              <span className="filler">.</span>
              <span className="filler">.</span>
              {pages[pages.length - 1]}
            </section>
          );
        }
      }
    }
  };

  return (
    <div className={`table-with-pagination-container ${tableContainerClass}`}>
      <div className={`table-with-pagination `}>
        <div className={`table-with-pagination-header ${hasScrollBar ? "scrollbar-visible" : ""}`}>
          {columns.map((el, index) => (
            <div key={`${index}-${el.key}`} className="column">
              <div
                className={`column-content ${isLoading ? "disabled" : ""} ${
                  !isLoading && el.sortOrder && el.name !== "" ? "cursor-pointer" : ""
                }`}
                onClick={() => {
                  if (!isLoading && el.sortOrder) {
                    handleSort(el);
                  }
                }}
              >
                <div className="content">{el.name}</div>
                {!isLoading && el.sortOrder && <div>{displaySortIcons(el)}</div>}
              </div>
            </div>
          ))}
        </div>
        <div className="table-with-pagination-body">{renderBody()}</div>
        <div className="table-with-pagination-footer">
          {totalPageCount > 1 && !!rows.length && (
            <>
              <Button
                text="Previous"
                className="previous-button"
                disabled={isLoading || currentPage <= 1}
                onClick={() => {
                  if (currentPage > 1) {
                    onPageChange(currentPage - 1);
                  }
                }}
              />
              {renderPageCountSection()}
              <Button
                text="Next"
                className="next-button"
                disabled={isLoading || currentPage >= totalPageCount}
                onClick={() => {
                  if (currentPage < totalPageCount) {
                    onPageChange(currentPage + 1);
                  }
                }}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TableWithPaginationPresentation;
