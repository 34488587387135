import React from "react";
import Icon from "components/Icon/icon.component";
import "./sort.styles.scss";

const Sort = () => {
  return (
    <div className="sort-container">
      <Icon icon="sort-top" size={8} />
      <Icon icon="sort-bottom" size={8} />
    </div>
  );
};

export default Sort;
