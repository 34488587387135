import { ChangeEvent, useCallback, useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import Button from "components/Button/button.component";
import Checkbox from "components/Checkbox/checkbox.component";
import "./toc-revision.styles.scss";
import FacilityDropdown from "../facility-dropdown";

import GenericPopupModal from "components/Modal/GenericPopupModal/generic-popup-modal.component";
import { useAppDispatch } from "state/store";
import ErrorIcon from "shared/assets/images/error.png";
import Icon from "components/Icon/icon.component";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import {
  ACUTE_LOS_ERROR,
  FACILITY_ERROR,
  LOS_LIMIT,
  LOS_VISIT_ERROR,
  NOTE_LIMIT,
  PAC_TYPE,
  TOC_ITEMS,
  TocStatusEnum,
} from "../constants/index.constant";
import { isEmptyString, validateNumber } from "shared/methods/utilityFunctions";
import {
  getTOCManagementState,
  getTocEditForm,
  resetLocationValidation,
  resetTocEditForm,
  resetTocItemsForm,
  setIsTocValueChanged,
  setIsTocsLoading,
  setNavigatorAndAdditionalNotes,
  setTocEditForm,
  setTocItemsForm,
  updateTocList,
} from "state/feature/toc-management/toc-management.slice";
import {
  createTocAsync,
  getTocAsync,
  editTocAsync,
  getTocHistory,
} from "state/feature/toc-management/toc-management.action";
import {
  ITransitionOfCare,
  ITransitionOfCareItems,
  ICreateNewTocPayload,
  ICreateTransitionOfCareItem,
  IEditTocPayload,
  IEditTransitionOfCareItem,
  ILocation,
} from "state/types/toc-management-slice.type";
import { CustomToolTip } from "components/CustomTooltip/custom-tooltip.component";
import TocChangeModal from "pages/task-management/transition-of-care/modals/toc-change-modal/toc-change-modal.component";
import {
  getBrowserState,
  getTaskManagementState,
  setIsCurrentTaskDirty,
  setLoadTaskList,
  setShouldPerformPostSubmitAction,
} from "state/feature/task-management/task-management.slice";
import { VISIT_TYPE } from "pages/task-management/constant";
import Preferred from "components/preferred/preferred.component";

interface TocRevisionProps {
  toc: ITransitionOfCare;
  taskId: string;
}

const TocRevision = (props: TocRevisionProps) => {
  useAxiosAuthenticated();
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const { facilities, tocs, intake, revisionReasons, statuses } = useSelector(getTOCManagementState);

  const { toc, taskId } = props;
  const {
    id,
    intakeId,
    clientId,
    version,
    noteCareManager,
    notePhysician,
    anticipatedAcuteLOS,
    createdAt,
    updatedAt,
    approvedAt,
    isExpanded,
    transitionOfCareItems,
    status,
    isModified,
    history,
    additionalNote,
    approvedBy,
    reasonId,
    statusId,
    createdBy,
    updatedBy,
    isEditModeOn,
  } = toc;
  const [isRowHovered, setIsRowHovered] = useState(false);
  const tocEditForm = useSelector(getTocEditForm);
  const { currentTab } = useSelector(getBrowserState);
  const { redirectParams } = useSelector(getTaskManagementState);
  const [isSubmitButtonPressed, setIsSubmitButtonPressed] = useState(false);

  const getProviderName = useCallback(
    (providerId: number) => {
      if (facilities && facilities.length > 0 && providerId !== -1) {
        const facility = facilities.find((el) => el.id === providerId);
        return facility ? facility.providerName : "";
      } else {
        return "";
      }
    },
    [facilities]
  );
  const getTargetLos = useCallback((los: number) => {
    if (los === -1) {
      return "";
    }
    return los.toString();
  }, []);

  const initializeTocItemsForm = useCallback(() => {
    if (transitionOfCareItems && transitionOfCareItems.length > 0) {
      const form: Record<string, ILocation> = {};
      transitionOfCareItems.forEach(({ id: tocItemId, providerId, targetLos, pacTypeName }: ITransitionOfCareItems) => {
        if (pacTypeName === PAC_TYPE.SNF) {
          form.snf = {
            id: tocItemId,
            providerId,
            providerName: getProviderName(providerId),
            los: getTargetLos(targetLos),
            pacTypeName: PAC_TYPE.SNF,
            isLosValid: true,
            isProviderValid: true,
          };
        }
        if (pacTypeName === PAC_TYPE.IRF) {
          form.irf = {
            id: tocItemId,
            providerId,
            providerName: getProviderName(providerId),
            los: getTargetLos(targetLos),
            pacTypeName: PAC_TYPE.IRF,
            isLosValid: true,
            isProviderValid: true,
          };
        }
        if (pacTypeName === PAC_TYPE.HH) {
          form.hh = {
            id: tocItemId,
            providerId,
            providerName: getProviderName(providerId),
            los: getTargetLos(targetLos),
            pacTypeName: PAC_TYPE.HH,
            isLosValid: true,
            isProviderValid: true,
          };
        }
        if (pacTypeName === PAC_TYPE.OPT) {
          form.opt = {
            id: tocItemId,
            providerId,
            providerName: getProviderName(providerId),
            los: getTargetLos(targetLos),
            pacTypeName: PAC_TYPE.OPT,
            isLosValid: true,
            isProviderValid: true,
          };
        }
        if (pacTypeName === PAC_TYPE.MPT) {
          form.mpt = {
            id: tocItemId,
            providerId,
            providerName: getProviderName(providerId),
            los: getTargetLos(targetLos),
            pacTypeName: PAC_TYPE.MPT,
            isLosValid: true,
            isProviderValid: true,
          };
        }
        dispatch(setTocItemsForm({ ...tocEditForm.tocItemsForm, ...form }));
        if (pacTypeName === PAC_TYPE.HOME_SERVICE && providerId === 0 && targetLos === 0) {
          dispatch(
            setTocEditForm({
              ...tocEditForm,
              acuteLos: { value: anticipatedAcuteLOS ? anticipatedAcuteLOS.toString() : "", error: "" },
              isHomeWithNoServicesEnabled: true,
            })
          );
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transitionOfCareItems, getProviderName, getTargetLos, dispatch, tocEditForm, tocEditForm.tocItemsForm]);

  const getTocItem = (pacType: string) => {
    return transitionOfCareItems?.find((t) => t.pacTypeName === pacType);
  };

  useEffect(() => {
    if (toc && status === TocStatusEnum.NEW) {
      const updatedTocs = tocs.map((t) => ({ ...t, isEditModeOn: t.id === id }));
      dispatch(updateTocList(updatedTocs));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isEditModeOn) {
      dispatch(
        setTocEditForm({
          ...tocEditForm,
          acuteLos: { value: anticipatedAcuteLOS ? anticipatedAcuteLOS.toString() : "", error: "" },
        })
      );
      initializeTocItemsForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditModeOn, anticipatedAcuteLOS]);

  const onNavigatorNotesChange = (val: string) => {
    dispatch(setIsTocValueChanged(true));
    dispatch(setTocEditForm({ ...tocEditForm, navigatorNotes: val, isTocItemsFormDirty: true, isFormEmpty: false }));
  };

  const onAdditionalNotesChange = (val: string) => {
    dispatch(setIsTocValueChanged(true));
    dispatch(setTocEditForm({ ...tocEditForm, additionalNotes: val, isTocItemsFormDirty: true, isFormEmpty: false }));
  };

  const onChangeAcuteLos = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setIsTocValueChanged(true));
    dispatch(
      setTocEditForm({
        ...tocEditForm,
        acuteLos: { value: e.target.value, error: "" },
        isTocItemsFormDirty: true,
        isFormEmpty: false,
      })
    );
  };

  const checkForLocationError = (providerId: number, los: string, key: string) => {
    let isProviderValid = false;
    let isLosValid = false;
    if (isEmptyString(los) && providerId === -1) {
      isProviderValid = true;
      isLosValid = true;
    } else if (!isEmptyString(los) && providerId === -1) {
      isProviderValid = false;
      isLosValid = 0 < parseInt(los, 10) && parseInt(los, 10) <= LOS_LIMIT[key.toUpperCase()];
    } else if (isEmptyString(los) && providerId !== -1) {
      isProviderValid = true;
      isLosValid = false;
    } else if (!isEmptyString(los) && providerId !== -1) {
      isProviderValid = true;
      isLosValid = 0 < parseInt(los, 10) && parseInt(los, 10) <= LOS_LIMIT[key.toUpperCase()];
    }

    return [isProviderValid, isLosValid];
  };

  const onFacilityChange = (key: string, value: { providerId: number; providerName: string }) => {
    dispatch(setIsTocValueChanged(true));
    dispatch(setTocEditForm({ ...tocEditForm, facilityError: "", isTocItemsFormDirty: true, isFormEmpty: false }));

    const location = tocEditForm.tocItemsForm[key];
    let [isProviderValid, isLosValid] = [true, true];
    [isProviderValid, isLosValid] = checkForLocationError(value.providerId, location.los, key);

    dispatch(
      setTocItemsForm({
        ...tocEditForm.tocItemsForm,
        [key]: {
          ...tocEditForm.tocItemsForm[key],
          ...value,
          isProviderValid,
          isLosValid,
        },
      })
    );
  };

  const onChangeVisitCount = (key: string, value: string) => {
    dispatch(setIsTocValueChanged(true));
    dispatch(setTocEditForm({ ...tocEditForm, facilityError: "", isTocItemsFormDirty: true, isFormEmpty: false }));

    const location = tocEditForm.tocItemsForm[key];
    let [isProviderValid, isLosValid] = [true, true];
    [isProviderValid, isLosValid] = checkForLocationError(tocEditForm.tocItemsForm[key].providerId, value, key);
    dispatch(
      setTocItemsForm({
        ...tocEditForm.tocItemsForm,
        [key]: {
          ...tocEditForm.tocItemsForm[key],
          los: value,
          isLosValid,
          isProviderValid,
        },
      })
    );
  };

  const handleHomeWithoutNoServices = () => {
    dispatch(resetLocationValidation());
    dispatch(setIsTocValueChanged(true));
    dispatch(
      setTocEditForm({
        ...tocEditForm,
        isHomeWithNoServicesEnabled: !tocEditForm.isHomeWithNoServicesEnabled,
        isTocItemsFormDirty: true,
        isFormEmpty: false,
        facilityError: "",
      })
    );
  };

  const checkIfAcuteLosValid = (value: string) => {
    if (!value) {
      return false;
    }
    const acuteLosNum = parseInt(value, 10);
    if (acuteLosNum < 1 || acuteLosNum > 50) {
      return false;
    }
    return true;
  };

  const checkIfNoteValid = () => {
    if (tocEditForm.navigatorNotes) {
      return tocEditForm.navigatorNotes.length <= NOTE_LIMIT;
    }
    return true;
  };

  const checkIfLocationsValid = () => {
    let isValid = false;
    Object.values(tocEditForm.tocItemsForm).forEach((location) => {
      isValid = isValid || (!!location.providerId && !!location.providerName);
    });
    return isValid;
  };

  const checkIfLosForLocationsValid = () => {
    let isValid = true;
    Object.entries(tocEditForm.tocItemsForm).forEach(([key, location]: [string, ILocation]) => {
      if (location.providerId && location.providerName) {
        const isLosValid =
          !!location.los &&
          0 < parseInt(location.los, 10) &&
          parseInt(location.los, 10) <= LOS_LIMIT[key.toUpperCase()];
        isValid = isValid && isLosValid;
      }
    });
    return isValid;
  };

  const checkIfTocItemsValid = () => {
    let isValid = true;
    Object.entries(tocEditForm.tocItemsForm).forEach(([key, location]) => {
      if (location.providerId !== -1 || !isEmptyString(location.los)) {
        const bothExists = location.providerId !== -1 && !isEmptyString(location.los);
        isValid = isValid && bothExists;
      }
    });

    return isValid;
  };

  const checkMandatoryValues = () => {
    const isActuteLosValid = checkIfAcuteLosValid(tocEditForm.acuteLos.value);
    dispatch(
      setTocEditForm({
        ...tocEditForm,
        acuteLos: { ...tocEditForm.acuteLos, error: isActuteLosValid ? "" : ACUTE_LOS_ERROR },
        facilityError: "",
      })
    );

    const isNoteValid = checkIfNoteValid();
    if (tocEditForm.isHomeWithNoServicesEnabled) {
      return isActuteLosValid && isNoteValid;
    }

    let isTocFormEmpty = true;
    Object.values(tocEditForm.tocItemsForm).forEach((location) => {
      isTocFormEmpty = isTocFormEmpty && !location.los && location.providerId === -1;
    });
    if (isTocFormEmpty) {
      dispatch(
        setTocEditForm({
          ...tocEditForm,
          acuteLos: { ...tocEditForm.acuteLos, error: isActuteLosValid ? "" : ACUTE_LOS_ERROR },
          facilityError: FACILITY_ERROR,
        })
      );
      return false;
    } else {
      dispatch(
        setTocEditForm({
          ...tocEditForm,
          acuteLos: { ...tocEditForm.acuteLos, error: isActuteLosValid ? "" : ACUTE_LOS_ERROR },
          facilityError: "",
        })
      );
    }

    const isLocationsValid = checkIfLocationsValid();
    const areLosForLocationsValid = checkIfLosForLocationsValid();
    const isTocItemsValid = checkIfTocItemsValid();

    return isNoteValid && isActuteLosValid && isLocationsValid && isTocItemsValid && areLosForLocationsValid;
  };

  useEffect(() => {
    if (isExpanded && !!approvedAt && isModified && !history) {
      dispatch(getTocHistory(id));
    }
  }, [id, isExpanded, approvedAt, isModified, history, dispatch]);

  const submitNewToc = () => {
    dispatch(setTocEditForm({ ...tocEditForm, isSubmitModalOpen: false }));
    const pendingStatus = statuses.find((el) => el.status === TocStatusEnum.PENDING);
    const payload = {
      intakeId,
      taskId: currentTab?.taskId,
      clientId,
      anticipatedAcuteLOS: parseInt(tocEditForm.acuteLos.value, 10),
      noteCareManager: tocEditForm.navigatorNotes ? tocEditForm.navigatorNotes.trim() : null,
      additionalNote: tocEditForm.additionalNotes ? tocEditForm.additionalNotes.trim() : null,
      version,
      reasonId,
      statusId: pendingStatus?.id,
    } as ICreateNewTocPayload;

    const newTocItems: ICreateTransitionOfCareItem[] = [];

    if (tocEditForm.isHomeWithNoServicesEnabled) {
      const tocItem = {
        pacTypeName: PAC_TYPE.HOME_SERVICE,
        providerID: 0,
        targetLOS: 0,
      } as ICreateTransitionOfCareItem;
      newTocItems.push(tocItem);
    } else {
      Object.values(tocEditForm.tocItemsForm).forEach(({ pacTypeName, providerId, los }: ILocation) => {
        if (providerId !== -1 && los) {
          const item: ICreateTransitionOfCareItem = {
            pacTypeName,
            providerID: providerId,
            targetLOS: parseInt(los, 10),
          };
          newTocItems.push(item);
        }
      });
    }

    payload.transitionOfCareItems = newTocItems;
    dispatch(setIsTocsLoading(true));
    appDispatch(createTocAsync(payload))
      .unwrap()
      .then(() => {
        dispatch(setIsTocValueChanged(false));
        dispatch(setIsCurrentTaskDirty({ taskId, isDirty: false }));
        dispatch(setLoadTaskList(true));
        dispatch(getTocAsync(intakeId.toString()));
        dispatch(resetTocEditForm());
        if (redirectParams) {
          toast.success("TOC added successfully", {
            containerId: "main",
          });
        } else {
          appDispatch(setShouldPerformPostSubmitAction({ taskId, performAction: true }));
        }
      });
  };

  const editToc = () => {
    dispatch(setTocEditForm({ ...tocEditForm, isSubmitModalOpen: false }));
    const payload = {
      id,
      anticipatedAcuteLOS: parseInt(tocEditForm.acuteLos.value, 10),
      noteCareManager: tocEditForm.navigatorNotes,
      additionalNote: tocEditForm.additionalNotes ? tocEditForm.additionalNotes.trim() : null,
      statusId,
    } as IEditTocPayload;

    const updateTocItems: IEditTransitionOfCareItem[] = [];

    if (tocEditForm.isHomeWithNoServicesEnabled) {
      const existingTocItem = transitionOfCareItems?.find((el) => el.pacTypeName === PAC_TYPE.HOME_SERVICE);
      const tocItem = {
        pacTypeName: PAC_TYPE.HOME_SERVICE,
        providerID: 0,
        targetLOS: 0,
        id: existingTocItem ? existingTocItem.id : -1,
      } as IEditTransitionOfCareItem;
      updateTocItems.push(tocItem);
    } else {
      Object.values(tocEditForm.tocItemsForm).forEach(({ id: tocItemId, pacTypeName, providerId, los }: ILocation) => {
        if (providerId !== -1 && los) {
          const item: IEditTransitionOfCareItem = {
            pacTypeName,
            providerID: providerId,
            targetLOS: parseInt(los, 10),
            id: !tocItemId ? "-1" : tocItemId,
          };
          updateTocItems.push(item);
        }
      });
    }

    payload.transitionOfCareItems = updateTocItems;
    dispatch(setIsTocsLoading(true));
    appDispatch(editTocAsync(payload))
      .unwrap()
      .then(() => {
        dispatch(setIsTocValueChanged(false));
        dispatch(setIsCurrentTaskDirty({ taskId, isDirty: false }));
        dispatch(setLoadTaskList(true));
        dispatch(getTocAsync(intakeId.toString()));
        dispatch(resetTocEditForm());
      });
  };

  const clearFormDetails = () => {
    dispatch(setIsTocValueChanged(false));
    dispatch(setIsCurrentTaskDirty({ taskId, isDirty: false }));
    dispatch(
      setTocEditForm({
        ...tocEditForm,
        acuteLos: { value: "", error: "" },
        navigatorNotes: "",
        isClearModalOpen: false,
        isTocItemsFormDirty: false,
        isFormEmpty: true,
        facilityError: "",
        isHomeWithNoServicesEnabled: false,
      })
    );
    dispatch(resetTocItemsForm());
  };

  const resetFormDetails = () => {
    dispatch(setIsTocValueChanged(false));
    dispatch(setIsCurrentTaskDirty({ taskId, isDirty: false }));
    dispatch(
      setTocEditForm({
        ...tocEditForm,
        navigatorNotes: noteCareManager,
        isCancelModalOpen: false,
        isTocItemsFormDirty: false,
        isFormEmpty: true,
        isHomeWithNoServicesEnabled: false,
        facilityError: "",
      })
    );
    const updatedTocs = tocs.map((t) => {
      if (t.id === id) {
        return { ...t, isEditModeOn: false };
      }
      return { ...t };
    });
    dispatch(updateTocList(updatedTocs));
  };

  const getLosVisitsError = (facilityName: string) => {
    return (
      <span>
        <img src={ErrorIcon} alt="Error" />
        {LOS_VISIT_ERROR + LOS_LIMIT[facilityName]}
      </span>
    );
  };

  const getProviderError = (facilityName: string) => {
    return (
      <span>
        <img src={ErrorIcon} alt="Error" />
        {`Enter a facility for ${facilityName}`}
      </span>
    );
  };

  const handleTocExpand = () => {
    const tocId = id;
    const newTocs: ITransitionOfCare[] = [];
    if (toc.approvedAt) {
      tocs.forEach((item: any) => {
        const updatedItem = { ...item };
        if (item.approvedAt) {
          if (item.id === tocId) {
            updatedItem.isExpanded = !item.isExpanded;
          } else {
            updatedItem.isExpanded = false;
          }
        }

        newTocs.push(updatedItem as ITransitionOfCare);
      });
    } else {
      tocs.forEach((item: any) => {
        const updatedItem = { ...item };
        if (!item.approvedAt) {
          if (item.id === tocId) {
            updatedItem.isExpanded = !item.isExpanded;
          }
        }

        newTocs.push(updatedItem as ITransitionOfCare);
      });
    }
    dispatch(updateTocList(newTocs));
  };

  const enableEditMode = () => {
    if (!isEditModeOn) {
      const updatedTocs = tocs.map((t) => {
        if (t.id === id) {
          dispatch(
            setNavigatorAndAdditionalNotes({
              navigatorNotes: t.noteCareManager,
              additionalNotes: t.additionalNote,
            })
          );
          return { ...t, isEditModeOn: true };
        }
        return { ...t };
      });
      dispatch(updateTocList(updatedTocs));
    }
    if (!isExpanded) {
      handleTocExpand();
    }
  };

  const getRevisionReseaon = () => {
    return revisionReasons.find((el) => el.id === reasonId)?.reason;
  };

  const getErrorClass = (field: ILocation) => {
    if (isSubmitButtonPressed && !field.isLosValid) {
      return "input-error";
    }
    return "";
  };

  const isPrefferedFacility = (facilityId?: number) => {
    const preferredFacility = facilities.find((item) => item.id === facilityId)?.preferredProvider;
    return preferredFacility;
  };

  const renderTocDetailsInReadMode = () => {
    let facilityName = "-";
    const facility = facilities.find((el) => el.id === intake?.facilityId);
    if (facility) {
      facilityName = facility.providerName;
    }

    const irf = getTocItem(PAC_TYPE.IRF);
    const snf = getTocItem(PAC_TYPE.SNF);
    const opt = getTocItem(PAC_TYPE.OPT);
    const mpt = getTocItem(PAC_TYPE.MPT);
    const hh = getTocItem(PAC_TYPE.HH);
    const homeWithNoServices = getTocItem(PAC_TYPE.HOME_SERVICE);
    return (
      <>
        <div className={`toc-approve-info-readonly `}>
          <div className="toc-content">
            <div className="toc-plan">
              <div className="episode-details">
                <div className="toc-plan-heading">TOC Plan</div>
                <div className="toc-plan-los-container">
                  <div className="acute-los-heading">Acute LOS</div>
                  <div className="acute-los-heading-sep">-</div>
                  <div className="acute-los-readonly">{anticipatedAcuteLOS.toString()}</div>
                </div>
              </div>
            </div>
            <div className="location-grid-container">
              <div className="location-dropdowns">
                <div className="location-row-header">
                  <div className="location">Location</div>
                  <div className="location-name">
                    <div>Location Name</div>
                  </div>
                  <div className="los">LOS/Visits</div>
                </div>

                <div className="location-row">
                  <div className="location-value">IRF</div>
                  <div className="toc-dropdown-readonly">
                    {irf ? getProviderName(irf.providerId) : "-"}
                    {isPrefferedFacility(irf?.providerId) && <Preferred />}
                  </div>
                  <div className="toc-visits-readonly">{irf ? getTargetLos(irf.targetLos) : "-"}</div>
                </div>

                <div className="location-row">
                  <div className="location-value">SNF</div>
                  <div className="toc-dropdown-readonly">
                    {snf ? getProviderName(snf.providerId) : "-"}{" "}
                    {isPrefferedFacility(snf?.providerId) && <Preferred />}
                  </div>
                  <div className="toc-visits-readonly">{snf ? getTargetLos(snf.targetLos) : "-"}</div>
                </div>

                <div className="location-row">
                  <div className="location-value">HH</div>
                  <div className="toc-dropdown-readonly">
                    {hh ? getProviderName(hh.providerId) : "-"} {isPrefferedFacility(hh?.providerId) && <Preferred />}
                  </div>
                  <div className="toc-visits-readonly">{hh ? getTargetLos(hh.targetLos) : "-"}</div>
                </div>

                <div className="location-row">
                  <div className="location-value">M-PT</div>
                  <div className="toc-dropdown-readonly">
                    {mpt ? getProviderName(mpt.providerId) : "-"}{" "}
                    {isPrefferedFacility(mpt?.providerId) && <Preferred />}
                  </div>
                  <div className="toc-visits-readonly">{mpt ? getTargetLos(mpt.targetLos) : "-"}</div>
                </div>

                <div className="location-row">
                  <div className="location-value">OPT</div>
                  <div className="toc-dropdown-readonly">
                    {opt ? getProviderName(opt.providerId) : "-"}{" "}
                    {isPrefferedFacility(opt?.providerId) && <Preferred />}
                  </div>
                  <div className="toc-visits-readonly">{opt ? getTargetLos(opt.targetLos) : "-"}</div>
                </div>
              </div>

              {homeWithNoServices ? (
                <div className="checkbox-container">
                  <div className="checkboxes" key="Incoming-checkox">
                    <Checkbox
                      name="homeWithoutServices"
                      value="Home w/No services"
                      id="Incoming-checkox"
                      onChange={() => {}}
                      isDisabled
                      isChecked={true}
                    />
                  </div>
                </div>
              ) : null}

              {!!approvedAt && isModified ? (
                <div className="view-changes-container">
                  <span
                    className="cta"
                    onClick={() => dispatch(setTocEditForm({ ...tocEditForm, isChangeModalOpen: true }))}
                  >
                    View Changes
                  </span>
                </div>
              ) : null}
            </div>
          </div>

          <div className="note-container">
            {reasonId && (
              <div className="revision-reason">
                <div className="note-heading">Reason for revising TOC</div>
                <div className="value">{getRevisionReseaon()}</div>
              </div>
            )}
            <div className="external-notes">
              <div className="navigator-notes">
                <div className="note-heading">Navigator Notes</div>
                <div className="note-textarea-readonly">
                  {!!noteCareManager && noteCareManager.length ? noteCareManager : "-"}
                </div>
              </div>
              {approvedAt ? (
                <div className="physician-notes">
                  <div className="note-heading">Physician Notes</div>
                  <div className="note-textarea-readonly">
                    {!!notePhysician && notePhysician.length ? notePhysician : "-"}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="internal-notes">
              <div className="note-heading">Additional Notes {"(Internal)"}</div>
              <div className="note-textarea-readonly">
                {!!additionalNote && additionalNote.length ? additionalNote : "-"}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderTocDetailsInEditMode = () => {
    let facilityName = "-";
    const facility = facilities.find((el) => el.id === intake?.facilityId);
    if (facility) {
      facilityName = facility.providerName;
    }
    const { irf, snf, hh, opt, mpt } = tocEditForm.tocItemsForm;
    return (
      <>
        <div className={`toc-approve-info `}>
          <div className="toc-plan">
            <div className="episode-details">
              <div className="toc-plan-heading">TOC Plan</div>
              <div className="toc-plan-los-container">
                <div className="acute-los-heading">Acute LOS</div>
                <div className="acute-los">
                  <input
                    className={`actue-los-input ${!tocEditForm.acuteLos.error ? null : "input-error"}`}
                    type="string"
                    placeholder="0"
                    name="acute-los"
                    value={tocEditForm.acuteLos.value}
                    maxLength={2}
                    onChange={(e: any) => {
                      if ((validateNumber(e.target.value) || e.target.value === "") && e.target.value !== "0") {
                        onChangeAcuteLos(e);
                      }
                    }}
                    onKeyDown={(e) => {
                      if (["e", ".", "-", "+"].includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="toc-plan-error">
              {!tocEditForm.acuteLos.error ? null : (
                <span>
                  <img src={ErrorIcon} alt="Error" />
                  {tocEditForm.acuteLos.error}
                </span>
              )}
              {!tocEditForm.facilityError ? null : (
                <span>
                  <img src={ErrorIcon} alt="Error" />
                  {tocEditForm.facilityError}
                </span>
              )}
            </div>
          </div>
          <div className="note-container">
            {reasonId && (
              <div className="revision-reason">
                <div className="note-heading">Reason for revising TOC</div>
                <div className="value">{getRevisionReseaon()}</div>
              </div>
            )}
            <div className="navigator-notes">
              <div className="note-heading">Navigator Notes</div>
              <textarea
                className="note-textarea"
                value={tocEditForm.navigatorNotes ?? ""}
                onChange={(e: any) => {
                  onNavigatorNotesChange(e.target.value);
                }}
                maxLength={2000}
                placeholder="Write your notes here for the physician..."
                cols={5}
              />
              {tocEditForm.navigatorNotes && tocEditForm.navigatorNotes.length > 0 && (
                <div className="note-hint">
                  <div className="note-limit">{`${tocEditForm.navigatorNotes.length}/${2000}`}</div>
                </div>
              )}
            </div>
            <div className="additinal-notes">
              <div className="note-heading">Additional Notes {"(Internal)"}</div>
              <textarea
                className="note-textarea"
                value={tocEditForm.additionalNotes ?? ""}
                onChange={(e: any) => {
                  onAdditionalNotesChange(e.target.value);
                }}
                maxLength={2000}
                placeholder="Write your notes here for internal use..."
                cols={5}
              />
              {tocEditForm.additionalNotes && tocEditForm.additionalNotes?.length > 0 && (
                <div className="note-hint">
                  <div className="note-limit">{`${tocEditForm.additionalNotes?.length}/${2000}`}</div>
                </div>
              )}
            </div>
          </div>
          <div className="location-grid-container">
            <div className="location-dropdowns">
              <div className="location-row-header">
                <div className="location">Location</div>
                <div className="location-name">
                  <div>Location Name</div>
                </div>
                <div className="los">LOS/Visits</div>
              </div>

              <div className="location-row">
                <div className="location-content">
                  <div className="location-value">IRF</div>
                  <div className="toc-dropdown">
                    <FacilityDropdown
                      selectedOptionId={irf.providerId}
                      isError={!irf.isProviderValid && isSubmitButtonPressed}
                      facilities={facilities}
                      onChange={(providerId: number, providerName: string) => {
                        onFacilityChange("irf", { providerId, providerName });
                      }}
                      isDisabled={tocEditForm.isHomeWithNoServicesEnabled}
                    />
                  </div>
                  <div className="toc-visits">
                    <input
                      type="string"
                      placeholder="0"
                      name={PAC_TYPE.IRF}
                      value={irf.los}
                      maxLength={2}
                      onChange={(e: any) => {
                        if ((validateNumber(e.target.value) || e.target.value === "") && e.target.value !== "0") {
                          onChangeVisitCount("irf", e.target.value);
                        }
                      }}
                      disabled={tocEditForm.isHomeWithNoServicesEnabled}
                      className={`visits-input  ${getErrorClass(irf)}`}
                      onKeyDown={(e) => {
                        if (["e", ".", "-", "+"].includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="location-error">
                  {!irf.isProviderValid && !tocEditForm.isHomeWithNoServicesEnabled && isSubmitButtonPressed
                    ? getProviderError(VISIT_TYPE.irf)
                    : null}
                  {!irf.isLosValid && !tocEditForm.isHomeWithNoServicesEnabled && isSubmitButtonPressed
                    ? getLosVisitsError(VISIT_TYPE.irf)
                    : null}
                </div>
              </div>

              <div className="location-row">
                <div className="location-content">
                  <div className="location-value">SNF</div>
                  <div className="toc-dropdown">
                    <FacilityDropdown
                      selectedOptionId={snf.providerId}
                      isError={!snf.isProviderValid && isSubmitButtonPressed}
                      facilities={facilities}
                      onChange={(providerId: number, providerName: string) => {
                        onFacilityChange("snf", { providerId, providerName });
                      }}
                      isDisabled={tocEditForm.isHomeWithNoServicesEnabled}
                    />
                  </div>
                  <div className="toc-visits">
                    <input
                      type="string"
                      placeholder="0"
                      name={PAC_TYPE.SNF}
                      value={snf.los}
                      maxLength={2}
                      onChange={(e: any) => {
                        if ((validateNumber(e.target.value) || e.target.value === "") && e.target.value !== "0") {
                          onChangeVisitCount("snf", e.target.value);
                        }
                      }}
                      className={`visits-input ${getErrorClass(snf)}`}
                      disabled={tocEditForm.isHomeWithNoServicesEnabled}
                      onKeyDown={(e) => {
                        if (["e", ".", "-", "+"].includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="location-error">
                  {!snf.isProviderValid && !tocEditForm.isHomeWithNoServicesEnabled && isSubmitButtonPressed
                    ? getProviderError(VISIT_TYPE.snf)
                    : null}
                  {!snf.isLosValid && !tocEditForm.isHomeWithNoServicesEnabled && isSubmitButtonPressed
                    ? getLosVisitsError(VISIT_TYPE.snf)
                    : null}
                </div>
              </div>

              <div className="location-row">
                <div className="location-content">
                  <div className="location-value">HH</div>
                  <div className="toc-dropdown">
                    <FacilityDropdown
                      selectedOptionId={hh.providerId}
                      isError={!hh.isProviderValid && isSubmitButtonPressed}
                      facilities={facilities}
                      onChange={(providerId: number, providerName: string) => {
                        onFacilityChange("hh", { providerId, providerName });
                      }}
                      isDisabled={tocEditForm.isHomeWithNoServicesEnabled}
                    />
                  </div>
                  <div className="toc-visits">
                    <input
                      type="string"
                      placeholder="0"
                      name={PAC_TYPE.HH}
                      value={hh.los}
                      maxLength={2}
                      onChange={(e: any) => {
                        if ((validateNumber(e.target.value) || e.target.value === "") && e.target.value !== "0") {
                          onChangeVisitCount("hh", e.target.value);
                        }
                      }}
                      className={`visits-input  ${getErrorClass(hh)}`}
                      disabled={tocEditForm.isHomeWithNoServicesEnabled}
                      onKeyDown={(e) => {
                        if (["e", ".", "-", "+"].includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="location-error">
                  {!hh.isProviderValid && !tocEditForm.isHomeWithNoServicesEnabled && isSubmitButtonPressed
                    ? getProviderError(VISIT_TYPE.hh)
                    : null}
                  {!hh.isLosValid && !tocEditForm.isHomeWithNoServicesEnabled && isSubmitButtonPressed
                    ? getLosVisitsError(VISIT_TYPE.hh)
                    : null}
                </div>
              </div>

              <div className="location-row">
                <div className="location-content">
                  <div className="location-value">M-PT</div>
                  <div className="toc-dropdown">
                    <FacilityDropdown
                      selectedOptionId={mpt.providerId}
                      isError={!mpt.isProviderValid && isSubmitButtonPressed}
                      facilities={facilities}
                      onChange={(providerId: number, providerName: string) => {
                        onFacilityChange("mpt", { providerId, providerName });
                      }}
                      isDisabled={tocEditForm.isHomeWithNoServicesEnabled}
                    />
                  </div>
                  <div className="toc-visits">
                    <input
                      type="string"
                      placeholder="0"
                      name={PAC_TYPE.MPT}
                      value={mpt.los}
                      maxLength={2}
                      onChange={(e: any) => {
                        if ((validateNumber(e.target.value) || e.target.value === "") && e.target.value !== "0") {
                          onChangeVisitCount("mpt", e.target.value);
                        }
                      }}
                      className={`visits-input  ${getErrorClass(mpt)}`}
                      disabled={tocEditForm.isHomeWithNoServicesEnabled}
                      onKeyDown={(e) => {
                        if (["e", ".", "-", "+"].includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="location-error">
                  {!mpt.isProviderValid && !tocEditForm.isHomeWithNoServicesEnabled && isSubmitButtonPressed
                    ? getProviderError("M-PT")
                    : null}
                  {!mpt.isLosValid && !tocEditForm.isHomeWithNoServicesEnabled && isSubmitButtonPressed
                    ? getLosVisitsError(VISIT_TYPE.mpt)
                    : null}
                </div>
              </div>

              <div className="location-row">
                <div className="location-content">
                  <div className="location-value">OPT</div>
                  <div className="toc-dropdown">
                    <FacilityDropdown
                      selectedOptionId={opt.providerId}
                      isError={!opt.isProviderValid && isSubmitButtonPressed}
                      facilities={facilities}
                      onChange={(providerId: number, providerName: string) => {
                        onFacilityChange("opt", { providerId, providerName });
                      }}
                      isDisabled={tocEditForm.isHomeWithNoServicesEnabled}
                    />
                  </div>
                  <div className="toc-visits">
                    <input
                      type="string"
                      placeholder="0"
                      name={PAC_TYPE.OPT}
                      value={opt.los}
                      maxLength={2}
                      onChange={(e: any) => {
                        if ((validateNumber(e.target.value) || e.target.value === "") && e.target.value !== "0") {
                          onChangeVisitCount("opt", e.target.value);
                        }
                      }}
                      className={`visits-input  ${getErrorClass(opt)}`}
                      disabled={tocEditForm.isHomeWithNoServicesEnabled}
                      onKeyDown={(e) => {
                        if (["e", ".", "-", "+"].includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="location-error">
                  {!opt.isProviderValid && !tocEditForm.isHomeWithNoServicesEnabled && isSubmitButtonPressed
                    ? getProviderError(VISIT_TYPE.opt)
                    : null}
                  {!opt.isLosValid && !tocEditForm.isHomeWithNoServicesEnabled && isSubmitButtonPressed
                    ? getLosVisitsError(VISIT_TYPE.opt)
                    : null}
                </div>
              </div>
            </div>

            <div className="checkbox-container">
              <div className="checkboxes" key="Incoming-checkox">
                <Checkbox
                  name="homeWithoutServices"
                  value="Home w/No services"
                  id="Incoming-checkox"
                  onChange={handleHomeWithoutNoServices}
                  isChecked={tocEditForm.isHomeWithNoServicesEnabled}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="last-row-with-approve-button">
          <div className="cta-container">
            {status === TocStatusEnum.PENDING ? (
              <Button
                text="Cancel"
                className={`white-button cancel-button`}
                showLoader={false}
                disabled={!isEditModeOn}
                onClick={() => {
                  dispatch(setTocEditForm({ ...tocEditForm, isCancelModalOpen: true }));
                }}
              />
            ) : null}
            <Button
              text="Clear"
              className={`white-button clear-button`}
              showLoader={false}
              disabled={!tocEditForm.isTocItemsFormDirty || tocEditForm.isFormEmpty}
              onClick={() => {
                dispatch(
                  setTocEditForm({
                    ...tocEditForm,
                    isClearModalOpen: true,
                  })
                );
              }}
            />
            <Button
              text="Submit"
              className={`green-button submit-button `}
              showLoader={false}
              disabled={!tocEditForm.isTocItemsFormDirty}
              onClick={() => {
                setIsSubmitButtonPressed(true);
                if (checkMandatoryValues()) {
                  dispatch(setTocEditForm({ ...tocEditForm, isSubmitModalOpen: true }));
                }
              }}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {tocEditForm.isSubmitModalOpen && isEditModeOn ? (
        <GenericPopupModal
          submitButtonTitle={"Confirm"}
          content={"Are you sure, you want to Submit?"}
          isOpen={tocEditForm.isSubmitModalOpen}
          handleCancelClick={() => {
            dispatch(setTocEditForm({ ...tocEditForm, isSubmitModalOpen: false }));
          }}
          handleSubmitClick={() => {
            dispatch(setIsTocValueChanged(false));
            dispatch(setIsCurrentTaskDirty({ taskId, isDirty: false }));
            if (id) {
              editToc();
            } else {
              submitNewToc();
            }
          }}
          cancelButtonVisible
        ></GenericPopupModal>
      ) : null}

      {tocEditForm.isClearModalOpen && tocEditForm.isTocItemsFormDirty && isEditModeOn ? (
        <GenericPopupModal
          submitButtonTitle={"Clear"}
          content={"Are you sure, you want to Clear?"}
          isOpen={tocEditForm.isClearModalOpen}
          handleCancelClick={() => {
            dispatch(
              setTocEditForm({
                ...tocEditForm,
                isClearModalOpen: false,
              })
            );
            dispatch(setIsCurrentTaskDirty({ taskId, isDirty: false }));
          }}
          handleSubmitClick={() => {
            clearFormDetails();
          }}
          cancelButtonVisible
        ></GenericPopupModal>
      ) : null}
      {tocEditForm.isCancelModalOpen && isEditModeOn ? (
        <GenericPopupModal
          submitButtonTitle={"OK"}
          content={"Are you sure, you want to Cancel?"}
          isOpen={tocEditForm.isCancelModalOpen}
          handleCancelClick={() => {
            dispatch(setTocEditForm({ ...tocEditForm, isCancelModalOpen: false }));
          }}
          handleSubmitClick={() => {
            dispatch(setTocEditForm({ ...tocEditForm, isCancelModalOpen: false }));
            // handleTocExpand();
            resetFormDetails();
          }}
          cancelButtonVisible
        ></GenericPopupModal>
      ) : null}
      {tocEditForm.isChangeModalOpen && !!history ? (
        <TocChangeModal
          isOpen={tocEditForm.isChangeModalOpen}
          facilities={facilities}
          onClose={(isOpen) => dispatch(setTocEditForm({ ...tocEditForm, isChangeModalOpen: isOpen }))}
          historyOfToc={history}
          toc={toc}
        />
      ) : null}
      <div
        onMouseEnter={() => setIsRowHovered(true)}
        onMouseLeave={() => setIsRowHovered(false)}
        className={`toc-row ${isRowHovered ? "hovered" : ""} ${isExpanded ? "opened" : ""}`}
      >
        <div className="toc-cell">{version === 1 ? "Original TOC" : `Revision ${version - 1}`}</div>
        {status === TocStatusEnum.NEW ? (
          <div className="toc-cell">{"-"}</div>
        ) : (
          <div className="toc-cell">
            <div className="created-date">{moment(createdAt).format("MM/DD/YYYY : hh:mm A")} </div>
            <div className="created-by">{createdBy ? "by " + createdBy : "-"}</div>
          </div>
        )}
        <div className={`toc-cell ${status === TocStatusEnum.PENDING ? "pending-status" : null}`}>{status}</div>
        {approvedAt ? (
          <div className="toc-cell">
            <div className="approved-date">{moment(approvedAt).format("MM/DD/YYYY : hh:mm A")} </div>
            <div className="approved-by">{"by " + (approvedBy ? approvedBy : "-")}</div>
          </div>
        ) : (
          <div className="toc-cell">{"-"}</div>
        )}
        <div className={`toc-cell icons ${isRowHovered || isExpanded ? "visible" : "hidden"}`}>
          {isRowHovered && status === TocStatusEnum.PENDING ? (
            <>
              {!isEditModeOn ? (
                <>
                  <span
                    className="icon-background edit-button"
                    data-tip
                    data-for={"Edit"}
                    onClick={() => enableEditMode()}
                  >
                    <Icon icon="edit" size={10} />
                    <CustomToolTip text="Edit" id="Edit" place="left" />
                  </span>
                </>
              ) : null}
            </>
          ) : null}
          {isExpanded ? (
            <Button
              icon="up-arrow"
              onClick={() => {
                handleTocExpand();
              }}
              buttonSize={10}
              className="icon-background"
            />
          ) : (
            <Button
              icon="down-arrow"
              onClick={() => {
                handleTocExpand();
              }}
              buttonSize={10}
              className="icon-background"
            />
          )}
        </div>
      </div>
      {isExpanded ? (
        <>
          {approvedAt ? (
            renderTocDetailsInReadMode()
          ) : (
            <>{isEditModeOn ? renderTocDetailsInEditMode() : renderTocDetailsInReadMode()}</>
          )}
        </>
      ) : null}
    </>
  );
};

export default TocRevision;
