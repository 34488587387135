export enum EpisodeDetailsTab {
  TASKS = "Tasks",
  PROCEDURE_DETAILS = "Procedure Details",
  PATIENT_DEMOGRAPHICS = "Patient Demographics",
  ACTIVITY_HISTORY = "Activity History",
  PLAN_OF_CARE = "Plan of Care",
  MISC = "Misc",
}

export enum EpisodesPageCommonMessages {
  COMING_SOON = "Coming Soon...",
  NO_EPISODES_FOUND = "Oops! No episodes found",
  NO_EPISODE_TASKS_FOUND = "Oops! No tasks found",
  NO_UPCOMING_TASKS_FOUND = "Oops! No upcoming tasks for this patient",
  NO_ACTIVITY_FOUND = "Oops! No Activity found",
  NO_PLAN_OF_CARE_FOUND = "Oops! Plan of Care doesn’t exist",
}
