import "./preview-risk-assessment-results.styles.scss";
import { QuestionAnswers } from "./preview-risk-assessment.types";

type ReviewRiskAssessmentResultsProps = {
  data: QuestionAnswers[];
  title: string;
};

const Row = ({ item }: { item: QuestionAnswers }) => {
  if (item.question && item.answer) {
    return (
      <>
        <tr>
          <td>
            <div className="question">{item.question}</div>
            <div className="answer">{item.answer}</div>
          </td>
          <td className="score">{item.ignoreScore ? "-" : item.score}</td>
        </tr>
        {item.extraInfo && (
          <tr className="extra-info-tr">
            {item.extraInfo.title && (
              <td>
                <div className="extra-info">
                  <div className="extra-info-title">{item.extraInfo.title}</div>
                  <div className="extra-info-values">
                    {item.extraInfo.value &&
                      item.extraInfo.value.length > 0 &&
                      item.extraInfo.value.map((v, index) => {
                        return <div key={v}>{v}</div>;
                      })}
                  </div>
                </div>
              </td>
            )}
            <td></td>
          </tr>
        )}
      </>
    );
  } else {
    return null;
  }
};

const PreviewRiskAssessmentResults = (props: ReviewRiskAssessmentResultsProps) => {
  const { data, title } = props;
  return (
    <div className="preview-section">
      <table className="preview-section-table">
        <thead>
          <th>
            <div className="preview-section-header">
              <div className="section-title">{title}</div>
            </div>
          </th>
          <th>Score</th>
        </thead>
        <tbody>
          {data &&
            data.length > 0 &&
            data.map((item: QuestionAnswers) => {
              return <Row key={item.id} item={item} />;
            })}
        </tbody>
      </table>
    </div>
  );
};

export default PreviewRiskAssessmentResults;
