import { TaskListPresentationProps } from "./task-list.type";
import Task from "./task/task.component";

const TaskListPresentation = (props: TaskListPresentationProps) => {
  const { tasks, searchText, activeTabName } = props;
  return (
    <div className="task-list">
      {tasks.map((item, index, taskArray) => {
        return (
          <Task
            key={item.id}
            {...item}
            index={index}
            totalLength={taskArray.length}
            searchText={searchText}
            activeTabName={activeTabName}
          />
        );
      })}
    </div>
  );
};

export default TaskListPresentation;
