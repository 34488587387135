export enum CommonStateType {
  ACCESS_DENIED,
  PERMISSION_DENIED,
  LOADING,
  PAGE_NOT_FOUND,
  INTAKE_NOT_FOUND,
  TASK_NOT_ADDED,
  NO_TASKS_AVAILABLE,
  NO_TASK_SELECTED,
  SEARCH_TASK_NOT_FOUND,
  NO_EPISODES_FOUND,
  EPISODE_TASK_COMING_SOON,
  EPISODE_NOT_FOUND_ON_SEARCH,
  NO_EPISODE_TASKS_FOUND,
  NO_TASK_MANGEMENT_TASK_AVAILABLE,
  NO_TASK_FOUND,
  NO_EPISODE_DETAILS_FOUND,
  NO_ACTIVITY_LOGS_FOUND,
  NO_ACTIVITY_HISTORY_FOUND,
  NO_PLAN_OF_CARE_LIST_FOUND,
  NO_PROVIDERS_FOUND,
}

export enum CommonStateMessages {
  INVALID_LINK = "The link might be broken or invalid. Please refresh the list page and try again.",
  PERMISSION_DENIED = "You do not have permission to access this page",
}
