import { createContext } from "react";
import { RiskAssessmentProps } from "../common/risk-assessment.types";

export const RiskAssessmentContext = createContext<RiskAssessmentProps>({
  onChangePill: () => {},
  onChangeDropdown: () => {},
  onSelectPill: () => {},
  onChangeCheckbox: () => {},
  onChangeDays: () => {},
  onChangeDate: () => {},
  onChangeText: () => {},
  onChangeAge: () => {},
});
