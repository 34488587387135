import { useState, useCallback, useEffect, useRef } from "react";
import { StatisticBoxProps } from "components/StatisticBox/props";
import StatisticBox from "components/StatisticBox/statistic-box.component";
import { navigatorStatsItems } from "shared/fakeData";
import "./manager-dashboard.styles.scss";
import Header from "components/Header/header.component";
import DatePicker from "components/DatePicker/date-picker.component";
import NavigatorTable from "./components/navigator-table/navigator-table.component";
import GenericTabs from "components/GenericTabs/generic-tabs.component";
import { useSelector } from "react-redux";
import {
  getColonSeparatedDuration,
  getDurationOfCall,
  getEndDate,
  getStartDate,
} from "shared/methods/utilityFunctions";
import moment from "moment";
import { NavigatorsTab } from "shared/enums";
import { ActiveTabName } from "components/GenericTabs/props";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import { getAuth } from "state/feature/auth/auth.slice";
import { getNavigatorState, resetManagerStats } from "state/feature/navigator/navigator.slice";
import { getNavigatorCallsAsync, getNavigatorStatsForManagerAsync } from "state/feature/navigator/navigator.action";
import { checkIfFeatureEnabledAsync } from "state/feature/common/common.action";
import { CommonStateType } from "components/common-state/common-state.enum";
import CommonState from "components/common-state/common-state.component";
import { getCommonState, setIsOpenDownloadFileModal } from "state/feature/common/common.slice";
import { managerDashboardTable } from "shared/constant/table";
import { SortingOrderType } from "shared/enums/sorting-types.enums";
import { ColumnType } from "pages/manager-dashboard/components/navigator-table/props";
import { useAppDispatch } from "state/store";
import { CSVLink } from "react-csv";
import Button from "components/Button/button.component";
import { CallingStatus } from "shared/enums/calling-status.enum";
import FileDownloadingPopupModal from "components/Modal/FileDownloadingPopupModal/file-downloading-popup-modal.component";
import { NavigatorCallLogsDownloadType } from "shared/types/call.type";
import { USER_ROLE } from "shared/constant/role-constant";
const ManagerDashboard = () => {
  useAxiosAuthenticated();
  const authState = useSelector(getAuth);
  const { isLoading } = useSelector(getNavigatorState);
  const { isInboundCalled, modal } = useSelector(getCommonState);
  const [isDatePickerOpen, setDatePickerOpen] = useState<boolean>(false);
  const [columnsHeader, setColumnsHeader] = useState(managerDashboardTable);
  const [isSortedColumn, setIsSortedColumn] = useState(false);
  const [columnSort, setColumnSort] = useState({ columnType: SortingOrderType.ASC, columnName: "name" });
  const [dateState, setDateState] = useState<any>({
    startDate: moment().subtract(7, "day").toDate(),
    endDate: new Date(),
    key: "selection",
  });

  const [activeTabName, setActiveTabName] = useState<ActiveTabName>(NavigatorsTab.ALL);
  const [navigatorStats, setNavigatorStats] = useState([]);
  const onClickTabName = (tabName: ActiveTabName) => {
    setActiveTabName(tabName);
  };
  const dispatch = useAppDispatch();
  const navigatorState = useSelector(getNavigatorState);
  const { navigatorsList } = navigatorState.managerStats;
  const csvLink = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
  const [navigatorCallsDownload, setNavigatorCallsDownload] = useState<Array<any>>([]);
  useEffect(() => {
    const controller = new AbortController();
    return () => {
      dispatch(resetManagerStats());
      controller?.abort();
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(checkIfFeatureEnabledAsync({ featureFlagName: "inboundCalls" }));
  }, [dispatch]);

  const getNavigatorStatsForManager = useCallback(
    async (
      startDateValue = dateState.startDate,
      endDateValue = dateState.endDate,
      columnName?: string,
      columnType?: SortingOrderType
    ) => {
      if (columnName && columnType) {
        setIsSortedColumn(true);
      }
      if (startDateValue && endDateValue) {
        const newColumnName = columnName ? columnName : columnSort.columnName;
        const newColumnType = columnType ? columnType : columnSort.columnType;
        dispatch(
          getNavigatorStatsForManagerAsync({
            managerId: authState.user.id,
            startDate: getStartDate(startDateValue),
            endDate: getEndDate(endDateValue),
            sortColumn: newColumnName,
            sortType: newColumnType,
          })
        ).then(() => {
          setIsSortedColumn(false);
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authState.user.id, dateState.startDate, dateState.endDate, dispatch]
  );

  useEffect(() => {
    if (isInboundCalled) {
      getNavigatorStatsForManager();
    }
  }, [getNavigatorStatsForManager, isInboundCalled]);

  const changeSort = (selectedColumn: ColumnType) => {
    const index = columnsHeader.findIndex((x) => x.key === selectedColumn.key);
    let sort: SortingOrderType = SortingOrderType.DEFAULT;
    switch (columnsHeader[index].sortOrder) {
      case SortingOrderType.DESC:
      case SortingOrderType.DEFAULT:
        sort = SortingOrderType.ASC;
        break;
      case SortingOrderType.ASC:
        sort = SortingOrderType.DESC;
        break;
    }
    const tempHeaders = [...columnsHeader];
    tempHeaders.forEach((header) => (header.sortOrder = SortingOrderType.DEFAULT));
    tempHeaders[index].sortOrder = sort;
    setColumnSort({ columnName: selectedColumn.key, columnType: sort });
    setColumnsHeader(tempHeaders);
    getNavigatorStatsForManager(dateState.startDate, dateState.endDate, selectedColumn.key, sort);
  };

  const prepareNavigatorStats = (navList: any) => {
    let statsResult = null;
    const navigatorStatsItemsInstance: StatisticBoxProps[] = JSON.parse(
      JSON.stringify(
        navigatorStatsItems.map((item) => {
          item.info = 0;
          return item;
        })
      )
    );
    if (navList && navList.length > 0) {
      navList.forEach((nav: any, index: number) => {
        statsResult = navigatorStatsItemsInstance.map((item: StatisticBoxProps) => {
          switch (item.code) {
            case "total-call-duration":
              item.value += nav.totalCallDuration;
              item.info = getColonSeparatedDuration(Number(item.value));
              break;
            case "missed-calls":
              const infoToInteger = parseInt(item.info);
              const missedCalls = infoToInteger + nav.missedCallsCount;
              item.info = missedCalls;
              break;
            case "voicemails":
              const voicemailInfo = parseInt(item.info);
              const voicemails = voicemailInfo + nav.pendingVoicemailsCount;
              item.info = voicemails;
              break;
            case "unread-messages":
              const unreadInfo = parseInt(item.info);
              const unreadMessages = unreadInfo + nav.unreadMessagesCount;
              item.info = unreadMessages;
              break;
          }
          return item;
        });
      });
      setNavigatorStats(statsResult as any);
    } else {
      statsResult = navigatorStatsItems.map((item) => {
        switch (item.code) {
          case "total-call-duration":
            item.info = getColonSeparatedDuration(Number(Math.round(0)));
            break;
          default:
            item.value = 0;
            item.info = "0";
            break;
        }
      });
      setNavigatorStats(statsResult as any);
    }
  };

  useEffect(() => {
    prepareNavigatorStats(navigatorsList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigatorsList]);

  const callAPIOnApply = (item: any) => {
    setDateState({ startDate: item[0].startDate, endDate: item[0].endDate, key: "selection" });
  };

  const handleApplyClick = (item: any) => {
    callAPIOnApply(item);
    setDatePickerOpen(false);
  };

  const handleClearButtonClick = (val: boolean) => {
    const start = moment().subtract(7, "day").startOf("day").toDate();
    const end = new Date(new Date().setHours(0, 0, 0, 0));
    setDateState({
      startDate: start,
      endDate: end,
      key: "selection",
    });
    setDatePickerOpen(val);
  };

  const handleDateRangeSelect = (item: any) => {
    setDateState([item]);
  };

  const getNavigatorList = () => {
    if (activeTabName === "Patient Navigators") {
      return navigatorsList.filter((item: any) => item.navigatorType === "PNAV");
    } else if (activeTabName === "Transition Navigators") {
      return navigatorsList.filter((item: any) => item.navigatorType === "TNAV");
    } else {
      return navigatorsList;
    }
  };

  const getConvertedToNavigatorCalls = (downloadedNavigatorCalls: Array<any>): Array<NavigatorCallLogsDownloadType> => {
    const convertedNavigatorCalls = downloadedNavigatorCalls.map((nav) => {
      const participantsNamesArr: Array<string> = [];
      const madeReceivedNavigatorsArr: Array<string> = [];
      nav.participants?.forEach((participant: any) => {
        if (participant?.type?.toLowerCase() === USER_ROLE.NAVIGATOR.toLowerCase()) {
          madeReceivedNavigatorsArr.push(participant?.name?.trim());
        } else {
          participantsNamesArr.push(participant?.name?.trim());
        }
      });

      const participantNames = participantsNamesArr.join(", ");
      const madeReceivedNavigators = madeReceivedNavigatorsArr.join(", ");
      const isIncomingCall = nav.participants?.find(
        (participant: any) =>
          participant.type?.toLowerCase() === USER_ROLE.NAVIGATOR.toLowerCase() && participant?.isCaller === true
      );
      let primaryNavigator = nav?.originalRecipient?.name ? nav.originalRecipient.name : "-";
      if (nav?.effectiveNavigator?.name) {
        primaryNavigator = nav.effectiveNavigator.name;
      }

      return {
        "Received/Made Call": madeReceivedNavigators,
        Type: isIncomingCall ? "Outbound" : "Inbound",
        "Other Participants": participantNames,
        "Call Date/Start Time": nav.callStartTime,
        "Call Duration": nav?.duration && nav.duration.length > 0 ? getDurationOfCall(Number(nav.duration)) : "-",
        "Primary Navigator": primaryNavigator,
        "Pri. Miss. Reason": nav.reasonForPrimaryUnavailability ? nav.reasonForPrimaryUnavailability : "-",
        "Secondary Recipient": nav?.effectiveBackupNavigator ? nav.effectiveBackupNavigator : "-",
        "Sec. Miss. Reason":
          nav?.reasonForBackupUnavailability && nav?.effectiveBackupNavigator ? nav.reasonForBackupUnavailability : "-",
        "On Call Hours": nav?.isOnCallNavigator ? "yes" : "no",
      };
    });
    return convertedNavigatorCalls;
  };

  useEffect(() => {
    if (csvLink && csvLink.current && csvLink.current?.link.click && navigatorCallsDownload.length > 0) {
      csvLink.current.link.click();
    }
  }, [navigatorCallsDownload]);

  const downloadExportToCsv = async () => {
    dispatch(setIsOpenDownloadFileModal(true));
    const res = await dispatch(
      getNavigatorCallsAsync({
        startDate: getStartDate(dateState.startDate),
        endDate: getEndDate(dateState.endDate),
        callDirection: "",
        callStatus: [],
        searchText: "",
        sortColumn: "calltime",
        sortOrder: SortingOrderType.DESC,
        offset: 0,
        navigatorId: "",
        limit: -1,
        isDownloadCallsClicked: true,
        isNavigatorManager: true,
      })
    );
    if (res.meta.requestStatus === "fulfilled") {
      const convertedPayload: NavigatorCallLogsDownloadType[] = getConvertedToNavigatorCalls(res.payload);
      setNavigatorCallsDownload(convertedPayload);
    }
  };

  return (
    <div id="dashboard-container">
      <FileDownloadingPopupModal
        isOpen={modal.isOpenDownloadFileModal}
        isLoading={navigatorState.isLoadingDownloadFile}
        isError={navigatorState.isErrorDownloadFile}
        handleCloseClick={() => {
          dispatch(setIsOpenDownloadFileModal(false));
        }}
        errorMessage={navigatorState.errorMessageDownload}
        isEmpty={navigatorCallsDownload.length === 0}
      />
      <Header className="dashboard">
        <div className="header-title">
          Hi, {authState.user.firstName} {authState.user.lastName}
        </div>
        <div className="status-and-date-picker home-page-datepicker">
          <div>
            <Button
              onClick={downloadExportToCsv}
              text="Export To CSV"
              className={`export-to-csv-button ${isLoading || navigatorsList.length === 0 ? "disabled" : ""}`}
              disabled={isLoading || navigatorsList.length === 0}
            />
            <CSVLink
              data={navigatorCallsDownload}
              ref={csvLink}
              filename={`call-logs-${moment(dateState.startDate).format("DD-MMMM-YYYY")}-to-${moment(
                dateState.endDate
              ).format("DD-MMMM-YYYY")}.csv`}
              className="hidden"
              target="_blank"
            />
          </div>
          <DatePicker
            onTap={(val) => {
              setDatePickerOpen(val);
            }}
            isOpen={isDatePickerOpen}
            onDateRangeSelect={(item) => handleDateRangeSelect(item)}
            handleClearButtonClick={handleClearButtonClick}
            handleApplyClick={handleApplyClick}
            maxDate={new Date()}
            dateFormat="mm/dd/yyyy"
          />
        </div>
      </Header>
      {isLoading && !isSortedColumn ? (
        <div className="loading-container">
          <CommonState type={CommonStateType.LOADING} />
        </div>
      ) : (
        <>
          <div className="statistic-box-grid">
            {navigatorStats?.map((stat: StatisticBoxProps, index) => {
              if (stat) {
                return (
                  <StatisticBox
                    key={index}
                    image={stat?.image}
                    info={isSortedColumn ? "" : stat?.info}
                    code={stat?.code}
                    value={stat?.value}
                    size={stat?.size}
                    className={stat?.className}
                    heading={stat?.heading}
                  />
                );
              }
            })}
          </div>
          <div className="all-filters-section">
            <GenericTabs
              tabsList={[
                {
                  name: NavigatorsTab.ALL,
                },
                {
                  name: NavigatorsTab.PATIENT,
                },
                {
                  name: NavigatorsTab.TRANSITION,
                },
              ]}
              activeTabName={activeTabName}
              setActiveTabName={onClickTabName}
            />
          </div>
          <div className="table-container">
            {navigatorsList.length > 0 && (
              <table>
                <NavigatorTable
                  columns={columnsHeader}
                  currentTableData={getNavigatorList()}
                  handleColumnSort={changeSort}
                  isSortedColumnLoading={isSortedColumn}
                />
              </table>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ManagerDashboard;
