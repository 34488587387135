import { ILocation } from "state/types/toc-management-slice.type";

export const PAC_TYPE = {
  IRF: "IRF",
  SNF: "SNF",
  HH: "HH",
  MPT: "M-PT",
  OPT: "OPT",
  HOME_SERVICE: "Home w/No services",
};

export const NOTE_LIMIT = 2000;

export const ACUTE_LOS_ERROR = "Acute LOS must be between 1 and 50";
export const FACILITY_ERROR = "Select atleast 1 facility or Home w/No services";
export const LOS_VISIT_ERROR = "LOS/Visit is required for facility and should be between 1 and ";

export const TOC_LISTING_HEADERS = [
  {
    name: "Name",
  },
  {
    name: "Created",
  },
  {
    name: "Status",
  },
  {
    name: "Approved",
  },
  {
    name: "",
  },
];

export const TOC_ITEMS: Record<string, ILocation> = {
  irf: {
    id: "",
    providerId: -1,
    providerName: "",
    los: "",
    pacTypeName: PAC_TYPE.IRF,
    isProviderValid: true,
    isLosValid: true,
  },
  snf: {
    id: "",
    providerId: -1,
    providerName: "",
    los: "",
    pacTypeName: PAC_TYPE.SNF,
    isProviderValid: true,
    isLosValid: true,
  },
  hh: {
    id: "",
    providerId: -1,
    providerName: "",
    los: "",
    pacTypeName: PAC_TYPE.HH,
    isProviderValid: true,
    isLosValid: true,
  },
  mpt: {
    id: "",
    providerId: -1,
    providerName: "",
    los: "",
    pacTypeName: PAC_TYPE.MPT,
    isProviderValid: true,
    isLosValid: true,
  },
  opt: {
    id: "",
    providerId: -1,
    providerName: "",
    los: "",
    pacTypeName: PAC_TYPE.OPT,
    isProviderValid: true,
    isLosValid: true,
  },
};

export enum TocStatusEnum {
  NEW = "New",
  PENDING = "Pending",
  APPROVED = "Approved",
  CANCELLED = "Cancelled",
}

export const LOS_LIMIT: Record<string, number> = {
  IRF: 90,
  SNF: 90,
  HH: 50,
  MPT: 50,
  OPT: 50,
};

export enum RevisionReasonModalEnum {
  APPROVED_REVISION,
  PENDING_REVISION,
}
