export enum TaskType {
  RISK = 1,
  TASK_DELAY = 2,
  TOC = 3,
  NOTES = 4,
  REMINDER = 5,
  ASSESSMENT = 6,
  SEND_ORDER = 7,
  ADMIT_TASK = 8,
  CHECK_ON_PATIENT = 9,
  EPISODE_STATUS_CHANGE = 10,
  CARE_PLAN_UTILIZATION = 11,
  CALL = 12,
  TASK_CLOSURE_APPROVED = 13,
  TASK_CLOSURE_REJECTED = 14,
  EPISODE_NAVIGATION_DECLINED = 15,
  EPISODE_NAVIGATION_ACCEPTED = 16,
  EPISODE_NAVIGATION_NOTES_CHANGES = 17,
}
