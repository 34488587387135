import { useDispatch, useSelector } from "react-redux";
import "./patient-task-details.style.scss";
import { useEffect, useRef } from "react";
import { SortingOrderType } from "shared/enums/sorting-types.enums";
import { getEpisodeTasksAsync } from "state/feature/episodes/episodes.action";
import PatientTaskDetailsPresentation from "./patient-task-details.component";
import { getBrowserState } from "state/feature/task-management/task-management.slice";

const PatientTaskDetails = () => {
  const dispatch = useDispatch();
  const { currentTab } = useSelector(getBrowserState);
  const { intakeId } = currentTab!;
  const initialRequestPayload = useRef({
    showFutureTaskOnly: true,
    intakeId: Number(intakeId),
    sortColumn: "dueDate",
    sortOrder: SortingOrderType.ASC,
  });

  useEffect(() => {
    dispatch(getEpisodeTasksAsync(initialRequestPayload.current));
  }, [dispatch, initialRequestPayload]);

  return <PatientTaskDetailsPresentation />;
};

export default PatientTaskDetails;
