import Icon from "components/Icon/icon.component";
import "./patient-decline-navigation-alert.styles.scss";
import { CustomToolTip } from "components/CustomTooltip/custom-tooltip.component";
import PatientDeclineNotesTooltip from "./patient-decline-notes-tooltip.component";
import { isEmptyString } from "shared/methods/utilityFunctions";
import moment from "moment";
const PatientDeclineNavigation = (props: {
  showNotesIcon?: boolean;
  patientNavigationDeclinedNotes?: string | null;
  className?: string;
  patientNavigationDeclinedChanged?: string | null;
}) => {
  const { showNotesIcon, patientNavigationDeclinedNotes, className = "", patientNavigationDeclinedChanged } = props;

  const date = patientNavigationDeclinedChanged
    ? moment.utc(patientNavigationDeclinedChanged, "YYYY-MM-DDTHH:mm:ss").local().format("MM/DD/YYYY")
    : null;
  return (
    <div className={`patient-decline-navigation-alert-container ${className}`}>
      <div className="alert-icon">
        <Icon icon="decline-navigation-notes-icon" size={15} color="red" />
      </div>
      <div className="text-and-notes-icon-container">
        {`Patient Declined Navigation Services${date ? ` (${date})` : ""}`}
        {showNotesIcon && patientNavigationDeclinedNotes && !isEmptyString(patientNavigationDeclinedNotes) && (
          <div className="notes-icon" data-tip data-for={patientNavigationDeclinedNotes}>
            <Icon icon="assessments" size={11} color="#00887C" />
            <CustomToolTip
              place="left"
              id={patientNavigationDeclinedNotes}
              text={<PatientDeclineNotesTooltip notes={patientNavigationDeclinedNotes} />}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PatientDeclineNavigation;
