import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, matchPath, useHistory, useLocation } from "react-router-dom";
import { getCookie } from "shared/methods/utilityFunctions";
import { useTwilioClientService } from "shared/services/twilio/twilio-client.service";
import { TwilioClient } from "shared/services/twilio/twilioClient";
import { getAuth } from "state/feature/auth/auth.slice";
import { getCallState } from "state/feature/call/call.slice";
import { getCommonState, setTwilioClientRef } from "state/feature/common/common.slice";
import { useAppDispatch } from "state/store";
import { getCurrentUserProfile } from "../../state/feature/navigator/navigator.action";
import { getNavigatorState } from "../../state/feature/navigator/navigator.slice";
import { privateRoutes } from "routes/constants";

export const PrivateRouteGuard = ({ children }: { children: any }) => {
  const history = useHistory();
  const { auth, user } = useSelector(getAuth);
  const location = useLocation();
  const { modal } = useSelector(getCommonState);
  const appDispatch = useAppDispatch();
  const twilioClient = useTwilioClientService();
  const callState = useSelector(getCallState);
  const { isLoadingCurrentUserProfile, currentUserProfile } = useSelector(getNavigatorState);

  useEffect(() => {
    if (auth.isAuthenticated && !modal.isSessionTimeoutModalVisible) {
      if (!isLoadingCurrentUserProfile && !currentUserProfile) {
        appDispatch(getCurrentUserProfile());
      }
      if (callState.twilioAcessToken) {
        appDispatch(setTwilioClientRef(TwilioClient.getInstance(callState.twilioAcessToken)));
      }
      if (!callState.twilioAcessToken) {
        twilioClient.generateToken();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callState.twilioAcessToken, auth.isAuthenticated, currentUserProfile, modal.isSessionTimeoutModalVisible]);

  if (!auth.isAuthenticated) {
    const tokensFromCookie = getCookie("authTokens");

    if (!tokensFromCookie || !JSON.parse(tokensFromCookie)) {
      history.replace("/");
    }
  } else {
    if (user && user.roles && user.roles.length > 0) {
      const role = user.roles[0];

      const routeToBeAccessed = privateRoutes.find((route) => {
        return matchPath(location.pathname, { path: route.path, exact: true });
      });

      if (!routeToBeAccessed?.isAccessibleBy?.includes(role)) {
        return <Redirect exact to={{ pathname: "/dashboard" }} />;
      }
    }
  }

  const avoidRoutes = ["/access-denied", "/risk-assessment", "/episodes", "/toc"];
  const allowBypass = () => avoidRoutes.filter((route) => location.pathname.includes(route)).length > 0;
  if (auth.isAuthenticated && !user.navigatorId && !allowBypass()) {
    return <Redirect exact to={{ pathname: "/access-denied" }} />;
  }

  return children;
};
