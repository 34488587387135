import { useEffect, useState } from "react";
import ClickAwayListener from "react-click-away-listener";
import TickmarkIcon from "shared/assets/images/tickmark.png";
import Icon from "components/Icon/icon.component";
import HighlightedText from "components/HighLightedText/high-lighted-text.component";
import "./facility-dropdown.styles.scss";

type FacilityDropdownProps = {
  onChange: (id: number, name: string) => void;
  isError: boolean;
  isDisabled?: boolean;
  selectedOptionId?: number;
  dropdownOptions: { id: number | undefined; name: string }[];
  placeholder?: string;
};
const FacilityDropdown = (props: FacilityDropdownProps) => {
  const [isOptionsVisible, setIsOptionsVisible] = useState<boolean>(false);
  const { selectedOptionId, onChange, isDisabled, isError, dropdownOptions, placeholder } = props;
  const [selectedOption, setSelectedOption] = useState<{ id: number; name: string }>();
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (dropdownOptions && dropdownOptions.length && selectedOptionId !== -1) {
        const facility = dropdownOptions.find((el) => el.id === selectedOptionId)!;
        setSelectedOption({ id: facility?.id ?? -1, name: facility?.name ?? "" });
      } else {
        setSelectedOption({ id: -1, name: "" });
      }
    }

    return () => {
      isMounted = false;
    };
  }, [selectedOptionId, dropdownOptions]);

  const onOptionClick = (id: number) => {
    if (dropdownOptions && dropdownOptions.length) {
      const facility = dropdownOptions.find((el) => el.id === id)!;
      setSelectedOption({ id: facility?.id ?? -1, name: facility?.name ?? "" });
      setIsOptionsVisible(false);
      onChange(facility?.id ?? -1, facility?.name ?? "");
      setSearch("");
    }
  };

  const clearSelection = () => {
    setSelectedOption({ id: -1, name: "" });
    onChange(-1, "");
  };

  const onSearchChange = (e: any) => {
    setSearch(e.target.value);
  };

  const getSearchResults = () => {
    return dropdownOptions.filter((el) => el.name.toLowerCase().includes(search.toLowerCase()));
  };

  const onBlurDropdown = (e: any) => {
    const isClickedOnDropdownOptions = e.relatedTarget
      ? Object.values(e.relatedTarget.classList).includes("search-input")
      : false;
    if (!isClickedOnDropdownOptions) {
      setIsOptionsVisible(false);
    }
  };

  return (
    <div className="facility-dropdown-container" tabIndex={0} onBlur={onBlurDropdown}>
      <div className={`current-selction-container ${isDisabled ? "disabled" : ""} ${!isError ? "" : "input-error"}`}>
        {selectedOption && selectedOption?.id !== -1 && selectedOption.name.length ? (
          <div className="current-selection">{selectedOption?.name}</div>
        ) : (
          <div className="placeholder">{placeholder ?? "Select Facility"}</div>
        )}

        {selectedOption && selectedOption?.id !== -1 && !isDisabled ? (
          <div className="cancel-selection-container">
            <div
              className="cancel-icon-container"
              onClick={() => {
                if (isDisabled) {
                  return;
                }
                clearSelection();
              }}
            >
              <Icon
                icon="cross-in-circle"
                size={18}
                className={`search-cross-icon ${isDisabled ? "search-cross-icon-disabled" : ""}`}
              />
            </div>
          </div>
        ) : null}
        <div
          className={`dropdown-icon-container ${isDisabled ? "dropdown-icon-container-disabled" : ""}`}
          onClick={() => {
            if (isDisabled) {
              return;
            }
            setIsOptionsVisible(!isOptionsVisible);
          }}
        >
          {isOptionsVisible ? (
            <Icon icon="up-arrow" className="select-box-up-arrow" size={11} />
          ) : (
            <Icon icon="down-arrow" className={`select-box-down-arrow `} size={11} />
          )}
        </div>
      </div>
      {isOptionsVisible ? (
        <div className="options-container">
          <div className="search-container">
            <div className="search-input-container">
              <Icon icon="search-lens" size={16} className="search-lens-icon" />
              <input
                className="search-input"
                type="text"
                value={search}
                onChange={onSearchChange}
                name="search"
                id="search"
                placeholder="Search by facility name"
              />
            </div>
            {search && search.length ? (
              <div className="cancel-icon-container">
                <span onClick={() => setSearch("")}>
                  <Icon icon="search-cross" size={16} className="search-cross-icon" />
                </span>
              </div>
            ) : null}
          </div>
          {getSearchResults().length ? (
            <div className="dropdown-options-container">
              {getSearchResults().map((el) => (
                <div
                  className={`dropdown-options-row`}
                  key={el.id}
                  onMouseDown={() => {
                    if (el.id) onOptionClick(el.id);
                  }}
                >
                  <div className="name">{HighlightedText(`${el.name}`, search)}</div>
                  <div className="distance">
                    {/* {el.distance.length ? <span className="text">{el.distance}</span> : null} */}
                    {el.id === selectedOption?.id ? <img className="check" src={TickmarkIcon} alt="tick-mark" /> : null}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="dropdown-options-container">
              <div className="no-option-row" key="no-option">
                <div className="name">No facilities found</div>
              </div>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default FacilityDropdown;
