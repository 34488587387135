import Button from "components/Button/button.component";
import Checkbox from "components/Checkbox/checkbox.component";
import CustomDropDown from "components/CustomDropdown/custom-dropdown.component";
import SidePopup from "components/SidePopup/side-popup.component";
import TextField from "components/TextFieldV2/text-field-v2.component";
import SingleDatePicker from "components/single-date-picker/single-date-picker.component";
import TextArea from "pages/episodes/episode-details/components/text-area/text-area.component";
import "./style.scss";
import { useSelector } from "react-redux";
import { getCommonState, setIsOpenCareOfPlanDeleteConfirmationModal } from "state/feature/common/common.slice";
import FacilityDropdown from "pages/task-management/transition-of-care/facility-dropdown";
import { POCServiceModalPropTypes } from "./poc-service-modal.props";
import {
  getPlanOfCareState,
  setActiveInactive,
  setAdmissonDate,
  setDischargeDate,
  setLos,
  setNotes,
  setOtherReason,
  setReadmissionReason,
  setReason,
  setServiceTypesForPlanOfCare,
} from "state/feature/plan-of-care/plan-of-care.slice";
import { useAppDispatch } from "state/store";
import moment from "moment";
import { isEmptyString, validateNumberForTextInput } from "shared/methods/utilityFunctions";
import { Other, errorCharacterMessage, losAndVisits, losWithDays } from "./poc-service-modal.constant";
import CommonState from "components/common-state/common-state.component";
import { CommonStateType } from "components/common-state/common-state.enum";
import { DaysVisit, ServiceType } from "shared/enums/plan-of-care.enum";
import { getFacilitiesForPlanOfCare } from "state/feature/plan-of-care/plan-of-care.action";

const POCServiceModalPresentation = (props: POCServiceModalPropTypes) => {
  const {
    handleModalClose,
    onFacilityChange,
    isSubmitButtonPressed,
    onClickSaveButton,
    enableSaveButton,
    handleIsSaveButtonEnabled,
    isEditable,
    getIfProviderPresent,
    isScrollVisible,
    isErrorLosEmptyIfDischargeDateAdded,
    isRequiredDayVisit,
  } = props;
  const {
    modal: { isOpenPOCServiceModal },
  } = useSelector(getCommonState);
  const { metadata, selectedPlanOfCareValues, isLoading } = useSelector(getPlanOfCareState);
  const dispatch = useAppDispatch();
  const service =
    metadata.service.length > 0
      ? metadata.service.map((item) => {
          return {
            name: item.serviceType,
            key: item.id,
            daysType: item.daysType,
          };
        })
      : [];
  const readmissionReason =
    metadata.readmissionReason.length > 0
      ? metadata.readmissionReason.map((item) => {
          return {
            name: item.name,
            key: item.id,
          };
        })
      : [];

  const reasons =
    metadata.reason.length > 0
      ? metadata.reason.map((item) => {
          return {
            name: item.itemName,
            key: item.id,
          };
        })
      : [];

  const getLosLabel = () => {
    if (selectedPlanOfCareValues.serviceType.daysType === DaysVisit.LOS) {
      return losWithDays;
    } else if (selectedPlanOfCareValues.serviceType.daysType === DaysVisit.VISIT) {
      return DaysVisit.VISIT;
    }

    return losAndVisits;
  };

  const isErrorLosRange =
    !isEmptyString(selectedPlanOfCareValues.los) &&
    (parseInt(selectedPlanOfCareValues.los) > 200 || parseInt(selectedPlanOfCareValues.los) <= 0)
      ? true
      : false;
  return (
    <>
      <SidePopup
        isOpen={isOpenPOCServiceModal.isOpen}
        onClose={() => {
          handleModalClose();
        }}
        contentClass="poc-service-content-container"
        className="poc-service-side-popup poc-service-modal-side-popup"
        heading={isOpenPOCServiceModal.isEdit ? "Edit Service" : "New Service"}
      >
        {isLoading ? (
          <div className="loading-container">
            <CommonState type={CommonStateType.LOADING} />
          </div>
        ) : (
          <div className="poc-service-modal-content">
            <div className="poc-service-modal-form">
              <div className="poc-service-modal-form-field">
                <div className="field-label">
                  Service Type
                  <span className="red-color-asterisk">*</span>
                </div>
                <CustomDropDown
                  placeholder="Select"
                  dropDownMenuItems={service}
                  handleValueChanges={(option: any) => {
                    handleIsSaveButtonEnabled();
                    dispatch(setServiceTypesForPlanOfCare(option));
                    if (isOpenPOCServiceModal.isEdit) {
                      dispatch(setActiveInactive(true));
                    } else {
                      dispatch(setActiveInactive(false));
                    }
                    dispatch(setReadmissionReason({ name: "", key: "" }));
                    dispatch(setOtherReason(""));
                  }}
                  value={selectedPlanOfCareValues.serviceType}
                  idFieldName="key"
                  dropDownBoxClass="action-dropdown"
                  selectionClass="action-selection"
                  dropDownContainerClass={`action-dropdown-container`}
                  isError={isSubmitButtonPressed && isEmptyString(selectedPlanOfCareValues.serviceType.name)}
                  message="Please select service type"
                  isDisabled={!isEditable}
                />
              </div>
              {selectedPlanOfCareValues.serviceType.name !== ServiceType.HOME_WITHOUT_SERVICES && (
                <div className="poc-service-modal-form-field">
                  <div className="field-label">
                    Service Reason<span className="red-color-asterisk">*</span>
                  </div>
                  <CustomDropDown
                    placeholder="Select"
                    dropDownMenuItems={reasons}
                    handleValueChanges={(option: any) => {
                      handleIsSaveButtonEnabled();
                      dispatch(setReason(option));
                    }}
                    value={selectedPlanOfCareValues.reason}
                    idFieldName="key"
                    dropDownBoxClass="action-dropdown"
                    selectionClass="action-selection"
                    dropDownContainerClass={`action-dropdown-container`}
                    message="Please select service reason"
                    isError={isSubmitButtonPressed && isEmptyString(selectedPlanOfCareValues.reason.name)}
                    isDisabled={!isEditable}
                  />
                </div>
              )}
              {(selectedPlanOfCareValues.serviceType.name === ServiceType.ER_URGENT_CARE ||
                selectedPlanOfCareValues.serviceType.name === ServiceType.HOSPITAL_OBSERVATION ||
                selectedPlanOfCareValues.serviceType.name === ServiceType.HOSPITAL_READMIT ||
                selectedPlanOfCareValues.serviceType.name === ServiceType.OP_PROCEDURE_SERGERY) && (
                <div className="poc-service-modal-form-field">
                  <div className="field-label">
                    Readmission Reason<span className="red-color-asterisk">*</span>
                  </div>
                  <CustomDropDown
                    placeholder="Select"
                    dropDownMenuItems={readmissionReason}
                    handleValueChanges={(option: any) => {
                      handleIsSaveButtonEnabled();
                      dispatch(setReadmissionReason(option));
                    }}
                    value={selectedPlanOfCareValues.readmissionReason}
                    idFieldName="key"
                    dropDownBoxClass="action-dropdown"
                    selectionClass="action-selection"
                    dropDownContainerClass={`action-dropdown-container`}
                    message="Please select readmission reason"
                    isError={isSubmitButtonPressed && isEmptyString(selectedPlanOfCareValues.readmissionReason.name)}
                    isDisabled={!isEditable}
                  />
                </div>
              )}
              {selectedPlanOfCareValues.serviceType.name !== ServiceType.HOME_WITHOUT_SERVICES &&
                !isEmptyString(selectedPlanOfCareValues.readmissionReason.name) &&
                selectedPlanOfCareValues.readmissionReason.name === Other && (
                  <div className="poc-service-modal-form-field">
                    <div className="field-label">
                      Other Details
                      {selectedPlanOfCareValues.readmissionReason.name === Other && (
                        <span className="red-color-asterisk">*</span>
                      )}
                    </div>
                    <TextArea
                      leftLabel=""
                      rightLabel=""
                      inputText={selectedPlanOfCareValues.otherReason}
                      handleChange={(value) => {
                        handleIsSaveButtonEnabled();
                        dispatch(setOtherReason(value));
                      }}
                      errorMessage={errorCharacterMessage}
                      rows={5}
                      id={""}
                      showError={
                        isSubmitButtonPressed &&
                        selectedPlanOfCareValues.readmissionReason.name === Other &&
                        selectedPlanOfCareValues.otherReason.length === 0
                      }
                      maxLength={200}
                      isDisabled={!isEditable}
                    />
                    {isEditable && selectedPlanOfCareValues.otherReason.length > 0 && (
                      <div className="character-count">{selectedPlanOfCareValues.otherReason.length}/200</div>
                    )}
                  </div>
                )}

              {/* <div className="poc-service-modal-form-field">
            <div className="field-label">Link to care plan item</div>
            <CustomDropDown
              placeholder="Select"
              dropDownMenuItems={[]}
              handleValueChanges={(option: any) => {}}
              value={{ key: "", name: "" }}
              idFieldName="key"
              dropDownBoxClass="action-dropdown"
              selectionClass="action-selection"
              dropDownContainerClass={`action-dropdown-container`}
              // isDisabled={isEmptyString(result.key)}
            />
          </div> */}
              {selectedPlanOfCareValues.serviceType.name !== ServiceType.HOME_WITHOUT_SERVICES && (
                <>
                  <div className="poc-service-modal-form-field">
                    <div className="field-label">
                      Provider
                      <span className="red-color-asterisk">*</span>
                    </div>
                    <FacilityDropdown
                      facilities={metadata.facilities}
                      selectedOptionId={
                        !isEmptyString(selectedPlanOfCareValues.provider.key) &&
                        parseInt(selectedPlanOfCareValues.provider.key) !== -1
                          ? parseInt(selectedPlanOfCareValues.provider?.key)
                          : -1
                      }
                      isError={isSubmitButtonPressed && isEmptyString(selectedPlanOfCareValues.provider.value)}
                      onChange={(providerId: number, providerName: string) => {
                        handleIsSaveButtonEnabled();
                        onFacilityChange({ providerId, providerName });
                      }}
                      isDisabled={!isEditable}
                    />
                    {isSubmitButtonPressed && isEmptyString(selectedPlanOfCareValues.provider.value) && (
                      <div className="error-container">
                        <div className="error-color">Please select provider</div>
                      </div>
                    )}
                    <Checkbox
                      defaultChecked={isOpenPOCServiceModal.isEdit ? true : false}
                      name="Include-Inactive"
                      value="Include Inactive"
                      onChange={async (e) => {
                        handleIsSaveButtonEnabled();
                        await getIfProviderPresent(e.target.checked);
                        dispatch(setActiveInactive(e.target.checked));
                      }}
                      id="inactive"
                      isDisabled={!isEditable}
                    />
                  </div>
                </>
              )}
              <div className="poc-service-modal-form-field admission-discharge-date-container">
                <div className="date-field">
                  <div className="field-label">
                    Admission Date<span className="red-color-asterisk">*</span>
                  </div>
                  <SingleDatePicker
                    defaultDate={isOpenPOCServiceModal.isEdit ? selectedPlanOfCareValues.admissionDate : null}
                    maxDate={null}
                    minDate={null}
                    className={`date-field ${!isEditable ? "disabled-date" : "date-selector"}`}
                    isError={isSubmitButtonPressed && !selectedPlanOfCareValues.admissionDate}
                    handleDateChange={(val: any) => {
                      handleIsSaveButtonEnabled();
                      dispatch(setDischargeDate(null));
                      dispatch(setAdmissonDate(val ? moment(val).format("MM/DD/YYYY") : null));
                    }}
                    errorMessage="Please select admission date"
                    popperPlacement="bottom"
                    isDisabled={!isEditable}
                  />
                </div>
                <div className="date-field">
                  <div className="field-label">Discharge Date</div>
                  <SingleDatePicker
                    defaultDate={selectedPlanOfCareValues.dischargeDate}
                    minDate={
                      selectedPlanOfCareValues.admissionDate
                        ? moment(selectedPlanOfCareValues.admissionDate).subtract(1).toDate()
                        : null
                    }
                    maxDate={null}
                    className={`date-field ${!isEditable ? "disabled-date" : "date-selector"}`}
                    isError={false}
                    handleDateChange={(val: any) => {
                      handleIsSaveButtonEnabled();
                      dispatch(setDischargeDate(val ? moment(val).toString() : null));
                    }}
                    popperPlacement="bottom"
                    isDisabled={!isEditable}
                  />
                </div>
              </div>
              {selectedPlanOfCareValues.serviceType.name !== ServiceType.HOME_WITHOUT_SERVICES && (
                <div className="poc-service-modal-form-field los-day-visit">
                  <div className="field-label">
                    {getLosLabel()}
                    {isRequiredDayVisit && <span className="red-color-asterisk">*</span>}
                  </div>
                  <TextField
                    label=""
                    className="los"
                    type="text"
                    value={selectedPlanOfCareValues.los}
                    placeholder=""
                    setValue={(label, value) => {
                      if (validateNumberForTextInput(value) && value.length <= 3) {
                        handleIsSaveButtonEnabled();
                        dispatch(setLos(value));
                      }
                    }}
                    showError={isSubmitButtonPressed && (isErrorLosRange || isErrorLosEmptyIfDischargeDateAdded)}
                    errorMessage={
                      isErrorLosEmptyIfDischargeDateAdded
                        ? "Number of visits is required when a discharge date has been entered"
                        : "LOS/Visits should not be less than 1 and greater than 200"
                    }
                    disabled={!isEditable}
                  />
                </div>
              )}
              <div className="poc-service-modal-form-field">
                <div className="field-label">Notes</div>
                <TextArea
                  leftLabel=""
                  rightLabel=""
                  inputText={selectedPlanOfCareValues.notes}
                  handleChange={(value) => {
                    handleIsSaveButtonEnabled();
                    dispatch(setNotes(value));
                  }}
                  errorMessage={errorCharacterMessage}
                  rows={5}
                  id={""}
                  maxLength={200}
                  isDisabled={!isEditable}
                />
                {isEditable && selectedPlanOfCareValues.notes.length > 0 && (
                  <div className="character-count">{selectedPlanOfCareValues.notes.length}/200</div>
                )}
              </div>
            </div>
            <div className={`poc-service-modal-button-container ${isScrollVisible ? "more-padding" : "less-padding"}`}>
              <div>
                {isOpenPOCServiceModal.isEdit && isEditable && (
                  <Button
                    className="delete-button white-button"
                    text="Delete"
                    onClick={() => {
                      dispatch(setIsOpenCareOfPlanDeleteConfirmationModal(true));
                    }}
                  />
                )}
              </div>
              <div>
                <Button className="cancel-button white-button" text="Cancel" onClick={handleModalClose} />
                {((isOpenPOCServiceModal.isEdit && isEditable) || !isOpenPOCServiceModal.isEdit) && (
                  <Button
                    className="save-button green-button"
                    text="Save"
                    disabled={enableSaveButton}
                    showLoader={isSubmitButtonPressed && enableSaveButton}
                    onClick={onClickSaveButton}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </SidePopup>
    </>
  );
};
export default POCServiceModalPresentation;
