import { ChangeEvent, useEffect } from "react";
import RiskAssessmentPresentation from "./risk-assessment.component";
import { RiskAssessmentContext } from "./context/risk-assessment.context";
import { useAppDispatch } from "state/store";
import { getCommonState } from "state/feature/common/common.slice";
import { useDispatch, useSelector } from "react-redux";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import {
  appendSelectedValue,
  getRiskAssessmentState,
  removeAppendedSelectedValue,
  removeCollapsibleSection,
  removeSelectedValue,
  setCollapsibleSection,
  removeSelectedValuesForSection,
  setIsDirty,
  setSelectedValue,
  setUnansweredSections,
} from "state/feature/risk-assessment/risk-assessment.slice";
import { Pages } from "./common/risk-assessment.types";
import { getRiskAssessmentByIntakeIdAsync } from "state/feature/risk-assessment/risk-assessment.action";
import CommonState from "components/common-state/common-state.component";
import { CommonStateType } from "components/common-state/common-state.enum";
import { FormType } from "shared/enums/form-types.enum";
import {
  getDelayAttempts,
  getIntakeDetails,
  getTaskDelayStatuses,
} from "state/feature/task-management/task-management.action";
import { getBrowserState, getCurrentPageTaskState } from "state/feature/task-management/task-management.slice";

const RiskAssessment = () => {
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const { isLoading } = useSelector(getCommonState);
  const { pages: riskAssessmentPages, selectedValues } = useSelector(getRiskAssessmentState);
  const { currentTab, pages } = useSelector(getBrowserState);
  const { patientDetails, isValidIntake, isValidatingIntake, intakeId, taskType, delayTask, taskPayload } = useSelector(
    getCurrentPageTaskState(currentTab!.taskId)
  );
  useAxiosAuthenticated();

  useEffect(() => {
    if (isValidIntake && !isValidatingIntake) {
      const currentPage = pages.find((p) => p.taskId === currentTab?.taskId);
      if (!currentPage?.taskPayload) {
        appDispatch(getRiskAssessmentByIntakeIdAsync({ intakeId }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, appDispatch, intakeId, isValidIntake, isValidatingIntake]);

  const onChangePill = (
    e: ChangeEvent<HTMLInputElement>,
    id: string,
    sectionId?: string | null,
    questionId?: string | null,
    nextQuestionId?: string | null,
    conditionalSectionId?: string | null,
    showSection?: boolean | null,
    pageId?: number | null,
    order?: number | null,
    sectionOrder?: number | null
  ) => {
    const element = document.getElementById(e.target.id) as HTMLInputElement;
    const allOtherRelatedCheckboxes = document.querySelectorAll(
      `.${e.target.classList[1]}`
    ) as NodeListOf<HTMLInputElement>;
    const parentElement = element.parentElement;
    if (parentElement) {
      if (element && element.checked) {
        allOtherRelatedCheckboxes.forEach((item: HTMLInputElement) => {
          if (item === element) {
            parentElement.classList.add("checked");
            appDispatch(
              setSelectedValue({
                sectionId,
                questionId,
                answer: {
                  [e.target.name]: [
                    {
                      id,
                      value: e.target.value,
                      nextQuestionId,
                      order,
                      collapsibleSectionId: showSection ? conditionalSectionId : null,
                    },
                  ],
                },
                pageId,
                sectionOrder,
                isValueChanged: true,
              })
            );
            if (conditionalSectionId) {
              if (showSection) {
                appDispatch(
                  setCollapsibleSection({ pageId: pageId, sectionId: conditionalSectionId, questionId: questionId })
                );
              } else {
                appDispatch(
                  removeCollapsibleSection({ sectionId: conditionalSectionId, pageId, questionId: questionId })
                );
              }
            }
          } else {
            item.checked = false;
            item?.parentElement?.classList.remove("checked");
          }
        });
      } else {
        appDispatch(
          removeSelectedValue({
            sectionId,
            questionId,
            answer: { [e.target.name]: [{ id, value: e.target.value, nextQuestionId }] },
          })
        );
        dispatch(
          setUnansweredSections({
            pageId,
            sectionId,
            questionId,
          })
        );
        parentElement.classList.remove("checked");

        if (conditionalSectionId) {
          if (showSection) {
            appDispatch(removeCollapsibleSection({ sectionId: conditionalSectionId, pageId, questionId: questionId }));
            appDispatch(removeSelectedValuesForSection(conditionalSectionId));
          }
        }
      }
    }
  };

  const onSelectPill = (
    e: ChangeEvent<HTMLInputElement>,
    id: string,
    sectionId?: string | null,
    questionId?: string | null,
    nextQuestionId?: string | null,
    conditionalSectionId?: string | null,
    showSection?: boolean | null,
    pageId?: number | null,
    order?: number | null,
    sectionOrder?: number | null
  ) => {
    const element = document.getElementById(e.target.id) as HTMLInputElement;
    const allOtherRelatedCheckboxes = document.querySelectorAll(
      `.${e.target.classList[1]}`
    ) as NodeListOf<HTMLInputElement>;
    const parentElement = element.parentElement;
    if (parentElement) {
      if (element && element.checked) {
        allOtherRelatedCheckboxes.forEach((item: HTMLInputElement) => {
          if (item === element) {
            parentElement.classList.add("checked");
            appDispatch(
              appendSelectedValue({
                order,
                sectionId,
                questionId,
                answer: { [e.target.name]: [{ id, value: e.target.value, nextQuestionId }] },
                pageId,
                sectionOrder,
              })
            );
            if (conditionalSectionId) {
              if (showSection) {
                appDispatch(
                  setCollapsibleSection({
                    pageId: pageId,
                    sectionId: conditionalSectionId,
                    questionId: questionId,
                    isDirty: false,
                  })
                );
              } else {
                appDispatch(
                  removeCollapsibleSection({ sectionId: conditionalSectionId, pageId, questionId: questionId })
                );
              }
            }
          }
        });
      } else {
        // TODO: remove appended value
        parentElement.classList.remove("checked");
        if (conditionalSectionId) {
          if (showSection) {
            appDispatch(removeCollapsibleSection({ sectionId: conditionalSectionId, pageId, questionId: questionId }));
          }
        }
      }
    }
  };

  const onChangeCheckbox = (
    e: ChangeEvent<HTMLInputElement>,
    id: string,
    title: string,
    sectionId: string,
    questionId: string,
    nextQuestionId?: string | null,
    pageId?: number | null,
    order?: number | null,
    sectionOrder?: number | null
  ) => {
    if (e.target.checked) {
      appDispatch(
        appendSelectedValue({
          sectionId,
          questionId,
          answer: { [questionId]: [{ id, value: title, nextQuestionId: nextQuestionId, order }] },
          pageId,
          sectionOrder,
          showSection: false,
        })
      );
    } else {
      appDispatch(
        removeAppendedSelectedValue({
          sectionId,
          questionId,
          answerId: id,
          nextQuestionId,
          pageId,
        })
      );
      // TODO : remove appended value
    }
  };

  const onChangeDropdown = (
    selectedValue: any,
    sectionId: string,
    questionId: string,
    pageId?: number | null,
    order?: number | null,
    sectionOrder?: number | null
  ) => {
    appDispatch(
      setSelectedValue({
        sectionId,
        questionId,
        answer: { [questionId]: [{ ...selectedValue, order }] },
        pageId,
        sectionOrder,
        collapsibleSectionId: null,
        isValueChanged: true,
      })
    );
  };

  const onChangeDays = (
    e: ChangeEvent<HTMLInputElement>,
    id?: string | null,
    sectionId?: string | null,
    questionId?: string | null,
    pageId?: number | null,
    order?: number | null,
    sectionOrder?: number | null
  ) => {
    appDispatch(
      setSelectedValue({
        sectionId,
        questionId,
        answer: { [e.target.name]: [{ id, value: e.target.value, nextQuestionId: null, order }] },
        pageId,
        sectionOrder,
        collapsibleSectionId: null,
        isValueChanged: true,
      })
    );
  };

  const onChangeAge = (
    id?: string | null,
    sectionId?: string | null,
    questionId?: string | null,
    pageId?: number | null,
    order?: number | null,
    sectionOrder?: number | null,
    value?: string | null
  ) => {
    appDispatch(
      setSelectedValue({
        sectionId,
        questionId,
        answer: { [questionId ?? ""]: [{ id, value: value, nextQuestionId: null, order }] },
        pageId,
        sectionOrder,
        collapsibleSectionId: null,
        isValueChanged: false,
      })
    );
  };

  const onChangeDate = (
    selectedDate: any,
    sectionId: string,
    questionId: string,
    pageId?: number | null,
    order?: number | null,
    sectionOrder?: number | null
  ) => {
    appDispatch(
      setSelectedValue({
        sectionId,
        questionId,
        answer: { [questionId]: [{ id: "", value: selectedDate, nextQuestionId: null, order }] },
        pageId,
        sectionOrder,
        collapsibleSectionId: null,
        isValueChanged: true,
      })
    );
  };

  const onChangeText = (
    text: string,
    sectionId: string,
    questionId: string,
    pageId?: number | null,
    order?: number | null,
    sectionOrder?: number | null,
    isConclusion?: boolean | null
  ) => {
    if (!text || text === "") {
      appDispatch(
        removeSelectedValue({
          sectionId,
          questionId,
          answer: { [questionId]: [{ id: "", value: text, nextQuestionId: null, order }] },
        })
      );
      if (!isConclusion) {
        dispatch(
          setUnansweredSections({
            pageId,
            sectionId,
            questionId,
          })
        );
      }
    } else {
      appDispatch(
        setSelectedValue({
          order,
          sectionId,
          questionId,
          answer: { [questionId]: [{ id: "", value: text, nextQuestionId: null, order, isConclusion }] },
          pageId,
          sectionOrder,
          collapsibleSectionId: null,
          isValueChanged: true,
        })
      );
    }
  };

  useEffect(() => {
    if (riskAssessmentPages.general.isCurrent && riskAssessmentPages.general.isDirty) {
      appDispatch(setIsDirty({ pageId: Pages.General, isDirty: false }));
    } else if (riskAssessmentPages.lace.isCurrent && riskAssessmentPages.lace.isDirty) {
      appDispatch(setIsDirty({ pageId: Pages.Lace, isDirty: false }));
    } else if (riskAssessmentPages.closing.isCurrent && riskAssessmentPages.closing.isDirty) {
      appDispatch(setIsDirty({ pageId: Pages.Closing, isDirty: false }));
    } else if (riskAssessmentPages.additional.isCurrent && riskAssessmentPages.additional.isDirty) {
      appDispatch(setIsDirty({ pageId: Pages.Additional, isDirty: false }));
    }
  }, [
    selectedValues,
    riskAssessmentPages.general.isCurrent,
    riskAssessmentPages.lace.isCurrent,
    riskAssessmentPages.closing.isCurrent,
    riskAssessmentPages.additional.isCurrent,
    riskAssessmentPages.general.isDirty,
    riskAssessmentPages.lace.isDirty,
    riskAssessmentPages.closing.isDirty,
    riskAssessmentPages.additional.isDirty,
    appDispatch,
  ]);

  useEffect(() => {
    if (!delayTask.delayStatuses.length) {
      dispatch(getTaskDelayStatuses(currentTab!.taskId));
    }
  }, [dispatch, currentTab, delayTask.delayStatuses]);

  useEffect(() => {
    if (!taskPayload) {
      appDispatch(getIntakeDetails({ intakeId, taskId: currentTab!.taskId })).then((response) => {
        if (response.payload) {
          const taskId = response.payload.tasks.find(
            (item: any) => taskType === "risk-assessment" && item.taskTypeId === FormType.RISK_ASSESSMENT
          )?.id;
          appDispatch(
            getDelayAttempts({
              intakeId: parseInt(intakeId),
              taskId: taskId,
            })
          );
        }
      });
    }
  }, [appDispatch, intakeId, taskType, currentTab, taskPayload]);

  return (
    <>
      {(isLoading || isValidatingIntake) && <CommonState type={CommonStateType.LOADING} />}

      {!isLoading && !isValidatingIntake && isValidIntake && !patientDetails?.isRiskAssessmentTaskAdded && (
        <CommonState type={CommonStateType.TASK_NOT_ADDED} />
      )}

      {!isLoading && !isValidIntake && !isValidatingIntake && <CommonState type={CommonStateType.INTAKE_NOT_FOUND} />}

      {!isValidatingIntake && isValidIntake && patientDetails?.isRiskAssessmentTaskAdded && (
        <div id="risk-assesment-container">
          <RiskAssessmentContext.Provider
            value={{
              onChangePill,
              onChangeDropdown,
              onSelectPill,
              onChangeCheckbox,
              onChangeDays,
              onChangeAge,
              onChangeDate,
              onChangeText,
            }}
          >
            <RiskAssessmentPresentation />
          </RiskAssessmentContext.Provider>
        </div>
      )}
    </>
  );
};

export default RiskAssessment;
