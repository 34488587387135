export enum FormType {
  RISK_ASSESSMENT = 1,
  TOC = 2,
  PRE_SURGERY_CALL = 3,
  ACCUTE_ASSESSMENT = 4,
  DAY_1_POST_ACUTE = 5,
  WEEK_1_POST_ACUTE = 6,
  WEEK_2_POST_ACUTE = 7,
  WEEK_3_POST_ACUTE = 8,
  WEEK_4_POST_ACUTE = 9,
  WEEK_6_POST_ACUTE = 10,
  WEEK_8_POST_ACUTE = 11,
  WEEK_10_POST_ACUTE = 12,
  WEEK_12_POST_ACUTE = 13,
  NOTES = 14,
  REMINDER = 15,
  SEND_ORDER = 16,
  ADMIT_TASK_WITH_PROVIDER = 17,
  ADMIT_TASK_WITH_PATIENT = 18,
  CHECK_ON_PATIENT_WITH_PATIENT = 19,
  CHECK_ON_PATIENT_WITH_PROVIDER = 20,
}
