import { createSlice } from "@reduxjs/toolkit";
import { IReminderTaskState } from "state/types/reminder-task-slice.type";
import { getReminderTaskAsync, saveReminderTaskAsync } from "./reminder-task.action";
import { TOAST_MESSAGES } from "pages/login/common/constants";
import { toast } from "react-toastify";

const initialState: IReminderTaskState = {
  isLoading: false,
  isLoadingSubmitButton: false,
  reminder: {
    navigatorNotes: "",
  },
  existingReminder: {
    id: "",
    title: "",
    taskOwner: "",
    taskOwnerUserId: "",
    navigatorNotes: "",
    startDate: null,
    dueDate: null,
    taskStatusId: null,
    requests: null,
  },
  isValueChanged: false,
  isDirty: false,
};

const reminderTaskSlice = createSlice({
  name: "reminder-task",
  initialState,
  reducers: {
    updateReminder(state, action) {
      state.reminder = { ...action.payload };
      state.isValueChanged = true;
    },
    setReminderTaskStateFromMemoize(state, action) {
      return action.payload;
    },
    setIsDirty(state, action) {
      state.isDirty = action.payload;
    },
    setIsValueChanged(state, action) {
      state.isValueChanged = action.payload;
    },
    resetReminderState() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    return (
      builder.addCase(getReminderTaskAsync.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(getReminderTaskAsync.fulfilled, (state, action) => {
        const { id, title, taskOwner, taskOwnerUserId, notes, startDate, dueDate, taskStatusId, requests } =
          action.payload;
        const navigatorNotes = notes ?? "";
        const requestNotes = requests ?? "";
        const reminderTask = {
          id,
          title,
          startDate,
          dueDate,
          taskOwner,
          taskOwnerUserId,
          taskStatusId,
          navigatorNotes,
          requests: requestNotes,
        };
        state.reminder = { navigatorNotes };
        state.existingReminder = reminderTask;
        state.isLoading = false;
      }),
      builder.addCase(getReminderTaskAsync.rejected, (state) => {
        state.isLoading = false;
        toast.error(TOAST_MESSAGES.ERROR, { containerId: "main" });
      }),
      builder.addCase(saveReminderTaskAsync.pending, (state, action) => {
        state.isLoadingSubmitButton = true;
      }),
      builder.addCase(saveReminderTaskAsync.fulfilled, (state, action) => {
        state.isLoadingSubmitButton = false;
      }),
      builder.addCase(saveReminderTaskAsync.rejected, (state, action) => {
        state.isLoadingSubmitButton = false;
        if ((action.payload as any).error.response.data.data?.submitedBy) {
          toast.error("Reminder Task has already been submitted", {
            toastId: "form-error",
            containerId: "main",
          });
        } else {
          toast.error(TOAST_MESSAGES.ERROR, {
            toastId: "form-error",
            containerId: "main",
          });
        }
      })
    );
  },
});

export const { updateReminder, setReminderTaskStateFromMemoize, setIsDirty, resetReminderState, setIsValueChanged } =
  reminderTaskSlice.actions;
export const getReminderTaskState = (state: any): IReminderTaskState => state.reminderTask;
export default reminderTaskSlice;
