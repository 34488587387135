import React from "react";
import Button from "components/Button/button.component";
import "./footer-bar.style.scss";

type FooterBarProps = {
  saveBtnLabel: string;
  onClickSave: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
};

const FooterBar = (props: FooterBarProps) => {
  const { saveBtnLabel, isDisabled, onClickSave, isLoading } = props;
  return (
    <div className="footer-button-container">
      <div className="footer-button-section">
        <Button
          showLoader={isLoading}
          text={saveBtnLabel}
          className="save-button"
          onClick={onClickSave}
          disabled={isDisabled}
        />
      </div>
    </div>
  );
};

export default FooterBar;
