export const searchWithResetStyles = {
  control: (styles: any) => ({
    ...styles,
    backgroundColor: "white",
    border: "0.1rem solid #ACC0BB;",
    height: "4.2rem",
    width: "auto",
    position: "relative",
    borderRadius: "0.8rem",
    boxShadow: "none",
    ":focus": {
      outline: "none",
      border: "0.1rem solid #ACC0BB",
    },
    ":hover": {
      border: "0.1rem solid #ACC0BB",
    },
  }),
  indicatorSeparator: () => ({
    color: "white",
    width: 0,
    height: 0,
  }),
  indicatorsContainer: (styles: any, { isDisabled }: any) => {
    const indicatorClass: HTMLDivElement | null = document.querySelector(".select__dropdown-indicator");
    if (indicatorClass) {
      if (isDisabled) {
        indicatorClass.style.cursor = "not-allowed";
      } else {
        indicatorClass.style.cursor = "pointer";
      }
    }
    return { ...styles };
  },
  dropdownIndicator: (styles: any) => ({
    ...styles,
    display: "none",
  }),
  option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
    return {
      ...styles,
      backgroundColor: isDisabled ? undefined : isSelected ? "white" : undefined,
      cursor: isDisabled ? "not-allowed" : "pointer",
      fontFamily: "Mon-medium",
      borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
      fontSize: "1.4rem",
      outline: "none",
      boxShadow: "none",
      padding: "1.6rem",
      color: isSelected ? "#1aa99c" : "black",
      ":last-child": {
        border: "none",
      },
      ":hover": {
        backgroundColor: "#f1faf9",
      },
    };
  },
  input: (styles: any) => ({
    ...styles,
    fontFamily: "Mon-medium",
    fontSize: "1.4rem",
    boxShadow: "none",
    cursor: "default",
  }),
  placeholder: (styles: any) => ({ ...styles, fontFamily: "Mon-medium", fontSize: "1.4rem" }),
  singleValue: function (styles: any): any {
    return {
      ...styles,
      fontFamily: "Mon-medium",
      fontSize: "1.4rem",
      width: "70%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    };
  },
  loadingIndicator: () => {
    return {
      display: "none",
    };
  },
  menu: (styles: any) => {
    return {
      ...styles,
      borderRadius: "0.8rem",
    };
  },
};
