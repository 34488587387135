import CommonState from "components/common-state/common-state.component";
import { CommonStateType } from "components/common-state/common-state.enum";
import { useSelector } from "react-redux";
import { getEpisodesSlice } from "state/feature/episodes/episodes.slice";
import ActivityLog from "./component/activity-log/activity-log.component";
import { getBrowserState, getCurrentPageTaskState } from "state/feature/task-management/task-management.slice";
import { EpisodesPageCommonMessages } from "shared/enums/episodes-details.enum";
import InfiniteScroll from "react-infinite-scroll-component";
import TableLoader from "components/TableLoader/table-loader.component";
import { useParams } from "react-router";
import { TaskPageName } from "shared/enums/page-name.enum";

const ActivityLogsPresentation = (props: { onIncrementPage: () => void }) => {
  const { isLoadingEpisodeDetailTabs, episodeDetailTabs } = useSelector(getEpisodesSlice);
  const { currentTab } = useSelector(getBrowserState);
  const { isValidIntake, isValidatingIntake } = useSelector(getCurrentPageTaskState(currentTab!.taskId));
  const { taskType } = useParams<{ intakeId: string; taskType: string }>();

  const { onIncrementPage } = props;
  return (
    <div
      className={`activity-logs-container  ${
        isValidIntake && !isValidatingIntake && taskType !== TaskPageName.TRANSITION_OF_CARE
          ? "footer-present"
          : "footer-not-present"
      }`}
    >
      {isLoadingEpisodeDetailTabs.activityHistory && episodeDetailTabs.activityHistory.length === 0 ? (
        <CommonState type={CommonStateType.LOADING} />
      ) : !isLoadingEpisodeDetailTabs.activityHistory && episodeDetailTabs.activityHistory.length === 0 ? (
        <CommonState
          type={CommonStateType.NO_ACTIVITY_LOGS_FOUND}
          className="task-management-no-activity-logs-found"
          text="No activity has occured for this patient."
          heading={EpisodesPageCommonMessages.NO_ACTIVITY_FOUND}
        />
      ) : (
        <div className="activity-logs">
          <InfiniteScroll
            next={() => {
              onIncrementPage();
            }}
            hasMore={episodeDetailTabs.activityHistory.length > 0}
            loader={isLoadingEpisodeDetailTabs.activityHistory ? <TableLoader /> : <></>}
            height="100%"
            dataLength={episodeDetailTabs.activityHistory.length}
          >
            {episodeDetailTabs.activityHistory.map((activityLog) => (
              <ActivityLog {...activityLog} />
            ))}
          </InfiniteScroll>
        </div>
      )}
    </div>
  );
};
export default ActivityLogsPresentation;
