import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getEpisodesSlice,
  setDemographicDetails,
  setIsPatientDemographicValueChanged,
} from "state/feature/episodes/episodes.slice";
import PatientDemographicsPresentation from "./patient-demographics.component";
import {
  getDemographicDataAsync,
  getGeneralCategoryAsync,
  getPracticeAsync,
  getPrimaryCarePhysiciansync,
  postGemographicsDataAsync,
} from "state/feature/episodes/episodes.action";
import { useAppDispatch } from "state/store";
import { DemoGraphicResponse } from "state/types/episodes-slice.type";

const PatientDemographics = () => {
  const appDispatch = useAppDispatch();
  const { episodeDetails, episodeDetailTabs } = useSelector(getEpisodesSlice);
  const { patientDemographicsDetails } = episodeDetailTabs;
  const { dropdownsData, demographicDetails, duplicateDataErrors, isValueChange } = patientDemographicsDetails;
  const { coachRelationshipList, maritalStatusList, pcpPracticeList, primaryCarePhysicianList } = dropdownsData;
  const [isLoading, setIsLoading] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  const demographicData = {
    allDropdownsData: { coachRelationshipList, maritalStatusList, pcpPracticeList, primaryCarePhysicianList },
    formData: { ...demographicDetails },
  };

  const saveChanges = async (form: DemoGraphicResponse | undefined) => {
    setIsBtnLoading(true);
    await appDispatch(postGemographicsDataAsync(form as DemoGraphicResponse));
    setIsBtnLoading(false);
  };

  const getDemographicDetails = useCallback(async () => {
    setIsLoading(true);
    try {
      await appDispatch(getPrimaryCarePhysiciansync({ clientId: episodeDetails?.clientId.toString() as string }));
      await appDispatch(getPracticeAsync({ clientId: episodeDetails?.clientId.toString() as string }));
      await appDispatch(getGeneralCategoryAsync());
      await appDispatch(getDemographicDataAsync({ patientId: episodeDetails?.patientId.toString() as string }));
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appDispatch, episodeDetails]);

  useEffect(() => {
    getDemographicDetails();
  }, [getDemographicDetails]);

  useEffect(() => {
    return () => {
      appDispatch(setIsPatientDemographicValueChanged(false));
      appDispatch(setDemographicDetails({} as DemoGraphicResponse));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PatientDemographicsPresentation
      isBtnLoading={isBtnLoading}
      dropdownData={demographicData.allDropdownsData}
      demographicDetails={demographicData.formData}
      saveChanges={saveChanges}
      isValueChange={isValueChange}
      isLoading={isLoading}
      duplicateDataErrors={duplicateDataErrors}
    />
  );
};

export default PatientDemographics;
