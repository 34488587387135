export enum CallingStatus {
  MISSED = "missed",
  NO_ANSWER = "no-answer",
  BUSY = "busy",
  IN_PROGRESS = "in-progress",
  RINGING = "ringing",
  RECORDING_VOICEMAIL = "recording voicemail",
  CONNECTED = "connected",
  DISCONNECTED = "disconnected",
}
