import CommonState from "components/common-state/common-state.component";
import { CommonStateType } from "components/common-state/common-state.enum";
import RiskAssessmentFormInputs from "pages/task-management/risk-assessment/risk-assessment-form-inputs/risk-assessment-form-inputs";
import { Pages } from "pages/task-management/risk-assessment/common/risk-assessment.types";
import Section from "pages/task-management/risk-assessment/section/section.component";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getQuestionsBySectionsAsync,
  getSectionsByPageAsync,
} from "state/feature/risk-assessment/risk-assessment.action";
import { getRiskAssessmentState } from "state/feature/risk-assessment/risk-assessment.slice";

const AdditionalQuestions = () => {
  const dispatch = useDispatch();
  const { isLoading, pages } = useSelector(getRiskAssessmentState);

  useEffect(() => {
    if (!pages.additional.sections || pages.additional.sections.length === 0) {
      dispatch(getSectionsByPageAsync({ pageId: Pages.Additional }));
    }
  }, [dispatch, pages.additional.sections]);

  useEffect(() => {
    if (
      pages.additional.sections &&
      pages.additional.sections.length > 0 &&
      pages.additional.sectionDetails.length === 0
    ) {
      dispatch(getQuestionsBySectionsAsync({ sections: pages.additional.sections, pageId: Pages.Additional }));
    }
  }, [dispatch, pages.additional.sections, pages.additional.sectionDetails]);

  return (
    <>
      {(isLoading || pages.additional.isLoading) && <CommonState type={CommonStateType.LOADING} />}
      {!isLoading &&
        pages.additional &&
        pages.additional.sectionDetails &&
        pages.additional.sectionDetails.length > 0 &&
        pages.additional.sectionDetails.map((data: any) => {
          return (
            <Section key={data.sectionId} sectionHeading={""}>
              <>
                {data.questions &&
                  data.questions.length > 0 &&
                  data.questions.map((item: any, index: any) => {
                    return (
                      <div key={item.questionId + "_" + index} className="question-section">
                        {item.questionType !== "conditional-section" && item.questionType !== "section-title" && (
                          <div className="question-section-title">{item.title}</div>
                        )}
                        <div className="question-section-checkbox-grid">
                          <RiskAssessmentFormInputs
                            questionId={item.questionId}
                            options={item.selectionValues}
                            inputType={item.questionType}
                            selectionType={item.selectionType}
                            sectionId={data.sectionId}
                            maxLength={item.maxLength}
                            className={`${item.questionType}-${item.questionId}`}
                            qTitle={item.title}
                            pageId={Pages.Additional}
                            order={item.order}
                            sectionOrder={data.order}
                          />
                        </div>
                      </div>
                    );
                  })}
              </>
            </Section>
          );
        })}
    </>
  );
};

export default AdditionalQuestions;
