import React from "react";
import "./patient-phone-numbers-dropdown.style.scss";
const PatientPhoneNumbersDropdown = ({
  contactDetails,
  id,
  patient,
  className,
  handleClick,
}: {
  contactDetails: Array<any>;
  id: string;
  patient: string;
  className?: string;
  handleClick: (phoneNumber: string) => void;
}) => {
  return (
    <div id={id} className={`phone-numbers-container ${className ? className : ""}`}>
      {contactDetails.map(({ isPreferred, type, phoneNumber }, index) => {
        return (
          <div
            className={`phone-number-details ${isPreferred ? "highlighted" : ""}`}
            onClick={() => handleClick(phoneNumber)}
            key={`${phoneNumber}-${index}`}
          >
            <div className="name">
              <div className="name-text">{patient}</div>
              {phoneNumber && <div className="phone-number">{phoneNumber}</div>}
            </div>
            <div className="label">
              {type}
              {isPreferred && <span>(preferred)</span>}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PatientPhoneNumbersDropdown;
