import { createAsyncThunk } from "@reduxjs/toolkit";
import { getConfig } from "config/config";
import { axiosInstance } from "shared/axios-instance";
import { IGetAdmitTaskDataType, IPutAdmitTaskDataType } from "state/types/admit-task.type";
import { IFacility } from "state/types/toc-management-slice.type";

export const getAdmitTaskData = createAsyncThunk(
  "admit-task/getAdmitTaskData",
  async (requestPayload: IGetAdmitTaskDataType, { rejectWithValue }) => {
    const response = await axiosInstance.get(
      `${getConfig().taskManagementBase}/api/v1/TOCTask/admit-task/${requestPayload.intakeId}/${requestPayload.taskId}`
    );
    if (response.status === 200) {
      return response.data;
    } else {
      rejectWithValue({});
    }
  }
);

export const postAdmitTaskData = createAsyncThunk(
  "admit-task/postAmitTaskData",
  async (requestPayload: IPutAdmitTaskDataType, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `${getConfig().taskManagementBase}/api/v1/TOCTask/admit-task`,
        requestPayload
      );
      return response;
    } catch (error: any) {
      const message = error?.response?.data?.errors?.[0]?.message ?? null;
      return rejectWithValue(message);
    }
  }
);

export const getAllFacilities = createAsyncThunk("admit-task/getAllFacilities", async (intakeId: string) => {
  const response = await axiosInstance.get(
    `${getConfig().efusionBase}/api/v2/ExtProvider/getallByIntake/${intakeId}/false/true/false/false/true`,
    { params: {} }
  );

  return response.data.map(
    (el: any) =>
      ({
        id: el.ID,
        firstName: el.FirstName,
        lastName: el.LastName,
        providerName: el.ProviderName,
        providerPhone: el.ProviderPhone,
        providerAddress1: el.ProviderAddress1,
        providerCity: el.ProviderCity,
        providerState: el.ProviderState,
        providerZip: el.ProviderZip,
        providerTypeName: el.ProviderTypeName,
        distance: "",
      } as IFacility)
  );
});

export const providerDetails = createAsyncThunk("admit-task/providerDetails", async (providerId: number) => {
  const response = await axiosInstance.get(
    `${getConfig().taskManagementBase}/api/v1/TOCTask/provider-send-order/${providerId}`
  );
  if (response.status === 204) {
    return null;
  }
  return response.data;
});
