import React, { useEffect, useState } from "react";
import SidePopup from "../side-popup.component";
import { useDispatch, useSelector } from "react-redux";
import "./compose-message.styles.scss";
import {
  getMessagingState,
  resetPatientInConversation,
  saveConversationId,
  saveConversationParticipants,
  saveParticipantsDetails,
  saveTwilioConversationId,
  setMessagePhysician,
  setUserIdforMessage,
} from "state/feature/messaging/messaging.slice";
import { resetAllContacts } from "state/feature/contact/contact.slice";

import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import { useAppDispatch } from "state/store";
import ChatSidePanel from "components/ChatSidePanel/chat-side-panel.component";
import { ComposeMessageProps } from "./type";
import { getPatientFullName, getTextWithElipsis, isEmptyString } from "shared/methods/utilityFunctions";
import moment from "moment";
import { setIsOpenChatModal } from "state/feature/common/common.slice";
import { OpenFrom } from "shared/enums/chat.enum";
import { GENERAL } from "components/ChatSidePanel/components/Compose/compose.constant";
import { getAuth } from "state/feature/auth/auth.slice";
import { CustomToolTip } from "components/CustomTooltip/custom-tooltip.component";
import { getPatientDetailsForConversationAsync } from "state/feature/messaging/messaging.action";

export const ComposeMessage = ({ isOpen, onClose, className, openFrom }: ComposeMessageProps) => {
  useAxiosAuthenticated();
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const { user } = useSelector(getAuth);
  const [selectedList, setSelectedList] = useState<any[]>([]);
  const [conversation, setConversation] = useState<any>(null);
  const { selectedConversation, patientInConversation } = useSelector(getMessagingState);
  const [showPatientDetails, setShowPatientDetails] = useState(false);

  useEffect(() => {
    if (
      isOpen &&
      selectedConversation.conversationId &&
      selectedConversation.conversationId !== -1 &&
      selectedConversation.subject.toLowerCase() !== GENERAL.toLowerCase()
    ) {
      appDispatch(getPatientDetailsForConversationAsync({ conversationId: selectedConversation.conversationId }))
        .unwrap()
        .then((res) => {
          if (res) {
            setShowPatientDetails(true);
          }
        });
    }

    return () => {
      dispatch(resetPatientInConversation(null));
      setShowPatientDetails(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConversation.conversationId, selectedConversation, appDispatch, dispatch]);

  const getPatientDOB = () => {
    if (conversation && !!conversation.patientDOB && conversation.subject.toLowerCase() !== GENERAL.toLowerCase()) {
      return `(${moment(conversation.patientDOB).format("MM/DD/YYYY")})`;
    } else if (
      selectedConversation &&
      selectedConversation.conversationId !== -1 &&
      selectedConversation.patientDOB &&
      selectedConversation.subject.toLowerCase() !== GENERAL.toLowerCase()
    ) {
      return `(${moment(selectedConversation.patientDOB).format("MM/DD/YYYY")})`;
    }

    return "";
  };

  const getHeading = () => {
    if (conversation || (selectedConversation && selectedConversation.conversationId !== -1)) {
      const subject = conversation
        ? getTextWithElipsis(conversation.subject, 25)
        : getTextWithElipsis(selectedConversation.subject, 25);
      const participants = conversation
        ? conversation.name
            .split(",")
            .filter((name: string) => {
              return name.trim() !== `${user.firstName} ${user.lastName[0]}`;
            })
            .toString()
        : selectedConversation.participants;
      return (
        <div className="two-liner-heading">
          <div
            data-tip
            data-for={participants}
            className={`participants ${showPatientDetails ? "participants-with-patient" : ""}`}
          >
            {participants}
          </div>
          <CustomToolTip text={participants} id={participants} place={"bottom"} />
          {!isEmptyString(subject) && subject === GENERAL && (
            <div className="subject">
              Sub: {subject} {getPatientDOB()}
            </div>
          )}
          {showPatientDetails && (
            <div className="patient-details">
              <div className="patient-name">
                <label>Patient</label> :{" "}
                {getPatientFullName({
                  firstName: patientInConversation?.firstName,
                  lastName: patientInConversation?.lastName,
                })}
              </div>
              <div className="patient-dob">
                <label>Patient DOB</label>: {patientInConversation?.dob}
              </div>
              <div className="procedure">
                <label>Procedure</label>:{" "}
                {!!patientInConversation?.procedureName ? patientInConversation?.procedureName : "-"}
              </div>
              <div className="procedure-date">
                <label>Procedure Date</label>:{" "}
                {!!patientInConversation?.procedureDate ? patientInConversation?.procedureDate : "-"}
              </div>
            </div>
          )}
        </div>
      );
    } else {
      return "New Message";
    }
  };

  const handleSidePopupClose = () => {
    setSelectedList([]);
    setConversation(null);
    appDispatch(saveConversationId(-1));
    dispatch(setUserIdforMessage(""));
    dispatch(setMessagePhysician(null));
    appDispatch(saveConversationParticipants(""));
    appDispatch(saveParticipantsDetails([]));
    appDispatch(saveTwilioConversationId(""));
    appDispatch(setIsOpenChatModal({ isOpen: false, openFrom: OpenFrom.COMPOSE }));
    onClose();
  };

  const clearDetails = () => {
    setConversation(null);
    dispatch(resetAllContacts());
  };

  const addItemInSelectedList = (items: any) => {
    clearDetails();
    setSelectedList(items);
  };

  return (
    <SidePopup
      isOpen={isOpen}
      onClose={() => {
        handleSidePopupClose();
      }}
      heading={getHeading()}
      className={`new-message ${className ? className : ""}`}
      openFrom={openFrom}
      isBackArrowInMobile={true}
      headerClass={showPatientDetails ? "header-with-patient" : ""}
      showPatientDetails={showPatientDetails}
    >
      <ChatSidePanel
        addItemInSelectedList={addItemInSelectedList}
        selectedList={selectedList}
        conversationState={conversation}
        setConversation={setConversation}
        openFrom={openFrom}
        isOpen={isOpen}
      />
    </SidePopup>
  );
};
