export const data = [
  {
    name: "Risk Assessment",
    standardRisk: true,
    highRisk: true,
  },
  { name: "Pre-Surgery Call", standardRisk: true, highRisk: true },
  { name: "Acute Check", standardRisk: true, highRisk: true },
  { name: "Day 1 Post-Acute", standardRisk: true, highRisk: true },
  { name: "1 Week Post-Acute", standardRisk: true, highRisk: true },
  { name: "2 Week Post-Acute", standardRisk: false, highRisk: true },
  { name: "3 Week Post-Acute", standardRisk: true, highRisk: true },
  { name: "4 Week Post-Acute", standardRisk: false, highRisk: true },
  { name: "6 Week Post-Acute", standardRisk: true, highRisk: true },
  { name: "8 Week Post-Acute", standardRisk: false, highRisk: true },
  { name: "10 Week Post-Acute", standardRisk: false, highRisk: true },
  { name: "12 Week Post-Acute", standardRisk: true, highRisk: true },
];
