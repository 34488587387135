import Status from "components/status/status.component";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { OnlineOfflineStatus } from "shared/enums/online-offline.enum";
import { getNavigatorOriginalOnlineOfflineStatus } from "shared/methods/utilityFunctions";
import { postNavigatorStatus } from "state/feature/navigator/navigator.action";
import { getNavigatorState, toggleStatusModal } from "state/feature/navigator/navigator.slice";
import { useAppDispatch } from "state/store";
import "./status-picker.styles.scss";

type StatusPrickerProps = {
  statusText: string;
};

const StatusPicker = ({ statusText }: StatusPrickerProps) => {
  const [text, setText] = useState<string>();
  const appDispatch = useAppDispatch();
  const { currentUserProfile, holidayList } = useSelector(getNavigatorState);
  useEffect(() => {
    setText(statusText);
  }, [statusText]);

  const onClickStatusChange = async () => {
    if (statusText.toLowerCase() !== OnlineOfflineStatus.FORWARDED.toLowerCase()) {
      if (currentUserProfile) {
        const availabilityStatusByWorkingHours = getNavigatorOriginalOnlineOfflineStatus(
          currentUserProfile,
          holidayList
        );
        if (availabilityStatusByWorkingHours === OnlineOfflineStatus.ONLINE) {
          if (statusText.toLowerCase() === OnlineOfflineStatus.ONLINE.toLowerCase()) {
            appDispatch(toggleStatusModal(true));
          } else {
            await appDispatch(
              postNavigatorStatus({ status: OnlineOfflineStatus.ONLINE, expirationMinutes: 0, revertStatus: true })
            );
          }
        } else {
          if (statusText.toLowerCase() === OnlineOfflineStatus.OFFLINE.toLowerCase()) {
            appDispatch(toggleStatusModal(true));
          } else {
            await appDispatch(
              postNavigatorStatus({ status: OnlineOfflineStatus.OFFLINE, expirationMinutes: 0, revertStatus: true })
            );
          }
        }
      }
    }
  };
  return (
    <div
      onClick={() => {
        onClickStatusChange();
      }}
      className={`status-picker ${text ? text.toLowerCase() : ""}`}
    >
      {text === OnlineOfflineStatus.ONLINE && <Status status={OnlineOfflineStatus.ONLINE.toLocaleLowerCase()} />}
      {text === OnlineOfflineStatus.OFFLINE && <Status status={OnlineOfflineStatus.OFFLINE.toLocaleLowerCase()} />}
      {text === OnlineOfflineStatus.FORWARDED && <Status status={OnlineOfflineStatus.FORWARDED.toLocaleLowerCase()} />}
      <div className="status">{text}</div>
    </div>
  );
};

export default StatusPicker;
