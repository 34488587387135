import { createAsyncThunk } from "@reduxjs/toolkit";
import { getConfig } from "config/config";
import { axiosInstance } from "shared/axios-instance";
import {
  IEpisodeEditRequest,
  IEpisodesGetRequest,
  IProviderEditRequestPayload,
  IProviderGetRequest,
} from "state/types/admin-slice.type";

export const getEpisodesAsync = createAsyncThunk(
  "admin/getEpisodesAsync",
  async (requestPayload: IEpisodesGetRequest, { rejectWithValue }) => {
    const response = await axiosInstance.post(`${getConfig().taskManagementBase}/api/v1/Episode/list`, requestPayload);
    if (response.status === 200) {
      return response.data;
    } else {
      rejectWithValue({});
    }
  }
);

export const getClientsAsync = createAsyncThunk("admin/getClientsAsync", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(`${getConfig().userManagementBase}/api/v1/UserManagement/clients`);
    return response.data;
  } catch (err) {
    rejectWithValue({});
  }
});

export const getStandardCareplans = createAsyncThunk("admin/getStandardCareplans", async (_, { rejectWithValue }) => {
  const response = await axiosInstance.get(`${getConfig().taskManagementBase}/api/v1/Episode/standard-careplans`);
  if (response.status === 200) {
    return response.data;
  } else {
    rejectWithValue({});
  }
});

export const editEpisodeAsync = createAsyncThunk(
  "admin/editEpisodeAsync",
  async (requestPayload: IEpisodeEditRequest, { rejectWithValue }) => {
    const response = await axiosInstance.put(`${getConfig().taskManagementBase}/api/v1/Episode`, requestPayload);
    if (response.status === 200) {
      return response.data;
    } else {
      rejectWithValue({});
    }
  }
);

export const getProvidersTabProviderList = createAsyncThunk(
  "admin/getProvidersTabProviderList",
  async (requestPayload: IProviderGetRequest, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`${getConfig().navigatorBase}/api/v1/providers`, requestPayload);
      if (response.data && response.status === 200) {
        return { records: response.data?.records ?? [], totalCount: response.data?.totalCount ?? 0 };
      }
      return { records: [], totalCount: 0 };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAllPrivelegesListAsync = createAsyncThunk(
  "admin/getAllPrivelegesListAsync",
  async (clientId: number) => {
    const response = await axiosInstance.get(`${getConfig().efusionBase}/api/v2/ExtProvider/acuteCareFacility`, {
      params: { clientId },
    });
    return response.data;
  }
);

export const updateProviderFacilitesAsync = createAsyncThunk(
  "admin/updateProviderFacilites",
  async (requestPayload: IProviderEditRequestPayload) => {
    const response = await axiosInstance.put(`${getConfig().navigatorBase}/api/v1/providers`, requestPayload);
    return response;
  }
);
