import Modal from "components/Modal/modal.component";
import Button from "components/Button/button.component";
import "./generic-popup-modal.styles.scss";
import ButtonLoader from "components/ButtonLoader/button-loader.component";

type GenericPopupModalProps = {
  heading?: string;
  submitButtonTitle: string;
  content: string;
  isOpen: boolean;
  handleCancelClick: () => void;
  handleSubmitClick: () => void;
  isButtonLoading?: boolean;
  className?: string;
  cancelButtonVisible: boolean;
};

const GenericPopupModal = ({
  heading,
  submitButtonTitle,
  content,
  isOpen,
  handleCancelClick,
  handleSubmitClick,
  isButtonLoading = false,
  className,
  cancelButtonVisible,
}: GenericPopupModalProps) => {
  return (
    <Modal isOpen={isOpen} className={`center save-risk-assessment-modal-container ${className ? className : ""}`}>
      <div className="save-risk-assessment-container">
        <div className="header">{heading}</div>
        <div className="content">{content}</div>
        <div className="button-section">
          {cancelButtonVisible && (
            <Button text="Cancel" className="white-button cancel-button" onClick={handleCancelClick} />
          )}
          {isButtonLoading ? (
            <div className="green-button reload-button">
              <ButtonLoader />
            </div>
          ) : (
            <Button text={submitButtonTitle} className="green-button reload-button" onClick={handleSubmitClick} />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default GenericPopupModal;
