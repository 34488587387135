import { useEffect } from "react";
import Header from "components/Header/header.component";
import Breadcrumb from "components/Breadcrumb/breadcrumb.component";
import Sort from "components/Sort/sort.component";
import { useSelector } from "react-redux";
import { managerContactTableColumns } from "shared/constant/table";
import "./manager-contact.styles.scss";
import { clearIdForNavigatorEpisode, getNavigatorState } from "state/feature/navigator/navigator.slice";
import { getNavigatorsEpisodesAsync } from "state/feature/navigator/navigator.action";
import { useAppDispatch } from "state/store";
import EpisodesTable from "./components/episodes-table/episodes-table.component";

const ManagerContact = () => {
  const pathname = location.pathname;
  const appDispatch = useAppDispatch();
  const { episodes, idForNavigatorEpisode } = useSelector(getNavigatorState);
  useEffect(
    () => () => {
      appDispatch(clearIdForNavigatorEpisode());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (idForNavigatorEpisode != "") {
      appDispatch(getNavigatorsEpisodesAsync({ navigatorId: idForNavigatorEpisode }));
    }
  }, [idForNavigatorEpisode, appDispatch]);

  return (
    <div id="contact-container">
      <Header className="contact">
        <Breadcrumb
          pathname={pathname
            .replace("contact", "navigator-management")
            .replace("%20", " ")
            .replace("/navigator-management/", "/navigator-management/Episodes of ")}
        />
      </Header>
      <table className="navigator-episodes-container-table">
        <tr>
          <td>
            <table>
              <tr>
                {managerContactTableColumns.map(({ name, sorting }) => {
                  return (
                    <th key={name}>
                      <div className="navigator-episodes-table-header-content">
                        <div className="name">{name}</div>
                        <div>{sorting && <Sort />}</div>
                      </div>
                    </th>
                  );
                })}
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <div className="navigator-episodes-table-container">
              {episodes && <EpisodesTable tableClassname="navigator-episodes-table" episodes={episodes} />}
            </div>
          </td>
        </tr>
      </table>
    </div>
  );
};

export default ManagerContact;
