import "./unsaved-data-modal.styles.scss";
import { batch, useDispatch, useSelector } from "react-redux";
import Modal from "../modal.component";
import Button from "components/Button/button.component";
import {
  getCommonState,
  setIsLogoutButtonClicked,
  setIsUnsavedDataModalVisible,
} from "state/feature/common/common.slice";
import { UNSAVED_CHANGES, UNSAVED_CHANGES_SUB_HEADING } from "shared/constant/commonConstants";
import { UnsavedDataProps } from "./unsaved-data-modal.props";

const UnsavedDataModal = (props: UnsavedDataProps) => {
  const { handleOnClick } = props;
  const commonState = useSelector(getCommonState);
  const dispatch = useDispatch();
  const handleOnNo = () => {
    batch(() => {
      dispatch(setIsUnsavedDataModalVisible(false));
      dispatch(setIsLogoutButtonClicked(false));
    });
  };

  const handleOnYes = () => {
    dispatch(setIsUnsavedDataModalVisible(false));
    handleOnClick();
  };

  return (
    <Modal isOpen={commonState?.modal.isUnsavedDataModalVisible} className="center unsaved-data-popup">
      <div className="unsaved-data-container">
        <div className="header">{UNSAVED_CHANGES}</div>
        <div className="content">{UNSAVED_CHANGES_SUB_HEADING}</div>
        <div className="button-section">
          <Button text="No" className="white-button no-button" onClick={handleOnNo} />
          <Button text="Yes" className="green-button yes-button" onClick={handleOnYes} />
        </div>
      </div>
    </Modal>
  );
};

export default UnsavedDataModal;
