import { useEffect } from "react";
import CheckOnPatientPresentation from "./check-on-patient.component";
import { useAppDispatch } from "state/store";
import {
  getAllFacilitiesAsync,
  getCheckOnPatientDetailsAsync,
  getProviderOptionsAsync,
  getTOCOffReasonsAsync,
  saveCheckOnPatientAsync,
} from "state/feature/check-on-patient/check-on-patient.action";
import { HandleSubmitDelayTask } from "components/Modal/delay-modal/delay-modal.types";
import { useSelector } from "react-redux";
import {
  getCheckOnPatientSlice,
  resetCheckOnPatientWhenChangingTab,
  setIsDirty,
} from "state/feature/check-on-patient/check-on-patient.slice";
import {
  expandTaskBoard,
  getBrowserState,
  getCurrentPageTaskState,
  getTaskManagementState,
  removeTab,
  setIsCurrentTaskDirty,
  setIsCurrentTaskTouched,
  setLoadTaskList,
  setShouldPerformPostSubmitAction,
  setShowUnsavedChangesModal,
} from "state/feature/task-management/task-management.slice";
import { setIsOpenDelayModal, setIsOpenManagerTaskApprovalModal } from "state/feature/common/common.slice";
import { checkTaskIsCompletedOrClosed } from "shared/methods/taskSpecificMethods";
import { getPatientFullName, isEmptyString } from "shared/methods/utilityFunctions";
import { getNavigatorState } from "state/feature/navigator/navigator.slice";
import { ISaveCheckOnPatientRequestPayload } from "state/types/check-on-patient.type";
import moment from "moment";
import {
  getDelayAttempts,
  getTaskDelayStatuses,
  setLockState,
} from "state/feature/task-management/task-management.action";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import { ITab } from "state/types/task-management-slice.type";
import { toast } from "react-toastify";
import { TOAST_MESSAGES } from "pages/login/common/constants";
import { getEpisodesSlice } from "state/feature/episodes/episodes.slice";
import { TaskStatus } from "shared/enums/tasks-status.enum";

const CheckOnPatient = () => {
  useAxiosAuthenticated();
  const appDispatch = useAppDispatch();
  const { currentUserProfile } = useSelector(getNavigatorState);
  const { currentTab, pages, currentPage } = useSelector(getBrowserState);
  const { intakeId, taskId } = currentTab!;
  const { patientDetails, taskPayload, delayTask, episode } = useSelector(getCurrentPageTaskState(taskId));
  const { checkOnPatientDetails, isDirty, isValueChange, selectedCheckOnPatient, providerOptions, isLoading } =
    useSelector(getCheckOnPatientSlice);
  const { redirectParams } = useSelector(getTaskManagementState);
  const { episodeDetails } = useSelector(getEpisodesSlice);

  const showDelayNote =
    (!isLoading &&
      checkOnPatientDetails &&
      checkOnPatientDetails?.taskStatusId !== TaskStatus.CLOSED &&
      checkOnPatientDetails?.taskStatusId !== TaskStatus.COMPLETED &&
      delayTask &&
      episode &&
      episode?.patientAndEpisodeDetails?.coachPhone !== null &&
      delayTask?.delayAttempts &&
      delayTask?.delayAttempts?.length > 0 &&
      delayTask?.delayAttempts[0]?.taskDelayStatusId === 1) ??
    false;
  const checkRequiredFields = () => {
    return (
      (selectedCheckOnPatient.isOfftrack &&
        (selectedCheckOnPatient.offTrackReason.key === "" ||
          selectedCheckOnPatient.offtrackStartDate === null ||
          selectedCheckOnPatient.offtrackDetails === "")) ||
      selectedCheckOnPatient.provider.id === -1 ||
      isEmptyString(selectedCheckOnPatient.dayVisitActual) ||
      isEmptyString(selectedCheckOnPatient.dayVisitCompleted) ||
      selectedCheckOnPatient.actualDischargeDate === null ||
      (!isEmptyString(selectedCheckOnPatient.dayVisitCompleted) &&
        !isEmptyString(selectedCheckOnPatient.dayVisitActual) &&
        parseInt(selectedCheckOnPatient.dayVisitCompleted) > parseInt(selectedCheckOnPatient.dayVisitActual)) ||
      (checkOnPatientDetails?.tocTaskDetails.serviceTypeName.toLowerCase() === "ach" &&
        selectedCheckOnPatient.procedureDate === null)
    );
  };

  const isPageDirty = (tab: ITab) => {
    const page = pages.find((el) => el.taskId === tab.taskId);
    if (!page) {
      return false;
    }
    return page.isDirty;
  };
  const handleCancelButton = () => {
    if (isPageDirty(currentTab!)) {
      appDispatch(setShowUnsavedChangesModal(true));
    } else {
      appDispatch(setIsDirty(false));
      appDispatch(setLockState({ intakeId: currentTab!.intakeId, taskId: currentTab!.taskId, isLocked: false }));
      appDispatch(removeTab(currentTab!.taskId));
    }
  };

  const handleSubmitButton = async (isSaveDraft?: boolean) => {
    if (!isSaveDraft) {
      appDispatch(setIsDirty(true));
    }
    if (intakeId && taskId && currentUserProfile && (!checkRequiredFields() || isSaveDraft)) {
      const {
        actualDischargeDate,
        dayVisitActual,
        dayVisitCompleted,
        dischargeFromService,
        offTrackReason: isOffTrackReason,
        isOfftrack,
        offtrackDetails: isOfftrackDetails,
        offtrackStartDate: isOfftrackStartDate,
        notes,
        provider,
        procedureDate,
      } = selectedCheckOnPatient;
      const offtrack =
        isOfftrack && (!isSaveDraft || (isSaveDraft && isOffTrackReason.key !== ""))
          ? {
              offTrackId: parseInt(isOffTrackReason.key),
              reason: isOffTrackReason.name,
              startDate: isOfftrackStartDate ? moment(isOfftrackStartDate).format("MM/DD/YYYY") : "",
              details: isOfftrackDetails,
            }
          : null;
      const providerVal = providerOptions.find((val) => val.ID === provider.id);
      const requestPayload: ISaveCheckOnPatientRequestPayload = {
        intakeId: parseInt(intakeId),
        taskId: taskId,
        actualDischargeDate: actualDischargeDate ? moment(actualDischargeDate).format("MM/DD/YYYY") : "",
        providerId: provider.id ?? -1,
        actualDayVisit: parseInt(dayVisitActual),
        completedDayVisit: parseInt(dayVisitCompleted),
        isDischarged: dischargeFromService,
        isSaveDraft: isSaveDraft ?? false,
        notes: notes,
        offTrack: offtrack,
        peopleAssigned: [],
        provider: providerVal?.ProviderName ?? provider?.name,
        providerType: providerVal?.ProviderTypeName ?? "",
        updateUserId: currentUserProfile?.id,
        updateDate: moment().utc().format(),
        updateUser: getPatientFullName({
          firstName: currentUserProfile?.firstName,
          lastName: currentUserProfile?.lastName,
        }),
        isEditable: checkOnPatientDetails?.isEditable ?? true,
        procedureDate: procedureDate ? moment(procedureDate).format("MM/DD/YYYY") : null,
      };
      const resp = await appDispatch(saveCheckOnPatientAsync(requestPayload)).unwrap();
      if (resp.data && resp.status === 200) {
        appDispatch(setLoadTaskList(true));
        //write code to close the tab
        if (!isSaveDraft) {
          if (redirectParams) {
            setTimeout(() => {
              toast.success(TOAST_MESSAGES.DATA_SAVE_SUCCESSFULLY, { containerId: "main" });
            }, 1000);
          } else {
            appDispatch(setShouldPerformPostSubmitAction({ taskId, performAction: true }));
          }
        }
      }
    }
  };

  const handleOpenDelayButton = () => {
    appDispatch(expandTaskBoard(false));
    appDispatch(setIsOpenDelayModal(true));
  };

  const handleSubmitDelayTask = async (handleDelay: HandleSubmitDelayTask) => {
    await handleSubmitButton(true);
    handleDelay(taskId);
  };

  const handleOpenManagerTaskApprovalButton = () => {
    appDispatch(setIsOpenManagerTaskApprovalModal(true));
  };
  useEffect(() => {
    if (intakeId && taskId && !taskPayload) {
      appDispatch(getTaskDelayStatuses(taskId));
      appDispatch(
        getDelayAttempts({
          intakeId: parseInt(intakeId),
          taskId: taskId,
        })
      );
      appDispatch(getCheckOnPatientDetailsAsync({ intakeId: intakeId, taskId })).then(() => {
        appDispatch(getProviderOptionsAsync(intakeId));
        appDispatch(getTOCOffReasonsAsync());
      });
    }
  }, [intakeId, taskId, appDispatch, taskPayload]);

  useEffect(() => {
    if (checkOnPatientDetails && currentPage?.patientDetails?.clientId) {
      appDispatch(getAllFacilitiesAsync(currentPage.patientDetails.clientId));
    }
  }, [appDispatch, checkOnPatientDetails, currentPage?.patientDetails?.clientId]);

  useEffect(() => {
    if (!delayTask.delayStatuses.length) {
      appDispatch(getTaskDelayStatuses(currentTab!.taskId));
    }
  }, [appDispatch, currentTab, delayTask.delayStatuses]);

  useEffect(() => {
    appDispatch(setIsCurrentTaskTouched({ taskId, isTouched: true }));
    if (isValueChange) {
      appDispatch(setIsCurrentTaskDirty({ taskId, isDirty: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValueChange]);

  useEffect(() => {
    return () => {
      appDispatch(resetCheckOnPatientWhenChangingTab());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <CheckOnPatientPresentation
      handleCancelButton={handleCancelButton}
      handleOpenDelayButton={handleOpenDelayButton}
      handleSubmitButton={handleSubmitButton}
      handleSubmitDelayTask={handleSubmitDelayTask}
      isTaskCompletedOrClosed={checkTaskIsCompletedOrClosed(checkOnPatientDetails?.taskStatusId)}
      handleOpenManagerTaskApprovalButton={handleOpenManagerTaskApprovalButton}
      patientDetails={patientDetails}
      showDelayNotes={showDelayNote}
    />
  );
};

export default CheckOnPatient;
