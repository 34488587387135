import Select from "react-select";
import { singleDropDownWithSearchStyles } from "./single-select-dropdown-with-search.styles";
import "./single-select-dropdown-with-search.styles.scss";
import { useState } from "react";
import getHighlightedText from "components/HighLightedText/high-lighted-text.component";

export type OptionType = {
  label: string;
  value: string;
};

const SingleSelectDropdownWithSearch = ({
  options,
  name,
  isDisabled,
  value,
  onChange,
  defaultValue,
  isSearchable = true,
}: { options: OptionType[] } & { name: string } & { isDisabled: boolean } & { value: any } & {
  onChange: (value: any) => void;
} & { defaultValue: any } & { isSearchable?: boolean }) => {
  const [inputValue, setInputValue] = useState("");
  return (
    <Select
      className="basic-single single-select-dropdown-with-search"
      classNamePrefix="select"
      isDisabled={isDisabled}
      isLoading={false}
      isClearable={false}
      isRtl={false}
      isSearchable={isSearchable}
      name={name}
      options={options}
      placeholder={name}
      styles={singleDropDownWithSearchStyles}
      value={value !== undefined ? value : defaultValue}
      openMenuOnClick
      openMenuOnFocus
      closeMenuOnSelect
      tabSelectsValue={false}
      onChange={(newValue) => {
        onChange(newValue);
        setInputValue("");
      }}
      inputValue={inputValue}
      onInputChange={(val) => {
        setInputValue(val);
      }}
      onMenuClose={() => {
        setInputValue(inputValue);
      }}
      onBlur={(e) => {
        e.preventDefault();
        setInputValue(inputValue);
      }}
      formatOptionLabel={(data) => {
        return <div key={data.label}>{getHighlightedText(data.label, inputValue)}</div>;
      }}
    />
  );
};
export default SingleSelectDropdownWithSearch;
