import Icon from "components/Icon/icon.component";
import { INotification } from "state/types/notification-slice-type";
import "./notification-row.styles.scss";
import { isMobile } from "react-device-detect";
import moment from "moment";
import { useEffect, useState } from "react";
import { getNotificationDescription } from "shared/methods/utilityFunctions";

interface INotificationRowProps {
  notification: INotification;
  onClick: () => void;
}

const NotificationRow = (notificationProps: INotificationRowProps) => {
  const { notification, onClick } = notificationProps;
  const [titleContent, setTitleContent] = useState("");
  const iconName = notification.actionType ? notification.actionType.split("_").join("-").toLowerCase() : null;

  useEffect(() => {
    switch (notification.actionType) {
      case "NEW_MESSAGE":
        setTitleContent(`${notification.eventTitle} has sent you a message.`);
        break;
      case "MISSED_CALL":
        setTitleContent(`You have a missed call from ${notification.eventTitle}.`);
        break;
      case "NEW_TOC":
        setTitleContent(`${notification.eventDesc}`);
        break;
      default:
        setTitleContent(notification.eventTitle);
    }
  }, [notification]);

  if (!isMobile) {
    return (
      <div
        className={`notification ${notification.isRead ? "read" : "unread"}`}
        onClick={(e) => {
          e.stopPropagation();
          onClick();
        }}
      >
        <div className={`${iconName}-img-container`}>
          <Icon icon={`${iconName}-icon`} className={`${iconName}-img`} size={36} />
        </div>
        <div className="notification-content notification-title"> {getNotificationDescription(notification)} </div>
        <div className="notification-timeStamp">{moment(notification.createdOn).fromNow()}</div>
        {isMobile && <Icon icon="right-arrow" size={14} className="notification-right-caret" />}
      </div>
    );
  } else {
    return (
      <div
        className={`notification ${notification.isRead ? "read" : "unread"}`}
        onClick={(e) => {
          e.stopPropagation();
          onClick();
        }}
      >
        <div className={`${iconName}-img-container`}>
          <Icon icon={`${iconName}-icon`} className={`${iconName}-img`} size={36} />
        </div>
        <div className="notification-content">
          <span className="notification-title">{getNotificationDescription(notification)}</span>
          <span className="notification-timeStamp">{moment(notification.createdOn).fromNow()}</span>
        </div>
        {isMobile && <Icon icon="right-arrow" size={14} className="notification-right-caret" />}
      </div>
    );
  }
};

export default NotificationRow;
