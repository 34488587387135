import CommonState from "components/common-state/common-state.component";
import { CommonStateType } from "components/common-state/common-state.enum";

import "./styles.scss";

const AccessDenied = () => {
  return <CommonState type={CommonStateType.ACCESS_DENIED} />;
};

export default AccessDenied;
