import Button from "components/Button/button.component";
import "./change-tnav-task-owner-modal.styles.scss";
import CustomDropDown from "components/CustomDropdown/custom-dropdown.component";
import { useState } from "react";
import Modal from "components/Modal/modal.component";
import ModalHeader from "components/ModalHeader/modal-header.component";

import { IChangeTnavTaskOwnerModalProps } from "./props.type";

const ChangeTnavTaskOwnerModal = function (props: IChangeTnavTaskOwnerModalProps) {
  const {
    isOpen,
    taskName,
    tnavList,
    handleCancelButton: onCancel,
    handleSubmit: onCreate,
    ownerId,
    ownerName,
    isLoadingButton,
  } = props;
  const [selectedTnav, setSelectedTnav] = useState({ key: ownerId, name: ownerName });
  const [isDirty, setIsDirty] = useState(false);

  return (
    <Modal
      isOpen={isOpen}
      className="center change-tnav-task-owner-modal-container"
      contentClass=" change-tnav-task-owner-modal-main"
      modalHeader={
        <ModalHeader iconClassName="cross-container" heading="Change Owner" onCloseModal={() => onCancel()} />
      }
    >
      <div id="change-tnav-task-owner-modal-content">
        <div className="content">
          <span className="bold">Task Name: </span>
          {taskName}
        </div>
        <div className="tnav-dropdown-container">
          <div className="label-content">
            <label>Select TNav</label>
          </div>
          <CustomDropDown
            placeholder="Select"
            idFieldName="key"
            dropDownMenuItems={tnavList}
            handleValueChanges={(val: any) => {
              setIsDirty(true);
              setSelectedTnav(val);
            }}
            dropDownBoxClass={""}
            value={selectedTnav}
            defaultValue={selectedTnav}
            selectionClass={""}
          />
        </div>

        <div className="button-container">
          <Button text="Cancel" onClick={onCancel} className="white-button cancel-button" />
          <div className={!isDirty || isLoadingButton ? "disabled" : ""}>
            <Button
              showLoader={isLoadingButton}
              text="Submit"
              onClick={() => onCreate({ ownerId: selectedTnav.key, ownerName: selectedTnav.name })}
              disabled={isLoadingButton || !isDirty}
              className="green-button submit-button"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ChangeTnavTaskOwnerModal;
