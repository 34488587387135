import { HeaderColumnInfo } from "components/table-with-pagination/table-with-pagination.component";
import { SortingOrderType } from "shared/enums/sorting-types.enums";

export enum AdminTab {
  PROVIDERS = "Providers",
  EPISODES = "Episodes",
}

export enum ProvidersTab {
  PROVIDER = "Provider",
}

export const EpisodesColumnsHeaderInfo: HeaderColumnInfo[] = [
  { name: "Episode Name", key: "episodeName", sortOrder: SortingOrderType.ASC },
  { name: "Program Name", key: "programName", sortOrder: SortingOrderType.DEFAULT },
  { name: "Episode ID", key: "id" },
  { name: "Episode Setting", key: "episodeSetting" },
  { name: "Workflow Domain", key: "workflowDomain" },
  { name: "Standard Careplans", key: "standardCarePlans" },
  { name: "", key: "" },
];
