import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { axiosInstance } from "shared/axios-instance";
import { NavigatorActionsEnum } from "shared/enums/navigator-actions-enum";
import { setCookie } from "shared/methods/utilityFunctions";
import { TwilioClient } from "shared/services/twilio/twilioClient";
import { getAccessTokenAsync } from "state/feature/auth/auth.action";
import { clearUser, getAuth, getTokensFromCookies, setAuthenticationTokens } from "state/feature/auth/auth.slice";
import { clearTwilioToken } from "state/feature/call/call.slice";
import { resetCommon } from "state/feature/common/common.slice";
import { resetContacts } from "state/feature/contact/contact.slice";
import { logNavigatorAction } from "state/feature/navigator/navigator.action";
import { getNavigatorState } from "state/feature/navigator/navigator.slice";
import { useAppDispatch } from "state/store";

const useAxiosAuthenticated = () => {
  const authState = useSelector(getAuth);
  const { currentUserProfile } = useSelector(getNavigatorState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const { jwtToken, refreshToken } = getTokensFromCookies();

    const requestIntercept = axiosInstance.interceptors.request.use(
      async (request: any) => {
        if (jwtToken && !request._retry) {
          request.headers = {
            ...request.headers,
            Authorization: `Bearer ${jwtToken}`,
          };
        }
        return request;
      },
      (error) => Promise.reject(error)
    );

    const responseIntercept = axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
          try {
            originalRequest._retry = true;

            const { jwtToken: newAccessToken } = await dispatch(getAccessTokenAsync({ refreshToken })).unwrap();
            const tokens = getTokensFromCookies();
            setCookie(
              "authTokens",
              JSON.stringify({
                ...tokens,
                jwtToken: newAccessToken,
              })
            );
            dispatch(setAuthenticationTokens({ ...authState.auth, jwtToken: newAccessToken }));
            originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;

            return await axiosInstance(originalRequest);
          } catch (err) {
            dispatch(
              logNavigatorAction({
                createdBy: currentUserProfile?.id ?? "",
                createdByUser: `${currentUserProfile?.firstName} ${currentUserProfile?.lastName}`,
                type: NavigatorActionsEnum.TWILIO_DISCONNECTED,
              })
            );
            dispatch(
              logNavigatorAction({
                createdBy: currentUserProfile?.id ?? "",
                createdByUser: `${currentUserProfile?.firstName} ${currentUserProfile?.lastName}`,
                type: NavigatorActionsEnum.SESSION_OUT,
              })
            );
            TwilioClient.removeInstance();
            dispatch(clearTwilioToken());
            dispatch(clearUser());
            dispatch(resetCommon());
            dispatch(resetContacts());
            sessionStorage.removeItem("riskAssessmentAnswers");
            location.replace("/");
          }
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosInstance.interceptors.request.eject(requestIntercept);
      axiosInstance.interceptors.response.eject(responseIntercept);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState]);

  return axiosInstance;
};

export default useAxiosAuthenticated;
