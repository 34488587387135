import { createSlice } from "@reduxjs/toolkit";
import {
  deleteCarePlanAsync,
  getCarePlanDataAsync,
  getFacilitiesForPlanOfCare,
  getReadmissionReasons,
  getReasonForService,
  getServiceTypes,
  postPlanOfCareDataAsync,
  putPlanOfCareDataAsync,
} from "./plan-of-care.action";
import { IPlanOfCareState, IReadmissionType, IServiceType, Reason } from "state/types/plan-of-care.type";
import { toast } from "react-toastify";
import {
  deleteSuccessMessage,
  serviceEditSuccessMessage,
  serviceSuccessMessage,
} from "components/Modal/poc-service-modal/poc-service-modal.constant";
import moment from "moment";
import { ServiceType } from "shared/enums/plan-of-care.enum";

const initialState: IPlanOfCareState = {
  isLoading: false,
  isButtonLoading: false,
  isConfirmationButtonLoading: false,
  deleteCarePlanData: null,
  id: "",
  metadata: {
    service: [],
    facilities: [],
    readmissionReason: [],
    reason: [],
  },
  selectedPlanOfCareValues: {
    serviceType: { key: "", name: "" },
    provider: { key: "", value: "" },
    activeInactive: false,
    los: "",
    admissionDate: null,
    dischargeDate: null,
    readmissionReason: { key: "", name: "" },
    otherReason: "",
    linkToPlanOfCare: null,
    reasonDetails: "",
    notes: "",
    reason: { key: "", name: "" },
  },
  isFacilitesLoading: false,
};

const planOfCareSlice = createSlice({
  name: "planOfCare",
  initialState,
  reducers: {
    setProviderForPlanOfCare(state, action) {
      state.selectedPlanOfCareValues.provider = action.payload;
    },
    setServiceTypesForPlanOfCare(state, action) {
      state.selectedPlanOfCareValues.serviceType = action.payload;
      if (action.payload.name === ServiceType.HOME_WITHOUT_SERVICES) {
        state.selectedPlanOfCareValues.provider = { key: "", value: "" };
        state.selectedPlanOfCareValues.los = "";
        state.selectedPlanOfCareValues.readmissionReason = { key: "", name: "" };
        state.selectedPlanOfCareValues.otherReason = "";
        state.selectedPlanOfCareValues.linkToPlanOfCare = null;
        state.selectedPlanOfCareValues.reasonDetails = "";
        state.selectedPlanOfCareValues.reason = { key: "", name: "" };
      }
    },
    setNotes(state, action) {
      state.selectedPlanOfCareValues.notes = action.payload;
    },
    setReasonDetails(state, action) {
      state.selectedPlanOfCareValues.reasonDetails = action.payload;
    },
    setOtherReason(state, action) {
      state.selectedPlanOfCareValues.otherReason = action.payload;
    },
    setReadmissionReason(state, action) {
      state.selectedPlanOfCareValues.readmissionReason = action.payload;
    },
    setAdmissonDate(state, action) {
      state.selectedPlanOfCareValues.admissionDate = action.payload;
    },
    setDischargeDate(state, action) {
      state.selectedPlanOfCareValues.dischargeDate = action.payload;
    },
    setLos(state, action) {
      state.selectedPlanOfCareValues.los = action.payload;
    },
    setActiveInactive(state, action) {
      state.selectedPlanOfCareValues.activeInactive = action.payload;
    },
    setReason(state, action) {
      state.selectedPlanOfCareValues.reason = action.payload;
    },
    resetPlanOfCareState(state) {
      state.selectedPlanOfCareValues = {
        serviceType: { key: "", name: "" },
        provider: { key: "", value: "" },
        activeInactive: false,
        los: "",
        admissionDate: null,
        dischargeDate: null,
        readmissionReason: { key: "", name: "" },
        otherReason: "",
        linkToPlanOfCare: null,
        reasonDetails: "",
        notes: "",
        reason: { key: "", name: "" },
      };
    },
    setCarePlanEditId(state, action) {
      state.id = action.payload;
    },
    setCarePlanLoading(state, action) {
      state.isLoading = action.payload;
    },
    setDeleteCarePlan(state, action) {
      state.deleteCarePlanData = action.payload;
    },
  },
  extraReducers(builder) {
    return (
      builder.addCase(getFacilitiesForPlanOfCare.pending, (state, action) => {
        state.isFacilitesLoading = true;
      }),
      builder.addCase(getFacilitiesForPlanOfCare.fulfilled, (state, action) => {
        state.isLoading = false;
        const isPresentProvider = action.payload.filter((facility: any) => facility.ID === action.meta.arg.id);
        if (isPresentProvider.length === 0) {
          state.selectedPlanOfCareValues.provider = { key: "", value: "" };
        }
        state.metadata.facilities = action.payload.map((facility: any) => {
          return {
            id: facility.ID,
            providerName: facility.ProviderName,
            distance: [],
          };
        });
        state.isFacilitesLoading = false;
      }),
      builder.addCase(getServiceTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.metadata.service = action.payload;
      }),
      builder.addCase(getReadmissionReasons.fulfilled, (state, action) => {
        state.isLoading = false;
        state.metadata.readmissionReason = action.payload;
      }),
      builder.addCase(getReasonForService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.metadata.reason = action.payload;
      }),
      builder.addCase(postPlanOfCareDataAsync.pending, (state) => {
        state.isButtonLoading = true;
      }),
      builder.addCase(postPlanOfCareDataAsync.fulfilled, (state, action) => {
        state.isButtonLoading = false;

        toast.success(serviceSuccessMessage, {
          toastId: "plan-of-care-modal",
          containerId: "main",
        });
      }),
      builder.addCase(postPlanOfCareDataAsync.rejected, (state) => {
        state.isButtonLoading = false;
        toast.error("Something went wrong", { toastId: "plan-of-care-error", containerId: "main" });
      }),
      builder.addCase(putPlanOfCareDataAsync.pending, (state) => {
        state.isButtonLoading = true;
      }),
      builder.addCase(putPlanOfCareDataAsync.fulfilled, (state, action) => {
        state.isButtonLoading = false;

        toast.success(serviceEditSuccessMessage, {
          toastId: "plan-of-care-modal",
          containerId: "main",
        });
      }),
      builder.addCase(putPlanOfCareDataAsync.rejected, (state) => {
        state.isButtonLoading = false;
        toast.error("Something went wrong", { toastId: "plan-of-care-error", containerId: "main" });
      }),
      builder.addCase(deleteCarePlanAsync.pending, (state, action) => {
        state.isConfirmationButtonLoading = true;
      }),
      builder.addCase(deleteCarePlanAsync.fulfilled, (state, action) => {
        state.isConfirmationButtonLoading = false;
        toast.success(deleteSuccessMessage, {
          toastId: "plan-of-care-delete-message",
          containerId: "main",
        });
      }),
      builder.addCase(deleteCarePlanAsync.rejected, (state, action) => {
        state.isConfirmationButtonLoading = false;
        toast.error("Something went wrong", { toastId: "plan-of-care-confirmation-modal", containerId: "main" });
      }),
      builder.addCase(getCarePlanDataAsync.pending, (state, action) => {
        state.isLoading = true;
      }),
      builder.addCase(getCarePlanDataAsync.fulfilled, (state, action) => {
        const selectedServiceType: IServiceType[] = state.metadata.service.filter((item: IServiceType) => {
          return item.id === action.payload.pacTypeID;
        });
        const selectedProvider: any = state.metadata.facilities.filter(
          (item: any) => item.id === action.payload.providerID
        );
        const selectedReadmissionReason: IReadmissionType[] = state.metadata.readmissionReason.filter(
          (item: IReadmissionType) => {
            return item.id === action.payload.readmissionReasonId;
          }
        );
        const selectedReason: Reason[] = state.metadata.reason.filter((item: Reason) => {
          return item.id === action.payload.reasonForServiceId;
        });
        state.selectedPlanOfCareValues = {
          serviceType:
            selectedServiceType.length > 0
              ? {
                  key: action.payload.pacTypeID,
                  name: selectedServiceType[0].serviceType,
                  daysType: selectedServiceType[0].daysType,
                }
              : { key: "", name: "" },
          provider:
            selectedProvider.length > 0
              ? { key: action.payload.providerID, value: selectedProvider[0].providerName }
              : { key: "", value: "" },
          activeInactive: action.meta.arg.isEdit ? true : false,
          los: action.payload.appointmentNo,
          admissionDate:
            action.payload.admissionDate !== null ? moment(action.payload.admissionDate).format("MM/DD/YYYY") : null,
          dischargeDate:
            action.payload.dischargeDate !== null ? moment(action.payload.dischargeDate).format("MM/DD/YYYY") : null,
          readmissionReason:
            selectedReadmissionReason.length > 0
              ? { key: action.payload.readmissionReasonId, name: selectedReadmissionReason[0].name }
              : { key: "", name: "" },
          otherReason: action.payload.reasonForOtherReadmission,
          linkToPlanOfCare: null,
          reasonDetails: action.payload.reason,
          notes: action.payload.notes,
          reason:
            selectedReason.length > 0
              ? { key: action.payload.reasonForServiceId, name: selectedReason[0].itemName }
              : { key: "", name: "" },
        };
        state.isLoading = false;
      })
    );
  },
});
export default planOfCareSlice;
export const {
  setProviderForPlanOfCare,
  setServiceTypesForPlanOfCare,
  setNotes,
  setReasonDetails,
  setOtherReason,
  setReadmissionReason,
  setAdmissonDate,
  setDischargeDate,
  setLos,
  setActiveInactive,
  setReason,
  resetPlanOfCareState,
  setCarePlanEditId,
  setCarePlanLoading,
  setDeleteCarePlan,
} = planOfCareSlice.actions;
export const getPlanOfCareState = (state: any): IPlanOfCareState => state.planOfCare;
