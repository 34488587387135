import { useDispatch, useSelector } from "react-redux";
import "./selected-manual-task.styles.scss";
import { getNewManualTaskState, setManualTaskFieldsValues } from "state/feature/new-manual-task/new-manual-task.slice";
import ManualTasksInputFields from "./manual-task-input-fields/manual-task-input-fields.component";
import { ManualTaskDropdownType } from "state/types/new-manual-task.type";
import { useRef } from "react";

const SelectedManualTask = () => {
  const { manualTaskFields, currentManualTaskType, manualTaskFieldsValues, isDirty, taskOwnerDropdown } =
    useSelector(getNewManualTaskState);
  const dispatch = useDispatch();

  const ref = useRef<HTMLDivElement | null>(null);

  const onChangeValue = (value: string | null | ManualTaskDropdownType, name: string, valueType: string) => {
    if (name === "startDate") {
      dispatch(
        setManualTaskFieldsValues({
          ...manualTaskFieldsValues,
          startDate: value && typeof value === "string" ? value : null,
          dueDate: null,
        })
      );
    } else {
      dispatch(setManualTaskFieldsValues({ ...manualTaskFieldsValues, [name]: value }));
    }
  };

  return (
    <div className="question-section-container">
      {manualTaskFields
        .filter((question) => currentManualTaskType?.manualTaskFields.includes(question.fieldId))
        .sort((a, b) => a.order - b.order)
        .map((question, index) => {
          if (question) {
            return (
              <div
                key={`${question.questionId}-${index}`}
                className={`question-section ${
                  question.fieldType === "date-selector" ? "date-selector-container" : ""
                } ${question.name}`}
              >
                <ManualTasksInputFields
                  {...question}
                  onChangeValue={onChangeValue}
                  answer={manualTaskFieldsValues[question.name as keyof typeof manualTaskFieldsValues]}
                  options={taskOwnerDropdown}
                  isDirty={isDirty}
                  key={question.name}
                  minDate={
                    question.name.toLowerCase() === "duedate" && manualTaskFieldsValues.startDate
                      ? new Date(manualTaskFieldsValues.startDate).setDate(
                          new Date(manualTaskFieldsValues.startDate).getDate() - 1
                        )
                      : null
                  }
                  isDueDateDisabled={
                    question.name.toLowerCase() === "duedate" && manualTaskFieldsValues.startDate === null
                      ? true
                      : false
                  }
                  dropdownRef={ref}
                />
              </div>
            );
          }
          return <div></div>;
        })}
    </div>
  );
};

export default SelectedManualTask;
