import CommonState from "components/common-state/common-state.component";
import { CommonStateType } from "components/common-state/common-state.enum";
import Assessment from "pages/task-management/assessment/assessment.container";
import { FooterTypes } from "pages/task-management/footer/footer.types";
import TaskFooter from "pages/task-management/footer/task-footer.component";
import TaskInfoContainer from "pages/task-management/task-info-tabs/task-info-tabs.container";
import TransitionOfCareContainer from "pages/task-management/transition-of-care/transition-of-care.container";
import { useDispatch, useSelector } from "react-redux";
import { TaskPageName } from "shared/enums/page-name.enum";
import { getCommonState } from "state/feature/common/common.slice";
import "./page-view.styles.scss";
import { getPatientDetailsAsync } from "state/feature/task-management/task-management.action";
import { useEffect } from "react";
import RiskAssessmentContainer from "pages/task-management/risk-assessment/risk-assessment.parent-container";
import { setRiskAssessmentStateFromMemoize } from "state/feature/risk-assessment/risk-assessment.slice";
import { getBrowserState } from "state/feature/task-management/task-management.slice";
import { setTocManagementStateFromMemoize } from "state/feature/toc-management/toc-management.slice";
import { setAssessmentStateFromMemoize } from "state/feature/assessment/assessment.slice";
import ReminderTaskContainer from "pages/task-management/reminder-task/reminder-task.container";
import { setReminderTaskStateFromMemoize } from "state/feature/reminder-task/reminder-task.slice";
import NewManualTaskContainer from "pages/task-management/new-manual-task/new-manual-task.container";
import { setNewManualTaskStateFromMemoize } from "state/feature/new-manual-task/new-manual-task.slice";
import NotesTask from "pages/task-management/notes-task/notes-task.container";
import PostTaskPerformedView from "../post-task-performed-view/post-task-performed-view.component";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import { useHistory } from "react-router";
import SendOrder from "pages/task-management/send-order/send-order.container";
import { setSendOrderStateFromMemoize } from "state/feature/send-order/send-order.slice";
import { setAdmitTaskStateFromMemoize } from "state/feature/admit-task/admit-task.slice";
import AdmitTask from "pages/task-management/admit-task/admit-task.container";
import { setCheckOnPatientStateFromMemoize } from "state/feature/check-on-patient/check-on-patient.slice";
import CheckOnPatient from "pages/task-management/check-on-patient/check-on-patient.container";

const PageViewContainer = function () {
  useAxiosAuthenticated();
  const dispatch = useDispatch();
  const history = useHistory();
  const { isPageLoading, currentTab, tabs, pages } = useSelector(getBrowserState);
  const page = pages.find((p) => p.taskId === currentTab?.taskId)!;
  const {
    isValidIntake,
    isValidatingIntake,
    intakeId,
    allowIntakeAcess,
    taskType,
    taskId,
    taskPayload,
    shouldPerformPostSubmitAction,
    shouldPerformPostDelayAction,
    shouldPerformPostTaskWaitingApprovalAction,
    shouldPerformPostTaskApprovedAction,
    shouldPerformPostTaskRejectedAction,
  } = page;

  const { featureFlags } = useSelector(getCommonState);

  const getFooterType = () => {
    switch (taskType) {
      case TaskPageName.ASSESSMENT:
        return FooterTypes.AssessmentFooter;
      case TaskPageName.RISK_ASSESSMENT:
        return FooterTypes.RiskFooter;
      default:
        return FooterTypes.None;
    }
  };

  useEffect(() => {
    if (!page.patientDetails) {
      dispatch(getPatientDetailsAsync({ intakeId, taskId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (intakeId && !isValidIntake && !isValidatingIntake) {
      history.replace("/not-found");
    }
  }, [isValidIntake, isValidatingIntake, intakeId, history]);

  useEffect(() => {
    if (taskPayload && taskType === TaskPageName.RISK_ASSESSMENT) {
      dispatch(setRiskAssessmentStateFromMemoize(taskPayload));
    } else if (taskPayload && taskType === TaskPageName.TRANSITION_OF_CARE) {
      dispatch(setTocManagementStateFromMemoize(taskPayload));
    } else if (taskPayload && taskType === TaskPageName.ASSESSMENT) {
      dispatch(setAssessmentStateFromMemoize(taskPayload));
    } else if (taskPayload && taskType === TaskPageName.REMINDER_TASK) {
      dispatch(setReminderTaskStateFromMemoize(taskPayload));
    } else if (taskPayload && taskType === TaskPageName.NEW_MANUAL_TASK) {
      dispatch(setNewManualTaskStateFromMemoize(taskPayload));
    } else if (taskPayload && taskType === TaskPageName.SEND_ORDER) {
      dispatch(setSendOrderStateFromMemoize(taskPayload));
    } else if (taskPayload && taskType === TaskPageName.ADMIT_TASK) {
      dispatch(setAdmitTaskStateFromMemoize(taskPayload));
    } else if (taskPayload && taskType === TaskPageName.CHECK_ON_PATIENT) {
      dispatch(setCheckOnPatientStateFromMemoize(taskPayload));
    }
  }, [taskType, dispatch, taskPayload]);

  const renderTask = () => {
    if (!tabs.length || !pages.length) {
      return <CommonState type={CommonStateType.NO_TASK_SELECTED} />;
    }

    if (taskType === TaskPageName.RISK_ASSESSMENT) {
      return (
        <div className="task-from-id">
          <RiskAssessmentContainer />
        </div>
      );
    }

    if (taskType === TaskPageName.TRANSITION_OF_CARE) {
      return (
        <div className="task-from-id">
          <TransitionOfCareContainer />
        </div>
      );
    }

    if (taskType === TaskPageName.ASSESSMENT) {
      return (
        <div className="task-from-id">
          <Assessment />
        </div>
      );
    }

    if (taskType === TaskPageName.NOTES_TASK) {
      return (
        <div className="task-from-id">
          <NotesTask />
        </div>
      );
    }

    if (taskType === TaskPageName.REMINDER_TASK) {
      return (
        <div className="task-from-id">
          <ReminderTaskContainer />
        </div>
      );
    }
    if (taskType === TaskPageName.SEND_ORDER) {
      return (
        <div className="task-from-id">
          <SendOrder />
        </div>
      );
    }

    if (taskType === TaskPageName.CHECK_ON_PATIENT) {
      return (
        <div className="task-from-id">
          <CheckOnPatient />
        </div>
      );
    }

    if (taskType === TaskPageName.NEW_MANUAL_TASK) {
      return (
        <div className="task-from-id">
          <NewManualTaskContainer />
        </div>
      );
    }

    if (taskType === TaskPageName.ADMIT_TASK) {
      return (
        <div className="task-from-id">
          <AdmitTask />
        </div>
      );
    }

    return <CommonState type={CommonStateType.PAGE_NOT_FOUND} />;
  };

  const renderTaskInfo = () => {
    if (!featureFlags.taskManagement) {
      return null;
    }

    if (!tabs.length || !pages.length) {
      return null;
    }

    return <TaskInfoContainer />;
  };

  return (
    <div id="page-view-container">
      {isPageLoading ? (
        <CommonState type={CommonStateType.LOADING} />
      ) : (
        <>
          {shouldPerformPostDelayAction ||
          shouldPerformPostSubmitAction ||
          shouldPerformPostTaskWaitingApprovalAction ||
          shouldPerformPostTaskApprovedAction ||
          shouldPerformPostTaskRejectedAction ? (
            <PostTaskPerformedView />
          ) : (
            <>
              {!shouldPerformPostSubmitAction &&
                !shouldPerformPostDelayAction &&
                !shouldPerformPostTaskWaitingApprovalAction &&
                !shouldPerformPostTaskApprovedAction &&
                !shouldPerformPostTaskRejectedAction &&
                intakeId &&
                isValidIntake &&
                allowIntakeAcess && (
                  <div className="task-render-container">
                    {renderTask()}
                    {renderTaskInfo()}
                  </div>
                )}
              {isValidIntake && !isValidatingIntake && pages.length && <TaskFooter footerType={getFooterType()} />}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default PageViewContainer;
