import { isEmptyString } from "shared/methods/utilityFunctions";
import { COMPLETED_GREATER_THAN_ACTUAL_DAYS_ERROR_MESSAGE, EMPTY_FIELD_ERROR_MESSAGE } from "../../common/constants";
import Error from "components/error-message/error-message.component";
import "./day-visit-error-message.style.scss";

const DayVisitErrorMessage = (props: { dayVisitCompleted: string; dayVisitActual: string }) => {
  const { dayVisitActual, dayVisitCompleted } = props;

  if (isEmptyString(dayVisitCompleted) || isEmptyString(dayVisitActual)) {
    const arr: Array<boolean> = [false, isEmptyString(dayVisitActual), isEmptyString(dayVisitCompleted)];
    return (
      <div className="day-visit-error-container">
        {arr.map((dayVisit) => {
          return <div className="day-visit-error">{dayVisit && <Error message={EMPTY_FIELD_ERROR_MESSAGE} />}</div>;
        })}
      </div>
    );
  } else if (
    !isEmptyString(dayVisitCompleted) &&
    !isEmptyString(dayVisitActual) &&
    parseInt(dayVisitCompleted) > parseInt(dayVisitActual)
  ) {
    return <Error message={COMPLETED_GREATER_THAN_ACTUAL_DAYS_ERROR_MESSAGE} />;
  }
  return null;
};

export default DayVisitErrorMessage;
