import "./tab-limit-modal.styles.scss";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../modal.component";
import Button from "components/Button/button.component";
import { TAB_LIMIT_EXCEEDED_HEADING, TAB_LIMIT_EXCEEDED_SUB_HEADING } from "shared/constant/commonConstants";
import { getBrowserState, setShowTabLimitModal } from "state/feature/task-management/task-management.slice";

const TabLimitModal = () => {
  const { showTabLimitModal } = useSelector(getBrowserState);
  const dispatch = useDispatch();

  const handleOk = () => {
    dispatch(setShowTabLimitModal(false));
  };

  return (
    <Modal isOpen={showTabLimitModal} className="center unsaved-task-popup">
      <div className="unsaved-task-container">
        <div className="header">{TAB_LIMIT_EXCEEDED_HEADING}</div>
        <div className="content">{TAB_LIMIT_EXCEEDED_SUB_HEADING}</div>
        <div className="button-section">
          <Button text="Ok" className="green-button yes-button" onClick={handleOk} />
        </div>
      </div>
    </Modal>
  );
};

export default TabLimitModal;
