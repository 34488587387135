import React from "react";
import HighlightedText from "components/HighLightedText/high-lighted-text.component";

const AssignPhoneDropdown = ({ dropDownMenuItems, optionClassName, handleSelection, searchText }: any) => {
  return (
    <div className="options-box">
      <ul>
        {dropDownMenuItems.length ? (
          dropDownMenuItems?.map((option: any, key: number) => (
            <li
              key={key}
              onClick={() => {
                if (!option.isDisabled) {
                  handleSelection(option);
                }
              }}
              className={`${option?.isDisabled ? "disabled-class" : ""}`}
            >
              <p className={`option ${optionClassName ? optionClassName : ""}`}>
                {HighlightedText(option.name, searchText)}
              </p>
              <p className="">{HighlightedText(option.value, searchText)}</p>
            </li>
          ))
        ) : (
          <li key={0}>No Result Found</li>
        )}
      </ul>
    </div>
  );
};

export default AssignPhoneDropdown;
