import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IProviderType, ISendOrderGetResponse, ISendOrderState } from "state/types/send-order.type";
import {
  getAllFacilitiesSendOrderAsync,
  getProviderOptionsAync,
  getProviderSendOrderDetailsAsync,
  getSendOrderDetailsAsync,
  saveSendOrderAsync,
} from "./send-order.action";
import { TaskStatus } from "shared/enums/tasks-status.enum";
import { toast } from "react-toastify";
import { TOAST_MESSAGES } from "pages/login/common/constants";

const initialState: ISendOrderState = {
  isLoadingSaveButton: false,
  isLoading: false,
  isValueChanged: false,
  sendOrderDetails: null,
  selectedSendOrder: {
    serviceType: { key: "", name: "" },
    los: "",
    providerDetails: { id: -1, name: "" },
    sendMethodType: { key: "", name: "" },
    sendMethodValue: "",
    preferredSendDate: { key: "", name: "" },
    sendDate: null,
    sendInstructions: "",
    notes: "",
    practiceName: "",
    providerName: "",
    providerType: "",
  },
  providerOptions: [],
  facilities: [],
  isDirty: false,
};

const senderOrderSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setSendOrderStateFromMemoize(state, action) {
      return action.payload;
    },
    setSelectedSendOrderValue(state, action) {
      state.isValueChanged = true;
      state.selectedSendOrder = action.payload;
      if (action.payload?.providerDetails?.id === -1) {
        state.selectedSendOrder.sendMethodType = { key: "", name: "" };
        state.selectedSendOrder.sendMethodValue = "";
        state.selectedSendOrder.sendInstructions = "";
        state.selectedSendOrder.preferredSendDate = { key: "", name: "" };
      }
    },
    setIsDirty(state, action) {
      state.isDirty = action.payload;
    },
    resetSendOrderWhenChangingTab(state) {
      state.isDirty = false;
      state.isLoading = false;
      state.isLoadingSaveButton = false;
      state.isValueChanged = false;
      state.selectedSendOrder = { ...initialState.selectedSendOrder };
    },
    resetSendOrderState() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    return (
      builder.addCase(getSendOrderDetailsAsync.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(
        getSendOrderDetailsAsync.fulfilled,
        (state, action: PayloadAction<ISendOrderGetResponse | null>) => {
          if (action.payload) {
            state.sendOrderDetails = action.payload;
            const { tocTaskDetails } = action.payload;
            if (tocTaskDetails) {
              const newSelectedSendOrder = {
                ...state.selectedSendOrder,
                serviceType: {
                  key: tocTaskDetails?.serviceTypeId?.toString() ?? "",
                  name: tocTaskDetails?.serviceTypeName ?? "",
                },
                los: tocTaskDetails?.los && tocTaskDetails?.los !== 0 ? tocTaskDetails.los?.toString() : "",
                notes: tocTaskDetails?.notes ?? "",
                providerDetails: { id: tocTaskDetails?.providerDetails?.id ?? -1, name: "" },
                sendDate: tocTaskDetails?.sendDate,
              };
              if (
                action.payload.taskStatusId !== TaskStatus.NEW &&
                action.payload.taskStatusId !== TaskStatus.IN_PROGRESS
              ) {
                newSelectedSendOrder.sendMethodType = {
                  key: tocTaskDetails?.providerDetails?.sendMethod ?? "",
                  name: tocTaskDetails?.providerDetails?.sendMethod ?? "",
                };
                newSelectedSendOrder.sendInstructions = tocTaskDetails?.providerDetails?.sendInstructions ?? "";
                newSelectedSendOrder.sendMethodValue = tocTaskDetails?.providerDetails?.sendDestination ?? "";
                newSelectedSendOrder.preferredSendDate = {
                  key: tocTaskDetails?.providerDetails?.sendDatePreference ?? "",
                  name: tocTaskDetails?.providerDetails?.sendDatePreference ?? "",
                };
              }
              state.selectedSendOrder = newSelectedSendOrder;
            }
          }
          state.isLoading = false;
        }
      ),
      builder.addCase(getSendOrderDetailsAsync.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(getProviderOptionsAync.fulfilled, (state, action: PayloadAction<Array<IProviderType>>) => {
        state.providerOptions = action.payload;
      }),
      builder.addCase(getProviderSendOrderDetailsAsync.fulfilled, (state, action) => {
        const newSelectedSendOrder = { ...state.selectedSendOrder };
        if (action.payload) {
          const {
            sendMethod,
            sendInstructions,
            emailFax,
            preferredSendDate,
            practiceName,
            providerName,
            providerType,
          } = action.payload;
          newSelectedSendOrder.sendMethodType = {
            key: sendMethod ?? "",
            name: sendMethod ?? "",
          };
          newSelectedSendOrder.sendMethodValue = emailFax ?? "";
          newSelectedSendOrder.sendInstructions = sendInstructions ?? "";
          newSelectedSendOrder.preferredSendDate = { key: preferredSendDate ?? "", name: preferredSendDate ?? "" };
          newSelectedSendOrder.practiceName = practiceName ?? "";
          newSelectedSendOrder.providerName = providerName ?? "";
          newSelectedSendOrder.providerType = providerType ?? "";
        } else {
          newSelectedSendOrder.sendMethodType = { key: "", name: "" };
          newSelectedSendOrder.sendMethodValue = "";
          newSelectedSendOrder.sendInstructions = "";
          newSelectedSendOrder.preferredSendDate = { key: "", name: "" };
        }
        state.selectedSendOrder = newSelectedSendOrder;
      }),
      builder.addCase(saveSendOrderAsync.pending, (state) => {
        state.isLoadingSaveButton = true;
      }),
      builder.addCase(saveSendOrderAsync.fulfilled, (state, action) => {
        state.isLoadingSaveButton = false;
      }),
      builder.addCase(saveSendOrderAsync.rejected, (state, action) => {
        state.isLoadingSaveButton = false;
        toast.error(action.payload ?? TOAST_MESSAGES.ERROR, { containerId: "main" });
      }),
      builder.addCase(getAllFacilitiesSendOrderAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.facilities = action.payload;
          const selectedProvider = action.payload.find(
            (provider: IProviderType) => state.sendOrderDetails?.tocTaskDetails?.providerDetails?.id === provider.ID
          );
          if (
            selectedProvider !== undefined &&
            state.sendOrderDetails?.tocTaskDetails?.providerDetails?.id &&
            state.sendOrderDetails?.tocTaskDetails?.providerDetails?.id !== -1
          ) {
            state.selectedSendOrder.providerDetails = {
              name: selectedProvider?.ProviderName,
              id: selectedProvider?.ID,
            };
          }
        }
      })
    );
  },
});

export const {
  setSelectedSendOrderValue,
  setSendOrderStateFromMemoize,
  resetSendOrderState,
  setIsDirty,
  resetSendOrderWhenChangingTab,
} = senderOrderSlice.actions;

export default senderOrderSlice;

export const getSendOrderSlice = (state: any): ISendOrderState => state.sendOrder;
