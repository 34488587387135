import { createAsyncThunk } from "@reduxjs/toolkit";
import { getConfig } from "config/config";
import { axiosInstance } from "shared/axios-instance";
import { IManualTasksApiType, IManualTasksFieldsApiType } from "state/types/episodes-slice.type";
import { INavigator } from "state/types/navigator.type";

export const getManualFieldTaskAsync = createAsyncThunk("new-manual-task/getManualFieldTaskAsync", async () => {
  const response = await axiosInstance.get(`${getConfig().taskManagementBase}/api/v1/Tasks/manual-task-fields`);
  if (response.status === 200) {
    return response.data?.data?.map((question: IManualTasksFieldsApiType) => {
      return {
        questionId: question.id,
        fieldId: question.fieldId,
        ...question.fields,
      };
    });
  }
  return [];
});

export const getManualTaskTypeAsync = createAsyncThunk("new-manual-task/getManualTaskTypeAsync", async () => {
  const response = await axiosInstance.get(`${getConfig().taskManagementBase}/api/v1/Tasks/manual-tasks`);
  if (response.status === 200 && response.data?.data) {
    return response.data.data.map((question: IManualTasksApiType) => {
      return {
        key: question.id,
        name: question.name,
        taskTypeId: question.taskTypeId,
        manualTaskFields: question.manualTaskFields.map((field: any) => field.id),
      };
    });
  }
  return [];
});

export const addManualTaskAsync = createAsyncThunk(
  "new-manual-task/addManualTaskAsync",
  async (requestPayload: {
    intakeId: number;
    createUser: string;
    createUserId: string;
    noteReminderManualTask: {
      title: string;
      taskOwners: string;
      notes: string | null;
      taskOwnerUserId: string;
      startDate: string;
      dueDate: string;
      requests: string | null;
    };

    taskTypeId: number;
  }) => {
    const response = await axiosInstance.post(
      `${getConfig().taskManagementBase}/api/v1/Tasks/add-manual-task`,
      requestPayload
    );
    return response;
  }
);

export const getTaskOwnerMenuAsync = createAsyncThunk(
  "new-manual-task/getTaskOwnerMenuAsync",
  async (requestPayload: { types: string[]; timeZone?: string }, { rejectWithValue }): Promise<INavigator[] | any> => {
    try {
      const response = await axiosInstance.post(`${getConfig().navigatorBase}/api/v1/navigators/list`, requestPayload);
      if (response.status === 200 && response.data) {
        return response.data.map((res: INavigator) => {
          return { key: res?.careManagerId, name: `${res?.firstName} ${res?.lastName}` };
        });
      } else {
        return [];
      }
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
