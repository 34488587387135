import { TaskType } from "shared/enums/task-type.enum";
import "./style.scss";
import {
  IAdmitTaskLog,
  ICallLog,
  ICarePlanUtilizationLog,
  ICheckOnPatientLog,
  IDelayedLog,
  IEpisodePatientNavigationLog,
  IEpisodeStatusChangeLog,
  INoteLog,
  IReminderLog,
  IRiskAssessmentLog,
  ISendOrderLog,
  ITocLog,
} from "state/types/episodes-slice.type";
import { ActivityHistoryTaskDetailsProps } from "./props";
import {
  getDateInMMDDYYYYFormat,
  getDurationOfCall,
  getTextWithElipsis,
  isEmptyString,
} from "shared/methods/utilityFunctions";
import moment from "moment";

export const ActivityHistoryDetailsColumn = (props: ActivityHistoryTaskDetailsProps) => {
  const { taskDetails, className, activityLogType, notes, calledFromTaskManagement } = props;

  const renderNotesUI = (isNotesReminderTaskTask?: boolean) => {
    return (
      <div className="details">
        <div className="details-name">Notes :</div>
        <div className="details-description">
          {notes ? (isNotesReminderTaskTask ? getTextWithElipsis(notes, 300) : notes) : "-"}
        </div>
      </div>
    );
  };

  const renderDetailsRisk = () => {
    const details = taskDetails as IRiskAssessmentLog;
    return (
      <>
        <div className="details">
          <div className="details-name">Risk: </div>
          <div className="details-description">{details?.riskLevel ?? "-"}</div>
        </div>
        <div className="details">
          <div className="details-name">High Risk: </div>
          <div className="details-description">{details?.highRiskFactors ?? "-"}</div>
        </div>
        {renderNotesUI()}
      </>
    );
  };

  const renderDetailsReminder = () => {
    const details = taskDetails as IReminderLog;
    return (
      <>
        <div className="details">
          <div className="details-name">Subject: </div>
          <div className="details-description">{details?.subject ?? "-"}</div>
        </div>
        <div className="details">
          <div className="details-name">Assignee: </div>
          <div className="details-description">{details?.assigneeName ?? "-"}</div>
        </div>
        <div className="details">
          <div className="details-name">Request :</div>
          <div className="details-description">
            {details?.requests ? getTextWithElipsis(details.requests, 300) : "-"}
          </div>
        </div>
        {renderNotesUI(true)}
      </>
    );
  };

  const renderDetailsNotes = () => {
    const details = taskDetails as INoteLog;

    return (
      <>
        <div className="details">
          <div className="details-name">Title: </div>
          <div className="details-description">{details?.title ?? "-"}</div>
        </div>
        {renderNotesUI(true)}
      </>
    );
  };

  const renderTaskDelay = () => {
    const details = taskDetails as IDelayedLog;

    return (
      <>
        <div className="details">
          <div className="details-name">Reason: </div>
          <div className="details-description">{details?.reason ?? "-"}</div>
        </div>
        <div className="details">
          <div className="details-name">Attempts: </div>
          <div className="details-description">{details?.attemptCount ?? "-"}</div>
        </div>
        {renderNotesUI()}
      </>
    );
  };

  const renderDetailsTOC = () => {
    const details = taskDetails as ITocLog;
    return (
      <>
        <div className="details">
          <div className="details-name">Nav. Notes: </div>
          <div className="details-description">{details?.navigatorNotes ?? "-"}</div>
        </div>
        <div className="details">
          <div className="details-name">Phy. Notes: </div>
          <div className="details-description">{details?.physicianNotes ?? "-"}</div>
        </div>
        {renderNotesUI()}
      </>
    );
  };

  const renderDetailsSendOrder = () => {
    const details = taskDetails as ISendOrderLog;
    return (
      <>
        <div className="details">
          <div className="details-name">Service Type: </div>
          <div className="details-description">{details?.serviceType ?? "-"}</div>
        </div>
        <div className="details">
          <div className="details-name">Provider: </div>
          <div className="details-description">{details?.provider ?? "-"}</div>
        </div>
        {renderNotesUI()}
      </>
    );
  };

  const renderDetailsAdmitTask = () => {
    const details = taskDetails as IAdmitTaskLog;
    return (
      <>
        <div className="details">
          <div className="details-name">Service Type: </div>
          <div className="details-description">{details?.serviceType ?? "-"}</div>
        </div>
        <div className="details">
          <div className="details-name">Provider: </div>
          <div className="details-description">{details?.provider ?? "-"}</div>
        </div>
        <div className="details">
          <div className="details-name">Order Status: </div>
          <div className="details-description">
            {details?.orderStatus && !isEmptyString(details?.orderStatus) ? details?.orderStatus : "-"}
          </div>
        </div>
        <div className="details">
          <div className="details-name">Admission Date: </div>
          <div className="details-description">
            {details?.admissionDate ? getDateInMMDDYYYYFormat(moment(details?.admissionDate, "YYYY-MM-DD")) : "-"}
          </div>
        </div>
        <div className="details">
          <div className="details-name">Planned Discharge Date: </div>
          <div className="details-description">
            {details?.plannedDischargeDate
              ? getDateInMMDDYYYYFormat(moment(details?.plannedDischargeDate, "YYYY-MM-DD"))
              : "-"}
          </div>
        </div>

        {renderNotesUI()}
      </>
    );
  };

  const renderDetailsCheckOnPatient = () => {
    const details = taskDetails as ICheckOnPatientLog;
    return (
      <>
        <div className="details">
          <div className="details-name">Service Type: </div>
          <div className="details-description">{details?.serviceType ?? "-"}</div>
        </div>
        <div className="details">
          <div className="details-name">Provider: </div>
          <div className="details-description">{details?.provider ?? "-"}</div>
        </div>
        {details && details?.dischargeDate !== null && (
          <div className="details">
            <div className="details-name">Discharged Date: </div>
            <div className="details-description">
              {details?.dischargeDate ? getDateInMMDDYYYYFormat(moment(details?.dischargeDate, "YYYY-MM-DD")) : "-"}
            </div>
          </div>
        )}
        {details && details?.plannedDischargeDate !== null && (
          <div className="details">
            <div className="details-name">Planned Discharge Date: </div>
            <div className="details-description">
              {details?.plannedDischargeDate
                ? getDateInMMDDYYYYFormat(moment(details?.plannedDischargeDate, "YYYY-MM-DD"))
                : "-"}
            </div>
          </div>
        )}
        {details && details?.visitsCompleted !== null && (
          <div className="details">
            <div className="details-name">Visits Completed: </div>
            <div className="details-description">{`${details?.visitsCompleted} of ${details?.totalVisits ?? ""}`}</div>
          </div>
        )}
        {details && details?.daysCompleted !== null && (
          <div className="details">
            <div className="details-name">Days Completed: </div>
            <div className="details-description">{`${details?.daysCompleted} of ${details?.totalDays ?? ""}`}</div>
          </div>
        )}
        {renderNotesUI()}
      </>
    );
  };

  const renderDetailsEpisodeStatusChange = () => {
    const details = taskDetails as IEpisodeStatusChangeLog;
    return (
      <>
        <div className="details">
          <div className="details-name">Reason: </div>
          <div className="details-description">{`${
            details?.statusChangeReason && !isEmptyString(details.statusChangeReason) ? details.statusChangeReason : "-"
          }`}</div>
        </div>
        {notes && renderNotesUI()}
      </>
    );
  };

  const renderDetailsCarePlanUtilization = () => {
    const details = taskDetails as ICarePlanUtilizationLog;
    const { los, otherReason, provider, readmissionReason, service, serviceReason, visits } = details;
    return (
      <>
        <div className="details">
          <div className="details-name">Service: </div>
          <div className="details-description">{service ?? "="}</div>
        </div>
        {serviceReason && (
          <div className="details">
            <div className="details-name">Reason: </div>
            <div className="details-description">{details.serviceReason}</div>
          </div>
        )}
        {provider && (
          <div className="details">
            <div className="details-name">Provider: </div>
            <div className="details-description">{provider ?? "-"}</div>
          </div>
        )}
        {los && (
          <div className="details">
            <div className="details-name">LOS (Days): </div>
            <div className="details-description">{details.los ?? "-"}</div>
          </div>
        )}
        {visits && (
          <div className="details">
            <div className="details-name">Visits: </div>
            <div className="details-description">{visits}</div>
          </div>
        )}
        {readmissionReason && (
          <div className="details">
            <div className="details-name">Readmission Reason: </div>
            <div className="details-description">{readmissionReason}</div>
          </div>
        )}
        {otherReason && (
          <div className="details">
            <div className="details-name">Other Reason: </div>
            <div className="details-description">{otherReason}</div>
          </div>
        )}
        {notes && renderNotesUI()}
      </>
    );
  };

  const renderCalls = () => {
    const details = taskDetails as ICallLog;
    let callDuration = details.callDuration;
    if (callDuration && !isEmptyString(callDuration) && !Number.isNaN(Number(callDuration))) {
      callDuration = getDurationOfCall(Number(callDuration));
    }
    if (callDuration) {
      return (
        <div className="details">
          <div className="details-name">Duration: </div>
          <div className="details-description">{callDuration}</div>
        </div>
      );
    }
    return;
  };

  const renderEpisodeNavigationAccepted = () => {
    if (calledFromTaskManagement) {
      return;
    }
    return (
      <div className="details">
        <div className="empty-column">-</div>
      </div>
    );
  };

  const renderEpisodeNavigationDecline = () => {
    const details = taskDetails as IEpisodePatientNavigationLog;
    return (
      <div className="details">
        <div className="details-name">Notes: </div>
        <div className="details-description">
          {details?.notes && !isEmptyString(details.notes) ? details.notes : "-"}
        </div>
      </div>
    );
  };
  const renderDetails = () => {
    if (activityLogType === TaskType.RISK) {
      return renderDetailsRisk();
    } else if (activityLogType === TaskType.REMINDER) {
      return renderDetailsReminder();
    } else if (activityLogType === TaskType.NOTES) {
      return renderDetailsNotes();
    } else if (activityLogType === TaskType.TASK_DELAY) {
      return renderTaskDelay();
    } else if (activityLogType === TaskType.TOC) {
      return renderDetailsTOC();
    } else if (activityLogType === TaskType.ASSESSMENT) {
      return renderNotesUI();
    } else if (activityLogType === TaskType.SEND_ORDER) {
      return renderDetailsSendOrder();
    } else if (activityLogType === TaskType.ADMIT_TASK) {
      return renderDetailsAdmitTask();
    } else if (activityLogType === TaskType.CHECK_ON_PATIENT) {
      return renderDetailsCheckOnPatient();
    } else if (activityLogType === TaskType.EPISODE_STATUS_CHANGE) {
      return renderDetailsEpisodeStatusChange();
    } else if (activityLogType === TaskType.CARE_PLAN_UTILIZATION) {
      return renderDetailsCarePlanUtilization();
    } else if (activityLogType === TaskType.CALL) {
      return renderCalls();
    } else if (
      activityLogType === TaskType.TASK_CLOSURE_REJECTED ||
      activityLogType === TaskType.TASK_CLOSURE_APPROVED
    ) {
      return renderNotesUI();
    } else if (
      activityLogType === TaskType.EPISODE_NAVIGATION_DECLINED ||
      activityLogType === TaskType.EPISODE_NAVIGATION_NOTES_CHANGES
    ) {
      return renderEpisodeNavigationDecline();
    } else if (activityLogType === TaskType.EPISODE_NAVIGATION_ACCEPTED) {
      return renderEpisodeNavigationAccepted();
    }
    return <div></div>;
  };
  return <div className={`activity-details-column ${className ? className : ""}`}>{renderDetails()}</div>;
};

export default ActivityHistoryDetailsColumn;
