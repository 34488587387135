import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getActvityLogAsync } from "state/feature/episodes/episodes.action";
import ActivityHistoryTabPresentation from "./activity-logs.component";
import "./style.scss";
import { PAGE_LIMIT } from "pages/episodes/episode-details/tabs/activity-history/type";
import { resetEpisodeDetailsActvityList } from "state/feature/episodes/episodes.slice";
import { getBrowserState } from "state/feature/task-management/task-management.slice";

const ActivityLogsTab = () => {
  const { currentTab } = useSelector(getBrowserState);
  const { intakeId } = currentTab!;
  const dispatch = useDispatch();
  const getInitialRequestPayload = useCallback(() => {
    return {
      intakeId,
      offset: 0,
      limit: PAGE_LIMIT,
    };
  }, [intakeId]);
  const [requestPayload, setRequestPayload] = useState(getInitialRequestPayload());

  const onIncrementPage = () => {
    setRequestPayload((prev) => {
      return { ...prev, offset: prev.offset + PAGE_LIMIT };
    });
  };

  useEffect(() => {
    dispatch(getActvityLogAsync(requestPayload));
  }, [dispatch, requestPayload, intakeId]);

  useEffect(() => {
    return () => {
      dispatch(resetEpisodeDetailsActvityList());
    };
  }, [dispatch]);

  return <ActivityHistoryTabPresentation onIncrementPage={onIncrementPage} />;
};

export default ActivityLogsTab;
