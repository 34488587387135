import { useSelector } from "react-redux";
import CheckOnPatientFormPresentation from "./check-on-patient-form.component";
import {
  getCheckOnPatientSlice,
  setSelectCheckOnPatientValues,
} from "state/feature/check-on-patient/check-on-patient.slice";
import { useAppDispatch } from "state/store";
import { useCallback } from "react";
import { checkonpatientQuestions } from "../../common/constants";
import { checkTaskIsCompletedOrClosed } from "shared/methods/taskSpecificMethods";
import { isEmptyString, validateNumberForTextInput } from "shared/methods/utilityFunctions";
import { ACH_ID } from "../../check-on-patient.constant";
import { TaskStatus } from "shared/enums/tasks-status.enum";
import { getBrowserState, getCurrentPageTaskState } from "state/feature/task-management/task-management.slice";

const CheckOnPatientForm = () => {
  const appDispatch = useAppDispatch();
  const { selectedCheckOnPatient, checkOnPatientDetails, isLoading } = useSelector(getCheckOnPatientSlice);
  const { currentTab } = useSelector(getBrowserState);
  const {
    delayTask: { delayAttempts },
    episode,
  } = useSelector(getCurrentPageTaskState(currentTab!.taskId));

  const showDelayNote =
    (!isLoading &&
      checkOnPatientDetails &&
      checkOnPatientDetails?.taskStatusId !== TaskStatus.CLOSED &&
      checkOnPatientDetails?.taskStatusId !== TaskStatus.COMPLETED &&
      episode &&
      episode?.patientAndEpisodeDetails.coachPhone !== null &&
      delayAttempts &&
      delayAttempts?.length > 0 &&
      delayAttempts[0]?.taskDelayStatusId === 1) ??
    false;
  const getCheckOnPatientQuestions = useCallback(() => {
    let question = checkonpatientQuestions;
    if (checkTaskIsCompletedOrClosed(checkOnPatientDetails?.taskStatusId)) {
      const entries = Object.entries(question).map(([key, value]) => {
        return [key, { ...value, disabled: true }];
      });
      question = Object.fromEntries(entries);
    } else {
      if (selectedCheckOnPatient.isOfftrack === true) {
        question.offtrackReason.required = true;
        question.offtrackDetails.required = true;
        question.offtrackStartDate.required = true;
      }
      if (checkOnPatientDetails?.tocTaskDetails?.serviceTypeId === ACH_ID) {
        question.procedureDate.disabled = false;
        question.procedureDate.required = true;
        question.actualDate.disabled = false;
        question.actualDate.required = true;
      } else {
        question.procedureDate.disabled = true;
        question.procedureDate.required = false;
        question.actualDate.disabled = false;
        question.actualDate.required = true;
      }

      question.offtrack.disabled = !checkOnPatientDetails?.isEditable ?? false;
      question.offtrackDetails.disabled = !checkOnPatientDetails?.isEditable ?? false;
      question.offtrackStartDate.disabled = !checkOnPatientDetails?.isEditable ?? false;
      question.offtrackReason.disabled = !checkOnPatientDetails?.isEditable ?? false;
    }
    return question;
  }, [checkOnPatientDetails, selectedCheckOnPatient]);

  const handleServiceType = (value: { name: string; key?: string; [name: string]: any }) => {
    appDispatch(
      setSelectCheckOnPatientValues({
        ...selectedCheckOnPatient,
        serviceType: { key: value?.key ?? "", name: value?.name ?? "" },
      })
    );
  };

  const handleProviderDropdown = (id: number, name: string) => {
    appDispatch(setSelectCheckOnPatientValues({ ...selectedCheckOnPatient, provider: { id, name } }));
  };

  const handleActualDayVisit = (label: string, value: string) => {
    if (validateNumberForTextInput(value) && value !== "0" && (isEmptyString(value) || parseInt(value) <= 200)) {
      appDispatch(
        setSelectCheckOnPatientValues({
          ...selectedCheckOnPatient,
          dayVisitActual: value,
        })
      );
    }
  };

  const handleCompletedDayVisit = (label: string, value: string) => {
    if ((validateNumberForTextInput(value) && (isEmptyString(value) || parseInt(value) <= 200)) || value === "0") {
      appDispatch(
        setSelectCheckOnPatientValues({
          ...selectedCheckOnPatient,
          dayVisitCompleted: value,
        })
      );
    }
  };
  const handleOffTrackReason = (value: { name: string; key?: string; [name: string]: any }) => {
    appDispatch(
      setSelectCheckOnPatientValues({
        ...selectedCheckOnPatient,
        offTrackReason: { key: value.key ?? "", name: value.name ?? "" },
      })
    );
  };

  const handleOffTrackStartDate = (val: string | null) => {
    appDispatch(
      setSelectCheckOnPatientValues({
        ...selectedCheckOnPatient,
        offtrackStartDate: val,
      })
    );
  };

  const handleOffTrackDetails = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!/^\s/.test(event.currentTarget.value)) {
      appDispatch(
        setSelectCheckOnPatientValues({
          ...selectedCheckOnPatient,
          offtrackDetails: event.currentTarget.value,
        })
      );
    }
  };
  const handleIsOffTrackCheckbox = (e: React.MouseEvent<HTMLInputElement>) => {
    const offtrackValue = selectedCheckOnPatient.isOfftrack
      ? { isOfftrack: false, isOffTrackReason: { key: "", name: "" }, isOfftrackDetails: "", isOfftrackStartDate: null }
      : { isOfftrack: true };
    appDispatch(
      setSelectCheckOnPatientValues({
        ...selectedCheckOnPatient,
        ...offtrackValue,
      })
    );
  };

  const handleActualDate = (val: string | null) => {
    appDispatch(
      setSelectCheckOnPatientValues({
        ...selectedCheckOnPatient,
        actualDischargeDate: val,
      })
    );
  };

  const handleProcedureDate = (val: string | null) => {
    appDispatch(
      setSelectCheckOnPatientValues({
        ...selectedCheckOnPatient,
        procedureDate: val,
      })
    );
  };

  const handleDischargeFromServiceCheckbox = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    appDispatch(
      setSelectCheckOnPatientValues({
        ...selectedCheckOnPatient,
        dischargeFromService: !selectedCheckOnPatient.dischargeFromService,
      })
    );
  };

  const handleNotes = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!/^\s/.test(event.currentTarget.value)) {
      appDispatch(
        setSelectCheckOnPatientValues({
          ...selectedCheckOnPatient,
          notes: event.currentTarget.value,
        })
      );
    }
  };

  const handleAcuteCareFacilityChange = (value: { name: string; key?: string; [name: string]: any }) => {
    if (value && value.key) {
      appDispatch(
        setSelectCheckOnPatientValues({
          ...selectedCheckOnPatient,
          provider: { name: value.name, id: parseInt(value?.key) },
        })
      );
    }
  };

  return (
    <CheckOnPatientFormPresentation
      checkOnPatientQuestions={getCheckOnPatientQuestions()}
      handleNotes={handleNotes}
      handleDischargeFromServiceCheckbox={handleDischargeFromServiceCheckbox}
      handleActualDate={handleActualDate}
      handleOffTrackDetails={handleOffTrackDetails}
      handleOffTrackStartDate={handleOffTrackStartDate}
      handleOffTrackReason={handleOffTrackReason}
      handleIsOffTrackCheckbox={handleIsOffTrackCheckbox}
      handleCompletedDayVisit={handleCompletedDayVisit}
      handleActualDayVisit={handleActualDayVisit}
      handleProviderDropdown={handleProviderDropdown}
      handleServiceType={handleServiceType}
      handleProcedureDate={handleProcedureDate}
      handleAcuteCareFacilityChange={handleAcuteCareFacilityChange}
      showDelayNotes={showDelayNote}
    />
  );
};

export default CheckOnPatientForm;
