import FilterPill from "components/FilterPill/filter-pill.component";
import moment from "moment";
import { EpisodesAppliedFilterProps, IEpisodeFilterTypeProps } from "pages/episodes/types";

const EpisodeFilters = (props: EpisodesAppliedFilterProps) => {
  const { isFilterApplied, handleRemoveFilterPill, selectedFilterList, handleFilterClearClick } = props;
  const getFilterTextName = (val: Array<IEpisodeFilterTypeProps>, key: string) => {
    if (key.toLowerCase().includes("date")) {
      return `${moment(val[0]?.startDate).format("MM/DD/YYYY")} - ${moment(val[0].endDate).format("MM/DD/YYYY")}`;
    }
    if (val.length > 2) {
      return `${val
        .slice(0, 2)
        .map((el: IEpisodeFilterTypeProps) => el.name)
        .join(" | ")} | (${val.length - 2}+)`;
    }
    return val.map((el: IEpisodeFilterTypeProps) => el.name).join(" | ");
  };
  const getFilterTitle = (name: string) => {
    switch (name) {
      case "admitDate":
        return "Admit Date";

      case "surgeryDate":
        return "Surgery Date";
      case "pnav":
        return "PNav";
      case "tnav":
        return "TNav";
      case "status":
        return "Status";
      default:
        return "Physician";
    }
  };
  return (
    <div className="filter-applied-container">
      {isFilterApplied() && (
        <>
          <div className="filter-text">Applied Filters: </div>
          <div className="filters-applied">
            {Object.entries(selectedFilterList).map(([key, value], index) => {
              if (value && value?.length > 0) {
                return (
                  <FilterPill
                    title={getFilterTitle(key)}
                    text={getFilterTextName(value, key)}
                    onClear={() => {
                      handleRemoveFilterPill(key, value);
                    }}
                    key={`${key}-${index}`}
                  />
                );
              }
            })}
          </div>
          <div className="clear-filter-button" onClick={handleFilterClearClick}>
            Clear All
          </div>
        </>
      )}
    </div>
  );
};
export default EpisodeFilters;
