import CommonState from "components/common-state/common-state.component";
import { CommonStateType } from "components/common-state/common-state.enum";
import TableLoader from "components/TableLoader/table-loader.component";
import { HeaderColumnInfo } from "pages/episodes/types";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { getInitialEpisodeActivityHistoryHeader } from "shared/constant/table";
import Row from "./components/row/row";
import Icon from "components/Icon/icon.component";
import Sort from "components/Sort/sort.component";
import { SortingOrderType } from "shared/enums/sorting-types.enums";
import { useSelector } from "react-redux";
import "./style.scss";
import { getEpisodesSlice } from "state/feature/episodes/episodes.slice";
import ActivityFilter from "./components/activity-filter/activity-filter.container";
import FilterPill from "components/FilterPill/filter-pill.component";
import { EpisodesPageCommonMessages } from "shared/enums/episodes-details.enum";
import { ActivityHistoryPresentationProps } from "./type";

const ActivityHistoryPresentation = (props: ActivityHistoryPresentationProps) => {
  const { currentTableData, isLoading, selectedFilterValues, handleApplyFilter, handleClearFilter, onIncrementPage } =
    props;
  const {
    episodeDetailTabs: { activityHistory },
  } = useSelector(getEpisodesSlice);
  const [headerColumns, setHeaderColumns] = useState<Array<HeaderColumnInfo>>(getInitialEpisodeActivityHistoryHeader());

  const renderSortIcons = (activityLog: HeaderColumnInfo) => {
    if (!activityLog.sortOrder) {
      return null;
    }
    return activityLog.sortOrder === SortingOrderType.DEFAULT ? (
      <Sort />
    ) : (
      <Icon icon={activityLog.sortOrder === SortingOrderType.ASC ? "sort-top" : "sort-bottom"} size={8} />
    );
  };

  const renderLoader = () => {
    return isLoading && currentTableData.length === 0 ? <CommonState type={CommonStateType.LOADING} /> : null;
  };

  return (
    <>
      <ActivityFilter
        handleApplyFilter={handleApplyFilter}
        handleClearFilter={handleClearFilter}
        selectedValues={selectedFilterValues}
      />
      <div className="filter-applied-container">
        {selectedFilterValues.length > 0 && (
          <>
            <div className="filter-text">Applied Filters: </div>
            <div className="filters-applied">
              {
                <FilterPill
                  title="Task Category"
                  text={selectedFilterValues.join(" | ")}
                  onClear={() => {
                    handleClearFilter();
                  }}
                />
              }
            </div>
            <div className="clear-filter-button" onClick={handleClearFilter}>
              Clear All
            </div>
          </>
        )}
      </div>
      {!isLoading && currentTableData.length === 0 ? (
        <CommonState
          type={CommonStateType.NO_ACTIVITY_HISTORY_FOUND}
          className={`no-activity-history-logs-found ${selectedFilterValues.length > 0 ? "applied-filter" : ""}`}
          text="No activity has occured for this patient."
          heading={EpisodesPageCommonMessages.NO_ACTIVITY_FOUND}
        />
      ) : (
        <div className="episode-activity-table">
          <div className={`episode-activity-table-header`}>
            {headerColumns.map((activityLog, index) => (
              <div key={`${index}-${activityLog.key}`} className="column">
                <div className={`column-content ${isLoading ? "column-content-disable-cursor" : ""}`}>
                  <div className="content">{activityLog.name}</div>
                  <span className="sort-icon">{!isLoading && renderSortIcons(activityLog)}</span>
                </div>
              </div>
            ))}
          </div>
          <div className={`episode-activity-table-body ${selectedFilterValues.length > 0 ? "filter-applied" : ""}`}>
            {renderLoader()}
            {activityHistory?.length > 0 ? (
              <InfiniteScroll
                next={() => {
                  onIncrementPage();
                }}
                hasMore={currentTableData.length > 0}
                loader={isLoading ? <TableLoader /> : <></>}
                height="100%"
                dataLength={currentTableData.length}
              >
                {activityHistory.map((activityLog, index) => (
                  <Row index={index} logData={activityLog} />
                ))}
              </InfiniteScroll>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
};

export default ActivityHistoryPresentation;
