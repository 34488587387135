import Icon from "components/Icon/icon.component";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCommonState } from "state/feature/common/common.slice";
import { getNavigatorState, setCallReadStatus } from "state/feature/navigator/navigator.slice";
import { acknowledgeMissedCall } from "state/feature/navigator/navigator.action";
import { useAppDispatch } from "state/store";
import { toast } from "react-toastify";
import { CALL_NOT_READ, CALL_READ } from "shared/constant/commonConstants";
import { getAuth } from "state/feature/auth/auth.slice";
import { USER_ROLE } from "shared/constant/role-constant";
import { CustomToolTip } from "components/CustomTooltip/custom-tooltip.component";
import { Participant } from "shared/types/table.type";
import { CallingStatus } from "shared/enums/calling-status.enum";

export const InboundRowIcons = ({
  voiceMailUrl,
  handleAudioPlayClick,
  clickedIndex,
  callerParticipantDetails,
}: {
  voiceMailUrl?: string | null;
  handleAudioPlayClick: () => void;
  clickedIndex: number;
  callerParticipantDetails?: Participant;
}) => {
  const { featureFlags } = useSelector(getCommonState);
  const authState = useSelector(getAuth);
  const dispatch = useAppDispatch();
  const navigatorState = useSelector(getNavigatorState);
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    if (callerParticipantDetails && navigatorState) {
      const index = navigatorState.calls[clickedIndex].participants.findIndex((item: Participant) => {
        return item.id === callerParticipantDetails.id;
      });
      const navigatorCallParticipantDetails = navigatorState?.calls?.[clickedIndex]?.participants?.[index];
      if (
        navigatorCallParticipantDetails &&
        (navigatorCallParticipantDetails?.status === CallingStatus.MISSED ||
          navigatorCallParticipantDetails?.status === CallingStatus.BUSY ||
          navigatorCallParticipantDetails?.status === CallingStatus.NO_ANSWER) &&
        (!navigatorCallParticipantDetails?.isMissedCallRead ||
          navigatorCallParticipantDetails?.isMissedCallRead === false)
      ) {
        setIsClicked(false);
      } else {
        setIsClicked(true);
      }
    }
  }, [callerParticipantDetails, navigatorState, clickedIndex]);

  return (
    <>
      {voiceMailUrl && (
        <div
          data-tip
          data-for="Voice mail"
          className="call-icon"
          onClick={() => {
            if (
              ((authState.user.roles[0] === USER_ROLE.MANAGER &&
                navigatorState.managerStats &&
                navigatorState.managerStats.selectedNavigator &&
                authState.user.id === navigatorState.managerStats.selectedNavigator.externalId) ||
                authState.user.roles[0] !== USER_ROLE.MANAGER) &&
              !callerParticipantDetails?.isMissedCallRead
            ) {
              dispatch(
                acknowledgeMissedCall({
                  participantId: callerParticipantDetails?.id,
                  callId: navigatorState.calls[clickedIndex].id,
                })
              ).then((response: any) => {
                if (response.error) {
                  toast.error(CALL_NOT_READ, {
                    toastId: "acknowledgement-error",
                    containerId: "main",
                  });
                } else {
                  dispatch(setCallReadStatus({ index: clickedIndex, participantDetail: callerParticipantDetails }));
                  toast.success(CALL_READ, {
                    toastId: "acknowledgement-sucess",
                    containerId: "main",
                  });
                }
              });
            }

            handleAudioPlayClick();
          }}
        >
          <Icon icon="voice-mail" size={20} />
          <CustomToolTip text="Voice mail" id="Voice mail" place="top" />
        </div>
      )}
      {!isClicked &&
        ((authState.user.roles[0] === USER_ROLE.MANAGER &&
          navigatorState.managerStats &&
          navigatorState.managerStats.selectedNavigator &&
          authState.user.id === navigatorState.managerStats.selectedNavigator.externalId) ||
          authState.user.roles[0] !== USER_ROLE.MANAGER) && (
          <div
            data-tip
            data-for="Mark as addressed"
            className="call-icon"
            onClick={() => {
              dispatch(
                acknowledgeMissedCall({
                  participantId: callerParticipantDetails?.id,
                  callId: navigatorState.calls[clickedIndex].id,
                })
              ).then((response: any) => {
                if (response.error) {
                  toast.error(CALL_NOT_READ, {
                    toastId: "acknowledgement-error",
                    containerId: "main",
                  });
                } else {
                  dispatch(setCallReadStatus({ index: clickedIndex, participantDetail: callerParticipantDetails }));
                  toast.success(CALL_READ, {
                    toastId: "acknowledgement-sucess",
                    containerId: "main",
                  });
                  setIsClicked(true);
                }
              });
            }}
          >
            <Icon icon="call-read" size={20} />
            <CustomToolTip text="Mark as addressed" id="Mark as addressed" place="top" />
          </div>
        )}
    </>
  );
};
