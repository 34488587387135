import React from "react";
import _ from "lodash";
const getHighlightedText = (text: string, highlight: string) => {
  if (!highlight.trim()) {
    return <span>{text}</span>;
  }
  const regex = new RegExp(`(${_.escapeRegExp(highlight)})`, "gi");
  const parts = text.split(regex);
  return (
    <span>
      {parts
        .filter((part) => part)
        .map((part, i) => (regex.test(part) ? <span className="bold-text">{part}</span> : <span key={i}>{part}</span>))}
    </span>
  );
};

export default getHighlightedText;
