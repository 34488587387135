import Header from "./components/header/header.component";
import "./admin.styles.scss";
import Providers from "./providers/providers.container";
import { useSelector } from "react-redux";
import { getAdminState } from "state/feature/admin/admin.slice";
import { AdminTab } from "./common/constant";
import Episodes from "./episodes/episode.component";

const AdminPresentation = () => {
  const { activeTab } = useSelector(getAdminState);
  return (
    <div id="admin-container">
      <Header />
      {activeTab === AdminTab.PROVIDERS && <Providers />}
      {activeTab === AdminTab.EPISODES && <Episodes />}
    </div>
  );
};
export default AdminPresentation;
