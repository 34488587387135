import { useState } from "react";
import "./row.styles.scss";

type RiskLevelProps = {
  riskScore: number;
  score: number;
};

const RiskLevel = ({ riskScore, score }: RiskLevelProps) => {
  const riskLevel = score >= riskScore ? "High" : "Standard";
  return <div className={`risk-level-pill ${riskLevel === "High" ? "high" : "standard"}`}>{riskLevel}</div>;
};

const Row = (item: any) => {
  const [isRowHovered, setIsRowHovered] = useState(false);

  return (
    <tr
      onMouseEnter={() => setIsRowHovered(true)}
      onMouseLeave={() => setIsRowHovered(false)}
      className={`${isRowHovered ? "hovered" : ""}`}
    >
      <td className="category">{item.section}</td>
      <td className="risk-score">{item.score}</td>
      <td>
        <RiskLevel riskScore={item.riskScore} score={item.score} />
      </td>
    </tr>
  );
};

export default Row;
