import { IActivityHistoryRow, ICallLog, ICarePlanUtilizationLog, ITocLog } from "state/types/episodes-slice.type";
import moment from "moment";
import ActivityHistoryDetailsColumn from "components/activity-history-details/activity-history-details.component";
import Icon from "components/Icon/icon.component";
import { ActivityLogCategory } from "shared/enums/activity-logs.enum";
import { TaskType } from "shared/enums/task-type.enum";
import { TaskStatus } from "shared/enums/tasks-status.enum";
import { ActivityHistoryRowProps } from "../../type";
import { CALLER, DONE_BY } from "shared/constant/activity-logs-constants";

const Row = (props: ActivityHistoryRowProps) => {
  const { logData, index, className } = props;

  const createdDateInLocal = moment.utc(logData.createDate, "YYYY-MM-DDTHH:mm:ss").local();
  const renderTaskStatusUIMessage = () => {
    const taskDetails =
      logData.activityLogType === TaskType.TOC && logData.taskStatusId === TaskStatus.COMPLETED
        ? (logData.details as ITocLog)
        : null;
    if (taskDetails && taskDetails.isUpdatedByPhysician) {
      return <span className="toc-approve-message">(Changes from submitted)</span>;
    }
    return null;
  };

  const renderDatesForCarePlanUtilization = () => {
    if (logData.activityLogType === TaskType.CARE_PLAN_UTILIZATION) {
      const details = logData.details as ICarePlanUtilizationLog;
      return (
        <>
          <div className="care-plan-dates">
            Admission Date:
            <span>{details.admissionDate ? moment(details.admissionDate)?.format("MM/DD/YYYY") : "-"}</span>
          </div>
          {details.dischargeDate && (
            <div className="care-plan-dates">
              Discharge Date:
              <span>{moment(details.dischargeDate)?.format("MM/DD/YYYY")}</span>
            </div>
          )}
        </>
      );
    }
  };

  const getClassNameForStatus = () => {
    if (logData.activityLogType === TaskType.CALL) {
      const details = logData.details as ICallLog;
      return details.callType === 0 && !details.callStatus.toLowerCase().includes("answered") ? "red-color" : "";
    }
    return "";
  };

  const renderDoneByUI = () => {
    if (logData.activityLogType === TaskType.CALL) {
      const details = logData.details as ICallLog;
      return (
        <div className="done-by">
          {CALLER} <span> {details.caller ?? "-"}</span>
        </div>
      );
    }
    return (
      <div className="done-by">
        {DONE_BY} <span> {logData.createUser ?? "-"}</span>
      </div>
    );
  };

  return (
    <div key={`${logData.id}-${index}`} onClick={() => {}} className={`episode-activity-listing-row ${className}`}>
      <div className="date-time-col column">
        {logData.createDate ? (
          <>
            <div className="date">{createdDateInLocal.format("MM/DD/YYYY")}</div>
            <div className="time">{createdDateInLocal.format("hh:mm A")}</div>
          </>
        ) : (
          "-"
        )}
      </div>
      <div className="icon column">
        {logData.category === ActivityLogCategory.CORE && (
          <Icon icon="core-category" size={15} className="core-category" />
        )}
        {logData.category === ActivityLogCategory.ASSESSMENT && <Icon icon="assessments" size={15} />}
        {logData.category === ActivityLogCategory.NOTES_REMINDERS && <Icon icon="notes-reminders" size={15} />}
        {logData.category === ActivityLogCategory.CARE_PLAN && <Icon icon="care-plan-category" size={15} />}
        {logData.category === ActivityLogCategory.MANAGEMENT && <Icon icon="episode-status-change-icon" size={15} />}
        {logData.category === ActivityLogCategory.CALL && <Icon icon="call-icon" size={16} />}
      </div>
      <div className="task-info-col column">
        <div className="task-name">{logData.name}</div>
        {logData.activityLogType !== TaskType.CARE_PLAN_UTILIZATION &&
          logData.activityLogType !== TaskType.EPISODE_NAVIGATION_ACCEPTED &&
          logData.activityLogType !== TaskType.EPISODE_NAVIGATION_DECLINED &&
          logData.activityLogType !== TaskType.EPISODE_NAVIGATION_NOTES_CHANGES && (
            <div className="task-status">
              <div className="task-status-label">Status:</div>
              <span className={getClassNameForStatus()}>
                {logData.status}
                {renderTaskStatusUIMessage()}
              </span>
            </div>
          )}
        {renderDoneByUI()}
        {renderDatesForCarePlanUtilization()}
      </div>
      <div className="column">
        <ActivityHistoryDetailsColumn
          activityLogType={logData.activityLogType}
          taskDetails={logData.details}
          className="episode-activity-tab"
          notes={logData.notes}
        />
      </div>
    </div>
  );
};
export default Row;
