import Icon from "../../../../../components/Icon/icon.component";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTwilioClientService } from "shared/services/twilio/twilio-client.service";
import {
  addPatientToPatientArray,
  saveConversationParticipants,
  setUserIdforMessage,
} from "state/feature/messaging/messaging.slice";
import { getCallState, saveCurrentCall } from "state/feature/call/call.slice";
import { useAppDispatch } from "state/store";
import { getWhiteLabelPhoneNumberAsync } from "state/feature/navigator/navigator.action";
import { getCommonState, setIsOpenCallModal, setIsOpenNumberSelectorModal } from "state/feature/common/common.slice";
import { isMobile } from "react-device-detect";
import Avatar from "components/Avatar/avatar.component";
import Button from "components/Button/button.component";
import SidePopup from "components/SidePopup/side-popup.component";
import Modal from "components/Modal/modal.component";
import ModalHeader from "components/ModalHeader/modal-header.component";
import { NO_PHONE_NUMBER_FOR_PRACTICE, TOAST_MESSAGE_NO_PHONE_NUMBER_ASSIGNED } from "shared/constant/commonConstants";
import CallParticipantsDropdown from "components/call-participants-dropdown/call-participants-dropdown.component";
import { getNavigatorState } from "state/feature/navigator/navigator.slice";
import { ContactDetail } from "state/types/task-management-slice.type";
import { ContactType } from "shared/enums/contact-type.enum";
import { PatientContactType } from "state/types/contact-slice.type";
import { CustomToolTip } from "components/CustomTooltip/custom-tooltip.component";
import { CallingStatus } from "shared/enums/calling-status.enum";
import { CallContext } from "App";

interface PatientTableRowProps {
  id: string;
  patient: string;
  physician: string;
  procedure: string;
  location: string;
  patientPhoneNumber: string;
  patientUserID: string;
  handleMessageSidePopup: () => void;
  clientID: string;
  patientContactDetails: ContactDetail[];
  openPhoneNumberModal: (value: string) => void;
  currentPhoneNumberModal: string;
  totalLength: number;
  currentLength: number;
  patientDetails: PatientContactType;
}

const Row = ({
  id,
  patient,
  physician,
  procedure,
  location,
  patientPhoneNumber,
  patientUserID,
  handleMessageSidePopup,
  clientID,
  patientContactDetails,
  openPhoneNumberModal,
  currentPhoneNumberModal,
  totalLength,
  currentLength,
  patientDetails,
}: PatientTableRowProps) => {
  const { modal: modalState, twilioErrorCode } = useSelector(getCommonState);
  const twilioClient = useTwilioClientService();
  const navigatorState = useSelector(getNavigatorState);
  const [isCallButtonClicked, setIsCallButtonClicked] = useState(false);
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const callState = useSelector(getCallState);
  const [isRowHovered, setIsRowHovered] = useState(false);
  const [showPhoneNumbers, setShowPhoneNumbers] = useState(false);
  const { ongoingCall: isOngoingCall } = useContext(CallContext);
  const handleSidePopupClose = () => {
    dispatch(setIsOpenCallModal(false));
  };
  const handleNumberSelector = () => {
    dispatch(setIsOpenNumberSelectorModal(true));
  };
  const [isOpen, setIsOpen] = useState(false);

  const handleActionPopupClose = () => {
    if (isMobile && !callState.twilioCallDevice) {
      twilioClient.registerDevice();
    }
    setIsOpen(!isOpen);
  };

  const makeACall = async (phoneNumber: string) => {
    try {
      if (!isCallButtonClicked) {
        setIsCallButtonClicked(true);
        const { data, status } = await appDispatch(getWhiteLabelPhoneNumberAsync({ clientId: clientID })).unwrap();
        if (status === 204) {
          toast.error(NO_PHONE_NUMBER_FOR_PRACTICE, {
            containerId: "main",
            toastId: "call",
          });
        } else if (data && data.whiteLabelPhoneNumber) {
          const callerDetails = {
            name: `${navigatorState.currentUserProfile?.firstName ? navigatorState.currentUserProfile?.firstName : ""}${
              navigatorState.currentUserProfile?.lastName ? ` ${navigatorState.currentUserProfile?.lastName}` : ""
            }`,
            id: navigatorState.currentUserProfile?.id ? navigatorState.currentUserProfile?.id : "",
            phoneNumber: navigatorState.currentUserProfile?.forwardingPhoneNumber
              ? navigatorState.currentUserProfile?.forwardingPhoneNumber
              : "",
            type: "Navigator",
          };
          const effectiveNavigator = {
            id: navigatorState.currentUserProfile?.id ? navigatorState.currentUserProfile?.id : "",
            name: `${
              navigatorState.currentUserProfile?.firstName ? navigatorState.currentUserProfile?.firstName : ""
            } ${navigatorState.currentUserProfile?.lastName ? navigatorState.currentUserProfile?.lastName : ""}`,
            phoneNumber: navigatorState.currentUserProfile?.forwardingPhoneNumber
              ? navigatorState.currentUserProfile?.forwardingPhoneNumber
              : "",
          };
          localStorage.setItem("callStatus", CallingStatus.CONNECTED);
          await twilioClient.addParticipantsAndCreateConference(
            phoneNumber,
            data.whiteLabelPhoneNumber,
            [
              {
                phoneNumber: phoneNumber ? phoneNumber : "",
                id: patientUserID ? patientUserID : "",
                name: patient ? patient : "",
                type: ContactType.PATIENT,
              },
              callerDetails,
            ],
            clientID,
            effectiveNavigator,
            -1,
            async () => {
              dispatch(saveCurrentCall(null));
              toast.dismiss();
            }
          );
          handleSidePopupClose();
        } else {
          localStorage.setItem("callStatus", CallingStatus.DISCONNECTED);
          toast.error(TOAST_MESSAGE_NO_PHONE_NUMBER_ASSIGNED, {
            containerId: "main",
            toastId: "call",
          });
        }
        setIsCallButtonClicked(false);
      }
    } catch (error: any) {
      console.log("...", error);
      localStorage.setItem("callStatus", CallingStatus.DISCONNECTED);
      toast.error(
        "The microphone is currently disabled in your browser. You must enable the microphone and reload the browser (which disconnects the current call) to resolve the issue",
        { containerId: "main", toastId: "twilio-error" }
      );
      callState.twilioCallDevice.disconnectAll();
      dispatch(saveCurrentCall(null));
      setIsCallButtonClicked(false);
      toast.dismiss("call");
    }
  };
  const handleMessageSidePopupClick = () => {
    dispatch(setUserIdforMessage(patientUserID));
    dispatch(addPatientToPatientArray(patientDetails));
    dispatch(saveConversationParticipants(patient));
    handleMessageSidePopup();
  };

  const togglePhoneNumbers = () => {
    setShowPhoneNumbers((prev) => !prev);
  };

  const showPhoneNumbersComponent = () => {
    return (
      <CallParticipantsDropdown
        id={id}
        patient={patient}
        contactDetails={patientContactDetails}
        onClick={({ phoneNumber }) => {
          if (!callState.currentCall) {
            makeACall(phoneNumber);
            dispatch(setIsOpenNumberSelectorModal(false));
          } else {
            toast.error("Another call is ongoing", {
              toastId: "call-ongoing",
              containerId: "main",
            });
          }
        }}
        className={totalLength > 5 && totalLength / 2 < currentLength ? "up" : "down"}
      />
    );
  };

  useEffect(() => {
    if (id !== currentPhoneNumberModal) {
      setShowPhoneNumbers(false);
    }
  }, [id, currentPhoneNumberModal]);

  useEffect(() => {
    if (isCallButtonClicked) {
      setIsRowHovered(false);
    }
  }, [isCallButtonClicked]);

  return !isMobile ? (
    <tr
      onMouseEnter={() => setIsRowHovered(true)}
      onMouseLeave={() => {
        setIsRowHovered(false);
        setShowPhoneNumbers(false);
      }}
      className={isRowHovered ? "hovered" : ""}
    >
      <td>{patient}</td>
      <td>{physician}</td>
      <td>{procedure}</td>
      <td>{location}</td>
      <td>
        <div className={`icons ${isRowHovered ? "visible" : "hidden"}`}>
          <div
            className={`icon-background ${
              twilioErrorCode ||
              isOngoingCall ||
              localStorage.getItem("callStatus") === CallingStatus.CONNECTED ||
              localStorage.getItem("callStatus") === CallingStatus.RINGING
                ? "disabled"
                : ""
            }`}
            data-tip
            data-for={"Call"}
            onClick={() => {
              if (
                !isOngoingCall ||
                !(
                  localStorage.getItem("callStatus") === CallingStatus.CONNECTED ||
                  localStorage.getItem("callStatus") === CallingStatus.RINGING
                )
              ) {
                if (!twilioErrorCode) {
                  if (patientContactDetails && patientContactDetails.length > 1) {
                    openPhoneNumberModal(id);
                    togglePhoneNumbers();
                  } else if (patientContactDetails && patientContactDetails.length === 0 && !patientPhoneNumber) {
                    toast.error("Phone number does not exist", {
                      toastId: "phone-number-error",
                      containerId: "main",
                    });
                  } else {
                    if (patientContactDetails && patientContactDetails.length === 1) {
                      makeACall(patientContactDetails[0].phoneNumber);
                    } else {
                      makeACall(patientPhoneNumber);
                    }
                  }
                } else {
                  toast.error("Calling is not enabled, please refresh the page to enable it.", {
                    toastId: "phone-number-error",
                    containerId: "main",
                  });
                }
              }
            }}
          >
            <Icon icon="call-icon" size={17} />
            <CustomToolTip text="Call" place="top" />
            {showPhoneNumbers &&
              patientContactDetails &&
              patientContactDetails.length > 1 &&
              showPhoneNumbersComponent()}
          </div>
          <div className="icon-background message" data-tip data-for={"Message"} onClick={handleMessageSidePopupClick}>
            <Icon icon="navigator-message" size={17} />
            <CustomToolTip text="Message" place="top" />
          </div>
        </div>
      </td>
    </tr>
  ) : (
    <>
      <tr onClick={handleActionPopupClose}>
        <td>
          <span className="identity-box">
            <Avatar personName={patient} />
          </span>
        </td>
        <td>
          <span className="patient-name">{patient}</span>
          <span className="procedure-name">{procedure}</span>
        </td>
        <SidePopup
          isOpen={isOpen}
          onClose={() => {
            handleActionPopupClose();
          }}
          className="bottom call-or-message-popup"
        >
          <div className="contact-name">{patient}</div>
          <div className="practice-name">{procedure}</div>
          <Button
            text="Message"
            className="green-button apply-button"
            icon="Message-generate"
            iconClassName="Message-generate-icon"
            buttonSize={16}
            onClick={handleMessageSidePopupClick}
          />
          <Button
            text="Call"
            className="white-button cancel-button"
            icon="Call-generate"
            iconClassName="phone-call-icon"
            buttonSize={18}
            onClick={() => {
              if (patientContactDetails && patientContactDetails.length > 1) {
                openPhoneNumberModal(id);
                setShowPhoneNumbers(true);
                handleNumberSelector();
              } else if (patientContactDetails && patientContactDetails.length === 0 && !patientPhoneNumber) {
                toast.error("Phone number does not exist", {
                  toastId: "phone-number-error",
                  containerId: "main",
                });
              } else {
                if (patientContactDetails && patientContactDetails.length === 1) {
                  makeACall(patientContactDetails[0].phoneNumber);
                } else {
                  makeACall(patientPhoneNumber);
                }
              }
            }}
          />
        </SidePopup>
      </tr>
      <Modal
        isOpen={
          modalState.isOpenNumberSelectorModal &&
          showPhoneNumbers &&
          patientContactDetails &&
          patientContactDetails.length > 1
        }
        modalHeader={
          <ModalHeader heading="Select Number" onCloseModal={() => dispatch(setIsOpenNumberSelectorModal(false))} />
        }
        className="number-select-modal center"
        contentClass="number-select-content"
      >
        {showPhoneNumbers && patientContactDetails && patientContactDetails.length > 1 && showPhoneNumbersComponent()}
      </Modal>
    </>
  );
};

export default Row;
