export enum EpisodeTaskStatus {
  COMPLETED = "completed",
  CLOSED = "closed",
  PROCESSING = "processing",
  NEW = "new",
  IN_PROGRESS = "in progress",
  DISCHARGED = "discharged",
  ON_HOLD = "on-hold",
  EXCLUDED = "excluded",
  DECEASED = "Deceased",
  CANCELED = "Canceled",
}
