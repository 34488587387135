import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import TaskManagement from "./task-management.component";
import "./task-management.styles.scss";
import { createContext, useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCommonState } from "state/feature/common/common.slice";
import { resetEpisodeDetailsTasksList } from "state/feature/episodes/episodes.slice";
import BrowserViewContainer from "./browser-view/browser-view.container";
import {
  getTaskManagementState,
  resetTaskManagementState,
  setRedirectParams,
} from "state/feature/task-management/task-management.slice";
import { TaskPageName } from "shared/enums/page-name.enum";
import { useHistory, useParams } from "react-router";
import useUrlBlocking from "shared/hooks/use-blocking.hook";
import { setLockState } from "state/feature/task-management/task-management.action";

export const TaskManagementContext = createContext<{ ref: null | React.MutableRefObject<any> }>({
  ref: null,
});

const TaskManagementContainer = () => {
  useAxiosAuthenticated();
  useUrlBlocking();

  const history = useHistory();
  const dispatch = useDispatch();
  const { featureFlags, featureFlagsLoading } = useSelector(getCommonState);
  const { redirectParams } = useSelector(getTaskManagementState);
  const { intakeId, taskId, taskType } = useParams<{ intakeId: string; taskType: string; taskId: string }>();

  useLayoutEffect(() => {
    const element = document.querySelector(".app-main-content");
    element?.setAttribute("style", "padding:0");

    return () => {
      element?.setAttribute("style", "padding:2.5rem 3.2rem 2.5rem 3.2rem");
    };
  }, []);

  useEffect(() => {
    if (intakeId || taskType || taskId) {
      dispatch(setRedirectParams({ intakeId, taskId, taskType: taskType as TaskPageName }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetEpisodeDetailsTasksList());
      dispatch(resetTaskManagementState());
      dispatch(setLockState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (redirectParams && (!redirectParams.intakeId || !redirectParams.taskId || !redirectParams.taskType)) {
      history.push("/not-found");
    }
  }, [history, redirectParams]);

  useEffect(() => {
    if (!redirectParams && !featureFlagsLoading.taskManagement && !featureFlags.taskManagement) {
      history.push("/not-found");
    }
  }, [history, redirectParams, featureFlags.taskManagement, featureFlagsLoading.taskManagement]);

  return (
    <div className="task-management-container">
      {featureFlags.taskManagementListView && !redirectParams ? <TaskManagement /> : null}
      <BrowserViewContainer />
    </div>
  );
};

export default TaskManagementContainer;
