import { useState } from "react";
import MiscPresentation from "./misc.component";
import "./misc.style.scss";
import { useHistory, useParams } from "react-router";
import { useAppDispatch } from "state/store";
import { updatePatientDeclinedNavigationAsync } from "state/feature/episodes/episodes.action";
import { toast } from "react-toastify";
import { TOAST_MESSAGES } from "pages/login/common/constants";
import { SOMETHING_WENT_WRONG } from "shared/constant/commonConstants";
import { getEpisodesSlice, setPatientDeclinedNavigationEpisode } from "state/feature/episodes/episodes.slice";
import { isEmptyString } from "shared/methods/utilityFunctions";
import { useSelector } from "react-redux";
import { PatientDeclineNavigationFieldType } from "./types";
import { IntakeStatus } from "shared/enums/intake-tasks-type.enum";
const Misc = () => {
  const { episodeDetails } = useSelector(getEpisodesSlice);
  const [patientDeclineNavigationField, setPatientDeclineNavigationField] = useState<PatientDeclineNavigationFieldType>(
    {
      patientNavigationDeclined: episodeDetails?.patientNavigationDeclined ?? false,
      patientNavigationDeclinedNotes: episodeDetails?.patientNavigationDeclinedNotes ?? "",
    }
  );
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const { intakeId } = useParams<{ intakeId: string }>();
  const history = useHistory();
  const appDispatch = useAppDispatch();
  const [isValueChanged, setIsValueChanged] = useState(false);
  const isPatientNavigationIsDisabled =
    episodeDetails?.intakeStatusId !== IntakeStatus.NEW && episodeDetails?.intakeStatusId !== IntakeStatus.PROCESSING;
  const handlePatientDeclinedCheckbox = () => {
    setIsValueChanged(true);
    setPatientDeclineNavigationField((prev) => {
      return { ...prev, patientNavigationDeclined: !prev.patientNavigationDeclined };
    });
  };

  const handleNotes = (text: string) => {
    if (!/^\s/.test(text)) {
      setIsValueChanged(true);
      setPatientDeclineNavigationField((prev) => {
        return { ...prev, patientNavigationDeclinedNotes: text };
      });
    }
  };

  const handleSaveChanges = async () => {
    if (intakeId && intakeId) {
      try {
        setIsLoadingButton(true);
        const notes =
          patientDeclineNavigationField.patientNavigationDeclined &&
          !isEmptyString(patientDeclineNavigationField.patientNavigationDeclinedNotes)
            ? patientDeclineNavigationField.patientNavigationDeclinedNotes
            : null;
        await appDispatch(
          updatePatientDeclinedNavigationAsync({
            intakeId: Number(intakeId),
            isPatientNavigationDeclined: patientDeclineNavigationField.patientNavigationDeclined,
            patientNavigationDeclinedNotes: notes,
          })
        ).unwrap();
        if (notes === null) {
          setPatientDeclineNavigationField((prev) => {
            return { ...prev, patientNavigationDeclinedNotes: "" };
          });
        }
        toast.success(TOAST_MESSAGES.DATA_SAVE_SUCCESSFULLY, {
          toastId: "misc-save-sucess",
          containerId: "main",
        });
        appDispatch(
          setPatientDeclinedNavigationEpisode({
            patientNavigationDeclined: patientDeclineNavigationField.patientNavigationDeclined,
            patientNavigationDeclinedNotes: notes,
          })
        );
      } catch (error) {
        toast.error(SOMETHING_WENT_WRONG, {
          toastId: "misc-error",
          containerId: "main",
        });
      } finally {
        setIsLoadingButton(false);
        setIsValueChanged(false);
      }
    }
  };

  const handleCancelButton = () => {
    setIsValueChanged(false);
    history.push("/episodes");
  };

  return (
    <MiscPresentation
      handlePatientDeclinedCheckbox={handlePatientDeclinedCheckbox}
      handleNotes={handleNotes}
      patientDeclineNavigationField={patientDeclineNavigationField}
      handleSaveChanges={handleSaveChanges}
      isLoadingSaveButton={isLoadingButton}
      handleCancelButton={handleCancelButton}
      isPatientNavigationIsDisabled={isPatientNavigationIsDisabled}
      isValueChanged={isValueChanged}
    />
  );
};
export default Misc;
