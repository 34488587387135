import Button from "components/Button/button.component";
import { useSelector } from "react-redux";
import { getCommonState } from "state/feature/common/common.slice";
import Modal from "../modal.component";
import CustomDropDown from "components/CustomDropdown/custom-dropdown.component";
import "./manager-approval-task-modal.styles.scss";
import { isEmptyString } from "shared/methods/utilityFunctions";
import moment from "moment";
import { getBrowserState, getCurrentPageTaskState } from "state/feature/task-management/task-management.slice";
import { ManagerTaskApprovalComponentProps } from "./manager-approval-task-modal.types";
import Error from "components/error-message/error-message.component";
import RejectButtonLoader from "shared/assets/images/BlackLoader.gif";

function ManagerTaskApprovalModalPresentation(props: ManagerTaskApprovalComponentProps) {
  const { modal } = useSelector(getCommonState);
  const { currentTab } = useSelector(getBrowserState);
  const {
    delayTask: { delayAttempts, delayStatuses },
  } = useSelector(getCurrentPageTaskState(currentTab!.taskId));
  const {
    handleOnApprove,
    handleOnReject,
    delayStatus,
    result,
    action,
    handleOnCancel,
    handleChangeNote,
    note,
    reasonForDeceased,
    isLoadingApproveButton,
    isLoadingRejectButton,
    isFormDirty,
    showNotesError,
  } = props;
  return (
    <Modal isOpen={modal.isOpenManagerTaskApprovalModal} className="center manager-task-approval-popup">
      <div
        className={`manager-task-approval-container ${
          delayAttempts && delayAttempts.length > 0 ? "with-delay-attempts" : "no-delay-attempts"
        }`}
      >
        <div>
          <div className="header">Closed Task Approval</div>
          <div className="content">
            <div className="manager-task-approval-content-title">Delay Status</div>
            <CustomDropDown
              placeholder="Select"
              dropDownMenuItems={[]}
              handleValueChanges={(option) => {}}
              value={delayStatus}
              idFieldName="key"
              dropDownBoxClass="status-dropdown"
              selectionClass="status-selection"
              dropDownContainerClass={`status-dropdown-container manager-approval-popup-dropdown-container `}
              isDisabled={true}
            />
            <div className="manager-task-approval-content-title">Result</div>
            <CustomDropDown
              placeholder="Select"
              dropDownMenuItems={[]}
              handleValueChanges={(option: any) => {}}
              value={result}
              idFieldName="key"
              dropDownBoxClass="result-dropdown"
              selectionClass="result-selection"
              dropDownContainerClass={`result-dropdown-container manager-approval-popup-dropdown-container `}
              isDisabled={true}
            />
            <div className="manager-task-approval-content-title">Action</div>
            <CustomDropDown
              placeholder="Select"
              dropDownMenuItems={[]}
              handleValueChanges={(option: any) => {}}
              value={action}
              idFieldName="key"
              dropDownBoxClass="action-dropdown"
              selectionClass="action-selection"
              dropDownContainerClass={`action-dropdown-container manager-approval-popup-dropdown-container`}
              isDisabled={true}
            />
            {reasonForDeceased.key && !isEmptyString(reasonForDeceased.key.toString()) && (
              <>
                <div className="manager-task-approval-content-title">Reason For Deceased</div>
                <CustomDropDown
                  placeholder="Select"
                  dropDownMenuItems={[]}
                  handleValueChanges={(option: any) => {}}
                  value={reasonForDeceased}
                  idFieldName="key"
                  dropDownBoxClass="action-dropdown"
                  selectionClass="action-selection"
                  dropDownContainerClass={`action-dropdown-container manager-approval-popup-dropdown-container`}
                  isDisabled={true}
                />
              </>
            )}

            <div className="manager-task-approval-content-title">Notes</div>
            <textarea
              className="text-area"
              id={`textarea`}
              name={`textarea`}
              maxLength={2000}
              cols={44}
              rows={3}
              placeholder="Enter any additional information for your answer"
              onChange={(event: any) => {
                handleChangeNote(event.target.value);
              }}
              disabled={false}
              value={note}
            />
            <div className="notes-length-and-error-message">
              <div className="error-message">
                {showNotesError && isFormDirty && <Error message="Notes are mandatory to reject the approval." />}
              </div>
              <div className="note-length">{note.length > 0 ? `${note.length} / 2000` : ""}</div>
            </div>
          </div>
        </div>
        <div className="button-section">
          <Button text="Cancel" className="cancel-button" onClick={handleOnCancel} />
          <Button
            text="Reject"
            disabled={isLoadingApproveButton || isLoadingRejectButton}
            className="reject-button"
            onClick={handleOnReject}
            showLoader={isLoadingRejectButton}
            buttonLoaderImage={RejectButtonLoader}
          />
          <Button
            text="Approve"
            disabled={isLoadingApproveButton || isLoadingRejectButton}
            className="green-button approve-button"
            onClick={handleOnApprove}
            showLoader={isLoadingApproveButton}
          />
        </div>
        {delayAttempts && delayAttempts?.length > 0 && (
          <div className="previous-attempts-section">
            <div className="line"></div>
            <div className="previous-attempts-title">Previous Attempts</div>
            <div className="delay-previous-attempts">
              {delayAttempts.map((item) => {
                const filteredDelayStatus = delayStatuses.find(
                  (delay) => item.taskDelayStatusId === delay.taskDelayStatusId
                );
                return (
                  <div key={item.timeOfDelay} className="delay-attempts-line">
                    {moment(item.timeOfDelay).format("MM/DD/YYYY")} | {moment(item.timeOfDelay).format("hh:mm A")}
                    {", "}
                    {filteredDelayStatus?.status}
                    {", "}
                    {filteredDelayStatus?.result.find((i) => i.id === item.taskDelayResultId)?.text} by {item.delayBy}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default ManagerTaskApprovalModalPresentation;
