import { preferedNumberRadioList } from "shared/constant/commonConstants";

export const SavedSuccessfully = "Patient Demographics Updated Successfully.";
export const DropdownitemValuesStringKey = { key: "", name: "" };
export const DropdownitemValues = { key: -1, name: "" };
export const DropdownValues = { value: DropdownitemValues, isValid: true };
export const stringDataValues = { value: "", isValid: true };

export const FormFieldDetails = {
  id: 0,
  isActive: false,
  mbi: stringDataValues,
  mbiEffectiveDate: { value: null, isValid: true },
  mrn: stringDataValues,
  pFirstName: stringDataValues,
  pMiddlename: stringDataValues,
  pLastName: stringDataValues,
  suffixName: stringDataValues,
  preferredName: stringDataValues,
  externalID: stringDataValues,
  gender: DropdownValues,
  dateOfBirth: { value: null, isValid: true },
  maritalStatus: DropdownValues,
  primaryCarePhysician: DropdownValues,
  pcpPractice: DropdownValues,
  mobilePhone: stringDataValues,
  homePhone: stringDataValues,
  preferredPhone: { value: preferedNumberRadioList, isValid: true },
  address: stringDataValues,
  city: stringDataValues,
  state: { value: DropdownitemValuesStringKey, isValid: true },
  zipCode: stringDataValues,
  email: stringDataValues,
  emergencyContactName: stringDataValues,
  emergencyContactNumber: stringDataValues,
  coachName: stringDataValues,
  coachRelationship: DropdownValues,
  coachEmailId: stringDataValues,
  coachNumber: stringDataValues,
  consentCoachContact: { value: false, isValid: true },
};

export const ErrorMessages = {
  SPECIAL_CHAR_NOT_ALLOWED: "Special characters are not allowed",
  ENTER_MBI_SAME_AS_MEDICARE: "Enter MBI exactly as it appears on the Medicare Card",
  MRN: "Length should be less than 50 characters.",
  ENTER_VALID_EMAIL: "Please enter valid email id.",
  FIRST_NAME_REQUIRED: "Patient First Name is required",
  LAST_NAME_REQUIRED: "Patient Last Name is required",
  LENGTH_SHOULD_BE_30: "Length should be less than 30 characters.",
  PLEASE_SELECT_DROPDOWN: "Please select one from dropdown.",
  PLEASE_ENTER_VALID_PHONE: "Please enter a valid phone number.",
  PLEASE_ENTER_VALID_PREFER: "Please select preferred number",
  LENGHT_SHOULD_BE_3_95: "Length should be minimum 3 and maximum 95 characters.",
  LENGHT_SHOULD_BE_3_85: "Length should be minimum 3 and maximum 85 characters.",
  LENGHT_SHOULD_BE_5: "Length should be equal to 5 digits.",
  DOBMUSTBE1900: "DOB year must be 1900 or greater",
  DOBMUSTBE18: "Patient's age must be 18 or greater",
  DOBREQUIRED: "DOB is required",
};

export const ErrorFieldNames = {
  MBI: "MBI",
  EMAIL: "Email",
};
