import Button from "components/Button/button.component";
import "./admit-task-footer.styles.scss";
import { AdmitTaskFooterProps } from "../admit-task.props";
import { TaskFooterButtonsText } from "shared/enums/task-management-footer.enum";

const AdmitTaskFooter = (props: AdmitTaskFooterProps) => {
  const {
    isDisabled,
    handleSubmitButton,
    isLoadingButton,
    handleCancelButton,
    handleDelayButton,
    isTaskCompletedOrClosed,
    isDelayedTaskRequiresApproval,
    isDisabledTaskRequiredApprovalButton,
    handleOpenManagerTaskApprovalButton,
  } = props;
  return (
    <footer className="admit-task-footer-container">
      <Button
        text={TaskFooterButtonsText.CANCEL}
        className="cancel-button"
        disabled={false}
        onClick={handleCancelButton}
      />
      {!isTaskCompletedOrClosed && (
        <>
          {isDelayedTaskRequiresApproval ? (
            <Button
              text={TaskFooterButtonsText.MANAGER_APPROVAL}
              className="manager-approval-button"
              onClick={handleOpenManagerTaskApprovalButton}
              disabled={isLoadingButton || isDisabledTaskRequiredApprovalButton}
            />
          ) : (
            <Button
              text={TaskFooterButtonsText.DELAY}
              className="cancel-button"
              onClick={handleDelayButton}
              disabled={isLoadingButton}
            />
          )}
          <Button
            text={TaskFooterButtonsText.DONE}
            className={`submit-button`}
            onClick={() => handleSubmitButton()}
            disabled={isDisabled}
          />
        </>
      )}
    </footer>
  );
};

export default AdmitTaskFooter;
