import { useSelector } from "react-redux";
import { Redirect } from "react-router";
import { getAuth } from "state/feature/auth/auth.slice";

export const PublicRouteGuard = ({ children }: { children: any }) => {
  const authState = useSelector(getAuth);

  if (authState.auth.isAuthenticated) {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      return <Redirect to={{ pathname: `${params.get("redirect")}` }} />;
    } else {
      return <Redirect to={{ pathname: "/dashboard" }} />;
    }
  } else {
    return children;
  }
};
