import NoTaskImage from "shared/assets/images/no-task-found.svg";
import "./no-task-found.styles.scss";
import { EpisodesPageCommonMessages } from "shared/enums/episodes-details.enum";

const NoTaskFound = () => {
  return (
    <div className="no-task-found-container">
      <img src={NoTaskImage} alt="No task found" className="no-task-found-image" />
      <div className="text">{EpisodesPageCommonMessages.NO_EPISODE_TASKS_FOUND}</div>
    </div>
  );
};

export default NoTaskFound;
