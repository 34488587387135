import { TaskStatus } from "shared/enums/tasks-status.enum";
import { EpisodeTasksTableRowProps } from "../episode-tasks-table/props";
import {
  checkForDash,
  checkIsTaskIsTOCTaskById,
  isEmptyString,
  removeLineBreaksFromText,
} from "shared/methods/utilityFunctions";
import { CustomToolTip } from "components/CustomTooltip/custom-tooltip.component";
import { FormType } from "shared/enums/form-types.enum";
import Icon from "components/Icon/icon.component";
import NoteForStrickedTask from "../NoteForStrickedTask/note-for-stricked-task";
import { STRICKED_ROW } from "shared/constant/commonConstants";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getCommonState } from "state/feature/common/common.slice";

const EpisodeTasksRow = (props: EpisodeTasksTableRowProps) => {
  const { rowData, index, className, onClickTask, isDisabled, isFutureTask, onClickEditIcon } = props;
  const isStricked = className === STRICKED_ROW;
  const [onHover, setHover] = useState(false);
  const { featureFlags } = useSelector(getCommonState);
  const isShowEditIcon =
    featureFlags.AllowTaskOwnerChange &&
    [
      FormType.SEND_ORDER,
      FormType.ADMIT_TASK_WITH_PROVIDER,
      FormType.CHECK_ON_PATIENT_WITH_PROVIDER,
      FormType.REMINDER,
    ].includes(rowData.taskTypeId) &&
    [TaskStatus.IN_PROGRESS, TaskStatus.NEW, TaskStatus.PROCESSING].includes(rowData.taskStatusId) &&
    rowData.ownerName &&
    rowData.ownerId
      ? true
      : false;

  const getStatusColumnData = () => {
    if (
      checkIsTaskIsTOCTaskById(rowData.taskTypeId) &&
      rowData.isOutdated &&
      (rowData.taskStatusId === TaskStatus.IN_PROGRESS || rowData.taskStatusId === TaskStatus.PROCESSING)
    ) {
      return <div className="outdated-status">Outdated</div>;
    } else if (
      rowData.secondaryTaskStatusId &&
      (rowData.taskStatusId === TaskStatus.IN_PROGRESS || rowData.taskStatusId === TaskStatus.PROCESSING)
    ) {
      if (rowData.secondaryTaskStatusId === TaskStatus.WAITING_FOR_APPROVAL) {
        return <div className="waiting-approval">Awaiting Approval</div>;
      }
      if (rowData.secondaryTaskStatusId === TaskStatus.REJECT_TASK_APPROVAL) {
        return <div className="rejected-approval">Rejected</div>;
      }
    } else if (rowData.statusType) {
      return <div className="status-type">{rowData.statusType}</div>;
    }
    return null;
  };

  const getTaskNameColumnData = () => {
    if (rowData.taskTypeId === FormType.REMINDER) {
      const reminderTitle = `Title: ${rowData?.taskTitle ?? "-"}`;
      return (
        <div className="reminder-title" data-tip data-for={reminderTitle}>
          {reminderTitle}
          <CustomToolTip place="top" id={reminderTitle} text={reminderTitle} />
        </div>
      );
    } else if (checkIsTaskIsTOCTaskById(rowData.taskTypeId)) {
      const serviceDetails = `${
        rowData?.providerType && !isEmptyString(rowData?.providerType) ? rowData?.providerType : "-"
      } | ${
        rowData?.provider && !isEmptyString(rowData?.provider) ? removeLineBreaksFromText(rowData?.provider) : "-"
      }`;
      return (
        <div className="service-details" data-tip data-for={serviceDetails}>
          {serviceDetails}
          {rowData?.providerType &&
            !isEmptyString(rowData?.providerType) &&
            rowData?.provider &&
            !isEmptyString(rowData?.provider) && (
              <CustomToolTip place="top" id={serviceDetails} text={serviceDetails} />
            )}
        </div>
      );
    }
    return null;
  };

  const getClassName = (nonStrickenedColumn?: boolean) => {
    if (!nonStrickenedColumn && isStricked && isFutureTask) {
      return `${className} disabled`;
    } else if (!nonStrickenedColumn && isStricked && !isFutureTask) {
      return className;
    } else if (isShowEditIcon && isFutureTask) {
      return `disabled`;
    }
    return "";
  };

  return (
    <div
      key={`${rowData.id}-${index}`}
      onClick={() => {
        if (!isDisabled) {
          onClickTask(rowData);
        }
      }}
      onMouseDown={() => setHover(true)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={`episode-listing-row ${isStricked ? "no-cursor" : className} ${
        !isStricked && isShowEditIcon ? "no-cursor-for-show-edit" : ""
      }`}
    >
      <div className={`column ${getClassName()}`}>
        <div className="content">{rowData.startDate}</div>
      </div>
      <div className={`column ${getClassName()}`}>
        <div className="content">{rowData.dueDate}</div>
      </div>
      <div className="column taskname">
        <div className="content">
          <div className={`${getClassName()}`}>{rowData.taskName}</div>
          {getTaskNameColumnData()}
        </div>
      </div>
      <div className="column status">
        <div className="content">
          <div className={`${getClassName()}`}>{rowData?.taskStatus}</div>
          {getStatusColumnData()}
        </div>
      </div>
      <div className={`column ${getClassName(true)}`}>
        <div className="content">{rowData.completedOn}</div>
      </div>
      <div className={`column ${getClassName(true)}`}>
        <div className="content">{rowData.doneBy}</div>
      </div>
      {featureFlags.AllowTaskOwnerChange && (
        <div className={`column owner ${getClassName(true)}`}>
          <div className="content">{rowData?.ownerName && isShowEditIcon ? rowData.ownerName : "-"}</div>
        </div>
      )}
      <div className="column buttons">
        <div className="content">
          {isStricked &&
            onHover &&
            (!checkForDash(rowData.performedBy) ||
              !checkForDash(rowData.dateAndTime) ||
              !checkForDash(rowData.reason)) && (
              <div className="note" data-tip data-for={`note-${rowData.id}`}>
                <Icon icon="note-for-tasks" className="note-for-tasks" size={19} />
                <CustomToolTip
                  id={`note-${rowData.id}`}
                  text={
                    <NoteForStrickedTask
                      doneBy={rowData.performedBy}
                      dateAndTime={rowData.dateAndTime}
                      reason={rowData.reason}
                    />
                  }
                  place="top"
                />
              </div>
            )}
          {onHover && isShowEditIcon && (
            <div
              className="edit-icon-container"
              data-tip
              data-for={`edit-icon-for-${rowData.id}`}
              onClick={(e) => {
                e.stopPropagation();
                onClickEditIcon(rowData);
              }}
            >
              <Icon icon="edit" size={19} />
              <CustomToolTip
                id={`edit-icon-for-${rowData.id}`}
                text={<div className="edit-icon-tooltip">Edit</div>}
                place="top"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default EpisodeTasksRow;
