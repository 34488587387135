import Button from "components/Button/button.component";
import SearchBox from "components/SearchBox/search-box.component";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearEpisodesSearch,
  getAdminState,
  setEpisodeGetRequest,
  setEpisodesIsSearching,
  setEpisodesSearch,
} from "state/feature/admin/admin.slice";
import { debounce } from "shared/methods/utilityFunctions";
import "./episodes-sub-header.styles.scss";

const EpisodesSubHeader = () => {
  const dispatch = useDispatch();
  const {
    episode: { isSearching },
  } = useSelector(getAdminState);
  const [searchTextVal, setSearchTextVal] = useState("");

  const handleSearch = useCallback(
    (search: string) => {
      dispatch(setEpisodesSearch({ searchText: search, searchBy: "episodeName" }));
      dispatch(setEpisodeGetRequest({ searchToken: search, searchBy: "episodeName", pageCount: 1 }));
    },
    [dispatch]
  );

  const debouncedSearch = useMemo(() => {
    return debounce(handleSearch, 500);
  }, [handleSearch]);

  useEffect(() => {
    if (!isSearching) {
      setSearchTextVal("");
    }
  }, [isSearching]);

  return (
    <div className="sub-header-container">
      <div className="right-section">
        {isSearching ? (
          <SearchBox
            icon="cross"
            className="search-box"
            iconClassName="search-icon"
            SearchBoxSize={22}
            placeholder="Search"
            text={searchTextVal}
            autoFocus={false}
            showIcon={!!searchTextVal && searchTextVal.length > 0}
            onClick={() => {
              dispatch(setEpisodesIsSearching(false));
              dispatch(clearEpisodesSearch());
              dispatch(setEpisodeGetRequest({ searchToken: "", searchBy: "" }));
            }}
            onTextChange={(text) => {
              setSearchTextVal(text);
              debouncedSearch(text);
            }}
          />
        ) : (
          <Button
            icon="search"
            buttonSize={14}
            className="search-icon-container"
            iconClassName="search-icon"
            onClick={() => dispatch(setEpisodesIsSearching(true))}
          />
        )}
      </div>
    </div>
  );
};

export default EpisodesSubHeader;
