export enum DelayStatusOptionKey {
  NO_ANSWER = "1",
  ANSWER = "2",
  UNABLE_TO_COMPLETE = "3",
}

//NoAnswers Results keys
export enum DelayResultNoAnswersKey {
  MESSAGE_LEFT = "1",
  UNABLE_TO_LEAVE_MESSAGE = "2",
}

// Answers Result keys
export enum DelayResultAnswersKey {
  CALL_BACK_LATER = "1",
  PATIENT_NOT_WANT_TO_DO_TASK = "2",
  PATIENT_DECLINE_NAVIGATION_SERVICE = "3",
  PARTIALLY_COMPLETED_WITH_NO_FURTHER_ACTIVITY = "4",
  OTHER_DELAY_TASK = "5",
  OTHER_CLOSE_TASK = "6",
}

// Unable To complete Result keys
export enum DelayResultUnableToCompleteKey {
  PATIENT_DECEASED = "1",
  DUPLICATE_TASK = "2",
  UNABLE_TO_REACH = "3",
  PRACTICE_REQUEST = "4",
  OTHER = "5",
  PATIENT_DECLINE_NAVIGATION_SERVICE = "6",
}

//No Answers All Actions
export enum DelayActionNoAnswerMessageLeftKey {
  TRY_LATER_TODAY = "1",
  TRY_TOMMOROW = "2",
  MARK_CLOSED = "3",
}

export enum DelayActionNoAnswerUnableToLeaveMessageKey {
  TRY_LATER_TODAY = "1",
  TRY_TOMMOROW = "2",
  MARK_CLOSED = "3",
}

//Answers All actions
export enum DelayActionAnswerCallBackLaterKey {
  TRY_LATER_TODAY = "1",
  TRY_TOMMOROW = "2",
}

export enum DelayActionAnswerPatientNotWantToDoTaskKey {
  MARK_TASK_CLOSED = "1",
}

export enum DelayActionAnswerPatientDeclinedNavigationServicesKey {
  MARK_TASK_CLOSED = "1",
}

export enum DelayActionAnswerPartiallyCompletedWithNoFurtherActivityKey {
  MARK_TASK_CLOSED = "1",
}

export enum DelayActionAnswerOtherDelayTaskKey {
  TRY_LATER_TODAY = "1",
  TRY_TOMMOROW = "2",
  MARK_TASK_CLOSED = "3",
}

export enum DelayActionAnswerOtherCloseTaskKey {
  MARK_TASK_CLOSED = "1",
}

///Unable to complete all actions
export enum DelayActionUnableToCompletedPatientDeceasedKey {
  MARK_TASK_CLOSED_WITH_EPISODE_DECEASED = "1",
}

export enum DelayActionUnableToCompletedDuplicateTaskKey {
  MARK_TASK_CLOSED = "1",
}
export enum DelayActionUnableToCompletedOtherKey {
  MARK_TASK_CLOSED = "1",
}
export enum DelayActionUnableToCompletedPracticeRequestKey {
  MARK_TASK_CLOSED = "1",
}
export enum DelayActionUnableToCompletedUnableToReachKey {
  MARK_TASK_CLOSED = "1",
}

export enum DelayActionUnableToCompletedPatientDeclinedNavigationKey {
  MARK_TASK_CLOSED = "1",
}

export enum PostDelaySubmitResponseData {
  SUBMITTED_FOR_CLOSE_APPROVAL = "Submitted for close approval",
}

export enum LastDelayAttemptStatus {
  REJECTED = "Rejected",
}
