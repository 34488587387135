import React from "react";
import { Rings } from "react-loader-spinner";
import "./conversation-loading.styles.scss";

type PropsType = {
  labelColor?: string;
};

const ConversationLoading = ({ labelColor = "#000000" }: PropsType) => {
  return (
    <div className="loading-conversation">
      <Rings color="#1aa99c" />
      <span style={{ color: labelColor }}>Loading...</span>
    </div>
  );
};

export default ConversationLoading;
