import { useState } from "react";
import { PlanOfCareTabRowProps } from "../plan-of-care-tab.props";
import PlanOfCareTabRowPresentation from "./plan-of-care-tab.row.component";

const PlanOfCareTabRow = (props: PlanOfCareTabRowProps) => {
  const { pocLog, index } = props;
  const [isHovered, setIsHovered] = useState(false);

  const onChangeMouseHovered = (value: boolean) => {
    setIsHovered(value);
  };
  return (
    <PlanOfCareTabRowPresentation
      pocLog={pocLog}
      index={index}
      isHovered={isHovered}
      onChangeMouseHovered={onChangeMouseHovered}
    />
  );
};

export default PlanOfCareTabRow;
