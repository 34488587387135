import { useDispatch, useSelector } from "react-redux";
import GenericPopupModal from "../GenericPopupModal/generic-popup-modal.component";
import { getCommonState, setIsOpenCareOfPlanDeleteConfirmationModal } from "state/feature/common/common.slice";
import { POCDeleteConfimationModalProps } from "./poc-delete-confirmation-modal.props";
import { getPlanOfCareState } from "state/feature/plan-of-care/plan-of-care.slice";
import "./poc-delete-confirmation-modal.style.scss";
import { pocDeleteHeading } from "../poc-service-modal/poc-service-modal.constant";

const POCDeleteConfirmationModal = (props: POCDeleteConfimationModalProps) => {
  const dispatch = useDispatch();
  const { modal } = useSelector(getCommonState);
  const { isConfirmationButtonLoading } = useSelector(getPlanOfCareState);
  const { handleSubmitForDeleteConfirmation } = props;
  return (
    <GenericPopupModal
      submitButtonTitle="Ok"
      heading="Delete Service"
      isOpen={modal.isOpenPOCDeleteModal}
      handleCancelClick={() => dispatch(setIsOpenCareOfPlanDeleteConfirmationModal(false))}
      cancelButtonVisible
      handleSubmitClick={handleSubmitForDeleteConfirmation}
      content={pocDeleteHeading}
      isButtonLoading={isConfirmationButtonLoading}
      className="poc-delete-modal"
    />
  );
};

export default POCDeleteConfirmationModal;
