import React from "react";
import "./section.styles.scss";

type SectionProps = {
  sectionHeading?: string | null;
  children: React.ReactNode;
};
const Section = (props: SectionProps) => {
  const { sectionHeading, children } = props;

  return (
    <div className="section-container">
      <div className="section-heading">{sectionHeading}</div>
      {children}
    </div>
  );
};

export default Section;
