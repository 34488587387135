import { createSlice } from "@reduxjs/toolkit";
import { TOAST_MESSAGES } from "pages/login/common/constants";
import { toast } from "react-toastify";
import { getNotesTaskAsync } from "./notes-task.action";
import { INotesTaskState } from "state/types/notes-task-slice.type";

const initialState: INotesTaskState = {
  isLoading: false,
  existingNotes: {
    id: "",
    title: "",
    taskOwner: "",
    taskOwnerUserId: "",
    navigatorNotes: "",
    startDate: null,
    dueDate: null,
    taskStatusId: null,
  },
};

const notesTaskSlice = createSlice({
  name: "notes-task",
  initialState,
  reducers: {
    setNotesTaskStateFromMemoize(state, action) {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    return (
      builder.addCase(getNotesTaskAsync.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(getNotesTaskAsync.fulfilled, (state, action) => {
        const { id, title, taskOwner, taskOwnerUserId, notes, startDate, dueDate, taskStatusId } = action.payload;
        state.existingNotes = {
          id,
          title,
          startDate,
          dueDate,
          taskOwner,
          taskOwnerUserId,
          taskStatusId,
          navigatorNotes: notes,
        };
        state.isLoading = false;
      }),
      builder.addCase(getNotesTaskAsync.rejected, (state) => {
        state.isLoading = false;
        toast.error(TOAST_MESSAGES.ERROR, { containerId: "main" });
      })
    );
  },
});

export const { setNotesTaskStateFromMemoize } = notesTaskSlice.actions;
export const getNotesTaskState = (state: any): INotesTaskState => state.notesTask;
export default notesTaskSlice;
