import { createAsyncThunk } from "@reduxjs/toolkit";
import { getConfig } from "config/config";
import { axiosInstance } from "shared/axios-instance";

export const getNotesTaskAsync = createAsyncThunk(
  "notes-task/getNotesTaskAsync",
  async (requestPayload: { intakeId: string; taskId: string }) => {
    const response = await axiosInstance.post(
      `${getConfig().taskManagementBase}/api/v1/Tasks/fetch-manual-task`,
      requestPayload
    );
    if (response.status === 200 && response.data && response.data.data) {
      return response.data.data;
    }
    return null;
  }
);
