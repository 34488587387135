import { useSelector } from "react-redux";
import { getCheckOnPatientSlice } from "state/feature/check-on-patient/check-on-patient.slice";
import CustomDropDown from "components/CustomDropdown/custom-dropdown.component";
import TextField from "components/TextFieldV2/text-field-v2.component";
import Checkbox from "components/Checkbox/checkbox.component";
import SingleDatePicker from "components/single-date-picker/single-date-picker.component";
import FacilityDropdown from "components/facility-dropdown/facility-dropdown.component";
import { CheckOnPatientFormPresentationProps } from "../../common/check-on-patient.props";
import Error from "components/error-message/error-message.component";
import { EMPTY_FIELD_ERROR_MESSAGE } from "../../common/constants";
import DayVisitErrorMessage from "../day-visit-error-message/day-visit-error-message.component";
import { ACH_ID } from "../../check-on-patient.constant";

const CheckOnPatientFormPresentation = (props: CheckOnPatientFormPresentationProps) => {
  const {
    checkOnPatientQuestions,
    handleActualDate,
    handleCompletedDayVisit,
    handleIsOffTrackCheckbox,
    handleActualDayVisit,
    handleDischargeFromServiceCheckbox,
    handleNotes,
    handleOffTrackDetails,
    handleOffTrackReason,
    handleOffTrackStartDate,
    handleProviderDropdown,
    handleServiceType,
    handleProcedureDate,
    handleAcuteCareFacilityChange,
    showDelayNotes,
  } = props;
  const { selectedCheckOnPatient, providerOptions, offtrackReasonOptions, isDirty, checkOnPatientDetails, facilities } =
    useSelector(getCheckOnPatientSlice);

  return (
    <div className={`check-on-patient-component ${showDelayNotes ? "no-answer-delay-note" : ""}`}>
      <div className="check-on-patient-form-field check-on-patient-form-row">
        <div className="check-on-patient-form-column service-type">
          <label className="field-label">{checkOnPatientQuestions.serviceType.title}</label>
          <CustomDropDown
            placeholder={"-"}
            dropDownMenuItems={[]}
            handleValueChanges={handleServiceType}
            value={selectedCheckOnPatient.serviceType}
            idFieldName="key"
            dropDownBoxClass="navigator-dropdown"
            isDisabled={checkOnPatientQuestions.serviceType.disabled}
            selectionClass="navigator-selection"
            dropDownContainerClass="toc-tasks-dropdown-container"
            message="Please select the Service type"
          />
          <div className="error-and-text-length-container"></div>
        </div>
      </div>

      <div className="check-on-patient-form-field check-on-patient-form-row">
        <div className="check-on-patient-form-column provider">
          <label htmlFor="" className="field-label">
            {checkOnPatientQuestions.provider.title}
            <span className="red-color">*</span>
          </label>
          {checkOnPatientDetails?.tocTaskDetails?.serviceTypeId === ACH_ID ? (
            <CustomDropDown
              placeholder="Select"
              dropDownMenuItems={facilities.map((item) => {
                return { name: item.ProviderName, key: item.ID };
              })}
              handleValueChanges={handleAcuteCareFacilityChange}
              defaultValue={{
                name: selectedCheckOnPatient.provider.name,
                key: selectedCheckOnPatient.provider.id?.toString(),
              }}
              idFieldName="key"
              selectionClass="navigator-selection"
              dropDownContainerClass="toc-tasks-dropdown-container"
              message="Please select one from dropdown."
              isError={false}
              isDisabled={checkOnPatientQuestions.provider.disabled}
            />
          ) : (
            <FacilityDropdown
              isDisabled={checkOnPatientQuestions.provider.disabled}
              dropdownOptions={providerOptions.map((provider) => {
                return { id: provider.ID, name: provider.ProviderName };
              })}
              isError={false}
              onChange={handleProviderDropdown}
              placeholder={checkOnPatientQuestions.provider.disabled ? `-` : "Select Provider"}
              selectedOptionId={selectedCheckOnPatient.provider?.id}
            />
          )}
          <div className="error-and-text-length-container">
            <div>
              {!checkOnPatientQuestions.provider.disabled &&
                isDirty &&
                (selectedCheckOnPatient.provider.id === undefined || selectedCheckOnPatient.provider.id === -1) && (
                  <Error message={EMPTY_FIELD_ERROR_MESSAGE} />
                )}
            </div>
          </div>
        </div>
      </div>

      <div className="check-on-patient-form-field check-on-patient-form-row">
        <div className="check-on-patient-form-column check-on-patient-dates">
          <label htmlFor="" className="field-label">
            {checkOnPatientQuestions.procedureDate.title}
            <span className="red-color">*</span>
          </label>
          <SingleDatePicker
            defaultDate={selectedCheckOnPatient.procedureDate ?? null}
            isDisabled={checkOnPatientQuestions.procedureDate.disabled}
            handleDateChange={handleProcedureDate}
            popperPlacement="bottom"
            minDate={null}
            maxDate={null}
          />
          <div className="error-and-text-length-container">
            <div>
              {!checkOnPatientQuestions.procedureDate.disabled &&
                isDirty &&
                checkOnPatientQuestions.procedureDate.required &&
                selectedCheckOnPatient.procedureDate === null && <Error message={EMPTY_FIELD_ERROR_MESSAGE} />}
            </div>
          </div>
        </div>
      </div>

      <div className="check-on-patient-form-field">
        <label className="field-label label-multiple-fields">{checkOnPatientQuestions.daysVisit.title}</label>
        <div className="check-on-patient-form-row days-visit">
          <div className="check-on-patient-form-column planned">
            <label className="field-sub-label field-label">{checkOnPatientQuestions.daysVisitPlanned.title}</label>
            <TextField
              label=""
              className="input-field check-on-patient-text-field disabled"
              inputClassName=""
              inputContainerClassName=""
              type="text"
              disabled={checkOnPatientQuestions.daysVisitPlanned.disabled}
              value={selectedCheckOnPatient.dayVisitPlanned}
              setValue={(label, value) => {}}
              placeholder={checkOnPatientQuestions.daysVisitPlanned.disabled ? "-" : ""}
            />
          </div>
          <div className="check-on-patient-form-column actual">
            <label className="field-sub-label field-label">
              {checkOnPatientQuestions.daysVisitActual.title}
              <span className="red-color">*</span>
            </label>
            <TextField
              label=""
              className={`input-field check-on-patient-text-field ${
                checkOnPatientQuestions.daysVisitActual.disabled ? "disabled" : ""
              }`}
              inputClassName=""
              inputContainerClassName=""
              type="text"
              disabled={checkOnPatientQuestions.daysVisitActual.disabled}
              value={selectedCheckOnPatient.dayVisitActual}
              setValue={handleActualDayVisit}
              placeholder={checkOnPatientQuestions.daysVisitActual.disabled ? "-" : ""}
            />
          </div>
          <div className="check-on-patient-form-column completed">
            <label className="field-sub-label field-label">
              {checkOnPatientQuestions.daysVisitCompleted.title}
              <span className="red-color">*</span>
            </label>
            <TextField
              label=""
              className={`input-field check-on-patient-text-field ${
                checkOnPatientQuestions.daysVisitCompleted.disabled ? "disabled" : ""
              }`}
              inputClassName=""
              inputContainerClassName=""
              type="text"
              disabled={checkOnPatientQuestions.daysVisitCompleted.disabled}
              value={selectedCheckOnPatient.dayVisitCompleted}
              setValue={handleCompletedDayVisit}
              placeholder={checkOnPatientQuestions.daysVisitCompleted.disabled ? "-" : ""}
            />
          </div>
        </div>
        <div className="error-and-text-length-container">
          <div>
            {!checkOnPatientQuestions.daysVisitCompleted.disabled &&
              !checkOnPatientQuestions.daysVisitActual.disabled &&
              isDirty && (
                <DayVisitErrorMessage
                  dayVisitActual={selectedCheckOnPatient.dayVisitActual}
                  dayVisitCompleted={selectedCheckOnPatient.dayVisitCompleted}
                />
              )}
          </div>
        </div>
      </div>

      <div
        className={`check-on-patient-form-field ${
          selectedCheckOnPatient.isOfftrack === false ? "offtrack-selected" : ""
        }`}
      >
        <label className="field-label label-multiple-fields">{checkOnPatientQuestions.offtrack.title}</label>
        <div
          className={`check-on-patient-form-row check-on-patient-form-column patient-off-track-checkbox  check-on-patient-checkbox `}
        >
          <Checkbox
            name="Patient is off-track"
            value="Patient is off-track"
            id="patient-is-offtrack"
            isDisabled={checkOnPatientQuestions.offtrack.disabled}
            onChange={handleIsOffTrackCheckbox}
            isChecked={selectedCheckOnPatient.isOfftrack}
          />
        </div>
        {selectedCheckOnPatient.isOfftrack ? (
          <div className="check-on-patient-form-row form-row-direction">
            <div className="check-on-patient-form-column reason">
              <label htmlFor="" className="field-label">
                {checkOnPatientQuestions.offtrackReason.title}
                <span className="red-color">*</span>
              </label>
              <CustomDropDown
                placeholder={checkOnPatientQuestions.offtrackReason.disabled ? "-" : "Select Reason"}
                dropDownMenuItems={offtrackReasonOptions}
                handleValueChanges={handleOffTrackReason}
                value={selectedCheckOnPatient.offTrackReason}
                idFieldName="key"
                dropDownBoxClass="navigator-dropdown"
                selectionClass="navigator-selection"
                isDisabled={checkOnPatientQuestions.offtrackReason.disabled}
                dropDownContainerClass="toc-tasks-dropdown-container"
                message="Please select the reason"
              />
              <div className="error-and-text-length-container">
                <div>
                  {!checkOnPatientQuestions.offtrackReason.disabled &&
                    isDirty &&
                    checkOnPatientQuestions.offtrackReason.required &&
                    selectedCheckOnPatient.offTrackReason.key === "" && <Error message={EMPTY_FIELD_ERROR_MESSAGE} />}
                </div>
              </div>
            </div>
            <div className="check-on-patient-form-column check-on-patient-dates">
              <label htmlFor="" className=" field-label">
                {checkOnPatientQuestions.offtrackStartDate.title}
                <span className="red-color">*</span>
              </label>
              <SingleDatePicker
                defaultDate={selectedCheckOnPatient.offtrackStartDate ?? null}
                isDisabled={checkOnPatientQuestions.offtrackStartDate.disabled}
                handleDateChange={handleOffTrackStartDate}
                popperPlacement="bottom"
                minDate={null}
                maxDate={null}
              />
              <div className="error-and-text-length-container">
                <div>
                  {!checkOnPatientQuestions.offtrackStartDate.disabled &&
                    isDirty &&
                    checkOnPatientQuestions.offtrackStartDate.required &&
                    selectedCheckOnPatient.offtrackStartDate === null && <Error message={EMPTY_FIELD_ERROR_MESSAGE} />}
                </div>
              </div>
            </div>
            <div className="check-on-patient-form-column details">
              <label className="field-label">
                {checkOnPatientQuestions.offtrackDetails.title}
                <span className="red-color">*</span>
              </label>
              <textarea
                className="text-area"
                id={`textarea`}
                name={`textarea`}
                maxLength={1000}
                cols={70}
                rows={10}
                placeholder={checkOnPatientQuestions.offtrackDetails.disabled ? "-" : "Write your notes here"}
                disabled={checkOnPatientQuestions.offtrackDetails.disabled}
                value={selectedCheckOnPatient.offtrackDetails}
                onChange={handleOffTrackDetails}
              />
              <div className="error-and-text-length-container">
                <div>
                  {!checkOnPatientQuestions.offtrackDetails.disabled &&
                    isDirty &&
                    checkOnPatientQuestions.offtrackDetails.required &&
                    selectedCheckOnPatient.offtrackDetails === "" && <Error message={EMPTY_FIELD_ERROR_MESSAGE} />}
                </div>
                {selectedCheckOnPatient.offtrackDetails.length > 0 &&
                  !checkOnPatientQuestions.offtrackDetails.disabled && (
                    <div className="text-length">{`${selectedCheckOnPatient.offtrackDetails.length}/1000`}</div>
                  )}
              </div>
            </div>
          </div>
        ) : (
          <div className="error-and-text-length-container"></div>
        )}
      </div>

      <div className="check-on-patient-form-field">
        <label className="field-label label-multiple-fields">
          {checkOnPatientQuestions.dischargeDate.title}
          <span className="red-color">*</span>
        </label>
        <div className="check-on-patient-form-row discharge-service-checkbox check-on-patient-checkbox">
          <Checkbox
            name={checkOnPatientQuestions.dischargeFromService.title}
            value={checkOnPatientQuestions.dischargeFromService.title}
            id="discharge-from-service"
            isDisabled={checkOnPatientQuestions.dischargeFromService.disabled}
            onChange={handleDischargeFromServiceCheckbox}
            isChecked={selectedCheckOnPatient.dischargeFromService}
          />
        </div>
        <div className="check-on-patient-form-row">
          <div className="check-on-patient-form-column check-on-patient-dates">
            <label htmlFor="" className="field-sub-label field-label">
              {checkOnPatientQuestions.estimatedDate.title}
            </label>
            <SingleDatePicker
              defaultDate={selectedCheckOnPatient.estimatedDischargeDate ?? null}
              isDisabled={true}
              handleDateChange={(val: string | null) => {}}
              popperPlacement="bottom"
              minDate={null}
              maxDate={null}
            />
            <div className="error-and-text-length-container"></div>
          </div>
          <div className="check-on-patient-form-column check-on-patient-dates">
            <label htmlFor="" className="field-sub-label field-label">
              {selectedCheckOnPatient.dischargeFromService ? checkOnPatientQuestions.actualDate.title : "Anticipated"}
              <span className="red-color">*</span>
            </label>
            <SingleDatePicker
              defaultDate={selectedCheckOnPatient.actualDischargeDate ?? null}
              isDisabled={checkOnPatientQuestions.actualDate.disabled}
              handleDateChange={handleActualDate}
              popperPlacement="bottom"
              minDate={null}
              maxDate={null}
            />
            <div className="error-and-text-length-container">
              <div>
                {!checkOnPatientQuestions.actualDate.disabled &&
                  isDirty &&
                  checkOnPatientQuestions.actualDate.required &&
                  selectedCheckOnPatient.actualDischargeDate === null && <Error message={EMPTY_FIELD_ERROR_MESSAGE} />}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="check-on-patient-form-field check-on-patient-form-row">
        <div className="check-on-patient-form-column notes">
          <label className="field-label">{checkOnPatientQuestions.notes.title}</label>
          <textarea
            className="text-area"
            id={`textarea`}
            name={`textarea`}
            maxLength={5000}
            cols={70}
            rows={10}
            placeholder={"Write your notes here"}
            disabled={checkOnPatientQuestions.notes.disabled}
            value={selectedCheckOnPatient.notes}
            onChange={handleNotes}
          />
          <div className="error-and-text-length-container">
            <div></div>
            {selectedCheckOnPatient.notes.length > 0 && !checkOnPatientQuestions.notes.disabled && (
              <div className="text-length">{`${selectedCheckOnPatient.notes.length}/5000`}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckOnPatientFormPresentation;
