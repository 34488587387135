import React, { useState, useEffect } from "react";
// import "./custom-dropdown.styles.scss";
import ClickAwayListener from "react-click-away-listener";
import Icon from "components/Icon/icon.component";
import SearchBox from "components/SearchBox/search-box.component";

type CustomDropDownProps = {
  placeholder?: string;
  idFieldName: string;
  handleToastDismiss?: () => void;
  dropDownMenuItems: Record<string, unknown>[];
  handleValueChanges: (value: { name: string }) => void;
  value?: { key: string; name: string };
  itemClassName?: string;
  optionClassName?: string;
  dropDownContainerClass?: string;
  dropDownBoxClass: string;
  selectionClass: string;
  isDisabled?: boolean;
  isError?: boolean;
  message?: string;
  onBlur?: () => void;
  searchText: string;
  handleSearchText: (value: string) => void;
};

const CustomDropDown = ({
  placeholder,
  idFieldName,
  handleToastDismiss,
  dropDownMenuItems,
  handleValueChanges,
  value,
  itemClassName,
  optionClassName,
  dropDownContainerClass,
  dropDownBoxClass,
  selectionClass,
  isDisabled,
  isError,
  message,
  onBlur,
  searchText,
  handleSearchText,
}: CustomDropDownProps) => {
  const [activeOption, setActiveOption] = useState<any>({
    name: "",
  });
  const [showOptionDropdown, setShowOptionDropdown] = useState(false);
  const [dropDownItems] = useState(dropDownMenuItems);

  useEffect(() => {
    setActiveOption(value);
  }, [value]);

  const handleDropdownChange = (state: any) => {
    if (handleToastDismiss) handleToastDismiss();
    setShowOptionDropdown((prev) => {
      const arrowEl = document.getElementById("select-box-bucket-down-arrow");
      if (prev) {
        arrowEl?.classList.remove("select-box-down-arrow-rotate");
        arrowEl?.classList.add("select-box-down-arrow-unrotate");
        return !prev;
      } else {
        if (state) {
          arrowEl?.classList.remove("select-box-down-arrow-unrotate");
          arrowEl?.classList.add("select-box-down-arrow-rotate");
          return !prev;
        } else return false;
      }
    });
  };

  const handleSelect = (selectedOption: any) => {
    setActiveOption(selectedOption);
    handleValueChanges(selectedOption);
  };

  const handleOptionChange = (option: any) => {
    if (handleSelect) handleSelect(option);
    handleDropdownChange(false);
  };
  return (
    <ClickAwayListener
      onClickAway={() => {
        handleSearchText("");
        handleDropdownChange(false);
      }}
    >
      <div className={`dropdown-container ${dropDownContainerClass ? dropDownContainerClass : ""}`}>
        <span
          onBlur={onBlur}
          tabIndex={isDisabled ? -1 : 0}
          className={`select-span ${isError ? "error" : "no-error"} ${selectionClass ? selectionClass : ""} ${
            isDisabled ? "no-pointer-events" : ""
          }`}
          onClick={handleDropdownChange}
        >
          <div className="name-container">
            {activeOption?.name ? (
              <p className="selected-value">{activeOption?.name}</p>
            ) : (
              <p className="selected-value placeholder">{placeholder}</p>
            )}
          </div>
          <div className="icon-container">
            <Icon icon="down-arrow" className="select-box-down-arrow" size={11} />
          </div>
        </span>
        {isError && !showOptionDropdown && <div className="error-color">{message}</div>}
        {showOptionDropdown && (
          <div className={`option-box-wrapper ${dropDownBoxClass}`}>
            <SearchBox
              text={searchText}
              icon="cross"
              className="search-box"
              iconClassName="search-icon"
              onTextChange={(val) => handleSearchText(val)}
              onClick={() => handleSearchText("")}
            />
            <ul>
              {dropDownMenuItems?.filter((options: any) =>
                options.name?.toLowerCase().includes(searchText.toLowerCase())
              ).length === 0 ? (
                <div className="no-result-found">{searchText.length > 0 ? "No Result Found" : ""}</div>
              ) : (
                dropDownMenuItems
                  ?.filter((options: any) => options.name?.toLowerCase().includes(searchText.toLowerCase()))
                  ?.map((option: any, index) => (
                    <li
                      key={`${option?.id}--${index}`}
                      className={`${itemClassName ? itemClassName : ""}
										${activeOption && activeOption[idFieldName] === option[idFieldName] ? "active-option" : ""}`}
                      onClick={() => handleOptionChange(option)}
                    >
                      {activeOption && activeOption[idFieldName] === option[idFieldName] ? (
                        <p className={`option selected ${optionClassName ? optionClassName : ""}`}>{option.name}</p>
                      ) : (
                        <p className={`option ${optionClassName ? optionClassName : ""}`}>{option.name}</p>
                      )}
                    </li>
                  ))
              )}
            </ul>
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default CustomDropDown;
