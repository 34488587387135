import { Holiday } from "state/types/navigator-slice.type";
import { IsdCodes } from "shared/constant/isd-codes";
import moment from "moment";
import momenttz from "moment-timezone";
import { NavigatorDataType } from "pages/manager-dashboard/components/navigator-table/props";
import { OnlineOfflineStatus } from "shared/enums/online-offline.enum";
import { INavigator } from "state/types/navigator.type";
import { TIMEZONE } from "shared/enums/timezone.enum";
import { INotification } from "state/types/notification-slice-type";
import { METERS_PER_MILE } from "shared/constant/commonConstants";
import { TaskPageName } from "shared/enums/page-name.enum";
import { ContactDetailsType, IEpisodeDetails, IPatientEpisodeDetails } from "state/types/episodes-slice.type";
import { TaskStatus } from "shared/enums/tasks-status.enum";
import { FormType } from "shared/enums/form-types.enum";

export function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function formatBytes(bytes: number | undefined, decimals = 2) {
  if (bytes === 0 || typeof bytes === "undefined") return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export const removeSpecialCharsForPhotoDescription = (text: string) => {
  const text1 = text.replace(/\n/g, " ");
  const text2 = text1.replace(/[;<=,>]+/g, "");
  return text2.replace(/[^0-9a-zA-Z +-=. ]+/g, "");
};
export const validateNumber = (value: string) => {
  const regex = /^[0-9]+$/;
  return regex.test(value);
};

export const removeAllSpecialCharacters = (str: string) => {
  return str.replace(/[^a-zA-Z_-]/g, "");
};

export const isEmptyString = (str: string) => {
  return /^\s*$/.test(str);
};

export const validateNumberForTextInput = (value: string) => {
  const re = /^[1-9][0-9]*$/;
  return isEmptyString(value) || value == "0" || re.test(value);
};

export const isEmptyArray = (arr: Array<any>) => {
  return arr.length === 0;
};

export const getAgeFromDate = (birthday: any) => {
  const ageDifMs = Date.now() - birthday;
  const ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const getDateInMMDDYYYYFormat = (date: any | null) => {
  if (date === null || isNaN(date)) {
    return "";
  } else {
    return moment(date).format("MM/DD/YYYY");
  }
};

export const getDurationOfCall = (duration: number) => {
  return `${Math.floor(duration / 60) > 9 ? Math.floor(duration / 60) : `0${Math.floor(duration / 60)}`} m ${
    duration % 60 > 9 ? duration % 60 : `0${duration % 60}`
  } sec`;
};

export const getColonSeparatedDuration = (duration: number) => {
  if (isNaN(duration)) {
    return "0";
  } else {
    return `${Math.floor(duration / 60) > 9 ? Math.floor(duration / 60) : `0${Math.floor(duration / 60)}`} : ${
      duration % 60 > 9 ? duration % 60 : `0${duration % 60}`
    }`;
  }
};

export const getStartDateWithoutUTC = (date: Date) => {
  return moment(date).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
};

export const getEndDateWithoutUTC = (date: Date) => {
  return moment(date).set({ hours: 23, minutes: 59, seconds: 59, milliseconds: 59 });
};

export const getEndDate = (date: Date) => {
  const dateWithLastHours = moment(date).set({ hours: 23, minutes: 59, seconds: 59, milliseconds: 59 });
  const formattedDate = moment(dateWithLastHours).toISOString();
  return formattedDate;
};

export const getStartDate = (date: Date) => {
  const dateWithStartHours = moment(date).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const formattedDate = moment(dateWithStartHours).toISOString();
  return formattedDate;
};

export const getUTCDateAndAdd12hrs = (date: Date) => {
  const dateU =
    String(date.getFullYear()) +
    "-" +
    (date.getMonth() > 8 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) +
    "-" +
    (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
    "T12:00:00.000Z";
  return dateU;
};

export const getDateInUTC = (date: Date) => {
  return (
    String(date?.getFullYear()) +
    "-" +
    (date?.getMonth() > 8 ? date?.getMonth() + 1 : "0" + (date?.getMonth() + 1)) +
    "-" +
    (date?.getDate() > 9 ? date?.getDate() : "0" + date?.getDate()) +
    "T00:00:00.000Z"
  );
};

export const getDatePart = (date: string) => {
  if (typeof date === "string") {
    return date?.slice(0, 10);
  }
};

export const setCookie = (name: any, value: any, days = 365) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

export const getCookie = (name: any) => {
  const nameEQ = name + "=";
  const ca = document.cookie.split(";");
  for (const element of ca) {
    let c = element;
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const eraseCookie = (name: any) => {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

export const deleteAllCookies = () => {
  document.cookie.split(";").forEach((c) => {
    document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
};

export const isReplyButtonDisabled = () => {
  const currentNavigatorId = sessionStorage.getItem("externalId");
  const checkLoggedInNavigator = getCookie("loggedInUser") != "{}";
  const loggedInNavigator = JSON.parse(getCookie("loggedInUser") as string);
  return checkLoggedInNavigator && currentNavigatorId === loggedInNavigator?.id;
};

export const getFullName = (el: any) => `${el.firstName} ${el.lastName}`;

export const formatPhoneNumber = (phoneNumber: string) => {
  const countryCode = phoneNumber.slice(0, -10);
  if (countryCode === IsdCodes.US) {
    return phoneNumber.replace(/(\+\d{1})(\d{3})(\d{3})(\d{4})/, "($1) $2-$3-$4");
  }
  if (countryCode === IsdCodes.IN) {
    return phoneNumber.replace(/(\+\d{2})(\d{3})(\d{3})(\d{4})/, "($1) $2-$3-$4");
  }

  return phoneNumber;
};

export const extractCountryCodeAndFormatFromPhone = (Phone: string) => {
  return Phone ? Phone.replace("+1", "").replace(/\D+/g, "") : "";
};

const getConvertedTimezone = (timeZone: string) => {
  if (timeZone?.includes("Eastern")) {
    return TIMEZONE.Eastern;
  } else if (timeZone?.includes("Mountain")) {
    return TIMEZONE.Mountain;
  } else if (timeZone?.includes("Pacific")) {
    return TIMEZONE.Pacific;
  }
  return TIMEZONE.Central;
};

export const getNavigatorWorkTimeInUtcByTimezone = (
  timeZone: string,
  workStartHour: number,
  workStartMin: number,
  workEndHour: number,
  workEndMin: number
): { startTimeConverted: string; endTimeConverted: string; localTime: string } => {
  const timezoneType = getConvertedTimezone(timeZone);
  const date = new Date();
  const workStartDate = momenttz
    .tz(date, timezoneType)
    .set({ hour: workStartHour, minute: workStartMin, second: 0, millisecond: 0 });
  const workEndDate = momenttz
    .tz(date, timezoneType)
    .set({ hour: workEndHour, minute: workEndMin, second: 0, millisecond: 0 });
  const startTimeConverted = moment.utc(workStartDate).format();
  const endTimeConverted = moment.utc(workEndDate).format();
  const localTime = moment.utc().format();
  return { startTimeConverted, endTimeConverted, localTime };
};

const getCentralTime = () => {
  const localTime = moment.utc().format();
  return momenttz.tz(localTime, TIMEZONE.Central);
};

const isHoliday = (holidayList?: Array<Holiday>) => {
  if (holidayList && holidayList.length > 0) {
    const centralTimeString = getCentralTime().format("YYYY-MM-DDT00:00:00");
    const index = holidayList.findIndex((holiday) => holiday.date === centralTimeString);
    if (index >= 0) {
      return true;
    }
  }
  return false;
};

const isWeekend = () => {
  const day = getCentralTime().day();
  return day === 0 || day === 6;
};

export const isNavigatorOnCallStatusEnabled = (timeZone: string, onCallDate: string, holidayList?: Array<Holiday>) => {
  const centralTimeNow = getCentralTime();
  const centralTimeNowFormatted = centralTimeNow.format("YYYY-MM-DDTHH:mm:ss");
  const navigatorTimezone = getConvertedTimezone(timeZone);
  const nineAMSameDay = momenttz
    .tz(onCallDate, navigatorTimezone)
    .set({ hour: 9, minute: 0, second: 0, millisecond: 0 })
    .format("YYYY-MM-DDTHH:mm:ss");
  const nineAMNextDay = momenttz
    .tz(onCallDate, navigatorTimezone)
    .add(1, "day")
    .set({ hour: 9, minute: 0, second: 0, millisecond: 0 })
    .format("YYYY-MM-DDTHH:mm:ss");
  const sixPMSameDay = momenttz
    .tz(onCallDate, navigatorTimezone)
    .set({ hour: 18, minute: 0, second: 0, millisecond: 0 })
    .format("YYYY-MM-DDTHH:mm:ss");

  if (isWeekend() || isHoliday(holidayList)) {
    return centralTimeNowFormatted >= nineAMSameDay && centralTimeNowFormatted <= nineAMNextDay;
  }
  return centralTimeNowFormatted >= sixPMSameDay || centralTimeNowFormatted < nineAMSameDay;
};

export const getOnCallNavigator = (onCallLNavigators: any[], currentUserProfile: INavigator | NavigatorDataType) => {
  const localTimeUtc = moment.utc().format();
  const centralDateTime = momenttz.tz(localTimeUtc, TIMEZONE.Central);
  const centralDateFormatted = centralDateTime.format("YYYY-MM-DD");
  const centralDateTimeFormatted = centralDateTime.format("YYYY-MM-DD HH:mm:ss");
  const centralDateTimeForPreviousDay = centralDateTime.subtract(1, "day").format("YYYY-MM-DD");
  let onCallNavigator;
  if (
    centralDateTimeFormatted < `${moment().format("YYYY-MM-DD")} 09:00:00` &&
    centralDateFormatted === moment().format("YYYY-MM-DD")
  ) {
    onCallNavigator = onCallLNavigators.find(
      (item) =>
        item.navigatorId === currentUserProfile.id && item.onCallDate === `${centralDateTimeForPreviousDay}T00:00:00`
    );
  } else if (
    centralDateTimeFormatted > `${moment().format("YYYY-MM-DD")} 09:00:00` &&
    centralDateFormatted === moment().format("YYYY-MM-DD")
  ) {
    onCallNavigator = onCallLNavigators.find(
      (item) => item.navigatorId === currentUserProfile.id && item.onCallDate === `${centralDateFormatted}T00:00:00`
    );
  } else {
    onCallNavigator = onCallLNavigators.find(
      (item) => item.navigatorId === currentUserProfile.id && item.onCallDate === `${centralDateFormatted}T00:00:00`
    );
  }
  return onCallNavigator;
};

export const getNavigatorStatusByOnCallTimings = (
  currentUserProfile: INavigator | NavigatorDataType,
  onCallLNavigators: any[]
) => {
  if (onCallLNavigators && onCallLNavigators.length > 0) {
    const onCallNavigator = getOnCallNavigator(onCallLNavigators, currentUserProfile);
    const isValidTime = onCallNavigator
      ? isNavigatorOnCallStatusEnabled("Central", onCallNavigator?.onCallDate)
      : false;
    if (isValidTime) {
      if (onCallNavigator) {
        return true;
      }
    }
  }
  return false;
};

export const getNavigatorStatusByOverrideValues = (navigator: NavigatorDataType | INavigator) => {
  const currentTime = moment(new Date()).utc().toISOString();
  const overrideDateMinutes = navigator.statusOverrride?.expirationMinutes;
  const overrideDateUtc = navigator.statusOverrride?.setDate;
  const overrideDateWithAddedMinutes = moment.utc(overrideDateUtc).add("minutes", overrideDateMinutes).toISOString();
  if (navigator && navigator.statusOverrride && currentTime < overrideDateWithAddedMinutes) {
    return navigator.statusOverrride?.status;
  } else {
    return null;
  }
};

export const getNavigatorOriginalOnlineOfflineStatus = (
  navigator: NavigatorDataType | INavigator,
  holidayList?: Array<Holiday>
) => {
  let isOnline: boolean;
  const navigatorTimezone = getConvertedTimezone(navigator?.timeZone as string);
  const currentDateInNavigatorTimeZone = momenttz.tz(new Date(), navigatorTimezone);

  if (isWeekend() || isHoliday(holidayList)) {
    return OnlineOfflineStatus.OFFLINE;
  } else if (navigator?.isOnVacation) {
    const currentDateInNavigatorTimeZoneFormatted = currentDateInNavigatorTimeZone.format("YYYY-MM-DDTHH:mm:ss");
    const vacationBeginDate = moment(navigator.vacationBeginDate)
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .format("YYYY-MM-DDTHH:mm:ss");
    const vacationEndDate = moment(navigator.vacationEndDate)
      .add("day", 1)
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .format("YYYY-MM-DDTHH:mm:ss");
    if (
      currentDateInNavigatorTimeZoneFormatted >= vacationBeginDate &&
      currentDateInNavigatorTimeZoneFormatted < vacationEndDate
    ) {
      return OnlineOfflineStatus.OFFLINE;
    }
  }
  const { startTimeConverted, endTimeConverted, localTime } = getNavigatorWorkTimeInUtcByTimezone(
    navigator?.timeZone as string,
    navigator?.workStartHour as number,
    navigator?.workStartMinute as number,
    navigator?.workEndHour as number,
    navigator?.workEndMinute as number
  );
  isOnline = localTime >= startTimeConverted && localTime < endTimeConverted;
  if (startTimeConverted > endTimeConverted) {
    if (startTimeConverted >= localTime) {
      isOnline = localTime >= startTimeConverted && localTime > endTimeConverted;
    } else {
      isOnline = localTime < startTimeConverted && localTime >= endTimeConverted;
    }
  }
  return isOnline ? OnlineOfflineStatus.ONLINE : OnlineOfflineStatus.OFFLINE;
};

export const getNavigatorAvailabilityStatus = (
  currentUserProfile: INavigator | NavigatorDataType | null,
  holidayList: Array<Holiday>,
  onCallNavigators: any[]
) => {
  if (currentUserProfile) {
    // Check to see if the user is on-call navigator for that day
    const isOnlineAsOnCall = getNavigatorStatusByOnCallTimings(currentUserProfile, onCallNavigators);
    if (isOnlineAsOnCall) {
      return OnlineOfflineStatus.FORWARDED;
    } else {
      // Check to see if Navigator has any override status set
      const navigatorStatusByOverride = getNavigatorStatusByOverrideValues(currentUserProfile);
      if (!navigatorStatusByOverride) {
        return getNavigatorOriginalOnlineOfflineStatus(currentUserProfile, holidayList);
      } else {
        return navigatorStatusByOverride;
      }
    }
  }
  return OnlineOfflineStatus.OFFLINE;
};

export const setLocalStorageItemWithExpiry = (key: string, value: any, expiryInMilliseconds: number) => {
  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + expiryInMilliseconds,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

export const getLocalStorageItemWithExpiry = (key: string) => {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

export const getTextWithElipsis = (str: string, maxlength: number) => {
  if (str.length > maxlength) {
    return str.substring(0, maxlength) + "...";
  }
  return str;
};

export const getLocalDateTime = (dateTime: string) => {
  return `${moment(moment.utc(dateTime).toDate()).format("MM/DD/YYYY")} ${moment(moment.utc(dateTime).toDate()).format(
    "hh:mm A"
  )}`;
};

export const getPatientFullName = (item: { firstName?: string; middleName?: string; lastName?: string }) => {
  const { firstName, middleName, lastName } = item;
  return [firstName, middleName, lastName].filter(Boolean).join(" ");
};

export const getPatientMetaData = (item: any) => {
  const { genderCode, mbi, dob } = item;
  const dobPatient = dob ? dob : "";
  const dobEndIndex = dobPatient.indexOf("T") === -1 ? dobPatient.length - 1 : dobPatient.indexOf("T");
  const [yyyy, mm, dd] = dobPatient.substring(0, dobEndIndex).split("-");
  const arr = [];
  if (dobEndIndex > 0) {
    arr.push(`${mm}/${dd}/${yyyy}`);
  }
  if (genderCode) {
    arr.push(genderCode);
  }
  if (mbi) {
    arr.push(mbi);
  }
  if (arr.length) {
    return `(${arr.join(", ")})`;
  }

  return "";
};

export const removeLastDashFromUrl = (url: string) => {
  const urlArray = url.split("/");

  if (urlArray[urlArray.length - 1] === "") {
    urlArray.splice(urlArray.length - 1);
    return urlArray.join("/");
  } else {
    return url;
  }
};

export const hasSpecialCharacter = (value: string) => {
  const regex = /[^A-Za-z0-9]/;
  return regex.test(value);
};

export const getGender = (gender: string) => {
  if (isEmptyString(gender)) {
    return "Not Known";
  }
  gender = gender.toUpperCase().trim();
  if (gender === "M") {
    return "Male";
  } else if (gender === "F") {
    return "Female";
  } else {
    return "Not Known";
  }
};

export const getAllIndexes = (arr: any[], val: string) => {
  const indexes = [];
  for (let i = 0; i < arr.length; i++)
    if (arr[i]?.questionId.includes(`${val}.yes`) || arr[i]?.questionId.includes(`${val}.no`)) indexes.push(i);
  return indexes;
};
export const isFileTypeImage = (typeOfTheFile: string) =>
  typeOfTheFile === "image" ||
  typeOfTheFile === "image/png" ||
  typeOfTheFile === "image/jpg" ||
  typeOfTheFile === "image/jpeg";

export const isFileDocument = (typeOfTheFile: string) =>
  typeOfTheFile === "application/pdf" ||
  typeOfTheFile === "pdf" ||
  typeOfTheFile === "application/msword" ||
  typeOfTheFile === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
  typeOfTheFile === "word";

export const getNotificationDescription = (notification: INotification) => {
  const senderName = notification.eventDesc.split(":");
  let eventMsg = `${notification.eventTitle} has sent you a message.`;
  const updatedEventTitle =
    notification.eventTitle.length > 20 ? notification.eventTitle.substring(0, 20) + "..." : notification.eventTitle;
  if (senderName.length > 1) {
    eventMsg = `${senderName[0]} has sent you a message in (${updatedEventTitle}) group.`;
  }
  return eventMsg;
};

export const isNotificationStale = (
  notificationConversationId: any,
  modalState: any,
  selectedConversationId: any
): boolean =>
  selectedConversationId.toString() === notificationConversationId.toString() &&
  (modalState.isOpenChatModal || modalState.isOpenChatModalForNotification);

export const convertMetersToMiles = (lengthInMeters: number) => {
  const dist = (lengthInMeters / METERS_PER_MILE).toFixed(1);
  const [wholeVal, fracVal] = dist.toString().split(".");
  if (fracVal === "0") {
    return [wholeVal, "miles"].join(" ");
  }
  return [dist, "miles"].join(" ");
};

export const validateMBI = (mbi: string) => {
  const regex =
    /^[1-9]{1}[^SLOIBZsloibz|^0-9|^`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]{1}[^SLOIBZsloibz|^`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]{1}[0-9]{1}?[^SLOIBZsloibz|^0-9|^`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]{1}[^SLOIBZsloibz|^`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]{1}[0-9]{1}?[^SLOIBZsloibz|^0-9|^`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]{1}[^SLOIBZsloibz|^0-9|^`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]{1}[0-9]{1}[0-9]{1}$/i;
  return regex.test(mbi);
};

export const iscontainOnlyNum = (num: string) => !isNaN(Number(num));
export const getTaskName = (taskName: string) => {
  if (taskName.includes(TaskPageName.CHECK_ON_PATIENT)) {
    return TaskPageName.CHECK_ON_PATIENT;
  } else if (
    taskName !== TaskPageName.REMINDER_TASK &&
    taskName !== TaskPageName.RISK_ASSESSMENT &&
    taskName !== TaskPageName.TRANSITION_OF_CARE &&
    taskName !== TaskPageName.NOTES_TASK &&
    taskName !== TaskPageName.SEND_ORDER &&
    taskName !== TaskPageName.ADMIT_TASK
  ) {
    return TaskPageName.ASSESSMENT;
  }
  return taskName;
};

export const getTaskHeaderText = (taskType: string) => taskType?.split("-").join(" ") ?? "";
export const createPatientEpisodeDetailsData = (data: IEpisodeDetails): IPatientEpisodeDetails => {
  const {
    patientFirstName,
    patientMiddleName,
    patientLastName,
    patientId,
    patientUserId,
    clientId,
    episodeId,
    programName,
    phoneNumber,
    preferredPhone,
    episodeName,
    episodeSite,
    episodeSide,
    physicianName,
    status,
    riskValue,
    tocAdherence,
    dateOfBirth,
    gender,
    programDischargeDate,
    surgeryDate,
    surgeryDischargeDate,
    admitDate,
    isOffTrack,
    pnavFirstName,
    pnavLastName,
    ccn,
    primaryCareManagerId,
    statusChangeReasonId,
  } = data;
  const filterContacts: any = Object.fromEntries(
    Object.entries(data).filter(([key, value], index) => {
      return key !== "phoneNumber" && key.includes("phone") && value !== null && value !== "";
    })
  );

  const contactDetails: Array<ContactDetailsType> = [];
  for (const phone in filterContacts) {
    contactDetails.push({
      isPreferred: data.preferredPhone === phone?.replace("phone", ""),
      phoneNumber: filterContacts && filterContacts?.[phone] ? filterContacts[phone] : "",
      type: phone?.replace("phone", ""),
    });
  }
  const convertedPatientEpisodeDetails: IPatientEpisodeDetails = {
    patientName:
      patientFirstName && !isEmptyString(patientFirstName)
        ? getFullName({ firstName: patientFirstName, lastName: patientLastName, middleName: patientMiddleName })
        : "-",
    episodeId,
    patientId: patientId,
    patientUserId,
    clientId: clientId.toString() ?? "",
    programName: programName ?? "-",
    episodeName: episodeName ?? "-",
    episodeSite: episodeSite ?? "N/A",
    episodeSide: episodeSide ?? "N/A",
    physicianName: physicianName && !isEmptyString(physicianName.trim()) ? physicianName : "-",
    status: status ?? "-",
    riskValue: riskValue && !isEmptyString(riskValue) ? riskValue : "-",
    tocAdherence: tocAdherence ?? "-",
    dob: dateOfBirth,
    gender: gender ?? "-",
    programDischargeDate: programDischargeDate,
    surgeryDate: surgeryDate,
    surgeryDischargeDate: surgeryDischargeDate,
    admitDate: admitDate,
    isOffTrack: isOffTrack === null ? "-" : isOffTrack ? "Off-Track" : "On-Track",
    patientPhoneNumber: phoneNumber,
    preferredPhone,
    contactDetails,
    pnavFirstName: pnavFirstName ?? "",
    pnavLastName: pnavLastName ?? "",
    patientFirstName,
    patientMiddleName,
    patientLastName,
    primaryCareManagerId,
    ccn,
    statusChangeReasonId,
  };
  return convertedPatientEpisodeDetails;
};

export const getTaskNameByTaskStatusId = (taskStatusId: TaskStatus, isTocType: boolean) => {
  if (isTocType && (taskStatusId === TaskStatus.IN_PROGRESS || TaskStatus.COMPLETED === taskStatusId)) {
    return taskStatusId === TaskStatus.IN_PROGRESS ? "Pending" : "Approved";
  }
  const keyName = TaskStatus[taskStatusId];
  const convertedKeyName = keyName ? keyName.split("_").join("-") : "-";
  return convertedKeyName.toLowerCase();
};

export const isDobValid = (value: Date | string | null) => {
  let isValid = false;
  if (!value) {
    return isValid;
  }
  const year = new Date(value).getFullYear();
  const age = Math.abs(new Date(Date.now() - new Date(value).getTime()).getUTCFullYear() - 1970);
  isValid = year >= 1900 && age >= 18;
  return isValid;
};

export const areStringsEqualIgnoreCase = (str1: string, str2: string) => str1.toLowerCase() === str2.toLowerCase();

export const checkIsArrayEmpty = (list: any[]) => Array.isArray(list) && list.length === 0;

export const checkIsTaskIsTOCTaskById = (taskTypeId: number): boolean =>
  taskTypeId === FormType.SEND_ORDER ||
  taskTypeId === FormType.ADMIT_TASK_WITH_PATIENT ||
  taskTypeId === FormType.ADMIT_TASK_WITH_PROVIDER ||
  taskTypeId === FormType.CHECK_ON_PATIENT_WITH_PATIENT ||
  taskTypeId === FormType.CHECK_ON_PATIENT_WITH_PROVIDER;

export const getTaskStatusNameByTaskStatusId = (taskStatusId: number) => {
  const taskStatusKeys = Object.fromEntries(
    Object.entries(TaskStatus).map(([keys, value]) => {
      return [value, keys.toLowerCase().split("_").join(" ")];
    })
  );
  return taskStatusKeys[taskStatusId] ?? "";
};

export const removeLineBreaksFromText = (text: string) => {
  return text.replace(/[\r\n]+/gm, " ");
};

export const checkForDash = (value: string) => {
  return value === "-";
};

export const getFirstLastNameFromFullName = (
  fullName: string
): { firstName: string | null; lastName: string | null } => {
  const firstName =
    fullName.length > 0
      ? fullName
          .split(" ")
          .slice(0, fullName.length - 1)
          .join(" ")
      : null;
  const lastName =
    fullName.length > 1
      ? fullName
          .split(" ")
          .slice(fullName.length - 1)
          .join(" ")
      : null;
  return { firstName, lastName };
};

export const debounce = function <T extends (...args: any[]) => void>(fn: T, wait: number) {
  let timerId: NodeJS.Timeout | number | undefined;
  return function (...args: any[]) {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(fn, wait, ...args);
  };
};

export const getTaskNameBasedOnTaskTypeId = (taskTypeId: number) => {
  switch (taskTypeId) {
    case FormType.RISK_ASSESSMENT:
      return TaskPageName.RISK_ASSESSMENT;
    case FormType.TOC:
      return TaskPageName.TRANSITION_OF_CARE;
    case FormType.NOTES:
      return TaskPageName.NOTES_TASK;
    case FormType.REMINDER:
      return TaskPageName.REMINDER_TASK;
    case FormType.CHECK_ON_PATIENT_WITH_PATIENT:
    case FormType.CHECK_ON_PATIENT_WITH_PROVIDER:
      return TaskPageName.CHECK_ON_PATIENT;
    case FormType.ADMIT_TASK_WITH_PATIENT:
    case FormType.ADMIT_TASK_WITH_PROVIDER:
      return TaskPageName.ADMIT_TASK;
    case FormType.SEND_ORDER:
      return TaskPageName.SEND_ORDER;
    default:
      return TaskPageName.ASSESSMENT;
  }
};
