import { createSlice } from "@reduxjs/toolkit";
import {
  createTocAsync,
  editTocAsync,
  findDistance,
  getAllTocFacilitiesAsync,
  getIntakeAsync,
  getTocAsync,
  getTocHistory,
  getTocRevisionReasons,
  getTocStatuses,
} from "./toc-management.action";
import { toast } from "react-toastify";
import {
  IFacility,
  ILocation,
  ITOCManagementState,
  ITocEditForm,
  ITransitionOfCare,
} from "state/types/toc-management-slice.type";
import { convertMetersToMiles } from "shared/methods/utilityFunctions";
import { TOC_ITEMS } from "pages/task-management/transition-of-care/constants/index.constant";

const initialState: ITOCManagementState = {
  isLoading: false,
  intake: null,
  isTocsLoading: true,
  tocs: [],
  facilities: [],
  revisionReasons: [],
  statuses: [],
  isTocValueChanged: false,
  tocEditForm: {
    acuteLos: {
      value: "",
      error: "",
    },
    navigatorNotes: null,
    physicianNotes: null,
    additionalNotes: null,
    isHomeWithNoServicesEnabled: false,
    tocItemsForm: TOC_ITEMS,
    facilityError: "",
    isClearModalOpen: false,
    isCancelModalOpen: false,
    isSubmitModalOpen: false,
    isTocItemsFormDirty: false,
    isFormEmpty: false,
    isChangeModalOpen: false,
  },
};
const tocManagementSlice = createSlice({
  name: "toc-management",
  initialState: Object.assign({}, initialState),
  reducers: {
    updateTocList(state, action) {
      state.tocs = action.payload;
    },
    addNewTocRevision(state, action) {
      state.tocs = [action.payload, ...state.tocs];
    },
    setIsTocsLoading(state, action) {
      state.isTocsLoading = action.payload;
    },
    setIsTocValueChanged(state, action) {
      state.isTocValueChanged = action.payload;
    },
    resetTocState() {
      return initialState;
    },
    setTocManagementStateFromMemoize(state, action) {
      return action.payload;
    },
    setTocEditForm(state, action) {
      state.tocEditForm = action.payload;
    },
    setTocItemsForm(state, action) {
      state.tocEditForm.tocItemsForm = action.payload;
    },
    resetTocItemsForm(state) {
      state.tocEditForm.tocItemsForm = structuredClone(TOC_ITEMS);
    },
    resetTocEditForm(state) {
      state.tocEditForm = structuredClone(initialState.tocEditForm);
    },
    setNavigatorAndAdditionalNotes: (state, action) => {
      state.tocEditForm.navigatorNotes = action.payload.navigatorNotes;
      state.tocEditForm.additionalNotes = action.payload.additionalNotes;
    },
    resetLocationValidation(state) {
      const tocItems: Record<string, ILocation> = {};
      Object.entries(state.tocEditForm.tocItemsForm).forEach(([key, location]) => {
        location.isLosValid = true;
        location.isProviderValid = true;
        tocItems[key] = location;
      });
      state.tocEditForm.tocItemsForm = tocItems;
    },
  },
  extraReducers: (builder) => {
    return (
      builder.addCase(getTocAsync.pending, (state) => {
        state.isTocsLoading = true;
        state.tocs = [];
      }),
      builder.addCase(getTocAsync.fulfilled, (state, action) => {
        state.isTocsLoading = false;
        state.tocs = action.payload;
      }),
      builder.addCase(getTocAsync.rejected, (state, action: any) => {
        state.isTocsLoading = false;
        state.tocs = [];
      }),
      builder.addCase(getAllTocFacilitiesAsync.fulfilled, (state, action) => {
        state.facilities = action.payload;
      }),
      builder.addCase(getIntakeAsync.fulfilled, (state, action) => {
        state.intake = action.payload;
      }),
      builder.addCase(createTocAsync.pending, (state, action) => {
        state.isLoading = true;
      }),
      builder.addCase(createTocAsync.fulfilled, (state, action) => {
        state.isLoading = false;
      }),
      builder.addCase(createTocAsync.rejected, (state, action) => {
        state.isLoading = false;
        toast.error("Something went wrong", {
          containerId: "main",
        });
      }),
      builder.addCase(editTocAsync.pending, (state, action) => {
        state.isLoading = true;
      }),
      builder.addCase(editTocAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success("TOC updated successfully", {
          containerId: "main",
        });
      }),
      builder.addCase(editTocAsync.rejected, (state: any, action) => {
        state.isLoading = false;
        toast.error("Something went wrong", {
          containerId: "main",
        });
      }),
      builder.addCase(getTocHistory.pending, (state, action) => {
        state.isLoading = true;
      }),
      builder.addCase(getTocHistory.fulfilled, (state, action) => {
        state.isLoading = false;
        const currentTocIdx = state.tocs.findIndex((el) => el.id === action.meta.arg);
        state.tocs[currentTocIdx].history = action.payload;
      }),
      builder.addCase(getTocHistory.rejected, (state, action) => {
        state.isLoading = false;
        toast.error("Something went wrong", {
          containerId: "main",
        });
      }),
      builder.addCase(findDistance.fulfilled, (state, action) => {
        const facilities: IFacility[] = [];
        const selectedPlaces = action.meta.arg.selectedPlaces;
        const response = action.payload;
        const selectedFacilityIds = selectedPlaces.map((el) => el.id);

        state.facilities.forEach((fac: IFacility) => {
          if (selectedFacilityIds.includes(fac.id)) {
            const index = selectedFacilityIds.findIndex((el) => el === fac.id);
            const entry = response.rows[0].elements[index];
            if (entry.distance) {
              const distance = convertMetersToMiles(entry.distance.value);
              facilities.push({ ...fac, distance } as IFacility);
            } else {
              facilities.push({ ...fac } as IFacility);
            }
          } else {
            facilities.push({ ...fac } as IFacility);
          }
        });

        facilities.sort((a, b) => {
          const distA = a.distance.length ? parseFloat(a.distance.split(" ")[0]) : Number.MAX_SAFE_INTEGER;
          const distB = b.distance.length ? parseFloat(b.distance.split(" ")[0]) : Number.MAX_SAFE_INTEGER;

          return distA - distB;
        });
        state.facilities = facilities;
      }),
      builder.addCase(getTocRevisionReasons.fulfilled, (state, action) => {
        state.revisionReasons = action.payload;
      }),
      builder.addCase(getTocStatuses.fulfilled, (state, action) => {
        state.statuses = action.payload;
      })
    );
  },
});

export const {
  addNewTocRevision,
  updateTocList,
  setIsTocsLoading,
  setIsTocValueChanged,
  resetTocState,
  setTocEditForm,
  setTocItemsForm,
  resetTocItemsForm,
  setTocManagementStateFromMemoize,
  resetTocEditForm,
  resetLocationValidation,
  setNavigatorAndAdditionalNotes,
} = tocManagementSlice.actions;
export const getTocs = (state: any): ITransitionOfCare[] => state.tocManagement.tocs;
export const getTOCManagementState = (state: any): ITOCManagementState => state.tocManagement;
export const getTocEditForm = (state: any): ITocEditForm => state.tocManagement.tocEditForm;
export default tocManagementSlice;
