import { HandleCallType, MakeACallType } from "pages/episodes/types";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { NO_PHONE_NUMBER_FOR_PRACTICE, TOAST_MESSAGE_NO_PHONE_NUMBER_ASSIGNED } from "shared/constant/commonConstants";
import { isEmptyString } from "shared/methods/utilityFunctions";
import { useTwilioClientService } from "shared/services/twilio/twilio-client.service";
import { getCallState, saveCurrentCall } from "state/feature/call/call.slice";
import { getCommonState, setIsOpenCallModal, setIsOpenChatModal } from "state/feature/common/common.slice";
import { getWhiteLabelPhoneNumberAsync } from "state/feature/navigator/navigator.action";
import { getNavigatorState } from "state/feature/navigator/navigator.slice";
import { useAppDispatch } from "state/store";
import PatientDetailsPresentation from "./patient-details.component";
import { OpenFrom } from "shared/enums/chat.enum";
import {
  addPatientToPatientArray,
  saveConversationParticipants,
  setUserIdforMessage,
} from "state/feature/messaging/messaging.slice";
import { getEpisodesSlice } from "state/feature/episodes/episodes.slice";
import { useParams } from "react-router";
import { CallingStatus } from "shared/enums/calling-status.enum";

const PatientDetails = () => {
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const { intakeId } = useParams<{ intakeId: string }>();
  const { currentUserProfile } = useSelector(getNavigatorState);
  const twilioClient = useTwilioClientService();
  const callState = useSelector(getCallState);
  const { twilioErrorCode } = useSelector(getCommonState);
  const { patientEpisodeDetails } = useSelector(getEpisodesSlice);

  const makeACall = async ({
    phoneNumber,
    isCallButtonClicked,
    setIsCallButtonClickedFunction,
    clientId,
    patientName,
    patientUserId,
    setShowPhoneNumbersFunction,
  }: MakeACallType) => {
    try {
      if (!isCallButtonClicked) {
        setIsCallButtonClickedFunction(true);

        const { data, status: responseStatus } = await appDispatch(
          getWhiteLabelPhoneNumberAsync({ clientId: clientId })
        ).unwrap();
        if (responseStatus === 204) {
          toast.error(NO_PHONE_NUMBER_FOR_PRACTICE, {
            containerId: "main",
            toastId: "call",
          });
        } else if (data && data.whiteLabelPhoneNumber) {
          const callerDetails = {
            name: `${currentUserProfile?.firstName ? currentUserProfile?.firstName : ""}${
              currentUserProfile?.lastName ? ` ${currentUserProfile?.lastName}` : ""
            }`,
            id: currentUserProfile?.id ? currentUserProfile?.id : "",
            phoneNumber: currentUserProfile?.forwardingPhoneNumber ? currentUserProfile?.forwardingPhoneNumber : "",
            type: "Navigator",
          };
          const effectiveNavigator = {
            id: currentUserProfile?.id ? currentUserProfile?.id : "",
            name: `${currentUserProfile?.firstName ? currentUserProfile?.firstName : ""}${
              currentUserProfile?.lastName ? ` ${currentUserProfile?.lastName}` : ""
            }`,
            phoneNumber: currentUserProfile?.forwardingPhoneNumber ? currentUserProfile?.forwardingPhoneNumber : "",
          };
          localStorage.setItem("callStatus", CallingStatus.CONNECTED);
          await twilioClient.addParticipantsAndCreateConference(
            phoneNumber,
            data.whiteLabelPhoneNumber,
            [
              {
                phoneNumber: phoneNumber,
                id: patientUserId,
                name: patientName,
                type: "Patient",
              },
              callerDetails,
            ],
            clientId,
            effectiveNavigator,
            intakeId ? parseInt(intakeId) : -1,
            async () => {
              dispatch(saveCurrentCall(null));
              toast.dismiss();
            }
          );
          dispatch(setIsOpenCallModal(false));
        } else {
          localStorage.setItem("callStatus", CallingStatus.DISCONNECTED);
          toast.error(TOAST_MESSAGE_NO_PHONE_NUMBER_ASSIGNED, {
            containerId: "main",
            toastId: "call",
          });
        }
        setShowPhoneNumbersFunction(false);
        setIsCallButtonClickedFunction(false);
      }
    } catch (error: any) {
      console.log(error);
      localStorage.setItem("callStatus", CallingStatus.DISCONNECTED);
      toast.error(
        "The microphone is currently disabled in your browser. You must enable the microphone and reload the browser (which disconnects the current call) to resolve the issue",
        { containerId: "main", toastId: "twilio-error" }
      );
      callState.twilioCallDevice.disconnectAll();
      dispatch(saveCurrentCall(null));
      setIsCallButtonClickedFunction(false);
      toast.dismiss("call");
    }
  };

  const handleCall = ({
    contactDetails,
    setShowPhoneNumbersFunction,
    patientPhoneNumber,
    clientId,
    isCallButtonClicked,
    patientName,
    patientUserId,
    setIsCallButtonClickedFunction,
  }: HandleCallType) => {
    if (!twilioErrorCode) {
      if (contactDetails) {
        if (contactDetails.length > 1) {
          setShowPhoneNumbersFunction(true);
        } else if (
          contactDetails.length === 0 &&
          (patientPhoneNumber === null || isEmptyString(patientPhoneNumber ?? ""))
        ) {
          toast.error("Phone number does not exist", {
            toastId: "phone-number-error",
            containerId: "main",
          });
        } else {
          if (contactDetails.length === 1) {
            makeACall({
              phoneNumber: contactDetails[0].phoneNumber,
              clientId,
              isCallButtonClicked,
              patientName,
              patientUserId,
              setIsCallButtonClickedFunction,
              setShowPhoneNumbersFunction,
            });
          } else if (patientPhoneNumber && !isEmptyString(patientPhoneNumber)) {
            makeACall({
              phoneNumber: patientPhoneNumber,
              clientId,
              isCallButtonClicked,
              patientName,
              patientUserId,
              setIsCallButtonClickedFunction,
              setShowPhoneNumbersFunction,
            });
          } else {
            toast.error("Something went wrong", {
              containerId: "main",
            });
          }
        }
      } else {
        toast.error("Something went wrong", {
          containerId: "main",
        });
      }
    } else {
      toast.error("Calling is not enabled, please refresh the page to enable it.", {
        toastId: "phone-number-error",
        containerId: "main",
      });
    }
  };

  const handleMessage = () => {
    if (patientEpisodeDetails && patientEpisodeDetails.patientId) {
      if (
        patientEpisodeDetails?.patientUserId &&
        patientEpisodeDetails.patientFirstName &&
        patientEpisodeDetails.patientLastName
      ) {
        dispatch(setUserIdforMessage(patientEpisodeDetails.patientUserId));
        dispatch(
          addPatientToPatientArray({
            patientID: patientEpisodeDetails.patientId,
            patientUserID: patientEpisodeDetails.patientUserId?.toString(),
            patientFirstName: patientEpisodeDetails.patientFirstName,
            patientLastName: patientEpisodeDetails.patientLastName,
          })
        );
        dispatch(saveConversationParticipants(patientEpisodeDetails.patientName ?? ""));
        dispatch(setIsOpenChatModal({ isOpen: true, openFrom: OpenFrom.EPISODE_DETAILS }));
      }
    }
  };

  return <PatientDetailsPresentation handleCall={handleCall} makeACall={makeACall} handleMessage={handleMessage} />;
};

export default PatientDetails;
