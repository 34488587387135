import { createAsyncThunk } from "@reduxjs/toolkit";
import { getConfig } from "config/config";
import { axiosInstance } from "shared/axios-instance";
import { IProviderType, ISaveSendOrderRequestPayload } from "state/types/send-order.type";

export const getSendOrderDetailsAsync = createAsyncThunk(
  "send-order/getSendOrderDetailsAsync",
  async (requestPayload: { intakeId: string; taskId: string }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `${getConfig().taskManagementBase}/api/v1/TOCTask/send-order/${requestPayload.intakeId}/${
          requestPayload.taskId
        }`
      );
      if (response.status === 200 && response.data && response.data.data) {
        return response.data.data;
      }
      return null;
    } catch (error: any) {
      return rejectWithValue(error.response);
    }
  }
);

export const getProviderOptionsAync = createAsyncThunk(
  "send-order/getProviderOptionsAync",
  async (intakeId: string, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `${getConfig().efusionBase}/api/v2/ExtProvider/getallByIntake/${intakeId}/false/true/false/false/true`
      );
      if (response.status === 200 && response.data) {
        return response.data as Array<IProviderType>;
      }
      return [];
    } catch (error) {
      return rejectWithValue([]);
    }
  }
);

export const getProviderSendOrderDetailsAsync = createAsyncThunk(
  "send-order/getProviderSendOrderDetailsAsync",
  async (requestPayload: { providerId: number }) => {
    const response = await axiosInstance.get(
      `${getConfig().taskManagementBase}/api/v1/TOCTask/provider-send-order/${requestPayload.providerId}`
    );
    if (response.status === 200 && response.data && response.data.data) {
      return response.data.data;
    }
    return null;
  }
);

export const saveSendOrderAsync = createAsyncThunk(
  "send-order/saveSendOrderAsync",
  async (requestPayload: ISaveSendOrderRequestPayload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `${getConfig().taskManagementBase}/api/v1/TOCTask/send-order`,
        requestPayload
      );
      return response;
    } catch (error: any) {
      const message = error?.response?.data?.errors?.[0]?.message ?? null;
      return rejectWithValue(message);
    }
  }
);

export const getAllFacilitiesSendOrderAsync = createAsyncThunk(
  "send-order/getAllFacilitiesAsync",
  async (clientId: number) => {
    try {
      const response = await axiosInstance.get(`${getConfig().efusionBase}/api/v2/ExtProvider/acuteCareFacility`, {
        params: { clientId },
      });
      return response.data;
    } catch (error) {
      return [];
    }
  }
);
