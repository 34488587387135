import "./message-read-participants.styles.scss";
import { IMessageReadParticipantsType } from "shared/types/messageReadParticipant.type";
import { getInitialsIfPictureNotPresent } from "shared/methods/taskSpecificMethods";
import ClickAwayListener from "react-click-away-listener";
import moment from "moment";
const MessageReadParticipantsPopover = ({
  receiptDetailsForParticipants,
  isOpen,
  className,
  handleCloseButton,
  id,
}: {
  receiptDetailsForParticipants: Array<IMessageReadParticipantsType>;
  isOpen: boolean;
  className?: string;
  handleCloseButton: () => void;
  id: string;
}) => {
  const getFormatLastReadTimeStamp = (timestamp: string) => {
    const localLastReadTimeStamp = moment(timestamp).local();
    const formattedLastReadTimestamp =
      Math.ceil(moment(new Date()).diff(localLastReadTimeStamp, "seconds")) < 60
        ? "Just now"
        : localLastReadTimeStamp.format("hh:mm A");

    return formattedLastReadTimestamp;
  };
  return (
    <ClickAwayListener onClickAway={handleCloseButton}>
      <div className={`message-read ${isOpen ? "show-read-participants" : ""} ${className ? className : ""}`} id={id}>
        <div
          className={`message-read-participants-container ${isOpen ? "show-read-participants" : ""} ${
            className ? className : ""
          }`}
        >
          <div className="message-read-participants-heading">Read By</div>
          <div className="message-read-participants-list">
            {receiptDetailsForParticipants.map((receiptDetailForParticipant: IMessageReadParticipantsType) => {
              return (
                <div className="message-read-participant" key={receiptDetailForParticipant.sid}>
                  <div className="message-read-participant-details">
                    <div className="message-read-participant-details--initials participant-read-initial">
                      {getInitialsIfPictureNotPresent({ name: receiptDetailForParticipant.fullName })}
                    </div>
                    <div className="message-read-participant-details--name">{receiptDetailForParticipant.fullName}</div>
                  </div>
                  <div className="message-read-participant-time">
                    {getFormatLastReadTimeStamp(receiptDetailForParticipant.lastReadTimestamp)}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default MessageReadParticipantsPopover;
