import moment from "moment";
import { EpisodesRowProps } from "pages/episodes/types";
import { isEmptyString } from "shared/methods/utilityFunctions";

const EpisodesRow = (props: EpisodesRowProps) => {
  const { row, index, onClickEpisode } = props;
  return (
    <div
      key={`${row.id}--${index}`}
      onClick={() => {
        if (row.intakeId) {
          onClickEpisode(row?.intakeId.toString());
        }
      }}
      className="episodes-listing-row"
    >
      {Object.entries(row).map(([key, value]) => {
        {
          if (key.toLowerCase().includes("id")) {
            return null;
          } else if (key === "dob") {
            return (
              <div key={`${row.id}-${key}`} className={`column dob`}>
                <div className="content">
                  <div>{value}</div>
                  {value && isEmptyString(value as string) && (
                    <div className="age">{`${moment().diff(value, "years", false)} years`}</div>
                  )}
                </div>
              </div>
            );
          } else {
            return (
              <div key={`${row.id}-${key}`} className="column">
                <div className="content">{value}</div>
              </div>
            );
          }
        }
      })}
    </div>
  );
};
export default EpisodesRow;
