import Button from "components/Button/button.component";
import "./footer.styles.scss";
import { FooterProps } from "../procedure-details.props";
const ProcedureDetailsFooter = (props: FooterProps) => {
  const { isDisabled, handleSubmit, isLoadingButton, className, isLoading, handleCancel } = props;
  return (
    <div className="procedure-details-footer-button-container">
      <div className="questions-footer-button-section">
        <div className="footer-buttons-div-container">
          <Button
            text={"Cancel"}
            className="cancel-button"
            disabled={isLoading || isLoadingButton}
            onClick={handleCancel}
          />

          <Button
            text={"Save Changes"}
            className={`submit-button ${className}`}
            onClick={handleSubmit}
            disabled={isDisabled}
            showLoader={isLoadingButton}
          />
        </div>
      </div>
    </div>
  );
};

export default ProcedureDetailsFooter;
