import EpisodeRow from "../episode-row/episode-row.component";
import "./episodes-table.styles.scss";

interface Episode {
  id: string;
  patientFirstName: string;
  patientLastName: string;
  physicianFirstName: string;
  physicianLastName: string;
  procedureDescription: string;
  patientCurrentLocation: string;
}
interface EpisodesTableProps {
  episodes: Episode[];
  tableClassname: string;
}

const EpisodesTable = ({ episodes, tableClassname }: EpisodesTableProps) => {
  return (
    <>
      {episodes && (
        <table className={tableClassname}>
          <tbody className="navigator-episodes-table-body">
            {episodes.length === 0 && (
              <tr className="no-message-container">
                <td className="no-message-td" colSpan={6}>
                  <div className="no-messages">No Data Found</div>
                </td>
              </tr>
            )}
            {episodes.map((item: Episode) => {
              return <EpisodeRow key={item.id} {...item} />;
            })}
          </tbody>
        </table>
      )}
    </>
  );
};

export default EpisodesTable;
