import CommonState from "components/common-state/common-state.component";
import { CommonStateType } from "components/common-state/common-state.enum";
import EpisodeTasksTablePresentation from "pages/episodes/episode-details/components/episode-tasks-table/episode-tasks-table.component";
import "./styles.scss";
import { EpisodeDetailsTasksPresentationProps, HeaderColumnInfo } from "pages/episodes/types";
import ChangeTnavTaskOwnerModal from "components/Modal/change-tnav-task-owner-modal/change-tnav-task-owner-modal.component";
import { getCommonState, setIsOpenTnavTaskOwnerChangeModal } from "state/feature/common/common.slice";
import { useSelector } from "react-redux";
import { useAppDispatch } from "state/store";

const EpisodeDetailsTasksPresentation = (props: EpisodeDetailsTasksPresentationProps) => {
  const {
    columns,
    currentTableData,
    isLoading,
    handleColumnSorting,
    isNewIntakeCreation,
    hasScrollbarVisible,
    onClickTask,
    tnavList,
    onClickEditIcon,
    handleChangeOwnerSubmit,
    handleChangeOwnerCancel,
    isLoadingSubmitChangerOwner,
  } = props;

  const {
    modal: { isOpenTnavTaskOwnerChangeModal },
  } = useSelector(getCommonState);
  const dispatch = useAppDispatch();
  return !isLoading && currentTableData.length === 0 ? (
    <CommonState type={CommonStateType.NO_EPISODE_TASKS_FOUND} isNewIntakeCreation={isNewIntakeCreation} />
  ) : (
    <>
      {isOpenTnavTaskOwnerChangeModal.isOpen && (
        <ChangeTnavTaskOwnerModal
          handleCancelButton={handleChangeOwnerCancel}
          handleSubmit={handleChangeOwnerSubmit}
          isOpen={isOpenTnavTaskOwnerChangeModal.isOpen}
          taskName={isOpenTnavTaskOwnerChangeModal.taskName}
          tnavList={tnavList}
          ownerName={isOpenTnavTaskOwnerChangeModal.ownerName}
          ownerId={isOpenTnavTaskOwnerChangeModal.ownerId}
          isLoadingButton={isLoadingSubmitChangerOwner}
        />
      )}
      <EpisodeTasksTablePresentation
        columns={columns}
        currentTableData={currentTableData}
        hasScrollbarVisible={hasScrollbarVisible}
        isLoading={isLoading}
        handleColumnSorting={handleColumnSorting}
        onClickTask={onClickTask}
        tnavList={tnavList}
        onClickEditIcon={onClickEditIcon}
      />
    </>
  );
};
export default EpisodeDetailsTasksPresentation;
