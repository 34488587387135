import Button from "components/Button/button.component";
import Modal from "components/Modal/modal.component";
import ModalHeader from "components/ModalHeader/modal-header.component";
import AvatarEditor from "react-avatar-editor";
import { useDispatch } from "react-redux";
import EmptyProfile from "shared/assets/images/empty-profile.svg";
import { setIsOpenProfileEditor } from "state/feature/common/common.slice";
import "./profile-picker.styles.scss";

function ProfilePicker({
  onChangeProfilePic,
  isOpenEditor,
  image,
  placeholder,
  personName,
}: {
  onChangeProfilePic: () => void;
  isOpenEditor: boolean;
  image?: string;
  placeholder?: string;
  personName?: string;
}) {
  const dispatch = useDispatch();
  return (
    <div className="profile-pic-container">
      {image ? (
        <div>
          <img className="actual-profile-image" src={image} alt="actual-profile-image" />
        </div>
      ) : (
        <div className="empty-image-container">
          <img
            className="empty-profile-image"
            src={EmptyProfile}
            alt="empty profile"
            onClick={() => dispatch(setIsOpenProfileEditor(true))}
          />
          <div className="upload-text" onClick={() => dispatch(setIsOpenProfileEditor(true))}>
            {placeholder ? placeholder : ""}
          </div>
          <div className="profile-picture-text">{personName ? personName : "Profile Photo"}</div>
        </div>
      )}
      <Modal
        isOpen={isOpenEditor}
        modalHeader={
          <ModalHeader heading="Crop and Adjust" onCloseModal={() => dispatch(setIsOpenProfileEditor(false))} />
        }
        className="photo-editor-modal center"
        contentClass="photo-editor-content"
      >
        <div className="content">
          <div>
            <AvatarEditor
              image="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8cHJvZmlsZXxlbnwwfHwwfHw%3D&w=1000&q=80"
              width={250}
              height={250}
              border={50}
              color={[255, 255, 255, 0.6]} // RGBA
              scale={1.2}
              rotate={0}
              className="profile-pic"
              onImageChange={onChangeProfilePic}
            />
          </div>
          <Button text="Crop" className="green-button crop-button" onClick={() => {}} />
        </div>
      </Modal>
    </div>
  );
}

export default ProfilePicker;
