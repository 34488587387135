import { useSelector } from "react-redux";
import { getAuth } from "state/feature/auth/auth.slice";
import { USER_ROLE } from "shared/constant/role-constant";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import Dashboard from "pages/dashboard/dashboard.container";
import ManagerDashboard from "pages/manager-dashboard/manager-dashboard.container";

const CommonDashBoard = () => {
  useAxiosAuthenticated();
  const authState = useSelector(getAuth);

  return (
    <>
      {authState && authState.user && authState.user.roles && authState.user.roles.length > 0 && (
        <>{authState.user.roles[0] === USER_ROLE.MANAGER ? <ManagerDashboard /> : <Dashboard />}</>
      )}
    </>
  );
};

export default CommonDashBoard;
