import Icon from "components/Icon/icon.component";
import moment from "moment";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNavigatorState, saveOnCallNavigators } from "state/feature/navigator/navigator.slice";
import { Holiday } from "state/types/navigator-slice.type";
import { INavigator } from "state/types/navigator.type";
import NavigatorPickerDropDown from "./navigator-picker.presentation";

type NavigatorPickerProps = {
  id: string;
  clickedElement: {
    id: string;
    position: any;
    remove: boolean;
  };
};

const NavigatorPicker = (props: NavigatorPickerProps) => {
  const { id, clickedElement } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const loading = useRef(true);
  const [selectedNavigator, setSelectedNavigator] = useState<null | { value: string }>(null);
  const {
    holidayList,
    navigatorScheduleOnCallNavigators,
    backupNavigators,
    extraOnCallNavigators,
    isSaveOnCallNavigatorList,
    isLoadingOnCallNavigators,
    currentUserProfile,
  } = useSelector(getNavigatorState);
  const [isHoliday, setIsHoliday] = useState(false);
  const [holidayDetails, setHolidayDetails] = useState<Holiday>();

  useEffect(() => {
    if (!isSaveOnCallNavigatorList && !isLoadingOnCallNavigators) {
      const screenHeight = screen.availHeight;
      const navigatorListHeight = 231;
      if (navigatorListHeight + (clickedElement.position.top + 100) > screenHeight) {
        document
          .getElementById("modal")
          ?.setAttribute(
            "style",
            `position:absolute;left:${clickedElement.position.left}px;top:${clickedElement.position.top - 200}px;`
          );
      } else {
        document
          .getElementById("modal")
          ?.setAttribute(
            "style",
            `position:absolute;left:${clickedElement.position.left}px;top:${clickedElement.position.top + 40}px;`
          );
      }
      if (id === clickedElement.id && (!selectedNavigator || selectedNavigator.value === "")) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickedElement, id]);

  useEffect(() => {
    if (holidayList && holidayList.length > 0) {
      holidayList.forEach((day) => {
        const date = moment(day.date).format("YYYY-MM-DD");
        if (date === id) {
          setIsHoliday(true);
          setHolidayDetails(day);
        }
      });
    }
  }, [holidayList, id]);

  const onSelectNavigator = (navigator: INavigator) => {
    if (id === clickedElement.id) {
      const nav = navigatorScheduleOnCallNavigators.find(
        (item: any) => moment(item.onCallDate).format("YYYY-MM-DD") === id
      );
      let payload;
      if (nav) {
        const previousNavigator = extraOnCallNavigators.find((navi) => navi?.dateId === nav?.id);
        payload = {
          ...navigator,
          date: id,
          dateId: nav.id,
          previousNavigatorId: previousNavigator?.previousNavigatorId ?? "",
          createdDate: nav?.createdDate ?? null,
          createdUser: nav?.createdUser ?? null,
        };
      } else {
        payload = { ...navigator, date: id, previousNavigatorId: "", createDate: null, createUser: null };
      }
      dispatch(saveOnCallNavigators(payload));
      setSelectedNavigator({ value: `${navigator.firstName} ${navigator.lastName}` });
    } else {
      setSelectedNavigator((prev) => prev);
    }
  };

  const closeDropdown = () => {
    setOpen(false);
  };

  const getNavigatorSelectorClassName = () => {
    if (!selectedNavigator) {
      return `dashed ${isSaveOnCallNavigatorList || isLoadingOnCallNavigators ? "pointer-disabled" : ""}`;
    } else {
      return `selected ${isSaveOnCallNavigatorList || isLoadingOnCallNavigators ? "pointer-disabled" : ""}`;
    }
  };

  useEffect(() => {
    const containerElement = document.getElementById(id);
    const actualParentElement = containerElement?.parentNode?.parentElement;
    const currentGrid = actualParentElement?.parentElement?.parentElement?.parentElement?.parentElement;
    if (actualParentElement && isHoliday && currentGrid) {
      actualParentElement.style.backgroundColor = "transparent";
      currentGrid.style.backgroundColor = "#F4F4F4";
    }
  }, [id, isHoliday]);

  const getNavigatorToBeShown = useCallback(
    (filteredNavigator: any) => {
      let navigatorToBeShown: INavigator | undefined;
      if (backupNavigators && backupNavigators.length) {
        navigatorToBeShown = backupNavigators?.find(
          (navigator) => navigator.id === (filteredNavigator.navigatorId || filteredNavigator.id)
        ) as INavigator;
      }
      if (!navigatorToBeShown) {
        navigatorToBeShown =
          currentUserProfile?.id === (filteredNavigator.navigatorId || filteredNavigator.id)
            ? (currentUserProfile as INavigator)
            : undefined;
      }
      return navigatorToBeShown;
    },
    [currentUserProfile, backupNavigators]
  );
  useEffect(() => {
    if (navigatorScheduleOnCallNavigators && navigatorScheduleOnCallNavigators.length > 0 && id) {
      setSelectedNavigator(null);
      const filteredNavigator: any = [...navigatorScheduleOnCallNavigators, ...extraOnCallNavigators].filter(
        (navigator) => moment(navigator.onCallDate || navigator.date).format("YYYY-MM-DD") === id
      );
      let filteredNavigatorToBeShown = null;
      if (filteredNavigator.length > 1) {
        filteredNavigatorToBeShown = filteredNavigator[filteredNavigator.length - 1];
      } else if (filteredNavigator.length === 1) {
        filteredNavigatorToBeShown = filteredNavigator[0];
      }
      if (filteredNavigatorToBeShown) {
        const navigatorToBeShown = getNavigatorToBeShown(filteredNavigatorToBeShown);
        if (navigatorToBeShown) {
          setSelectedNavigator({ value: `${navigatorToBeShown.firstName} ${navigatorToBeShown.lastName}` });
        }
      }
      loading.current = false;
    } else {
      loading.current = false;
    }
  }, [id, navigatorScheduleOnCallNavigators, extraOnCallNavigators, getNavigatorToBeShown]);

  useEffect(() => {
    const containerElement = document.getElementById(id);
    const actualParentElement = containerElement?.parentNode?.parentElement;
    if (selectedNavigator && selectedNavigator.value !== "") {
      if (actualParentElement) {
        actualParentElement.style.pointerEvents = "none";
        const removeButton = document.querySelector(`.remove-button-${id}`) as HTMLElement;
        if (removeButton) {
          removeButton.style.pointerEvents = "all";
        }
      }
    } else {
      if (actualParentElement) {
        setTimeout(() => {
          actualParentElement.style.pointerEvents = "all";
          const removeButton = document.querySelector(`.remove-button-${id}`) as HTMLElement;
          if (removeButton) {
            removeButton.style.pointerEvents = "none";
          }
        }, 100);
      }
    }
  }, [selectedNavigator, id]);

  return (
    <div className="navigator-picker-container" id={id}>
      {isHoliday && (
        <div className="navigator-selector-container disabled">
          <div className="holiday">
            <div className="es-holiday">ES Holiday</div>
            <div className="holiday-name">{holidayDetails?.name}</div>
          </div>
        </div>
      )}
      <div className={`navigator-selector-container ${getNavigatorSelectorClassName()}`}>
        {loading.current && <div className="loading-icon">Loading...</div>}
        {!loading.current && !selectedNavigator && (
          <div className="plus-icon-container">
            <Icon icon="plus-add" size={10} className="plus-icon" />
          </div>
        )}
        {!loading.current && selectedNavigator && (
          <div className="selected-value-container">
            <div className="selected-value">{selectedNavigator.value}</div>
            <div
              className={`remove-button-${id}`}
              onClick={(e) => {
                if (!isSaveOnCallNavigatorList && !isLoadingOnCallNavigators) {
                  e.preventDefault();
                  if (selectedNavigator) {
                    setSelectedNavigator(null);
                  }
                  e.stopPropagation();
                }
              }}
            >
              <Icon icon="cross-bold" size={8} className="cross-icon" />
            </div>
          </div>
        )}
      </div>
      {!loading.current && open && !selectedNavigator && (
        <NavigatorPickerDropDown
          isOpen={open}
          onSelectNavigator={onSelectNavigator}
          selectedNavigator={selectedNavigator}
          closeDropdown={closeDropdown}
        />
      )}
    </div>
  );
};

export default NavigatorPicker;
