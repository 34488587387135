import "./unsaved-task-modal.styles.scss";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../modal.component";
import Button from "components/Button/button.component";
import { UNSAVED_CHANGES, UNSAVED_TASK_SUB_HEADING } from "shared/constant/commonConstants";
import { getBrowserState, setShowUnsavedChangesModal } from "state/feature/task-management/task-management.slice";

export interface UnsavedTaskProps {
  handleOnClick: () => void;
}

const UnsavedTaskModal = (props: UnsavedTaskProps) => {
  const { handleOnClick } = props;
  const { showUnsavedChangesModal } = useSelector(getBrowserState);
  const dispatch = useDispatch();
  const handleOnNo = () => {
    dispatch(setShowUnsavedChangesModal(false));
  };

  const handleOnYes = () => {
    handleOnClick();
  };

  return (
    <Modal isOpen={showUnsavedChangesModal} className="center unsaved-task-popup">
      <div className="unsaved-task-container">
        <div className="header">{UNSAVED_CHANGES}</div>
        <div className="content">{UNSAVED_TASK_SUB_HEADING}</div>
        <div className="button-section">
          <Button text="No" className="white-button no-button" onClick={handleOnNo} />
          <Button text="Yes" className="green-button yes-button" onClick={handleOnYes} />
        </div>
      </div>
    </Modal>
  );
};

export default UnsavedTaskModal;
