import React from "react";
import Modal from "components/Modal/modal.component";
import Button from "components/Button/button.component";
import "./logout-modal.styles.scss";

type LogOutModalProps = {
  isOpen: boolean;
  handleCancelClick: () => void;
  handleReloadClick: () => void;
};

const LogOutModal = ({ isOpen, handleCancelClick, handleReloadClick }: LogOutModalProps) => {
  return (
    <Modal isOpen={isOpen} className="center logout-popup">
      <div className="logout-container">
        <div className="header">Logout</div>
        <div className="content">Are you sure you want to logout?</div>
        <div className="button-section">
          <Button text="Cancel" className="white-button cancel-button" onClick={handleCancelClick} />
          <Button text="Logout" className="green-button reload-button" onClick={handleReloadClick} />
        </div>
      </div>
    </Modal>
  );
};

export default LogOutModal;
