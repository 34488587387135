import { createAsyncThunk } from "@reduxjs/toolkit";
import { getConfig } from "config/config";
import { axiosInstance } from "shared/axios-instance";
import {
  ICurrentPatientDetails,
  IPatientAddress,
  ContactDetail,
  GetTaskListPayload,
  ApproveRejectTaskActionPayload,
} from "state/types/task-management-slice.type";
import { getFirstLastNameFromFullName, getGender, isEmptyString } from "shared/methods/utilityFunctions";
import { ContactType } from "shared/enums/contact-type.enum";
import { GetDelayAttempts, SaveDelayTask } from "pages/task-management/risk-assessment/common/risk-assessment.types";

export const getPatientDetailsAsync = createAsyncThunk(
  "task-management/getPatientDetailsAsync",
  async (requestPayload: any, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `${getConfig().efusionBase}/api/v2/ExtPatient/getPatientDetails/${requestPayload.intakeId}`
      );
      if (response?.status === 200) {
        return {
          intakeId: response.data.CurrentIntakeId,
          patientId: response.data.PatientId,
          patientName: `${response.data.FirstName} ${response.data.LastName}`,
          age: response.data.Age,
          isPlannedEpisode: response.data.IsPlannedEpisode,
          isRiskAssessmentTaskAdded: response.data.IsRiskAssessmentTaskAdded,
          clientId: response.data.ClientID,
          gender: getGender(response.data.Gender),
          dob: response.data.DOB,
          lastLoginAt: null,
          patientAddress: {
            address1: response.data.PatientAddress.Address1,
            address2: response.data.PatientAddress.Address2,
            city: response.data.PatientAddress.City,
            state: response.data.PatientAddress.State,
            zip: response.data.PatientAddress.Zip,
          } as IPatientAddress,
        } as ICurrentPatientDetails;
      }
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response);
    }
  }
);

export const getTasksAsync = createAsyncThunk(
  "task-management/getTasksAsync",
  async (requestPayload: GetTaskListPayload) => {
    const response = await axiosInstance.post(`${getConfig().taskManagementBase}/api/v1/tasks`, requestPayload);
    return response.data;
  }
);

export const getPatientAndEpisodeDetailsAysnc = createAsyncThunk(
  "task-management/getPatientAndEpisodeDetailsAysnc",
  async (requestPayload: any) => {
    const response = await axiosInstance.get(
      `${getConfig().taskManagementBase}/api/v1/Tasks/patient-and-episode-details?intakeId=${requestPayload.intakeId}`
    );
    const contactDetails: Array<ContactDetail> = response.data.data.patientContacts;
    if (response.data.data.providerPhone) {
      contactDetails.push({
        id: response.data.data.physicianId,
        name: response.data.data.physician,
        isPreferred: false,
        phoneNumber: response.data.data.providerPhone,
        type: ContactType.PHYSICIAN,
        firstName: response.data.data.physicianFirstName,
        lastName: response.data.data.physicianLastName,
        clientId: response.data.data.clientId.toString(),
      });
    }
    const { coachName, coachPhone } = response?.data?.data;
    if (coachPhone && !isEmptyString(coachPhone)) {
      const { firstName, lastName } = getFirstLastNameFromFullName(coachName ?? "");
      contactDetails.push({
        id: -1,
        name: coachName,
        firstName: firstName ?? "",
        lastName: lastName ?? "",
        isPreferred: false,
        phoneNumber: coachPhone,
        type: ContactType.CAREGIVER,
        clientId: response.data.data.clientId.toString(),
      });
    }
    const patientAndEpisodeDetailsData = {
      id: response.data.data.patientUserId,
      intakeId: requestPayload.intakeId,
      clientId: response.data.data.clientId.toString(),
      patientAndEpisodeDetails: {
        patientId: response.data.data.patientId.toString(),
        patientUserId: response.data.data.patientUserId,
        physicianUserId: response.data.data.physicianUserId,
        patient: response.data.data.patient,
        episodeId: "24",
        dateOfBirth: response.data.data.dob,
        gender: response.data.data.gender,
        practice: response.data.data.practice,
        procedure: response.data.data.procedureName,
        location: response.data.data.locationName,
        contactDetails: contactDetails,
        patientPhoneNumber: response.data.data.patientPhone ? response.data.data.patientPhone : "",
        coach: response.data.data.coachName ? response.data.data.coachName.toString() : "N/A",
        coachPhone: coachPhone && !isEmptyString(coachPhone) ? coachPhone : null,
        episode: response.data.data.episode,
        physician: response.data.data.physician,
        toc: response.data.data.toc,
        episodeSetting: response.data.data.episodeSetting,
        episodeCare: response.data.data.episodeCare,
        episodeType: response.data.data.episodeType,
        surgerySide: response.data.data.surgerySide,
        surgerySite: response.data.data.surgerySite,
        surgeryDate:
          response.data.data.surgeryDate && !isEmptyString(response.data.data.surgeryDate)
            ? response.data.data.surgeryDate
            : null,
        admitDate:
          response.data.data.admitDate && !isEmptyString(response.data.data.admitDate)
            ? response.data.data.admitDate
            : null,
        programDischargeDate:
          response.data.data.programDischargeDate && !isEmptyString(response.data.data.programDischargeDate)
            ? response.data.data.programDischargeDate
            : null,
        meds: "",
        description: "",
        patientFirstName: response.data.data.patientFirstName,
        patientLastName: response.data.data.patientLastName,
        intakeStatusId: response.data.data.intakeStatusId,
        navigator: {
          id: response.data.data.navigatorUserId,
          careManagerId: response.data.data.careManagerid,
          firstName: response.data.data.navigatorFirstName,
          lastName: response.data.data.navigatorLastName,
        },
        patientNavigationDeclined: response.data.data?.patientNavigationDeclined,
        patientNavigationDeclinedNotes: response.data.data?.patientNavigationDeclinedNotes,
        patientNavigationDeclinedChanged: response?.data?.data?.patientNavigationDeclinedChanged,
      },
      statusAndAlerts: {
        alert: response.data.data.alert,
        status: response.data.data.status,
        offTrack: response.data.data.offTrackStartDate,
        riskInfo: response.data.data.offtrackReason,
      },
    };
    return patientAndEpisodeDetailsData;
  }
);

export const calculateRisk = createAsyncThunk(
  "task-management/calculateRisk",
  async (requestPayload: { intakeId: string }) => {
    const response = await axiosInstance.get(
      `${getConfig().taskManagementBase}/api/v1/RiskAssessment/calculateRisk/${requestPayload.intakeId}`
    );
    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return "Incomplete";
    } else {
      return null;
    }
  }
);

export const getTaskDelayStatuses = createAsyncThunk("task-management/getTaskDelayStatuses", async (taskId: string) => {
  const response = await axiosInstance.get(`${getConfig().taskManagementBase}/api/v1/Tasks/task-delay-statuses`);
  return response.data.data;
});

export const postDelayTask = createAsyncThunk(
  "task-management/postDelayTask",
  async (requestPayload: SaveDelayTask) => {
    const response = await axiosInstance.post(
      `${getConfig().taskManagementBase}/api/v1/Tasks/delay-task`,
      requestPayload
    );
    return response.data;
  }
);

export const getDelayAttempts = createAsyncThunk(
  "task-management/getDelayAttempts",
  async (requestPayload: GetDelayAttempts) => {
    const response = await axiosInstance.post(
      `${getConfig().taskManagementBase}/api/v1/Tasks/get-delay-attempts`,
      requestPayload
    );
    return response.data.data;
  }
);

export const getIntakeDetails = createAsyncThunk(
  "task-management/getIntakeDetails",
  async (payload: { intakeId: string; taskId: string }) => {
    const { intakeId } = payload;
    const response = await axiosInstance.get(
      `${getConfig().taskManagementBase}/api/v1/Tasks/intake?intakeId=${intakeId}`
    );
    return response.data.data;
  }
);

export const getTaskAccess = createAsyncThunk(
  "task-management/getTaskAccess",
  async ({ intakeId, taskId }: GetDelayAttempts) => {
    const response = await axiosInstance.get(
      `${getConfig().taskManagementBase}/api/v1/Tasks/task-access-status?intakeId=${intakeId}&taskId=${taskId}`
    );
    return response.data;
  }
);

export const setLockState = createAsyncThunk(
  "task-management/setLockState",
  async (payload?: { intakeId: string; taskId: string; isLocked: boolean }) => {
    const response = await axiosInstance.post(
      `${getConfig().taskManagementBase}/api/v1/Tasks/lock-state`,
      payload ?? {}
    );
    return response.data;
  }
);

export const getPatientLastLoginAsync = createAsyncThunk(
  "task-management/getPatientLastLoginAsync",
  async (requestPayload: { userId: string; intakeId: string; taskId: string }) => {
    try {
      const response = await axiosInstance.get(
        `${getConfig().authenticationBase}/api/v1/Account/get-registration-detail`,
        {
          params: { userId: requestPayload.userId },
        }
      );
      if (response.status === 200 && response.data?.data) {
        return response.data.data;
      }
      return null;
    } catch (error) {
      return null;
    }
  }
);

export const getTaskManagementTasksExistAsync = createAsyncThunk(
  "task-management/getTaskManagementTasksExistAsync",
  async (requestPayload: { intakeId: string; taskId: string }) => {
    const response = await axiosInstance.get(
      `${getConfig().taskManagementBase}/api/v1/Tasks/intake?intakeId=${requestPayload.intakeId}`
    );
    if (response.status === 200 && response.data && response.data?.data) {
      return response.data.data;
    }
    return null;
  }
);

export const postTaskActionAsync = createAsyncThunk(
  "task-management/postTaskActionAsync",
  async (requestPayload: ApproveRejectTaskActionPayload) => {
    const response = await axiosInstance.post(
      `${getConfig().taskManagementBase}/api/v1/Tasks/task-action`,
      requestPayload
    );
    return response.data;
  }
);
