import { CustomToolTip } from "components/CustomTooltip/custom-tooltip.component";
import Icon from "components/Icon/icon.component";
import { PatientRegisteredTooltip } from "components/patient-registered-tooltip/patient-registered-tooltip.component";
import moment from "moment";
import { Link } from "react-router-dom";
import { ICurrentPatientDetails } from "state/types/task-management-slice.type";

export const PatientDetailsHeader = (props: { patientDetails: ICurrentPatientDetails | null; intakeId: string }) => {
  const { patientDetails, intakeId } = props;

  if (patientDetails) {
    return (
      <>
        <div className="patient-name-container">
          <Link className="patient-name" to={`/episodes/${intakeId}`}>
            {patientDetails.patientName}
          </Link>
          {patientDetails.lastLoginAt && patientDetails.lastLoginAt.isRegistered && (
            <div
              className="patient-registered-icon-container"
              data-tip
              data-for={`patient-registered-icon-${patientDetails?.patientName}`}
            >
              <Icon icon="patient-registered" size={17} />
              <CustomToolTip
                id={`patient-registered-icon-${patientDetails?.patientName}`}
                text={<PatientRegisteredTooltip patientLastLogged={patientDetails.lastLoginAt} />}
                place="bottom"
              />
            </div>
          )}
        </div>
        <div className="patient-other-details">
          <div>{patientDetails.gender}</div>
          <div className="dash">|</div>
          <div>
            <span className="dob">DOB:</span> {moment(patientDetails.dob).format("MM/DD/YYYY")}{" "}
            {patientDetails.age ? `(${patientDetails.age} years)` : ""}
          </div>
        </div>
      </>
    );
  }
  return null;
};
