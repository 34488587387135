import { useSelector } from "react-redux";
import { ProvidersTab } from "../common/constant";
import { getAdminState } from "state/feature/admin/admin.slice";
import Provider from "./provider/provider.container";
import ProvidersSubHeader from "../components/providers-sub-header/providers-sub-header.container";

const ProvidersPresentation = () => {
  const {
    providers: { activeTab },
  } = useSelector(getAdminState);
  return (
    <div className="providers-container">
      <ProvidersSubHeader />
      {activeTab === ProvidersTab.PROVIDER && <Provider />}
    </div>
  );
};

export default ProvidersPresentation;
