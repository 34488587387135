import Modal from "components/Modal/modal.component";
import { useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { getNavigatorState } from "state/feature/navigator/navigator.slice";
import { INavigator } from "state/types/navigator.type";
import "./navigator-picker.styles.scss";

type NavigatorPickerProps = {
  isOpen: boolean;
  onSelectNavigator: (value: INavigator) => void;
  selectedNavigator: {
    value: string;
  } | null;
  closeDropdown: () => void;
};

const NavigatorPickerDropDown = (props: NavigatorPickerProps) => {
  const { isOpen, onSelectNavigator, closeDropdown } = props;
  const { backupNavigators, isLoadingBackupNavigatorList, currentUserProfile } = useSelector(getNavigatorState);

  useEffect(() => {
    document.addEventListener("click", (e: Event) => {
      const clickedTarget = e.target as HTMLElement;
      if (!clickedTarget.classList.contains("fc-daygrid-event")) {
        closeDropdown();
      }
    });
  }, [closeDropdown]);
  const getNavigatorDropdownList = useCallback(() => {
    if (!isLoadingBackupNavigatorList) {
      if (!backupNavigators || (backupNavigators && backupNavigators.length == 0 && currentUserProfile)) {
        return currentUserProfile ? [currentUserProfile] : [];
      } else {
        return backupNavigators;
      }
    } else {
      return [];
    }
  }, [currentUserProfile, backupNavigators, isLoadingBackupNavigatorList]);
  return (
    <Modal isOpen={isOpen} className="navigator-list-modal">
      <div className="navigator-list">
        {/* <div className="navigator-list-item-header">Select Navigator</div> */}
        <div className="navigator-list-items">
          {getNavigatorDropdownList().length === 0 ? (
            <div className="navigator-list-item">No Record Found</div>
          ) : (
            getNavigatorDropdownList().map((navigator) => {
              return (
                <div
                  className="navigator-list-item"
                  key={navigator?.id}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onSelectNavigator(navigator);
                  }}
                >
                  {`${navigator?.firstName} ${navigator?.lastName}`}
                </div>
              );
            })
          )}
        </div>
      </div>
    </Modal>
  );
};
export default NavigatorPickerDropDown;
