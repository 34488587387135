import React, { useState, useCallback, useEffect } from "react";
import { useLocation, useHistory, Prompt } from "react-router";
import "./styles.scss";
import Profile from "pages/profile/profile.container";
import LogoutModal from "components/Modal/LogoutModal/logout-modal.component";
import { batch, useSelector } from "react-redux";
import { clearUser, getAuth } from "state/feature/auth/auth.slice";
import { getNavigatorRoleAsync } from "state/feature/auth/auth.action";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import {
  getCommonState,
  resetCommon,
  setConfirmedNavigation,
  setIsBlocking,
  setIsInboundCalled,
  setIsOpenLogoutModal,
  setIsOpenMultipleTabPopup,
  setIsUnsavedDataModalVisible,
  setLastLocation,
} from "state/feature/common/common.slice";
import { TwilioClient } from "shared/services/twilio/twilioClient";
import { clearTwilioToken, getCallState, saveCurrentCall, saveTwilioCallDevice } from "state/feature/call/call.slice";
import UserManagement from "pages/user-management/user-management.container";
import { checkIfFeatureEnabledAsync } from "../state/feature/common/common.action";
import { resetContacts } from "state/feature/contact/contact.slice";
import {
  getNavigatorState,
  resetToInitialNavigatorState,
  resetToInitialState,
  toggleStatusModal,
} from "state/feature/navigator/navigator.slice";
import { useAppDispatch } from "state/store";
import { OnlineOfflineStatus } from "shared/enums/online-offline.enum";
import { logNavigatorAction, postNavigatorStatus } from "state/feature/navigator/navigator.action";
import { isMobile } from "react-device-detect";
import { setUserValueChanged } from "state/feature/user-management/user-management.slice";
import OnlineOfflineModal from "components/Modal/online-offline-modal/online-offline-modal.component";
import { getNavigatorAvailabilityStatus } from "shared/methods/utilityFunctions";
import { updateIncomingCallStatusAsync } from "state/feature/call/call.action";
import TaskManagementContainer from "pages/task-management/task-management.container";
import { useSignalRService } from "shared/services/signalr/signalr.service";
import { getMessagingState } from "state/feature/messaging/messaging.slice";
import { resetAssessmentState } from "state/feature/assessment/assessment.slice";
import { ComposeMessage } from "components/SidePopup/ComposeMessage/compose-message.component";
import Episodes from "pages/episodes/episodes.container";
import ManagerContact from "pages/manager-contact/manager-contact.container";
import ManagerDashboard from "pages/manager-dashboard/manager-dashboard.container";
import NavigatorManagement from "pages/navigator-management/navigator-management.container";
import Dashboard from "pages/dashboard/dashboard.container";
import Contact from "pages/contact/contact.container";
import UnsavedDataModal from "components/Modal/unsaved-data-modal/unsaved-data-modal.component";
import { resetTocState, setIsTocValueChanged } from "state/feature/toc-management/toc-management.slice";
import { setIsValueChanged as isRiskAssessmentValueChanged } from "state/feature/risk-assessment/risk-assessment.slice";
import MultipleTaskModal from "components/Modal/multiple-task-modal/multiple-task-modal.component";
import { MultipleTabTaskStatus } from "shared/enums/multiple-tab-task-status.enum";
import Button from "components/Button/button.component";
import {
  addNewTab,
  expandTaskBoard,
  getBrowserState,
  getTaskManagementState,
  setCurrentTab,
} from "state/feature/task-management/task-management.slice";
import SideBar from "components/SideBar/side-bar.component";
import { NavigatorActionsEnum } from "shared/enums/navigator-actions-enum";

const SideBarNav = ({ children }: { children?: React.ReactNode }) => {
  useAxiosAuthenticated();
  const {
    featureFlags,
    modal,
    navigation: { confirmedNavigation, isBlocking, lastLocation },
    isLogoutButtonClicked,
  } = useSelector(getCommonState);
  const signalRClient = useSignalRService();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { auth, user } = useSelector(getAuth);
  const appDispatch = useAppDispatch();
  const callState = useSelector(getCallState);
  const { isOpenStatusModal, isOnlineOfflineButtonLoading, currentUserProfile, holidayList, onCallLNavigators } =
    useSelector(getNavigatorState);
  const messagingState = useSelector(getMessagingState);
  const { browser, redirectParams } = useSelector(getTaskManagementState);
  const { tabs } = useSelector(getBrowserState);

  const getHeading = () => {
    if (messagingState.selectedConversation?.participants) {
      return messagingState.selectedConversation?.participants;
    } else if (messagingState.isLoading) {
      return "Loading conversation";
    } else {
      return "New Message";
    }
  };
  const getStatusText = useCallback(() => {
    const statusText = getNavigatorAvailabilityStatus(currentUserProfile, holidayList, onCallLNavigators);
    return statusText === OnlineOfflineStatus.OFFLINE ? OnlineOfflineStatus.ONLINE : OnlineOfflineStatus.OFFLINE;
  }, [holidayList, onCallLNavigators, currentUserProfile]);

  useEffect(() => {
    if (auth.isAuthenticated && user.navigatorId) {
      setTimeout(() => {
        dispatch(checkIfFeatureEnabledAsync({ featureFlagName: "userManagement" }));
        appDispatch(checkIfFeatureEnabledAsync({ featureFlagName: "inboundCalls" })).then(() =>
          dispatch(setIsInboundCalled(true))
        );
        appDispatch(checkIfFeatureEnabledAsync({ featureFlagName: "taskManagement" }));
        appDispatch(checkIfFeatureEnabledAsync({ featureFlagName: "taskManagementListView" }));
        appDispatch(checkIfFeatureEnabledAsync({ featureFlagName: "inAppNotifications" }));
        appDispatch(checkIfFeatureEnabledAsync({ featureFlagName: "navigatorEpisodeList" }));
        appDispatch(checkIfFeatureEnabledAsync({ featureFlagName: "AdminSettings" }));
        appDispatch(checkIfFeatureEnabledAsync({ featureFlagName: "AllowTaskOwnerChange" }));
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, appDispatch, auth.isAuthenticated, user.navigatorId]);

  const onClickStatusChange = async (value: number) => {
    const currentStatus = getNavigatorAvailabilityStatus(currentUserProfile, holidayList, onCallLNavigators);
    if (currentStatus === OnlineOfflineStatus.ONLINE) {
      await appDispatch(postNavigatorStatus({ status: OnlineOfflineStatus.OFFLINE, expirationMinutes: value }));
    } else if (currentStatus === OnlineOfflineStatus.OFFLINE) {
      await appDispatch(postNavigatorStatus({ status: OnlineOfflineStatus.ONLINE, expirationMinutes: value }));
    }
    dispatch(toggleStatusModal(false));
  };

  const shouldBlockNavigation = () => {
    if (isBlocking) {
      return true;
    }
    return false;
  };

  const handleBlockedNavigation = (nextLocation: any) => {
    if (!confirmedNavigation && shouldBlockNavigation() && !isLogoutButtonClicked) {
      batch(() => {
        appDispatch(setIsUnsavedDataModalVisible(true));
        appDispatch(setLastLocation(nextLocation));
      });
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      batch(() => {
        dispatch(setConfirmedNavigation(false));
        dispatch(setIsBlocking(false));
      });
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, history, dispatch]);

  const [routesForNavigatorManager, setRoutesForNavigatorManager] = useState<any[]>([
    {
      name: "Dashboard",
      path: "/dashboard",
      icon: "dashboard-icon",
      iconSelected: "dashboard-selected-icon",
      className: "nav-item-dashboard",
      size: 24,
      main: () => <ManagerDashboard />,
      exact: true,
      enableMobileView: true,
      order: 1,
    },
    {
      name: "Navigators",
      path: "/navigator-management",
      icon: "navigator-management-icon",
      iconSelected: "navigator-management-selected-icon",
      className: "nav-item-management",
      size: 22,
      main: () => <NavigatorManagement />,
      exact: false,
      enableMobileView: true,
      order: 2,
    },
    {
      name: "Contacts",
      path: "/contact",
      icon: "contact-icon",
      iconSelected: "contact-selected-icon",
      className: "nav-item-contact",
      size: 24,
      main: () => <ManagerContact />,
      exact: true,
      enableMobileView: true,
      order: 3,
    },
  ]);
  const [routesForNavigator, setRoutesForNavigator] = useState<any[]>([
    {
      name: "Dashboard",
      path: "/dashboard",
      icon: "dashboard-icon",
      iconSelected: "dashboard-selected-icon",
      className: "nav-item-dashboard",
      size: 24,
      main: () => <Dashboard />,
      exact: true,
      enableMobileView: true,
      order: 1,
    },
    {
      name: "My Profile",
      path: "/profile",
      icon: "navigator-management-icon",
      iconSelected: "navigator-management-selected-icon",
      className: "nav-item-profile",
      size: 22,
      main: () => <Profile />,
      exact: true,
      enableMobileView: true,
      order: 2,
    },
    {
      name: "Contacts",
      path: "/contact",
      icon: "contact-icon",
      iconSelected: "contact-selected-icon",
      className: "nav-item-contact",
      size: 24,
      main: () => <Contact />,
      exact: true,
      enableMobileView: true,
      order: 3,
    },
  ]);

  useEffect(() => {
    if (featureFlags.userManagement) {
      const userManagementRoute = {
        name: "User Management",
        path: "/user-management",
        icon: "user-management-icon",
        iconSelected: "user-management-selected-icon",
        className: "nav-item-user-management",
        size: 22,
        main: () => <UserManagement />,
        exact: true,
        enableMobileView: false,
        order: 4,
      };

      if (!routesForNavigatorManager.find((x) => x.name === "User Management")) {
        setRoutesForNavigatorManager(
          [...routesForNavigatorManager, userManagementRoute].sort((item1, item2) =>
            item1.order < item2.order ? -1 : 1
          )
        );
      }
      if (!routesForNavigator.find((x) => x.name === "User Management")) {
        setRoutesForNavigator(
          [...routesForNavigator, userManagementRoute].sort((item1, item2) => (item1.order < item2.order ? -1 : 1))
        );
      }
    }
    if (featureFlags.taskManagement && featureFlags.taskManagementListView) {
      const taskManagementRoute = {
        name: "Task Management",
        path: "/task-management",
        icon: "task-management-icon",
        iconSelected: "task-management-selected-icon",
        className: "nav-item-contact",
        size: 22,
        main: () => <TaskManagementContainer />,
        exact: false,
        enableMobileView: false,
        order: 6,
      };
      if (!routesForNavigatorManager.find((x) => x.name === "Task Management")) {
        setRoutesForNavigatorManager(
          [...routesForNavigatorManager, taskManagementRoute].sort((item1, item2) =>
            item1.order < item2.order ? -1 : 1
          )
        );
      }
      if (!routesForNavigator.find((x) => x.name === "Task Management")) {
        setRoutesForNavigator(
          [...routesForNavigator, taskManagementRoute].sort((item1, item2) => (item1.order < item2.order ? -1 : 1))
        );
      }
    }
    if (featureFlags.navigatorEpisodeList) {
      const episodesListRoute = {
        name: "Episodes",
        path: "/episodes",
        icon: "episodes-icon",
        iconSelected: "episodes-selected-icon",
        className: "nav-item-episodes",
        size: 21,
        main: () => <Episodes />,
        exact: true,
        enableMobileView: true,
        order: 5,
      };
      if (!routesForNavigatorManager.find((x) => x.name === "Episodes")) {
        setRoutesForNavigatorManager(
          [...routesForNavigatorManager, episodesListRoute].sort((item1, item2) => (item1.order < item2.order ? -1 : 1))
        );
      }
      if (!routesForNavigator.find((x) => x.name === "Episodes")) {
        setRoutesForNavigator(
          [...routesForNavigator, episodesListRoute].sort((item1, item2) => (item1.order < item2.order ? -1 : 1))
        );
      }
    }
  }, [
    featureFlags.userManagement,
    featureFlags.taskManagement,
    routesForNavigator,
    routesForNavigatorManager,
    featureFlags.taskManagementListView,
    featureFlags.navigatorEpisodeList,
  ]);

  const getDataBasedOnUrl = useCallback(() => {
    if (user.email && user.navigatorId && auth.isAuthenticated) {
      dispatch(getNavigatorRoleAsync());
    }
  }, [user.email, user.navigatorId, auth.isAuthenticated, dispatch]);

  useEffect(() => {
    getDataBasedOnUrl();
  }, [getDataBasedOnUrl]);

  const publicRoutes = ["/", "/forgot-password"];

  const logOutUser = () => {
    if (callState.currentCall) {
      if (callState.currentCall?.status() === "pending") {
        appDispatch(
          updateIncomingCallStatusAsync({
            callSid: callState.currentCall?.parameters?.CallSid,
            status: "missed",
            currentUserProfile,
          })
        ).then(() => {
          callState.currentCall?.reject();
        });
      } else {
        callState.currentCall?.reject();
      }
    }
    const globalWindow = window as any;
    if (globalWindow && globalWindow.ReactNativeWebView) {
      globalWindow.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "loggedOutUser",
          loggedInUser: null,
        })
      );
    }
    if (callState.twilioCallDevice) {
      callState.twilioCallDevice?.disconnectAll();
      if (callState.twilioCallDevice._state === "registered") {
        callState.twilioCallDevice?.unregister();
      }
    }
    dispatch(
      logNavigatorAction({
        createdBy: currentUserProfile?.id ?? "",
        createdByUser: `${currentUserProfile?.firstName} ${currentUserProfile?.lastName}`,
        type: NavigatorActionsEnum.LOGOUT,
      })
    );
    TwilioClient.removeInstance();
    signalRClient?.disconnectSignalrOnLogout(user.email);
    dispatch(clearTwilioToken());
    dispatch(saveCurrentCall(null));
    dispatch(saveTwilioCallDevice(null));
    dispatch(resetTocState());
    dispatch(clearUser());
    dispatch(resetCommon());
    dispatch(resetContacts());
    dispatch(resetToInitialNavigatorState());
    history.replace("/");
    sessionStorage.removeItem("riskAssessmentAnswers");
  };

  const handleConfirmNavigationClick = () => {
    dispatch(setIsTocValueChanged(false));
    dispatch(isRiskAssessmentValueChanged(false));
    dispatch(resetToInitialState());
    dispatch(resetAssessmentState());
    dispatch(setUserValueChanged(false));
    if (!isLogoutButtonClicked) {
      appDispatch(setConfirmedNavigation(true));
    } else {
      logOutUser();
    }
  };

  const handleLogoutClick = async () => {
    logOutUser();
  };

  const handleLeftButton = () => {
    dispatch(
      setIsOpenMultipleTabPopup({
        isOpen: false,
        doneBy: "",
        doneByUser: "",
        action: -1,
      })
    );
  };

  const handleOpenTask = () => {
    dispatch(
      setIsOpenMultipleTabPopup({
        isOpen: false,
        doneBy: "",
        doneByUser: "",
        action: -1,
      })
    );
    const selectedTab = tabs.find((t) => t.taskId === browser.requestedTaskPayload?.taskId);
    if (!selectedTab) {
      dispatch(
        addNewTab({
          taskId: browser.requestedTaskPayload?.taskId,
          intakeId: browser.requestedTaskPayload?.intakeId,
          taskType: browser.requestedTaskPayload?.taskType,
          title: browser.requestedTaskPayload?.title,
        })
      );
    } else {
      dispatch(setCurrentTab(selectedTab));
    }
    history.push("/task-management");
    dispatch(expandTaskBoard(false));
  };

  const getButtonBasedOnType = () => {
    switch (modal.isOpenMultipleTabPopup.action) {
      case MultipleTabTaskStatus.Locked:
      case MultipleTabTaskStatus.Attempted:
        return (
          <>
            <Button text="No" className="white-button left-button" onClick={handleLeftButton} />
            <Button text="Yes" className="green-button right-button" onClick={handleOpenTask} />
          </>
        );
      case MultipleTabTaskStatus.Closed:
      case MultipleTabTaskStatus.Completed:
        return (
          <>
            <Button text="No" className="white-button left-button" onClick={handleLeftButton} />
            <Button text="Yes" className="green-button right-button" onClick={handleOpenTask} />
          </>
        );
    }
  };

  const getHeaderBasedOnType = () => {
    switch (modal.isOpenMultipleTabPopup.action) {
      case MultipleTabTaskStatus.Locked:
        return "Task Already Opened";
      case MultipleTabTaskStatus.Attempted:
        return "Task Attempted";
      case MultipleTabTaskStatus.Closed:
      case MultipleTabTaskStatus.Completed:
        return "Task Already Completed/Closed";
      default:
        return "";
    }
  };

  const getSubHeaderBasedOnType = () => {
    switch (modal.isOpenMultipleTabPopup.action) {
      case MultipleTabTaskStatus.Locked:
        return `${modal.isOpenMultipleTabPopup.doneByUser} already has this task open. If this is incorrect, you can continue and open this task. Do you want to continue?`;
      case MultipleTabTaskStatus.Attempted:
        return `${modal.isOpenMultipleTabPopup.doneByUser} attempted this task today. Do you want to continue?`;
      case MultipleTabTaskStatus.Closed:
      case MultipleTabTaskStatus.Completed:
        return `${modal.isOpenMultipleTabPopup.doneByUser} has already completed/closed this task. You can continue to open this task in view mode. Do you want to continue?`;
      default:
        return "";
    }
  };

  if (!publicRoutes.includes(location.pathname)) {
    return (
      <div className={`app-routes ${isMobile ? "mobileView" : ""}`}>
        <OnlineOfflineModal
          className="online-offline-modal"
          isOpen={isOpenStatusModal}
          submitButtonTitle="Yes"
          heading={`Temporary set me ${getStatusText().toLowerCase()}`}
          handleCancelClick={() => dispatch(toggleStatusModal(false))}
          isButtonLoading={isOnlineOfflineButtonLoading}
          handleSubmitClick={onClickStatusChange}
          isOnline={
            getNavigatorAvailabilityStatus(currentUserProfile, holidayList, onCallLNavigators) ===
            OnlineOfflineStatus.ONLINE
          }
          currentProfile={currentUserProfile}
        />

        <LogoutModal
          isOpen={modal.isOpenLogoutModal}
          handleCancelClick={() => dispatch(setIsOpenLogoutModal(false))}
          handleReloadClick={() => handleLogoutClick()}
        />

        {!redirectParams ? (
          <MultipleTaskModal
            isOpen={modal.isOpenMultipleTabPopup.isOpen}
            buttons={getButtonBasedOnType()}
            header={getHeaderBasedOnType()}
            subHeader={getSubHeaderBasedOnType()}
          />
        ) : null}

        <ComposeMessage
          isOpen={modal.isOpenChatModal.isOpen}
          openFrom={modal.isOpenChatModal.openFrom}
          onClose={() => {
            // setConversationRequestPayload({ ...converationRequestPayload.current, offset: 0 });
          }}
        />

        <UnsavedDataModal handleOnClick={handleConfirmNavigationClick} />
        <Prompt when={isBlocking} message={handleBlockedNavigation} />

        {user && user.roles && user.roles.length > 0 && (
          <SideBar routes={user.roles[0] === "Navigator Manager" ? routesForNavigatorManager : routesForNavigator} />
        )}
        {children}
      </div>
    );
  } else return null;
};

export default SideBarNav;
