export const DROP_DOWN_ITEMS = [
  { key: "transition", name: "Transition" },
  { key: "patient", name: "Patient" },
];

export const TIME_ZONE_DROPDOWN_ITEMS = [
  { key: "Eastern Time", name: "Eastern Time" },
  { key: "Central Time", name: "Central Time" },
  { key: "Mountain Time", name: "Mountain Time" },
  { key: "Pacific Time", name: "Pacific Time" },
];

export const BACKUP_NAVIGATORS = [
  { key: "backup-navigator", name: "Backup Navigator" },
  { key: "theresa-webb", name: "Theresa Webb" },
  { key: "darlene-robertson", name: "Darlene Robertson" },
  { key: "kathryn-murphy", name: "Kathryn Murphy" },
];

export const WORK_HOUR_DROPDOWN_ITEMS = [
  { key: "01:00", name: "01:00" },
  { key: "01:30", name: "01:30" },
  { key: "02:00", name: "02:00" },
  { key: "02:30", name: "02:30" },
  { key: "03:00", name: "03:00" },
  { key: "03:30", name: "03:30" },
  { key: "04:00", name: "04:00" },
  { key: "04:30", name: "04:30" },
  { key: "05:00", name: "05:00" },
  { key: "05:30", name: "05:30" },
  { key: "06:00", name: "06:00" },
  { key: "06:30", name: "06:30" },
  { key: "07:00", name: "07:00" },
  { key: "07:30", name: "07:30" },
  { key: "08:00", name: "08:00" },
  { key: "08:30", name: "08:30" },
  { key: "09:00", name: "09:00" },
  { key: "09:30", name: "09:30" },
  { key: "10:00", name: "10:00" },
  { key: "10:30", name: "10:30" },
  { key: "11:00", name: "11:00" },
  { key: "11:30", name: "11:30" },
  { key: "12:00", name: "12:00" },
  { key: "12:30", name: "12:30" },
];

export const ONLINE_STATUS_DROPDOWN_ITEMS = [
  {
    key: "15",
    name: "15",
  },
  {
    key: "30",
    name: "30",
  },
  {
    key: "45",
    name: "45",
  },
  {
    key: "60",
    name: "60",
  },
];
