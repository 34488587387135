import { SessionTimerProps } from "./session-timer.props";

const SessionTimerPresentation = (props: SessionTimerProps) => {
  const { timer } = props;
  return (
    <div className="session-timer-container">
      <div className="session-ends-in">You will be logged out in</div>
      <div className="timer-minutes">{`${timer} minute(s)`}</div>
    </div>
  );
};

export default SessionTimerPresentation;
