import React from "react";
import Icon from "../Icon/icon.component";
import "./table-loader.styles.scss";

const TableLoader = () => {
  return (
    <div className="table-loader">
      <Icon icon={"table-loader"} className={"table-loader-class"} size={20} />
      <span className="loading-text">Loading please wait...</span>
    </div>
  );
};

export default TableLoader;
