import Button from "components/Button/button.component";
import Checkbox from "components/Checkbox/checkbox.component";
import SidePopup from "components/SidePopup/side-popup.component";
import CommonState from "components/common-state/common-state.component";
import { CommonStateType } from "components/common-state/common-state.enum";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { getCommonState } from "state/feature/common/common.slice";

const ActivityFilterPresentation = (props: {
  filterOptions: Array<string>;
  selectedFilterValues: Array<string>;
  handleStatusFilter: (option: string) => void;
  handleApplyClick: () => void;
  handleClearFilter: () => void;
  handleModalClose: () => void;
  selectedValues: Array<string>;
  isLoadingFilter: boolean;
  isFilterValueChanged: boolean;
}) => {
  const {
    filterOptions,
    handleStatusFilter,
    handleModalClose,
    selectedFilterValues,
    handleApplyClick,
    handleClearFilter,
    isLoadingFilter,
    isFilterValueChanged,
    selectedValues,
  } = props;
  const { modal } = useSelector(getCommonState);

  const checkIfValueExist = useCallback(
    (statusOption: string) => selectedFilterValues.includes(statusOption),
    [selectedFilterValues]
  );
  return (
    <SidePopup
      isOpen={modal.isOpenFilterModal}
      onClose={() => {
        handleModalClose();
      }}
      contentClass="filter-content-container"
      className="filter-side-popup activity-logs-filter-side-popup"
      heading="Filter"
    >
      <div className="filter-content">
        {isLoadingFilter ? (
          <CommonState type={CommonStateType.LOADING} />
        ) : (
          <div className="filter-options">
            <div>
              <div className="task-status">Task Category</div>
              <div className="task-status-checkbox-container">
                {filterOptions.length > 0 &&
                  filterOptions.map((statusOption) => {
                    return (
                      <div className={`${statusOption}`}>
                        <Checkbox
                          name={statusOption}
                          value={statusOption}
                          id={`${statusOption}-checkbox`}
                          onChange={() => {
                            handleStatusFilter(statusOption);
                          }}
                          isChecked={checkIfValueExist(statusOption)}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        )}
        <div className="filter-buttons">
          <Button
            onClick={() => {
              if (!isLoadingFilter && isFilterValueChanged) {
                handleApplyClick();
              }
            }}
            text="Apply"
            disabled={isLoadingFilter || !isFilterValueChanged}
            className={`green-button apply-button ${isLoadingFilter || !isFilterValueChanged ? "disabled" : ""}`}
          />
          <Button
            onClick={handleClearFilter}
            text="Clear"
            className={`white-button clear-button ${
              (selectedValues.length === 0 && !isFilterValueChanged) || isLoadingFilter ? "disabled" : ""
            }`}
            disabled={(selectedValues.length === 0 && !isFilterValueChanged) || isLoadingFilter}
          />
        </div>
      </div>
    </SidePopup>
  );
};
export default ActivityFilterPresentation;
