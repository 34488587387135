import { useEffect } from "react";
import AdminPresentation from "./admin.component";
import { useDispatch } from "react-redux";
import { getClientsAsync } from "state/feature/admin/admin.action";
import { setPracticeOption } from "state/feature/admin/admin.slice";

const Admin = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getClientsAsync());
    return () => {
      dispatch(setPracticeOption(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <AdminPresentation />;
};

export default Admin;
