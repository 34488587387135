import React from "react";
import { TooltipSingleLineProps } from "./props";
import "./tooltip-single-line.styles.scss";

const TooltipSingleLine = ({ isOnCallNavigator, isBackupNavigator, receivedBy, isHovered }: TooltipSingleLineProps) => {
  return (
    <div className="tooltipSingleLine-container">
      <div className={`tooltip ${isHovered ? "tooltipHovered" : ""}`}>
        <div className="tooltiptext-center">
          {isOnCallNavigator && (
            <>
              The call was recieved by <strong>you</strong> on behalf of &nbsp;
              <strong>{receivedBy}</strong>
            </>
          )}
          {isBackupNavigator && (
            <>
              The call was recieved by <strong>{receivedBy}</strong> on your behalf.
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TooltipSingleLine;
