import Modal from "components/Modal/modal.component";
import "./summary-modal.styles.scss";
import ModalHeader from "components/ModalHeader/modal-header.component";
import Icon from "components/Icon/icon.component";
import { data } from "./summary-modal-data";
import { useDispatch } from "react-redux";
import { showStaticModal } from "state/feature/risk-assessment/risk-assessment.slice";
import { RiskTable } from "components/Modal/summary-modal/risk-table/risk-table.component";

type SummaryModalProps = {
  isOpen: boolean;
};

const SummaryModal = ({ isOpen }: SummaryModalProps) => {
  const dispatch = useDispatch();
  return (
    <Modal
      isOpen={isOpen}
      modalHeader={
        <ModalHeader
          icon={
            <div className="checklist-icon-container">
              <Icon icon="summary-modal-checklist" size={16} />
            </div>
          }
          iconClassName="cross-container"
          heading="Tasks for Each Risk Level"
          onCloseModal={() => dispatch(showStaticModal(false))}
        />
      }
      className="center summary-modal-container"
      contentClass="summary-modal-main"
    >
      <div className="summary-modal-content">
        <RiskTable tableClassName="summary-modal-content-table" risks={data} />
      </div>
    </Modal>
  );
};

export default SummaryModal;
