export const data = [
  {
    name: "Dr. Hiten Shah:",
    value: "And I tweeted it because it just came in my head. I had no rhyme or reason.",
  },
  {
    name: "Kristin Watson: ",
    value:
      "And this is Hiten Shah. And today on The Startup Chat, we’re going to talk about how self-management is the best management, because I tweeted that and I think Steli liked it.",
  },
  {
    name: "Dr. Hiten Shah:",
    value: "And I tweeted it because it just came in my head. I had no rhyme or reason.",
  },
  {
    name: "Dr. Hiten Shah:",
    value: "And I tweeted it because it just came in my head. I had no rhyme or reason.",
  },
  {
    name: "Dr. Hiten Shah:",
    value: "And I tweeted it because it just came in my head. I had no rhyme or reason.",
  },
];
