import { useEffect, useState } from "react";
import TableWithPaginationPresentation, {
  HeaderColumnInfo,
} from "components/table-with-pagination/table-with-pagination.component";
import "./episodes.styles.scss";
import EpisodeRow from "./episode-row.component";
import { useDispatch, useSelector } from "react-redux";
import {
  clearEpisodesSearch,
  getAdminState,
  resetEpisode,
  setEpisodeGetRequest,
  setEpisodesIsSearching,
} from "state/feature/admin/admin.slice";
import { EpisodesColumnsHeaderInfo } from "../common/constant";
import { getEpisodesAsync, getStandardCareplans } from "state/feature/admin/admin.action";
import { CommonStateType } from "components/common-state/common-state.enum";
import { SortingOrderType } from "shared/enums/sorting-types.enums";
import { getCommonState } from "state/feature/common/common.slice";
import Modal from "components/Modal/modal.component";
import EpisodesSubHeader from "../components/episodes-sub-header/episodes-sub-header.component";
import EpisodeEdit from "./episode-edit/episode-edit.component";

const Episodes = () => {
  const [columnsHeaderInfo, setColumnsHeaderInfo] = useState(EpisodesColumnsHeaderInfo);

  const {
    modal: { isOpenEditEpisodeModal },
  } = useSelector(getCommonState);
  const dispatch = useDispatch();
  const {
    episode: { records, totalCount, episodeGetRequest, isLoading: isLoadingEpisodes },
    selectedPractice,
  } = useSelector(getAdminState);

  useEffect(() => {
    if (episodeGetRequest.clientId !== 0) {
      dispatch(getEpisodesAsync(episodeGetRequest));
    }
  }, [dispatch, episodeGetRequest]);

  useEffect(() => {
    dispatch(getStandardCareplans());
  }, [dispatch]);

  useEffect(() => {
    if (selectedPractice) {
      dispatch(
        setEpisodeGetRequest({
          ...episodeGetRequest,
          clientId: selectedPractice?.key,
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPractice, setEpisodeGetRequest]);

  useEffect(() => {
    return () => {
      dispatch(resetEpisode());
      dispatch(clearEpisodesSearch());
      dispatch(setEpisodesIsSearching(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSort = (col: HeaderColumnInfo) => {
    const index = columnsHeaderInfo.findIndex((x) => x.key === col.key);
    let sort: SortingOrderType = SortingOrderType.DEFAULT;
    switch (columnsHeaderInfo[index].sortOrder) {
      case SortingOrderType.DESC:
      case SortingOrderType.DEFAULT:
        sort = SortingOrderType.ASC;
        break;
      case SortingOrderType.ASC:
        sort = SortingOrderType.DESC;
        break;
    }
    dispatch(setEpisodeGetRequest({ sortOrder: sort, sortBy: col.key }));
    const tempHeaders = [...columnsHeaderInfo];
    tempHeaders.forEach((header) => {
      if (header.sortOrder) {
        header.sortOrder = SortingOrderType.DEFAULT;
      }
    });
    tempHeaders[index].sortOrder = sort;
    setColumnsHeaderInfo(tempHeaders);
  };
  return (
    <>
      <div className="episodes-container">
        {isOpenEditEpisodeModal ? (
          <Modal className="episode-edit-modal" children={<EpisodeEdit />} isOpen={isOpenEditEpisodeModal} />
        ) : null}
        <EpisodesSubHeader />
        <div className="episodes-table">
          <TableWithPaginationPresentation
            columns={columnsHeaderInfo}
            handleSort={handleSort}
            isLoading={isLoadingEpisodes}
            emptyMessageType={CommonStateType.NO_EPISODES_FOUND}
            rows={records.map((data) => (
              <EpisodeRow key={data.id} episode={data} />
            ))}
            tableContainerClass="episodes-table-container"
            countPerPage={episodeGetRequest.pageSize}
            currentPage={episodeGetRequest.pageCount}
            totalCount={totalCount}
            onPageChange={(pageCount: number) => {
              dispatch(setEpisodeGetRequest({ ...episodeGetRequest, pageCount }));
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Episodes;
