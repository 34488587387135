import React from "react";
import { CheckboxProps } from "./props";
import "./checkbox.styles.scss";

const Checkbox = ({ className, value, name, onChange, id, isChecked, isDisabled, defaultChecked }: CheckboxProps) => {
  if (isChecked !== undefined) {
    return (
      <label className={`checkbox-label ${isDisabled ? "disabled" : ""}`} htmlFor={id}>
        <input
          id={id}
          type="checkbox"
          className={`checkbox ${className ? className : ""}`}
          name={name}
          onChange={onChange}
          checked={isChecked}
          disabled={isDisabled}
        />
        {value}
      </label>
    );
  } else {
    return (
      <label className={`checkbox-label ${isDisabled ? "disabled" : ""}`} htmlFor={id}>
        <input
          id={id}
          type="checkbox"
          defaultChecked={defaultChecked ? defaultChecked : false}
          className={`checkbox ${className ? className : ""}`}
          name={name}
          onChange={onChange}
          disabled={isDisabled}
        />
        {value}
      </label>
    );
  }
};

export default Checkbox;
