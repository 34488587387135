import ConversationLoading from "components/ConversationLoading/conversation-loading.component";
import { TooltipItems } from "components/Tooltip/props";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAgeFromDate, getDateInMMDDYYYYFormat } from "shared/methods/utilityFunctions";
import "./conversation-participants.styles.scss";
import { getMessagingState } from "state/feature/messaging/messaging.slice";
import { getPatientDetailsForConversationAsync } from "state/feature/messaging/messaging.action";

const ConversationParticipants = () => {
  const { isLoadingMessagingParticipants, patientInConversation, selectedConversation } =
    useSelector(getMessagingState);
  const dispatch = useDispatch();

  const getStatus = (value: any) => {
    if (value === "OnTrack") {
      return "On-Track";
    } else if (value === "OffTrack") {
      return "Off-Track";
    } else {
      return value;
    }
  };

  useEffect(() => {
    if (
      !isLoadingMessagingParticipants &&
      selectedConversation.conversationId &&
      selectedConversation.conversationId !== -1
    ) {
      dispatch(
        getPatientDetailsForConversationAsync({
          conversationId: selectedConversation.conversationId,
          detailedInfo: true,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConversation.conversationId, selectedConversation, dispatch]);

  const getPatientDetailsArr = () => {
    const {
      firstName,
      lastName,
      location,
      dob,
      phoneNumber,
      preferred,
      procedureName,
      procedureDate,
      surgerySide,
      surgerySite,
      days90,
      status,
    } = patientInConversation || {};

    const patientDetailsArr: TooltipItems[] = [
      {
        name: "Patient",
        value: firstName && lastName ? `${firstName} ${lastName}` : "-",
        isHighlighted: !!(firstName || lastName),
        className: "patient",
      },
    ];
    if (phoneNumber) {
      patientDetailsArr.push({
        name: "Phone No.",
        value: phoneNumber ? (
          <div>
            <div>{phoneNumber}</div>
            {preferred && <div className="phone-number-details">{preferred + " (Preferred)"}</div>}
          </div>
        ) : (
          "-"
        ),
      } as TooltipItems);
    }

    patientDetailsArr.push(
      ...([
        {
          name: "DOB (Age)",
          value: `${dob ? `${getDateInMMDDYYYYFormat(new Date(dob))} (${getAgeFromDate(new Date(dob))} years)` : "-"} `,
        },
        {
          name: "Procedure",
          value: `${
            procedureName
              ? `${procedureName} ${procedureDate ? `(${getDateInMMDDYYYYFormat(new Date(procedureDate))})` : ""}`
              : "-"
          } `,
        },
        {
          name: "Laterality",
          value: surgerySide && surgerySide !== "N/A" ? `${surgerySide} - ${surgerySite}` : "None",
        },
        {
          name: "90 Days",
          value: `${days90 ? getDateInMMDDYYYYFormat(new Date(days90)) : "-"}`,
        },
        {
          name: "Status",
          value: status ? `${getStatus(status)}` : "",
          className: `${status === "OnTrack" ? "on-track" : "off-track"}`,
        },
      ] as TooltipItems[])
    );

    return patientDetailsArr;
  };

  return (
    <div className="conversation-participants-container">
      <div>
        {isLoadingMessagingParticipants && (
          <div className="loading">
            <ConversationLoading />
          </div>
        )}
        {!isLoadingMessagingParticipants && getPatientDetailsArr()?.length !== 0 && (
          <div className="participant-grid">
            {getPatientDetailsArr()?.map((item, index) => (
              <React.Fragment key={"item_" + index}>
                <div className={`item-name ${item?.className ? `${item.className}-name` : ""}`}>{item?.name}</div>
                {item.className === "patient" && (
                  <div>
                    <div className={`item-value ${item.isHighlighted ? "highlighted" : ""} ${item.className ?? ""}`}>
                      {item.value}
                    </div>
                  </div>
                )}
                {item.className !== "patient" && (
                  <div className={`item-value ${item.isHighlighted ? "highlighted" : ""} ${item.className ?? ""}`}>
                    {item.value}
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ConversationParticipants;
