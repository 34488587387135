import RiskAssessmentSummary from "./summary-view/risk-assessment-summary.component";
import RiskAssessment from "./risk-assessment.container";
import { getRiskAssessmentState, resetRiskAssessmentState } from "state/feature/risk-assessment/risk-assessment.slice";
import { useDispatch, useSelector } from "react-redux";
import {
  getBrowserState,
  getCurrentPageTaskState,
  setIsCurrentTaskDirty,
  setIsCurrentTaskTouched,
} from "state/feature/task-management/task-management.slice";
import { useEffect } from "react";

const RiskAssessmentContainer = () => {
  const dispatch = useDispatch();
  const riskAssessmentState = useSelector(getRiskAssessmentState);
  const { currentTab } = useSelector(getBrowserState);
  const { patientDetails, isValidIntake, isValidatingIntake } = useSelector(
    getCurrentPageTaskState(currentTab!.taskId)
  );

  useEffect(() => {
    if (riskAssessmentState.isValueChanged) {
      dispatch(setIsCurrentTaskTouched({ taskId: currentTab?.taskId, isTouched: true }));
      dispatch(setIsCurrentTaskDirty({ taskId: currentTab?.taskId, isDirty: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [riskAssessmentState]);

  useEffect(() => {
    return () => {
      dispatch(resetRiskAssessmentState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkRiskAssessmentPublished = () => {
    return (
      !isValidatingIntake &&
      isValidIntake &&
      riskAssessmentState.status?.toLowerCase() === "published" &&
      patientDetails &&
      patientDetails.isRiskAssessmentTaskAdded
    );
  };

  return <>{checkRiskAssessmentPublished() ? <RiskAssessmentSummary /> : <RiskAssessment />}</>;
};

export default RiskAssessmentContainer;
