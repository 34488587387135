import Button from "components/Button/button.component";
import { RevisionReasonModalEnum } from "../../constants/index.constant";
import "./revision-reason-modal.styles.scss";
import CustomDropDown from "components/CustomDropdown/custom-dropdown.component";
import { useState } from "react";
import Modal from "components/Modal/modal.component";
import ModalHeader from "components/ModalHeader/modal-header.component";
import { ITocRevisionReason } from "state/types/toc-management-slice.type";
import Icon from "components/Icon/icon.component";
import { IRevisionReasonModal } from "./props.type";

export const RevisionReasonModal = function (props: IRevisionReasonModal) {
  const { isOpen, menuItems, onCancel, onCreate, onModalClose, src } = props;
  const [selectedReason, setSelectedReason] = useState<ITocRevisionReason | null>(null);

  return (
    <Modal
      isOpen={isOpen}
      className="center revision-reason-modal-container"
      contentClass="revision-reason-modal-main"
      modalHeader={
        <ModalHeader iconClassName="cross-container" heading="Create a revision" onCloseModal={() => onModalClose()} />
      }
    >
      <div id="revision-reason-modal-content">
        <div className="info">
          A TOC should only be revised if there is a change to the services that are required for a patient . A TOC
          should NOT be revised just to match the services a patient has received.
        </div>
        <div className="reason-dropdown-container">
          <div className="label-content">
            <label>Reason of revising TOC</label>
          </div>
          <CustomDropDown
            placeholder="Select"
            idFieldName="id"
            dropDownMenuItems={menuItems.map(({ id, reason }) => ({ id, name: reason }))}
            handleValueChanges={(val: any) => setSelectedReason(val)}
            dropDownBoxClass={""}
            selectionClass={""}
          />
          {src === RevisionReasonModalEnum.PENDING_REVISION && (
            <div className="warning-content">
              <div className="warning-icon">
                <Icon icon="yellow-warning" size={16} />
              </div>
              <div className="warning-info">
                This episode has a TOC that has not been approved. If you continue, the unapproved TOC will be canceled
                and can no longer be approved.
              </div>
            </div>
          )}
        </div>

        <div className="cta-container">
          <Button text="Cancel" onClick={onCancel} className="white-button cancel-button" />
          <Button
            text="Create"
            onClick={() => onCreate(selectedReason)}
            disabled={!selectedReason}
            className="green-button create-button"
          />
        </div>
      </div>
    </Modal>
  );
};
