import NavigatorRow from "../navigator-row/navigator-row.component";
import "./navigator-table.styles.scss";
import { ColumnType } from "shared/types/table.type";

const NavigatorTable = ({
  currentTableData,
  tableClassName,
}: {
  currentTableData: any[];
  columns: ColumnType[];
  tableClassName: string;
}) => {
  return (
    <>
      {currentTableData && (
        <table className={tableClassName}>
          <tbody className="navigator-management-table-body">
            {currentTableData.length === 0 && (
              <tr className="no-message-container">
                <td className="no-message-td" colSpan={6}>
                  <div className="no-messages">No Contacts Found</div>
                </td>
              </tr>
            )}
            {currentTableData.map((item: any, index) => {
              return <NavigatorRow key={`${item.id}-${index}`} {...item} />;
            })}
          </tbody>
        </table>
      )}
    </>
  );
};

export default NavigatorTable;
