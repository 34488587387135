import ReactSlider from "react-slider";
import "./scale.styles.scss";
import { useContext } from "react";
import { AssessmentContext } from "pages/task-management/assessment/context/assessment-context";
import Button from "components/Button/button.component";
import { useSelector } from "react-redux";
import { getAssessmentState } from "state/feature/assessment/assessment.slice";

interface ScaleProps {
  id?: string;
  questionId: string;
  order: number | null | undefined;
  sectionId: string | null | undefined;
  scaleTitle?: string | null;
  defaultValue?: Array<number>;
  stepCount?: number;
  minValue?: number;
  maxValue?: number;
  isDisabled?: boolean;
}

const Scale = (scaleProps: ScaleProps) => {
  const { id, questionId, order, defaultValue, stepCount, minValue, maxValue, scaleTitle, isDisabled } = scaleProps;
  const { onScaleChange } = useContext(AssessmentContext);
  const { answers } = useSelector(getAssessmentState);
  const answer = answers.filter((i) => i.questionId === questionId);

  return (
    <div>
      <div className="scale-heading">{scaleTitle}</div>
      <label id="first-slider-label"></label>
      <label id="second-slider-label"></label>
      <ReactSlider
        disabled={isDisabled}
        className={`horizontal-slider-${questionId}-${order} horizontal-slider`}
        thumbClassName="horizontal-slider-thumb"
        trackClassName="horizontal-slider-track"
        defaultValue={defaultValue ? defaultValue : [0, 10]}
        value={
          answer.length > 0 && Object.keys(answer[0].selectedValues).length > 0
            ? [answer[0].selectedValues[questionId][0].value, answer[0].selectedValues[questionId][1].value]
            : defaultValue
        }
        onChange={(value, index) => {
          if (onScaleChange) {
            onScaleChange(questionId, value[0].toString(), value[1].toString(), "");
          }
        }}
        ariaLabelledby={["first-slider-label", "second-slider-label"]}
        ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
        renderTrack={(props, state) => {
          const modifiedProps = { ...props };
          modifiedProps.style = {
            ...props.style,
            background: state.index === 2 ? "rgba(0,0,0,0.1)" : state.index === 1 ? props.style?.background : "#ddd",
          };
          return <div {...modifiedProps}></div>;
        }}
        renderThumb={(props) => {
          return <div {...props}></div>;
        }}
        marks={stepCount}
        pearling
        step={stepCount ? stepCount : 1}
        min={minValue ? minValue : 0}
        max={maxValue ? maxValue : 10}
        minDistance={stepCount ? stepCount : 1}
        renderMark={(props) => {
          return (
            <div className="mark-scale">
              <div {...props} className="line">
                <div className="mark-value">{props.key}</div>
              </div>
            </div>
          );
        }}
      />

      {!isDisabled && (
        <span>
          <Button
            text="Reset"
            className="reset-button"
            onClick={() => {
              if (onScaleChange) {
                onScaleChange(questionId, "0", "0", "");
              }
            }}
          />
        </span>
      )}
    </div>
  );
};

export default Scale;
