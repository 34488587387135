import React from "react";
import { GenericTabsProps, TabsListProps } from "./props";
import "./generic-tabs.styles.scss";

const GenericTabs = (props: GenericTabsProps) => {
  const { tabsList, activeTabName, setActiveTabName } = props;
  const handleClick = (singleTab: TabsListProps) => {
    if (!singleTab.isDisabled) setActiveTabName(singleTab.name);
  };
  return (
    <div className="navigatorSection">
      <div className="navigatorTabs">
        {tabsList?.map((singleTab) => (
          <div
            key={singleTab.name}
            className={`tabs ${singleTab.isDisabled ? "" : "allowed"} ${
              activeTabName === singleTab.name ? "active" : ""
            } ${singleTab.className ? singleTab.className : ""}`}
            onClick={() => handleClick(singleTab)}
          >
            {singleTab?.name} {singleTab?.count ? `(${singleTab?.count})` : null}
          </div>
        ))}
      </div>
    </div>
  );
};

export default GenericTabs;
