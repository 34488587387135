import StylesConfig from "react-select";
import { OptionType } from "./single-select-dropdown-with-search.component";

export const singleDropDownWithSearchStyles = {
  control: (styles: any) => ({
    ...styles,
    backgroundColor: "white",
    border: "0.1rem solid #a6d9ce",
    height: "4.2rem",
    width: "auto",
    position: "relative",
    borderRadius: "0.8rem",
    ":focus": {
      outline: "none",
      border: "0.1rem solid #a6d9ce !important",
    },
    ":hover": {
      border: "0.1rem solid #a6d9ce !important",
    },
  }),
  indicatorSeparator: () => ({
    color: "white",
    width: 0,
    height: 0,
  }),
  indicatorsContainer: (styles: any, { isDisabled }: any) => {
    const indicatorClass: HTMLDivElement | null = document.querySelector(".select__dropdown-indicator");
    if (indicatorClass) {
      if (isDisabled) {
        indicatorClass.style.cursor = "not-allowed";
      } else {
        indicatorClass.style.cursor = "pointer";
      }
    }
    return { ...styles };
  },
  dropdownIndicator: (styles: any) => ({
    ...styles,
  }),
  option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
    return {
      ...styles,
      backgroundColor: isDisabled ? undefined : isSelected ? "white" : undefined,
      cursor: isDisabled ? "not-allowed" : "pointer",
      fontFamily: "Mon-medium",
      fontSize: "1.4rem",
      outline: "none",
      padding: "1rem",
      color: isSelected ? "#1aa99c" : "black",
      ":hover": {
        backgroundColor: "#f1faf9",
      },
    };
  },
  input: (styles: any) => ({
    ...styles,
    fontFamily: "Mon-medium",
    fontSize: "1.4rem",
  }),
  placeholder: (styles: any) => ({ ...styles, fontFamily: "Mon-medium", fontSize: "1.4rem" }),
  singleValue: function (styles: any, { data }: any): any {
    if (data && data.label === "From") {
      return { ...styles, fontFamily: "Mon-medium", fontSize: "1.4rem", color: "rgba(0,0,0,0.6)" };
    }
    return { ...styles, fontFamily: "Mon-medium", fontSize: "1.4rem" };
  },
  menu: (styles: any) => {
    return {
      ...styles,
    };
  },
};
