import ActivityHistoryDetailsColumn from "components/activity-history-details/activity-history-details.component";
import moment from "moment";
import { CALLER, DONE_BY } from "shared/constant/activity-logs-constants";
import { TaskType } from "shared/enums/task-type.enum";
import { TaskStatus } from "shared/enums/tasks-status.enum";
import { IActivityHistoryRow, ICallLog, ICarePlanUtilizationLog, ITocLog } from "state/types/episodes-slice.type";

const ActivityLog = (props: IActivityHistoryRow) => {
  const { activityLogType, createDate, details, createUser, name, notes, taskStatusId, status } = props;

  const renderUTCCreatedDatedInLocal = () => {
    const createdDateInLocal = moment.utc(createDate, "YYYY-MM-DDTHH:mm:ss").local();
    return `${createdDateInLocal.format("MM/DD/YYYY")} | ${createdDateInLocal.format("hh:mm A")}`;
  };
  const renderTaskStatusUIMessage = () => {
    const taskDetails =
      activityLogType === TaskType.TOC && taskStatusId === TaskStatus.COMPLETED ? (details as ITocLog) : null;
    if (taskDetails && taskDetails.isUpdatedByPhysician) {
      return <span className="toc-approve-message">(Changes from submitted)</span>;
    }
    return null;
  };

  const getClassNameForStatusCallLog = () => {
    if (activityLogType === TaskType.CALL) {
      const callDetails = details as ICallLog;
      return callDetails.callType === 0 && !callDetails.callStatus.toLowerCase().includes("answered")
        ? "red-color"
        : "";
    }
    return "";
  };

  const renderDoneByUI = () => {
    if (activityLogType === TaskType.CALL) {
      const newDetails = details as ICallLog;
      return (
        <div className="done-by">
          {CALLER}
          <span className="done-by-value">{newDetails.caller ?? "-"}</span>
        </div>
      );
    }
    return (
      <div className="done-by">
        {DONE_BY}
        <span className="done-by-value">{createUser ? createUser : "-"}</span>
      </div>
    );
  };

  const renderTaskStatusDetails = () => {
    if (activityLogType === TaskType.CARE_PLAN_UTILIZATION) {
      const careUtilizationDetails = details as ICarePlanUtilizationLog;
      return (
        <>
          {renderDoneByUI()}

          {careUtilizationDetails.admissionDate && (
            <>
              |
              <div className="admission-date">
                Admission Date:
                <span className="admission-date-value">
                  {moment(careUtilizationDetails.admissionDate).format("MM/DD/YYYY")}
                </span>
              </div>
            </>
          )}
          {careUtilizationDetails.dischargeDate && (
            <>
              <div className="discharge-date">
                Discharge Date:
                <span className="discharge-date-value">
                  {moment(careUtilizationDetails.dischargeDate).format("MM/DD/YYYY")}
                </span>
              </div>
            </>
          )}
        </>
      );
    }
    return (
      <>
        {activityLogType !== TaskType.EPISODE_NAVIGATION_ACCEPTED &&
          activityLogType !== TaskType.EPISODE_NAVIGATION_DECLINED &&
          activityLogType !== TaskType.EPISODE_NAVIGATION_NOTES_CHANGES && (
            <>
              <div className="task-status">
                Status:
                <span className={`task-status-value ${getClassNameForStatusCallLog()}`}>
                  {status}
                  {renderTaskStatusUIMessage()}
                </span>
              </div>
              |
            </>
          )}
        {renderDoneByUI()}
      </>
    );
  };
  return (
    <div className="activity-log">
      <div className="task-name">{name ? name : "-"}</div>
      <div className="task-status-details">{renderTaskStatusDetails()}</div>
      <div className="date-time">{createDate ? renderUTCCreatedDatedInLocal() : "-"}</div>
      <ActivityHistoryDetailsColumn
        activityLogType={activityLogType}
        notes={notes}
        taskDetails={details}
        className="activity-tab-right-panel"
        calledFromTaskManagement={true}
      />
    </div>
  );
};
export default ActivityLog;
