import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAssessmentState } from "state/feature/assessment/assessment.slice";
import { getCommonState, setIsBlocking } from "state/feature/common/common.slice";
import { getNavigatorState } from "state/feature/navigator/navigator.slice";
import { getRiskAssessmentState } from "state/feature/risk-assessment/risk-assessment.slice";
import { getBrowserState } from "state/feature/task-management/task-management.slice";
import { getTOCManagementState } from "state/feature/toc-management/toc-management.slice";
import { getUserManagementState } from "state/feature/user-management/user-management.slice";

const useUrlBlocking = () => {
  const dispatch = useDispatch();
  const { isValueChanged: isRiskValueChanged } = useSelector(getRiskAssessmentState);
  const { isUserValueChanged } = useSelector(getUserManagementState);
  const { isProfileValueChanged, isCalenderValueChanged } = useSelector(getNavigatorState);
  const { isValueChanged: isAssessmentValueChanged } = useSelector(getAssessmentState);
  const { isTocValueChanged } = useSelector(getTOCManagementState);
  const { pages } = useSelector(getBrowserState);
  const { modal } = useSelector(getCommonState);

  const showUnsavedPopupWhenReload = (e: any) => {
    e.returnValue = null;
  };

  const isAnyPageDirtyOnTaskManagement = useCallback(() => {
    return pages.findIndex((p) => p.isDirty) > -1;
  }, [pages]);

  useEffect(() => {
    if (
      (isTocValueChanged ||
        isRiskValueChanged ||
        isUserValueChanged ||
        isCalenderValueChanged ||
        isAssessmentValueChanged ||
        isProfileValueChanged ||
        isAnyPageDirtyOnTaskManagement()) &&
      !modal.isOpenDelayModal
    ) {
      window.addEventListener("beforeunload", showUnsavedPopupWhenReload, true);
      dispatch(setIsBlocking(true));
    } else {
      window.removeEventListener("beforeunload", showUnsavedPopupWhenReload, true);
      dispatch(setIsBlocking(false));
    }

    return () => {
      window.removeEventListener("beforeunload", showUnsavedPopupWhenReload, true);
    };
  }, [
    isTocValueChanged,
    isRiskValueChanged,
    isUserValueChanged,
    isCalenderValueChanged,
    isAssessmentValueChanged,
    isProfileValueChanged,
    modal.isOpenDelayModal,
    isAnyPageDirtyOnTaskManagement,
    dispatch,
  ]);
};

export default useUrlBlocking;
