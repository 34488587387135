import Icon from "components/Icon/icon.component";

type RowProps = {
  name: string;
  highRisk: boolean;
  standardRisk: boolean;
};
export const Row = ({ name, highRisk, standardRisk }: RowProps) => {
  return (
    <tr>
      <td>{name}</td>
      <td>{standardRisk && <Icon icon="black-tick" size={10} />}</td>
      <td>{highRisk && <Icon icon="black-tick" size={10} />}</td>
    </tr>
  );
};
