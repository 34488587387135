export enum IntakeStatus {
  NEW = 1,
  ACTIVE,
  UNASSIGNED,
  ON_HOLD,
  DISCHARGED,
  PROCESSING = 7,
  CANCELED,
  CLOSED,
  DECEASED,
  IMPORTED,
  EXCLUDED,
  PARTIAL_INTAKE = 14,
}
