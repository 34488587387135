import { useSelector } from "react-redux";
import { getNotificationState, setIsNotificationScreenVisible } from "state/feature/notification/notification.slice";
import { useAppDispatch } from "state/store";
import { Link } from "react-router-dom";
import { MobileView } from "react-device-detect";
import { getAuth } from "state/feature/auth/auth.slice";
import Header from "components/Header/header.component";
import Icon from "components/Icon/icon.component";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import NotificationListing from "../components/notification-listing/notification-listing.component";
import "../components/notification-listing/notification-listing.styles.scss";
import "./notification-mobile.styles.scss";

const NotificationMobile = () => {
  useAxiosAuthenticated();
  const { notifications, unreadNotificationCount } = useSelector(getNotificationState);
  const authState = useSelector(getAuth);
  const appDispatch = useAppDispatch();

  return (
    <div className="notification-container">
      <MobileView>
        <Header className="notification" hideMobileMenuTrigger={true}>
          <div className="mobile-notification-title">
            <Link
              to={{
                pathname: `/dashboard`,
              }}
              onClick={() => appDispatch(setIsNotificationScreenVisible(false))}
            >
              <Icon icon="right-arrow" size={14} className="back-to-home-icon" />
            </Link>
            <span className="mobile-notification-text">
              Notifications {unreadNotificationCount > 0 ? `(${unreadNotificationCount})` : null}
            </span>
          </div>
          {notifications.length > 0 && <span className="mark-all-read-btn">Mark all as read</span>}
        </Header>
        <NotificationListing userEmail={authState.user.email} />
      </MobileView>
    </div>
  );
};

export default NotificationMobile;
