import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import {
  ICheckOnPatientGetResponse,
  ICheckOnPatientState,
  IProviderType,
  selectedCheckOnPatientType,
} from "state/types/check-on-patient.type";
import {
  getAllFacilitiesAsync,
  getCheckOnPatientDetailsAsync,
  getProviderOptionsAsync,
  getTOCOffReasonsAsync,
  saveCheckOnPatientAsync,
} from "./check-on-patient.action";
import { toast } from "react-toastify";
import { TOAST_MESSAGES } from "pages/login/common/constants";

const initialState: ICheckOnPatientState = {
  isLoading: false,
  checkOnPatientDetails: null,
  isLoadingSaveButton: false,
  isValueChange: false,
  offtrackReasonOptions: [],
  selectedCheckOnPatient: {
    serviceType: { key: "", name: "" },
    estimatedDischargeDate: null,
    actualDischargeDate: null,
    provider: { id: -1, name: "" },
    procedureDate: null,
    notes: "",
    practiceName: "",
    providerName: "",
    providerType: "",
    dayVisitActual: "",
    dayVisitCompleted: "",
    dayVisitPlanned: "",
    isOfftrack: false,
    offTrackReason: { key: "", name: "" },
    offtrackStartDate: null,
    offtrackDetails: "",
    isOfftrackDetailsEditable: true,
    dischargeFromService: false,
    peopleAssigned: [],
  },
  providerOptions: [],
  isDirty: false,
  facilities: [],
};

const checkOnPatientSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setIsDirty(state, action) {
      state.isDirty = action.payload;
    },
    setSelectCheckOnPatientValues(state, action: PayloadAction<selectedCheckOnPatientType>) {
      state.selectedCheckOnPatient = action.payload;
      state.isValueChange = true;
    },
    resetCheckOnPatientState: () => initialState,
    setCheckOnPatientStateFromMemoize(state, action) {
      return action.payload;
    },
    resetCheckOnPatientWhenChangingTab(state) {
      state.isDirty = false;
      state.isLoading = false;
      state.isLoadingSaveButton = false;
      state.isValueChange = false;
      state.selectedCheckOnPatient = { ...initialState.selectedCheckOnPatient };
    },
  },
  extraReducers: (builder) => {
    return (
      builder.addCase(getCheckOnPatientDetailsAsync.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(
        getCheckOnPatientDetailsAsync.fulfilled,
        (state, action: PayloadAction<ICheckOnPatientGetResponse | null>) => {
          if (action.payload) {
            state.checkOnPatientDetails = action.payload;
            const { tocTaskDetails, isEditable } = action.payload;
            if (tocTaskDetails) {
              state.selectedCheckOnPatient.actualDischargeDate = tocTaskDetails?.actualDischargeDate;
              state.selectedCheckOnPatient.estimatedDischargeDate = tocTaskDetails.estimatedDischargeDate;
              state.selectedCheckOnPatient.dayVisitActual = tocTaskDetails.actualDayVisit?.toString() ?? "";
              state.selectedCheckOnPatient.dayVisitCompleted = tocTaskDetails.completedDayVisit?.toString() ?? "";
              state.selectedCheckOnPatient.dayVisitPlanned = tocTaskDetails.plannedDayVisit?.toString() ?? "";
              state.selectedCheckOnPatient.serviceType = {
                key: tocTaskDetails.serviceTypeId?.toString() ?? "",
                name: tocTaskDetails.serviceTypeName ?? "",
              };
              state.selectedCheckOnPatient.provider = {
                id: tocTaskDetails.providerDetails?.id,
                name: "",
              };
              state.selectedCheckOnPatient.procedureDate = tocTaskDetails.procedureDate;
              state.selectedCheckOnPatient.isOfftrack = tocTaskDetails.offTrack ? true : false;
              state.selectedCheckOnPatient.dischargeFromService = tocTaskDetails.isDischarged ?? false;
              if (tocTaskDetails.offTrack) {
                state.selectedCheckOnPatient.offtrackDetails = tocTaskDetails.offTrack.details ?? "";
                state.selectedCheckOnPatient.offtrackStartDate = tocTaskDetails.offTrack.startDate ?? "";
                const offtrackReason = state.offtrackReasonOptions.find(
                  (offtrack) => parseInt(offtrack.key) === tocTaskDetails.offTrack?.offTrackId
                );
                state.selectedCheckOnPatient.offTrackReason = {
                  key: offtrackReason?.key ?? "",
                  name: offtrackReason?.name ?? "",
                };
              }
              state.selectedCheckOnPatient.notes = tocTaskDetails.notes ?? "";
              state.selectedCheckOnPatient.isOfftrackDetailsEditable = isEditable ?? false;
            }
          }
          state.isLoading = false;
        }
      ),
      builder.addCase(getCheckOnPatientDetailsAsync.rejected, (state, action) => {
        state.isLoading = false;
      }),
      builder.addCase(getProviderOptionsAsync.fulfilled, (state, action: PayloadAction<Array<IProviderType>>) => {
        if (action.payload) {
          state.providerOptions = action.payload;
        }
        const selectedProvider = action.payload.find(
          (provider) => state.checkOnPatientDetails?.tocTaskDetails?.providerDetails?.id === provider.ID
        );
        state.selectedCheckOnPatient.provider = {
          id: selectedProvider?.ID ?? -1,
          name: selectedProvider?.ProviderName ?? "",
        };
      }),
      builder.addCase(saveCheckOnPatientAsync.pending, (state, action) => {
        state.isLoadingSaveButton = true;
      }),
      builder.addCase(saveCheckOnPatientAsync.fulfilled, (state, action) => {
        state.isLoadingSaveButton = false;
      }),
      builder.addCase(saveCheckOnPatientAsync.rejected, (state, action) => {
        state.isLoadingSaveButton = false;
        toast.error(action.payload ?? TOAST_MESSAGES.ERROR, { containerId: "main" });
      }),
      builder.addCase(
        getTOCOffReasonsAsync.fulfilled,
        (state, action: PayloadAction<Array<{ key: string; name: string }>>) => {
          state.offtrackReasonOptions = action.payload;
          const offtrackReason =
            state.checkOnPatientDetails?.tocTaskDetails?.offTrack?.offTrackId &&
            action.payload.find(
              (reason) => reason.key === state.checkOnPatientDetails?.tocTaskDetails?.offTrack?.offTrackId?.toString()
            );
          if (offtrackReason) {
            state.selectedCheckOnPatient.offTrackReason = offtrackReason;
          }
        }
      ),
      builder.addCase(getAllFacilitiesAsync.fulfilled, (state, action) => {
        state.facilities = action.payload;
        const selectedProvider = action.payload.find(
          (provider: IProviderType) => state.selectedCheckOnPatient.provider.id === provider.ID
        );
        if (
          selectedProvider !== undefined &&
          state.selectedCheckOnPatient.provider.id !== -1 &&
          state.selectedCheckOnPatient.provider.id !== undefined
        ) {
          state.selectedCheckOnPatient.provider = {
            name: selectedProvider.ProviderName ?? "",
            id: selectedProvider.ID ?? "",
          };
        }
      })
    );
  },
});

export const {
  setIsDirty,
  setSelectCheckOnPatientValues,
  resetCheckOnPatientWhenChangingTab,
  resetCheckOnPatientState,
  setCheckOnPatientStateFromMemoize,
} = checkOnPatientSlice.actions;

export default checkOnPatientSlice;

export const getCheckOnPatientSlice = (state: any): ICheckOnPatientState => state.checkOnPatient;
