import Modal from "components/Modal/modal.component";
import Button from "components/Button/button.component";
import "./force-quit-call-modal.styles.scss";

type ForceQuitCallModalProps = {
  isOpen: boolean;
  handleCloseClick: () => void;
  handleApply: () => void;
  className?: string;
};

const ForceQuitCallModal = ({ isOpen, handleCloseClick, className, handleApply }: ForceQuitCallModalProps) => {
  return (
    <Modal isOpen={isOpen} className={`center force-quit-call-modal-container ${className ? className : ""}`}>
      <div className="force-quit-call-container">
        <div className="content-heading">End Call</div>
        <div className="content">This will end the call. Do you want to continue?</div>
        <div className="button-section">
          <Button text="No" className="white-button cancel-button" onClick={handleCloseClick} />
          <Button text="Yes" className="green-button" onClick={handleApply} />
        </div>
      </div>
    </Modal>
  );
};

export default ForceQuitCallModal;
