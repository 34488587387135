import { useState } from "react";
import Icon from "components/Icon/icon.component";
import { useHistory, useLocation } from "react-router";
import Status from "components/status/status.component";
import { getCommonState } from "state/feature/common/common.slice";
import { useSelector } from "react-redux";
import { getNavigatorAvailabilityStatus } from "shared/methods/utilityFunctions";
import { getNavigatorState } from "state/feature/navigator/navigator.slice";

const NavigatorRow = (item: any) => {
  const [isRowHovered, setIsRowHovered] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { onCallLNavigators, holidayList } = useSelector(getNavigatorState);
  const { featureFlags } = useSelector(getCommonState);

  const handleEditButtonClick = () => {
    history.push(`${location.pathname}/profile/${item.id}`, {
      pageName: `${item.id}`,
    });
    sessionStorage.setItem("navigatorCareManagerId", item.careManagerId);
    sessionStorage.setItem("navigatorProfileDetails", JSON.stringify(item));
  };
  const handleNameClick = () => {
    sessionStorage.setItem("navigatorCareManagerId", item.careManagerId);
  };

  return (
    <tr
      onMouseEnter={() => setIsRowHovered(true)}
      onMouseLeave={() => setIsRowHovered(false)}
      className={`${isRowHovered ? "hovered" : ""}`}
    >
      <td>
        {featureFlags.inboundCalls && (
          <div className={`work-hour-status`}>
            <Status status={getNavigatorAvailabilityStatus(item, holidayList, onCallLNavigators).toLowerCase()} />
          </div>
        )}
      </td>
      <td onClick={handleNameClick}>
        <div className="navigator-name-container">
          <span
            className="navigator-name-container-content"
            onClick={handleEditButtonClick}
          >{`${item.firstName} ${item.lastName}`}</span>
        </div>
      </td>
      <td>{item.numberOfPatients}</td>
      {/* above will be changed when api response is updated with assigned patient count */}
      <td>{item.email}</td>
      <td>{item.forwardingPhoneNumber}</td>
      <td>
        <div className={`icons ${isRowHovered ? "visible" : "hidden"}`}>
          <div className="icon-background edit-buttom" onClick={handleEditButtonClick}>
            <Icon icon="edit" size={12} />
          </div>
        </div>
      </td>
    </tr>
  );
};

export default NavigatorRow;
