import { HeaderProps } from "./props";
import "./header.styles.scss";
import { getCommonState, setIsOpenMobileMenuNav } from "state/feature/common/common.slice";
import { useDispatch, useSelector } from "react-redux";
import { MobileView } from "react-device-detect";
import Avatar from "components/Avatar/avatar.component";
import { getAuth } from "state/feature/auth/auth.slice";
import { Link } from "react-router-dom";
import { setIsNotificationScreenVisible } from "state/feature/notification/notification.slice";
import NotificationBadgeMobile from "pages/notification/components/notification-badge-mobile/notification-badge-mobile.component";

const Header = ({ className, children, hideMobileMenuTrigger }: HeaderProps) => {
  const { featureFlags } = useSelector(getCommonState);
  const dispatch = useDispatch();
  const handleSidePopupOpen = () => {
    dispatch(setIsOpenMobileMenuNav(true));
  };
  const authState = useSelector(getAuth);

  return (
    <div id="header" className={`${className}-header`}>
      <MobileView className={`mobile-menu-trigger ${hideMobileMenuTrigger ? "hidden" : ""}`}>
        {featureFlags.inAppNotifications && (
          <Link
            to={{
              pathname: `/notification`,
            }}
            onClick={() => dispatch(setIsNotificationScreenVisible(true))}
          >
            <NotificationBadgeMobile />
          </Link>
        )}
        <div onClick={handleSidePopupOpen}>
          <Avatar firstName={authState.user.firstName} lastName={authState.user.lastName} />
        </div>
      </MobileView>
      {children}
    </div>
  );
};

export default Header;
