import { useEffect, useRef } from "react";
import Icon from "../Icon/icon.component";
import { SearchBoxProps } from "./props";
import "./patient-search-box.styles.scss";

const PatientSearchBox = ({
  text,
  className,
  onClick,
  icon,
  onTextChange,
  placeholder = "Search By Name",
  showIcon = false,
  onBlur,
  onBlurWithIcon,
}: SearchBoxProps) => {
  const patientInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (showIcon) {
      if (patientInputRef?.current) patientInputRef.current.focus();
    }
  }, [showIcon]);

  if (icon) {
    return (
      <div className={`${className ? className : ""} icon-button`}>
        <div className="input-plus-lens">
          <Icon icon="search-lens" size={16} className="search-lens-icon" />
          <input
            className="input-field"
            type="text"
            placeholder={placeholder}
            onChange={(event) => {
              onTextChange(event.target.value);
            }}
            value={text}
            ref={patientInputRef}
            onBlur={onBlurWithIcon}
          />
        </div>
        {showIcon && (
          <div
            className="icon-section"
            onClick={() => {
              if (patientInputRef?.current) patientInputRef.current.focus();
              onClick();
            }}
          >
            <Icon icon="search-cross" size={16} className="search-cross-icon" />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={`${className ? className : ""} icon-button`}>
      <div className="input-plus-lens">
        <input
          type="SearchBox"
          className={"input-field"}
          onClick={onClick}
          value={text}
          onBlur={onBlur}
          onChange={(event) => {
            onTextChange(event.target.value);
          }}
          ref={patientInputRef}
        />
      </div>
    </div>
  );
};

export default PatientSearchBox;
