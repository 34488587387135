import React from "react";
import Modal from "../modal.component";
import { TranscriptionModalProps } from "./props";
import "./transcription-modal.styles.scss";
import ModalHeader from "components/ModalHeader/modal-header.component";

const TranscriptionModal = ({ heading, transcript, isOpen }: TranscriptionModalProps) => {
  return (
    <Modal
      modalHeader={<ModalHeader heading={heading} />}
      isOpen={isOpen}
      className="transcription-modal center"
      contentClass="transcription-modal-content"
      animationClass="transcription-modal-wrapper"
    >
      <div className="transcription-modal-content-container">
        <div>
          {transcript?.map(({ name, value }, index) => {
            return (
              <div key={index} className="transcript-line">
                <div className="name">{name}</div>
                <div className="value">{value}</div>
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

export default TranscriptionModal;
