import { useCallback, useEffect, useState, useRef } from "react";
import OtpInput from "react-otp-input";
import { HOME_CONSTANTS, OTP_CONSTANTS } from "../login/common/constants";
import LoginScreenImage from "shared/assets/images/login-screen-image.png";
import Icon from "components/Icon/icon.component";
import Button from "components/Button/button.component";
import ResendArrow from "shared/assets/images/green-resend-arrow.svg";
import "./forgot-password.styles.scss";
import { useHistory } from "react-router";
import Logo from "shared/assets/images/logo.svg";
import { toast } from "react-toastify";
import TextField from "components/TextFieldV2/text-field-v2.component";
import Header from "components/Header/header.component";
import { isEmptyString } from "shared/methods/utilityFunctions";
import moment from "moment";
import { requestSendOtpAsync, requestUpdatePasswordAsync, requestVerifyOtpAsync } from "state/feature/auth/auth.action";
import { useAppDispatch } from "state/store";
import ButtonLoader from "components/ButtonLoader/button-loader.component";
import { useSelector } from "react-redux";
import { getAuth, setError } from "state/feature/auth/auth.slice";
import { BrowserView, isMobile } from "react-device-detect";

export const ForgotPassword = () => {
  const [userName, setUserName] = useState("");
  const [seconds, setSeconds] = useState(HOME_CONSTANTS.remainingTimeSeconds);
  const [disableInput] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [otp, setOtp] = useState<string>("");
  const [showResend, setShowResend] = useState(false);
  const [passwordValue, setPasswordValue] = useState("");
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const [showVerifiedText, setShowVerifiedText] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const appDispatch = useAppDispatch();
  const [userState, setUserState] = useState<{ phoneNUmber: string; code: string; succeeded: boolean; message: "" }>({
    phoneNUmber: "",
    code: "",
    succeeded: false,
    message: "",
  });
  const authState = useSelector(getAuth);
  const prepend = (n: number) => {
    return n < OTP_CONSTANTS.OTP_Seconds_Check ? "0" + n : n;
  };

  const history = useHistory();
  const timer = useRef<NodeJS.Timeout>();
  useEffect(() => {
    timer.current = setInterval(
      () =>
        setSeconds((prev) => {
          if (prev !== 0) {
            return prev - 1;
          }
          setShowResend(true);
          return prev;
        }),
      1000
    );
    return () => clearInterval(timer.current);
  }, []);
  const handleChange = (otpValue: string) => setOtp(otpValue);

  const resetPasswordScreen = useCallback(() => {
    setShowResetPassword(true);
  }, []);

  const verifyOTP = useCallback(() => {
    appDispatch(requestVerifyOtpAsync({ verificationCode: otp, phoneNumber: userState.phoneNUmber })).then(
      ({ payload: result }) => {
        if (result.result) {
          setShowVerifiedText(true);
          setTimeout(() => {
            setIsVerified(true);
            resetPasswordScreen();
          }, 2000);
        }
      }
    );
  }, [otp, resetPasswordScreen, userState, appDispatch]);

  useEffect(() => {
    if (otp?.length === 4) {
      verifyOTP();
    }
  }, [otp, resetPasswordScreen, verifyOTP]);

  const [isPasswordSameAsOld, setIsPasswordSameAsOld] = useState<boolean>(false);

  const updatePassword = () => {
    setIsPasswordSameAsOld(false);
    appDispatch(
      requestUpdatePasswordAsync({
        userName: userName,
        password: passwordValue,
        confirmPassword: passwordValue,
        code: userState.code,
      })
    ).then(({ payload: result }) => {
      if (result && result.isValid && result.updatedItem) {
        history.push("/");
      } else if (result && !result.isValid) {
        setIsPasswordSameAsOld(true);
      }
    });
  };

  const passwordNotEmpty = passwordValue.length > 0;
  const isLengthAtLeast8 = passwordValue.length > 8;
  const containsDigit = /\d/.test(passwordValue);
  const containsUppercase = /[A-Z]/.test(passwordValue);
  const containsLowercase = /[a-z]/.test(passwordValue);

  const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  const containsSpecialCharacter = format.test(passwordValue);

  useEffect(() => {
    if (
      passwordNotEmpty &&
      isLengthAtLeast8 &&
      containsDigit &&
      containsUppercase &&
      containsLowercase &&
      containsSpecialCharacter
    ) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [
    containsDigit,
    containsLowercase,
    containsSpecialCharacter,
    containsUppercase,
    isLengthAtLeast8,
    passwordNotEmpty,
    passwordValue,
  ]);

  const callPasswordResetApi = useCallback(() => {
    if (!isEmptyString(userName) && userName.trim()) {
      appDispatch(requestSendOtpAsync(userName)).then(({ payload: result }) => {
        if (!result.succeeded) {
          toast.error(result.message, {
            toastId: "user-does-not-exist",
            containerId: "main",
          });
        }

        if (result && result.succeeded) {
          setOtp("");
          setSeconds(HOME_CONSTANTS.remainingTimeSeconds);
          setShowResend(false);
          setUserState(result);
        }
      });
    }
  }, [userName, appDispatch]);

  const [showPassword, setShowPassword] = useState(false);
  const forgotPasswordScreen = () => {
    setUserName("");
    setSeconds(HOME_CONSTANTS.remainingTimeSeconds);
    setUserState({ code: "", phoneNUmber: "", succeeded: false, message: "" });
  };
  const togglePassword = () => {
    setShowPassword((prev) => {
      const element = document.querySelector(".password-input") as HTMLInputElement;
      if (!prev) {
        element.type = "text";
      } else {
        element.type = "password";
      }
      return !prev;
    });
  };
  useEffect(() => {
    if (isEmptyString(otp as string)) {
      appDispatch(setError(false));
    }
  }, [otp, appDispatch]);
  return (
    <div className={`otpScreenContainer ${isMobile ? "mobileView" : ""}`}>
      <div className="otpFormWrapper">
        <Header className="otp" hideMobileMenuTrigger={true}>
          <img src={Logo} alt="logo" className="logo-image" />
        </Header>
        <div className="left-content">
          <div className="heading">
            <div className="reset-password-icon-container">
              <Icon icon="reset-password" className="reset-password-icon" size={15} />
            </div>
            <div className="reset-password-heading">Reset Password</div>
          </div>
          {userState.code !== "" && userState.code !== "" ? (
            <>
              <div className="otpBoxes">
                {!showResetPassword && !isVerified && (
                  <>
                    <div className="otp-line">Enter the OTP we sent to your mobile phone via SMS</div>
                    <OtpInput
                      value={otp}
                      onChange={handleChange}
                      numInputs={OTP_CONSTANTS.OTP_Length}
                      inputStyle={`inputStyling ${authState.isError ? "error" : ""} ${
                        showVerifiedText ? "verified" : ""
                      }`}
                      placeholder={OTP_CONSTANTS.OTP_Placeholder}
                      isInputNum={true}
                      isDisabled={disableInput}
                    />
                  </>
                )}
                {showResetPassword && isVerified && (
                  <>
                    <div className="reset-password-section">
                      <TextField
                        label="Password"
                        className="input-field"
                        inputClassName="password-input"
                        type="password"
                        value={passwordValue}
                        setValue={(label: string, value2: string) => setPasswordValue(value2)}
                        //
                        icon={<Icon icon="password" size={19} className="username-icon" />}
                        placeholder="Enter New Password"
                        togglePassword={togglePassword}
                        isVisiblePassword={showPassword}
                        showError={isPasswordSameAsOld}
                        errorMessage={"New password cannot be same as old password"}
                      />
                      <button
                        className={`green-button confirm-button ${!userName ? "disabled" : ""}`}
                        onClick={() => updatePassword()}
                        disabled={disableButton}
                      >
                        {authState.isLoading ? <ButtonLoader /> : <span className="login-button-screen">Confirm</span>}
                      </button>
                    </div>
                    <ul className="validations">
                      <li className={`${isLengthAtLeast8 ? "tick" : ""}`}>
                        <span>Must contain at least 8 characters</span>
                      </li>
                      <li className={`${containsDigit ? "tick" : ""}`}>
                        <span>Must contain a number</span>
                      </li>
                      <li className={`${passwordNotEmpty && containsUppercase ? "tick" : ""}`}>
                        <span>Must contain an upper-case character</span>
                      </li>
                      <li className={`${passwordNotEmpty && containsLowercase ? "tick" : ""}`}>
                        <span>Must contain a lower-case character</span>
                      </li>
                      <li className={`${containsSpecialCharacter ? "tick" : ""}`}>
                        <span>Must contain a special character i.e. @_!%</span>
                      </li>
                    </ul>
                  </>
                )}
              </div>
              {!showResetPassword && !showResend && authState.isError && <div className="error">Enter correct OTP</div>}
              {!showResetPassword && !showResend && !authState.isError && showVerifiedText && (
                <div className="verified">Verified</div>
              )}
              {!showResetPassword && !showResend ? (
                <div className="timeRemainingDiv">
                  <span className="timeRemainingSpan">
                    Code expires in{" "}
                    <span className="seconds">{`${moment.utc(prepend(seconds * 1000)).format("mm:ss")}`}</span> seconds
                  </span>
                </div>
              ) : (
                !showResetPassword &&
                showResend && (
                  <div className="button-wrapper">
                    {authState.isError ? (
                      <div className="error">Enter correct OTP</div>
                    ) : showVerifiedText ? (
                      <div className="verified">Verified</div>
                    ) : (
                      <div />
                    )}
                    <div className="button-container">
                      <Button
                        text="Resend OTP"
                        onClick={callPasswordResetApi}
                        className="no-border-button resend-otp"
                        imgChildren={<img src={ResendArrow} alt="resend arrow" />}
                      />
                    </div>
                  </div>
                )
              )}
              {!isVerified && !showResetPassword && (
                <div className="button-container ">
                  <div className="img-button-container">
                    <input
                      type="button"
                      className="no-border-button another-username"
                      onClick={forgotPasswordScreen}
                      value="Try Different Username"
                    />
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              <TextField
                label="User Name"
                className="input-field"
                inputClassName="input"
                inputContainerClassName="login-input-container"
                type="text"
                value={userName}
                setValue={(label: string, userNameValue: string) => {
                  setUserName(userNameValue);
                }}
                icon={<Icon icon="username" size={16} className="username-icon" />}
                placeholder="Enter your username"
              />
              <div className="login-button-wrapper">
                <Button
                  text="Back to Login"
                  onClick={() => history.replace("/")}
                  className="forgot-password no-border-button icon-button"
                />
                <div className="login-button-container">
                  <button
                    className={`login-button ${!userName ? "disabled" : ""}`}
                    onClick={callPasswordResetApi}
                    disabled={userName === ""}
                  >
                    {authState.isLoading ? <ButtonLoader /> : <span className="login-button-screen">Send OTP</span>}
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <BrowserView className="rightImageBlock">
        <img src={LoginScreenImage} alt="login screen" className="login-screen-image"></img>
        <div className="big-text">
          <div className="line1">Manage Your Patients</div>
          <div className="line2">Effortlessly</div>
        </div>
      </BrowserView>
    </div>
  );
};
