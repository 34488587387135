import SingleDatePicker from "components/single-date-picker/single-date-picker.component";
import TextField from "components/TextField/text-field.component";
import CustomDropDown from "components/CustomDropdown/custom-dropdown.component";
import "./patient-demographics.style.scss";
import { useEffect, useRef, useState } from "react";
import Toggle from "components/Toggle/toggle.component";
import RadioInputGroup from "components/RadioInputGroup/radio-input-group.component";
import { countryList, genderDropdownItems, prefferedPhoneType } from "shared/constant/commonConstants";
import Checkbox from "components/Checkbox/checkbox.component";
import FooterBar from "./components/footer-bar/footer-bar.component";
import {
  DropdownitemValues,
  DropdownitemValuesStringKey,
  ErrorFieldNames,
  ErrorMessages,
  FormFieldDetails,
} from "./constants";
import {
  DropdownItemType,
  FormFieldTypes,
  DropdownItemTypeStringkey,
  ComponentProps,
} from "./patient-demographics.props";
import {
  areStringsEqualIgnoreCase,
  hasSpecialCharacter,
  isDobValid,
  iscontainOnlyNum,
  validateEmail,
} from "shared/methods/utilityFunctions";
import {
  getDateFormat,
  getLastTendigits,
  isAddressValid,
  isCityValid,
  isContainWhiteSpace,
  isEmptyString,
  isMbiValid,
  isPhoneNumberValid,
  isZipcodeValid,
} from "shared/methods/taskSpecificMethods";

import useFooterVisibility from "shared/hooks/use-footer-visibility";
import { useSelector } from "react-redux";
import { useAppDispatch } from "state/store";
import { DemoGraphicResponse } from "state/types/episodes-slice.type";
import {
  clearDuplicateErrors,
  getEpisodesSlice,
  setIsPatientDemographicValueChanged,
} from "state/feature/episodes/episodes.slice";
import CommonState from "components/common-state/common-state.component";
import { CommonStateType } from "components/common-state/common-state.enum";

const PatientDemographicsPresentation = (props: ComponentProps) => {
  const { isBtnLoading, demographicDetails, duplicateDataErrors, saveChanges, dropdownData, isLoading, isValueChange } =
    props;

  // useFooterVisibility();
  const appDispatch = useAppDispatch();
  const prefPhoneRef = useRef("");
  const patientEpisodeStatusRef = useRef<HTMLDivElement | null>(null);
  const isDuplicateErrorExist = Array.isArray(duplicateDataErrors) && duplicateDataErrors.length;
  const {
    coachRelationshipList = [],
    pcpPracticeList = [],
    primaryCarePhysicianList = [],
    maritalStatusList = [],
  } = dropdownData || {};
  const [isFormUpdated, setIsFormUpdated] = useState(false);
  const [formData, setFormData] = useState<FormFieldTypes>(JSON.parse(JSON.stringify(FormFieldDetails)));

  const {
    mbi,
    mbiEffectiveDate,
    mrn,
    pFirstName,
    pMiddlename,
    pLastName,
    suffixName,
    preferredName,
    externalID,
    gender,
    dateOfBirth,
    maritalStatus,
    primaryCarePhysician,
    pcpPractice,
    mobilePhone,
    homePhone,
    preferredPhone,
    address,
    city,
    state,
    zipCode,
    email,
    emergencyContactName,
    emergencyContactNumber,
    coachName,
    coachRelationship,
    coachEmailId,
    coachNumber,
    consentCoachContact,
  } = formData;

  const addtoFields = () => {
    if (demographicDetails) {
      setIsFormUpdated(true);
      formData.id = demographicDetails.id;
      formData.mbi.value = demographicDetails.mbi;
      formData.mbiEffectiveDate.value = demographicDetails.mbiEffectiveDate
        ? new Date(demographicDetails.mbiEffectiveDate)
        : null;
      formData.mrn.value = demographicDetails.mrn ?? "";
      formData.pFirstName.value = demographicDetails.firstName ?? "";
      formData.pMiddlename.value = demographicDetails.middleName ?? "";
      formData.pLastName.value = demographicDetails.lastName ?? "";
      formData.suffixName.value = demographicDetails.suffix ?? "";
      formData.preferredName.value = demographicDetails.preferredName ?? "";
      formData.externalID.value = demographicDetails.externalId ?? "";
      formData.dateOfBirth.value = demographicDetails.dob ? new Date(demographicDetails.dob) : null;
      formData.mobilePhone.value = getLastTendigits(demographicDetails.phoneCell) ?? "";
      formData.homePhone.value = getLastTendigits(demographicDetails.phoneHome) ?? "";
      formData.address.value = demographicDetails.address ?? "";
      formData.city.value = demographicDetails.city ?? "";
      formData.zipCode.value = demographicDetails.zip ?? "";
      formData.email.value = demographicDetails.email ?? "";
      formData.emergencyContactName.value = demographicDetails.emergencyContactName ?? "";
      formData.emergencyContactNumber.value = getLastTendigits(demographicDetails.phoneEmergency) ?? "";
      formData.coachName.value = demographicDetails.coachName ?? "";
      formData.coachEmailId.value = demographicDetails.coachEmail ?? "";
      formData.coachName.value = demographicDetails.coachName ?? "";
      formData.coachNumber.value = getLastTendigits(demographicDetails.coachPhoneCell) ?? "";
      formData.consentCoachContact.value = demographicDetails.consentToContactCoach;
      formData.preferredPhone.value.forEach((item) => {
        const flag = item.id === demographicDetails.preferredPhone + "";
        if (flag) prefPhoneRef.current = item.text;
        item.checked = flag;
      });
      formData.gender.value =
        (genderDropdownItems.find((item) => item.key === demographicDetails.genderId) as DropdownItemType) ??
        DropdownitemValues;
      formData.maritalStatus.value =
        (maritalStatusList.find((item) => item.key === demographicDetails.maritalStatus) as DropdownItemType) ??
        DropdownitemValues;
      formData.state.value =
        (countryList.find(
          (item) => item.key === demographicDetails.state || item.name === demographicDetails.state
        ) as DropdownItemTypeStringkey) ?? DropdownitemValuesStringKey;

      formData.coachRelationship.value =
        (coachRelationshipList.find(
          (item) => item.key === demographicDetails.coachReleationshipId
        ) as DropdownItemType) ?? DropdownitemValues;
      formData.coachRelationship.value =
        (coachRelationshipList.find(
          (item) => item.key === demographicDetails.coachReleationshipId
        ) as DropdownItemType) ?? DropdownitemValues;
      formData.pcpPractice.value =
        (pcpPracticeList.find(
          (item: DropdownItemType) => item.key === demographicDetails.practiceId
        ) as DropdownItemType) ?? DropdownitemValues;
      formData.primaryCarePhysician.value =
        (primaryCarePhysicianList.find(
          (item) => item.key === demographicDetails.primaryCarePhysicianId
        ) as DropdownItemType) ?? DropdownitemValues;
      setFormData({ ...formData });
    }
  };

  const updateform = (params: FormFieldTypes) => {
    setFormData({ ...params });
    if (!isValueChange) appDispatch(setIsPatientDemographicValueChanged(true));
  };

  const validateForm = () => {
    let flag = true;
    const isMobilePrefered = prefPhoneRef.current === prefferedPhoneType.MOBILE.text;
    const isHomePrefered = prefPhoneRef.current === prefferedPhoneType.HOME.text;
    const isMobilePhoneValid = isPhoneNumberValid(mobilePhone.value);
    const isHomePhoneValid = isPhoneNumberValid(homePhone.value);

    if (isMobilePrefered && !isMobilePhoneValid) [mobilePhone.isValid, flag] = [false, false];
    else if (!isEmptyString(mobilePhone.value) && !isMobilePhoneValid) [mobilePhone.isValid, flag] = [false, false];

    if (isHomePrefered && !isHomePhoneValid) [homePhone.isValid, flag] = [false, false];
    else if (!isEmptyString(homePhone.value) && !isHomePhoneValid) [homePhone.isValid, flag] = [false, false];

    if (!isMbiValid(mbi.value)) [mbi.isValid, flag] = [false, false];
    if (isEmptyString(pFirstName.value)) [pFirstName.isValid, flag] = [false, false];
    if (isEmptyString(pLastName.value)) [pLastName.isValid, flag] = [false, false];
    if (isEmptyString(gender.value.name)) [gender.isValid, flag] = [false, false];
    if (!isDobValid(dateOfBirth.value)) [dateOfBirth.isValid, flag] = [false, false];
    if (!preferredPhone.value.some((item) => item.checked)) [preferredPhone.isValid, flag] = [false, false];
    if (isEmptyString(address.value) || !isAddressValid(address.value)) [address.isValid, flag] = [false, false];
    if (isEmptyString(city.value) || !isCityValid(city.value)) [city.isValid, flag] = [false, false];
    if (isEmptyString(state.value.name)) [state.isValid, flag] = [false, false];
    if (isEmptyString(zipCode.value) || !isZipcodeValid(zipCode.value)) [zipCode.isValid, flag] = [false, false];
    if (!isEmptyString(emergencyContactNumber.value) && !isPhoneNumberValid(emergencyContactNumber.value))
      [emergencyContactNumber.isValid, flag] = [false, false];
    if (!isEmptyString(email.value) && !validateEmail(email.value)) [email.isValid, flag] = [false, false];
    if (!isEmptyString(coachNumber.value) && !isPhoneNumberValid(coachNumber.value))
      [coachNumber.isValid, flag] = [false, false];
    if (!isEmptyString(coachEmailId.value) && !validateEmail(coachEmailId.value))
      [coachEmailId.isValid, flag] = [false, false];
    updateform(formData);
    return flag;
  };

  const submitForm = () => {
    if (demographicDetails && validateForm()) {
      const data: any = { ...demographicDetails };
      const keys = Object.keys(data);
      for (const key of keys) {
        const keyType = key as keyof DemoGraphicResponse;
        const value = data[keyType];
        if (typeof value === "string" && isEmptyString(value)) data[keyType] = null;
      }
      appDispatch(setIsPatientDemographicValueChanged(false));
      saveChanges(data);
    }
  };

  const getMBIErrorMessage = () => {
    if (isDuplicateErrorExist)
      return duplicateDataErrors.find((item) => areStringsEqualIgnoreCase(item.field, ErrorFieldNames.MBI))?.message;
    if (hasSpecialCharacter(mbi.value)) {
      return ErrorMessages.SPECIAL_CHAR_NOT_ALLOWED;
    }
    if (!mbi.isValid) {
      return ErrorMessages.ENTER_MBI_SAME_AS_MEDICARE;
    }
  };

  const getDobErrorMessage = () => {
    if (!!dateOfBirth.value) {
      const year = new Date(dateOfBirth.value).getFullYear();
      return year < 1900 ? ErrorMessages.DOBMUSTBE1900 : ErrorMessages.DOBMUSTBE18;
    }
    return ErrorMessages.DOBREQUIRED;
  };

  const getEmailError = () =>
    isDuplicateErrorExist
      ? duplicateDataErrors.find((item) => areStringsEqualIgnoreCase(item.field, ErrorFieldNames.EMAIL))?.message
      : ErrorMessages.ENTER_VALID_EMAIL;

  const setMBIValue = (_: string, value: string) => {
    if (duplicateDataErrors?.length) appDispatch(clearDuplicateErrors([]));
    formData.mbi.value = value;
    formData.mbi.isValid = true;
    if (demographicDetails) demographicDetails.mbi = value;
    updateform(formData);
  };

  const setEffectiveMBIDate = (value: Date | null) => {
    formData.mbiEffectiveDate.value = value;
    formData.mbiEffectiveDate.isValid = true;
    if (demographicDetails) demographicDetails.mbiEffectiveDate = value ? getDateFormat(value.toString()) : "";
    updateform(formData);
  };
  const setMRN = (_: string, value: string) => {
    formData.mrn.value = value;
    if (demographicDetails) demographicDetails.mrn = value;
    updateform(formData);
  };

  const setFirstName = (_: string, value: string) => {
    formData.pFirstName.value = value;
    formData.pFirstName.isValid = true;
    if (demographicDetails) demographicDetails.firstName = value;
    updateform(formData);
  };

  const setMiddleName = (_: string, value: string) => {
    formData.pMiddlename.value = value;
    if (demographicDetails) demographicDetails.middleName = value;
    updateform(formData);
  };

  const setLastName = (_: string, value: string) => {
    formData.pLastName.value = value;
    formData.pLastName.isValid = true;
    if (demographicDetails) demographicDetails.lastName = value;
    updateform(formData);
  };
  const seSuffixName = (_: string, value: string) => {
    formData.suffixName.value = value;
    if (demographicDetails) demographicDetails.suffix = value;
    updateform(formData);
  };

  const setPreferredName = (_: string, value: string) => {
    formData.preferredName.value = value;
    if (demographicDetails) demographicDetails.preferredName = value;
    updateform(formData);
  };
  const setExternalID = (_: string, value: string) => {
    formData.externalID.value = value;
    if (demographicDetails) demographicDetails.externalId = value;
    updateform(formData);
  };

  const setGenderItem = (value: any) => {
    formData.gender.value = value;
    formData.gender.isValid = true;
    if (demographicDetails) demographicDetails.genderId = value.key;
    updateform(formData);
  };

  const setDOBDate = (value: Date) => {
    formData.dateOfBirth.value = value;
    formData.dateOfBirth.isValid = true;
    if (demographicDetails) demographicDetails.dob = value ? getDateFormat(value.toString()) : "";
    updateform(formData);
  };

  const setMaritalStatus = (value: any) => {
    formData.maritalStatus.value = value;
    if (demographicDetails) demographicDetails.maritalStatus = value.key;
    updateform(formData);
  };

  const setPrimaryCarePhysician = (value: any) => {
    formData.primaryCarePhysician.value = value;
    formData.primaryCarePhysician.isValid = true;
    if (demographicDetails) demographicDetails.primaryCarePhysicianId = value.key;
    updateform(formData);
  };

  const setPCPPractice = (value: any) => {
    formData.pcpPractice.value = value;
    formData.pcpPractice.isValid = true;
    if (demographicDetails) demographicDetails.practiceId = value.key;
    updateform(formData);
  };

  const setMobilePhone = (_: string, value: string) => {
    if (!isContainWhiteSpace(value) && iscontainOnlyNum(value)) {
      formData.mobilePhone.value = value;
      formData.mobilePhone.isValid = true;
      if (demographicDetails) demographicDetails.phoneCell = !isEmptyString(value) ? `+1${value}` : "";
    } else formData.mobilePhone.value = "";
    updateform(formData);
  };

  const setHomePhone = (_: string, value: string) => {
    if (!isContainWhiteSpace(value) && iscontainOnlyNum(value)) {
      formData.homePhone.value = value;
      formData.homePhone.isValid = true;
      if (demographicDetails) demographicDetails.phoneHome = !isEmptyString(value) ? `+1${value}` : "";
    } else formData.homePhone.value = "";
    updateform(formData);
  };

  const setPreferedNumberStatus = (id: string | number) => {
    const prefPhone = [...preferredPhone.value];
    prefPhone.forEach((item) => {
      const flag = item.id === id;
      if (flag) prefPhoneRef.current = item.text;
      item.checked = flag;
    });
    formData.preferredPhone.value = prefPhone;
    formData.preferredPhone.isValid = true;
    formData.homePhone.isValid = true;
    formData.mobilePhone.isValid = true;
    if (demographicDetails) demographicDetails.preferredPhone = parseInt(id + "");
    updateform(formData);
  };

  const setAddress = (_: string, value: string) => {
    formData.address.value = value;
    formData.address.isValid = true;
    if (demographicDetails) demographicDetails.address = value;
    updateform(formData);
  };

  const setCity = (_: string, value: string) => {
    formData.city.value = value;
    formData.city.isValid = true;
    if (demographicDetails) demographicDetails.city = value;
    updateform(formData);
  };

  const setCountry = (value: any) => {
    formData.state.value = value;
    formData.state.isValid = true;
    if (demographicDetails) demographicDetails.state = value.key;
    updateform(formData);
  };

  const setzipCode = (_: string, value: string) => {
    if (!isContainWhiteSpace(value) && iscontainOnlyNum(value)) {
      formData.zipCode.value = value;
      formData.zipCode.isValid = true;
      if (demographicDetails) demographicDetails.zip = value;
    } else formData.zipCode.value = "";
    updateform(formData);
  };

  const setEmail = (_: string, value: string) => {
    if (duplicateDataErrors?.length) appDispatch(clearDuplicateErrors([]));
    formData.email.value = value;
    formData.email.isValid = true;
    if (demographicDetails) demographicDetails.email = value;
    updateform(formData);
  };

  const setEmergencyContactName = (_: string, value: string) => {
    formData.emergencyContactName.value = value;
    if (demographicDetails) demographicDetails.emergencyContactName = value;
    updateform(formData);
  };

  const setEmergencyContactNumber = (_: string, value: string) => {
    if (!isContainWhiteSpace(value) && iscontainOnlyNum(value)) {
      formData.emergencyContactNumber.value = value;
      formData.emergencyContactNumber.isValid = true;
      if (demographicDetails) demographicDetails.phoneEmergency = !isEmptyString(value) ? `+1${value}` : "";
    } else formData.emergencyContactNumber.value = "";
    updateform(formData);
  };

  const setCoachName = (_: string, value: string) => {
    formData.coachName.value = value;
    if (demographicDetails) demographicDetails.coachName = value;
    updateform(formData);
  };

  const setCoachRelationship = (value: any) => {
    formData.coachRelationship.value = value;
    if (demographicDetails) demographicDetails.coachReleationshipId = value.key;
    updateform(formData);
  };

  const setCoachEmail = (_: string, value: string) => {
    formData.coachEmailId.value = value;
    formData.coachEmailId.isValid = true;
    if (demographicDetails) demographicDetails.coachEmail = value;
    updateform(formData);
  };

  const setCoachNumber = (_: string, value: string) => {
    if (!isContainWhiteSpace(value) && iscontainOnlyNum(value)) {
      formData.coachNumber.value = value;
      formData.coachNumber.isValid = true;
      if (demographicDetails) demographicDetails.coachPhoneCell = !isEmptyString(value) ? `+1${value}` : "";
    } else formData.coachNumber.value = "";
    updateform(formData);
  };
  const setConsentCoachcontact = () => {
    const value = !consentCoachContact.value;
    formData.consentCoachContact.value = value;
    if (demographicDetails) demographicDetails.consentToContactCoach = value;
    updateform(formData);
  };

  useEffect(() => {
    if (isDuplicateErrorExist) {
      for (const item of duplicateDataErrors) {
        if (areStringsEqualIgnoreCase(item.field, ErrorFieldNames.EMAIL)) formData.email.isValid = false;
        else if (areStringsEqualIgnoreCase(item.field, ErrorFieldNames.MBI)) formData.mbi.isValid = false;
      }
      updateform(formData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duplicateDataErrors]);

  useEffect(() => {
    const isDemographicDetaisExist = demographicDetails ? Object.keys(demographicDetails).length : false;

    if (isDemographicDetaisExist && !isFormUpdated) {
      console.log("....", demographicDetails);

      addtoFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demographicDetails, dropdownData]);

  return (
    <div id="episode-details-patient-demographic">
      {isLoading ? (
        <CommonState type={CommonStateType.LOADING} className="demographic-loader" />
      ) : (
        <div className="task-container-tab patient-demographic-container">
          <p className="title title-margin">Personal Details</p>
          <div className="patient-demographic-row-container">
            <div className="patient-demographic-wrapper">
              <TextField
                label="MBI"
                type="text"
                value={mbi.value}
                placeholder="XXXXXXXXX"
                isRequired
                setValue={setMBIValue}
                showError={!mbi.isValid}
                errorMessage={getMBIErrorMessage()}
              />
            </div>
            <div className="patient-demographic-wrapper">
              <div className="label">MBI Effective Date</div>
              <SingleDatePicker
                defaultDate={mbiEffectiveDate?.value}
                minDate={null}
                maxDate={new Date()}
                isError={!mbiEffectiveDate.isValid}
                handleDateChange={setEffectiveMBIDate}
                isDisabled={false}
                popperPlacement="bottom"
              />
            </div>
            <div className="patient-demographic-wrapper">
              <TextField
                label="Medical Record Number (MRN)"
                type="text"
                value={mrn.value}
                setValue={setMRN}
                maxLength={50}
                placeholder="XXXXXXXX"
                errorMessage={ErrorMessages.MRN}
                showError={!mrn.isValid}
              />
            </div>
          </div>
          <div className="patient-demographic-row-container">
            <div className="patient-demographic-wrapper">
              <TextField
                label="Patient First Name"
                type="text"
                value={pFirstName.value}
                placeholder="First Name"
                maxLength={20}
                isRequired={true}
                setValue={setFirstName}
                showError={!pFirstName.isValid}
                errorMessage={ErrorMessages.FIRST_NAME_REQUIRED}
              />
            </div>
            <div className="patient-demographic-wrapper">
              <TextField
                label="Patient Middle Name"
                type="text"
                value={pMiddlename.value}
                placeholder="Middle Name"
                maxLength={20}
                setValue={setMiddleName}
              />
            </div>
            <div className="patient-demographic-wrapper">
              <TextField
                label="Patient Last Name"
                type="text"
                value={pLastName.value}
                setValue={setLastName}
                placeholder="Last Name"
                errorMessage={ErrorMessages.LAST_NAME_REQUIRED}
                showError={!pLastName.isValid}
                isRequired={true}
                maxLength={30}
              />
            </div>
          </div>
          <div className="patient-demographic-row-container">
            <div className="patient-demographic-wrapper">
              <TextField
                label="Suffix Name"
                type="text"
                value={suffixName.value}
                placeholder="Suffix Name"
                maxLength={50}
                setValue={seSuffixName}
              />
            </div>
            <div className="patient-demographic-wrapper">
              <TextField
                label="Preferred Name"
                type="text"
                value={preferredName.value}
                placeholder="Preferred Name"
                maxLength={30}
                setValue={setPreferredName}
              />
            </div>
            <div className="patient-demographic-wrapper">
              <TextField
                label="External ID"
                type="text"
                value={externalID.value}
                setValue={setExternalID}
                placeholder="External ID"
                errorMessage={ErrorMessages.LENGTH_SHOULD_BE_30}
                showError={false}
                maxLength={30}
              />
            </div>
          </div>
          <div className="patient-demographic-row-container">
            <div className="patient-demographic-wrapper">
              <div className="dropdown-container">
                <div className="label">
                  Gender
                  <span className="red-color-asterisk">*</span>
                </div>
                <CustomDropDown
                  placeholder="Male"
                  value={gender.value}
                  dropDownMenuItems={genderDropdownItems}
                  handleValueChanges={setGenderItem}
                  dropDownContainerClass="dropdown-container"
                  isDisabled={false}
                  isError={!gender.isValid}
                  message={ErrorMessages.PLEASE_SELECT_DROPDOWN}
                  idFieldName="name"
                  activeOptionBasedOnValue={true}
                />
              </div>
            </div>
            <div className="patient-demographic-wrapper">
              <div className="label">
                Date of Birth
                <span className="red-color-asterisk">*</span>
              </div>
              <SingleDatePicker
                defaultDate={dateOfBirth.value}
                minDate={null}
                maxDate={new Date()}
                isError={!dateOfBirth.isValid}
                errorMessage={getDobErrorMessage()}
                isDisabled={false}
                handleDateChange={setDOBDate}
                popperPlacement="bottom"
              />
            </div>
            <div className="patient-demographic-wrapper">
              <div className="label">Marital Status</div>
              <CustomDropDown
                placeholder="Marital Status"
                value={maritalStatus.value}
                dropDownMenuItems={maritalStatusList}
                handleValueChanges={setMaritalStatus}
                isDisabled={false}
                isError={!maritalStatus.isValid}
                message={ErrorMessages.PLEASE_SELECT_DROPDOWN}
                idFieldName="name"
                activeOptionBasedOnValue={true}
              />
            </div>
          </div>
          <div className="patient-demographic-row-container margin-top-row">
            <div className="patient-demographic-wrapper">
              <div className="label">Primary Care Physician</div>
              <CustomDropDown
                placeholder="Primary Care Physician"
                value={primaryCarePhysician.value}
                dropDownMenuItems={primaryCarePhysicianList}
                handleValueChanges={setPrimaryCarePhysician}
                isDisabled={false}
                isError={!primaryCarePhysician.isValid}
                message={ErrorMessages.PLEASE_SELECT_DROPDOWN}
                idFieldName="name"
                activeOptionBasedOnValue={true}
              />
            </div>
            <div className="patient-demographic-wrapper">
              <div className="label">PCP Practice</div>
              <CustomDropDown
                placeholder="PCP Practice"
                value={pcpPractice.value}
                dropDownMenuItems={pcpPracticeList}
                handleValueChanges={setPCPPractice}
                isDisabled={false}
                isError={!pcpPractice.isValid}
                message={ErrorMessages.PLEASE_SELECT_DROPDOWN}
                idFieldName="name"
                activeOptionBasedOnValue={true}
              />
            </div>
            <div className="patient-demographic-wrapper" />
          </div>
          <p className="title">Contact Details</p>
          <div className="patient-demographic-row-container">
            <div className="patient-demographic-wrapper">
              <TextField
                label="Mobile Phone"
                type="text"
                value={mobilePhone.value}
                placeholder="Mobile Phone"
                className="phone-container-content"
                isPhoneNumber
                maxLength={10}
                isRequired={prefPhoneRef.current === prefferedPhoneType.MOBILE.text}
                errorMessage={ErrorMessages.PLEASE_ENTER_VALID_PHONE}
                showError={!mobilePhone.isValid}
                setValue={setMobilePhone}
              />
            </div>
            <div className="patient-demographic-wrapper">
              <TextField
                label="Home Phone"
                type="text"
                value={homePhone.value}
                placeholder="Home Phone"
                maxLength={10}
                isPhoneNumber
                setValue={setHomePhone}
                isRequired={prefPhoneRef.current === prefferedPhoneType.HOME.text}
                errorMessage={ErrorMessages.PLEASE_ENTER_VALID_PHONE}
                showError={!homePhone.isValid}
              />
            </div>
            <div className="patient-demographic-wrapper">
              <RadioInputGroup
                labelText="Preferred Calling Number"
                errorMessage={ErrorMessages.PLEASE_ENTER_VALID_PREFER}
                showError={!preferredPhone.isValid}
                shouldDisplayErroronDisabled
                radioList={preferredPhone.value}
                handleInputChange={setPreferedNumberStatus}
                className="episode-care"
                required
              />
            </div>
          </div>
          <div className="patient-demographic-row-container">
            <div className="patient-demographic-wrapper">
              <TextField
                label="Address"
                type="text"
                value={address.value}
                placeholder="Address"
                maxLength={95}
                isRequired
                setValue={setAddress}
                errorMessage={ErrorMessages.LENGHT_SHOULD_BE_3_95}
                showError={!address.isValid}
              />
            </div>
            <div className="patient-demographic-wrapper">
              <TextField
                label="City"
                type="text"
                value={city.value}
                placeholder="City"
                maxLength={85}
                isRequired
                setValue={setCity}
                errorMessage={ErrorMessages.LENGHT_SHOULD_BE_3_85}
                showError={!city.isValid}
              />
            </div>
            <div className="patient-demographic-wrapper">
              <div className="label">
                State
                <span className="red-color-asterisk">*</span>
              </div>
              <CustomDropDown
                placeholder="State"
                dropDownMenuItems={countryList}
                handleValueChanges={setCountry}
                value={state.value}
                isDisabled={false}
                isError={!state.isValid}
                message={ErrorMessages.PLEASE_SELECT_DROPDOWN}
                idFieldName="name"
                activeOptionBasedOnValue={true}
              />
            </div>
          </div>
          <div className="patient-demographic-row-container">
            <div className="patient-demographic-wrapper">
              <TextField
                label="Zip Code"
                type="text"
                value={zipCode.value}
                placeholder="Zip Code"
                maxLength={5}
                isRequired
                setValue={setzipCode}
                errorMessage={ErrorMessages.LENGHT_SHOULD_BE_5}
                showError={!zipCode.isValid}
              />
            </div>
            <div className="patient-demographic-wrapper">
              <TextField
                label="Email"
                type="text"
                value={email.value}
                placeholder="Email"
                maxLength={100}
                setValue={setEmail}
                errorMessage={getEmailError()}
                showError={!email.isValid}
              />
            </div>
            <div className="patient-demographic-wrapper" />
          </div>
          <div className="patient-demographic-row-container">
            <div className="patient-demographic-wrapper">
              <TextField
                label="Emergency Contact Name"
                type="text"
                value={emergencyContactName.value}
                placeholder="Emergency Contact Name"
                maxLength={30}
                setValue={setEmergencyContactName}
              />
            </div>
            <div className="patient-demographic-wrapper">
              <TextField
                label="Emergency Contact Number"
                type="text"
                value={emergencyContactNumber.value}
                placeholder="Emergency Contact Number"
                maxLength={10}
                isPhoneNumber
                setValue={setEmergencyContactNumber}
                errorMessage={ErrorMessages.PLEASE_ENTER_VALID_PHONE}
                showError={!emergencyContactNumber.isValid}
              />
            </div>
            <div className="patient-demographic-wrapper" />
          </div>
          <p className="title coach-details">Coach Details</p>
          <div className="patient-demographic-row-container">
            <div className="patient-demographic-wrapper">
              <TextField
                label="Coach Name"
                type="text"
                value={coachName.value}
                placeholder="Coach Name"
                maxLength={30}
                setValue={setCoachName}
              />
            </div>
            <div className="patient-demographic-wrapper">
              <div className="label">Coach Relationship</div>
              <CustomDropDown
                placeholder="Coach Relationship"
                value={coachRelationship.value}
                dropDownMenuItems={coachRelationshipList}
                handleValueChanges={setCoachRelationship}
                isDisabled={false}
                isError={!coachRelationship.isValid}
                message={ErrorMessages.PLEASE_SELECT_DROPDOWN}
                idFieldName="name"
                activeOptionBasedOnValue={true}
                dropdownRef={patientEpisodeStatusRef}
              />
            </div>
            <div className="patient-demographic-wrapper">
              <TextField
                label="Coach Email"
                type="text"
                value={coachEmailId.value}
                placeholder="Coach Email"
                maxLength={30}
                setValue={setCoachEmail}
                errorMessage={ErrorMessages.ENTER_VALID_EMAIL}
                showError={!coachEmailId.isValid}
              />
            </div>
          </div>
          <div className="patient-demographic-row-container">
            <div className="patient-demographic-wrapper">
              <TextField
                label="Coach Number"
                type="text"
                value={coachNumber.value}
                placeholder="Coach Number"
                maxLength={10}
                isPhoneNumber
                setValue={setCoachNumber}
                errorMessage={ErrorMessages.PLEASE_ENTER_VALID_PHONE}
                showError={!coachNumber.isValid}
              />
            </div>
            <div className="patient-demographic-wrapper consent-coach">
              <Checkbox
                id="patient"
                value="Consent to contact coach"
                name="patient"
                isChecked={consentCoachContact.value}
                onChange={setConsentCoachcontact}
              />
            </div>
            <div className="patient-demographic-wrapper" />
          </div>
          <FooterBar
            isDisabled={!isValueChange}
            isLoading={isBtnLoading}
            saveBtnLabel="Save Changes"
            onClickSave={submitForm}
          />
        </div>
      )}
    </div>
  );
};

export default PatientDemographicsPresentation;
