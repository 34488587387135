import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProcedureDetailsPresentation from "./procedure-details.component";
import {
  getEpisodesSlice,
  setEpisodeDetails,
  setIsDirty,
  setPatientEpisodeDetails,
  setProcedureDetails,
  updateProcedureDetailsValues,
} from "state/feature/episodes/episodes.slice";
import {
  getAllEpisodesAsync,
  getAllFacilitiesAsync,
  getAllPracticesAsync,
  getAllProgramsAsync,
  getAllSurgeonsAsync,
  getPreferredPhoneNumberIdAsync,
  getProvidersReferrerListAsync,
  getSurgerySiteSideAsync,
  getUpdatedEpisodeDetailsById,
  updateEpisodeAsync,
} from "state/feature/episodes/episodes.action";
import { useAppDispatch } from "state/store";
import {
  RadioButtonListType,
  SURGEON_EPISODE_DETAILS_FORM,
  episodeCareRadioList,
  episodeCareType,
  episodePlanType,
  episodeSettingRadioList,
  episodeSettingType,
  episodeTypeRadioList,
} from "shared/constant/commonConstants";
import { useParams } from "react-router";
import { createPatientEpisodeDetailsData, getPatientFullName, isEmptyString } from "shared/methods/utilityFunctions";
import {
  isCcnValid,
  isCptValid,
  isProcedureDescriptionValid,
  isSurgeryDateValid,
} from "shared/methods/taskSpecificMethods";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import "./procedure-details.styles.scss";
import {
  GetProvidersReferrerListResponse,
  IEpisodeDetails,
  IProcedureDetailType,
  IUpdateEpisodeRequestPayload,
  InputType,
} from "state/types/episodes-slice.type";
import moment from "moment";
import { getBackUpNavigatorListAsync } from "state/feature/navigator/navigator.action";
import { getNavigatorState } from "state/feature/navigator/navigator.slice";
import {
  HandlePatientCareManagerProp,
  ProcedureDetailsDropdownValueProp,
  SurgeonsProps,
} from "./procedure-details.props";
import { toast } from "react-toastify";
import { ItemType } from "components/single-select-dropdown-with-clear-option/single-select-dropdown-with-clear-option.props";
import { PayloadAction } from "@reduxjs/toolkit";
import { INavigator } from "state/types/navigator.type";

const ProcedureDetails = () => {
  const dispatch = useDispatch();
  useAxiosAuthenticated();
  const { intakeId } = useParams<{ intakeId: string }>();
  const appDispatch = useAppDispatch();
  const [isValueSetForFirstTime, setIsValueSetForFirstTime] = useState(false);
  const [episodeSettingInputs, setEpisodeSettingInputs] = useState<Array<RadioButtonListType>>(episodeSettingRadioList);
  const [episodeTypeInputs, setEpisodeTypeInputs] = useState<Array<RadioButtonListType>>(episodeTypeRadioList);
  const [episodeCareInputs, setEpisodeCareInputs] = useState<Array<RadioButtonListType>>(episodeCareRadioList);
  const { backupNavigators } = useSelector(getNavigatorState);
  const [isEpisodeCareInputDisabled, setIsEpisodeCareInputDisabled] = useState<boolean>(true);
  const [isDisabledSubmitButton, setIsDisabledSubmitButton] = useState<boolean>(true);

  const {
    episodeDetailTabs: {
      procedureDetails: { details: procedureDetails, isDirty, metadata },
    },
    patientEpisodeDetails,
    episodeDetails,
    patientEpisodeStatus,
  } = useSelector(getEpisodesSlice);
  const [acuteCareFacilityValueIfDisabled, setAcuteCareFacilityValueIfDisabled] = useState("");

  const handleDisable = (isInitialized?: boolean) => {
    if (!isInitialized) {
      setIsDisabledSubmitButton(false);
    }
  };

  const handleSurgeonNameChange = (value: SurgeonsProps, isInitialized?: boolean) => {
    handleDisable(isInitialized);
    const { name, PracticeId, NPI, key } = value;
    const surgeonpracticeData = procedureDetails?.surgeonPractice;
    let practiceDetails = null;
    let practiceToChange: any = surgeonpracticeData;
    let isValid: boolean | undefined = false;
    if (name) {
      practiceDetails =
        PracticeId &&
        metadata.practices &&
        metadata.practices.length &&
        metadata.practices.find((item) => item.key === PracticeId);
      practiceToChange = practiceDetails || surgeonpracticeData;
      isValid = practiceDetails ? true : procedureDetails?.surgeonPractice.isValid;
    }
    dispatch(
      setProcedureDetails({
        key: "surgeonName",
        data: { value: { name, id: key }, NPI, PracticeId, isValid: true },
        isDirty: true,
      })
    );
  };

  const handleAcuteDischargePhysicianChange = (value: SurgeonsProps, isInitialized?: boolean) => {
    handleDisable(isInitialized);
    const { name, PracticeId, NPI, key } = value;
    const surgeonpracticeData = SURGEON_EPISODE_DETAILS_FORM.surgeonPractice;
    let practiceDetails = null;
    let practiceToChange: any = surgeonpracticeData;
    let isValid: boolean | undefined = false;
    if (name) {
      practiceDetails =
        PracticeId &&
        metadata.practices &&
        metadata.practices.length &&
        metadata.practices.find((item) => item?.key === PracticeId);
      practiceToChange = practiceDetails || surgeonpracticeData;
      isValid = practiceDetails ? true : procedureDetails?.surgeonPractice.isValid;
    }
    dispatch(
      setProcedureDetails({
        key: "acuteDischargePhysician",
        data: { value: { name, id: key }, NPI, PracticeId, isValid: true },
        isDirty: true,
      })
    );
  };

  const handlePatientCareManagerChange = (value: HandlePatientCareManagerProp, isInitialized?: boolean) => {
    handleDisable(isInitialized);
    const { name, PracticeId, NPI, id, key } = value;
    const surgeonpracticeData = SURGEON_EPISODE_DETAILS_FORM.surgeonPractice;
    let practiceDetails = null;
    let practiceToChange: any = surgeonpracticeData;
    let isValid: boolean | undefined = false;
    if (name) {
      practiceDetails =
        PracticeId &&
        metadata.practices &&
        metadata.practices.length &&
        metadata.practices.find((item) => item.key === PracticeId);
      practiceToChange = practiceDetails || surgeonpracticeData;
      isValid = practiceDetails ? true : procedureDetails?.surgeonPractice.isValid;
    }
    dispatch(
      setProcedureDetails({
        key: "patientCareManager",
        data: { value: { name, id: id ? id : key ? key : "" }, NPI, PracticeId, isValid: true },
        isDirty: true,
      })
    );
  };

  const handleSurgerySiteSideChange = (value: ProcedureDetailsDropdownValueProp, isInitialized?: boolean) => {
    handleDisable(isInitialized);
    dispatch(
      setProcedureDetails({
        key: "surgerySiteSide",
        data: { value: { name: value?.name, id: value?.key }, isValid: true },
        isDirty: true,
      })
    );
  };

  const handleSurgeonPracticeChange = (
    value: ProcedureDetailsDropdownValueProp,
    isValid = true,
    isInitialized?: boolean
  ) => {
    handleDisable(isInitialized);
    dispatch(
      setProcedureDetails({
        key: "surgeonPractice",
        data: { value: { name: value.name, id: value.key }, isValid },
        isDirty: true,
      })
    );
  };

  const handleEpisodeSettingsChange = (value: ProcedureDetailsDropdownValueProp, isInitialized?: boolean) => {
    handleDisable(isInitialized);
    dispatch(
      setProcedureDetails({
        key: "episodeSettings",
        data: { value },
        isDirty: true,
      })
    );
  };

  const handleEpisodeSettingUpdate = (id: string, value: string, isInitialized?: boolean) => {
    const updatedInputs = [...episodeSettingInputs];
    updatedInputs.forEach((el) => {
      if (el.id === id) {
        el.checked = true;
      } else {
        el.checked = false;
      }
    });
    setEpisodeSettingInputs(updatedInputs);
    handleEpisodeSettingsChange({ name: value, id }, isInitialized);
  };

  const handleEpisodeChange = (value: { name: string; key: string; value?: number }, isInitialized?: boolean) => {
    handleDisable(isInitialized);
    const [episodeId, surgeryCategoryId] = value?.key ? value.key.split(":") : ["", ""];
    dispatch(
      setProcedureDetails({
        key: "surgerySiteSide",
        data: { value: { name: "", id: "" } },
        isDirty: true,
      })
    );
    dispatch(
      setProcedureDetails({
        key: "episode",
        data: { value: { name: value?.name, id: episodeId, episodeTypeId: value.value }, isValid: true },
        isDirty: true,
      })
    );
    const settingText = Object.entries(episodeSettingType).find((item) => item[1].id === value.value?.toString())?.[1]
      .text as string;
    if (value.value && !isInitialized) {
      handleEpisodeSettingUpdate(value.value?.toString(), settingText, isInitialized);
    }

    if (surgeryCategoryId) {
      appDispatch(getSurgerySiteSideAsync(surgeryCategoryId)).then((res) => {
        if (res.payload && episodeDetails) {
          const surgerySiteSide = res.payload?.find(
            (siteSide: {
              DomainID: number;
              ID: number;
              SurgeryCategory: string;
              SurgeryCategoryID: number;
              SurgerySide: string | null;
              SurgerySite: string | null;
            }) =>
              siteSide.SurgerySite &&
              siteSide.SurgerySide &&
              `${siteSide.SurgerySite} - ${siteSide.SurgerySide}` ===
                `${episodeDetails?.episodeSite} - ${episodeDetails?.episodeSide}`
          );
          if (surgerySiteSide && isInitialized) {
            const siteSideName = `${episodeDetails?.episodeSite} - ${episodeDetails?.episodeSide}`;
            handleSurgerySiteSideChange({ name: siteSideName, key: surgerySiteSide?.ID }, isInitialized);
          }
        }
      });
    }
  };

  const handleProgramChange = (
    value: { name: string; key?: number; id?: number },
    valueAlreadyExist?: boolean,
    episodeName?: string
  ) => {
    const valueID = value?.id ? value?.id : value?.key;
    if (valueID !== procedureDetails?.program.value.id) {
      handleDisable(valueAlreadyExist);
      dispatch(
        setProcedureDetails({
          key: "program",
          data: {
            value: { name: value?.name, id: valueID },
            isValid: true,
          },
          isDirty: true,
        })
      );
      dispatch(
        setProcedureDetails({
          key: "surgerySiteSide",
          data: { value: { name: "", id: "" } },
          isDirty: true,
        })
      );
      dispatch(setProcedureDetails({ key: "episode", data: { value: { name: "", id: "" } } }));
      if (valueID) {
        appDispatch(
          getAllEpisodesAsync({ programId: valueID.toString(), clientId: episodeDetails?.clientId as number })
        ).then((response: any) => {
          if (valueAlreadyExist && response.payload && response.payload.length > 0) {
            const episode = response.payload.find((item: any) => episodeName === item.LongName);
            handleEpisodeChange(
              {
                name: episode?.LongName,
                key: `${episode?.ID}: ${episode?.SurgeryCategoryID}`,
                value: episode?.EpisodeTypeID,
              },
              valueAlreadyExist
            );
          }
        });
      }
    }
  };

  const handleCptChange = (value: string, isInitialized?: boolean) => {
    handleDisable(isInitialized);
    dispatch(
      setProcedureDetails({
        key: "cpt",
        data: {
          value,
          isValid: isEmptyString(value) ? true : isCptValid(value),
        },
        isDirty: true,
      })
    );
  };

  const handleProcedureDescriptionChange = (value: string, isInitialized?: boolean) => {
    handleDisable(isInitialized);
    dispatch(
      setProcedureDetails({
        key: "procedureDescription",
        data: { value, isValid: isInitialized ? true : isProcedureDescriptionValid(value) },
        isDirty: true,
      })
    );
  };

  const handleEpisodeTypeChange = (value: { name: string; id: string | number }, isInitialized?: boolean) => {
    handleDisable(isInitialized);
    const { id, text } = episodeCareType.SURGICAL;
    dispatch(setProcedureDetails({ key: "episodeType", data: { value: value }, isDirty: true }));
    dispatch(
      setProcedureDetails({
        key: "episodeCare",
        data: {
          value: { id, name: text },
        },
        isDirty: true,
      })
    );
  };

  const handleEpisodeCareChange = (value: ProcedureDetailsDropdownValueProp, isInitialized?: boolean) => {
    handleDisable(isInitialized);
    dispatch(setProcedureDetails({ key: "episodeCare", data: { value }, isDirty: true }));
  };

  const handleAcuteCareFacilityChange = (value: ProcedureDetailsDropdownValueProp, isInitialized?: boolean) => {
    handleDisable(isInitialized);
    dispatch(
      setProcedureDetails({
        key: "acuteCareFacility",
        data: { value: { name: value.name, id: value.key }, isValid: true },
        isDirty: true,
      })
    );
  };

  const handleCnnChange = (value: string, isInitialized?: boolean) => {
    handleDisable(isInitialized);
    dispatch(
      setProcedureDetails({
        key: "ccn",
        data: { value, isValid: isEmptyString(value) ? true : isCcnValid(value) },
        isDirty: true,
      })
    );
  };

  const handleAdmitDateChange = (value: string, isFirstTimeCalled?: boolean) => {
    handleDisable(isFirstTimeCalled);
    dispatch(setProcedureDetails({ key: "admitDate", data: { value, isValid: true }, isDirty: true }));
    if (!isFirstTimeCalled) {
      dispatch(setProcedureDetails({ key: "surgeryDate", data: { value: null }, isDirty: true }));
      dispatch(setProcedureDetails({ key: "dischargeDate", data: { value: null }, isDirty: true }));
    }
  };

  const handleSurgeryDateChange = (value: string, isValidDate?: boolean, isFirstTimeCalled?: boolean) => {
    handleDisable(isFirstTimeCalled);
    dispatch(
      setProcedureDetails({
        key: "surgeryDate",
        data: { value, isValid: isValidDate ?? isSurgeryDateValid(procedureDetails?.admitDate.value, value) },
        isDirty: true,
      })
    );
    if (!isFirstTimeCalled && !moment(value).isSameOrBefore(procedureDetails?.dischargeDate.value)) {
      dispatch(
        setProcedureDetails({
          key: "dischargeDate",
          data: { value: null },
          isDirty: true,
        })
      );
    }
  };

  const handleDischargeDateChange = (value: string | null, isInitialized?: boolean) => {
    handleDisable(isInitialized);
    dispatch(
      setProcedureDetails({
        key: "dischargeDate",
        data: {
          value,
        },
        isDirty: true,
      })
    );
  };

  const handleIntakeStatusChange = (value: ProcedureDetailsDropdownValueProp, isInitialized?: boolean) => {
    handleDisable(isInitialized);
    dispatch(
      setProcedureDetails({
        key: "patientEpisodeStatus",
        data: { value: { name: value.name, id: value?.key } },
        isDirty: true,
      })
    );
  };

  const handleEpisodeCareUpdate = (id: string, value: string, isInitialized?: boolean) => {
    const updatedEpisodeCareInputs = [...episodeCareInputs];
    updatedEpisodeCareInputs.forEach((el) => {
      el.checked = el.id === id;
    });
    if (episodeCareType.MEDICAL.text === value) {
      handleSurgeryDateChange("", true, true);
    }
    setEpisodeCareInputs(updatedEpisodeCareInputs);
    handleEpisodeCareChange({ name: value, id }, isInitialized);
  };

  const handleEpisodeTypeUpdate = (id: string, value: string, isInitialized?: boolean) => {
    const isPlanned = value === episodePlanType.PLANNED.text;
    setIsEpisodeCareInputDisabled(isPlanned);
    const updatedEpisodeTypeInputs = [...episodeTypeInputs];
    updatedEpisodeTypeInputs.forEach((el) => {
      el.checked = el.id === id;
    });
    setEpisodeTypeInputs(updatedEpisodeTypeInputs);

    if (isPlanned) {
      const { SURGICAL } = episodeCareType;
      handleEpisodeCareUpdate(SURGICAL.id, SURGICAL.text, isInitialized);
    }
    handleEpisodeTypeChange({ name: value, id }, isInitialized);
  };
  const handleReferrerChange = (value: ItemType, isInitialized?: boolean) => {
    handleDisable(isInitialized);
    dispatch(
      setProcedureDetails({
        key: "referringProvider",
        data: { value },
        isDirty: true,
      })
    );
  };

  const handleReferralIdChange = (value: string, isInitialized?: boolean) => {
    handleDisable(isInitialized);
    dispatch(
      setProcedureDetails({
        key: "referralId",
        data: { value },
        isDirty: true,
      })
    );
  };

  const handleTransitionNavigatorChange = (
    value: { key?: string; name: string; [name: string]: any },
    isInitialized?: boolean
  ) => {
    handleDisable(isInitialized);
    dispatch(setProcedureDetails({ key: "transitionNavigator", data: { value }, isDirty: true }));
  };

  const isChecked = (list: RadioButtonListType[]) => list.some((item) => item.checked);

  const checkSurgeonEpiosdeDetailsFormValidation = () => {
    const {
      surgeonName,
      program,
      episode,
      procedureDescription,
      episodeSettings,
      surgerySiteSide,
      episodeCare,
      episodeType,
      acuteCareFacility,
      admitDate,
      surgeryDate,
    } = procedureDetails!;

    const updatedForm = {
      ...procedureDetails,
      surgeonName: { ...surgeonName, isValid: !!surgeonName.value },
      program: { ...program, isValid: !!program.value.name },
      episode: { ...episode, isValid: !!episode.value.name },
      procedureDescription: {
        ...procedureDescription,
        isValid: procedureDescription.isDisabled ? true : isProcedureDescriptionValid(procedureDescription.value),
      },
      episodeSettings: {
        ...episodeSettings,
        isValid: !!episodeSettings.value.name,
      },
      episodeType: {
        ...episodeType,
        isValid: !!episodeType.value.name,
      },
      episodeCare: {
        ...episodeCare,
        isValid: !!episodeCare.value.name,
      },
      surgerySiteSide: {
        ...surgerySiteSide,
        isValid: !!surgerySiteSide.value.name,
      },
      acuteCareFacility: {
        ...acuteCareFacility,
        isValid: !!acuteCareFacility.value.name,
      },
      admitDate: { ...admitDate, isValid: !!admitDate.value },
      surgeryDate: {
        ...surgeryDate,
        isValid: episodeCare.value.name === episodeCareType.SURGICAL.text ? !!surgeryDate.value : true,
      },
    } as IProcedureDetailType;

    dispatch(updateProcedureDetailsValues(updatedForm));
    let isValid = true;
    Object.values(updatedForm).forEach((input: InputType) => {
      if (!input.isDisabled && (input.isRequired || input.value)) {
        isValid = isValid && input.isValid;
      }
    });
    return isValid;
  };

  const getPayload = () => {
    const {
      program,
      episode,
      procedureDescription,
      episodeSettings,
      surgerySiteSide,
      episodeCare,
      episodeType,
      acuteCareFacility,
      admitDate,
      surgeryDate,
      surgeonPractice,
      acuteDischargePhysician,
      dischargeDate,
      ccn,
      cpt,
      patientCareManager,
      surgeonName,
      referralId,
      referringProvider,
      transitionNavigator,
    } = procedureDetails!;

    const requestPayload: IUpdateEpisodeRequestPayload = {
      clientId: episodeDetails?.clientId ? episodeDetails?.clientId : null,
      surgeonID: surgeonName.value.id ? parseInt(surgeonName.value.id, 10) : null,
      practiceID: surgeonPractice.value.id ? parseInt(surgeonPractice.value.id, 10) : null,
      programID: program.value.id ? parseInt(program.value.id, 10) : 0,
      episodeID: episode.value?.id ?? 0,
      procedureDescription: procedureDescription.value,
      cpt: cpt.value ?? "",
      surgerySiteSide: surgerySiteSide.value?.id ?? 0,
      facilityID: acuteCareFacility.value.id ? parseInt(acuteCareFacility.value.id, 10) : null,
      episodeTypeID: episodeSettings.value.id ? parseInt(episodeSettings.value.id, 10) : 0,
      medSurg: episodeCare.value.id ? parseInt(episodeCare.value.id, 10) : 0,
      plannedUnplanned: episodeType.value.id ? parseInt(episodeType.value.id, 10) : 0,
      admitDate: admitDate.value ? moment(admitDate.value).format("MM/DD/YYYY") : "",
      surgeryDate:
        episodeCare.value.name === episodeCareType.SURGICAL.text && surgeryDate.value
          ? moment(surgeryDate.value).format("MM/DD/YYYY")
          : "",
      dischargeDate: dischargeDate.value ? moment(dischargeDate.value).format("MM/DD/YYYY") : "",
      patientCareManager: patientCareManager.value?.id ?? "",
      dischargePhysicianId: acuteDischargePhysician.value?.id ?? null,
      fromNavigatorApp: true,
      address1: "dummy address",
      city: "dummy city",
      genderID: 0,
      preferredPhone: "home",
      state: "dummy state",
      zip: "dummy zip",
      intakeStatusID:
        patientEpisodeStatus.intakeStatus.key.toString() !== "-1" ||
        patientEpisodeStatus.intakeStatus.key.toString() !== "0"
          ? patientEpisodeStatus.intakeStatus.key.toString()
          : "2",
      patientID: episodeDetails?.patientId ?? 0,
      intakeID: intakeId ? parseInt(intakeId, 10) : 0,
      ccn: ccn.value ?? "",
      primaryCareManagerID: patientCareManager.value.id ?? "",
      referralId: referralId.value,
      referringProviderID: !isEmptyString(referringProvider.value.key) ? referringProvider.value?.key : null,
      transitionNavigatorId: !isEmptyString(transitionNavigator.value.key) ? transitionNavigator.value.key : null,
    };
    return requestPayload;
  };

  const setPatientDetails = (data: IEpisodeDetails) => {
    const newPatientDetails = createPatientEpisodeDetailsData(data);
    dispatch(setPatientEpisodeDetails(newPatientDetails));
    dispatch(setEpisodeDetails(data));
  };

  const handleSubmit = () => {
    const isValidForm = checkSurgeonEpiosdeDetailsFormValidation();
    if (isValidForm) {
      dispatch(setIsDirty(false));
      const payload = getPayload();
      appDispatch(updateEpisodeAsync(payload)).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          toast.success("Procedure details successfully saved", {
            toastId: "procedure-detail-success",
            containerId: "main",
          });
          setIsDisabledSubmitButton(true);
          if (res.payload) {
            appDispatch(getUpdatedEpisodeDetailsById({ intakeId })).then((response) => {
              if (response.payload) {
                setPatientDetails({ ...episodeDetails, ...response.payload });
              }
            });
          }
        } else {
          toast.error("Something went wrong", {
            toastId: "procedure-detail-success",
            containerId: "main",
          });
        }
      });
    }
  };

  useEffect(() => {
    if (episodeDetails?.clientId) {
      if (!metadata.surgeons.length) {
        dispatch(getAllSurgeonsAsync(episodeDetails.clientId));
      }
      if (!metadata.practices.length) {
        dispatch(getAllPracticesAsync(episodeDetails.clientId));
      }
      if (!metadata.programs.length) {
        dispatch(getAllProgramsAsync(episodeDetails.clientId));
      }
      if (!metadata.facilities.length) {
        dispatch(getAllFacilitiesAsync(episodeDetails.clientId));
      }
      if (!metadata.referrals.length) {
        appDispatch(getProvidersReferrerListAsync({ clientId: episodeDetails.clientId, showAll: true })).then((res) => {
          const referralList = res.payload as Array<GetProvidersReferrerListResponse>;
          const check = referralList.find((referral) => referral.id === episodeDetails.referringProviderID);
          if (check) {
            handleReferrerChange(
              {
                key: check.id,
                name: getPatientFullName({
                  firstName: check?.firstName ?? undefined,
                  lastName: check?.lastName ?? undefined,
                }),
              },
              true
            );
          }
        });
      }
    }
    if (!metadata.preferredPhones.length) {
      dispatch(getPreferredPhoneNumberIdAsync());
    }
    appDispatch(getBackUpNavigatorListAsync({ types: ["TNAV", "PNAV"] })).then((res) => {
      if (res.payload && res.payload.length && episodeDetails?.transitionNavigatorId) {
        const navigator: INavigator | null = res.payload.find(
          (nav: INavigator) => nav.careManagerId === episodeDetails?.transitionNavigatorId
        );
        if (navigator) {
          handleTransitionNavigatorChange(
            {
              key: navigator.careManagerId,
              name: getPatientFullName({ firstName: navigator.firstName, lastName: navigator.lastName }),
            },
            true
          );
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setExistingValueInProcedureDetails = () => {
    if (episodeDetails) {
      const program = metadata.programs.find((item) => episodeDetails.programName === item.name);
      const programObj = {
        name: program.name,
        id: program.key,
      };

      handleProgramChange(programObj, true, episodeDetails.episodeName);
      handleEpisodeSettingUpdate(
        episodeDetails.episodeTypeId.toString(),
        Object.entries(episodeSettingType).find(
          (item) => parseInt(item[1].id) === Number(episodeDetails.episodeTypeId)
        )?.[1].text as string,
        true
      );
      handleEpisodeTypeUpdate(
        episodeDetails.plannedUnplannedId.toString(),
        Object.entries(episodePlanType).find(
          (item) => parseInt(item[1].id) === Number(episodeDetails.plannedUnplannedId)
        )?.[1].text as string,
        true
      );
      handleEpisodeCareUpdate(
        episodeDetails.medSurg.toString(),
        Object.entries(episodeCareType).find((item) => parseInt(item[1].id) === Number(episodeDetails.medSurg))?.[1]
          .text as string,
        true
      );
      handleProcedureDescriptionChange(episodeDetails.procedureDescription ?? "", true);
      if (episodeDetails.admitDate) {
        handleAdmitDateChange(episodeDetails.admitDate, true);
      }
      handleCnnChange(episodeDetails.ccn ? episodeDetails.ccn : "", true);
      handleCptChange(episodeDetails.cpt ? episodeDetails.cpt : "", true);
      handleDischargeDateChange(episodeDetails.surgeryDischargeDate, true);
      if (episodeDetails.surgeryDate) {
        handleSurgeryDateChange(
          episodeDetails.surgeryDate,
          isSurgeryDateValid(episodeDetails.admitDate, episodeDetails.surgeryDate),
          true
        );
      }
      const acuteCareFacility = metadata.facilities.find((item) => item.key === episodeDetails.facilityId);
      if (acuteCareFacility) {
        handleAcuteCareFacilityChange(acuteCareFacility, true);
      }
      if (episodeDetails.primaryCareManagerId) {
        handlePatientCareManagerChange(
          {
            name: `${episodeDetails.pnavFirstName} ${episodeDetails.pnavLastName}`,
            id: episodeDetails.primaryCareManagerId,
          },
          true
        );
      }
      let surgeon = null;
      let acuteDischargePhysician = null;
      metadata.surgeons.forEach((surg) => {
        if (surg.key === episodeDetails.admittingPhysicianId) {
          surgeon = surg;
        }
        if (surg.key === episodeDetails.dischargePhysicianId) {
          acuteDischargePhysician = surg;
        }
      });

      const surgeonPractice = metadata.practices.find((practice) => practice.key === episodeDetails.practiceId);
      if (surgeonPractice && surgeonPractice.key !== "") {
        handleSurgeonPracticeChange(surgeonPractice, true, true);
      }
      if (surgeon) {
        handleSurgeonNameChange(surgeon, true);
      }
      if (acuteDischargePhysician) {
        handleAcuteDischargePhysicianChange(acuteDischargePhysician, true);
      }

      const intakeStatus = metadata.intakeStatuses.find(
        (status) => status.id === episodeDetails?.intakeStatusId.toString()
      );
      if (episodeDetails.referralId) {
        handleReferralIdChange(episodeDetails.referralId, true);
      }
      if (intakeStatus) {
        const intakeStatusObject = {
          name: intakeStatus.name,
          key: intakeStatus.id,
        };
        handleIntakeStatusChange(intakeStatusObject, true);
      }
      dispatch(setIsDirty(false));
      setIsValueSetForFirstTime(true);
    }
  };

  useEffect(() => {
    if (
      !isValueSetForFirstTime &&
      metadata.programs.length &&
      metadata.surgeons.length &&
      metadata.practices.length &&
      metadata.facilities.length &&
      intakeId &&
      episodeDetails
    ) {
      setExistingValueInProcedureDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metadata, backupNavigators, intakeId, episodeDetails]);

  return (
    <ProcedureDetailsPresentation
      handleSurgeonNameChange={handleSurgeonNameChange}
      handleSurgeonPracticeChange={handleSurgeonPracticeChange}
      handleProgramChange={handleProgramChange}
      handleEpisodeChange={handleEpisodeChange}
      handleCptChange={handleCptChange}
      handleProcedureDescriptionChange={handleProcedureDescriptionChange}
      handleEpisodeSettingUpdate={handleEpisodeSettingUpdate}
      handleEpisodeTypeUpdate={handleEpisodeTypeUpdate}
      handleEpisodeCareUpdate={handleEpisodeCareUpdate}
      handleSurgerySiteSideChange={handleSurgerySiteSideChange}
      handleAcuteCareFacilityChange={handleAcuteCareFacilityChange}
      handleCnnChange={handleCnnChange}
      handleAdmitDateChange={handleAdmitDateChange}
      handleSurgeryDateChange={handleSurgeryDateChange}
      handleDischargeDateChange={handleDischargeDateChange}
      handleAcuteDischargePhysicianChange={handleAcuteDischargePhysicianChange}
      handlePatientCareManagerChange={handlePatientCareManagerChange}
      handleIntakeStatusChange={handleIntakeStatusChange}
      isChecked={isChecked}
      episodeSettingInputs={episodeSettingInputs}
      episodeTypeInputs={episodeTypeInputs}
      episodeCareInputs={episodeCareInputs}
      isEpisodeCareInputDisabled={isEpisodeCareInputDisabled}
      acuteCareFacilityValueIfDisabled={acuteCareFacilityValueIfDisabled}
      isLoading={!isValueSetForFirstTime}
      handleSubmit={handleSubmit}
      isDisabledSubmitButton={isDisabledSubmitButton === true}
      handleReferralIdChange={handleReferralIdChange}
      handleReferrerChange={handleReferrerChange}
      handleTransitionNavigatorChange={handleTransitionNavigatorChange}
    />
  );
};

export default ProcedureDetails;
