import DropDown from "pages/task-management/risk-assessment/risk-assessment-form-inputs/dropdown/dropdown.component";
import {
  Pages,
  QuestionType,
  SelectionType,
  SelectionValue,
  Question,
} from "pages/task-management/risk-assessment/common/risk-assessment.types";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Pill } from "pages/task-management/risk-assessment/risk-assessment-form-inputs/pill/pill.component";
import Pills from "pages/task-management/risk-assessment/risk-assessment-form-inputs/pills/pills.component";
import CommonNote from "pages/task-management/risk-assessment/risk-assessment-form-inputs/common-note/common-note.component";
import "./assessment-form-inputs.styles.scss";
import Collapse from "components/collapse/collapse.component";
import { useDispatch, useSelector } from "react-redux";
import SingleDatePicker from "components/single-date-picker/single-date-picker.component";
import moment from "moment";
import Icon from "components/Icon/icon.component";
import CheckoxContainer from "pages/task-management/risk-assessment/risk-assessment-form-inputs/checkbox-container/checkbox-container";
import { isEmptyString } from "shared/methods/utilityFunctions";
import Scale from "pages/task-management/risk-assessment/risk-assessment-form-inputs/scale/scale.component";
import { getBrowserState, getCurrentPageTaskState } from "state/feature/task-management/task-management.slice";
import { getAssessmentState, setIsValueChanged } from "state/feature/assessment/assessment.slice";
import { AssessmentContext } from "../context/assessment-context";
import { isArray } from "lodash";
import store from "state/store";
import Error from "components/error-message/error-message.component";
import { TaskStatus } from "shared/enums/tasks-status.enum";
import { AssessmentType } from "shared/enums/assessment.enum";

type AssessmentFormInputProps = {
  sectionId?: string | null;
  questionId: string;
  inputType?: QuestionType;
  selectionType?: SelectionType;
  className?: string;
  options?: SelectionValue[];
  title?: string | null;
  questions?: Question[];
  pageId?: Pages;
  maxLength?: number;
  qTitle?: string | null;
  order?: number | null;
  sectionOrder?: number | null;
  noteText?: string | null;
  alertText?: string | null;
  stepCount?: number;
  selectedRange?: number[];
  scaleTitle?: string;
  id?: string;
  placeHolder?: string;
  subTitle?: string | null;
  isRequired?: boolean;
  isConclusion?: boolean;
  type?: string;
};

const AssessmentFormInputs = (props: AssessmentFormInputProps) => {
  const { currentTab } = useSelector(getBrowserState);
  const { patientDetails } = useSelector(getCurrentPageTaskState(currentTab!.taskId));
  const { answers, isSubmitButtonClicked, taskStatusId, isAnswersExist, isRedirectEnabled } =
    useSelector(getAssessmentState);
  const dispatch = useDispatch();
  const [currentSelectedOptions, setCurrentSelectedOptions] = useState<any | null>(null);
  const [nextQuestionIdValue, setNextQuestionIdValue] = useState<any | null>(null);
  const { onChangeDays, onChangeAge, onChangeDate, onChangeText, onChangeCheckbox } = useContext(AssessmentContext);
  const {
    questions,
    questionId,
    className,
    inputType,
    selectionType,
    options,
    title,
    sectionId,
    pageId,
    maxLength,
    qTitle,
    order,
    sectionOrder,
    noteText,
    stepCount,
    selectedRange,
    id: uniqiueId,
    placeHolder,
    subTitle: sTitle,
    isRequired,
    isConclusion,
    type,
  } = props;
  const isDisabledAssessmentForm = useCallback(
    () =>
      (!isRedirectEnabled && isAnswersExist) ||
      taskStatusId === TaskStatus.COMPLETED ||
      taskStatusId === TaskStatus.CLOSED,
    [taskStatusId, isRedirectEnabled, isAnswersExist]
  );
  useEffect(() => {
    if (answers && answers.length > 0) {
      const filteredQuestion = answers.filter((x) => x.questionId === questionId);
      if (filteredQuestion && filteredQuestion.length > 0) {
        const currentQuestion = filteredQuestion[0];
        if (currentQuestion && Object.keys(currentQuestion).length > 0) {
          const answer = currentQuestion.selectedValues[questionId ?? ""];
          setCurrentSelectedOptions(answer);
        } else {
          setCurrentSelectedOptions(null);
        }
      } else {
        setCurrentSelectedOptions(null);
      }
    } else {
      setCurrentSelectedOptions(null);
    }
  }, [answers, questionId]);

  useEffect(() => {
    if (patientDetails?.patientId && inputType === "age-selector" && !currentSelectedOptions) {
      onChangeAge(questionId, sectionId, questionId, pageId, order, sectionOrder, patientDetails.age.toString() ?? "0");
    }
  }, [
    patientDetails,
    questionId,
    order,
    pageId,
    sectionId,
    sectionOrder,
    onChangeAge,
    inputType,
    currentSelectedOptions,
  ]);

  switch (inputType) {
    case "pill":
      return (
        <>
          <div className="question-section-checkbox-grid">
            <Pills options={options}>
              {({ id, title: pillTitle, order: orderEl, nextQuestionId, showSection, conditionalSectionId }) => {
                return (
                  <>
                    <Pill
                      id={id}
                      sectionId={sectionId}
                      title={pillTitle}
                      questionId={questionId}
                      order={orderEl}
                      className={className}
                      selectionType={selectionType}
                      nextQuestionId={nextQuestionId}
                      conditionalSectionId={conditionalSectionId}
                      showSection={showSection}
                      pageId={pageId}
                      currentSelectedOptions={currentSelectedOptions}
                      questionOrder={order}
                      sectionOrder={sectionOrder}
                      setNextQuestionIdValue={(val) => {
                        setNextQuestionIdValue((prev: any) => (prev = val));
                      }}
                      disabled={isDisabledAssessmentForm()}
                      setIsValueChanged={setIsValueChanged}
                    />
                  </>
                );
              }}
            </Pills>
          </div>
          {isRequired && currentSelectedOptions === null && isSubmitButtonClicked && <Error />}
          {questions && questions.length > 0 && (
            <div id={`${sectionId}-${questionId}-extra-questions`} className={"question-section-checkbox-grid"}>
              <div className={`${questions && questions.length > 0 ? "nested-pill-questions" : ""}`}>
                {questions.map(
                  ({
                    questionId: id,
                    questionType,
                    selectionValues,
                    selectionType: selectType,
                    title: questionTitle,
                    note,
                    subTitle,
                    maxLength: mLength,
                    questions: moreQuestions,
                    showAlways,
                    placeHolder: pHolder,
                    isRequired: otherQuestionIsRequired,
                  }) => {
                    const selected = answers
                      .map((values) =>
                        Object.entries(values.selectedValues).find((answer) =>
                          answer[1].length > 0 && answer[1][0] !== null
                            ? answer[1][0].nextQuestionId === id && note
                            : false
                        )
                      )
                      .flat()
                      .filter((result) => result !== undefined || null);
                    if (
                      currentSelectedOptions &&
                      isArray(currentSelectedOptions) &&
                      currentSelectedOptions?.some((x: any) => x.nextQuestionId === id)
                    ) {
                      return (
                        <React.Fragment key={id}>
                          <div
                            className={`extra-section-1 ${
                              questionTitle && questionType === "pill" ? "extra-section-pill" : ""
                            }`}
                          >
                            <React.Fragment key={`${questionId}_${id}`}>
                              {questionTitle && questionType === "pill" && (
                                <div className="pill-option-title">{questionTitle}</div>
                              )}
                              <AssessmentFormInputs
                                options={selectionValues}
                                inputType={questionType}
                                selectionType={selectType}
                                className={`${questionType}-${id?.replaceAll(".", "-")}`}
                                questionId={id}
                                title={questionTitle}
                                maxLength={mLength}
                                questions={moreQuestions}
                                sectionId={sectionId}
                                pageId={pageId}
                                order={order}
                                placeHolder={pHolder}
                                isRequired={otherQuestionIsRequired}
                              />
                            </React.Fragment>
                          </div>
                          {note && selected && selected.length > 0 && (
                            <CommonNote
                              className="assessment-note"
                              reminder={note}
                              image={<Icon icon="assessment-note" size={16} />}
                            />
                          )}
                        </React.Fragment>
                      );
                    }
                    if (showAlways) {
                      return (
                        <div key={`${id}-show-always`} className="show-always-questions">
                          <AssessmentFormInputs
                            options={selectionValues}
                            inputType={questionType}
                            selectionType={selectType}
                            className={`${questionType}-${id?.replaceAll(".", "-")}`}
                            questionId={id}
                            title={questionTitle}
                            maxLength={mLength}
                            placeHolder={pHolder}
                            isRequired={otherQuestionIsRequired}
                          />
                        </div>
                      );
                    }
                  }
                )}
              </div>
            </div>
          )}
        </>
      );

    case "text-medium":
      return (
        <div className="text-selector-container">
          {(sTitle || title) && <div className="text-title">{sTitle ? sTitle : title}</div>}

          <input
            value={currentSelectedOptions && currentSelectedOptions.length > 0 ? currentSelectedOptions[0].value : ""}
            name={`${questionId}`}
            type="text"
            className="text-selector"
            maxLength={maxLength}
            placeholder={placeHolder}
            disabled={isDisabledAssessmentForm()}
            onChange={(event: any) => {
              const regex = /^[a-zA-Z\s]+$/;
              if (regex.test(event.target.value) || isEmptyString(event.target.value)) {
                onChangeText(event.target.value, sectionId ?? "", questionId ?? "", pageId, order, sectionOrder);
              }
            }}
          />

          <div className={"text-container-max-length"}>
            {maxLength && currentSelectedOptions && isArray(currentSelectedOptions) && (
              <div className="text-length">{`${currentSelectedOptions[0].value.length}/${maxLength}`}</div>
            )}
          </div>
          {isRequired && currentSelectedOptions === null && isSubmitButtonClicked && (
            <Error message="This field is required" />
          )}
        </div>
      );

    case "text-area":
      if (type === AssessmentType.NO_ANSWER && isDisabledAssessmentForm() && currentSelectedOptions === null) {
        return <div className="no-answer">N/A</div>;
      } else if (
        isConclusion ||
        (isDisabledAssessmentForm() &&
          currentSelectedOptions &&
          currentSelectedOptions.length > 0 &&
          currentSelectedOptions[0].value) ||
        !isDisabledAssessmentForm()
      ) {
        return (
          <>
            <div className="question-section-checkbox-grid text-area-main-container">
              <div className="text-area-container">
                {(sTitle || title) && <div className="text-area-title">{sTitle ? sTitle : title}</div>}
                <textarea
                  className="text-area"
                  id={`textarea-${questionId}`}
                  name={`textarea-${questionId}`}
                  maxLength={maxLength}
                  placeholder={placeHolder !== undefined && !isEmptyString(placeHolder) ? placeHolder : ""}
                  cols={70}
                  rows={5}
                  defaultValue={
                    currentSelectedOptions && currentSelectedOptions.length > 0 ? currentSelectedOptions[0].value : ""
                  }
                  disabled={isDisabledAssessmentForm()}
                  onInput={(event: any) => {
                    onChangeText(event.target.value, sectionId ?? "", questionId ?? "", pageId, order, sectionOrder);
                  }}
                />
              </div>
              {!isDisabledAssessmentForm() && (
                <div className={"text-area-container-max-length"}>
                  {maxLength && currentSelectedOptions && isArray(currentSelectedOptions) && (
                    <div className="text-area-length">{`${currentSelectedOptions[0].value.length}/${maxLength}`}</div>
                  )}
                </div>
              )}
              {isRequired && currentSelectedOptions === null && isSubmitButtonClicked && (
                <Error message="This field is required" />
              )}
            </div>
            {questions && questions.length > 0 && (
              <div id={`${sectionId}-${questionId}-extra-questions`} className={"question-section-checkbox-grid"}>
                <div className={`${questions && questions.length > 0 ? "nested-pill-questions" : ""}`}>
                  {questions.map(
                    ({
                      questionId: id,
                      questionType,
                      selectionValues,
                      selectionType: selectType,
                      title: questionTitle,
                      maxLength: mLength,
                      showAlways,
                      placeHolder: pHolder,
                      subTitle,
                      isRequired: otherQuestionIsRequired,
                    }) => {
                      if (showAlways) {
                        return (
                          <div key={`${id}-show-always`} className="show-always-questions">
                            <AssessmentFormInputs
                              options={selectionValues}
                              inputType={questionType}
                              selectionType={selectType}
                              className={`${questionType}-${id?.replaceAll(".", "-")}`}
                              questionId={id}
                              title={subTitle ? subTitle : questionTitle}
                              maxLength={mLength}
                              placeHolder={pHolder}
                              isRequired={otherQuestionIsRequired}
                            />
                          </div>
                        );
                      }
                    }
                  )}
                </div>
              </div>
            )}
          </>
        );
      } else {
        return null;
      }

    case "dropdown":
      let dropdownValues = [];
      if (questionId === "followupAppointment.assignedPhysician") {
        dropdownValues = store.getState().assessment.physicans.map((physician: any) => {
          return {
            id: physician.ID,
            value: physician.ProviderName,
            nextQuestionId: null,
          };
        });
      }
      if (selectionType === "single-select") {
        const showNote =
          currentSelectedOptions && currentSelectedOptions.length > 0
            ? options?.find((option) => option.id === currentSelectedOptions[0].id)?.showNote
            : false;

        return (
          <>
            <div className="question-section-checkbox-grid assessment-dropdown">
              <div className={`${selectionType}-dropdown-container`}>
                {title && <p className="dropdown-title">{title}</p>}
                <DropDown
                  questionId={questionId}
                  sectionId={sectionId}
                  isDisabled={isDisabledAssessmentForm()}
                  placeholder="Select Value"
                  dropDownMenuItems={
                    questionId === "followupAppointment.assignedPhysician"
                      ? dropdownValues
                      : options && options.length > 0
                      ? options.map(({ title: dropdownTitle, id, nextQuestionId }) => {
                          return {
                            id,
                            value: dropdownTitle,
                            nextQuestionId,
                          };
                        })
                      : []
                  }
                  defaultValue={
                    currentSelectedOptions && currentSelectedOptions.length > 0 ? currentSelectedOptions[0] : null
                  }
                  idFieldName="id"
                  dropDownBoxClass="dropdown-box"
                  selectionClass="selection-style"
                  dropDownContainerClass="option-dropdown-container"
                  pageId={pageId}
                  questionOrder={order}
                  sectionOrder={sectionOrder}
                  setIsValueChanged={setIsValueChanged}
                />
              </div>

              {isRequired && currentSelectedOptions === null && isSubmitButtonClicked && <Error />}
            </div>
            {noteText && showNote && (
              <CommonNote
                className="assessment-note"
                reminder={noteText}
                image={<Icon icon="assessment-note" size={16} />}
              />
            )}
          </>
        );
      } else {
        return <div></div>;
      }

    case "checkbox":
      return (
        <>
          {title && <div className="checkbox-options-title">{title}</div>}
          <div className="checkbox-parent-container">
            {options?.map(({ id: optionId, title: checkboxTitle, nextQuestionId }) => (
              <React.Fragment key={optionId}>
                <CheckoxContainer
                  key={`checkbox-${optionId}`}
                  value={checkboxTitle}
                  sectionId={questionId}
                  isDisabled={isDisabledAssessmentForm()}
                  className={
                    isDisabledAssessmentForm() &&
                    currentSelectedOptions &&
                    currentSelectedOptions.length > 0 &&
                    !currentSelectedOptions.map((v: any) => v.id).includes(optionId)
                      ? "disabled"
                      : "disabled-but-highlighted"
                  }
                  name={questionId.toString()}
                  onChange={(e: any) => {
                    if (nextQuestionId) {
                      setNextQuestionIdValue(e.target.checked ? nextQuestionId : null);
                    }
                    onChangeCheckbox(
                      e,
                      optionId,
                      checkboxTitle,
                      sectionId ?? "",
                      questionId ?? "",
                      nextQuestionId,
                      pageId,
                      order,
                      sectionOrder
                    );
                    dispatch(setIsValueChanged(true));
                  }}
                  id={optionId}
                />
                {questions && questions.length > 0 && (
                  <>
                    {questions.map(
                      ({
                        questionId: id,
                        questionType,
                        selectionValues,
                        selectionType: selectType,
                        note,
                        subTitle,
                        maxLength: mLength,
                        questions: moreQuestions,
                        placeHolder: pHolder,
                        showAlways,
                        title: questionTitle,
                        isRequired: otherQuestionIsRequired,
                      }) => {
                        const selected = answers
                          .map((values) =>
                            Object.entries(values.selectedValues).find((answer) =>
                              answer[1].length > 0 && answer[1][0] !== null
                                ? answer[1][0].nextQuestionId === id && note
                                : null
                            )
                          )
                          .flat()
                          .filter((result) => result !== undefined || null);

                        if (
                          currentSelectedOptions?.some((x: any) => x.nextQuestionId === id) &&
                          id === nextQuestionId
                        ) {
                          return (
                            <div
                              key={id}
                              id={`${sectionId}-${questionId}-extra-questions`}
                              className="checkbox-extra-section"
                            >
                              <AssessmentFormInputs
                                key={`checkbox-extra-question-${id}`}
                                questionId={id}
                                options={selectionValues}
                                inputType={questionType}
                                selectionType={selectType}
                                className={`${questionType}-${id?.replaceAll(".", "-")}`}
                                title={subTitle}
                                questions={moreQuestions}
                                sectionId={sectionId}
                                maxLength={mLength}
                                pageId={pageId}
                                order={order}
                                sectionOrder={sectionOrder}
                                placeHolder={pHolder}
                                isRequired={otherQuestionIsRequired}
                              />
                              {note && selected && selected.length > 0 && (
                                <CommonNote
                                  className="assessment-note"
                                  reminder={note}
                                  image={<Icon icon="assessment-note" size={16} />}
                                />
                              )}
                            </div>
                          );
                        }
                      }
                    )}
                  </>
                )}
              </React.Fragment>
            ))}
            {isRequired && currentSelectedOptions === null && isSubmitButtonClicked && <Error />}
            {questions && questions.length > 0 && (
              <>
                {questions.map(
                  ({
                    questionId: id,
                    questionType,
                    selectionValues,
                    selectionType: selectType,
                    maxLength: mLength,
                    placeHolder: pHolder,
                    showAlways,
                    title: questionTitle,
                    isRequired: otherQuestionIsRequired,
                  }) => {
                    if (showAlways) {
                      return (
                        <div key={`${id}-show-always`} className="show-always-questions">
                          <AssessmentFormInputs
                            options={selectionValues}
                            inputType={questionType}
                            selectionType={selectType}
                            className={`${questionType}-${id?.replaceAll(".", "-")}`}
                            questionId={id}
                            title={questionTitle}
                            maxLength={mLength}
                            placeHolder={pHolder}
                            isRequired={otherQuestionIsRequired}
                          />
                        </div>
                      );
                    }
                  }
                )}
              </>
            )}
          </div>
        </>
      );

    case "days-selector":
      return (
        <div>
          <div className="days-selector-container">
            <input
              name={`${questionId}`}
              type="text"
              className="days-selector"
              disabled={isDisabledAssessmentForm()}
              onChange={(event) => {
                const re = /^[1-9][0-9]*$/;
                if (
                  isEmptyString(event.target.value) ||
                  event.target.value.toString() === "0" ||
                  re.test(event.target.value)
                ) {
                  dispatch(setIsValueChanged(true));
                  onChangeDays(event, questionId, sectionId, questionId, pageId, order, sectionOrder);
                }
              }}
              maxLength={2}
              value={currentSelectedOptions && currentSelectedOptions.length > 0 ? currentSelectedOptions[0].value : ""}
            />
            <label className="days-selector-label">days</label>
          </div>
          {isRequired && currentSelectedOptions === null && isSubmitButtonClicked && (
            <Error message="This field is required" />
          )}
        </div>
      );

    case "date-selector":
      return (
        <>
          <div className="days-selector-container">
            {title && <div className="checkbox-options-title">{title}</div>}
            <SingleDatePicker
              defaultDate={
                currentSelectedOptions && currentSelectedOptions.length > 0 ? currentSelectedOptions[0].value : null
              }
              minDate={null}
              maxDate={null}
              isDisabled={isDisabledAssessmentForm()}
              isError={false}
              handleDateChange={(val: any) => {
                onChangeDate(val ? moment(val) : "", sectionId ?? "", questionId ?? "", pageId, order, sectionOrder);
              }}
              popperPlacement="bottom"
            />
          </div>
          {isRequired && currentSelectedOptions === null && isSubmitButtonClicked && (
            <Error message="This field is required" />
          )}
        </>
      );

    case "conditional-section":
      return <Collapse heading={qTitle ?? ""} conditionalSectionId={questionId} pageId={pageId} />;

    case "section-title":
      return <div className="section-question-heading">{qTitle}</div>;

    case "age-selector":
      return (
        <div>
          <div className="age-selector-container">
            <input
              name={`${questionId}`}
              type="text"
              disabled={isDisabledAssessmentForm()}
              className="age-selector"
              maxLength={2}
              value={patientDetails?.age ?? "N/A"}
            />
            <label className="age-selector-label">years</label>
          </div>
          {isRequired && currentSelectedOptions === null && isSubmitButtonClicked && (
            <Error message="This field is required" />
          )}
        </div>
      );

    case "scale":
      return (
        <>
          <div className="scale-container">
            <Scale
              id={uniqiueId}
              questionId={questionId}
              sectionId={sectionId}
              order={order}
              scaleTitle={sTitle}
              stepCount={stepCount}
              defaultValue={selectedRange}
              isDisabled={isDisabledAssessmentForm()}
            />
          </div>
          {questions && questions.length > 0 && (
            <div id={`${sectionId}-${questionId}-extra-questions`} className={"question-section-checkbox-grid"}>
              <div className={`${questions && questions.length > 0 ? "nested-pill-questions" : ""}`}>
                {questions.map(
                  ({
                    questionId: id,
                    questionType,
                    selectionValues,
                    selectionType: selectType,
                    title: questionTitle,
                    maxLength: mLength,
                    showAlways,
                    placeHolder: pHolder,
                    isRequired: otherQuestionIsRequired,
                  }) => {
                    if (showAlways) {
                      return (
                        <div key={`${id}-show-always`} className="show-always-questions">
                          <AssessmentFormInputs
                            options={selectionValues}
                            inputType={questionType}
                            selectionType={selectType}
                            className={`${questionType}-${id?.replaceAll(".", "-")}`}
                            questionId={id}
                            title={questionTitle}
                            maxLength={mLength}
                            placeHolder={pHolder}
                            isRequired={otherQuestionIsRequired}
                          />
                        </div>
                      );
                    }
                  }
                )}
              </div>
            </div>
          )}
        </>
      );

    case "number":
      return (
        <div className="number-input-container">
          <div className="number-title">{sTitle ? sTitle : title}</div>
          <input
            type="number"
            id={`number-${questionId}`}
            maxLength={6}
            pattern="^\d+(?:\.\d{1,2})?$"
            step=".01"
            disabled={isDisabledAssessmentForm()}
            value={currentSelectedOptions && currentSelectedOptions.length > 0 ? currentSelectedOptions[0].value : ""}
            onChange={(event: any) => {
              const regexp = /^[0-9]{0,3}(\.[0-9]{0,2})?$/;
              if (event.target.value.length <= event.target.maxLength && regexp.test(event.target.value)) {
                onChangeText(event.target.value, sectionId ?? "", questionId ?? "", pageId, order, sectionOrder);
              }
            }}
          />
          {isRequired && currentSelectedOptions === null && isSubmitButtonClicked && (
            <Error message="This field is required" />
          )}
        </div>
      );

    case "no-answer":
      return (
        <>
          {questions && questions.length > 0 && (
            <div id={`${sectionId}-${questionId}-extra-questions`} className={"question-section-checkbox-grid"}>
              <div className={`${questions && questions.length > 0 ? "nested-pill-questions" : ""}`}>
                {questions.map(
                  ({
                    questionId: id,
                    questionType,
                    selectionValues,
                    selectionType: selectType,
                    title: questionTitle,
                    note,
                    subTitle,
                    maxLength: mLength,
                    questions: moreQuestions,
                    showAlways,
                    placeHolder: pHolder,
                  }) => {
                    if (showAlways) {
                      return (
                        <div key={`${id}-show-always`} className="show-always-questions">
                          <AssessmentFormInputs
                            options={selectionValues}
                            inputType={questionType}
                            selectionType={selectType}
                            className={`${questionType}-${id?.replaceAll(".", "-")}`}
                            questionId={id}
                            title={questionTitle}
                            maxLength={mLength}
                            placeHolder={pHolder}
                            type="no-answer"
                          />
                        </div>
                      );
                    }
                  }
                )}
              </div>
            </div>
          )}
        </>
      );
  }

  return <div></div>;
};

export default AssessmentFormInputs;
