import React from "react";
import Modal from "../Modal/modal.component";
import Icon from "components/Icon/icon.component";
import { SidePopupProps } from "./props";
import "./side-popup.styles.scss";

const SidePopup = ({
  isOpen,
  onClose,
  children,
  contentClass,
  className,
  heading,
  headerClass,
  modalFooter,
  openFrom,
  isBackArrowInMobile,
  showPatientDetails,
}: SidePopupProps) => {
  return (
    <Modal
      className={`side-popup right ${className ? className : ""}`}
      animationClass="side-popup-animation"
      contentClass={`side-popup-content ${contentClass ? contentClass : ""}`}
      isOpen={isOpen}
      showPatientDetails={showPatientDetails}
      modalHeader={
        <div className={`header ${headerClass ? headerClass : ""}`}>
          <div
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}
            className="close-icon-container"
          >
            <Icon icon="close" size={13} className={`close-icon ${isBackArrowInMobile ? "hidden" : "show"}`} />
            <Icon
              icon="right-arrow1"
              size={16}
              className={`back-arrow-icon ${isBackArrowInMobile ? "show" : "hidden"}`}
            />
          </div>
          <div className="heading">{heading}</div>
        </div>
      }
      modalFooter={modalFooter}
      openFrom={openFrom}
    >
      {children}
    </Modal>
  );
};

export default SidePopup;
