import Button from "components/Button/button.component";
import Checkbox from "components/Checkbox/checkbox.component";
import { MiscPresentationProps } from "./types";

const MiscPresentation = (props: MiscPresentationProps) => {
  const {
    handleNotes,
    handlePatientDeclinedCheckbox,
    patientDeclineNavigationField,
    handleSaveChanges,
    isLoadingSaveButton,
    handleCancelButton,
    isPatientNavigationIsDisabled,
    isValueChanged,
  } = props;
  return (
    <div id="misc-tab-container">
      <div className="misc-body">
        <div className="misc-body-content">
          <div className="misc-body-content-field">
            <Checkbox
              name="Declined Episode Navigation"
              value="Declined Episode Navigation"
              id="declined-episode-navigation-checkbox"
              isDisabled={isPatientNavigationIsDisabled}
              onChange={(e) => {
                handlePatientDeclinedCheckbox();
              }}
              isChecked={patientDeclineNavigationField.patientNavigationDeclined}
            />
          </div>
          <div className="misc-body-content-field input-field notes">
            <label className="field-label">Reason for Decline</label>
            <textarea
              className="text-area"
              id={`textarea`}
              name={`textarea`}
              maxLength={2000}
              cols={70}
              rows={5}
              placeholder={"Write your notes here"}
              value={patientDeclineNavigationField.patientNavigationDeclinedNotes}
              disabled={!patientDeclineNavigationField.patientNavigationDeclined || isPatientNavigationIsDisabled}
              onChange={(e) => {
                handleNotes(e.currentTarget.value);
              }}
            />
            <div className=" error-character-length-container">
              <div className="error-field"></div>
              <div className="text-length">
                {!isPatientNavigationIsDisabled &&
                  patientDeclineNavigationField.patientNavigationDeclined &&
                  patientDeclineNavigationField.patientNavigationDeclinedNotes.length > 0 &&
                  `${patientDeclineNavigationField.patientNavigationDeclinedNotes.length}/2000`}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="misc-footer">
        <div className="questions-footer-button-section">
          <div className="footer-buttons-div-container">
            <Button
              text={"Cancel"}
              className="cancel-button"
              disabled={isLoadingSaveButton}
              onClick={(e) => {
                handleCancelButton();
              }}
            />

            <Button
              text={"Save Changes"}
              className={`submit-button `}
              onClick={(e) => {
                handleSaveChanges();
              }}
              showLoader={isLoadingSaveButton}
              disabled={!isValueChanged || isLoadingSaveButton || isPatientNavigationIsDisabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default MiscPresentation;
