import "./transition-of-care.styles.scss";
import Header from "pages/task-management/header/header.component";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  getBrowserState,
  getCurrentPageTaskState,
  setIsCurrentTaskDirty,
  setIsCurrentTaskTouched,
} from "state/feature/task-management/task-management.slice";
import moment from "moment";

import TocRevision from "./toc-revisions/toc-revision.component";
import Button from "components/Button/button.component";
import CommonState from "components/common-state/common-state.component";
import { CommonStateType } from "components/common-state/common-state.enum";
import { RevisionReasonModalEnum, TOC_LISTING_HEADERS, TocStatusEnum } from "./constants/index.constant";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import {
  getTOCManagementState,
  addNewTocRevision,
  updateTocList,
  resetTocState,
  resetTocItemsForm,
  resetTocEditForm,
} from "state/feature/toc-management/toc-management.slice";
import { ITocRevisionReason, ITransitionOfCare } from "state/types/toc-management-slice.type";
import {
  getTocAsync,
  getIntakeAsync,
  getTocRevisionReasons,
  getTocStatuses,
} from "state/feature/toc-management/toc-management.action";
import { useAppDispatch } from "state/store";
import { getCommonState } from "state/feature/common/common.slice";
import useFacilities from "./hooks/useFacilities";
import { RevisionReasonModal } from "./modals/revision-reason-modal/revision-reason-modal.component";
import { IRevisionReasonModal } from "./modals/revision-reason-modal/props.type";
import useUrlBlocking from "shared/hooks/use-blocking.hook";
import { PatientDetailsHeader } from "components/patient-details-header/patient-details-header.component";

const TransitionOfCareContainer = () => {
  useUrlBlocking();
  useAxiosAuthenticated();
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const { currentTab } = useSelector(getBrowserState);
  const { patientDetails, isValidIntake, isValidatingIntake, intakeId, taskId, taskPayload } = useSelector(
    getCurrentPageTaskState(currentTab!.taskId)
  );
  const [hasScrollBar, setHasScrollBar] = useState(false);
  const [reasonModalPayload, setReasonModalPayload] = useState<IRevisionReasonModal | null>();
  const { isTocsLoading, tocs, intake, facilities, revisionReasons, isTocValueChanged } =
    useSelector(getTOCManagementState);
  const { featureFlags } = useSelector(getCommonState);
  const [isAllTocsApproved, setIsAllTocsApproved] = useState(true);
  const [isNewTocExists, setIsNewTocExists] = useState(false);
  const [revisionReason, setRevisionReason] = useState<ITocRevisionReason | null>();
  const ref = useRef<any>(null);
  const getFacilitiesWithDistance = useFacilities(intakeId);
  const prepareNewTocRevision = useCallback(() => {
    const version = tocs.length;
    const { id } = revisionReason || {};
    return {
      id: "",
      intakeId: parseInt(intakeId, 10),
      noteCareManager: "",
      version: version + 1,
      anticipatedAcuteLOS: 0,
      createdAt: moment().format("MM/DD/YYYY"),
      approvedAt: "",
      clientId: intake?.clientId,
      isExpanded: true,
      status: TocStatusEnum.NEW,
      reasonId: id,
    } as ITransitionOfCare;
  }, [intakeId, intake, tocs.length, revisionReason]);

  useEffect(() => {
    if (facilities.length) {
      getFacilitiesWithDistance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilities.length]);

  useEffect(() => {
    dispatch(setIsCurrentTaskTouched({ taskId, isTouched: isTocValueChanged }));
    if (isTocValueChanged) {
      dispatch(setIsCurrentTaskDirty({ taskId, isDirty: true }));
    }
  }, [isTocValueChanged, taskId, dispatch]);

  useEffect(() => {
    if (intakeId && isValidIntake && !isValidatingIntake && !taskPayload) {
      dispatch(getIntakeAsync(intakeId));
      dispatch(getTocRevisionReasons());
      dispatch(getTocStatuses());
    }
  }, [dispatch, appDispatch, intakeId, isValidIntake, isValidatingIntake, taskPayload]);

  useEffect(() => {
    if (intakeId && isValidIntake && !isValidatingIntake && !taskPayload) {
      appDispatch(getTocAsync(intakeId));
    }
  }, [dispatch, appDispatch, intakeId, isValidIntake, isValidatingIntake, taskPayload]);

  useEffect(() => {
    if (!isTocsLoading) {
      const isApproved = tocs.findIndex((el) => el.status === TocStatusEnum.PENDING) === -1;
      setIsAllTocsApproved(isApproved);

      const isNewTocPresent = tocs.findIndex((el) => el.status === TocStatusEnum.NEW) > -1;
      setIsNewTocExists(isNewTocPresent);
    }
  }, [tocs, intakeId, isTocsLoading, dispatch]);

  useEffect(() => {
    if (!isTocsLoading && tocs.length === 0 && intake && isAllTocsApproved) {
      dispatch(addNewTocRevision(prepareNewTocRevision()));
    }
  }, [tocs, intake, isTocsLoading, isAllTocsApproved, prepareNewTocRevision, dispatch]);

  const updateState = () => {
    const InfiniteScrollInnerDivElement = document.querySelector(".revision-table-body");
    if (InfiniteScrollInnerDivElement) {
      setHasScrollBar(InfiniteScrollInnerDivElement.scrollHeight > InfiniteScrollInnerDivElement.clientHeight);
    }
  };

  useEffect(() => {
    if (tocs.length > 0 && !isTocsLoading) {
      setTimeout(() => {
        updateState();
      }, 100);
      updateState();
    } else {
      updateState();
    }
    window.addEventListener("resize", updateState);
    return () => window.removeEventListener("resize", updateState);
  }, [tocs, isTocsLoading]);

  useEffect(() => {
    return () => {
      if (ref.current) {
        clearTimeout(ref.current);
      }
    };
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetTocState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (revisionReason) {
      if (!isAllTocsApproved) {
        const pendingToc = tocs.find((el) => el.status === TocStatusEnum.PENDING);

        if (pendingToc) {
          const updatedTocs = tocs.map((toc) => {
            if (toc.id === pendingToc?.id) {
              return { ...toc, status: TocStatusEnum.CANCELLED };
            }
            return { ...toc };
          });

          dispatch(updateTocList(updatedTocs));
        }
      }

      dispatch(resetTocEditForm());
      dispatch(addNewTocRevision(prepareNewTocRevision()));
      setRevisionReason(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, prepareNewTocRevision, revisionReason]);

  const openReasonModal = () => {
    setReasonModalPayload({
      isOpen: true,
      src: isAllTocsApproved ? RevisionReasonModalEnum.APPROVED_REVISION : RevisionReasonModalEnum.PENDING_REVISION,
      menuItems: revisionReasons,
      onCancel: () => {
        setReasonModalPayload({ ...reasonModalPayload, isOpen: false } as IRevisionReasonModal);
      },
      onCreate: (val: ITocRevisionReason | null) => {
        if (val) {
          setRevisionReason(val);
          setReasonModalPayload({ ...reasonModalPayload, isOpen: false } as IRevisionReasonModal);
        }
      },
      onModalClose: () => {
        setReasonModalPayload({ ...reasonModalPayload, isOpen: false } as IRevisionReasonModal);
      },
    });
  };

  const patientDetailsDisplay = useCallback(() => {
    if (patientDetails) {
      return (
        <>
          <PatientDetailsHeader patientDetails={patientDetails} intakeId={intakeId} />
        </>
      );
    }
  }, [patientDetails, intakeId]);

  const getTocsList = () => tocs.map((toc) => <TocRevision toc={toc} key={toc.id} taskId={taskId} />);

  return (
    <>
      {reasonModalPayload?.isOpen && <RevisionReasonModal {...reasonModalPayload} />}
      <div className="container">
        <div className="revisons">
          <div className="heading">
            <div className="info-container">
              <div className="patient-info">
                {!patientDetails ? null : (
                  <>
                    <Header className="toc" patientDetails={patientDetailsDisplay()} title="Transition Of Care" />
                  </>
                )}
              </div>
            </div>
            <div className="cta-container">
              <div className="new-revision-container">
                {isTocsLoading || isNewTocExists ? null : (
                  <Button
                    text="Create New Revision"
                    className={`green-button new-revision-cta`}
                    showLoader={false}
                    disabled={isTocsLoading}
                    onClick={() => {
                      openReasonModal();
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={`revision-table-container`}>
            <div
              className="revision-table"
              onClick={() => {
                ref.current = setTimeout(() => {
                  updateState();
                }, 100);
              }}
            >
              <div
                className={`revision-table-header ${
                  hasScrollBar
                    ? `revision-table-header-scrollbar-is-visible ${
                        featureFlags.taskManagement ? "task-details-visible" : ""
                      }`
                    : ""
                }`}
              >
                {TOC_LISTING_HEADERS.map(({ name }) => {
                  return (
                    <div className="revision-table-header-cell" key={name}>
                      <div className="revision-table-header-content">
                        <div className="name">{name}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="revision-table-body">
                {isTocsLoading ? <CommonState type={CommonStateType.LOADING} /> : getTocsList()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransitionOfCareContainer;
