import Modal from "components/Modal/modal.component";
import Button from "components/Button/button.component";
import "./file-downloading-popup-modal.style.scss";
import { Rings } from "react-loader-spinner";
import ErrorIcon from "shared/assets/images/download-error.svg";
import GreenTickIcon from "shared/assets/images/green-tick-download.svg";

type FileDownloadingPopupModalProps = {
  isOpen: boolean;
  handleCloseClick: () => void;
  className?: string;
  isLoading?: boolean;
  isError?: boolean;
  isEmpty?: boolean;
  errorMessage: string;
};

const FileDownloadingPopupModal = ({
  isOpen,
  handleCloseClick,
  className,
  isLoading,
  isError,
  errorMessage,
  isEmpty,
}: FileDownloadingPopupModalProps) => {
  return (
    <Modal isOpen={isOpen} className={`center file-downloading-popup-modal-container ${className ? className : ""}`}>
      <div className="file-downloading-popup-container">
        <div className="image">
          {isLoading ? <Rings color="#1aa99c" /> : <img src={isError || isEmpty ? ErrorIcon : GreenTickIcon} />}
        </div>

        <div className={`content-heading`}>
          {isLoading ? "Exporting Data..." : isError || isEmpty ? "Error!" : "Data Exported Sucessfully"}
        </div>
        {(isLoading || isError || isEmpty) && (
          <div className="content">
            {isLoading ? "process will be done in a few moments..." : isError ? errorMessage : "No data to export"}
          </div>
        )}
        <div className="button-section">
          <Button
            text={isLoading ? "Cancel" : "OK"}
            className={`${isLoading ? "white-button cancel-button" : "green-button"}`}
            onClick={handleCloseClick}
          />
        </div>
      </div>
    </Modal>
  );
};

export default FileDownloadingPopupModal;
