import { Pages } from "./risk-assessment.types";

export const TAB_LIST = [
  { name: "General Assessment", id: 1, isFilled: false },
  { name: "LACE Assessment", id: 2, isFilled: false },
  { name: "Closing Questions", id: 3, isFilled: false },
  { name: "Additional Questions", id: 4, isFilled: false },
];

export const getPageIdByPage = (page: Pages) => {
  switch (page) {
    case Pages.General:
      return "generalAssessment";
      break;
    case Pages.Lace:
      return "laceAssessment";
      break;
    case Pages.Closing:
      return "closingQuestions";
      break;
    case Pages.Additional:
      return "additionalQuestions";
      break;
  }
  return "";
};
