import Button from "components/Button/button.component";
import { CheckOnPatientFooterProps } from "../../common/check-on-patient.props";
import "./footer.style.scss";
import { TaskFooterButtonsText } from "shared/enums/task-management-footer.enum";
const CheckOnPatientFooter = (props: CheckOnPatientFooterProps) => {
  const {
    isDisabled,
    handleSubmitButton,
    isLoadingButton,
    handleCancelButton,
    handleOpenDelayButton,
    isDelayedTaskRequiresApproval = false,
    isDisabledDelayedTaskRequiresApproval,
    handleOpenManagerTaskApproval,
  } = props;
  return (
    <footer className="check-on-patient-footer-container">
      <Button
        text={TaskFooterButtonsText.CANCEL}
        className="cancel-button"
        disabled={false}
        onClick={handleCancelButton}
      />
      {handleOpenDelayButton && !isDelayedTaskRequiresApproval && (
        <Button
          text={TaskFooterButtonsText.DELAY}
          className="cancel-button"
          onClick={handleOpenDelayButton}
          disabled={isLoadingButton}
        />
      )}
      {handleOpenManagerTaskApproval && isDelayedTaskRequiresApproval && (
        <Button
          text={TaskFooterButtonsText.MANAGER_APPROVAL}
          className="manager-approval-button"
          onClick={handleOpenManagerTaskApproval}
          disabled={isDisabledDelayedTaskRequiresApproval || isLoadingButton}
        />
      )}
      {handleSubmitButton && (
        <Button
          text={TaskFooterButtonsText.SUBMIT}
          className={`submit-button`}
          onClick={() => handleSubmitButton()}
          disabled={isDisabled}
          showLoader={isLoadingButton}
        />
      )}
    </footer>
  );
};

export default CheckOnPatientFooter;
