import React from "react";
import Modal from "components/Modal/modal.component";
import ButtonLoader from "components/ButtonLoader/button-loader.component";
import "./screen-loader.styles.scss";

type ScreenLoaderProps = {
  isOpen: boolean;
};

const ScreenLoader = ({ isOpen }: ScreenLoaderProps) => {
  return (
    <Modal isOpen={isOpen} className="center">
      <div className="loader-container loaderButton">
        <ButtonLoader />
      </div>
    </Modal>
  );
};

export default ScreenLoader;
