import { useSelector } from "react-redux";
import {
  getBrowserState,
  getCurrentPageTaskState,
  getTaskManagementState,
  removeTab,
  setIsCurrentTaskDirty,
  setIsCurrentTaskTouched,
  setShouldPerformPostSubmitAction,
  setShowUnsavedChangesModal,
} from "state/feature/task-management/task-management.slice";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import { useCallback, useEffect, useRef } from "react";
import CustomDropDown from "components/CustomDropdown/custom-dropdown.component";
import Header from "../header/header.component";
import Error from "components/error-message/error-message.component";
import "./new-manual-task.styles.scss";
import { useAppDispatch } from "state/store";
import {
  addManualTaskAsync,
  getManualFieldTaskAsync,
  getManualTaskTypeAsync,
  getTaskOwnerMenuAsync,
} from "state/feature/new-manual-task/new-manual-task.action";
import {
  getNewManualTaskState,
  resetManualFieldsValues,
  resetNewManualTask,
  setCurrentManualTaskType,
  setIsDirty,
  setIsValueChanged,
} from "state/feature/new-manual-task/new-manual-task.slice";
import Button from "components/Button/button.component";
import SelectedManualTask from "./selected-manual-task/selected-manual-task.container";
import {
  getEndDateWithoutUTC,
  getPatientFullName,
  getStartDateWithoutUTC,
  isEmptyString,
} from "shared/methods/utilityFunctions";
import { getNavigatorState } from "state/feature/navigator/navigator.slice";
import { TaskPageName } from "shared/enums/page-name.enum";
import { toast } from "react-toastify";
import { TOAST_MESSAGES } from "pages/login/common/constants";
import { ITab } from "state/types/task-management-slice.type";
import { setLockState } from "state/feature/task-management/task-management.action";
import { getCommonState } from "state/feature/common/common.slice";
import { PatientDetailsHeader } from "components/patient-details-header/patient-details-header.component";
import { FormType } from "shared/enums/form-types.enum";
import { MANUAL_TASK_REMINDER_POST_SUBMIT_MESSAGE } from "./common/constants";

const NewManualTaskContainer = () => {
  const history = useHistory();
  const appDispatch = useAppDispatch();
  const { currentTab, pages } = useSelector(getBrowserState);
  const { redirectParams } = useSelector(getTaskManagementState);
  const { intakeId, taskId } = currentTab!;
  const { currentUserProfile } = useSelector(getNavigatorState);
  const {
    manualTaskType,
    currentManualTaskType,
    isDirty,
    manualTaskFieldsValues,
    isLoading,
    isLoadingAddTask,
    isValueChanged,
  } = useSelector(getNewManualTaskState);
  const { patientDetails } = useSelector(getCurrentPageTaskState(taskId));
  const { featureFlags } = useSelector(getCommonState);
  const ref = useRef<HTMLDivElement | null>(null);

  const getPatientDetails = useCallback(() => {
    if (patientDetails) {
      return <PatientDetailsHeader intakeId={intakeId} patientDetails={patientDetails} />;
    }
  }, [patientDetails, intakeId]);

  const handleCreateTask = async () => {
    try {
      appDispatch(setIsDirty(true));
      const ifAllAnswerNotSelected = Object.entries(manualTaskFieldsValues)
        .filter(([key, value]) => {
          if (currentManualTaskType?.taskTypeId && Number(currentManualTaskType?.taskTypeId) === FormType.REMINDER) {
            return key !== "notes" && key !== "requests";
          }
          return key === "notes" || key === "title";
        })
        .some(([key, value]) => {
          if (typeof value === "string") {
            return isEmptyString(value);
          }

          return value === null;
        });
      if (currentUserProfile && currentManualTaskType && intakeId && ifAllAnswerNotSelected === false && !isLoading) {
        const userFullName = getPatientFullName({
          firstName: currentUserProfile?.firstName,
          lastName: currentUserProfile?.lastName,
        });

        const startDate = getStartDateWithoutUTC(
          currentManualTaskType?.name.toLowerCase() === TaskPageName.REMINDER_TASK && manualTaskFieldsValues.startDate
            ? moment(manualTaskFieldsValues.startDate).toDate()
            : new Date()
        ).format("YYYY-MM-DDTHH:mm:ss");

        const dueDate = getEndDateWithoutUTC(
          currentManualTaskType?.name.toLowerCase() === TaskPageName.REMINDER_TASK &&
            manualTaskFieldsValues.dueDate !== null
            ? moment(manualTaskFieldsValues.dueDate).toDate()
            : new Date()
        ).format("YYYY-MM-DDTHH:mm:ss");

        const noteReminderManualTask = {
          title: manualTaskFieldsValues.title,
          taskOwners: manualTaskFieldsValues.taskOwner?.name ?? userFullName,
          taskOwnerUserId: manualTaskFieldsValues.taskOwner?.key ?? currentUserProfile.careManagerId,
          notes: manualTaskFieldsValues.notes ?? null,
          startDate,
          dueDate,
          requests: manualTaskFieldsValues.requests ?? null,
        };
        const requestPayload = {
          intakeId: parseInt(intakeId),
          createUser: userFullName,
          createUserId: currentUserProfile.id,
          noteReminderManualTask,
          taskTypeId: currentManualTaskType?.taskTypeId,
        };
        const resp = await appDispatch(addManualTaskAsync(requestPayload)).unwrap();
        if (resp && resp.status === 200 && resp.data) {
          appDispatch(setIsValueChanged(false));
          if (redirectParams) {
            const message =
              resp.data.message && !isEmptyString(resp.data.message)
                ? resp.data.message
                : TOAST_MESSAGES.DATA_SAVE_SUCCESSFULLY;
            setTimeout(() => {
              toast.success(message, { containerId: "main" });
            }, 1000);
          } else {
            if (!(featureFlags.taskManagement && featureFlags.taskManagementListView)) {
              history.goBack();
            } else {
              const message =
                currentManualTaskType?.taskTypeId && Number(currentManualTaskType?.taskTypeId) === FormType.REMINDER
                  ? MANUAL_TASK_REMINDER_POST_SUBMIT_MESSAGE
                  : undefined;
              appDispatch(setShouldPerformPostSubmitAction({ taskId, performAction: true, message }));
            }
          }
        } else {
          const message =
            resp.data.message && !isEmptyString(resp.data.message) ? resp.data.message : TOAST_MESSAGES.ERROR;
          toast.error(message, { containerId: "main" });
        }
      }
    } catch (err) {
      toast.error(TOAST_MESSAGES.ERROR, { containerId: "main" });
    }
  };

  const isPageDirty = (tab: ITab) => {
    const page = pages.find((el) => el.taskId === tab.taskId);
    if (!page) {
      return false;
    }
    return page.isDirty;
  };

  const onCancel = () => {
    if (isPageDirty(currentTab!)) {
      appDispatch(setShowUnsavedChangesModal(true));
    } else {
      appDispatch(setLockState({ intakeId: currentTab!.intakeId, taskId: currentTab!.taskId, isLocked: false }));
      if (featureFlags.taskManagement && featureFlags.taskManagementListView) {
        appDispatch(removeTab(currentTab!.taskId));
        return;
      }
    }
  };

  useEffect(() => {
    appDispatch(setIsCurrentTaskTouched({ taskId, isTouched: isValueChanged }));
    appDispatch(setIsCurrentTaskDirty({ taskId, isDirty: isValueChanged }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValueChanged]);

  const onManualTaskTypeSelect = (key: string) => {
    appDispatch(resetManualFieldsValues());
    appDispatch(setCurrentManualTaskType(manualTaskType.find((el) => el.key === key)));
  };

  useEffect(() => {
    appDispatch(getManualTaskTypeAsync());
    appDispatch(getManualFieldTaskAsync());
    appDispatch(getTaskOwnerMenuAsync({ types: ["PNAV", "TNAV"] }));
    return () => {
      appDispatch(resetNewManualTask());
    };
  }, [appDispatch]);

  return (
    <div id="new-manual-task-container">
      <Header className="reminder-task-header" patientDetails={getPatientDetails()} title="New Task" />
      <div className="new-manual-task-component">
        <div className="manual-task-type-dropdown question-section-dropdown-container task-owner">
          <p className="dropdown-title">Type</p>
          <CustomDropDown
            placeholder="Select the type of task"
            dropDownMenuItems={manualTaskType}
            handleValueChanges={(value) => {
              onManualTaskTypeSelect(value.key!);
            }}
            value={
              currentManualTaskType ? { key: currentManualTaskType.key, name: currentManualTaskType.name } : undefined
            }
            idFieldName="key"
            dropDownBoxClass=""
            selectionClass=""
            dropDownContainerClass="manual-task-type-dropdown"
            message="Please select the navigator type"
            dropdownRef={ref}
          />
          {isDirty && !currentManualTaskType && <Error message="This field is required" />}
        </div>
        <hr></hr>
        {currentManualTaskType ? (
          <SelectedManualTask />
        ) : (
          <div className="question-section empty-task-type">Select the Task type to get started...</div>
        )}
      </div>
      <footer id="new-manual-task-footer">
        <div className="footer-buttons-div-container">
          <Button text={"Cancel"} className="cancel-button" onClick={onCancel} />

          <Button
            text="Done"
            className="submit-button"
            showLoader={isLoadingAddTask}
            onClick={() => {
              if (!isLoadingAddTask || isLoading) handleCreateTask();
            }}
            disabled={isLoading || isLoadingAddTask}
          />
        </div>
      </footer>
    </div>
  );
};

export default NewManualTaskContainer;
