import { HeaderColumnInfo } from "components/table-with-pagination/table-with-pagination.component";
import { SortingOrderType } from "shared/enums/sorting-types.enums";

export const navigatorDashboardCallTableColumns = [
  {
    name: "",
    key: "callType",
  },
  {
    name: "Participants",
    key: "participants",
    sortOrder: SortingOrderType.DEFAULT,
  },
  {
    name: "Call Date/Start Time",
    key: "calltime",
    sortOrder: SortingOrderType.DESC,
  },
  {
    name: "Call Duration",
    key: "duration",
    sortOrder: SortingOrderType.DEFAULT,
  },
  {
    name: "",
    key: "actions",
    sortOrder: SortingOrderType.DEFAULT,
  },
];

export const inboundNavigatorDashboardCallTableColumns = [
  {
    name: "",
    key: "callType",
    order: 1,
  },
  {
    name: "Participants",
    key: "participants",
    sortOrder: SortingOrderType.DEFAULT,
    order: 2,
  },
  {
    name: "Call Date/Start Time",
    key: "calltime",
    sortOrder: SortingOrderType.DESC,
    order: 3,
  },
  {
    name: "Call Duration",
    key: "duration",
    sortOrder: SortingOrderType.DEFAULT,
    order: 4,
  },
  {
    name: "Original Recipient",
    key: "originalrecipient",
    sortOrder: SortingOrderType.DEFAULT,
    order: 5,
  },
  {
    name: "",
    key: "actions",
    sortOrder: SortingOrderType.DEFAULT,
    order: 7,
  },
];

export const navigatorDashboardMessageTableColumns = [
  {
    name: "Participants",
    key: "participants",
    sortOrder: SortingOrderType.DEFAULT,
  },
  {
    name: "Subject",
    key: "subject",
    sortOrder: SortingOrderType.DEFAULT,
  },
  {
    name: "Date/Time",
    key: "conversationTime",
    sortOrder: SortingOrderType.DESC,
  },
  {
    name: "Message",
    key: "message",
    sortOrder: SortingOrderType.DEFAULT,
  },
  {
    name: "",
    key: "actions",
  },
];

export const navigatorManagementTableColumns = [
  {
    name: "",
    sorting: false,
    key: "work-hour-status",
  },
  {
    name: "Navigator Name",
    sorting: false,
    key: "name",
  },
  {
    name: "Patients Assigned",
    sorting: false,
    key: "assignedPatients",
  },
  {
    name: "Email",
    sorting: false,
    key: "email",
  },
  {
    name: "Phone No.",
    sorting: false,
    key: "phone",
  },
  {
    name: "",
    key: "actions",
  },
];

export const managerContactTableColumns = [
  {
    name: "Patient",
    sorting: false,
    key: "patient",
  },
  {
    name: "Physician",
    sorting: false,
    key: "physician",
  },
  {
    name: "Procedure",
    sorting: false,
    key: "procedure",
  },
  {
    name: "Current Location",
    sorting: false,
    key: "currentLocation",
  },
];

export const contactsPatientTableColumns = [
  {
    name: "Patient",
    key: "name",
    sortOrder: SortingOrderType.ASC,
  },
  {
    name: "Physician",
    key: "physician",
    sortOrder: SortingOrderType.DEFAULT,
  },
  {
    name: "Procedure",
    key: "procedure",
    sortOrder: SortingOrderType.DEFAULT,
  },
  {
    name: "Location",
    key: "location",
    sortOrder: SortingOrderType.DEFAULT,
  },
  {
    name: "",
    key: "actions",
  },
];

export const contactsPracticeTableColumns = [
  {
    name: "Physician",
    key: "name",
    sortOrder: SortingOrderType.ASC,
  },
  {
    name: "No. of Patients",
    key: "noOfPatients",
    sortOrder: SortingOrderType.DEFAULT,
  },
  {
    name: "Practice Name",
    key: "practice",
    sortOrder: SortingOrderType.DEFAULT,
  },
  {
    name: "",
    key: "actions",
  },
];

export const inboundNavigatorManagerDashboardCallTableColumns = [
  {
    name: "Pri. Miss. Reason",
    key: "reasonForPrimaryUnavailability",
    sortOrder: SortingOrderType.DEFAULT,
    order: 6,
  },
  { name: "Secondary Recipient", key: "effectiveBackupNavigator", sortOrder: SortingOrderType.DEFAULT, order: 7 },
  { name: "Sec. Miss. Reason", key: "reasonForBackupUnavailability", sortOrder: SortingOrderType.DEFAULT, order: 8 },
  { name: "", key: "", order: 9 },
];
export const managerDashboardTable = [
  { name: "", key: "action" },
  { name: "Navigator Name", key: "name", sortOrder: SortingOrderType.ASC },
  { name: "Total Calls", key: "totalCallsCount", sortOrder: SortingOrderType.DEFAULT },
  { name: "Inbound Calls", key: "inboundCallsCount", sortOrder: SortingOrderType.DEFAULT },
  { name: "Outbound Calls", key: "outboundCallsCount", sortOrder: SortingOrderType.DEFAULT },
  { name: "Total Call Duration", key: "totalCallDuration", sortOrder: SortingOrderType.DEFAULT },
  { name: "Missed Calls", key: "missedCallsCount", sortOrder: SortingOrderType.DEFAULT },
  { name: "Pending Voicemails", key: "pendingVoicemailsCount", sortOrder: SortingOrderType.DEFAULT },
  { name: "Unread Messages", key: "unreadMessagesCount", sortOrder: SortingOrderType.DEFAULT },
];

export const getInitialEpisodesTableHeader = () => [
  { name: "Patient Name", key: "patientName", sortOrder: SortingOrderType.ASC },
  { name: "D.O.B", key: "dob", sortOrder: SortingOrderType.DEFAULT },
  { name: "Episodes Name", key: "episodename", sortOrder: SortingOrderType.DEFAULT },
  { name: "Admit Date ", key: "admitDate", sortOrder: SortingOrderType.DEFAULT },
  { name: "Surgery Date", key: "surgeryDate", sortOrder: SortingOrderType.DEFAULT },
  { name: "Status", key: "status", sortOrder: SortingOrderType.DEFAULT },
  { name: "Physician", key: "physician", sortOrder: SortingOrderType.DEFAULT },
  { name: "P Nav", key: "pnav", sortOrder: SortingOrderType.DEFAULT },
  { name: "T Nav", key: "tnav", sortOrder: SortingOrderType.DEFAULT },
];

export const getInitialEpisodeDetailsTaskTableHeader = (isAllowedChangeOwner?: boolean) => {
  const episodesTaskList = [
    { name: "Start Date", key: "startDate", sortOrder: SortingOrderType.DEFAULT },
    { name: "Due Date", key: "dueDate", sortOrder: SortingOrderType.ASC },
    { name: "Task", key: "taskName", sortOrder: SortingOrderType.DEFAULT },
    { name: "Status", key: "taskStatus", sortOrder: SortingOrderType.DEFAULT },
    { name: "Updated", key: "completedOn", sortOrder: SortingOrderType.DEFAULT },
    { name: "Updated by ", key: "doneBy", sortOrder: SortingOrderType.DEFAULT },
    { name: "Owner", key: "owner" },
    { name: "", key: "" },
  ];
  if (!isAllowedChangeOwner) {
    return episodesTaskList.filter((task) => task.key !== "owner");
  }
  return episodesTaskList;
};

export const getInitialEpisodeActivityHistoryHeader = () => [
  { name: "Date & Time", key: "dateTime" },
  { name: "", key: "sort-icon" },
  { name: "Task", key: "taskName" },
  { name: "Details", key: "details" },
];

export const getInitialEpisodePlanOfCareHeader = () => [
  { name: "Service", key: "serviceType", sortOrder: SortingOrderType.DEFAULT },
  { name: "Admission", key: "admitDate", sortOrder: SortingOrderType.ASC },
  { name: "Discharge", key: "dischargeDate", sortOrder: SortingOrderType.DEFAULT },
  { name: "Details", key: "details" },
  { name: "", key: "" },
];

export const getInitialAdminProvidersProviderHeader = (): Array<HeaderColumnInfo> => [
  { name: "Name", key: "name", sortOrder: SortingOrderType.ASC },
  { name: "Provider Name", key: "providerName" },
  { name: "Provider Type", key: "ProviderType" },
  { name: "Have Privileges At", key: "privelegesat" },
  { name: "", key: "" },
];
