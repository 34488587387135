import { CommonStateType } from "components/common-state/common-state.enum";
import TableWithPaginationPresentation, {
  HeaderColumnInfo,
} from "components/table-with-pagination/table-with-pagination.component";
import ProviderRow from "./provider-row-component";
import { getAdminState } from "state/feature/admin/admin.slice";
import { useSelector } from "react-redux";
import { getCommonState } from "state/feature/common/common.slice";
import Modal from "components/Modal/modal.component";
import ProviderEdit from "./provider-edit/provider-edit.component";

const ProviderPresentation = (props: {
  columnsHeaderInfo: Array<HeaderColumnInfo>;
  handleSort: (col: HeaderColumnInfo) => void;
  onPageChange: (pageCount: number) => void;
}) => {
  const {
    modal: { isOpenEditProviderModal },
  } = useSelector(getCommonState);
  const { columnsHeaderInfo, handleSort, onPageChange } = props;
  const {
    providers: { provider },
  } = useSelector(getAdminState);
  return (
    <div className="provider-table">
      {isOpenEditProviderModal && (
        <Modal className="provider-edit-modal" children={<ProviderEdit />} isOpen={isOpenEditProviderModal} />
      )}
      <TableWithPaginationPresentation
        columns={columnsHeaderInfo}
        emptyMessageType={CommonStateType.NO_PROVIDERS_FOUND}
        handleSort={handleSort}
        isLoading={provider.isLoading || provider.isLoadingPrivelegesList}
        rows={provider.records.map((data) => (
          <ProviderRow {...data} />
        ))}
        tableContainerClass="provider-table-container"
        countPerPage={provider.providerGetRequest.pageSize}
        currentPage={provider.providerGetRequest.pageCount + 1}
        totalCount={provider.totalCount}
        onPageChange={onPageChange}
      />
    </div>
  );
};

export default ProviderPresentation;
