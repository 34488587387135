import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ITaskBoard, ITaskManagementState, IPage, IBrowser, Episode } from "state/types/task-management-slice.type";
import {
  getPatientDetailsAsync,
  getTasksAsync,
  getPatientAndEpisodeDetailsAysnc,
  calculateRisk,
  getTaskDelayStatuses,
  getIntakeDetails,
  getDelayAttempts,
  getPatientLastLoginAsync,
  getTaskManagementTasksExistAsync,
} from "./task-management.action";
import { TaskListTab } from "shared/enums/page-name.enum";

const initialState: ITaskManagementState = {
  redirectParams: null,
  taskBoard: {
    isExpanded: true,
    selectedTaskIds: [],
    areTasksLoading: false,
    tasks: {
      active: [],
      delayed: [],
    },
    loadTaskList: false,
    selectedCareManagerIds: [],
    currentTaskListTab: TaskListTab.DELAYED,
    selectedTaskId: "",
  },
  browser: {
    currentTab: null,
    currentPage: null,
    tabs: [],
    pages: [],
    isPageLoading: false,
    showUnsavedChangesModal: false,
    requestedTaskPayload: null,
    showTabLimitModal: false,
  },
};

const taskManagementSlice = createSlice({
  name: "task-management",
  initialState: Object.assign({}, initialState),
  reducers: {
    addNewTab(state, action) {
      const { taskId, intakeId, taskType, title } = action.payload;
      state.browser.tabs = [...state.browser.tabs, { taskId, intakeId, taskType, title, isLoading: false }];
      state.browser.currentTab = state.browser.tabs.find((el) => el.intakeId === intakeId && el.taskId === taskId)!;
    },
    addNewPage(state, action) {
      state.browser.pages = [...state.browser.pages, action.payload];
    },
    removeTab(state, action) {
      const currentTabIdx = state.browser.tabs.findIndex((t) => t.taskId === action.payload);
      if (state.browser.tabs.length === 1) {
        state.browser.currentTab = null;
      } else {
        if (currentTabIdx === state.browser.tabs.length - 1) {
          state.browser.currentTab = state.browser.tabs[currentTabIdx - 1];
        } else {
          state.browser.currentTab = state.browser.tabs[currentTabIdx + 1];
        }
      }
      state.browser.tabs = state.browser.tabs.filter((t) => t.taskId !== action.payload);
      state.browser.pages = state.browser.pages.filter((t) => t.taskId !== action.payload);
      state.browser.currentPage = state.browser.pages.find((t) => t.taskId === action.payload) ?? null;
    },
    removePage(state, action) {
      state.browser.pages = state.browser.pages.filter((t) => t.taskId !== action.payload);
    },
    expandTaskBoard(state, action) {
      state.taskBoard.isExpanded = action.payload;
    },
    setShowUnsavedChangesModal(state, action) {
      state.browser.showUnsavedChangesModal = action.payload;
    },
    setShowTabLimitModal(state, action) {
      state.browser.showTabLimitModal = action.payload;
    },
    setShouldPerformPostSubmitAction(
      state,
      action: PayloadAction<{ taskId: string; performAction: boolean; message?: string }>
    ) {
      state.browser.pages = state.browser.pages.map((p) => {
        if (p.taskId === action.payload.taskId) {
          if (action.payload.message) {
            p.shouldPerformPostSubmitAction = { message: action.payload.message };
          } else {
            p.shouldPerformPostSubmitAction = action.payload.performAction;
          }
          p.isDirty = false;
        }
        return p;
      });
    },
    setShouldPerformPostDelayAction(state, action) {
      state.browser.pages = state.browser.pages.map((p) => {
        if (p.taskId === action.payload.taskId) {
          p.shouldPerformPostDelayAction = action.payload.performAction;
          p.isDirty = false;
        }
        return p;
      });
    },

    setShouldPerformPostTaskWaitingApprovalAction(
      state,
      action: PayloadAction<{ taskId: string; performAction: boolean }>
    ) {
      state.browser.pages = state.browser.pages.map((p) => {
        if (p.taskId === action.payload.taskId) {
          p.shouldPerformPostTaskWaitingApprovalAction = action.payload.performAction;
          p.isDirty = false;
        }
        return p;
      });
    },
    setShouldPerformPostTaskApprovedAction(state, action: PayloadAction<{ taskId: string; performAction: boolean }>) {
      state.browser.pages = state.browser.pages.map((p) => {
        if (p.taskId === action.payload.taskId) {
          p.shouldPerformPostTaskApprovedAction = action.payload.performAction;
          p.isDirty = false;
        }
        return p;
      });
    },
    setShouldPerformPostTaskRejectedAction(state, action: PayloadAction<{ taskId: string; performAction: boolean }>) {
      state.browser.pages = state.browser.pages.map((p) => {
        if (p.taskId === action.payload.taskId) {
          p.shouldPerformPostTaskRejectedAction = action.payload.performAction;
          p.isDirty = false;
        }
        return p;
      });
    },
    saveSelectedCareManagerIds(state, action) {
      state.taskBoard.selectedCareManagerIds = action.payload.ids;
      state.taskBoard.currentTaskListTab = action.payload.taskType;
    },
    setLoadTaskList(state, action) {
      state.taskBoard.loadTaskList = action.payload;
    },
    setCurrentTab(state, action) {
      state.browser.currentTab = action.payload;
    },
    setCurrentPage(state, action) {
      state.browser.currentPage = action.payload;
    },
    memoizeTaskPayloadForCurrentPage(state, action) {
      const currentTab = action.payload.currentTab;
      state.browser.pages = state.browser.pages.map((el) => {
        if (el.taskId === currentTab.taskId) {
          el.taskPayload = action.payload.taskPayload;
          el.isTouched = false;
        }
        return el;
      });
    },
    setIsPageLoading(state, action) {
      state.browser.isPageLoading = action.payload;
    },
    setIsCurrentTaskTouched(state, action) {
      state.browser.pages = state.browser.pages.map((el) => {
        if (el.taskId === action.payload.taskId) {
          el.isTouched = action.payload.isTouched;
        }
        return el;
      });
    },
    setIsCurrentTaskDirty(state, action) {
      state.browser.pages = state.browser.pages.map((el) => {
        if (el.taskId === action.payload.taskId) {
          el.isDirty = action.payload.isDirty;
        }
        return el;
      });
    },
    setRequestedTaskPayload(state, action) {
      state.browser.requestedTaskPayload = action.payload;
    },
    setRedirectParams(state, action) {
      state.redirectParams = action.payload;
    },
    resetTaskManagementState(state) {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    return (
      builder.addCase(getPatientDetailsAsync.pending, (state, action) => {
        state.browser.pages = state.browser.pages.map((p) => {
          if (p.intakeId === action.meta.arg.intakeId && p.taskId === action.meta.arg.taskId) {
            return {
              ...p,
              isValidatingIntake: true,
              isLoading: true,
              isValidIntake: false,
              allowIntakeAcess: true,
            };
          }
          return p;
        });
      }),
      builder.addCase(getPatientDetailsAsync.fulfilled, (state, action) => {
        state.browser.pages = state.browser.pages.map((p) => {
          if (p.intakeId === action.meta.arg.intakeId && p.taskId === action.meta.arg.taskId) {
            return {
              ...p,
              isValidatingIntake: false,
              isLoading: false,
              isValidIntake: action.payload ? true : false,
              patientDetails: { ...action.payload },
            };
          }
          return p;
        });
      }),
      builder.addCase(getPatientDetailsAsync.rejected, (state, action) => {
        state.browser.pages = state.browser.pages.map((p) => {
          if (p.intakeId === action.meta.arg.intakeId && p.taskId === action.meta.arg.taskId) {
            return {
              ...p,
              isValidatingIntake: false,
              isLoading: false,
              isValidIntake: false,
              allowIntakeAcess: (action.payload as any).status === 403 ? false : true,
            };
          }
          return p;
        });
      }),
      builder.addCase(getTasksAsync.pending, (state) => {
        state.taskBoard.areTasksLoading = true;
      }),
      builder.addCase(getTasksAsync.fulfilled, (state, action) => {
        state.taskBoard.areTasksLoading = false;
        if (action.meta.arg.taskType === TaskListTab.DELAYED) {
          const delayedTasks =
            action.meta.arg.offset === 0 ? action.payload : [...state.taskBoard.tasks.delayed, ...action.payload];
          state.taskBoard.tasks = {
            active: [],
            delayed: delayedTasks,
          };
        } else {
          const activeTasks =
            action.meta.arg.offset === 0 ? action.payload : [...state.taskBoard.tasks.active, ...action.payload];
          state.taskBoard.tasks = {
            active: activeTasks,
            delayed: [],
          };
        }
      }),
      builder.addCase(getTasksAsync.rejected, (state, action) => {
        state.taskBoard.areTasksLoading = false;
        if (action.meta.arg.offset === 0) {
          state.taskBoard.tasks = { active: [], delayed: [] };
        }
      }),
      builder.addCase(getPatientAndEpisodeDetailsAysnc.pending, (state, action) => {
        state.browser.pages = state.browser.pages.map((p) => {
          if (p.intakeId === action.meta.arg.intakeId && p.taskId === action.meta.arg.taskId) {
            return {
              ...p,
              isRightPanelLoading: true,
            };
          }
          return p;
        });
      }),
      builder.addCase(getPatientAndEpisodeDetailsAysnc.fulfilled, (state, action) => {
        state.browser.pages = state.browser.pages.map((p) => {
          if (p.intakeId === action.meta.arg.intakeId && p.taskId === action.meta.arg.taskId) {
            state.browser.currentPage = { ...p, isRightPanelLoading: false, episode: action.payload };
            return {
              ...p,
              isRightPanelLoading: false,
              episode: action.payload,
            };
          }
          return p;
        });
      }),
      builder.addCase(getPatientAndEpisodeDetailsAysnc.rejected, (state, action) => {
        state.browser.pages = state.browser.pages.map((p) => {
          if (p.intakeId === action.meta.arg.intakeId && p.taskId === action.meta.arg.taskId) {
            return {
              ...p,
              isRightPanelLoading: false,
              episode: null,
            };
          }
          return p;
        });
      }),
      builder.addCase(calculateRisk.pending, (state) => {}),
      builder.addCase(getTaskDelayStatuses.pending, (state, action) => {
        state.browser.pages = state.browser.pages.map((p) => {
          if (p.taskId === action.meta.arg) {
            p.isDelayTaskError = false;
            p.isDelayTaskLoading = true;
          }
          return p;
        });
      }),
      builder.addCase(getTaskDelayStatuses.fulfilled, (state, action) => {
        state.browser.pages = state.browser.pages.map((p) => {
          if (p.taskId === action.meta.arg) {
            p.isDelayTaskLoading = false;
            p.isDelayTaskError = false;
            p.delayTask.delayStatuses = action.payload;
          }
          return p;
        });
      }),
      builder.addCase(getTaskDelayStatuses.rejected, (state, action) => {
        state.browser.pages = state.browser.pages.map((p) => {
          if (p.taskId === action.meta.arg) {
            p.isDelayTaskLoading = false;
            p.isDelayTaskError = true;
            p.delayTask.delayStatuses = [];
          }
          return p;
        });
      }),
      builder.addCase(getIntakeDetails.pending, (state, action) => {
        state.browser.pages = state.browser.pages.map((p) => {
          if (p.taskId === action.meta.arg.taskId) {
            p.isDelayTaskError = false;
            p.isDelayTaskLoading = true;
          }
          return p;
        });
      }),
      builder.addCase(getIntakeDetails.fulfilled, (state, action) => {
        state.browser.pages = state.browser.pages.map((p) => {
          if (p.taskId === action.meta.arg.taskId) {
            p.isDelayTaskError = false;
            p.isDelayTaskLoading = false;
            p.delayTask.intakeDetails = action.payload;
          }
          return p;
        });
      }),
      builder.addCase(getDelayAttempts.pending, (state, action) => {
        state.browser.pages = state.browser.pages.map((p) => {
          if (p.taskId === action.meta.arg.taskId) {
            p.isDelayTaskError = false;
            p.isDelayTaskLoading = true;
          }
          return p;
        });
      }),
      builder.addCase(getDelayAttempts.fulfilled, (state, action) => {
        state.browser.pages = state.browser.pages.map((p) => {
          if (p.taskId === action.meta.arg.taskId) {
            p.isDelayTaskError = false;
            p.isDelayTaskLoading = false;
            p.delayTask.delayAttempts = action.payload;
          }
          return p;
        });
      }),
      builder.addCase(getPatientLastLoginAsync.fulfilled, (state, action) => {
        state.browser.pages = state.browser.pages.map((p) => {
          if (
            p.intakeId === action.meta.arg.intakeId &&
            p.taskId === action.meta.arg.taskId &&
            p.patientDetails &&
            action.payload
          ) {
            p.patientDetails = { ...p.patientDetails, lastLoginAt: action.payload };
            return p;
          }
          return p;
        });
      }),
      builder.addCase(getTaskManagementTasksExistAsync.fulfilled, (state, action) => {
        state.browser.pages = state.browser.pages.map((page) => {
          if (
            page.intakeId === action.meta.arg.intakeId &&
            page.taskId === action.meta.arg.taskId &&
            action.payload &&
            action.payload?.intakeStatusId
          ) {
            page.intakeStatusIdIfExistInCosmos = action.payload.intakeStatusId;
          }
          return page;
        });
      })
    );
  },
});
export const {
  expandTaskBoard,
  setCurrentTab,
  setCurrentPage,
  memoizeTaskPayloadForCurrentPage,
  addNewTab,
  addNewPage,
  removeTab,
  removePage,
  setIsPageLoading,
  saveSelectedCareManagerIds,
  setLoadTaskList,
  setIsCurrentTaskTouched,
  setIsCurrentTaskDirty,
  setShowUnsavedChangesModal,
  setRequestedTaskPayload,
  setShouldPerformPostSubmitAction,
  setShouldPerformPostDelayAction,
  setShowTabLimitModal,
  setRedirectParams,
  resetTaskManagementState,
  setShouldPerformPostTaskWaitingApprovalAction,
  setShouldPerformPostTaskApprovedAction,
  setShouldPerformPostTaskRejectedAction,
} = taskManagementSlice.actions;
export const getTaskManagementState = (state: any): ITaskManagementState => {
  return state.taskManagement;
};

export const getTaskBoardState = (state: any): ITaskBoard => {
  return state.taskManagement.taskBoard;
};

export const getBrowserState = (state: any): IBrowser => state.taskManagement.browser;

export const getCurrentPageTaskState =
  (taskId: string) =>
  (state: any): IPage =>
    state.taskManagement.browser.pages.find((el: { taskId: string }) => el.taskId === taskId);

export default taskManagementSlice;
