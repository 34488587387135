import Icon from "components/Icon/icon.component";
import { useSelector } from "react-redux";
import { getNotificationState } from "state/feature/notification/notification.slice";
import "./notification-badge-mobile.styles.scss";

const NotificationBadgeMobile = () => {
  const { unreadNotificationCount } = useSelector(getNotificationState);
  return (
    <div className="notification-icon-container-mobile">
      {unreadNotificationCount > 0 && (
        <div className="notification-count-badge">
          <p>{unreadNotificationCount > 99 ? "99+" : unreadNotificationCount}</p>
        </div>
      )}
      <Icon icon="notification-bell" className="notification-icon" size={20} />
    </div>
  );
};

export default NotificationBadgeMobile;
