import moment from "moment";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { getAssessmentState } from "state/feature/assessment/assessment.slice";
import { getBrowserState, getCurrentPageTaskState } from "state/feature/task-management/task-management.slice";
import Header from "../header/header.component";
import "./assessment.styles.scss";
import CommonNote from "pages/task-management/risk-assessment/risk-assessment-form-inputs/common-note/common-note.component";
import AssessmentFormInputs from "./assessment-form-inputs/assessment-form-inputs.components";
import CommonState from "components/common-state/common-state.component";
import { CommonStateType } from "components/common-state/common-state.enum";
import Icon from "components/Icon/icon.component";
import DelayModal from "components/Modal/delay-modal/delay-modal.container";
import { AssessmentPresentationProps } from "./assessment.props";
import { TaskStatus } from "shared/enums/tasks-status.enum";
import { PatientDetailsHeader } from "components/patient-details-header/patient-details-header.component";
import ManagerTaskApprovalModal from "components/Modal/manager-approval-task-modal/manager-approval-task-modal.container";

const AssessmentPresentation = (props: AssessmentPresentationProps) => {
  const { handleSubmitDelayTask } = props;
  const { workflowName, episodeType, questions, error, isLoading, taskStatusId, isRedirectEnabled, isAnswersExist } =
    useSelector(getAssessmentState);
  const { currentTab } = useSelector(getBrowserState);
  const {
    patientDetails,
    episode,
    delayTask: { delayAttempts },
  } = useSelector(getCurrentPageTaskState(currentTab!.taskId));
  const { intakeId } = currentTab!;
  const showDelayNote =
    (!isLoading &&
      taskStatusId !== TaskStatus.CLOSED &&
      taskStatusId !== TaskStatus.COMPLETED &&
      episode &&
      episode?.patientAndEpisodeDetails.coachPhone !== null &&
      delayAttempts &&
      delayAttempts?.length > 0 &&
      delayAttempts[0]?.taskDelayStatusId === 1) ??
    false;

  const checkIfAssessmentCompleted = () =>
    (!isRedirectEnabled && isAnswersExist) || taskStatusId === TaskStatus.COMPLETED;

  const patientDetailsDisplay = useCallback(() => {
    if (patientDetails) {
      return <PatientDetailsHeader patientDetails={patientDetails} intakeId={intakeId} />;
    }
  }, [patientDetails, intakeId]);

  return (
    <div className="assessment-presentation-container">
      <Header
        className="assessment"
        patientDetails={patientDetailsDisplay()}
        title={`${episodeType} ${workflowName}`}
        showDelayNote={showDelayNote}
      />

      <DelayModal handleSubmit={handleSubmitDelayTask} />
      <ManagerTaskApprovalModal handleSubmit={() => {}} />

      {checkIfAssessmentCompleted() && <div className="submitted-note">This assessment is already completed</div>}
      <div
        className={`assessment-question-container ${
          (taskStatusId === TaskStatus.COMPLETED && isRedirectEnabled) || showDelayNote ? "small-height" : "big-height"
        }`}
      >
        {questions.length === 0 && error && <CommonState type={CommonStateType.NO_TASK_FOUND} />}
        {!isLoading &&
          questions &&
          questions.length > 0 &&
          !error &&
          questions.map((item, index) => {
            const questionNumber = index + 1;
            return (
              <div
                key={item.questionId}
                className={`${item.isConclusion ? "conclusion-question" : "assessment-questions"}`}
              >
                {item.isConclusion ? (
                  <div className="conclusion-line" />
                ) : (
                  <div className="number">{questionNumber}.</div>
                )}
                <div className="question-section">
                  <div className="question-title">{item.title}</div>
                  <AssessmentFormInputs
                    options={item.selectionValues}
                    inputType={item.questionType}
                    selectionType={item.selectionType}
                    className={`${item.questionType}-${item.questionId}`}
                    questionId={item.questionId}
                    questions={item.questions}
                    maxLength={item.maxLength}
                    qTitle={item.title}
                    order={item.order}
                    selectedRange={item.selectedRange}
                    stepCount={item.stepCount}
                    placeHolder={item.placeHolder}
                    subTitle={item.subTitle}
                    isRequired={item.isRequired}
                    isConclusion={item.isConclusion}
                  />
                  {item.note && (
                    <CommonNote
                      className="assessment-note"
                      reminder={item.note}
                      image={<Icon icon="assessment-note" size={16} />}
                    />
                  )}
                </div>
              </div>
            );
          })}
        {isLoading && (
          <div className="loading-container">
            <CommonState type={CommonStateType.LOADING} />
          </div>
        )}
      </div>
    </div>
  );
};

export default AssessmentPresentation;
