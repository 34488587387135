import { createAsyncThunk } from "@reduxjs/toolkit";
import { getConfig, initConfiguration } from "config/config";
import { axiosInstance } from "shared/axios-instance";

export const checkIfFeatureEnabledAsync = createAsyncThunk(
  "common/checkIfFeatureEnabledAsync",
  async (requestPayload: { featureFlagName: string }) => {
    const response = await axiosInstance.get(
      `${getConfig().efusionBase}/api/v2/ExtFeature/${requestPayload.featureFlagName}`
    );
    return response;
  }
);

export const getConfigurationSettings = createAsyncThunk("common/getConfigurationSettings", async () => {
  return initConfiguration();
});
