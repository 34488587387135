import { getInitialsIfPictureNotPresent } from "shared/methods/taskSpecificMethods";
import { IMessageReadParticipantsType } from "shared/types/messageReadParticipant.type";
import MessageReadParticipantsModal from "components/Modal/MessageReadParticipantsModal/message-read-participant-modal.component";
type MessageReadParticipantsInitialsProps = {
  filteredReceiptDetailsForParticipants: Array<IMessageReadParticipantsType>;
  receiptDetailsForParticipants: Array<IMessageReadParticipantsType>;
  showReadParticipants: boolean;
  setShowReadParticipants: any;
  closeReadParticipants: any;
  id: string;
};
const MessageReadParticipantsInitials = ({
  filteredReceiptDetailsForParticipants,
  receiptDetailsForParticipants,
  showReadParticipants,
  setShowReadParticipants,
  closeReadParticipants,
  id,
}: MessageReadParticipantsInitialsProps) => {
  return (
    <div className="participant-read-container" key={`participant-read-container__${id}`}>
      <MessageReadParticipantsModal
        receiptDetailsForParticipants={receiptDetailsForParticipants}
        isOpen={showReadParticipants}
        handleCloseButton={closeReadParticipants}
        id={`message-read-participant__${id}`}
      />
      <div className="participant-read-initials" onClick={setShowReadParticipants} id={id}>
        {filteredReceiptDetailsForParticipants.map(
          (filteredreceiptdetailsforparticipant: IMessageReadParticipantsType) => {
            const readParticipantName =
              filteredreceiptdetailsforparticipant.fullName !== ""
                ? getInitialsIfPictureNotPresent({ name: filteredreceiptdetailsforparticipant?.fullName })
                : getInitialsIfPictureNotPresent({ name: filteredreceiptdetailsforparticipant?.identity });

            return (
              <div className="participant-read-initial" id={filteredreceiptdetailsforparticipant.sid}>
                <span>{readParticipantName}</span>
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};
export default MessageReadParticipantsInitials;
