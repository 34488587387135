import Icon from "components/Icon/icon.component";
import { useEffect, useCallback } from "react";
import "./toast-caller-incoming.styles.scss";
import { useTwilioClientService } from "shared/services/twilio/twilio-client.service";
import { useDispatch, useSelector } from "react-redux";
import {
  getCallState,
  saveCurrentCall,
  setCurrentIncomingCallNumber,
  setSeconds,
  incrementMinutes,
  incrementSeconds,
  setIsMinimizeIncomingCall,
} from "state/feature/call/call.slice";
import { CallPosition } from "shared/enums/call-position.enum";
import { toast } from "react-toastify";
import CommonState from "components/common-state/common-state.component";
import { getIncomingCallDetails, updateIncomingCallStatusAsync } from "state/feature/call/call.action";
import { Call } from "@twilio/voice-sdk";
import { CALLER_TYPES } from "shared/constant/caller-types-constants";
import { CommonStateType } from "components/common-state/common-state.enum";
import { getConfig } from "config/config";
import { getNavigatorState } from "state/feature/navigator/navigator.slice";
import { CallingStatus } from "shared/enums/calling-status.enum";
import { SortingOrderType } from "shared/enums/sorting-types.enums";
import { PAGE_LIMIT } from "shared/constant/commonConstants";
import { getEndDate, getStartDate } from "shared/methods/utilityFunctions";
import moment from "moment";
import { useIdleTimerContext } from "react-idle-timer";

const ToastCallerIncoming = (params: { connection: Call }) => {
  const dispatch = useDispatch();
  const { pause: pauseTimer, resume: resumeTimer, reset: resetTimer } = useIdleTimerContext();
  const twilioClient = useTwilioClientService();
  const { currentUserProfile } = useSelector(getNavigatorState);
  const {
    isLoadingIncomingCallDetails,
    currentIncomingCallNumber,
    currentIncomingCallDetails,
    currentCall,
    minutes,
    seconds,
    isMinimizeIncomingCall,
  } = useSelector(getCallState);

  useEffect(() => {
    resetTimer();
    pauseTimer();

    return () => {
      resumeTimer();
    };
  }, [pauseTimer, resumeTimer, resetTimer]);

  useEffect(() => {
    const fromNumber = params?.connection?.parameters?.From ? params.connection.parameters.From : "";
    dispatch(setCurrentIncomingCallNumber(fromNumber));
    dispatch(getIncomingCallDetails({ fromNumber }));
  }, [dispatch, params?.connection?.parameters]);

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds >= 59) {
        dispatch(setSeconds(0));
        dispatch(incrementMinutes());
      } else {
        dispatch(incrementSeconds());
      }
    }, 1000);

    return () => {
      clearInterval(myInterval);
    };
  }, [dispatch, seconds, currentCall]);

  const getSwitchPositionButton = () => {
    let iconClassName: string;
    let switchPosition: CallPosition;
    if (twilioClient.getCallPosition() == CallPosition.BottomLeft) {
      iconClassName = "position-change-right";
      switchPosition = CallPosition.BottomRight;
    } else {
      iconClassName = "position-change-left";
      switchPosition = CallPosition.BottomLeft;
    }
    return (
      <div
        className="swap-position-icon-container"
        onClick={(e) => {
          twilioClient.switchCallPosition(switchPosition);
        }}
      >
        <Icon className="swap-position-icon" icon={iconClassName} size={16} />{" "}
      </div>
    );
  };
  const getMuteUnmuteButton = () => {
    if (currentCall && currentCall.isMuted()) {
      return <Icon className="mute" icon="mute" size={isMinimizeIncomingCall ? 40 : 50} />;
    } else if (currentCall && !currentCall.isMuted()) {
      return <Icon className="mute" icon="unmute" size={isMinimizeIncomingCall ? 40 : 50} />;
    } else {
      return <Icon className="mute" icon="mute" size={isMinimizeIncomingCall ? 40 : 50} />;
    }
  };
  const connectCall = () => {
    pauseTimer();
    if (params && params.connection) {
      dispatch(
        updateIncomingCallStatusAsync({
          callSid: params.connection?.parameters?.CallSid,
          status: "in-progress",
          currentUserProfile,
        })
      );
      twilioClient.connectConference(params.connection, [], () => {
        dispatch(saveCurrentCall(null));
        toast.dismiss();
      });
    }
  };

  const rejectCall = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (params && params.connection) {
      localStorage.setItem("callStatus", CallingStatus.DISCONNECTED);
      twilioClient.disconnectConference(params.connection).then(() => {
        resumeTimer();
      });
    }
  };

  const getPatientNameElement = (className: string, callerName: string) => {
    const profileDetaileUrl =
      currentIncomingCallDetails?.intakeId !== 0
        ? `TIntakes/PatientProfile/${currentIncomingCallDetails?.intakeId}`
        : `TPatients/Details/${currentIncomingCallDetails?.patientId}`;

    return callerName !== "" && CALLER_TYPES.PATIENT === currentIncomingCallDetails?.callerType ? (
      <a
        href={
          currentIncomingCallDetails.isNewTaskEnabled
            ? `${location.origin}/episodes/${currentIncomingCallDetails.intakeId}`
            : `${getConfig().efusionBase}/${profileDetaileUrl}`
        }
        rel="external"
        target="_blank"
        className={className}
      >
        {callerName}
      </a>
    ) : (
      <div className={className}>{callerName}</div>
    );
  };
  const getCallerDetailsUI = useCallback(() => {
    if (currentIncomingCallDetails) {
      const {
        intakeId,
        callerName,
        callerType,
        phoneNumber,
        phoneType,
        episode,
        practice,
        providerName,
        primaryNavigator,
      } = currentIncomingCallDetails;
      const profileDetaileUrl =
        intakeId !== 0
          ? `TIntakes/PatientProfile/${currentIncomingCallDetails?.intakeId}`
          : `TPatients/Details/${currentIncomingCallDetails?.patientId}`;
      return (
        <>
          <div className={`call-data-grid ${callerType !== CALLER_TYPES.PATIENT ? "no-incoming-call-details" : ""}`}>
            {!isMinimizeIncomingCall && (
              <>
                <div className={`item-name`}>Caller</div>
                {getPatientNameElement("item-value item-value-two-row", callerName)}

                <div className={`item-name`}>Type</div>
                <div className={`item-value`}>{callerType}</div>

                {callerType === CALLER_TYPES.PATIENT && (
                  <>
                    <div className={`item-name`}>Phone</div>
                    <div className={`item-value`}>
                      {phoneNumber !== null && phoneNumber.trim() != "" ? `${phoneNumber} ${phoneType}` : "-"}
                    </div>
                  </>
                )}

                {callerType === CALLER_TYPES.PATIENT && (
                  <>
                    <div className={`item-name`}>Episode</div>
                    <div className={`item-value item-value-three-row`}>{episode}</div>
                  </>
                )}

                <div className={`item-name`}>Practice</div>
                <div className={`item-value item-value-two-row`}>{practice}</div>
              </>
            )}
            {callerType === CALLER_TYPES.PATIENT && (
              <>
                <div className={`item-name`}>Physician</div>
                <div className={`item-value item-value-one-row`}>{providerName}</div>

                {!isMinimizeIncomingCall && (
                  <>
                    <div className={`item-name`}>Pri. Nav.</div>
                    <div className={`item-value item-value-one-row`}>{primaryNavigator}</div>
                  </>
                )}
              </>
            )}
          </div>
        </>
      );
    }
    return (
      <>
        <div className={`call-data-grid ${!isMinimizeIncomingCall ? "no-incoming-call-details" : ""}`}>
          {!isMinimizeIncomingCall && (
            <>
              <div className={`item-name`}>Caller</div>
              <div className={`item-value`}>{currentIncomingCallNumber}</div>

              <div className={`item-name`}>Type</div>
              <div className={`item-value`}>N/A</div>

              <div className={`item-name`}>Episode</div>
              <div className={`item-value`}>N/A</div>
            </>
          )}
        </div>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIncomingCallDetails, currentIncomingCallNumber, isMinimizeIncomingCall]);

  const getHeadingForIncomingCall = () => {
    if (isMinimizeIncomingCall) {
      const callerName =
        currentIncomingCallDetails && currentIncomingCallDetails?.callerName !== ""
          ? currentIncomingCallDetails?.callerName
          : currentIncomingCallNumber;

      return getPatientNameElement("caller-name", callerName);
    } else {
      if (currentCall?.status() == "open") {
        return "Ongoing call";
      }
      return "Incoming Call";
    }
  };

  return (
    <div className="phone-floating-modal-content-container incoming-call-modal-container">
      <div className={`incoming-call-details ${isMinimizeIncomingCall ? "incoming-call-details-minimized" : ""}`}>
        {!isMinimizeIncomingCall && <br></br>}
        <div className="call-title">
          {getHeadingForIncomingCall()}
          {!isMinimizeIncomingCall && (
            <div onClick={() => dispatch(setIsMinimizeIncomingCall(true))} className="resize">
              <Icon icon="minimize" size={20} className="resize" />
            </div>
          )}
        </div>
        {!isMinimizeIncomingCall && <br></br>}
        {currentCall?.status() == "open" && !isMinimizeIncomingCall && (
          <div className="timer">
            {minutes === 0 && seconds === 0 ? (
              "00:00"
            ) : (
              <>
                {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
              </>
            )}
          </div>
        )}
        {isLoadingIncomingCallDetails || currentCall?.status() === "connecting" ? (
          <>
            <div className="loading-container">
              <CommonState type={CommonStateType.LOADING} />
            </div>
          </>
        ) : (
          getCallerDetailsUI()
        )}
      </div>

      <div className={`buttons ${isMinimizeIncomingCall ? "minimized-buttons" : ""}`}>
        {twilioClient.getCallPosition() == CallPosition.BottomRight && (
          <>
            <div>{getSwitchPositionButton()}</div>

            {isMinimizeIncomingCall && (
              <div onClick={() => dispatch(setIsMinimizeIncomingCall(false))} className="maximize resize">
                <Icon icon="maximize" size={15} />
              </div>
            )}
          </>
        )}
        {currentCall?.status() !== "pending" && twilioClient.getCallPosition() == CallPosition.BottomLeft && (
          <div
            data-tooltip="Mute/Unmute"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (currentCall.isMuted()) {
                currentCall.mute(false);
              } else {
                currentCall.mute(true);
              }
            }}
          >
            {getMuteUnmuteButton()}
          </div>
        )}
        {twilioClient.getCallPosition() == CallPosition.BottomLeft && currentCall?.status() !== "open" && (
          <div
            data-tooltip="Reject call"
            onClick={(e) => {
              rejectCall(e);
            }}
          >
            <Icon className="mute" icon="cut-call1" size={isMinimizeIncomingCall ? 40 : 50} />
          </div>
        )}
        {currentCall?.status() === "pending" && (
          <div className="answer-call" onClick={connectCall} data-tooltip="Answer call">
            <Icon icon="call-icon" size={isMinimizeIncomingCall ? 20 : 30} />
          </div>
        )}
        {currentCall?.status() === "open" && (
          <div
            data-tooltip="Reject call"
            onClick={(e) => {
              rejectCall(e);
            }}
          >
            <Icon icon="cut-call1" size={isMinimizeIncomingCall ? 40 : 70} />
          </div>
        )}

        {twilioClient.getCallPosition() == CallPosition.BottomLeft && (
          <>
            {isMinimizeIncomingCall && (
              <div onClick={() => dispatch(setIsMinimizeIncomingCall(false))} className="maximize resize">
                <Icon icon="maximize" size={15} />
              </div>
            )}
            <div>{getSwitchPositionButton()}</div>
          </>
        )}
        {twilioClient.getCallPosition() == CallPosition.BottomRight && currentCall?.status() != "open" && (
          <div
            data-tooltip="Reject call"
            onClick={(e) => {
              rejectCall(e);
            }}
          >
            <Icon className="mute" icon="cut-call1" size={isMinimizeIncomingCall ? 40 : 50} />
          </div>
        )}
        {currentCall?.status() != "pending" && twilioClient.getCallPosition() == CallPosition.BottomRight && (
          <div
            data-tooltip="Mute/Unmute"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (currentCall.isMuted()) {
                currentCall.mute(false);
              } else {
                currentCall.mute(true);
              }
            }}
          >
            {getMuteUnmuteButton()}
          </div>
        )}
      </div>
    </div>
  );
};

export default ToastCallerIncoming;
