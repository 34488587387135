import Header from "pages/task-management/header/header.component";
import ScoreTable from "./score-table/score-table.component";
import GaugeChart from "react-gauge-chart";
import "./risk-assessment-summary.styles.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getRiskAssessmentState,
  setShowPostAcuteTaskCompletionPopUp,
} from "state/feature/risk-assessment/risk-assessment.slice";
import CommonState from "components/common-state/common-state.component";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { getRiskAssessmentSummaryByIntakeIdAsync } from "state/feature/risk-assessment/risk-assessment.action";
import SummaryModal from "components/Modal/summary-modal/summary-modal.component";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import { useAppDispatch } from "state/store";
import AlertSection from "./alert-section/alert-section.component";
import { CommonStateType } from "components/common-state/common-state.enum";
import ResultModal from "components/Modal/result-modal/result-modal.component";
import moment from "moment";
import { getBrowserState, getCurrentPageTaskState } from "state/feature/task-management/task-management.slice";
import { RiskAssessmentView } from "shared/enums/page-name.enum";
import RiskAssessmentResult from "../result-view/risk-assessment-result.component";
import { PatientDetailsHeader } from "components/patient-details-header/patient-details-header.component";

const RiskAssessmentSummary = () => {
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const { isLoading, summary, staticModalVisible, showPostAcuteTaskCompletionPopUp, currentView } =
    useSelector(getRiskAssessmentState);
  const { currentTab } = useSelector(getBrowserState);
  const { taskId, intakeId } = currentTab!;
  const { patientDetails, isValidatingIntake, isValidIntake } = useSelector(getCurrentPageTaskState(taskId));
  const [highRiskSections, setHighRiskSections] = useState(0);
  const history = useHistory();
  useAxiosAuthenticated();

  useEffect(() => {
    if (intakeId) {
      setHighRiskSections(0);
      appDispatch(getRiskAssessmentSummaryByIntakeIdAsync({ intakeId }));
    }
  }, [appDispatch, history, intakeId]);

  useEffect(() => {
    if (summary && summary.sections && summary.sections.length > 0) {
      const sections = summary.sections;
      sections.forEach((sec: any) => {
        if (sec.score >= sec.riskScore) {
          setHighRiskSections((count) => count + 1);
        }
      });
    }
  }, [summary, dispatch, patientDetails]);

  const patientDetailsDisplay = useCallback(() => {
    if (!isLoading && !isValidatingIntake && patientDetails) {
      return <PatientDetailsHeader intakeId={intakeId} patientDetails={patientDetails} />;
    }
  }, [isLoading, isValidatingIntake, patientDetails, intakeId]);

  const getPercentageForGuage = () => {
    if (patientDetails && !isValidatingIntake) {
      if (!patientDetails.isPlannedEpisode) {
        return 1;
      } else if (highRiskSections > 0) {
        if (highRiskSections === summary.sections.length) {
          return 1;
        } else {
          return 0.8;
        }
      } else {
        return 0.2;
      }
    }
  };

  const getActionText = () => {
    if (patientDetails && !isValidatingIntake) {
      if (!patientDetails?.isPlannedEpisode) {
        return "High risk because episode is unplanned";
      } else if (highRiskSections === 0) {
        return "No Action Needed";
      } else {
        return "Action Needed";
      }
    }
  };

  return (
    <>
      {currentView === RiskAssessmentView.RESULT ? (
        <RiskAssessmentResult />
      ) : (
        <>
          {!isLoading &&
          !isValidIntake &&
          isValidIntake &&
          showPostAcuteTaskCompletionPopUp &&
          highRiskSections <= 0 &&
          patientDetails?.isPlannedEpisode ? (
            <ResultModal
              isOpen={true}
              headerDescription={"Patient is at Standard Risk"}
              handleOkClick={() => {
                dispatch(setShowPostAcuteTaskCompletionPopUp(false));
              }}
            />
          ) : null}
          {!isLoading && !isValidIntake && !isValidatingIntake && (
            <CommonState type={CommonStateType.INTAKE_NOT_FOUND} />
          )}
          {(isLoading || isValidatingIntake) && <CommonState type={CommonStateType.LOADING} />}
          {!isLoading && !isValidatingIntake && isValidIntake && summary?.sections?.length > 0 && (
            <div className="summary-container">
              <Header patientDetails={patientDetailsDisplay()} title="Risk Assessment Summary " />
              {!isLoading && !isValidatingIntake && isValidIntake && (
                <div className="summary-container-grid">
                  <div className="left-container">
                    <div className="gauge-chart-container">
                      <GaugeChart
                        id="gauge-chart"
                        nrOfLevels={2}
                        colors={["#34D399", "#F87171"]}
                        arcWidth={0.1}
                        percent={getPercentageForGuage()}
                        arcPadding={0}
                        arcsLength={[0.7, 0.3]}
                        hideText
                        cornerRadius={5}
                        needleColor="#333333"
                        needleBaseColor="#333333"
                      />
                      <div className="chart-text">
                        <div
                          className={`${
                            highRiskSections > 0 || !patientDetails?.isPlannedEpisode ? "high-risk" : "standard-risk"
                          }`}
                        >
                          {highRiskSections > 0 || !patientDetails?.isPlannedEpisode ? "High Risk" : "Standard Risk"}
                        </div>
                        <div className="action-needed">{getActionText()}</div>
                      </div>
                    </div>
                    {summary.alerts?.length ? <AlertSection alerts={summary.alerts} /> : null}
                  </div>
                  <div className="summary-table-container">
                    <ScoreTable
                      tableClassName="summary-container-table"
                      columns={[
                        {
                          name: "Category",
                        },
                        {
                          name: "Risk Score",
                        },
                        {
                          name: "Risk Level",
                        },
                      ]}
                      scores={summary?.sections}
                    />
                  </div>
                </div>
              )}
              <SummaryModal isOpen={staticModalVisible} />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default RiskAssessmentSummary;
