import { createSlice } from "@reduxjs/toolkit";
import { TOAST_MESSAGES } from "pages/login/common/constants";
import { toast } from "react-toastify";
import { IUserManagementState } from "state/types/user-management-slice.type";
import {
  saveNavigatorDetailsAsync,
  getAssociatedPhysiciansAsync,
  getClientsAsync,
  getPatientDetailsAsync,
  getPatientDetailsByIdAsync,
  getPhysicianDetailsByIdAsync,
  getUsersAsync,
  getUsersTypeAsync,
  savePatientDetailsAsync,
  savePhysicianAsync,
  getNavigatorByIdAsync,
  getExistingNavigatorByEmailAsync,
  getExistingNavigatorByIdFromCosmosAsync,
  getNavigatorManagersAsync,
  getPatientDetailsByPatientIdAsync,
} from "./user-management.action";

const initialState: IUserManagementState = {
  isError: false,
  isLoading: false,
  isLoadingAssociatedPhysicians: false,
  isLoadingPatientData: false,
  isLoadingNavigatorDataById: false,
  isLoadingNavigatorManagers: false,
  isPatientListLoading: false,
  isLoadSearch: false,
  isSavingData: false,
  isPatientSelected: false,
  users: null,
  currentUserDetails: null,
  managers: [],
  clients: [],
  physicians: [],
  patients: [],
  usertypes: [],
  currentPatientDetails: "",
  isUserValueChanged: false,
};

const userManagementSlice = createSlice({
  name: "user-management",
  initialState: Object.assign({}, initialState),
  reducers: {
    resetUsers(state: any) {
      state.users = null;
    },
    clearCurrentUserDetails(state: any) {
      state.currentUserDetails = null;
    },
    setIsPatientSelected(state, action) {
      state.isPatientSelected = action.payload;
    },
    setCurrentPatientDetails(state, action) {
      state.currentPatientDetails = action.payload;
    },
    resetUserManagementState: (state: any) => initialState,
    setUserValueChanged: (state, action) => {
      state.isUserValueChanged = action.payload;
    },
    setPhysicians: (state, action) => {
      state.physicians = action.payload;
    },
  },
  extraReducers: (builder) => {
    return (
      builder.addCase(getUsersAsync.pending, (state: any) => {
        state.isLoading = true;
        state.isError = false;
      }),
      builder.addCase(getUsersAsync.fulfilled, (state: any, action: any) => {
        state.isLoading = false;
        if (action.payload.length) {
          if (state.users === null) {
            state.users = [];
          }
          state.users = [...state.users, ...action.payload];
        } else {
          if (state.users === null) {
            state.users = [];
          }
        }
      }),
      builder.addCase(getUsersAsync.rejected, (state: any) => {
        state.isLoading = false;
        state.isError = true;
        state.users = [];
        state.isError = true;
        toast.error(TOAST_MESSAGES.ERROR, {
          containerId: "main",
          toastId: "error",
        });
      }),
      builder.addCase(getUsersTypeAsync.fulfilled, (state: IUserManagementState, action) => {
        state.usertypes = action.payload;
      }),
      builder.addCase(getUsersTypeAsync.rejected, (state) => {
        state.isError = false;
        toast.error(TOAST_MESSAGES.ERROR, {
          containerId: "main",
          toastId: "error",
        });
      }),
      builder.addCase(getPatientDetailsAsync.pending, (state: IUserManagementState) => {
        state.isPatientListLoading = true;
        state.isError = false;
      }),
      builder.addCase(getPatientDetailsAsync.fulfilled, (state: IUserManagementState, action) => {
        state.isPatientListLoading = false;
        state.patients = action.payload ? action.payload : [];
      }),
      builder.addCase(getPatientDetailsAsync.rejected, (state: IUserManagementState) => {
        state.isPatientListLoading = false;
        state.isError = true;
        toast.error(TOAST_MESSAGES.ERROR, {
          containerId: "main",
          toastId: "error",
        });
      }),
      builder.addCase(getAssociatedPhysiciansAsync.pending, (state: IUserManagementState) => {
        state.isLoading = true;
        state.isLoadingAssociatedPhysicians = true;
        state.isError = false;
      }),
      builder.addCase(getAssociatedPhysiciansAsync.fulfilled, (state: IUserManagementState, action) => {
        state.isLoading = false;
        state.isLoadingAssociatedPhysicians = false;
        state.physicians = action.payload ? action.payload : [];
      }),
      builder.addCase(getAssociatedPhysiciansAsync.rejected, (state: IUserManagementState) => {
        state.isLoading = false;
        state.isLoadingAssociatedPhysicians = false;
        state.isError = true;
        toast.error(TOAST_MESSAGES.ERROR, {
          containerId: "main",
          toastId: "error",
        });
      }),
      builder.addCase(getClientsAsync.pending, (state: IUserManagementState) => {
        state.isLoadSearch = true;
        state.isError = false;
      }),
      builder.addCase(getClientsAsync.fulfilled, (state: IUserManagementState, action) => {
        state.isLoadSearch = false;
        state.clients = action.payload ? action.payload : [];
      }),
      builder.addCase(getClientsAsync.rejected, (state: IUserManagementState) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(TOAST_MESSAGES.ERROR, {
          containerId: "main",
          toastId: "error",
        });
      }),
      builder.addCase(savePhysicianAsync.pending, (state: IUserManagementState) => {
        state.isLoading = true;
        state.isError = false;
        state.isSavingData = true;
      }),
      builder.addCase(savePhysicianAsync.fulfilled, (state: IUserManagementState, action: any) => {
        state.isLoading = false;
        state.isSavingData = false;
        state.isUserValueChanged = false;
        state.currentUserDetails = null;
        const successMessage = action.meta.arg.id ? "Physician updated successfully" : "Physician added successfully";
        toast.success(successMessage, {
          containerId: "main",
        });
      }),
      builder.addCase(savePhysicianAsync.rejected, (state: IUserManagementState, action: any) => {
        state.isLoading = false;
        state.isSavingData = false;
        state.isError = true;
        if (action.payload.status === 400) {
          toast.error(action.payload.data[0].message, {
            containerId: "main",
            toastId: "error",
          });
        } else {
          toast.error(TOAST_MESSAGES.ERROR, {
            containerId: "main",
            toastId: "error",
          });
        }
      }),
      builder.addCase(savePatientDetailsAsync.pending, (state: IUserManagementState) => {
        state.isSavingData = true;
        state.isError = false;
      }),
      builder.addCase(savePatientDetailsAsync.fulfilled, (state: IUserManagementState, action: any) => {
        state.isUserValueChanged = false;
        state.isSavingData = false;
        state.isUserValueChanged = false;
        state.currentUserDetails = null;
        const successMessage = action.meta.arg.id ? "Patient updated successfully" : "Patient added successfully";
        toast.success(successMessage, {
          containerId: "main",
        });
      }),
      builder.addCase(savePatientDetailsAsync.rejected, (state: IUserManagementState, action: any) => {
        state.isSavingData = false;
        state.isError = false;
        if (action.payload.status === 400) {
          toast.error(action.payload.data[0].message, {
            containerId: "main",
            toastId: "error",
          });
        } else {
          toast.error(TOAST_MESSAGES.ERROR, {
            containerId: "main",
            toastId: "error",
          });
        }
      }),
      builder.addCase(getPatientDetailsByIdAsync.pending, (state: IUserManagementState) => {
        state.isLoadingPatientData = true;
        state.isError = false;
      }),
      builder.addCase(getPatientDetailsByIdAsync.fulfilled, (state: IUserManagementState, action) => {
        state.isLoadingPatientData = false;
        state.currentUserDetails = action.payload?.currentDetails;
        state.currentPatientDetails = action.payload?.currentPatientDetails ?? "";
      }),
      builder.addCase(getPatientDetailsByIdAsync.rejected, (state: IUserManagementState) => {
        state.isLoadingPatientData = false;
        state.isError = true;
        toast.error(TOAST_MESSAGES.ERROR, {
          containerId: "main",
          toastId: "error",
        });
      }),
      builder.addCase(getPatientDetailsByPatientIdAsync.fulfilled, (state: IUserManagementState, action) => {
        if (action.payload.status === 204) {
          state.isError = true;
        }
      }),
      builder.addCase(getPatientDetailsByPatientIdAsync.rejected, (state: IUserManagementState) => {
        state.isLoadingPatientData = false;
        state.isError = true;
        toast.error(TOAST_MESSAGES.ERROR, {
          containerId: "main",
          toastId: "error",
        });
      }),
      builder.addCase(getPhysicianDetailsByIdAsync.pending, (state: IUserManagementState) => {
        state.isError = false;
      }),
      builder.addCase(getPhysicianDetailsByIdAsync.fulfilled, (state: IUserManagementState, action) => {
        state.isLoading = false;
        state.currentUserDetails = action.payload;
      }),
      builder.addCase(getPhysicianDetailsByIdAsync.rejected, (state: IUserManagementState) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(TOAST_MESSAGES.ERROR, {
          containerId: "main",
          toastId: "error",
        });
      }),
      builder.addCase(saveNavigatorDetailsAsync.pending, (state: IUserManagementState) => {
        state.isSavingData = true;
        state.isError = false;
      }),
      builder.addCase(saveNavigatorDetailsAsync.fulfilled, (state: IUserManagementState, action: any) => {
        state.isSavingData = false;
        state.isUserValueChanged = false;
        state.currentUserDetails = null;
        const successMessage = action.meta.arg.id ? "Navigator updated successfully" : "Navigator added successfully";
        toast.success(successMessage, {
          containerId: "main",
        });
      }),
      builder.addCase(saveNavigatorDetailsAsync.rejected, (state: IUserManagementState, action: any) => {
        state.isSavingData = false;
        state.isError = true;
        const { data, status } = action.payload;
        if (status === 400) {
          toast.error(data[0].message, {
            toastId: "invalid-format",
            containerId: "main",
          });
        } else {
          toast.error(TOAST_MESSAGES.ERROR, {
            containerId: "main",
            toastId: "error",
          });
        }
      }),
      builder.addCase(getNavigatorByIdAsync.pending, (state: IUserManagementState) => {
        state.isLoadingNavigatorDataById = true;
        state.isError = false;
      }),
      builder.addCase(getNavigatorByIdAsync.fulfilled, (state: IUserManagementState, action: any) => {
        state.isLoadingNavigatorDataById = false;
        if (action.payload.status === 204) {
          state.isError = true;
        } else {
          state.currentUserDetails = action.payload;
        }
      }),
      builder.addCase(getNavigatorByIdAsync.rejected, (state: IUserManagementState) => {
        state.isLoadingNavigatorDataById = false;
        state.isError = true;
        toast.error(TOAST_MESSAGES.ERROR, {
          containerId: "main",
          toastId: "error",
        });
      }),
      builder.addCase(getExistingNavigatorByIdFromCosmosAsync.pending, (state: IUserManagementState) => {
        state.isLoading = true;
        state.isError = false;
      }),
      builder.addCase(getExistingNavigatorByIdFromCosmosAsync.fulfilled, (state: IUserManagementState, action: any) => {
        state.isLoading = false;
        if (action.payload.status === 200) {
        } else if (action.payload.status === 204) {
          state.isError = true;
          toast.error(TOAST_MESSAGES.NO_ACCOUNT_EXIST, {
            containerId: "main",
            toastId: "error",
          });
        }
      }),
      builder.addCase(getExistingNavigatorByIdFromCosmosAsync.rejected, (state: IUserManagementState) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(TOAST_MESSAGES.ERROR, {
          containerId: "main",
          toastId: "error",
        });
      }),
      builder.addCase(getExistingNavigatorByEmailAsync.pending, (state: IUserManagementState) => {
        state.isLoading = true;
        state.isError = false;
      }),
      builder.addCase(getExistingNavigatorByEmailAsync.fulfilled, (state: IUserManagementState, action: any) => {
        state.isLoading = false;
        if (action.payload.status === 204) {
          state.isError = true;
          toast.error(TOAST_MESSAGES.NO_ACCOUNT_EXIST, {
            containerId: "main",
            toastId: "error",
          });
        } else {
          state.currentUserDetails = action.payload;
        }
      }),
      builder.addCase(getExistingNavigatorByEmailAsync.rejected, (state: IUserManagementState) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(TOAST_MESSAGES.ERROR, {
          containerId: "main",
          toastId: "error",
        });
      }),
      builder.addCase(getNavigatorManagersAsync.pending, (state: IUserManagementState) => {
        state.isLoadingNavigatorManagers = true;
        state.isError = false;
      }),
      builder.addCase(getNavigatorManagersAsync.fulfilled, (state: IUserManagementState, action: any) => {
        state.isLoadingNavigatorManagers = false;
        state.managers = action.payload;
      }),
      builder.addCase(getNavigatorManagersAsync.rejected, (state: IUserManagementState) => {
        state.isLoadingNavigatorManagers = false;
        state.isError = true;
        toast.error(TOAST_MESSAGES.ERROR, {
          containerId: "main",
          toastId: "error",
        });
      })
    );
  },
});

export const {
  resetUserManagementState,
  resetUsers,
  clearCurrentUserDetails,
  setIsPatientSelected,
  setCurrentPatientDetails,
  setUserValueChanged,
  setPhysicians,
} = userManagementSlice.actions;
export default userManagementSlice;
export const getUserManagementState = (state: any): IUserManagementState => state.userManagement;
