import { useEffect, useState } from "react";
import { getInitialEpisodeDetailsTaskTableHeader } from "shared/constant/table";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import { batch, useSelector } from "react-redux";
import { getEpisodesSlice, resetEpisodeDetailsTasksList } from "state/feature/episodes/episodes.slice";
import { getEpisodeTasksAsync, updateChangeTnavTaskOwnerAsync } from "state/feature/episodes/episodes.action";
import { SortingOrderType } from "shared/enums/sorting-types.enums";
import { useHistory, useParams } from "react-router";
import EpisodeDetailsTasksPresentation from "./episode-details-tasks.component";
import { HeaderColumnInfo } from "pages/episodes/types";
import { IEpisodeDetailTasksType } from "state/types/episodes-slice.type";
import { TaskPageName } from "shared/enums/page-name.enum";
import {
  getCommonState,
  setIsOpenMultipleTabPopup,
  setIsOpenTnavTaskOwnerChangeModal,
} from "state/feature/common/common.slice";
import { addNewTab, setRequestedTaskPayload } from "state/feature/task-management/task-management.slice";
import { ITab } from "state/types/task-management-slice.type";
import { getTaskAccess } from "state/feature/task-management/task-management.action";
import { useAppDispatch } from "state/store";
import { FormType } from "shared/enums/form-types.enum";
import { getTaskNameBasedOnTaskTypeId } from "shared/methods/utilityFunctions";
import { getBackUpNavigatorListAsync } from "state/feature/navigator/navigator.action";
import { INavigator } from "state/types/navigator.type";
import { getPatientFullName } from "shared/methods/utilityFunctions";
import { toast } from "react-toastify";
import { getAuth } from "state/feature/auth/auth.slice";
import { TOAST_MESSAGES } from "pages/login/common/constants";

const EpisodeDetailsTasks = () => {
  useAxiosAuthenticated();
  const { patientEpisodeDetails, episodeDetailTabs, isLoadingEpisodeDetailTabs, isNewIntakeCreation } =
    useSelector(getEpisodesSlice);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { intakeId } = useParams<{ intakeId: string }>();
  const initialRequestPayload = { intakeId: Number(intakeId), sortColumn: "dueDate", sortOrder: SortingOrderType.ASC };
  const {
    modal: { isOpenTnavTaskOwnerChangeModal },
    featureFlags,
  } = useSelector(getCommonState);
  const [headerColumnsInfo, setHeaderColumnsInfo] = useState(getInitialEpisodeDetailsTaskTableHeader());
  const [requestPayload, setRequestPayload] = useState<{
    intakeId: number;
    sortColumn: string;
    sortOrder: SortingOrderType;
  }>(initialRequestPayload);
  const [hasScrollbarVisible, setHasScrollbarVisible] = useState(false);
  const { user } = useSelector(getAuth);
  const [tnavList, setTnavList] = useState<Array<{ key: string; name: string }>>([]);
  const [isLoadingSubmitChangerOwner, setIsLoadingSubmitChangerOwner] = useState(false);
  const updateState = () => {
    const InfiniteScrollInnerDivElement = document.querySelector(
      ".episode-tasks-table-body .infinite-scroll-component"
    );
    if (InfiniteScrollInnerDivElement) {
      setHasScrollbarVisible(InfiniteScrollInnerDivElement.scrollHeight > InfiniteScrollInnerDivElement.clientHeight);
    }
  };

  const handleCancelTnavOwnerChangeButton = () => {
    dispatch(
      setIsOpenTnavTaskOwnerChangeModal({
        isOpen: false,
        careManagerId: "",
        intakeId: "",
        ownerName: "",
        ownerId: "",
        taskId: "",
        taskName: "",
      })
    );
  };

  const onChangeSort = (key: string, order: SortingOrderType) => {
    setRequestPayload((prev) => {
      return {
        ...prev,
        sortColumn: key,
        sortOrder: order,
      };
    });
  };

  const handleColumnSorting = (selectedColumn: HeaderColumnInfo) => {
    const index = headerColumnsInfo.findIndex((x) => x.key === selectedColumn.key);
    let sort: SortingOrderType = SortingOrderType.DEFAULT;
    switch (headerColumnsInfo[index].sortOrder) {
      case SortingOrderType.DESC:
      case SortingOrderType.DEFAULT:
        sort = SortingOrderType.ASC;
        break;
      case SortingOrderType.ASC:
        sort = SortingOrderType.DESC;
        break;
    }
    const tempHeaders = [...headerColumnsInfo];
    tempHeaders.forEach((header) => {
      if (header.sortOrder) {
        header.sortOrder = SortingOrderType.DEFAULT;
      }
    });
    tempHeaders[index].sortOrder = sort;
    batch(() => {
      onChangeSort(selectedColumn.key, sort);
    });
    setHeaderColumnsInfo(tempHeaders);
  };

  useEffect(() => {
    if (patientEpisodeDetails) {
      dispatch(getEpisodeTasksAsync(requestPayload));
    }
    return () => {
      handleCancelTnavOwnerChangeButton();
      dispatch(resetEpisodeDetailsTasksList());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientEpisodeDetails?.status, patientEpisodeDetails?.statusChangeReasonId, dispatch, requestPayload, intakeId]);

  const handleOpenTaskFromEpisodeScreen = (data: IEpisodeDetailTasksType) => {
    const taskName: string = getTaskNameBasedOnTaskTypeId(data.taskTypeId);
    const newTab: ITab = {
      taskId: data.id,
      intakeId,
      title: `${patientEpisodeDetails?.patientFirstName} ${patientEpisodeDetails?.patientLastName} | ${data.taskName}`,
      isLoading: false,
      taskType: taskName as TaskPageName,
    };
    dispatch(addNewTab(newTab));
    history.push("/task-management");
  };

  const onClickTask = (data: IEpisodeDetailTasksType) => {
    if (data.taskTypeId === FormType.TOC) {
      handleOpenTaskFromEpisodeScreen(data);
      return;
    }
    const taskName: string = getTaskNameBasedOnTaskTypeId(data.taskTypeId);
    dispatch(getTaskAccess({ taskId: data.id, intakeId: parseInt(intakeId) })).then((response) => {
      if (!response?.payload?.data) {
        handleOpenTaskFromEpisodeScreen(data);
      } else {
        dispatch(
          setRequestedTaskPayload({
            taskId: data.id,
            intakeId,
            title: `${patientEpisodeDetails?.patientFirstName} ${patientEpisodeDetails?.patientLastName} | ${data.taskName}`,
            taskType: taskName,
          })
        );
        dispatch(
          setIsOpenMultipleTabPopup({
            isOpen: true,
            ...response.payload.data,
          })
        );
      }
    });
  };

  useEffect(() => {
    updateState();
    window.addEventListener("resize", updateState);
    return () => window.removeEventListener("resize", updateState);
  }, [episodeDetailTabs.tasks, isLoadingEpisodeDetailTabs.tasks]);

  useEffect(() => {
    if (tnavList.length === 0 && isOpenTnavTaskOwnerChangeModal.isOpen) {
      dispatch(getBackUpNavigatorListAsync({ types: ["TNAV"] }))
        .unwrap()
        .then((result) => {
          const tnav =
            result.length > 0
              ? result.map((res: INavigator) => {
                  return { key: res.careManagerId, name: `${res.firstName}${res.lastName ? ` ${res.lastName}` : ""}` };
                })
              : [];
          setTnavList(tnav);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isOpenTnavTaskOwnerChangeModal.isOpen]);

  useEffect(() => {
    if (featureFlags.AllowTaskOwnerChange) {
      setHeaderColumnsInfo(getInitialEpisodeDetailsTaskTableHeader(featureFlags.AllowTaskOwnerChange));
    }
  }, [featureFlags.AllowTaskOwnerChange]);

  return (
    <EpisodeDetailsTasksPresentation
      columns={headerColumnsInfo}
      currentTableData={episodeDetailTabs.tasks}
      isLoading={isLoadingEpisodeDetailTabs.tasks}
      handleColumnSorting={handleColumnSorting}
      onClickTask={onClickTask}
      isNewIntakeCreation={isNewIntakeCreation}
      hasScrollbarVisible={hasScrollbarVisible}
      tnavList={tnavList}
      isLoadingSubmitChangerOwner={isLoadingSubmitChangerOwner}
      onClickEditIcon={(data) => {
        if (data.ownerId && data.ownerName) {
          dispatch(
            setIsOpenTnavTaskOwnerChangeModal({
              isOpen: true,
              careManagerId: data.ownerId,
              ownerName: data.ownerName,
              intakeId: intakeId,
              taskId: data.id,
              taskName: data.taskName,
              ownerId: data.ownerId,
            })
          );
        }
      }}
      handleChangeOwnerCancel={handleCancelTnavOwnerChangeButton}
      handleChangeOwnerSubmit={(data) => {
        setIsLoadingSubmitChangerOwner(true);
        const payload = {
          intakeId: isOpenTnavTaskOwnerChangeModal.intakeId ? Number(isOpenTnavTaskOwnerChangeModal.intakeId) : -1,
          taskId: isOpenTnavTaskOwnerChangeModal.taskId,
          updateUser: getPatientFullName({
            firstName: user?.firstName,
            lastName: user?.lastName,
          }),
          updateUserId: user.navigatorId ?? "",
          ownerName: data.ownerName,
          ownerId: data.ownerId,
        };
        dispatch(updateChangeTnavTaskOwnerAsync(payload))
          .then((res) => {
            dispatch(resetEpisodeDetailsTasksList());
            dispatch(getEpisodeTasksAsync(initialRequestPayload));
            handleCancelTnavOwnerChangeButton();
            toast.success("Task owner updated successfully", { containerId: "main" });
          })
          .catch((error) => {
            toast.error(TOAST_MESSAGES.ERROR, {
              containerId: "main",
              toastId: "error",
            });
          })
          .finally(() => {
            setIsLoadingSubmitChangerOwner(false);
          });
      }}
    />
  );
};
export default EpisodeDetailsTasks;
