import { Call, Device } from "@twilio/voice-sdk";
import ToastCaller from "components/toast-caller-outgoing/toast-caller.component";
import React, { createContext, useContext, useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getAuth } from "state/feature/auth/auth.slice";
import { generateTwilioTokenAsync, updateIncomingCallStatusAsync } from "state/feature/call/call.action";
import {
  clearCall,
  clearTwilioToken,
  getCallState,
  saveCurrentCall,
  saveTwilioCallDevice,
  setIsMinimizeIncomingCall,
} from "state/feature/call/call.slice";
import { CallPosition } from "../../enums/call-position.enum";
import { TwilioClientServiceProps } from "./twilio-client.service.props";
import IncomingCallToast from "components/toast-caller-incoming/toast-caller-incoming.component";
import { checkIfFeatureEnabledAsync } from "state/feature/common/common.action";
import store, { useAppDispatch } from "state/store";
import { Refresh } from "components/RefreshComponent/refresh.component";
import { setTwilioErrorCode } from "state/feature/common/common.slice";
import { CallingStatus } from "shared/enums/calling-status.enum";
import { useIdleTimerContext } from "react-idle-timer";
import { NavigatorActionsEnum } from "shared/enums/navigator-actions-enum";
import { logNavigatorAction } from "state/feature/navigator/navigator.action";

const TwilioClientServiceContext = createContext<any>({});

export const TwilioClientServiceProvider = (props: any) => {
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const authState = useSelector(getAuth);
  const callState = useSelector(getCallState);
  const [callPosition, setCallPosition] = useState(CallPosition.BottomRight);
  const [currentIncomingCall, setCurrentIncomingCall] = useState<Call | null>();
  const { pause, reset } = useIdleTimerContext();

  useEffect(() => {
    if (authState.auth.isAuthenticated) {
      dispatch(checkIfFeatureEnabledAsync({ featureFlagName: "inboundCalls" }));
    }
  }, [dispatch, authState.auth.isAuthenticated]);

  const disconnectCallWhenReload = (e: any) => {
    e.returnValue = null;
  };

  useEffect(() => {
    if (currentIncomingCall?.status() === "pending") {
      window.addEventListener("beforeunload", disconnectCallWhenReload);
    } else {
      window.removeEventListener("beforeunload", disconnectCallWhenReload);
    }

    return () => window.removeEventListener("beforeunload", disconnectCallWhenReload);
  }, [currentIncomingCall]);

  const value: TwilioClientServiceProps = {
    switchCallPosition: async (position: CallPosition) => {
      toast.update("call", {
        position: position == CallPosition.BottomRight ? "bottom-right" : "bottom-left",
      });
      setCallPosition(position);
    },
    getCallPosition: () => {
      return callPosition;
    },
    generateToken: async () => {
      if (!callState.twilioAcessToken) {
        dispatch(generateTwilioTokenAsync({ email: authState.user.email }));
      }
    },
    registerIncomingEvent: (device: any) => {
      console.log("::incoming event -> register incoming event call");
      const currentUserProfile = store.getState().navigator?.currentUserProfile;
      console.log(device, "::fetching device from callState");
      console.log(currentUserProfile, "::current user profile fetched properly");
      device.on("incoming", (connection: Call) => {
        console.log("::incoming event binded");
        /* Please do not change this as twilio device is not getting updated in realtime, so this is only to update the device _calls array in realtime */
        device._calls.push();
        if (device.isBusy) {
          console.log("::incoming event -> device busy");
          connection.reject();
        } else if (device._calls.length > 1) {
          device._calls.forEach((item: Call, index: number) => {
            if (index > 0) {
              item.reject();
              dispatch(
                updateIncomingCallStatusAsync({
                  callSid: item?.parameters?.CallSid,
                  status: "missed",
                  currentUserProfile,
                  isAutoRejected: true,
                  rejectedDueToError: false,
                })
              );
            }
          });
          console.log("::incoming event -> multiple calls condition triggered");
          return;
        }
        console.log(currentIncomingCall, "::incoming event -> Current incoming call");
        console.log(device.isBusy, "::incoming event -> is device busy");
        if (!device.isBusy && !currentIncomingCall) {
          const incomingCallEvent = new CustomEvent("incomingCall", {
            detail: {
              toastId: "call",
              connection,
            },
          });
          window.dispatchEvent(incomingCallEvent);
          console.log("::incoming event -> device not busy and current incoming call not present condition triggered");
          if (currentUserProfile) {
            console.log("::incoming event -> if current user profile present trigger ringing event");
            dispatch(
              updateIncomingCallStatusAsync({
                callSid: connection?.parameters?.CallSid,
                status: "ringing",
                currentUserProfile,
              })
            );
          } else {
            console.log("::incoming event -> if current user profile is null show toast");
            toast.error("Logged in user not loaded properly, please login again", {
              toastId: "logged-in-problem",
              containerId: "main",
            });
          }
          console.log("::incoming event -> set current incoming call");
          setCurrentIncomingCall(connection);
          dispatch(saveCurrentCall(connection));
          console.log("::incoming event -> set ringing in local storage");
          localStorage.setItem("callStatus", CallingStatus.RINGING);
          console.log("::incoming event -> show incoming calling popup");
          toast.info(<IncomingCallToast connection={connection} />, {
            position: callPosition == CallPosition.BottomRight ? "bottom-right" : "bottom-left",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            containerId: "calling",
            toastId: "call",
            pauseOnFocusLoss: false,
          });
          connection.on("reconnecting", (twilioError) => {
            console.log("::incoming event -> ::reconnecting event -> error code:", twilioError.code);
            console.log("::incoming event -> ::reconnecting event -> error event:", twilioError);
            switch (twilioError.code) {
              case 53001:
                dispatch(
                  updateIncomingCallStatusAsync({
                    callSid: connection?.parameters?.CallSid,
                    status: "error",
                    currentUserProfile,
                  })
                );
                const callEvent = new CustomEvent("incomingCallDisconnect", {
                  detail: {
                    toastId: "call",
                  },
                });
                dispatch(
                  logNavigatorAction({
                    createdBy: currentUserProfile?.id ?? "",
                    createdByUser: `${currentUserProfile?.firstName} ${currentUserProfile?.lastName}`,
                    type: NavigatorActionsEnum.TWILIO_DISCONNECTED,
                  })
                );
                window.dispatchEvent(callEvent);
                dispatch(saveCurrentCall(null));
                dispatch(clearCall());
                setCurrentIncomingCall(null);
                device.unregister();
                device.destroy();
                toast.error(<Refresh />, {
                  containerId: "main",
                  toastId: "twilio-error",
                  autoClose: false,
                });
                break;
              default:
                break;
            }
          });
          connection.on("error", (error) => {
            console.log("::incoming event -> ::error event -> ", error);
            const callEvent = new CustomEvent("incomingCallDisconnect", {
              detail: {
                toastId: "call",
              },
            });
            window.dispatchEvent(callEvent);
            dispatch(clearCall());
            dispatch(saveCurrentCall(null));

            toast.error(
              "The microphone is currently disabled in your browser. You must enable the microphone and reload the browser (which disconnects the current call) to resolve the issue",
              { containerId: "main", toastId: "twilio-error" }
            );
          });
          connection.on("cancel", (call: Call) => {
            console.log("::incoming event -> ::cancel event triggered");
            const callEvent = new CustomEvent("incomingCallDisconnect", {
              detail: {
                toastId: "call",
                call,
              },
            });
            window.dispatchEvent(callEvent);
            if (call === undefined && localStorage.getItem("callStatus") !== CallingStatus.CONNECTED) {
              dispatch(
                updateIncomingCallStatusAsync({
                  callSid: connection?.parameters?.CallSid,
                  status: "missed",
                  currentUserProfile,
                  isCallCancelled: true,
                })
              );
            }
            dispatch(clearCall());
            dispatch(saveCurrentCall(null));
            dispatch(setIsMinimizeIncomingCall(false));
            setCallPosition(CallPosition.BottomRight);
            setCurrentIncomingCall(undefined);
            if (localStorage.getItem("callStatus") === CallingStatus.RINGING) {
              localStorage.setItem("callStatus", CallingStatus.DISCONNECTED);
            } else if (localStorage.getItem("callStatus") === CallingStatus.CONNECTED) {
              reset();
              pause();
            }
          });
          connection.on("disconnect", (call: Call) => {
            console.log("::incoming event -> ::disconnect event triggered");
            localStorage.setItem("callStatus", CallingStatus.DISCONNECTED);
            const callEvent = new CustomEvent("incomingCallDisconnect", {
              detail: {
                toastId: "call",
                call,
              },
            });
            window.dispatchEvent(callEvent);
            call.disconnect();
            dispatch(clearCall());
            dispatch(saveCurrentCall(null));
            setCallPosition(CallPosition.BottomRight);
            dispatch(setIsMinimizeIncomingCall(false));
            setCurrentIncomingCall(undefined);
          });
        } else {
          dispatch(
            updateIncomingCallStatusAsync({
              callSid: connection?.parameters?.CallSid,
              status: "missed",
              currentUserProfile,
              isAutoRejected: false,
              rejectedDueToError: false,
              isRejected: true,
            })
          );
          connection.reject();
        }
      });
      dispatch(saveTwilioCallDevice(device));
      dispatch(
        logNavigatorAction({
          createdBy: currentUserProfile?.id ?? "",
          createdByUser: `${currentUserProfile?.firstName} ${currentUserProfile?.lastName}`,
          type: NavigatorActionsEnum.TWILIO_CONNECTED,
        })
      );
    },
    registerDevice: () => {
      if (callState.twilioAcessToken) {
        const twilioDevice = new Device(callState.twilioAcessToken, {
          logLevel: 1,
          codecPreferences: [Call.Codec.Opus, Call.Codec.PCMU],
          closeProtection: "A call is currently in progress. Leaving or reloading the page will end the call.",
          allowIncomingWhileBusy: true,
          forceAggressiveIceNomination: false,
          tokenRefreshMs: 10000,
        });

        twilioDevice.on("tokenWillExpire", () => {
          dispatch(clearTwilioToken());
        });

        twilioDevice.on("registered", () => {
          console.log("::register device method called -> registering device");
          value.registerIncomingEvent(twilioDevice);
        });
        twilioDevice.on("error", (error) => {
          dispatch(setTwilioErrorCode(error.code));
          const currentCall = store.getState().call.currentCall;
          const currentUserProfile = store.getState().navigator?.currentUserProfile;
          const callEvent = new CustomEvent("incomingCallDisconnect", {
            detail: {
              toastId: "call",
            },
          });
          switch (error.code) {
            case 31005:
              if (currentCall && currentCall._wasConnected !== null && currentCall._wasConnected) {
                twilioDevice.disconnectAll();
              } else if (currentCall && currentCall._wasConnected !== null && !currentCall._wasConnected) {
                dispatch(
                  updateIncomingCallStatusAsync({
                    callSid: currentCall?.parameters?.CallSid,
                    status: "missed",
                    currentUserProfile,
                    isAutoRejected: false,
                    rejectedDueToError: true,
                  })
                );
                twilioDevice.disconnectAll();
              }
              window.dispatchEvent(callEvent);
              dispatch(saveCurrentCall(null));
              dispatch(clearCall());
              setCurrentIncomingCall(null);
              if (currentCall) {
                currentCall.disconnect();
                currentCall.reject();
              }
              if (twilioDevice) {
                twilioDevice.disconnectAll();
                if (twilioDevice.state === "registered") {
                  twilioDevice.unregister();
                }
                twilioDevice.destroy();
              }
              dispatch(
                logNavigatorAction({
                  createdBy: currentUserProfile?.id ?? "",
                  createdByUser: `${currentUserProfile?.firstName} ${currentUserProfile?.lastName}`,
                  type: NavigatorActionsEnum.TWILIO_DISCONNECTED,
                })
              );
              toast.error(<Refresh />, {
                containerId: "main",
                toastId: "twilio-error",
                autoClose: false,
                closeButton: false,
                closeOnClick: false,
                draggable: false,
                style: {
                  cursor: "default",
                },
              });
              break;
            default:
              break;
          }
        });
        twilioDevice.register();
        dispatch(saveTwilioCallDevice(twilioDevice));
      }
    },
    sendDigits: (digit: string) => {
      if (callState.currentCall) {
        callState.currentCall.sendDigits(digit);
      }
    },
    addParticipantsAndCreateConference: async (
      to: string,
      from: string,
      participants: any[],
      clientId: string,
      effectiveNavigator: { id: string; name: string; phoneNumber: string },
      intakeId: number,
      postCallback: () => void
    ) => {
      const isMicrophoneAvailable = await navigator.mediaDevices.getUserMedia({ audio: true });
      if (isMicrophoneAvailable) {
        const incomingCallEvent = new CustomEvent("incomingCall", {
          detail: {
            toastId: "call",
          },
        });
        window.dispatchEvent(incomingCallEvent);
        const participantsExceptNavigator = participants.filter((x) => x.type.toLowerCase() !== "navigator");
        const twilioCall = await callState.twilioCallDevice.connect({
          params: {
            To: to,
            From: from ?? "",
            ClientId: clientId,
            Participants: JSON.stringify(participants),
            EffectiveNavigator: JSON.stringify(effectiveNavigator),
            intakeId,
          },
        });
        twilioCall.on("cancel", (call: Call) => {
          const callEvent = new CustomEvent("incomingCallDisconnect", {
            detail: {
              toastId: "call",
              call,
            },
          });
          window.dispatchEvent(callEvent);
          call.disconnect();
          dispatch(clearCall());
          dispatch(saveCurrentCall(null));
          setCallPosition(CallPosition.BottomRight);
          dispatch(setIsMinimizeIncomingCall(false));
          setCurrentIncomingCall(undefined);
        });
        twilioCall.on("disconnect", (call: Call) => {
          localStorage.setItem("callStatus", CallingStatus.DISCONNECTED);
          const callEvent = new CustomEvent("incomingCallDisconnect", {
            detail: {
              toastId: "call",
              call,
            },
          });
          window.dispatchEvent(callEvent);
          callState.twilioCallDevice.disconnectAll();
          dispatch(clearCall());
          dispatch(saveCurrentCall(null));
          setCallPosition(CallPosition.BottomRight);
          dispatch(setIsMinimizeIncomingCall(false));
          setCurrentIncomingCall(undefined);
        });
        dispatch(saveCurrentCall(twilioCall));
        dispatch(clearCall());
        toast.info(<ToastCaller participants={participantsExceptNavigator} />, {
          position: callPosition == CallPosition.BottomRight ? "bottom-right" : "bottom-left",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          containerId: "calling",
          toastId: "call",
        });
      } else {
        toast.error(
          "The microphone is currently disabled in your browser. You must enable the microphone and reload the browser (which disconnects the current call) to resolve the issue",
          { containerId: "main", toastId: "twilio-error" }
        );
      }
    },
    connectConference: async (connection: Call, participants: any[]) => {
      if (connection) {
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then((stream) => {
            connection.accept();
            dispatch(saveCurrentCall(connection));
            dispatch(clearCall());
            setTimeout(() => {
              setTimeout(() => {
                toast.update("call", {
                  render: () => <IncomingCallToast connection={connection} />,
                });
              }, 1500);
            }, 500);
            const incomingCallEvent = new CustomEvent("incomingCall", {
              detail: {
                toastId: "call",
                connection,
              },
            });
            window.dispatchEvent(incomingCallEvent);
            localStorage.setItem("callStatus", CallingStatus.CONNECTED);
          })
          .catch((err) => {
            connection.reject();
            dispatch(clearCall());
            dispatch(saveCurrentCall(null));
            const callEvent = new CustomEvent("incomingCallDisconnect", {
              detail: {
                toastId: "call",
              },
            });
            window.dispatchEvent(callEvent);
            toast.error(
              "The microphone is currently disabled in your browser. You must enable the microphone and reload the browser (which disconnects the current call) to resolve the issue",
              { containerId: "main", toastId: "twilio-error" }
            );
          });
      }
    },
    disconnectConference: async (call: Call) => {
      const currentUserProfile = store.getState().navigator?.currentUserProfile;
      const callEvent = new CustomEvent("incomingCallDisconnect", {
        detail: {
          toastId: "call",
          call,
        },
      });
      window.dispatchEvent(callEvent);
      if (call) {
        if (call.status() === "pending") {
          appDispatch(
            updateIncomingCallStatusAsync({
              callSid: call?.parameters?.CallSid,
              status: "missed",
              currentUserProfile,
              isRejected: true,
            })
          ).then(() => {
            call.reject();
            setCurrentIncomingCall(undefined);
            if (callState && callState.twilioCallDevice) {
              callState.twilioCallDevice.disconnectAll();
            }
          });
        } else {
          call.reject();
          setCurrentIncomingCall(undefined);
          if (callState && callState.twilioCallDevice) {
            callState.twilioCallDevice.disconnectAll();
          }
        }
      }
      if (callState && callState.twilioCallDevice) {
        callState.twilioCallDevice.disconnectAll();
        dispatch(clearCall());
        dispatch(saveCurrentCall(null));
        setCallPosition(CallPosition.BottomRight);
        setCurrentIncomingCall(undefined);
        dispatch(setIsMinimizeIncomingCall(false));
      }

      return new Promise((resolve) => resolve(200));
    },
  };

  return <TwilioClientServiceContext.Provider value={value}>{props.children}</TwilioClientServiceContext.Provider>;
};

export const useTwilioClientService = () => {
  return useContext(TwilioClientServiceContext);
};
