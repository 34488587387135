export enum TaskFooterButtonsText {
  CANCEL = "Cancel",
  DELAY = "Delay",
  DONE = "Done",
  MANAGER_APPROVAL = "Manager Approval",
  SAVE_DRAFT = "Save Draft",
  NEXT = "Next",
  PREVIOUS = "Previous",
  CLEAR = "Clear",
  SUBMIT = "Submit",
}
