import { useEffect, useState } from "react";
import Modal from "../modal.component";
import MessageReadParticipantsPopover from "components/MessageReadParticipants/message-read-participants.component";
import { isMobile } from "react-device-detect";
import "./message-read-participant-modal.style.scss";
const MessageReadParticipantsModal = ({
  receiptDetailsForParticipants,
  isOpen,
  className,
  handleCloseButton,
  id,
}: any) => {
  const [open, setOpen] = useState(isOpen);
  useEffect(() => {
    if (isMobile) {
      if (isOpen) {
        setOpen(true);
      } else {
        setTimeout(() => {
          setOpen(false);
        }, 200);
      }
    }
  }, [isOpen]);
  if (isMobile) {
    return (
      <Modal
        isOpen={open}
        className={`center message-read-participants-details-mobile-modal ${!isOpen ? "close-drop" : ""}`}
      >
        <MessageReadParticipantsPopover
          receiptDetailsForParticipants={receiptDetailsForParticipants}
          isOpen={true}
          handleCloseButton={handleCloseButton}
          id={id}
        />
      </Modal>
    );
  } else {
    return (
      <MessageReadParticipantsPopover
        receiptDetailsForParticipants={receiptDetailsForParticipants}
        isOpen={isOpen}
        handleCloseButton={handleCloseButton}
        id={id}
      />
    );
  }
};

export default MessageReadParticipantsModal;
