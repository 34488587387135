import Breadcrumb from "components/Breadcrumb/breadcrumb.component";
import Header from "components/Header/header.component";
import CommonState from "components/common-state/common-state.component";
import { CommonStateType } from "components/common-state/common-state.enum";
import { useDispatch, useSelector } from "react-redux";
import GenericTabs from "components/GenericTabs/generic-tabs.component";
import { EpisodeDetailsTab } from "shared/enums/episodes-details.enum";
import { useState } from "react";
import { ActiveTabName } from "components/GenericTabs/props";
import PatientDetails from "./components/patient-details/patient-details.container";
import Icon from "components/Icon/icon.component";
import EpisodeDetailsTasks from "./tabs/tasks/episode-details-tasks.container";
import PatientDemographicsContainer from "./tabs/patient-demographics/patient-demographics.container";
import { useHistory } from "react-router";
import ActivityHistory from "./tabs/activity-history/activity-history.container";
import Button from "components/Button/button.component";
import {
  addNewTab,
  expandTaskBoard,
  getBrowserState,
  setShowTabLimitModal,
} from "state/feature/task-management/task-management.slice";
import { ITab } from "state/types/task-management-slice.type";
import { TaskPageName } from "shared/enums/page-name.enum";
import { CustomToolTip } from "components/CustomTooltip/custom-tooltip.component";
import { getCommonState, setIsFilterModal, setIsOpenPOCServiceModal } from "state/feature/common/common.slice";
import { getEpisodeDetailsTabs } from "components/GenericTabs/constants";
import { EpisodeDetailsProps } from "./episode-details.props";
import ProcedureDetails from "./procedure-details/procedure-details.container";
import { getEpisodesSlice } from "state/feature/episodes/episodes.slice";
import _ from "lodash";
import { TASKS_LIMIT } from "pages/task-management/constant";
import PatientStatus from "./components/patient-status/patient-status.component";
import PatientStatusModal from "components/Modal/patient-status-modal/patient-status-modal.container";
import PlanOfCare from "./tabs/plan-of-care/plan-of-care.container";
import Status from "components/status/status.component";
import { IntakeStatus } from "shared/enums/intake-tasks-type.enum";
import Misc from "./tabs/misc/misc.container";
import PatientDeclineNavigation from "components/patient-decline-navigation-alert/patient-decline-navigation-alert.component";

const EpisodeDetailsPresentation = (props: EpisodeDetailsProps) => {
  const dispatch = useDispatch();
  const { modal } = useSelector(getCommonState);
  const { intakeId, activeTabName, onClickTabName } = props;
  const {
    isLoadingEpisodeDetails,
    patientEpisodeDetails,
    isNewIntakeCreation,
    isLoadingNewIntakeCreation,
    episodeDetails,
    episodeDetailTabs,
    isLoadingEpisodeDetailTabs,
    isActivityFilterApplied,
  } = useSelector(getEpisodesSlice);
  const history = useHistory();
  const { tabs } = useSelector(getBrowserState);

  const getIsActivityFilterDisabled = () =>
    isLoadingEpisodeDetailTabs.activityHistory ||
    (!isActivityFilterApplied && episodeDetailTabs.activityHistory.length == 0);

  const handleAddTask = () => {
    if (tabs.length === TASKS_LIMIT) {
      dispatch(setShowTabLimitModal(true));
      return;
    }
    const newTab: ITab = {
      taskId: _.uniqueId(),
      intakeId: intakeId!,
      title: `${episodeDetails?.patientFirstName} ${episodeDetails?.patientLastName} | New Task`,
      isLoading: false,
      taskType: TaskPageName.NEW_MANUAL_TASK,
    };
    dispatch(addNewTab(newTab));
    dispatch(expandTaskBoard(false));
    history.push("/task-management");
  };

  const renderPresentation = () => {
    if (isLoadingEpisodeDetails || isLoadingNewIntakeCreation) {
      return (
        <div className="loading-container">
          <CommonState type={CommonStateType.LOADING} />
        </div>
      );
    } else if (episodeDetails === null) {
      return (
        <CommonState
          type={CommonStateType.NO_EPISODE_DETAILS_FOUND}
          className="no-episode-details-found"
          handleBackButton={() => {
            history.push("/episodes");
          }}
        />
      );
    }
    return (
      <>
        <Header className="episode-details">
          <Breadcrumb
            pathname={`/episodes/${patientEpisodeDetails?.patientName}`}
            onBackButton={() => {
              if (history.location.state && history.location.state === true) {
                history.goBack();
              } else {
                history.push("/episodes");
              }
            }}
          />
          <div />
          <div className="right-section">
            {patientEpisodeDetails && <PatientStatus status={patientEpisodeDetails.status} />}
            {patientEpisodeDetails?.patientUserId === null && (
              <div className="error-wrap">
                <Icon icon="error-message-icon" size={15} className="error-message-icon" />
                <div className="error-message-text">
                  Patient details are being updated. Wait for few minutes and
                  <span onClick={() => window.location.reload()}> refresh </span>the page.
                </div>
              </div>
            )}
          </div>
        </Header>

        <PatientDetails />
        {episodeDetails.patientNavigationDeclined && (
          <PatientDeclineNavigation className="episode-details-patient-decline-alert" />
        )}
        <div
          className={`all-filters-section ${
            episodeDetails.patientNavigationDeclined ? "all-filters-section-with-patient-declined-alert" : ""
          }`}
        >
          <GenericTabs
            tabsList={getEpisodeDetailsTabs()}
            activeTabName={activeTabName}
            setActiveTabName={onClickTabName}
          />
          <div className="right-section">
            {!isLoadingNewIntakeCreation &&
              isNewIntakeCreation &&
              (episodeDetails.intakeStatusId === IntakeStatus.NEW ||
                episodeDetails.intakeStatusId === IntakeStatus.PROCESSING) &&
              intakeId &&
              activeTabName === EpisodeDetailsTab.TASKS && (
                <Button
                  className="add-task-button green-button"
                  text="Add Task"
                  disabled={false}
                  onClick={handleAddTask}
                />
              )}

            {activeTabName === EpisodeDetailsTab.ACTIVITY_HISTORY && (
              <div data-tip data-for="Filter" className="filter-icon-container">
                <Button
                  icon="filter"
                  className={`filter-container ${getIsActivityFilterDisabled() ? "disabled" : ""}`}
                  iconClassName="filter-icon"
                  buttonSize={14}
                  onClick={() => {
                    if (!getIsActivityFilterDisabled()) {
                      dispatch(setIsFilterModal(true));
                    }
                  }}
                  disabled={getIsActivityFilterDisabled()}
                />
                <Status status={isActivityFilterApplied ? "filtered" : ""} />
                <CustomToolTip text={"Filter"} id="Filter" place="bottom" />
              </div>
            )}
            {activeTabName === EpisodeDetailsTab.PLAN_OF_CARE &&
              !isLoadingNewIntakeCreation &&
              isNewIntakeCreation &&
              !(
                episodeDetails.intakeStatusId === IntakeStatus.CLOSED ||
                episodeDetails.intakeStatusId === IntakeStatus.CANCELED ||
                episodeDetails.intakeStatusId === IntakeStatus.DECEASED ||
                episodeDetails.intakeStatusId === IntakeStatus.DISCHARGED ||
                episodeDetails.intakeStatusId === IntakeStatus.ON_HOLD ||
                episodeDetails.intakeStatusId === IntakeStatus.EXCLUDED
              ) && (
                <Button
                  className="add-service-button green-button"
                  text="Add Service"
                  disabled={false}
                  onClick={() => {
                    dispatch(setIsOpenPOCServiceModal({ isOpen: true, isEdit: false }));
                  }}
                />
              )}
          </div>
        </div>
        {activeTabName === EpisodeDetailsTab.PROCEDURE_DETAILS && <ProcedureDetails />}
        {activeTabName === EpisodeDetailsTab.TASKS && <EpisodeDetailsTasks />}
        {activeTabName === EpisodeDetailsTab.PATIENT_DEMOGRAPHICS && <PatientDemographicsContainer />}
        {activeTabName === EpisodeDetailsTab.ACTIVITY_HISTORY && <ActivityHistory />}
        {activeTabName === EpisodeDetailsTab.PLAN_OF_CARE && <PlanOfCare />}
        {activeTabName === EpisodeDetailsTab.MISC && <Misc />}
      </>
    );
  };
  return (
    <>
      {modal.isOpenPatientStatusModal && <PatientStatusModal />}
      {renderPresentation()}
    </>
  );
};

export default EpisodeDetailsPresentation;
