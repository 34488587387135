import { IPlanOfCareListType } from "pages/episodes/episode-details/tabs/plan-of-care/type";
import "./style.scss";
import { planOfCareDetailsLabels } from "shared/constant/plan-of-care.constant";
import { PAC_TYPE } from "pages/task-management/transition-of-care/constants/index.constant";
import { isEmptyString } from "shared/methods/utilityFunctions";

type PlanOfCareDetailsColumnProps = {
  pocDetails: IPlanOfCareListType;
  className?: string;
  openFromRightPanel?: boolean;
};
const PlanOfCareDetailsColumn = (props: PlanOfCareDetailsColumnProps) => {
  const { pocDetails, className, openFromRightPanel = false } = props;
  const renderPocDetails = (): Array<{ name: string; value: string }> | null => {
    const arr: Array<{ name: string; value: string }> = [];
    if (
      openFromRightPanel === false &&
      pocDetails?.pacType &&
      pocDetails.pacType.toLowerCase() === PAC_TYPE.HOME_SERVICE.toLowerCase() &&
      isEmptyString(pocDetails?.notes ?? "")
    ) {
      return null;
    }
    if (pocDetails?.reasonForService) {
      arr.push({ name: planOfCareDetailsLabels.reasonService, value: pocDetails?.reasonForService });
    }
    if (pocDetails?.readmitReason) {
      arr.push({ name: planOfCareDetailsLabels.readmitReason, value: pocDetails?.readmitReason });
    }
    if (pocDetails?.reasonForOtherReadmission) {
      arr.push({ name: planOfCareDetailsLabels.otherDetails, value: pocDetails?.reasonForOtherReadmission });
    }
    if (pocDetails?.notes) {
      arr.push({ name: planOfCareDetailsLabels.notes, value: pocDetails?.notes ?? "-" });
    }
    return arr;
  };

  const renderPocDetailsData = renderPocDetails();

  return (
    <>
      {renderPocDetailsData !== null ? (
        renderPocDetailsData.map((details) => {
          return (
            <div className={`poc-details-row ${className ?? ""}`}>
              <div className="name">{details.name}:</div>
              <div className="value">{details.value}</div>
            </div>
          );
        })
      ) : (
        <div>-</div>
      )}
    </>
  );
};

export default PlanOfCareDetailsColumn;
