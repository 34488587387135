import LeftCaredSold from "shared/assets/images/tooltip.svg";
import "./notification-web.styles.scss";
import { acknowledgeAllUnreadNotifications } from "state/feature/notification/notification.action";
import { useAppDispatch } from "state/store";
import { useSelector } from "react-redux";
import { getNotificationState } from "state/feature/notification/notification.slice";
import { getAuth } from "state/feature/auth/auth.slice";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import NotificationListing from "pages/notification/components/notification-listing/notification-listing.component";
import { setNotificationStatusForAllConversations } from "state/feature/messaging/messaging.slice";

const NotificationWeb = () => {
  useAxiosAuthenticated();
  const appDispatch = useAppDispatch();
  const authState = useSelector(getAuth);
  const { notifications, unreadNotificationCount, isNotificationDrawerOpen, isNotificationScreenVisible } =
    useSelector(getNotificationState);

  const handleMarkAllAsReadClick = () => {
    appDispatch(acknowledgeAllUnreadNotifications({ ownerUserId: authState.user.email }));
    appDispatch(setNotificationStatusForAllConversations());
  };

  return (
    <>
      {isNotificationDrawerOpen || isNotificationScreenVisible ? (
        <div
          className={`notification-drawer ${isNotificationDrawerOpen ? "notification-is-open" : ""}`}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="notification-drawer-header">
            <h2>Notifications {unreadNotificationCount > 0 ? `(${unreadNotificationCount})` : null}</h2>
            {notifications.length > 0 && (
              <p
                onClick={(e) => {
                  e.stopPropagation();
                  handleMarkAllAsReadClick();
                }}
              >
                Mark all as read
              </p>
            )}
          </div>
          <div className="notification-drawer-body">
            <img
              src={LeftCaredSold}
              className={`notification-left-caret ${isNotificationDrawerOpen ? "notification-is-open" : ""}`}
              alt="left-caret"
            />
            <NotificationListing userEmail={authState.user.email} />
          </div>
        </div>
      ) : null}
    </>
  );
};
export default NotificationWeb;
