import { useState } from "react";

const EpisodeRow = (item: any) => {
  const [isRowHovered, setIsRowHovered] = useState(false);

  return (
    <tr
      onMouseEnter={() => setIsRowHovered(true)}
      onMouseLeave={() => setIsRowHovered(false)}
      className={`${isRowHovered ? "hovered" : ""}`}
    >
      <td>{`${item.patientFirstName === null ? "" : item.patientFirstName} ${
        item.patientLastName === null ? "" : item.patientLastName
      }`}</td>
      <td>{`${item.physicianFirstName === null ? "" : item.physicianFirstName} ${
        item.physicianLastName === null ? "" : item.physicianLastName
      }`}</td>
      <td>{item.procedureDescription === null ? "" : item.procedureDescription}</td>
      <td>{item.patientCurrentLocation === null ? "" : item.patientCurrentLocation}</td>
    </tr>
  );
};

export default EpisodeRow;
