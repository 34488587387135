import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useEffect } from "react";
import { getBrowserState, getCurrentPageTaskState } from "../../state/feature/task-management/task-management.slice";
import { getRiskAssessmentState } from "../../state/feature/risk-assessment/risk-assessment.slice";

/**
 * @example
 * This component can be used to avoid Footer while scrolling.
 * In order to add CSS, tab component parent div should use className as 'task-container-tab'
 */
const useFooterVisibility = () => {
  const location = useLocation();
  const { currentTab } = useSelector(getBrowserState);
  const { isValidIntake, patientDetails } = useSelector(getCurrentPageTaskState(currentTab!.taskId));
  const { isLoading: isLoadingRiskAssessment } = useSelector(getRiskAssessmentState);

  useEffect(() => {
    const isFooterVisible = document.querySelector(".footer-button-container");
    const patientAndEpisodeDetails = document.querySelector(".task-container-tab");
    const patientAndEpisodeDetailsInfo = document.querySelector(".task-container-tabs-info");

    const updateFooterVisibility = (isVisible: boolean) => {
      if (patientAndEpisodeDetails) {
        patientAndEpisodeDetails.classList.toggle("footer-present", isVisible);
        patientAndEpisodeDetails.classList.toggle("footer-not-present", !isVisible);
      }

      if (patientAndEpisodeDetailsInfo) {
        const classListMethod = isVisible ? "add" : "remove";
        patientAndEpisodeDetailsInfo.classList[classListMethod](`footer-present-not-open`);
        patientAndEpisodeDetailsInfo.classList[classListMethod](`footer-not-present-open`);
        patientAndEpisodeDetailsInfo.classList.remove(`footer-present-open`);
        patientAndEpisodeDetailsInfo.classList.remove(`footer-not-present-not-open`);
      }
    };

    if (isFooterVisible) {
      updateFooterVisibility(true);
    } else {
      updateFooterVisibility(false);
    }
  }, [location.pathname, patientDetails?.isRiskAssessmentTaskAdded, isValidIntake, isLoadingRiskAssessment]);

  return {};
};

export default useFooterVisibility;
