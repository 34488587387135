export const navigatorStatsItems = [
  {
    code: "total-call-duration",
    image: "call-duration",
    className: "statistic-box-call-duration",
    info: "",
    value: 0,
    size: 86,
    heading: "Total Call Duration",
  },
  {
    code: "missed-calls",
    image: "missed-call",
    className: "statistic-box-call-duration",
    info: 0,
    value: 0,
    size: 86,
    heading: "Missed Calls",
  },
  {
    code: "voicemails",
    image: "voicemail",
    className: "statistic-box-call-duration",
    info: 0,
    value: 0,
    size: 86,
    heading: "Pending Voicemails",
  },
  {
    code: "unread-messages",
    image: "message",
    className: "statistic-box-call-duration",
    info: 0,
    value: 0,
    size: 86,
    heading: "Unread Messages",
  },
];
