import XIcon from "shared/assets/images/x.svg";
import "./tabs-view.styles.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getBrowserState,
  memoizeTaskPayloadForCurrentPage,
  removeTab,
  setCurrentTab,
  setShowUnsavedChangesModal,
} from "state/feature/task-management/task-management.slice";
import { ITab } from "state/types/task-management-slice.type";
import { getRiskAssessmentState } from "state/feature/risk-assessment/risk-assessment.slice";
import { TaskPageName } from "shared/enums/page-name.enum";
import { getAssessmentState } from "state/feature/assessment/assessment.slice";
import { getTOCManagementState } from "state/feature/toc-management/toc-management.slice";
import Status from "components/status/status.component";
import UnsavedTaskModal from "components/Modal/unsaved-task-modal/unsaved-task-modal.component";
import { getReminderTaskState } from "state/feature/reminder-task/reminder-task.slice";
import { getNewManualTaskState } from "state/feature/new-manual-task/new-manual-task.slice";
import TabLimitModal from "components/Modal/tab-limit-modal/tab-limit-modal.component";
import { setLockState } from "state/feature/task-management/task-management.action";
import { CustomToolTip } from "components/CustomTooltip/custom-tooltip.component";
import { getSendOrderSlice } from "state/feature/send-order/send-order.slice";
import { getAdmitTaskState } from "state/feature/admit-task/admit-task.slice";
import { getCheckOnPatientSlice } from "state/feature/check-on-patient/check-on-patient.slice";

const TabsViewContainer = function (props: { tabs: Array<ITab> }) {
  const dispatch = useDispatch();
  const { tabs } = props;
  const { currentTab, pages } = useSelector(getBrowserState);
  const assessmentState = useSelector(getAssessmentState);
  const riskAssessmentState = useSelector(getRiskAssessmentState);
  const tocManagementState = useSelector(getTOCManagementState);
  const reminderTaskState = useSelector(getReminderTaskState);
  const newManualTaskState = useSelector(getNewManualTaskState);
  const sendOrderState = useSelector(getSendOrderSlice);
  const admitTaskState = useSelector(getAdmitTaskState);
  const checkOnPatientState = useSelector(getCheckOnPatientSlice);
  const memoizeTask = () => {
    if (!currentTab) {
      return;
    }
    const { taskType } = currentTab;

    let taskPayload = null;
    if (taskType === TaskPageName.RISK_ASSESSMENT) {
      taskPayload = riskAssessmentState;
    } else if (taskType === TaskPageName.TRANSITION_OF_CARE) {
      taskPayload = tocManagementState;
    } else if (taskType === TaskPageName.ASSESSMENT) {
      taskPayload = assessmentState;
    } else if (taskType === TaskPageName.REMINDER_TASK) {
      taskPayload = reminderTaskState;
    } else if (taskType === TaskPageName.NEW_MANUAL_TASK) {
      taskPayload = newManualTaskState;
    } else if (taskType === TaskPageName.SEND_ORDER) {
      taskPayload = sendOrderState;
    } else if (taskType === TaskPageName.ADMIT_TASK) {
      taskPayload = admitTaskState;
    } else if (taskType === TaskPageName.CHECK_ON_PATIENT) {
      taskPayload = checkOnPatientState;
    }

    dispatch(memoizeTaskPayloadForCurrentPage({ taskPayload, currentTab }));
  };

  const onTabClick = (t: ITab) => {
    memoizeTask();
    dispatch(setCurrentTab(t));
  };

  const isPageDirty = (tab: ITab) => {
    const page = pages.find((el) => el.taskId === tab.taskId);
    if (!page) {
      return false;
    }
    return page.isDirty;
  };

  const onClose = (t: ITab) => {
    if (isPageDirty(t)) {
      if (currentTab?.taskId !== t.taskId) {
        dispatch(setCurrentTab(t));
      }
      dispatch(setShowUnsavedChangesModal(true));
    } else {
      dispatch(setLockState({ intakeId: currentTab!.intakeId, taskId: currentTab!.taskId, isLocked: false }));
      dispatch(removeTab(t.taskId));
    }
  };

  return (
    <div className="tabs-container">
      <UnsavedTaskModal
        handleOnClick={() => {
          dispatch(setLockState({ intakeId: currentTab!.intakeId, taskId: currentTab!.taskId, isLocked: false }));
          dispatch(removeTab(currentTab?.taskId));
          dispatch(setShowUnsavedChangesModal(false));
        }}
      />
      <TabLimitModal />
      {tabs.map((t) => (
        <div
          key={t.taskId}
          className={`tab-container ${currentTab?.taskId === t.taskId ? "active" : "inactive"}`}
          onClick={() => onTabClick(t)}
        >
          <div id="tab-context">
            <div data-tip data-for={`${t.title}`} className="title-info">
              {isPageDirty(t) ? <Status status="offline" /> : null}
              <div className="title">{t.title}</div>
            </div>
            <CustomToolTip text={`${t.title}`} id={`${t.title}`} place="top" />

            <div
              className="close-icon"
              onClick={(e) => {
                e.stopPropagation();
                onClose(t);
              }}
            >
              <img src={XIcon} alt="Close" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TabsViewContainer;
