import Icon from "components/Icon/icon.component";
import { useState } from "react";
import "./text-field-v2.styles.scss";
type TextFieldProps = {
  label: string;
  className?: string;
  type: string;
  value: string;
  setValue: (label: string, value: string) => void;
  showError?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  onBlur?: () => void;
  placeholder?: string;
  inputClassName?: string;
  icon?: React.ReactNode;
  isRequired?: boolean;
  inputContainerClassName?: string;
  togglePassword?: (e: React.MouseEvent) => void;
  isVisiblePassword?: boolean;
  max?: number;
};

const TextField = ({
  label,
  className,
  type,
  value,
  setValue,
  showError,
  disabled,
  errorMessage,
  onBlur,
  placeholder,
  inputClassName,
  icon,
  isRequired,
  inputContainerClassName,
  togglePassword,
  isVisiblePassword,
  max,
}: TextFieldProps) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const handleOnFocus = () => {
    setIsFocused(true);
  };
  const handleOnBlur = () => {
    setIsFocused(false);
    onBlur?.();
  };
  return (
    <div className={`text-input-field-container ${className ? className : ""}`}>
      <label className="field-title">
        {label}
        {isRequired && <span className="red-color">*</span>}
      </label>
      <div
        className={`field-wrap ${inputContainerClassName ? inputContainerClassName : ""}  ${
          showError ? "red-border" : isFocused ? "focused" : "black-border"
        } ${disabled ? "disabled" : ""}`}
      >
        {icon ? icon : null}
        <input
          type={type ? type : "text"}
          className={`input-field ${inputClassName ? inputClassName : ""}`}
          value={value}
          onChange={(event) => setValue(label, event.target.value)}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          min="0"
          disabled={disabled}
          placeholder={placeholder}
          max={max}
        />
        {type === "password" && !isVisiblePassword ? (
          <div className="eye" role="button" onClick={togglePassword}>
            <Icon icon="eye" size={20} />
          </div>
        ) : type === "password" && isVisiblePassword ? (
          <div className="eye" role="button" onClick={togglePassword}>
            <Icon icon="eye-slash" size={20} />
          </div>
        ) : null}
      </div>
      {showError && <div className="error-wrap">{errorMessage}</div>}
    </div>
  );
};

export default TextField;
