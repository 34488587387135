import Icon from "components/Icon/icon.component";
import React from "react";
import "./text-field.styles.scss";
import { TextFieldProps } from "./text-field.props";

const TextField = (props: TextFieldProps) => {
  const {
    label,
    className,
    type,
    value,
    setValue,
    showError,
    disabled,
    errorMessage,
    onBlur,
    placeholder,
    inputClassName,
    isPhoneNumber,
    maxLength,
    showErrorIcon = false,
    inputSizeClassName,
    infoMessaage,
    isDisplayInfoMessage,
    id,
    isRequired,
  } = props;
  return (
    <div id={id} className={`text-input-field-container ${className ? className : ""}`}>
      <label className="field-title">
        {label}
        {isRequired && <span className="red-color-asterisk">*</span>}
      </label>
      <div className={`field-wrap ${inputSizeClassName ? inputSizeClassName : ""}`}>
        {isPhoneNumber && <input type="text" className="input-field phone-number" value="+1" disabled />}
        <input
          type={type ? type : "text"}
          className={`input-field ${showError ? "red-border" : "blue-border"} ${inputClassName ? inputClassName : ""}`}
          value={value}
          onChange={(event) => setValue(label, event.target.value)}
          onBlur={onBlur}
          min="0"
          disabled={disabled}
          placeholder={placeholder}
          maxLength={maxLength}
        />
        {isDisplayInfoMessage && (
          <div className="info-wrap">
            <Icon icon="info" size={15} className="info-icon" />
            {infoMessaage}
          </div>
        )}
      </div>
      {showError && errorMessage && (
        <div className="error-wrap">
          {showErrorIcon && <Icon icon="error-message-icon" size={15} className="error-message-icon" />}
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default TextField;
