import Icon from "components/Icon/icon.component";
import { ErrorMessageProps } from "./error-message.type";

const Error = (props: ErrorMessageProps) => {
  const { message } = props;
  return (
    <div className="not-selected-error-message">
      <div>
        <Icon icon="error-message-icon" size={15} />
      </div>
      <div>{message ? message : "Select an answer from the above options"}</div>
    </div>
  );
};
export default Error;
