export const checkonpatientQuestions = {
  serviceType: { disabled: true, title: "Service Type" },
  provider: { disabled: false, title: "Provider", required: true },
  procedureDate: { disabled: true, title: "Procedure Date", required: true },
  daysVisit: { disabled: false, title: "Days/Visit" },
  daysVisitPlanned: { disabled: true, title: "Planned" },
  daysVisitActual: { disabled: false, title: "Anticipated", required: true },
  daysVisitCompleted: { disabled: false, title: "Completed to Now", required: true },
  offtrack: { disabled: false, title: "Off Track" },
  offtrackReason: { disabled: false, title: "Reason", required: false },
  offtrackStartDate: { disabled: false, title: " Start Date", required: false },
  offtrackDetails: { disabled: false, title: "Details", required: false },
  dischargeDate: { disabled: false, title: "Discharge", required: true },
  estimatedDate: { disabled: true, title: "Estimated" },
  actualDate: { disabled: false, title: "Actual", required: true },
  dischargeFromService: { disabled: false, title: "Patient has discharged from this site of care" },
  notes: { disabled: false, title: "Notes" },
};

export const EMPTY_FIELD_ERROR_MESSAGE = "This field is required";
export const COMPLETED_GREATER_THAN_ACTUAL_DAYS_ERROR_MESSAGE = "Completed cannot be greater than Actual Days/Visit";
