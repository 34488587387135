import { createAsyncThunk } from "@reduxjs/toolkit";
import { getConfig } from "config/config";
import { axiosInstance } from "shared/axios-instance";

export const getReminderTaskAsync = createAsyncThunk(
  "reminder-task/getReminderTaskAsync",
  async (requestPayload: { intakeId: string; taskId: string }) => {
    const response = await axiosInstance.post(
      `${getConfig().taskManagementBase}/api/v1/Tasks/fetch-manual-task`,
      requestPayload
    );
    if (response.status === 200 && response.data && response.data.data) {
      return response.data.data;
    }
    return null;
  }
);

export const saveReminderTaskAsync = createAsyncThunk(
  "reminder-task/saveReminderTaskAsync",
  async (
    requestPayload: {
      intakeId: string;
      taskId: string;
      notes: string;
      updateUser: string;
      updateUserId: string;
      isSaveDraft: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.put(
        `${getConfig().taskManagementBase}/api/v1/Tasks/save-reminder`,
        requestPayload
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({ error });
    }
  }
);
